import React from "react";
import { SetbackType } from "../../../../../types/Setback";

interface Props {
  setbackType: SetbackType;
  selected: boolean;
}

class SetbackIcon extends React.Component<Props, {}> {
  render() {
    return (
      <div
        className={`component--setback-icon ${this.props.setbackType} ${this.props.selected ? "selected" : ""}`}
      />
    );
  }
}

export default SetbackIcon;
