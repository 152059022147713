import React from "react";
import { connect } from "react-redux";
import { developmentSelectors } from "../../../../state/development";
import authentication from "../../../../utils/authentication";
import panelsConfig from "../../utils/configFiles/panelsConfig";
import { allTogglesDisabled, buildingUsageIsEnabled } from "../../../utils/uiToggleHelper";
import { Panel } from "../../../../types/uiElements";
import { Path } from "../../../../types/Path";
import Sidebar from "../../../sharedComponents/Sidebar";
import database from "../../../../utils/database/database";
import companyInfo from "../../../../utils/companyInfo";
import RedirectComponent from "../../../sharedComponents/RedirectComponent";
import { TooltipType } from "../../../sharedComponents/Tooltip/config";

const mapStateToProps = (state) => {
  return {
    toggles: developmentSelectors.getBuildingUsageToggles(state),
    developmentIsEdited: developmentSelectors.getIsEdited(state),
  };
};

interface OwnProps {
  panels: Array<Panel>;
  onPanelToggle(panel: Panel);
}

type StateProps = ReturnType<typeof mapStateToProps>;
type Props = StateProps & OwnProps;

interface State {
  showSavedMessage: boolean;
}

class ExplorerSidebar extends React.PureComponent<Props, State>  {
  constructor(props) {
    super(props);

    this.state = {
      showSavedMessage: false,
    };
  }

  /**
   * Display saved message after it has been edited and saved.
   */
  componentDidUpdate(previousProps: Props) {
    if (previousProps.developmentIsEdited && !this.props.developmentIsEdited) {
      this.setState({ showSavedMessage: true });
    } else if (!previousProps.developmentIsEdited && this.props.developmentIsEdited) {
      this.setState({ showSavedMessage: false });
    }
  }

  /**
   * Selects the state of the button. Returns "active", "disabled" or "".
   */
  buttonStateClass = (subjectPanel) => {
    if (this.props.panels.some((panel) => panel.title === subjectPanel.title)) {
      return "active";
    } else if (
      (allTogglesDisabled(this.props.toggles) && subjectPanel.requireUsages)
      || (!buildingUsageIsEnabled(this.props.toggles, subjectPanel.usageGroup))
    ) {
      return "hidden";
    } else {
      return "";
    }
  }

  /**
   * Render the side bar panel buttons.
   */
  renderPanelButton = (panelSettings) => {
    return (
      <div
        className={`side-bar-links flx v-center ${this.buttonStateClass(panelSettings)}`}
        onClick={() => { this.props.onPanelToggle(panelSettings) }}
      >
        <span className={`icon icon-${panelSettings.description}`} />
        {panelSettings.title}
      </div>
    )
  }

  /**
   * Redirect to home.
   */
  redirectToHome = async (redirect) => {
    const userIsAuthenticated = authentication.isUserAuthenticated();
    if (userIsAuthenticated) {
      await database.saveCurrentProject();
      redirect();
    }
  }

  /**
   * Render the Your Projects section.
   */
  renderYourProjectsSection = () => {
    const userIsAuthenticated = authentication.isUserAuthenticated();
    const { showSavedMessage } = this.state;

    return (
      <div className="sidebar-block">
        <div className="title-wrapper">
          <h5>YOUR PROJECTS</h5>
          {showSavedMessage && <span className="saved-message">Saved</span>}
        </div>

        <div className="sidebar-nav nav-adjust">
          <RedirectComponent path={Path.Home}>
            {({ redirect }) => (
              <div className="side-bar-links flx v-center" onClick={() => this.redirectToHome(redirect)}
                data-tip={!userIsAuthenticated && TooltipType.Dashboard}
                data-offset="{'bottom': 150, 'left': 35}"
                data-class="component-react-tooltip offset-triangle"
              >
                <span className={`icon icon-dashboard`} />
                Dashboard
              </div>
            )}
          </RedirectComponent>
        </div>
      </div>
    );
  }

  /**
   * Render the General Assumptions section.
   */
  renderGeneralAssumptionsSection = () => {
    return (
      <div className="sidebar-block" key="panel_block_general_assumptions">
        <h5>GENERAL ASSUMPTIONS</h5>
        <div className="sidebar-nav nav-adjust">
          {this.renderPanelButton(panelsConfig.projectInfoPanel)}
          {this.renderPanelButton(panelsConfig.projectCostPanel)}
          {this.renderPanelButton(panelsConfig.heightAndSetbacksPanel)}
        </div>
      </div>
    );
  }

  /**
   * Render the Use Assumptions section.
   */
  renderUseAssumptionsSection = () => {
    if (allTogglesDisabled(this.props.toggles)) return null;

    return (
      <div className="sidebar-block" key="panel_block_use_assumptions">
        <h5>USE ASSUMPTIONS</h5>
        <div className="sidebar-nav nav-adjust">
          {this.renderPanelButton(panelsConfig.condoPanel)}
          {this.renderPanelButton(panelsConfig.multifamilyPanel)}
          {this.renderPanelButton(panelsConfig.hotelPanel)}
          {this.renderPanelButton(panelsConfig.officePanel)}
          {this.renderPanelButton(panelsConfig.retailPanel)}
          {this.renderPanelButton(panelsConfig.industrialPanel)}
          {this.renderPanelButton(panelsConfig.parkingConstraintsPanel)}
        </div>
      </div>
    );
  }

  /**
   * Render the Financials section.
   */
  renderFinancialsSection = () => {
    if (allTogglesDisabled(this.props.toggles)) return null;

    return (
      <div className="sidebar-block">
        <h5>FINANCIALS</h5>
        <div className="sidebar-nav nav-adjust">
          {this.renderPanelButton(panelsConfig.backOfEnvelopePanel)}
        </div>
      </div>
    );
  }

  /**
   * Render the Reports section.
   */
  renderReportsSection = () => {
    return (
      <div className="sidebar-block">
        <h5>REPORTS</h5>
        <div className="sidebar-nav nav-adjust">
          {this.renderPanelButton(panelsConfig.pdfPanel)}
        </div>
      </div>
    );
  }

  /**
   * Render the Support section.
   */
  renderSupportSection = () => {
    return (
      <div className="sidebar-block">
        <h5>SUPPORT</h5>

        <div className="sidebar-nav nav-adjust">
          {/* Support  */}
          <a
            href={companyInfo.SUPPORT_URL}
            target="_blank"
            rel="noopener noreferrer"
            className="side-bar-links flx v-center"
          >
            <span className="icon icon-support" />
            Support
          </a>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="component--explorer-sidebar">
        <Sidebar>
          {this.renderYourProjectsSection()}
          {this.renderGeneralAssumptionsSection()}
          {this.renderUseAssumptionsSection()}
          {this.renderFinancialsSection()}
          {this.renderReportsSection()}
          {this.renderSupportSection()}
        </Sidebar>
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
)(ExplorerSidebar);
