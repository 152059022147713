import defaultsInitialOnly from "./presetLists/defaultsInitialOnly";
import defaults from "./presetLists/defaults";
import increments from "./presetLists/increments";

/**
 * Generate the list of default static presets.
 */
const forUpdate = () => {
  return [defaults];
}

/**
 * Generate the list of initial static presets.
 */
const forInitialization = () => {
  return [
    increments,
    defaultsInitialOnly,
    defaults,
  ];
}

export default {
  forUpdate,
  forInitialization
}
