import { VariableId } from "../../../../../types/VariableId";
import { Config } from "../../../../../types/Config";
import Format from "../../../../../types/Format";
import { BuildingUse } from "../../../../../types/BuildingUse";
import Unit from "../../../../../types/Unit";

const kpiList: Config = {
  [VariableId.ProjectGrossBuildableArea]: {
    text: "Project Gross Buildable",
    variableId: VariableId.ProjectGrossBuildableArea,
    buildingUse: BuildingUse.Project,
    formatOptions: { type: Format.Type.Number },
    [Unit.System.Imperial]: {
      unitTarget: Unit.Type.SquareFeet,
      formatOptions: { suffix: " SF" },
    },
    [Unit.System.Metric]: {
      unitTarget: Unit.Type.SquareMeters,
      formatOptions: { suffix: " m²" },
    },
  },

  [VariableId.FloorAreaRatio]: {
    text: "Floor Area Ratio With Parking",
    variableId: VariableId.FloorAreaRatio,
    formatOptions: {
      type: Format.Type.Number,
      decimalPlaces: 2,
    },
    buildingUse: BuildingUse.Project,
  },

  [VariableId.FloorAreaRatioWithoutParking]: {
    text: "Floor Area Ratio Without Parking",
    variableId: VariableId.FloorAreaRatioWithoutParking,
    formatOptions: {
      type: Format.Type.Number,
      decimalPlaces: 2,
    },
    buildingUse: BuildingUse.Project,
  },

  [VariableId.ProjectNumberOfFloors]: {
    text: "Number of Floors",
    variableId: VariableId.ProjectNumberOfFloors,
    buildingUse: BuildingUse.Project,
    formatOptions: { type: Format.Type.Number },
  },

  [VariableId.ProjectNetLeasableOrSellableArea]: {
    text: "Project Net Area",
    variableId: VariableId.ProjectNetLeasableOrSellableArea,
    buildingUse: BuildingUse.Project,
    formatOptions: { type: Format.Type.Number },
    [Unit.System.Imperial]: {
      unitTarget: Unit.Type.SquareFeet,
      formatOptions: { suffix: " SF" },
    },
    [Unit.System.Metric]: {
      unitTarget: Unit.Type.SquareMeters,
      formatOptions: { suffix: " m²" },
    },
  },

  [VariableId.ProjectTotalDevelopmentCost]: {
    text: "Project Cost Total",
    variableId: VariableId.ProjectTotalDevelopmentCost,
    buildingUse: BuildingUse.Project,
    formatOptions: { type: Format.Type.Currency },
  },

  [VariableId.UnitsPerParcelArea]: {
    variableId: VariableId.UnitsPerParcelArea,
    text: "Unit Density Ratio",
    formatOptions: {
      type: Format.Type.Number,
      decimalPlaces: 1,
    },
    [Unit.System.Imperial]: {
      unitTarget: Unit.Type.Acres,
      unitIsInverse: true,
      formatOptions: { suffix: " units / acre" },
    },
    [Unit.System.Metric]: {
      unitTarget: Unit.Type.Hectares,
      unitIsInverse: true,
      formatOptions: { suffix: " units / hectare" },
    }
  },

  [VariableId.ProjectHardCostPerGrossBuildableArea]: {
    variableId: VariableId.ProjectHardCostPerGrossBuildableArea,
    buildingUse: BuildingUse.Project,
    formatOptions: {
      type: Format.Type.Currency,
      decimalPlaces: 2,
    },
    [Unit.System.Imperial]: {
      text: "Average Hard Cost PSF",
      unitTarget: Unit.Type.SquareFeet,
      unitIsInverse: true,
    },
    [Unit.System.Metric]: {
      text: "Average Hard Cost PSM",
      unitTarget: Unit.Type.SquareMeters,
      unitIsInverse: true,
    },
  },

  [VariableId.RentalUsesAnnualNetOperatingIncomeForBackOfEnvelope]: {
    text: "Lease Uses NOI",
    variableId: VariableId.RentalUsesAnnualNetOperatingIncomeForBackOfEnvelope,
    buildingUse: BuildingUse.RentalUses,
    formatOptions: { type: Format.Type.Currency },
  },

  [VariableId.RentalUsesAnnualReturnOnCostForBackOfEnvelope]: {
    text: "Lease Uses ROC",
    variableId: VariableId.RentalUsesAnnualReturnOnCostForBackOfEnvelope,
    formatOptions: {
      type: Format.Type.Percentage,
      decimalPlaces: 2,
    },
    buildingUse: BuildingUse.RentalUses,
  },

  [VariableId.CondoReturnOnCost]: {
    text: "Condo ROC",
    variableId: VariableId.CondoReturnOnCost,
    formatOptions: {
      type: Format.Type.Percentage,
      decimalPlaces: 2,
    },
    buildingUse: BuildingUse.Condo,
  },

  [VariableId.CondoAverageAreaPerUnit]: {
    text: "Condo Average Unit Size",
    variableId: VariableId.CondoAverageAreaPerUnit,
    buildingUse: BuildingUse.Condo,
    formatOptions: { type: Format.Type.Number },
    [Unit.System.Imperial]: {
      unitTarget: Unit.Type.SquareFeet,
      formatOptions: { suffix: " SF" },
    },
    [Unit.System.Metric]: {
      unitTarget: Unit.Type.SquareMeters,
      formatOptions: { suffix: " m²" },
    },
  },

  [VariableId.CondoAverageGrossSalesPerUnit]: {
    text: "Condo Average Sale",
    variableId: VariableId.CondoAverageGrossSalesPerUnit,
    formatOptions: {
      type: Format.Type.Currency,
      suffix: " / unit",
    },
    buildingUse: BuildingUse.Condo,
  },

  [VariableId.CondoAverageGrossSalesPerSellableArea]: {
    variableId: VariableId.CondoAverageGrossSalesPerSellableArea,
    buildingUse: BuildingUse.Condo,
    formatOptions: {
      type: Format.Type.Currency,
      decimalPlaces: 2
    },
    [Unit.System.Imperial]: {
      text: "Condo Average Sale PSF",
      unitTarget: Unit.Type.SquareFeet,
      unitIsInverse: true,
    },
    [Unit.System.Metric]: {
      text: "Condo Average Sale PSM",
      unitTarget: Unit.Type.SquareMeters,
      unitIsInverse: true,
    },
  },

  [VariableId.CondoProfit]: {
    text: "Condo Profit",
    variableId: VariableId.CondoProfit,
    buildingUse: BuildingUse.Condo,
    formatOptions: { type: Format.Type.Currency }
  },

  [VariableId.MultifamilyAverageAreaPerUnit]: {
    text: "Multifamily Average Unit Size",
    variableId: VariableId.MultifamilyAverageAreaPerUnit,
    buildingUse: BuildingUse.Multifamily,
    formatOptions: { type: Format.Type.Number },
    [Unit.System.Imperial]: {
      unitTarget: Unit.Type.SquareFeet,
      formatOptions: { suffix: " SF" },
    },
    [Unit.System.Metric]: {
      unitTarget: Unit.Type.SquareMeters,
      formatOptions: { suffix: " m²" },
    },
  },

  [VariableId.MultifamilyAverageYearOnePotentialGrossMonthlyRentalIncomePerUnit]: {
    text: "Multifamily Average Rent",
    variableId: VariableId.MultifamilyAverageYearOnePotentialGrossMonthlyRentalIncomePerUnit,
    buildingUse: BuildingUse.Multifamily,
    formatOptions: { type: Format.Type.Currency },
  },

  [VariableId.MultifamilyAverageYearOnePotentialGrossMonthlyRentalIncomePerLeasableArea]: {
    variableId: VariableId.MultifamilyAverageYearOnePotentialGrossMonthlyRentalIncomePerLeasableArea,
    buildingUse: BuildingUse.Multifamily,
    formatOptions: {
      type: Format.Type.Currency,
      decimalPlaces: 2,
    },
    [Unit.System.Imperial]: {
      text: "Multifamily Average Rent PSF",
      unitTarget: Unit.Type.SquareFeet,
      formatOptions: { suffix: " / SF" },
      unitIsInverse: true,
    },
    [Unit.System.Metric]: {
      text: "Multifamily Average Rent PSM",
      unitTarget: Unit.Type.SquareMeters,
      formatOptions: { suffix: " / m²" },
      unitIsInverse: true,
    },
  },

  [VariableId.HotelAnnualReturnOnCostForBackOfEnvelope]: {
    text: "Hotel ROC",
    variableId: VariableId.HotelAnnualReturnOnCostForBackOfEnvelope,
    formatOptions: {
      type: Format.Type.Percentage,
      decimalPlaces: 2,
    },
    buildingUse: BuildingUse.Hotel,
  },

  [VariableId.HotelAnnualRoomRevenueForBackOfEnvelope]: {
    text: "Hotel Total Room Revenue",
    variableId: VariableId.HotelAnnualRoomRevenueForBackOfEnvelope,
    buildingUse: BuildingUse.Hotel,
    formatOptions: { type: Format.Type.Currency },
  },

  [VariableId.ParkingRequiredSpacesBeforeReduction]: {
    text: "Parking Required Spaces",
    variableId: VariableId.ParkingRequiredSpacesBeforeReduction,
    formatOptions: {
      type: Format.Type.Number,
      suffix: " spaces",
    },
    buildingUse: BuildingUse.Parking,
  },

  [VariableId.ParkingRequiredSpacesAfterReduction]: {
    text: "Parking Spaces After Reduction",
    variableId: VariableId.ParkingRequiredSpacesAfterReduction,
    formatOptions: {
      type: Format.Type.Number,
      suffix: " spaces",
    },
    buildingUse: BuildingUse.Parking,
  },

  [VariableId.ParkingHardCostPerSpace]: {
    text: "Parking Hard Cost per Space",
    variableId: VariableId.ParkingHardCostPerSpace,
    formatOptions: {
      type: Format.Type.Currency,
      suffix: " / space",
    },
    buildingUse: BuildingUse.Parking,
  },
};

export default kpiList;
