import userProjectsTimestamp from "./userProjectsTimestamp";
import returnOnCost from"./returnOnCost";
import { AsyncDataPopulator } from "..";

const dataPopulators: Array<AsyncDataPopulator> = [
  userProjectsTimestamp,
  returnOnCost
];

export default dataPopulators;
