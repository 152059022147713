import React from "react";
import { connect } from "react-redux";
import AccordionSection from "../../../../../sharedComponents/AccordionSection";
import UnitSystemInput from "../../../../../sharedComponents/UnitSystemInput";
import { InputField, ToggleField } from "../../../../../utils/forms/fieldRenderers";
import { usageColor } from "../../../../../utils/buildingUsageProperties";
import { developmentActions, developmentSelectors } from "../../../../../../state/development";
import developmentUiHelper from "../../../../../utils/developmentUiHelper";
import analytics from "../../../../../../utils/analytics";
import { BuildingUse } from "../../../../../../types/BuildingUse";
import RecalculatePopup from "../RecalculatePopup";
import { accordionSectionSelectors, accordionSectionActions } from "../../../../../../state/ui/shared/accordionSection";
import { Accordions } from "../../../../../../state/ui/shared/accordionSection/reducers";

const mapStateToProps = (state) => {
  return {
    developmentName: developmentSelectors.getName(state),
    values: developmentSelectors.getValues(state),
    parcel: developmentSelectors.getParcel(state),
    toggles: developmentSelectors.getBuildingUsageToggles(state),
    developmentUnitSystem: developmentSelectors.getUnitSystem(state),
    accordionIsOpen: accordionSectionSelectors.getStaticAccordionIsOpen(state, Accordions.BuildingUses),
  }
}

const mapDispatchToProps = {
  setDevelopmentName: developmentActions.setName,
  toggleBooleanInput: developmentActions.toggleBooleanInput,
  setDevelopmentUnitSystem: developmentActions.setUnitSystem,
  setStaticAccordionIsOpen: accordionSectionActions.setStaticAccordionIsOpen,
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;
type Props = StateProps & DispatchProps;

interface State {
  recalculatePopupIsOpen: boolean;
}

class ProjectInfoAccordionSection extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      recalculatePopupIsOpen: false,
    }
  }

  /**
   * Updates the store with a new development name on every keystroke.
   */
  handleDevelopmentNameChange = (event) => {
    let newDevelopmentName = event.target.value;
    this.props.setDevelopmentName(newDevelopmentName);
  };

  /**
   * Updates the store value for the toggle based on the variableId.
   */
  handleToggleChange = (variableId) => {
    analytics.trackChangeToggle(variableId, !this.getToggleValue(variableId));
    this.props.toggleBooleanInput(variableId);
  }

  /**
   * Retrieve toggle value based on usage_group.
   */
  getToggleValue = (variableId) => {
    return this.props.values[variableId];
  };

  /**
   * Toggle recalculatePanelIsOpen flag.
   */
  toggleRecalculatePanelIsOpen = () => {
    this.setState({ recalculatePopupIsOpen: !this.state.recalculatePopupIsOpen })
  }

  /**
   * Toggle the accordion is open prop.
   */
  toggleIsOpen = () => {
    this.props.setStaticAccordionIsOpen(Accordions.BuildingUses, !this.props.accordionIsOpen);
  }

  render() {
    const {
      developmentName,
      developmentUnitSystem,
      setDevelopmentUnitSystem,
    } = this.props;

    return (
      <AccordionSection
        title="BUILDING USES"
        iconClass="name-uses"
        isOpen={this.props.accordionIsOpen}
        toggleIsOpen={this.toggleIsOpen}
        className="project-info-accordion"
      >
        <div className="unit-system-input-container">
          <UnitSystemInput unitSystem={developmentUnitSystem} setUnitSystem={setDevelopmentUnitSystem} />
        </div>

        <InputField
          label={"Project Name"}
          type="text"
          placeholder={developmentUiHelper.EMPTY_NAME_PLACEHOLDER}
          inputElementProps={{
            value: developmentName,
            onChange: this.handleDevelopmentNameChange,
          }}
        />

        <label className="top-label">Building Uses</label>
        <div className="uses-container">
          <div>
            <div>
              <ToggleField
                label="Condominiums"
                color={usageColor(BuildingUse.Condo)}
                toggleElementProps={{
                  value: this.getToggleValue("condoToggle"),
                  onChange: () => { this.handleToggleChange("condoToggle") }
                }}
              />
            </div>
            <div>
              <ToggleField
                label="Multifamily"
                color={usageColor(BuildingUse.Multifamily)}
                toggleElementProps={{
                  value: this.getToggleValue("multifamilyToggle"),
                  onChange: () => { this.handleToggleChange("multifamilyToggle") }
                }}
              />
            </div>
            <div>
              <ToggleField
                label="Hotel"
                color={usageColor(BuildingUse.Hotel)}
                toggleElementProps={{
                  value: this.getToggleValue("hotelToggle"),
                  onChange: () => { this.handleToggleChange("hotelToggle") }
                }}
              />
            </div>
          </div>
          <div>
            <div>
              <ToggleField
                label="Office"
                color={usageColor(BuildingUse.Office)}
                toggleElementProps={{
                  value: this.getToggleValue("officeToggle"),
                  onChange: () => { this.handleToggleChange("officeToggle") }
                }}
              />
            </div>
            <div>
              <ToggleField
                label="Retail"
                color={usageColor(BuildingUse.Retail)}
                toggleElementProps={{
                  value: this.getToggleValue("retailToggle"),
                  onChange: () => { this.handleToggleChange("retailToggle") }
                }}
              />
            </div>
            <div>
              <ToggleField
                label="Industrial"
                color={usageColor(BuildingUse.Industrial)}
                toggleElementProps={{
                  value: this.getToggleValue("industrialToggle"),
                  onChange: () => { this.handleToggleChange("industrialToggle") }
                }}
              />
            </div>
          </div>
        </div>

        <button className="recalculate-button" onClick={this.toggleRecalculatePanelIsOpen}>recalculate project</button>
        {this.state.recalculatePopupIsOpen && <RecalculatePopup onStateChange={this.toggleRecalculatePanelIsOpen} />}
      </AccordionSection>
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProjectInfoAccordionSection);
