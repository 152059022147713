import React from "react";
import { RouteComponentProps } from "react-router";
import { withRouter } from "react-router-dom";
import { Path } from "../../../types/Path";
import LogInPopup from "../../sharedComponents/LogInPopup";
import BrandingWatermark from "../../sharedComponents/BrandingWatermark";
import GeocoderSearch from "../../sharedComponents/GeocoderSearch";
import analytics from "../../../utils/analytics";

type Props = RouteComponentProps;

interface State {
  logInPopupIsOpen: boolean;
}

class HomePage extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      logInPopupIsOpen: Boolean(this.props.location.state && this.props.location.state.logInPopupIsOpen),
    }

    analytics.trackPageview(Path.Home);
  }

  /**
   * Redirect to the subscribe page.
   */
  redirectToSubscribe = () => {
    this.props.history.push(Path.Subscribe);
  }

  /**
   * Toggle the logInPopupIsOpen state.
   */
  toggleLogInPopupIsOpen = () => {
    this.setState({ logInPopupIsOpen: !this.state.logInPopupIsOpen });
  }

  render() {
    return (
      <div className="component--home-page">
        <div className="top-bar">
          <div className="authentication-link" onClick={this.toggleLogInPopupIsOpen}>Log In</div>
          <div className="authentication-link" onClick={this.redirectToSubscribe}>Sign Up</div>
        </div>

        <GeocoderSearch />

        <div className="footer">
          <BrandingWatermark />
        </div>
        {this.state.logInPopupIsOpen && <LogInPopup onStateChange={this.toggleLogInPopupIsOpen} classes="on-home-page"/>}
      </div>
    );
  }
}

export default withRouter(HomePage);
