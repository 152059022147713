import { Config } from "../../../../../types/Config";
import { VariableId } from "../../../../../types/VariableId";
import { BuildingUse } from "../../../../../types/BuildingUse";
import Format from "../../../../../types/Format";
import Unit from "../../../../../types/Unit";

const config: Config = {
  [VariableId.HotelGrossBuildableArea]: {
    variableId: VariableId.HotelGrossBuildableArea,
    text: "Hotel Gross Buildable",
    formatOptions: { type: Format.Type.Number },
    [Unit.System.Imperial]: {
      unitTarget: Unit.Type.SquareFeet,
      formatOptions: { suffix: " SF" },
    },
    [Unit.System.Metric]: {
      unitTarget: Unit.Type.SquareMeters,
      formatOptions: { suffix: " m²" },
    }
  },

  [VariableId.HotelRoomQuantityToggleable]: {
    variableId: VariableId.HotelRoomQuantityToggleable,
    buildingUse: BuildingUse.Hotel,
    text: "Number of Keys",
    formatOptions: {
      type: Format.Type.Number,
      suffix: " keys"
    }
  },

  [VariableId.HotelAverageRoomArea]: {
    variableId: VariableId.HotelAverageRoomArea,
    buildingUse: BuildingUse.Hotel,
    text: "Average Room Area",
    formatOptions: { type: Format.Type.Number },
    [Unit.System.Imperial]: {
      unitTarget: Unit.Type.SquareFeet,
      formatOptions: { suffix: " SF" },
    },
    [Unit.System.Metric]: {
      unitTarget: Unit.Type.SquareMeters,
      formatOptions: { suffix: " m²" },
    }
  },

  [VariableId.HotelLossFactor]: {
    variableId: VariableId.HotelLossFactor,
    buildingUse: BuildingUse.Hotel,
    text: "Loss Factor",
    formatOptions: { type: Format.Type.Percentage },
  },

  [VariableId.HotelAmenitiesAreaToggleable]: {
    variableId: VariableId.HotelAmenitiesAreaToggleable,
    buildingUse: BuildingUse.Hotel,
    text: "Amenities Area",
    formatOptions: { type: Format.Type.Number },
    [Unit.System.Imperial]: {
      unitTarget: Unit.Type.SquareFeet,
      formatOptions: { suffix: " SF" },
    },
    [Unit.System.Metric]: {
      unitTarget: Unit.Type.SquareMeters,
      formatOptions: { suffix: " m²" },
    }
  },

  [VariableId.HotelAnnualRevenueForBackOfEnvelope]: {
    variableId: VariableId.HotelAnnualRevenueForBackOfEnvelope,
    text: "Hotel Income",
    formatOptions: { type: Format.Type.Currency },
  },

  [VariableId.HotelStabilizedAverageDailyRate]: {
    variableId: VariableId.HotelStabilizedAverageDailyRate,
    buildingUse: BuildingUse.Hotel,
    text: "ADR (Average Daily Rate)",
    formatOptions: {
      type: Format.Type.Currency,
      suffix: "/key"
    }
  },

  [VariableId.HotelOtherRevenuePercentage]: {
    variableId: VariableId.HotelOtherRevenuePercentage,
    buildingUse: BuildingUse.Hotel,
    text: "Other Revenue (Percent of Hotel Income)",
    formatOptions: { type: Format.Type.Percentage },
  },

  [VariableId.HotelAnnualNetOperatingIncomeForBackOfEnvelope]: {
    variableId: VariableId.HotelAnnualNetOperatingIncomeForBackOfEnvelope,
    text: "Hotel NOI",
    formatOptions: { type: Format.Type.Currency },
  },

  [VariableId.HotelStabilizedOccupancyPercentage]: {
    variableId: VariableId.HotelStabilizedOccupancyPercentage,
    buildingUse: BuildingUse.Hotel,
    text: "Occupancy",
    formatOptions: { type: Format.Type.Percentage },
  },

  [VariableId.HotelOperatingExpensePercentage]: {
    variableId: VariableId.HotelOperatingExpensePercentage,
    buildingUse: BuildingUse.Hotel,
    text: "Expense Ratio",
    formatOptions: { type: Format.Type.Percentage },
  }
};

export default config;
