export enum System {
  Metric = "metric",
  Imperial = "imperial"
}

export enum Type {
  SquareMeters = "m2",
  SquareFeet = "ft2",
  KiloMeters = "km",
  Meters = "m",
  Feet = "ft",
  Hectares = "ha",
  Acres = "ac",
  Degrees = "deg",
  Radians = "rad",
}

type BaseUnitTypeMap = {
  [key in Type]: Type;
}

export const BASE_UNIT_TYPE_MAP: BaseUnitTypeMap = {
  [Type.SquareMeters]: Type.SquareMeters,
  [Type.SquareFeet]: Type.SquareMeters,
  [Type.Hectares]: Type.SquareMeters,
  [Type.Acres]: Type.SquareMeters,
  [Type.KiloMeters]: Type.Meters,
  [Type.Meters]: Type.Meters,
  [Type.Feet]: Type.Meters,
  [Type.Degrees]: Type.Radians,
  [Type.Radians]: Type.Radians,
}
