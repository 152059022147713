import React from "react";
import { connect } from "react-redux";
import { developmentSelectors } from "../../../../../state/development";
import { Panel } from "../../../../../types/uiElements";
import PanelHeader from "../sharedComponents/PanelHeader";
import SliderBox from "../sharedComponents/SliderBox";
import PanelSectionTitle from "../sharedComponents/PanelSectionTitle";
import config from "./config";
import ConfigurationHandler from "../../../../sharedComponents/ConfigurationHandler";

const mapStateToProps = (state) => {
  return {
    unitSystem: developmentSelectors.getUnitSystem(state),
    values: developmentSelectors.getValues(state),
  }
}

interface OwnProps {
  panel: Panel;
  onPanelClose(panel: Panel);
  onPanelPin(panel: Panel);
}

type StateProps = ReturnType<typeof mapStateToProps>;
type Props = OwnProps & StateProps;

class IndustrialPanel extends React.Component<Props, any>  {
  /**
   * Render Industrial Gross Buildable section.
   */
  renderGrossBuildableSection = () => {
    return (
      <>
        <ConfigurationHandler config={config.industrialGrossBuildableArea} component={PanelSectionTitle} unitSystem={this.props.unitSystem} />
        <div>
          <ConfigurationHandler config={config.industrialNetLeasableAreaToggleable} component={SliderBox} unitSystem={this.props.unitSystem} />
          {
            this.props.values.industrialNetLeasableAreaToggleable
              ? <ConfigurationHandler config={config.industrialLossFactor} component={SliderBox} unitSystem={this.props.unitSystem} />
              : null
          }
        </div>
      </>
    );
  }

  /**
   * Render Industrial Income section.
   */
  renderIncomeSection = () => {
    if (this.props.values.industrialNetLeasableAreaToggleable === 0) return null;

    return (
      <>
        <ConfigurationHandler config={config.industrialYearOnePotentialGrossIncome} component={PanelSectionTitle} unitSystem={this.props.unitSystem} />
        <div>
          <ConfigurationHandler config={config.industrialYearOneRentPerArea} component={SliderBox} unitSystem={this.props.unitSystem} />
          <ConfigurationHandler config={config.industrialYearOneExpenseReimbursementFeePerArea} component={SliderBox} unitSystem={this.props.unitSystem} />
        </div>
      </>
    );
  }

  /**
   * Render Industrial NOI section.
   */
  renderNOISection = () => {
    if (this.props.values.industrialNetLeasableAreaToggleable === 0) return null;

    return (
      <>
        <ConfigurationHandler config={config.industrialAnnualNetOperatingIncomeForBackOfEnvelope} component={PanelSectionTitle} unitSystem={this.props.unitSystem} />
        <div>
          <ConfigurationHandler config={config.industrialStabilizedVacancyPercentage} component={SliderBox} unitSystem={this.props.unitSystem} />
          <ConfigurationHandler config={config.industrialYearOneOperatingExpensePerArea} component={SliderBox} unitSystem={this.props.unitSystem} />
        </div>
      </>
    );
  }

  render() {
    return (
      <div className="panel">
        <PanelHeader
          isPinned={this.props.panel.isPinned}
          panel={this.props.panel}
          onPanelClose={this.props.onPanelClose}
          onPanelPin={this.props.onPanelPin}
        />
        <div className="panel-inner-wrapper">
          {this.renderGrossBuildableSection()}
          {this.renderIncomeSection()}
          {this.renderNOISection()}
        </div>
      </div>
    );
  }
}

export default connect(
  mapStateToProps
)(IndustrialPanel);
