import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { developmentSelectors } from "../../../../../../../state/development";
import { BuildingUse } from "../../../../../../../types/BuildingUse";
import authentication from "../../../../../../../utils/authentication";
import { getRentalUseText } from "../../utils/backOfEnvelopeHelper";
import Cell from "../../../../../../sharedComponents/Cell";
import CellRow from "../../../../../../sharedComponents/CellRow";
import CellsContainer from "../../../../../../sharedComponents/CellsContainer";
import ConfigurationHandler from "../../../../../../sharedComponents/ConfigurationHandler";
import config from "./config";

const mapStateToProps = (state) => {
  return {
    unitSystem: developmentSelectors.getUnitSystem(state),
    toggles: developmentSelectors.getBuildingUsageToggles(state),
  }
}

interface OwnProps {
  index: number;
  currentTab: number;
}

type StateProps = ReturnType<typeof mapStateToProps>;
type Props = OwnProps & StateProps;

class BackOfEnvelopeSummaryWorksheet extends PureComponent<Props, {}>  {
  /**
   * Render Income section.
   */
  renderIncomeSection = (userIsAuthenticated) => (
    <tbody data-tour="income-row">
      <CellRow>
        <Cell text="Income" styleVariation="border-below bold-17" />
        <Cell styleVariation="border-below bold-17" />
      </CellRow>
      <CellRow usageGroup={BuildingUse.Condo} >
        <ConfigurationHandler config={config.condoProfit} component={CellsContainer} unitSystem={this.props.unitSystem} hiddenValues={!userIsAuthenticated} isFromBackOfEnvelope />
      </CellRow>
      <CellRow usageGroup={BuildingUse.RentalUses} >
        <ConfigurationHandler config={config.rentalUsesAnnualNetOperatingIncomeForBackOfEnvelope} component={CellsContainer} textModifier={() => getRentalUseText(this.props.toggles)} unitSystem={this.props.unitSystem} hiddenValues={!userIsAuthenticated} isFromBackOfEnvelope />
      </CellRow>
      <CellRow usageGroup={BuildingUse.Hotel} >
        <ConfigurationHandler config={config.hotelAnnualNetOperatingIncomeForBackOfEnvelope} component={CellsContainer} unitSystem={this.props.unitSystem} hiddenValues={!userIsAuthenticated} isFromBackOfEnvelope />
      </CellRow>
      <CellRow
        classes="break"
      />
    </tbody>
  )

  /**
   * Render Cost section.
   */
  renderCostSection = (userIsAuthenticated) => (
    <tbody data-tour="cost-row">
      <CellRow>
        <Cell text="Cost" styleVariation="border-below bold-17" />
        <Cell styleVariation="border-below bold-17" />
      </CellRow>
      <CellRow usageGroup={BuildingUse.Condo} >
        <ConfigurationHandler config={config.condoTotalDevelopmentCostIncludingParking} component={CellsContainer} unitSystem={this.props.unitSystem} hiddenValues={!userIsAuthenticated} isFromBackOfEnvelope />
      </CellRow>
      <CellRow usageGroup={BuildingUse.RentalUses} >
        <ConfigurationHandler config={config.rentalUsesTotalDevelopmentCostIncludingParking} component={CellsContainer} textModifier={() => getRentalUseText(this.props.toggles)} unitSystem={this.props.unitSystem} hiddenValues={!userIsAuthenticated} isFromBackOfEnvelope />

      </CellRow>
      <CellRow usageGroup={BuildingUse.Hotel} >
        <ConfigurationHandler config={config.hotelTotalDevelopmentCostIncludingParking} component={CellsContainer} unitSystem={this.props.unitSystem} hiddenValues={!userIsAuthenticated} isFromBackOfEnvelope />
      </CellRow>
      <CellRow>
        <ConfigurationHandler config={config.projectTotalDevelopmentCost} component={CellsContainer} styleVariation="border-above bold-17" unitSystem={this.props.unitSystem} hiddenValues={!userIsAuthenticated} isFromBackOfEnvelope />
      </CellRow>
      <CellRow
        classes="break"
      />
    </tbody>
  )

  /**
   * Render Return On Cost section.
   */
  renderReturnOnCostSection = () => (
    <tbody data-tour="roc-row">
      <CellRow>
        <Cell text="Return on Cost" styleVariation="border-below bold-17" />
        <Cell styleVariation="border-below bold-17" />
      </CellRow>
      <CellRow usageGroup={BuildingUse.Condo} >
        <ConfigurationHandler config={config.condoReturnOnCost} component={CellsContainer} styleVariation="bold-17" unitSystem={this.props.unitSystem} />
      </CellRow>
      <CellRow usageGroup={BuildingUse.RentalUses}>
        <ConfigurationHandler config={config.rentalUsesAnnualReturnOnCostForBackOfEnvelope} component={CellsContainer} textModifier={() => getRentalUseText(this.props.toggles)} styleVariation="bold-17" unitSystem={this.props.unitSystem} />
      </CellRow>
      <CellRow usageGroup={BuildingUse.Hotel}>
        <ConfigurationHandler config={config.hotelAnnualReturnOnCostForBackOfEnvelope} component={CellsContainer} styleVariation="bold-17" unitSystem={this.props.unitSystem} />
      </CellRow>
    </tbody>
  )

  render() {
    if (!this.props.currentTab || this.props.index === this.props.currentTab) {
      const userIsAuthenticated = authentication.isUserAuthenticated();

      return (
        <table>
          {this.renderIncomeSection(userIsAuthenticated)}
          {this.renderCostSection(userIsAuthenticated)}
          {this.renderReturnOnCostSection()}
        </table>
      );
    }

    return null;
  }
}

export default connect(
  mapStateToProps
)(BackOfEnvelopeSummaryWorksheet);
