import React from "react"
import authentication from "../../../../../utils/authentication";
import ActivityFeedback from "../../../../sharedComponents/ActivityFeedback";

enum Status {
  Initial = "INITIAL",
  ResendingEmail = "RESENDING_EMAIL",
  EmailSent = "EMAIL_SENT",
  VerifyingEmail = "VERIFYING_EMAIL",
  VerifyEmailFail = "VERIFY_EMAIL_FAIL"
}

interface OwnProps {
  onEmailVerified();
}

type Props = OwnProps;

interface State {
  status: Status;
};

const FEEDBACK_TEXT = {
  resendEmail: {
    sending: "Sending email...",
    success: "Email has been resent."
  },
  checkEmailVerification: {
    verifying: "Verifying your email...",
    failure: "Your email is not verified yet.",
  },
}

const SPINNING_TIME = 1000 // 1 second.
const ANSWER_TIME = 2000 // 2 second.

export class VerifyEmail extends React.PureComponent<Props, State> {
  user: any;

  constructor(props) {
    super(props);

    this.state = {
      status: Status.Initial,
    }

    this.user = authentication.getCurrentUser();
  }

  /**
   * Send a verification email to the user.
   */
  sendEmailVerification = () => {
    authentication.sendEmailVerification();
  }

  /**
   * Verify the user's email and manage the state changes to provide user with feedback.
   */
  checkEmailVerification = async () => {
    const userEmailIsVerified = await authentication.userEmailIsVerified();
    if (userEmailIsVerified) {
      this.props.onEmailVerified();
    } else {
      this.setState({ status: Status.VerifyingEmail })
      setTimeout(
        () => {
          this.setState({ status: Status.VerifyEmailFail });
          setTimeout(
            () => {
              this.setState({ status: Status.Initial });
            }, ANSWER_TIME
          );
        }, SPINNING_TIME
      );
    }
  }

  /**
   * Resend a verification email to the user and manage the state changes to provide user with feedback.
   */
  resendEmailVerification = () => {
    this.setState({ status: Status.ResendingEmail })
    this.sendEmailVerification();
    setTimeout(
      () => {
        this.setState({ status: Status.EmailSent });
        setTimeout(
          () => {
            this.setState({ status: Status.Initial })
          }, ANSWER_TIME
        );
      }, SPINNING_TIME
    );
  }

  /**
   * Sign out.
   */
  logOut = () => {
    authentication.signOut();
  }

  render() {
    switch (this.state.status) {
      case Status.Initial:
        return (
          <div className="component-verify-email">
            <div className="icon" />
            <div className="content-wrapper">
              <div className="text-box">
                We have sent an email with instructions to <br />
                <span className="bold">{this.user.email}</span>.
              </div>
              <button className="submit" onClick={this.checkEmailVerification}>
                Next
              </button>
            </div>
            <div className="footer">
              <div className="text-box">
                Wrong Email? <span className="underlined-link" onClick={this.logOut}>Go Back</span>
              </div>
              <div className="text-box">
                Didn’t get the email? <span className="underlined-link" onClick={this.resendEmailVerification}>Resend now</span>
              </div>
            </div>
          </div>
        );
      case Status.ResendingEmail:
        return <ActivityFeedback message={FEEDBACK_TEXT.resendEmail.sending} displaySpinner />;
      case Status.EmailSent:
        return <ActivityFeedback message={FEEDBACK_TEXT.resendEmail.success} />;
      case Status.VerifyingEmail:
        return <ActivityFeedback message={FEEDBACK_TEXT.checkEmailVerification.verifying} displaySpinner />;
      case Status.VerifyEmailFail:
        return <ActivityFeedback message={FEEDBACK_TEXT.checkEmailVerification.failure} />;
      default:
        return null;
    }
  }
}

export default VerifyEmail;
