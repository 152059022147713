import geometry from "../geometry";
import { ParcelProperty } from "./ParcelProperty";

/**
 * Assembles parcel from array of parcels.
 */
const assembleParcels = (assemblyParcels) => {
  if (assemblyParcels.length === 0) return null;
  if (assemblyParcels.length === 1) return assemblyParcels[0];

  let { featuresUnion, bufferedFeatures } = geometry.featuresUnionWithBuffering(assemblyParcels);
  featuresUnion.properties = {
    [ParcelProperty.AssemblyParcels]: bufferedFeatures,
    [ParcelProperty.IsAnAssembly]: true,
  };

  return featuresUnion;
}

export default assembleParcels;
