import React from "react";
import PdfContext from "../../../PdfContext";
import ContactDetails from "../../sharedComponents/ContactDetails";

const FromContactDetails = () => (
  <PdfContext.Consumer>
    {({ colorPalette, fromContactDetails }) => (
      <ContactDetails
        contactDetails={fromContactDetails}
        label="Prepared by:"
        textColor={colorPalette.text}
        labelColor={colorPalette.secondary}
        fontSize={10}
      />
    )}
  </PdfContext.Consumer>
);

export default FromContactDetails;
