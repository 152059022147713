import React from "react";
import { connect } from "react-redux";
import { Redirect, RouteComponentProps } from "react-router";
import { withRouter } from "react-router-dom";
import { developmentActions, developmentSelectors } from "../../../state/development";
import analytics from "../../../utils/analytics";
import { allTogglesDisabled, buildingUsageIsEnabled } from "../../utils/uiToggleHelper";
import database from "../../../utils/database/database";
import { Panel } from "../../../types/uiElements";
import ExplorerToolbar from "./ExplorerToolbar";
import ExplorerSidebar from "./ExplorerSidebar";
import Map from "./Map";
import SetbackSelectionMap from "./SetbackSelectionMap";
import Panels from "./Panels";
import ThumbnailMap from "./ThumbnailMap";
import PdfGenerator from "../../sharedComponents/PdfGenerator";
import { Path } from "../../../types/Path";
import ReturnOnCost from "../../sharedComponents/ReturnOnCost";
import developmentAccessors from "../../../state/development/utils/developmentAccessors";
import { VariableId } from "../../../types/VariableId";
import { pdfActions } from "../../../state/pdf";
import panelsConfig from "../utils/configFiles/panelsConfig";
import KeepProject from "./KeepProject";
import { accordionSectionActions } from "../../../state/ui/shared/accordionSection";

const mapStateToProps = (state) => {
  let toggleValues = developmentSelectors.getBuildingUsageToggles(state);
  let development = developmentSelectors.getDevelopment(state);

  return {
    toggles: toggleValues,
    allTogglesDisabled: allTogglesDisabled(toggleValues),
    setbackMode: developmentSelectors.getValue(state, "setbackMode"),
    values: developmentAccessors.getValues(development),
    buildingModel: developmentSelectors.getBuildingModel(state),
  };
};

const mapDispatchToProps = {
  initializeDevelopment: developmentActions.initialize,
  clearPdfState: pdfActions.clearState,
  resetDevelopmentState: developmentActions.resetState,
  resetProjectInfoPanelUi: accordionSectionActions.reset,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;
type RouterProps = RouteComponentProps<{ projectId: string }>;
type Props = StateProps & DispatchProps & RouterProps;

interface State {
  initialized: boolean;
  panels: Array<Panel>;
  redirectToDashboardPage: boolean;
  setbackMode: boolean;
  alertIsOpen: boolean;
}

class ExplorerPage extends React.Component<Props, State>  {
  project: any;

  constructor(props) {
    super(props);

    this.state = {
      initialized: false,
      panels: [panelsConfig.projectInfoPanel],
      redirectToDashboardPage: false,
      setbackMode: false,
      alertIsOpen: true,
    };

    analytics.trackPageview(Path.Explorer);
  }

  /**
   * Update component on url change.
   */
  componentDidUpdate(previousProps: Props) {
    if (previousProps.match.params.projectId !== this.props.match.params.projectId) {
      this.reinitializeExplorer();
    }

    const buildingWasReduced = this.getBuildingIsReduced(previousProps);
    const buildingIsReduced = this.getBuildingIsReduced(this.props);
    if (!buildingWasReduced && buildingIsReduced) this.setState({ alertIsOpen: true });
  }

  /**
   * Ensure this.initialize is called once the project is loaded from the
   * database.
   */
  componentDidMount() {
    if (!database.isInitialized()) {
      database.onInitialized(this.initialize);
    } else {
      this.initialize();
    }
  }

  /**
   * Ensure this.initialize is not called after this component has been
   * unmounted.
   */
  componentWillUnmount() {
    database.removeOnInitializedCallback(this.initialize);
    this.props.clearPdfState();
    this.props.resetDevelopmentState();
    this.props.resetProjectInfoPanelUi();
  }

  /**
   * Initialize the Explorer. This function should be called once the database has loaded.
   */
  initialize = async () => {
    let projectId = this.props.match.params.projectId;
    const result = await database.loadProject(projectId);
    this.project = result.projectData;

    if (projectId !== result.newProjectId) {
      projectId = result.newProjectId;
      this.props.history.replace(`${Path.Explorer}/${projectId}`);
    }

    if (this.project) {
      this.props.initializeDevelopment(this.project, projectId);
      this.setState({ initialized: true });
    } else {
      this.setState({ redirectToDashboardPage: true });
    }
  }

  /**
   * The list of currently enabled panels. If all of the building usages are
   * currently turned off, returns only those panels that do not require an
   * active building usage.
   */
  enabledPanels() {
    return this.props.allTogglesDisabled
      ? this.state.panels.filter((panel) => !panel.requireUsages)
      : this.state.panels.filter((panel) => buildingUsageIsEnabled(this.props.toggles, panel.usageGroup));
  }

  /**
   * This function handles the pinning/unpinning logic for the panels.
   * Filters out the panels that are not pinned except if it's the first panel in the list,
   * in which case it will become the active panel.
   */
  handlePanelPin = (pinnedPanel: Panel) => {
    let panels = [...this.state.panels];

    panels.forEach((panel, index) => {
      if (panel.title === pinnedPanel.title) panels[index] = { ...panel, isPinned: !pinnedPanel.isPinned };
    });

    panels = panels.filter((panel, index) => index === 0 || panel.isPinned);

    this.setState({ panels });
  }

  /**
   * This function populates the panels array.
   */
  handlePanelToggle = (subjectPanel: Panel) => {
    let panels = this.state.panels.filter((panel) => buildingUsageIsEnabled(this.props.toggles, panel.usageGroup));
    subjectPanel.isPinned = false;

    if (this.state.panels.some((panel) => panel.title === subjectPanel.title)) {
      // Remove panel from list if list contains it already
      panels = panels.filter((panel) => panel.title !== subjectPanel.title);
      analytics.trackClosePanel(subjectPanel.title);
    } else {
      if (subjectPanel.closeOtherPanels) {
        // Remove all other panels if subjectPanel has closeOtherPanels flag set
        panels = [subjectPanel];
      } else if (panels.length > 0) {
        // This conditional handles the case when there are panels in the list already
        // and checks whether to replace the first one or push the pinned panels
        if (panels[0].isPinned) {
          panels.unshift(subjectPanel);
        } else {
          panels[0] = subjectPanel;
        }
      } else {
        // In this case, there are no panels in the list, thus just adding it to the list
        panels.unshift(subjectPanel);
      }
      analytics.trackOpenPanel(subjectPanel.title);
    }

    // Filter out the panels that require usages in case all toggles are disabled.
    if (this.props.allTogglesDisabled) {
      panels = panels.filter((panel) => !panel.requireUsages);
    }

    this.setState({ panels });
  };

  /**
   * Close setback mode if open and re-initialize the explorer. This is important for switching projects.
   */
  reinitializeExplorer = async () => {
    this.setState({ setbackMode: false });
    this.initialize();
  }

  /**
   * Conditionally render the setback selection map or the project map.
   */
  renderMap = () => {
    return this.state.setbackMode
      ? <SetbackSelectionMap toggleSetbackSelectionMode={this.toggleSetbackSelectionMode} />
      : <Map camera={this.project.camera} />
  }

  /**
   * Toggle the setback selection mode.
   */
  toggleSetbackSelectionMode = () => {
    analytics.trackChangeToggle("setbackMode", !this.state.setbackMode);
    this.setState({ setbackMode: !this.state.setbackMode });
  }

  /**
   * Get the building is reduced flag.
   */
  getBuildingIsReduced = (props: Props) => {
    if (!props.buildingModel) return false;

    const buildableArea = props.values[VariableId.ProjectGrossBuildableArea];
    const buildingArea = props.buildingModel.floors.reduce((total, floor) => {
      return total + floor.footprint.area;
    }, 0);

    return (Math.floor(buildingArea) < Math.floor(buildableArea));
  }

  /**
   * Render the building reduced alert.
   */
  renderBuildingReducedAlert = () => {
    if (!this.state.alertIsOpen) return null;

    const buildingIsReduced = this.getBuildingIsReduced(this.props);
    if (!buildingIsReduced) return null;

    return (
      <div className="building-alert">
        <div className="icon-info" />
        Your setbacks are too big, or your building is too tall. Please reduce setback values or building areas.
        <div className="icon-close" onClick={() => this.setState({ alertIsOpen: false })} />
      </div>
    );
  }

  render() {
    if (this.state.redirectToDashboardPage) return <Redirect to={Path.Dashboard} />;
    if (!this.state.initialized) return null;
    const { values } = this.props;
    const condoReturnOnCost = values[VariableId.CondoReturnOnCost];
    const leaseUsesReturnOnCost = values[VariableId.IncomeProducingUsesAnnualReturnOnCostForBackOfEnvelope];

    return (
      <div className="component--explorer-page">
        <ExplorerToolbar onReinitializeProject={this.reinitializeExplorer} />

        <ExplorerSidebar
          panels={this.enabledPanels()}
          onPanelToggle={this.handlePanelToggle}
        />

        <div className="explorer-content">
          <Panels
            panels={this.enabledPanels()}
            toggleSetbackSelectionMode={this.toggleSetbackSelectionMode}
            setbackMode={this.state.setbackMode}
            onPanelToggle={this.handlePanelToggle}
            onPanelPin={this.handlePanelPin}
          />

          {this.renderMap()}
          <ReturnOnCost condoReturnOnCost={condoReturnOnCost} leaseUsesReturnOnCost={leaseUsesReturnOnCost} isActive={!this.state.setbackMode} />
          {!this.state.setbackMode && <KeepProject />}
        </div>

        <PdfGenerator />
        <ThumbnailMap />
        {this.renderBuildingReducedAlert()}
      </div>
    );
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ExplorerPage)
);
