/**
 * Set empty string chargebeeCostumerId to null.
 */
const modifyEmptyChargebeeCustomerId = (user) => {
  if (user.chargebeeCustomerId === ""){
    const chargebeeCustomerId: string | null = null;
    user.chargebeeCustomerId = chargebeeCustomerId;
  }
}

export default modifyEmptyChargebeeCustomerId;
