import React from "react";
import { connect } from "react-redux";
import { newDevelopmentSelectors } from "../../../../../state/newDevelopment";
import { ParcelTool } from "../../../../../types/ParcelTool";
import Unit from "../../../../../types/Unit";
import Format from "../../../../../types/Format";
import valueFormatter from "../../../../../utils/valueFormatter";
import unitConversions from "../../../../../utils/unitConversions";

const mapStateToProps = (state) => {
  return {
    parcelToolFromToolbar: newDevelopmentSelectors.getParcelToolFromToolbar(state),
    polygonPerimeter: newDevelopmentSelectors.getPolygonPerimeter(state),
    polygonArea: newDevelopmentSelectors.getPolygonArea(state),
    unitSystem: newDevelopmentSelectors.getUnitSystem(state),
  }
}

type StateProps = ReturnType<typeof mapStateToProps>;
type Props = StateProps;

class DrawingInformation extends React.PureComponent<Props, {}> {
  /**
   * Convert, format and return polygon area.
   */
  getArea = () => {
    if (!this.props.polygonArea) return null;
    let [unitTarget, suffix] = this.props.unitSystem === Unit.System.Metric ? [Unit.Type.SquareMeters, " m²"] : [Unit.Type.SquareFeet, " ft²"];
    let formatOptions = { type: Format.Type.Number, suffix: suffix };

    return this.convertAndFormatValue(this.props.polygonArea, unitTarget, formatOptions);
  }

  /**
   * Convert, format and return polygon perimeter.
   */
  getPerimeter = () => {
    if (!this.props.polygonPerimeter) return null;
    let [unitTarget, suffix] = this.props.unitSystem === Unit.System.Metric ? [Unit.Type.Meters, " m"] : [Unit.Type.Feet, " ft"];
    let formatOptions = { type: Format.Type.Number, suffix: suffix };

    return this.convertAndFormatValue(this.props.polygonPerimeter, unitTarget, formatOptions);
  }

  /**
   * Convert and format value.
   */
  convertAndFormatValue = (value, unitTarget, formatOptions: Format.Options) => {
    let convertedValue = unitConversions.convertFromBase(value, unitTarget);

    return valueFormatter.format(convertedValue, formatOptions);
  }

  render() {
    if (this.props.parcelToolFromToolbar !== ParcelTool.DrawParcel && this.props.parcelToolFromToolbar !== ParcelTool.EditParcel) {
      return null;
    }

    return (
      <div className="component--drawing-information">
        <div className="header">
          <span>Info</span>
        </div>
        <div className="section">
          <div className="section-header">Perimeter:</div>
          <div className="section-body">
            {this.getPerimeter() || "-"}
          </div>
        </div>
        <div className="section">
          <div className="section-header">Area:</div>
          <div className="section-body">
            {this.getArea() || "-"}
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
)(DrawingInformation);
