import React from "react";
import { connect } from "react-redux";
import { pdfSelectors, pdfActions } from "../../../state/pdf";
import PdfViewer from "./PdfViewer";
import MapParcel from "./MapParcel";
import MapLocation from "./MapLocation";
import MapBuilding from "./MapBuilding";
import registerFonts from "./utils/registerFonts";
import { developmentSelectors } from "../../../state/development";
import BusyPopup from "../BusyPopup";
import CloseBackground from "./CloseBackground";
import MapCover from "./MapCover";

// Register fonts to be used on the PDF document.
registerFonts();

const mapStateToProps = (state) => {
  return {
    pdfIsReady: pdfSelectors.getIsReady(state),
    pdfIsActive: pdfSelectors.getIsActive(state),
    development: developmentSelectors.getDevelopment(state),
    unitSystem: developmentSelectors.getUnitSystem(state),
    mapImages: pdfSelectors.getMapImages(state),
    title: pdfSelectors.getTitle(state),
    summary: pdfSelectors.getSummary(state),
    coverImages: pdfSelectors.getCoverPageImages(state),
    toContactDetails: pdfSelectors.getToContactDetails(state),
    fromContactDetails: pdfSelectors.getFromContactDetails(state),
    colorPalette: pdfSelectors.getColorPalette(state),
    address: pdfSelectors.getAddress(state),
  };
};

const mapDispatchToProps = {
  finishPdf: pdfActions.finish,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;
type Props = StateProps & DispatchProps;

interface State {
  pdfDocumentIsReady: boolean;
}

class PdfGenerator extends React.PureComponent<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      pdfDocumentIsReady: false
    }
  }

  /**
   * Set the pdfDocumentIsReady state to true.
   */
  setPdfDocumentIsReady = () => {
    this.setState({ pdfDocumentIsReady: true });
  }

  /**
   * Set the pdfDocumentIsReady state to false.
   */
  clearPdfDocumentIsReady = () => {
    this.setState({ pdfDocumentIsReady: false });
  }

  /**
   * Close the PDF.
   */
  closePdf = () => {
    this.clearPdfDocumentIsReady();
    this.props.finishPdf();
  }

  /**
   * Render the pdf viewer.
   */
  renderPdfViewer = () => (
    <PdfViewer
      onPdfRender={this.setPdfDocumentIsReady}
      className="pdf-viewer"
      onStateChange={this.closePdf}
      state={{
        development: this.props.development,
        mapImages: this.props.mapImages || {},
        unitSystem: this.props.unitSystem,
        colorPalette: this.props.colorPalette,
        title: this.props.title,
        summary: this.props.summary,
        coverImages: this.props.coverImages,
        toContactDetails: this.props.toContactDetails,
        fromContactDetails: this.props.fromContactDetails,
        address: this.props.address,
      }}
    />
  );

  /**
   * Render the maps.
   */
  renderMaps = () => (
    <div className="component--pdf-generator">
      <MapCover />
      <MapParcel />
      <MapLocation />
      <MapBuilding />
    </div>
  );

  render() {
    if (!this.props.pdfIsActive) return null;

    return (
      <>
        {this.props.pdfIsReady ? this.renderPdfViewer() : this.renderMaps()}
        {this.state.pdfDocumentIsReady
          ? <CloseBackground onStateChange={this.closePdf} />
          : <BusyPopup text="Generating Property Report..." preventClose />}
      </>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PdfGenerator);
