import React from "react";
import styled from "@react-pdf/styled-components";
import logo from "../../../../../../../assets/images/branding-watermark.png";

const Container = styled.View`
  position: absolute;
  bottom: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
`;

const LogoImage = styled.Image`
  width: 67px;
`;

const Logo = () => (
  <Container>
    <LogoImage src={logo} />
  </Container>
);

export default Logo;
