import Pdf from "../../../types/Pdf";
import { colorFor } from "../../../views/utils/colors";

/**
 * Return default pdf document with optional data.
 */
const getPdfDocument = (pdfDocument?: Pdf.Document) => {
  return {
    colorPalette: {
      primary: colorFor("pdf-primary-default"),
      secondary: colorFor("pdf-secondary-default"),
      text: colorFor("pdf-text-default"),
    },
    title: "",
    summary: "",
    fromContactDetails: {
      name: "",
      companyName: "",
      phoneNumber: "",
      email: "",
    },
    toContactDetails: {
      name: "",
      companyName: "",
      phoneNumber: "",
      email: "",
    },
    coverPageImages: {
      [Pdf.ImageType.Cover]: null,
      [Pdf.ImageType.Logo]: null,
    },
    address: {
      address: "",
      city: "",
      state: "",
      zipCode: "",
    },
    ...pdfDocument,
  }
}

export default getPdfDocument;
