import log from "loglevel";
import parcelAccessors from "../../utils/parcel/parcelAccessors";
import { GeoJSON } from "geojson";
import { KeyCode } from "../../types/KeyCodes";
import { ParcelTool } from "../../types/ParcelTool";
import actionTypes from "./actionTypes";
import assembleParcels from "../../utils/parcel/assembleParcels";
import geometry from "../../utils/geometry";
import { MapStyleProperties } from "../../utils/mapbox/mapStyleProperties";
import turf from "../../utils/turf";
import Unit from "../../types/Unit";
import { Filters, FilterId } from "../../types/Filter";
import filterHelper from "../../utils/filterHelper";
import { VariableId } from "../../types/VariableId";
import { Values } from "../../types/Development/Values";
import { GeocodePlaceType } from "../../types/Mapbox/Mapbox";
import { listingsActionsTypes } from "../listings";

export interface NewDevelopment {
  searchAddress: string;
  suggestedFeaturePlaceType: Array<GeocodePlaceType>;
  hoveredFeature: any;
  selectedFeature: any;
  selectedFeatureMembers: { [featureId: number]: GeoJSON };
  drawnParcels: Array<GeoJSON>;
  suggestedFeatures: Array<any>;
  suggestedFeaturesSelectionIndex: number;
  userIsTyping: boolean;
  geocoderIsQuerying: boolean;
  geocoderProximityCenter: [number, number] | null;
  pinPosition?: [number, number] | null;
  displayPin?: boolean;
  parcelTool: ParcelTool;
  parcelToolFromToolbar: ParcelTool;
  mapIsReady: boolean;
  polygonIsBeingChanged: boolean;
  polygonArea: number;
  polygonPerimeter: number;
  unitSystem: Unit.System;
  smartSearchIsOpen: boolean;
  drawParcelInformationPanelIsOpen: boolean;
  parcelsInViewport: Array<any>;
  smartSearchResult: Array<any>;
  filters: Filters;
  initialValues: { [key in VariableId]?: Values[key] };
  parcelDataInViewport: boolean | null;
  zoningDataInViewport: boolean | null;
}

const DEFAULT_INPUT_VALUE = "";
const DEFAULT_FAR_VALUE = 6;

const INITIAL_STATE: NewDevelopment = {
  searchAddress: DEFAULT_INPUT_VALUE,
  suggestedFeaturePlaceType: [],
  hoveredFeature: null,
  selectedFeature: null,
  selectedFeatureMembers: {},
  drawnParcels: [],
  suggestedFeatures: [],
  suggestedFeaturesSelectionIndex: -1,
  userIsTyping: false,
  geocoderIsQuerying: false,
  geocoderProximityCenter: null,
  parcelTool: ParcelTool.SelectParcel,
  parcelToolFromToolbar: ParcelTool.SelectParcel,
  mapIsReady: false,
  polygonIsBeingChanged: false,
  polygonArea: 0,
  polygonPerimeter: 0,
  unitSystem: Unit.System.Imperial,
  smartSearchIsOpen: false,
  drawParcelInformationPanelIsOpen: false,
  parcelsInViewport: [],
  smartSearchResult: [],
  filters: filterHelper.initializeFilters(),
  initialValues: {
    floorAreaRatio: DEFAULT_FAR_VALUE,
  },
  parcelDataInViewport: null,
  zoningDataInViewport: null,
};

const reducer = (previousState = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.INITIALIZE: return initialize(previousState);
    case actionTypes.ADDRESS_KEYSTROKE_AND_FORWARD_GEOCODE_START: return addressKeystrokeAndForwardGeocodeStart(previousState, action.payload);
    case actionTypes.ADDRESS_SUBMIT: return addressSubmit(previousState, action.payload);
    case listingsActionsTypes.SET_SELECTED_LISTING:
    case actionTypes.CLEAR_FEATURE_SELECTION: return clearFeatureSelection(previousState, action.payload);
    case actionTypes.FORWARD_GEOCODE_SUCCESS: return forwardGeocodeSuccess(previousState, action.payload);
    case actionTypes.GEOCODE_ERROR: return geocodeError(previousState, action.payload);
    case actionTypes.HOVER_FEATURE: return hoverFeature(previousState, action.payload);
    case actionTypes.SELECT_PARCEL_SUCCESS: return selectParcelSuccess(previousState, action.payload);
    case actionTypes.COMBINE_PARCELS_SUCCESS: return combineParcelsSuccess(previousState, action.payload);
    case actionTypes.SET_PROXIMITY_CENTER: return setProximityCenter(previousState, action.payload);
    case actionTypes.SUGGESTED_FEATURE_NEXT: return suggestedFeatureNext(previousState, action.payload);
    case actionTypes.SUGGESTED_FEATURE_PREVIOUS: return suggestedFeaturePrevious(previousState, action.payload);
    case actionTypes.SET_POLYGON_MEASUREMENTS: return setPolygonMeasurements(previousState, action.payload);
    case actionTypes.RESET_POLYGON_MEASUREMENTS: return resetPolygonMeasurements(previousState, action.payload);
    case actionTypes.SET_POLYGON_IS_BEING_CHANGED: return setPolygonIsBeingChanged(previousState, action.payload);
    case actionTypes.SET_PARCEL_TOOL: return setParcelTool(previousState, action.payload);
    case actionTypes.SET_MAP_IS_READY: return setMapIsReady(previousState, action.payload);
    case actionTypes.MODIFIER_KEY_DOWN: return modifierKeyDown(previousState, action.payload);
    case actionTypes.MODIFIER_KEY_UP: return modifierKeyUp(previousState, action.payload);
    case actionTypes.SET_DRAWN_PARCELS: return setDrawnParcels(previousState, action.payload);
    case actionTypes.SET_UNIT_SYSTEM: return setUnitSystem(previousState, action.payload);
    case actionTypes.SET_SMART_SEARCH_IS_OPEN: return setSmartSearchIsOpen(previousState, action.payload);
    case actionTypes.SET_DISPLAY_PIN: return setDisplayPin(previousState, action.payload);
    case actionTypes.SET_PARCELS_IN_VIEWPORT: return setParcelsInViewPort(previousState, action.payload);
    case actionTypes.UPDATE_FILTER: return updateFilter(previousState, action.payload);
    case actionTypes.UPDATE_INITIAL_VALUES: return updateInitialValues(previousState, action.payload);
    case actionTypes.SET_DATA_IN_VIEWPORT: return setDataInViewport(previousState, action.payload);
    case actionTypes.SET_DEFAULT_PANEL: return setDefaultPanel(previousState, action.payload);
    case actionTypes.SET_DRAW_PARCEL_INFORMATION_PANEL_IS_OPEN: return setDrawParcelInformationPanelIsOpen(previousState, action.payload);
    default: return previousState;
  }
}

/**
 * See `initialize` action creator.
 *
 * Returns initial state with default values.
 */
const initialize = (previousState: NewDevelopment): NewDevelopment => {
  return {
    ...INITIAL_STATE,
    drawnParcels: previousState.drawnParcels,
    filters: previousState.filters,
    pinPosition: previousState.pinPosition,
    displayPin: previousState.displayPin,
    suggestedFeaturePlaceType: previousState.suggestedFeaturePlaceType,
  };
}

/**
 * See `addressKeystrokeAndForwardGeocodeStart` action creator.
 *
 * Returns a new state object with the input value updated.
 */
const addressKeystrokeAndForwardGeocodeStart = (previousState: NewDevelopment, payload): NewDevelopment => {
  // TODO: Clean up handling of minimum address length.
  // See https://deepblocks.tpondemand.com/entity/4076-clean-up-handling-of-minimum-address
  return {
    ...previousState,
    searchAddress: payload.searchAddress,
    userIsTyping: true,
    pinPosition: null,
    suggestedFeaturesSelectionIndex: -1,
    geocoderIsQuerying: payload.geocoderIsQuerying
  }
}

/**
 * See `addressSubmit` action creator.
 *
 * Returns a new state with the properties set to move to the suggested feature.
 */
const addressSubmit = (previousState: NewDevelopment, payload): NewDevelopment => {
  if (previousState.geocoderIsQuerying) {
    return previousState;
  }

  return {
    ...previousState,
    pinPosition: payload.suggestedFeature.center,
    displayPin: true,
    searchAddress: payload.suggestedFeature.place_name,
    suggestedFeaturePlaceType: payload.suggestedFeature.place_type || INITIAL_STATE.suggestedFeaturePlaceType,
    selectedFeature: null,
    selectedFeatureMembers: {},
    userIsTyping: false,
  }
}

/**
 * See `forwardGeocodeSuccess` action creator.
 *
 * Returns a new state object with suggestion list updated.
 */
const forwardGeocodeSuccess = (previousState: NewDevelopment, payload): NewDevelopment => {
  let newState = {
    suggestedFeatures: [],
    geocoderIsQuerying: false
  };

  if (payload.results) {
    newState.suggestedFeatures = payload.results.features;
  }

  return {
    ...previousState,
    ...newState
  }
}

/**
 * See `geocodeError` action creator.
 *
 * Returns the same state object after logging the error.
 */
const geocodeError = (previousState: NewDevelopment, payload): NewDevelopment => {
  log.error("Error:", payload.error);
  return previousState;
}

/**
 * See `hoverFeature` action creator
 *
 * Returns a new state object with the hovered feature updated.
 */
const hoverFeature = (previousState: NewDevelopment, payload): NewDevelopment => {
  return {
    ...previousState,
    hoveredFeature: payload.hoveredFeature
  }
}

/**
 * See `clearFeatureSelection` action creator.
 *
 * Returns a new state with the parcel search, hover and selection properties reset
 * to their initial values.
 */
const clearFeatureSelection = (previousState: NewDevelopment, payload): NewDevelopment => {
  return {
    ...previousState,
    selectedFeatureMembers: {},
    selectedFeature: INITIAL_STATE.selectedFeature,
    hoveredFeature: INITIAL_STATE.hoveredFeature,
    searchAddress: INITIAL_STATE.searchAddress,
    suggestedFeatures: INITIAL_STATE.suggestedFeatures,
    suggestedFeaturesSelectionIndex: INITIAL_STATE.suggestedFeaturesSelectionIndex,
    userIsTyping: INITIAL_STATE.userIsTyping,
    initialValues: INITIAL_STATE.initialValues,
  }
}

/**
 * See `selectParcelSuccess` action creator.
 *
 * Returns a new state object with the selected feature updated.
 */
const selectParcelSuccess = (previousState: NewDevelopment, payload): NewDevelopment => {
  let selectedParcel = payload.selectedParcel;
  let geocodedFeature = payload.geocodedFeature;

  let selectedFeatureMembers = {};
  const parcelId = parcelAccessors.getParcelId(selectedParcel);
  if (parcelId) {
    selectedFeatureMembers[parcelId] = selectedParcel;
  }

  const floorAreaRatio = parcelAccessors.getMinimumFloorAreaRatio(selectedParcel);

  return {
    ...previousState,
    searchAddress: geocodedFeature && geocodedFeature.place_name,
    suggestedFeaturePlaceType: INITIAL_STATE.suggestedFeaturePlaceType,
    selectedFeature: selectedParcel,
    selectedFeatureMembers: selectedFeatureMembers,
    userIsTyping: false,
    pinPosition: null,
    displayPin: false,
    suggestedFeaturesSelectionIndex: -1,
    smartSearchIsOpen: false,
    drawParcelInformationPanelIsOpen: false,
    parcelsInViewport: [],
    initialValues: {
      ...previousState.initialValues,
      floorAreaRatio: floorAreaRatio || DEFAULT_FAR_VALUE,
    }
  };
}

/**
 * See `combineParcelsSuccess` action creator.
 *
 * Returns a new state object with the selected feature updated.
 */
const combineParcelsSuccess = (previousState: NewDevelopment, payload): NewDevelopment => {
  let geocodedFeature = payload.geocodedFeature;

  // Adjust the array of selected features.
  const clickedParcel = payload.clickedParcel;
  let selectedFeatureMembers = { ...previousState.selectedFeatureMembers };
  let parcelId = parcelAccessors.getParcelId(clickedParcel);
  if (parcelId && selectedFeatureMembers[parcelId]) {
    delete selectedFeatureMembers[parcelId];
  } else if (parcelId) {
    selectedFeatureMembers[parcelId] = clickedParcel;
  }

  const selectedFeature = assembleParcels(Object.values(selectedFeatureMembers));

  const floorAreaRatio = parcelAccessors.getMinimumFloorAreaRatio(selectedFeature);

  return {
    ...previousState,
    suggestedFeaturePlaceType: INITIAL_STATE.suggestedFeaturePlaceType,
    searchAddress: geocodedFeature && geocodedFeature.place_name,
    selectedFeature: selectedFeature,
    selectedFeatureMembers: selectedFeatureMembers,
    userIsTyping: false,
    pinPosition: null,
    displayPin: false,
    suggestedFeaturesSelectionIndex: -1,
    smartSearchIsOpen: false,
    parcelsInViewport: [],
    initialValues: {
      ...previousState.initialValues,
      floorAreaRatio: floorAreaRatio || DEFAULT_FAR_VALUE,
    }
  };
}

/**
 * See `setProximityCenter` action creator.
 *
 * Returns a new state object with the geocoderProximityCenter updated.
 */
const setProximityCenter = (previousState: NewDevelopment, payload): NewDevelopment => {
  return {
    ...previousState,
    geocoderProximityCenter: payload.geocoderProximityCenter
  };
}

/**
 * Calculates next index when down arrow key is pressed.
 *
 * Returns a new state object with the suggestedFeaturesSelectionIndex updated.
 */
const suggestedFeatureNext = (previousState: NewDevelopment, payload): NewDevelopment => {
  let previousIndex = previousState.suggestedFeaturesSelectionIndex;
  let maxIndex = previousState.suggestedFeatures.length;
  let nextIndex = (previousIndex + 1) % maxIndex;

  return setActiveSuggestionIndex(previousState, nextIndex);
}

/**
 * Calculates next index when up arrow key is pressed.
 *
 * Returns a new state object with the suggestedFeaturesSelectionIndex updated.
 */
const suggestedFeaturePrevious = (previousState: NewDevelopment, payload): NewDevelopment => {
  let previousIndex = previousState.suggestedFeaturesSelectionIndex;
  let maxIndex = previousState.suggestedFeatures.length;
  let nextIndex = (previousIndex - 1 + maxIndex) % maxIndex;

  return setActiveSuggestionIndex(previousState, nextIndex);
}

/**
 * Sets the suggestedFeaturesSelectionIndex which highlights the suggestion in the suggestion dropdown.
 *
 * Returns a new state object with the suggestedFeaturesSelectionIndex updated.
 */
const setActiveSuggestionIndex = (previousState, nextIndex) => {
  if (nextIndex >= 0) {
    return {
      ...previousState,
      suggestedFeaturesSelectionIndex: nextIndex
    };
  }

  return previousState;
}

/**
 * See `setParcelTool` action creator.
 *
 * Returns a new state object with the parcelTool updated.
 */
const setParcelTool = (previousState: NewDevelopment, payload): NewDevelopment => {
  return {
    ...previousState,
    parcelTool: payload.parcelTool,
    parcelToolFromToolbar: payload.parcelTool,
  };
}

/**
 * See `setMapIsReady` action creator.
 *
 * Returns a new state object with the mapIsReady updated.
 */
const setMapIsReady = (previousState: NewDevelopment, payload): NewDevelopment => {
  return {
    ...previousState,
    mapIsReady: payload.mapIsReady,
  };
}

/**
 * See `setPolygonMeasurements` action creator.
 *
 * Returns a new state object with the polygonArea and polygonPerimeter updated.
 */
const setPolygonMeasurements = (previousState: NewDevelopment, payload): NewDevelopment => {
  const { feature } = payload;
  const polygonArea = turf.area(feature);
  const polygonPerimeter = geometry.perimeter(feature);

  return {
    ...previousState,
    polygonArea,
    polygonPerimeter,
  };
}

/**
 * See `resetPolygonMeasurements` action creator.
 *
 * Returns new state with default polygon values.
 */
const resetPolygonMeasurements = (previousState: NewDevelopment, payload): NewDevelopment => {
  return {
    ...previousState,
    polygonArea: INITIAL_STATE.polygonArea,
    polygonPerimeter: INITIAL_STATE.polygonPerimeter,
    polygonIsBeingChanged: false
  }
}

/**
 * See `modifierKeyDown` action creator.
 *
 * Returns a new state object with the appropriate parcelTool updated.
 */
const modifierKeyDown = (previousState: NewDevelopment, payload): NewDevelopment => {
  if (
    payload.keyCode !== KeyCode.Shift ||
    previousState.parcelToolFromToolbar !== ParcelTool.SelectParcel
  ) return previousState;

  return {
    ...previousState,
    parcelTool: ParcelTool.CombineParcels,
  };
}

/**
 * See `setPolygonIsBeingChanged` action creator.
 *
 * Returns a new state object with the polygonIsBeingChanged updated.
 */
const setPolygonIsBeingChanged = (previousState: NewDevelopment, payload): NewDevelopment => {
  return {
    ...previousState,
    polygonIsBeingChanged: payload.polygonIsBeingChanged
  }
}

/**
 * See `modifierKeyUp` action creator.
 *
 * Returns a new state object with the appropriate parcelTool updated.
 */
const modifierKeyUp = (previousState: NewDevelopment, payload): NewDevelopment => {
  return {
    ...previousState,
    parcelTool: previousState.parcelToolFromToolbar,
  };
}

/**
 * See `setDrawnParcels` action creator.
 *
 * Returns a new state object with the drawnParcels updated.
 */
const setDrawnParcels = (previousState: NewDevelopment, payload): NewDevelopment => {
  let drawnParcels: any = {};
  payload.drawnParcels.forEach(
    (parcel) => {
      let parcelId = parcel.properties[MapStyleProperties.RawParcelFieldId.Id];
      // Remove id property of feature that gets added by mapbox-draw.
      delete parcel.id;
      drawnParcels[parcelId] = parcel;
    }
  )

  return { ...previousState, drawnParcels };
}

/**
 * See `setUnitSystem` action creator.
 *
 * Returns a new state object with the unit system updated.
 */
const setUnitSystem = (previousState: NewDevelopment, payload): NewDevelopment => {
  return {
    ...previousState,
    unitSystem: payload.unitSystem
  };
}

/**
 * See `setSmartSearchIsOpen` action creator.
 *
 * Returns a new state object with the smartSearchIsOpen updated.
 */
const setSmartSearchIsOpen = (previousState: NewDevelopment, payload): NewDevelopment => {
  const newState = clearFeatureSelection(previousState, payload);
  return {
    ...newState,
    smartSearchIsOpen: payload.smartSearchIsOpen,
    parcelsInViewport: [],
  };
}

/**
 * See `setDisplayPin` action creator.
 *
 * Returns a new state object with the displayPin updated.
 */
const setDisplayPin = (previousState: NewDevelopment, payload): NewDevelopment => {
  return {
    ...previousState,
    displayPin: payload.displayPin,
  };
}

/**
 * See `setParcelsInViewPort` action creator.
 *
 * Returns a new state object with the parcelsInViewport updated.
 */
const setParcelsInViewPort = (previousState: NewDevelopment, payload): NewDevelopment => {
  const newState = clearFeatureSelection(previousState, payload);
  const parcelsInViewport = payload.parcelsInViewport;

  const filters = filterHelper.updateFiltersToDisplay(parcelsInViewport, newState.filters);
  let smartSearchResult = filterHelper.applyFilters(parcelsInViewport, filters);

  return {
    ...newState,
    filters,
    parcelsInViewport,
    smartSearchResult,
  };
}

/**
 * See `updateFilter` action creator.
 *
 * Returns a new state object with the filters array updated.
 */
const updateFilter = (previousState: NewDevelopment, payload): NewDevelopment => {
  const parcelsInViewport = previousState.parcelsInViewport;
  let filters = { ...previousState.filters };
  filters[payload.filterId].isActive = payload.isActive;
  filters[payload.filterId].value = payload.value;

  if (payload.filterId === FilterId.Vacant) {
    filters[FilterId.ExistingStructureArea].isActive = false;
    filters[FilterId.ExistingYearBuilt].isActive = false;
  } else if (payload.filterId === FilterId.ExistingStructureArea || payload.filterId === FilterId.ExistingYearBuilt) {
    filters[FilterId.Vacant].isActive = false;
  }

  let smartSearchResult = filterHelper.applyFilters(parcelsInViewport, filters);

  return {
    ...previousState,
    smartSearchResult,
    filters: filters,
  };
}

/**
 * See `updateInitialValues` action creator.
 *
 * Returns a new state with initial values updated.
 */
const updateInitialValues = (previousState: NewDevelopment, payload): NewDevelopment => {
  let floorAreaRatio = Number(payload.values.floorAreaRatio);
  if (isNaN(floorAreaRatio)) {
    floorAreaRatio = previousState.initialValues.floorAreaRatio || DEFAULT_FAR_VALUE;
  }

  return {
    ...previousState,
    initialValues: {
      ...previousState.initialValues,
      ...payload.values,
      floorAreaRatio,
    }
  }
}

/**
 * See `setDataInViewport` action creator.
 *
 * Returns a new state object with the parcelDataInViewport and zoningDataInViewport flags updated.
 */
const setDataInViewport = (previousState: NewDevelopment, payload): NewDevelopment => {
  return {
    ...previousState,
    zoningDataInViewport: payload.zoningDataInViewport,
    parcelDataInViewport: payload.parcelDataInViewport,
  };
}

/**
 * See `setDefaultPanel` action creator.
 *
 * Returns a new state object with the smartSearchIsOpen or drawParcelInformationPanelIsOpen flags updated.
 */
const setDefaultPanel = (previousState: NewDevelopment, payload): NewDevelopment => {
  if (previousState.suggestedFeaturePlaceType.includes(GeocodePlaceType.Address) || previousState.selectedFeature) return previousState;

  const smartSearchIsOpen = Boolean(previousState.zoningDataInViewport || previousState.parcelDataInViewport);
  return {
    ...previousState,
    smartSearchIsOpen: smartSearchIsOpen,
    drawParcelInformationPanelIsOpen: !smartSearchIsOpen,
  };
}

/**
 * See `setDrawParcelInformationPanelIsOpen` action creator.
 *
 * Returns a new state object with the drawParcelInformationPanelIsOpen updated.
 */
const setDrawParcelInformationPanelIsOpen = (previousState: NewDevelopment, payload): NewDevelopment => {
  return {
    ...previousState,
    drawParcelInformationPanelIsOpen: payload.drawParcelInformationPanelIsOpen,
  };
}

export default reducer;
