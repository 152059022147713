import React from "react"
import PropertyListing from "../PropertyListing/PropertyListing";

interface OwnProps {
  tabText: string;
  selectedFeature;
}

type Props = OwnProps;

interface State {
  listingTabIsActive: boolean;
}

export class PropertyListingTabContainer extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      listingTabIsActive: !Boolean(props.selectedFeature),
    }
  }

  /**
   * Open the appropriate tab.
   */
  componentDidUpdate(previousProps: Props) {
    if (!previousProps.selectedFeature && this.props.selectedFeature) {
      this.setState({ listingTabIsActive: false });
    } else if(!this.props.selectedFeature) {
      this.setState({ listingTabIsActive: true })
    }
  }

  /**
   * Enable the listing tab.
   */
  enableListingTab = () => {
    this.setState({ listingTabIsActive: true });
  }

  /**
   * Disable the listing tab.
   */
  disableListingTab = () => {
    if (Boolean(this.props.selectedFeature)) this.setState({ listingTabIsActive: false });
  }

  /**
   * Render tabs.
   */
  renderTabs = () => {
    const { tabText, selectedFeature } = this.props;
    const { listingTabIsActive } = this.state;
    const featureTabIsEnabled = Boolean(selectedFeature);

    return (
      <div className="tab-container">
        <div onClick={this.disableListingTab} className={`tab ${featureTabIsEnabled ? "" : "disabled"} ${listingTabIsActive ? "" : "active"}`}>{tabText}</div>
        <div onClick={this.enableListingTab} className={`tab ${listingTabIsActive ? "active" : ""}`}>Listing</div>
      </div>
    );
  }

  render() {
    const { children } = this.props;
    const { listingTabIsActive } = this.state;

    return (
      <div className="component--property-listing-tab-container">
        {this.renderTabs()}
        <div className={`content-wrapper ${listingTabIsActive ? "" : "hidden"}`} >
          {listingTabIsActive && <PropertyListing />}
        </div>
        <div className={`content-wrapper ${listingTabIsActive ? "hidden" : ""}`} >
          {children}
        </div>
      </div>
    );
  }
}

export default PropertyListingTabContainer;
