import database from "./database";
import helpHero from "../helpHero";
import authentication from "../authentication";
import log from "loglevel";
import userSessions from "./userSessions";
import reduxStore from "../../state/reduxStore";
import { authenticationActions } from "../../state/authentication";
import intercom from "../intercom";
import development from "./development";

// The enumerated string must match the collection name in Firestore.
enum FirestoreCollection {
  Users = "users",
  Developments = "projects",
}

/**
 * Initializes firebase
 */
const initialize = () => {
  try {

    authentication.onStateChange((user) => {
      if (!user) {
        userSessions.stopListening();
        reduxStore.dispatch(authenticationActions.signOutSuccess());
        intercom.shutdown();
        intercom.initializeAnonymous();
      } else {
        // It is important to start listening _before_ sending the notification.
        // Otherwise, we may respond to our own notification's "echo" from Firestore.
        userSessions.startListening(user);
        userSessions.notifyUserSessions(user);
        reduxStore.dispatch(authenticationActions.signInSuccess());

        helpHero.identify(user.uid);
      }

      database.initialize();
    });

  } catch (error) {
    log.warn(error);
  }
};

export {
  FirestoreCollection,
};

export default {
  initialize,
  development,
};
