import MapboxDraw from "@mapbox/mapbox-gl-draw";
import log from "loglevel";
import React from "react";
import ReactMapboxGl, { Marker } from "react-mapbox-gl";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { listingsActions } from "../../../../state/listings";
import { newDevelopmentActions, newDevelopmentSelectors } from "../../../../state/newDevelopment";
import { userSelectors } from "../../../../state/user";
import { Tier } from "../../../../state/user/reducers";
import { DrawingMode, hiddenMode } from "../../../../types/DrawingModes";
import Format from "../../../../types/Format";
import { KeyCode } from "../../../../types/KeyCodes";
import { ParcelTool } from "../../../../types/ParcelTool";
import { Path } from "../../../../types/Path";
import Unit from "../../../../types/Unit";
import analytics from "../../../../utils/analytics";
import authentication from "../../../../utils/authentication";
import geometry from "../../../../utils/geometry";
import { MapStyleProperties } from "../../../../utils/mapbox/mapStyleProperties";
import { RawParcelFieldId } from "../../../../utils/mapbox/mapStyleProperties/mapStyleProperties";
import parcelAccessors from "../../../../utils/parcel/parcelAccessors";
import populateParcelProperties from "../../../../utils/parcel/populateParcelProperties";
import roundToDecimal from "../../../../utils/roundToDecimal";
import turf from "../../../../utils/turf";
import unitConversions from "../../../../utils/unitConversions";
import valueFormatter from "../../../../utils/valueFormatter";
import BrandingWatermark from "../../../sharedComponents/BrandingWatermark";
import BusyPopup from "../../../sharedComponents/BusyPopup";
import mapboxPresentationProperties from "../../../utils/mapboxPresentationProperties";
import DrawingInformation from "./DrawingInformation";
import DrawnFeatureLayer from "./DrawnFeatureLayer";
import HoveredFeatureLayer from "./HoveredFeatureLayer";
import ListingsFeatureLayer from "./ListingsFeatureLayer";
import SelectedFeatureLayer from "./SelectedFeatureLayer";
import SmartSearchLayer from "./SmartSearchLayer";
import arrayHelper from "../../../../utils/arrayHelper";
import { ListingProperty } from "../../../../types/Service/Listings/Listings";

/**
 * @fileoverview This container wraps the Mapbox map for new project creation and manages
 *  any required data and functionality.
 */

const CAMERA_INITIAL_PITCH: [number] = [0];
const CAMERA_INITIAL_BEARING: [number] = [0];
const TOOL_TIP_OFFSET: [number, number] = [40, 30];
const CAMERA_SELECT_PARCEL_ZOOM: number = 16.1;

const SMART_SEARCH_TIMEOUT = 1000; // 1 second
let smartSearchTimeoutId: any;

const Mapbox = ReactMapboxGl({
  accessToken: process.env.REACT_APP_MAPBOX_ACCESS_TOKEN as string,
  dragRotate: false,
  touchZoomRotate: false,
  boxZoom: false,
});

const VISIBLE_LAYERS = {
  [Tier.Pro]: [
    MapStyleProperties.LayerId.UsaParcelsZoningQuery,
    MapStyleProperties.LayerId.UsaDemographicsQuery,
    MapStyleProperties.LayerId.UsaParcelsQuery,
    MapStyleProperties.LayerId.UsaOpportunityZoneFill,
    MapStyleProperties.LayerId.UsaParcelsSymbol,
    MapStyleProperties.LayerId.UsaParcelsBorder,
    MapStyleProperties.LayerId.UsaCountiesFill,
    MapStyleProperties.LayerId.UsaCountiesBorder,
    MapStyleProperties.LayerId.UsaZoningSymbol,
    MapStyleProperties.LayerId.UsaZoningBorder,
  ],
  [Tier.Advanced]: [
    MapStyleProperties.LayerId.UsaDemographicsQuery,
    MapStyleProperties.LayerId.UsaParcelsQuery,
    MapStyleProperties.LayerId.UsaOpportunityZoneFill,
    MapStyleProperties.LayerId.UsaParcelsSymbol,
    MapStyleProperties.LayerId.UsaParcelsBorder,
    MapStyleProperties.LayerId.UsaCountiesFill,
    MapStyleProperties.LayerId.UsaCountiesBorder,
  ],
  [Tier.Standard]: [
    MapStyleProperties.LayerId.UsaDemographicsQuery,
    MapStyleProperties.LayerId.UsaOpportunityZoneFill,
    MapStyleProperties.LayerId.UsaCountiesFill,
    MapStyleProperties.LayerId.UsaCountiesBorder,
  ]
};

const PARCEL_TOOL_TO_DRAWING_MODE = {
  [ParcelTool.DrawParcel]: DrawingMode.DrawPolygon,
  [ParcelTool.EditParcel]: DrawingMode.SimpleSelect,
  [ParcelTool.DeleteParcel]: DrawingMode.SimpleSelect
}

const mapStateToProps = (state) => {
  return {
    pinPosition: newDevelopmentSelectors.getPinPosition(state),
    parcelTool: newDevelopmentSelectors.getParcelTool(state),
    parcelToolFromToolbar: newDevelopmentSelectors.getParcelToolFromToolbar(state),
    polygonIsBeingChanged: newDevelopmentSelectors.getPolygonIsBeingChanged(state),
    drawnParcels: newDevelopmentSelectors.getDrawnParcels(state),
    userLocation: userSelectors.getLocation(state),
    selectedFeature: newDevelopmentSelectors.getSelectedFeature(state),
    selectedFeatureMembers: newDevelopmentSelectors.getSelectedFeatureMembers(state),
    unitSystem: newDevelopmentSelectors.getUnitSystem(state),
    smartSearchIsOpen: newDevelopmentSelectors.getSmartSearchIsOpen(state),
    displayPin: newDevelopmentSelectors.getDisplayPin(state),
    tier: userSelectors.getTier(state),
  }
}

const mapDispatchToProps = {
  setMapIsReady: newDevelopmentActions.setMapIsReady,
  setProximityCenter: newDevelopmentActions.setProximityCenter,
  combineParcelsStart: newDevelopmentActions.combineParcelsStart,
  selectParcelStart: newDevelopmentActions.selectParcelStart,
  hoverFeature: newDevelopmentActions.hoverFeature,
  setPolygonMeasurements: newDevelopmentActions.setPolygonMeasurements,
  resetPolygonMeasurements: newDevelopmentActions.resetPolygonMeasurements,
  setPolygonIsBeingChanged: newDevelopmentActions.setPolygonIsBeingChanged,
  clearFeatureSelection: newDevelopmentActions.clearFeatureSelection,
  setDrawnParcels: newDevelopmentActions.setDrawnParcels,
  setParcelsInViewport: newDevelopmentActions.setParcelsInViewport,
  setSmartSearchIsOpen: newDevelopmentActions.setSmartSearchIsOpen,
  setDisplayPin: newDevelopmentActions.setDisplayPin,
  setDataInViewport: newDevelopmentActions.setDataInViewport,
  getListingsStart: listingsActions.getListingsStart,
  clearListings: listingsActions.clearListings,
  selectListing: listingsActions.setSelectedListing,
};

interface OwnProps {
  slideshowDataIsReady: boolean;
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;
type RouterProps = RouteComponentProps<{ camera: string }>;
type Props = RouterProps & StateProps & DispatchProps & OwnProps;

interface State {
  mapStyleIsStreet: boolean;
  distanceToLastPolygonVertex: number | null;
  drawingMode: boolean;
  cameraCenter: [number, number];
  cameraZoom: [number];
  cursorCoordinates: [number, number];
  queryViewport: boolean;
  mapIsLoaded: boolean;
  boundingBox?: [number, number, number, number];
}

class NewProjectMap extends React.PureComponent<Props, State> {
  map: any;
  mapboxDraw: any;
  setModeToDrawingOnKeyDown: boolean;
  contextMenuClickPoint: [number, number] | null;
  parcelIdBeingEdited: string | null;

  constructor(props: Props) {
    super(props);
    let cameraCenter = MapStyleProperties.camera.center;
    let cameraZoom = MapStyleProperties.camera.zoom;

    const cameraFromUrl = this.getCameraFromUrl();

    if (cameraFromUrl) {
      cameraCenter = cameraFromUrl.center;
      cameraZoom = cameraFromUrl.zoom;
    } else if (this.props.location.state && this.props.location.state.pinPosition) {
      cameraCenter = this.props.location.state.pinPosition;
      cameraZoom = CAMERA_SELECT_PARCEL_ZOOM;
    } else if (props.userLocation) {
      cameraCenter = props.userLocation;
      cameraZoom = CAMERA_SELECT_PARCEL_ZOOM;
    }

    this.state = {
      mapStyleIsStreet: true,
      distanceToLastPolygonVertex: null,
      drawingMode: false,
      cameraCenter: cameraCenter,
      cameraZoom: [cameraZoom],
      cursorCoordinates: [0, 0],
      queryViewport: Boolean(this.props.location.state && this.props.location.state.smartSearchIsOpen),
      mapIsLoaded: false,
    }

    this.setModeToDrawingOnKeyDown = false;
    this.contextMenuClickPoint = null;
    this.parcelIdBeingEdited = null;

    if (this.props.location.state && this.props.location.state.smartSearchIsOpen) this.props.setSmartSearchIsOpen(true);
    if (this.props.location.state && this.props.location.state.displayPin !== undefined) this.props.setDisplayPin(this.props.location.state.displayPin);
  }

  /**
   * Get the camera object if the url contains parameters for it.
   */
  getCameraFromUrl = (): { center: [number, number], zoom: number } | null => {
    const cameraString = this.props.match.params.camera;
    if (!cameraString) return null;

    let camera = cameraString.split(",").map(Number);
    if (camera.length < 3) return null;

    let center = camera.slice(0, 2) as [number, number];
    let zoom = camera[2];
    if (center.some(isNaN) || isNaN(zoom)) return null;

    return { center, zoom };
  }

  /**
   * Change drawing mode and transfer the drawn parcels when the parcel tool changes.
   */
  componentDidUpdate(previousProps: Props) {
    if (this.map && this.props.pinPosition && previousProps.pinPosition !== this.props.pinPosition) {
      if (!this.props.selectedFeature && !this.selectParcelIfAvailable(this.props.pinPosition)) this.jumpToCoordinates(this.props.pinPosition);

    } else if (previousProps.userLocation === undefined && this.props.userLocation && !this.getCameraFromUrl()) {
      this.jumpToCoordinates(this.props.userLocation);
    }

    if (this.map && !previousProps.smartSearchIsOpen && this.props.smartSearchIsOpen) {
      this.setState({ queryViewport: true });
      setTimeout(this.getParcelsInViewport, 100);
    }

    if (previousProps.parcelToolFromToolbar === this.props.parcelToolFromToolbar || !this.map) return;
    this.resetDrawingInformation();

    // Temporarily change mode to `DrawPolygon` to force finalization of unfinished parcels being drawn.
    this.mapboxDraw.changeMode(DrawingMode.DrawPolygon);
    this.cleanDrawnParcels(this.mapboxDraw.getAll().features);

    switch (this.props.parcelToolFromToolbar) {
      case ParcelTool.DrawParcel:
      case ParcelTool.EditParcel:
      case ParcelTool.DeleteParcel:
        if (!this.state.drawingMode) {
          this.setState({ drawingMode: true });
        }
        this.props.clearFeatureSelection();
        this.mapboxDraw.changeMode(PARCEL_TOOL_TO_DRAWING_MODE[this.props.parcelToolFromToolbar]);
        break;
      default: // Non drawing parcel tools.
        if (this.state.drawingMode) {
          this.props.setDrawnParcels(this.mapboxDraw.getAll().features);
          this.mapboxDraw.changeMode(DrawingMode.HiddenMode);
          this.setState({ drawingMode: false });
        }
        break;
    }
  }

  /**
   * Select parcel if it exists at coordinates.
   */
  selectParcelIfAvailable = (coordinates) => {
    const point = this.map.project(coordinates);
    let [selectedParcel, selectedCoordinates] = this.getParcel(point);
    if (selectedParcel) {
      this.props.selectParcelStart(selectedParcel, selectedCoordinates);
      this.jumpToCoordinates(selectedCoordinates);
      return true;
    }

    return false;
  }

  /**
   * Set up all the map dependent sources, listeners and bindings after it is loaded.
   */
  handleStyleLoad = (map) => {
    // Reference the map instance.
    this.map = map;
    this.updateGeocoderProximity();
    this.props.setMapIsReady(true);
    this.attachMapDraw();

    if (this.state.queryViewport) setTimeout(this.getParcelsInViewport, 100);

    this.map.on("idle", () => {
      let currentBoundingBox = this.calculateCurrentBoundingBox();
      if (!this.state.boundingBox || !arrayHelper.equals(this.state.boundingBox, currentBoundingBox)) {
        this.checkDataInViewport();
        this.getListingsInViewport(currentBoundingBox);
        this.setState({ boundingBox: currentBoundingBox });
        this.updateUrl();
      }

      const { smartSearchIsOpen, pinPosition } = this.props;
      if (pinPosition && !this.props.selectedFeature && smartSearchIsOpen === false) {
        this.selectParcelIfAvailable(pinPosition);
      }

    });
  }

  /**
   * Calculate and return bounding box of current map viewport.
   */
  calculateCurrentBoundingBox = () => {
    // Calculate viewport bounding box in lat,long.
    const canvas = this.map.getCanvas();
    const w = canvas.width;
    const h = canvas.height;
    const upperRight = this.map.unproject([w, 0]).toArray();
    const lowerLeft = this.map.unproject([0, h]).toArray();

    return [...lowerLeft, ...upperRight] as [number, number, number, number];
  }

  /**
   * Get listings at current viewport bounding box.
   */
  getListingsInViewport = (currentBoundingBox) => {
    if (this.map.getZoom() < 15) {
      this.props.clearListings();
    } else {
      this.props.getListingsStart(currentBoundingBox);
    }
  }

  /**
   * Check if there are visible features of the parcel and/or zoning layers in the viewport.
   */
  checkDataInViewport = () => {
    let parcelDataFeature = this.map.queryRenderedFeatures({
      layers: [MapStyleProperties.LayerId.UsaParcelsQuery],
    })[0];

    let zoningDataFeature = this.map.queryRenderedFeatures({
      layers: [MapStyleProperties.LayerId.UsaParcelsZoningQuery],
    })[0];

    this.props.setDataInViewport(Boolean(parcelDataFeature), Boolean(zoningDataFeature));
    if (!this.state.mapIsLoaded) {
      if (Boolean(zoningDataFeature)) {
        analytics.trackMapLoadZoningData();
      } else if (Boolean(parcelDataFeature)) {
        analytics.trackMapLoadParcelData();
      } else {
        analytics.trackMapLoadDrawParcels();
      }

      this.setState({ mapIsLoaded: true });
    }
  }

  /**
   * Get all the parcels in the viewport along with their sanitized zoning, demographics and
   * opportunity zone information.
   */
  getParcelsInViewport = () => {
    const parcelFeatures = this.map.queryRenderedFeatures({
      layers: [MapStyleProperties.LayerId.UsaParcelsQuery],
    });

    const zoningFeatures = this.map.queryRenderedFeatures({
      layers: [MapStyleProperties.LayerId.UsaParcelsZoningQuery],
    });

    const opportunityZoneFeatures = this.map.queryRenderedFeatures({
      layers: [MapStyleProperties.LayerId.UsaOpportunityZoneFill],
    });

    const demographicsFeatures = this.map.queryRenderedFeatures({
      layers: [MapStyleProperties.LayerId.UsaDemographicsQuery],
    });

    let reducedZoningFeatures: any = {};
    zoningFeatures.forEach((feature) => {
      if (!reducedZoningFeatures[feature.properties[RawParcelFieldId.Id]]) {
        reducedZoningFeatures[feature.properties[RawParcelFieldId.Id]] = feature;
      }
    });

    // Parcels can be split into smaller features by Mapbox. This block joins them back together.
    let reducedParcelFeatures: any = {};
    parcelFeatures.forEach((parcel) => {
      if (!reducedParcelFeatures[parcel.properties[RawParcelFieldId.Id]]) {
        reducedParcelFeatures[parcel.properties[RawParcelFieldId.Id]] = [];
      }
      reducedParcelFeatures[parcel.properties[RawParcelFieldId.Id]].push(parcel);
    });

    reducedParcelFeatures = Object.values(reducedParcelFeatures)
      .map((features) => geometry.featuresUnion(features))
      .filter((feature) => feature);

    let combinedParcelData: any[] = [];
    reducedParcelFeatures.forEach((parcel) => {
      try {
        const center = turf.centerOfMass(parcel);
        let parcelZoning = reducedZoningFeatures[parcel.properties[RawParcelFieldId.Id]];

        let parcelDemographics;
        for (let index = 0; index < demographicsFeatures.length; index++) {
          if (turf.booleanPointInPolygon(center, demographicsFeatures[index])) {
            parcelDemographics = demographicsFeatures[index];
            break;
          }
        }

        let parcelOpportunityZone;
        for (let index = 0; index < opportunityZoneFeatures.length; index++) {
          if (turf.booleanPointInPolygon(center, opportunityZoneFeatures[index])) {
            parcelOpportunityZone = opportunityZoneFeatures[index];
            break;
          }
        }

        combinedParcelData.push(populateParcelProperties({
          parcelFeature: parcel,
          zoningFeature: parcelZoning,
          demographicsFeature: parcelDemographics,
          opportunityZoneFeature: parcelOpportunityZone,
        }));
      } catch (error) {
        console.warn(error);
      }
    });

    this.props.setParcelsInViewport(combinedParcelData);
    this.setState({ queryViewport: false });
  }

  /**
   * Clean drawn parcels of parcel being edited.
   */
  cleanEditedParcel = () => {
    if (this.parcelIdBeingEdited) {
      const editedFeature = this.mapboxDraw.get(this.parcelIdBeingEdited);
      if (editedFeature) this.cleanDrawnParcels([editedFeature]);
      this.parcelIdBeingEdited = null;
    }
  }

  /**
   * Attach the map drawing object to the map and set its event listeners.
   */
  attachMapDraw = () => {
    if (!this.mapboxDraw) {
      this.mapboxDraw = new MapboxDraw({
        displayControlsDefault: false,
        styles: mapboxPresentationProperties.drawingPolygonStyle,
        modes: Object.assign({ [DrawingMode.HiddenMode]: hiddenMode }, MapboxDraw.modes),
        defaultMode: DrawingMode.HiddenMode
      });

      this.map.addControl(this.mapboxDraw);
    }

    Object.values(this.props.drawnParcels).forEach((currentFeature) => this.mapboxDraw.add(currentFeature));

    // Handle changes on the drawing mode.
    this.map.on("draw.modechange",
      () => {
        this.cleanEditedParcel();

        if (this.props.parcelTool === ParcelTool.DrawParcel) {
          if (this.setModeToDrawingOnKeyDown || this.contextMenuClickPoint) {
            this.mapboxDraw.changeMode(DrawingMode.DrawPolygon);
            this.setModeToDrawingOnKeyDown = false;
            this.contextMenuClickPoint = null;
          }

          this.resetDrawingInformation();
        } else if (this.props.parcelTool === ParcelTool.EditParcel && this.contextMenuClickPoint) {
          this.selectParcelToEdit(this.contextMenuClickPoint);
          this.contextMenuClickPoint = null;
        }
      }
    );

    // Handle update to drawn parcels.
    this.map.on("draw.update", () => this.parcelIdBeingEdited = this.mapboxDraw.getSelectedIds()[0]);

    // Handle drawn parcel creation.
    this.map.on("draw.create", (data) => {
      analytics.trackDrawParcel(data.features[0]);
      this.cleanDrawnParcels(data.features);
    });

    // Handle changes on the drawing features currently selected.
    this.map.on("draw.selectionchange",
      (data) => {
        switch (this.props.parcelTool) {
          case ParcelTool.DeleteParcel:
            this.deleteDrawnParcels(data.features);
            break;
          case ParcelTool.EditParcel:
            if (data.features.length === 0) {
              this.resetDrawingInformation();
            } else if (this.mapboxDraw.getMode() === DrawingMode.DirectSelect) {
              this.updatePolygonInformation();
              this.props.setPolygonIsBeingChanged(true);
            }
            break;
        }
      }
    );
  }

  /**
   * Clean drawn parcels
   */
  cleanDrawnParcels = (features) => {
    if (features.length > 0) {
      this.deleteDrawnParcels(features);
      let cleanFeatures = geometry.cleanDrawnPolygons(features);
      cleanFeatures.forEach((feature) => this.mapboxDraw.add(feature));
    }
  }

  /**
   * Reset drawing information about the polygon being drawn or edited.
   */
  resetDrawingInformation = () => {
    this.props.resetPolygonMeasurements();
    this.setState({ distanceToLastPolygonVertex: null });
  }

  /**
   * Delete drawn parcels.
   */
  deleteDrawnParcels = (features) => {
    if (features.length > 0) {
      let featureIds = features.map((feature) => feature.id);
      this.mapboxDraw.delete(featureIds);
    }
  }

  /**
   * Update the polygon information, specifically:
   *
   * - Area.
   * - Perimeter.
   * - Distance to last point added (if we are in draw parcel mode).
   */
  updatePolygonInformation = () => {
    if (this.props.parcelTool === ParcelTool.DrawParcel && this.props.polygonIsBeingChanged) {
      let features = this.mapboxDraw.getAll().features;
      if (!features || features.length === 0) return;

      let currentFeature = features[features.length - 1];
      try {
        let ring = turf.getCoords(currentFeature)[0];
        let currentPoint = ring[ring.length - 2];
        let lastPolygonPoint = ring[ring.length - 3];
        this.setState({
          distanceToLastPolygonVertex: unitConversions.convert(turf.distance(currentPoint, lastPolygonPoint), Unit.Type.KiloMeters, Unit.Type.Meters)
        });

        this.props.setPolygonMeasurements(currentFeature);
      } catch (error) {
        log.warn(error);
      }
    } else if (this.props.parcelTool === ParcelTool.EditParcel) {
      let features = this.mapboxDraw.getSelected().features;
      if (!features || features.length === 0) return;

      let currentFeature = features[0];
      this.props.setPolygonMeasurements(currentFeature);
    }
  }

  /**
   * Handle click on the map depending on parcel tool.
   */
  handleMapClick = (map, event) => {
    if (!this.map) return;
    if (event.type === "contextmenu") {
      this.contextMenuClickPoint = (this.props.parcelTool === ParcelTool.DrawParcel) || (this.props.parcelTool === ParcelTool.EditParcel)
          ? event.point
          : null;
    }

    switch (this.props.parcelTool) {
      case ParcelTool.SelectParcel:
      case ParcelTool.CombineParcels:
        this.handleClickedParcel(event.point);
        break;
      case ParcelTool.DrawParcel:
        this.handleDrawingPolygonClick(event.point);
        break;
      case ParcelTool.EditParcel:
        this.selectParcelToEdit(event.point);
        break;
    }
  }

  /**
   * Handle what action to dispatch depending on the parcelTool and if a parcel was clicked or not.
   */
  handleClickedParcel = (point) => {
    const listingFeature = this.getListingFeature(point);

    let queryPoint = point;
    if (listingFeature) {
      const listing = JSON.parse(listingFeature.properties.listing);
      queryPoint = this.map.project(listing[ListingProperty.Coordinates]);
    }

    let [clickedParcel, clickedCoordinates] = this.getParcel(queryPoint);

    if (clickedParcel === null && listingFeature === null) {
      this.props.clearFeatureSelection();
      return;
    } else if (clickedParcel === null && listingFeature !== null) {
      this.props.selectListing([JSON.parse(listingFeature.properties.listing)])
      return;
    }

    const parcelId = parcelAccessors.getParcelId(clickedParcel);
    if (parcelId) analytics.trackParcelClick(parcelId, clickedCoordinates);

    if (this.props.parcelTool === ParcelTool.SelectParcel) {
      this.props.selectParcelStart(clickedParcel, clickedCoordinates);
    } else if (this.props.parcelTool === ParcelTool.CombineParcels) {
      this.props.combineParcelsStart(clickedParcel, clickedCoordinates, this.props.selectedFeatureMembers);
    }
  }

  /**
   * Get listing from map at point position.
   */
  getListingFeature = (point) => {
    if (!this.map) return null;

    let listingFeatures = this.map.queryRenderedFeatures(point, {
      layers: [
        MapStyleProperties.LayerId.ListingsQuery,
      ],
    });

    return listingFeatures.length > 0 ? listingFeatures[0] : null;
  }

  /**
   * Handle click when the map is on draw parcel mode.
   */
  handleDrawingPolygonClick = (point) => {
    if (!this.mapboxDraw) return;

    if (this.mapboxDraw.getMode() !== DrawingMode.DrawPolygon) {
      this.mapboxDraw.changeMode(DrawingMode.DrawPolygon);
    } else if (!this.props.polygonIsBeingChanged) {
      this.initializePolygonIsBeingDrawn();
    }

    this.updateCursorCoordinates(point);
  }

  /**
   * Handle key down when map is on draw parcel mode.
   */
  handleKeyDown = (event) => {
    switch (event.key) {
      case KeyCode.Enter:
      case KeyCode.Esc:
        if (this.props.parcelTool === ParcelTool.DrawParcel) this.setModeToDrawingOnKeyDown = true;
        break;
      case KeyCode.Delete:
        if (this.props.parcelTool === ParcelTool.EditParcel) {
          this.contextMenuClickPoint = null;
          this.mapboxDraw.trash();
        }
        break;
    }
  }

  /**
   * Initialize the polygon being drawn.
   */
  initializePolygonIsBeingDrawn = () => {
    this.props.setPolygonIsBeingChanged(true);
    this.setState({ distanceToLastPolygonVertex: 0 });
  }

  /**
   * Select the parcel to be edited.
   */
  selectParcelToEdit = (point) => {
    if (!this.mapboxDraw) return;

    let clickedFeatureIds = this.mapboxDraw.getFeatureIdsAt(point);

    if (clickedFeatureIds.length > 0) {
      for (const featureId of clickedFeatureIds) {
        if (featureId) {
          this.mapboxDraw.changeMode(DrawingMode.DirectSelect, { featureId });
          break;
        }
      }
    }
  }

  /**
   * Jump to the selected parcel.
   */
  jumpToCoordinates = (center) => {
    const mapZoom = this.map ? this.map.getZoom() : 0;
    const zoom = Math.max(mapZoom, CAMERA_SELECT_PARCEL_ZOOM);
    this.setState({
      cameraCenter: center,
      cameraZoom: [zoom]
    })
  }

  /**
   * Get the parcel at point position.
   */
  getParcel = (point) => {
    let selectedFeatureUnion = this.getCompleteParcelFeatureAtScreenPoint(point);

    if (selectedFeatureUnion === null) return [null, null];

    let clickedCoordinates = this.map.unproject(point);
    clickedCoordinates = [clickedCoordinates.lng, clickedCoordinates.lat];

    return [selectedFeatureUnion, clickedCoordinates];
  }

  /**
   * Get the id of the parcel that includes a given screen point in the current viewport.
   */
  getParcelIdFromScreenPoint = (point) => {
    if (!this.map) return {};

    let drawnFeature = this.map.queryRenderedFeatures(point, {
      layers: [MapStyleProperties.LayerId.DrawnParcels],
    })[0];

    if (drawnFeature) {
      return { isDrawn: true, id: drawnFeature.properties[MapStyleProperties.RawParcelFieldId.Id] }
    }

    let initialFeature = this.map.queryRenderedFeatures(point, {
      layers: [MapStyleProperties.LayerId.UsaParcelsQuery],
    })[0];

    return initialFeature
      ? { isDrawn: false, id: initialFeature.properties[MapStyleProperties.RawParcelFieldId.Id] }
      : {};
  }

  /*
   * Enable the new project layers visibility when the map style changes.
   */
  displayLayers = (map) => {
    let tier = this.props.tier;
    const userIsAuthenticated = authentication.isUserAuthenticated();
    if (!userIsAuthenticated) tier = Tier.Pro;

    if (tier) {
      VISIBLE_LAYERS[tier].forEach(
        (layerId) => map.setLayoutProperty(layerId, "visibility", "visible")
      );
    }
  }

  /**
   * Handle mouse move over the map depending on map mode.
   */
  handleMouseMove = (map, event) => {
    if (!this.map || this.map.isMoving()) return;

    switch (this.props.parcelTool) {
      case ParcelTool.SelectParcel:
      case ParcelTool.CombineParcels:
        this.hoverOverParcel(event.point);
        break;
      case ParcelTool.DrawParcel:
      case ParcelTool.EditParcel:
        if (this.props.polygonIsBeingChanged) {
          this.updateCursorCoordinates(event.point);
          this.updatePolygonInformation();
        }
        break;
      default:
        break;
    }
  }

  /**
   * Set cursor coordinates from map point.
   */
  updateCursorCoordinates = (point) => {
    let cursorCoordinates = this.map.unproject(point);
    this.setState({ cursorCoordinates: [cursorCoordinates.lng, cursorCoordinates.lat] });
  }

  /**
   * Handle the hovering over the parcels.
   */
  hoverOverParcel = (point) => {
    let listing = this.getListingFeature(point);
    let feature = listing ? null : this.getDetiledFeatureAtScreenPoint(point);

    this.props.hoverFeature(feature);
    feature || listing
        ? this.map.getCanvas().classList.add("hover-parcel")
        : this.map.getCanvas().classList.remove("hover-parcel");
  }

  /**
   * Update proximity and camera zoom when camera stops moving.
   */
  handleMoveEnd = () => {
    this.updateGeocoderProximity();

    if (this.props.smartSearchIsOpen) {
      // Timeout is needed to debounce the smart search allowing the user to pan and zoom without
      // constantly being interrupted by the search.
      smartSearchTimeoutId = setTimeout(() => {
        this.setState({ queryViewport: true });
        // Inner timeout is needed for the setState to take effect.
        setTimeout(() => this.getParcelsInViewport(), 50);
      }, SMART_SEARCH_TIMEOUT);
    }
  }

  /**
   * Update the url.
   */
  updateUrl = () => {
    if (!this.map) return;

    const zoom = roundToDecimal(this.map.getZoom(), 2);
    const center = this.map.getCenter().wrap();
    const centerCoordinates = [roundToDecimal(center.lng, 7), roundToDecimal(center.lat, 7)];

    const cameraString = centerCoordinates.join(",") + "," + zoom;
    this.props.history.replace(`${Path.NewProject}/${cameraString}`);
  }

  /**
   * Allow the geocoder to recommend Addresses/Places by proximity.
   */
  updateGeocoderProximity = () => {
    if (this.map) {
      let center = this.map.getCenter().wrap();
      if (center.lng && center.lat) this.props.setProximityCenter([center.lng, center.lat]);
    }
  }

  /**
   * Generate a complete parcel from raw Mapbox features. The returned feature
   * uses the raw parcel feature's geometry, and its properties are populated
   * with sanitized data values, part of which is sourced from the additional raw
   * Mapbox features.
   */
  getCompleteParcelFeatureAtScreenPoint = (point) => {
    let parcelFeature = this.getDetiledFeatureAtScreenPoint(point);
    if (!parcelFeature) return null;

    let center = turf.centerOfMass(parcelFeature);
    let centerPoint = this.map.project(turf.getCoord(center));

    let zoningFeature = this.map.queryRenderedFeatures(centerPoint, {
      layers: [MapStyleProperties.LayerId.UsaParcelsZoningQuery],
    })[0];

    let opportunityZoneFeature = this.map.queryRenderedFeatures(centerPoint, {
      layers: [MapStyleProperties.LayerId.UsaOpportunityZoneFill],
    })[0];

    let demographicsFeature = this.map.queryRenderedFeatures(centerPoint, {
      layers: [MapStyleProperties.LayerId.UsaDemographicsQuery],
    })[0];

    return populateParcelProperties({
      parcelFeature,
      zoningFeature,
      demographicsFeature,
      opportunityZoneFeature,
    });
  }

  /**
   * Get the parcel features at a given point in the screen.
   */
  getDetiledFeatureAtScreenPoint = (point) => {
    let { id, isDrawn } = this.getParcelIdFromScreenPoint(point);
    if (!id) return null;

    if (isDrawn) {
      return this.props.drawnParcels[id];
    }

    let features = this.map.queryRenderedFeatures({
      layers: [
        MapStyleProperties.LayerId.UsaParcelsQuery,
        MapStyleProperties.LayerId.DrawnParcels,
      ],
      filter: ["in", MapStyleProperties.RawParcelFieldId.Id, id]
    });

    return features.length === 0
      ? null
      : geometry.featuresUnion(features);
  }

  /**
   * Updates map style.
   */
  toggleMapStyle = () => {
    this.setState({
      mapStyleIsStreet: !this.state.mapStyleIsStreet
    })
    analytics.trackMapStyleButtonClick(!this.state.mapStyleIsStreet ? "street" : "satellite");
  }

  /**
   * Returns mapbox style.
   */
  getMapStyle = () => {
    return this.state.mapStyleIsStreet
      ? MapStyleProperties.StyleUrl.Streets
      : MapStyleProperties.StyleUrl.Satellite;
  }

  /**
   * Render a tool tip next to the cursor showing of the distance to the last point added to the polygon.
   */
  renderToolTip = (): any => {
    if (this.state.distanceToLastPolygonVertex === null) return;

    let [unitTarget, suffix] = this.props.unitSystem === Unit.System.Metric ? [Unit.Type.Meters, " m"] : [Unit.Type.Feet, " ft"];
    let convertedValue = unitConversions.convertFromBase(this.state.distanceToLastPolygonVertex, unitTarget);

    let formattedValue = valueFormatter.format(convertedValue, { type: Format.Type.Number, suffix: suffix });
    return (
      <Marker coordinates={this.state.cursorCoordinates} offset={TOOL_TIP_OFFSET}>
        <div className="tool-tip">
          {formattedValue}
        </div>
      </Marker>
    );
  }

  /**
   * Render the map pin.
   */
  renderPin = (): any => {
    const { pinPosition, displayPin } = this.props;
    if (!pinPosition || !displayPin) return;

    return <Marker coordinates={pinPosition} className="map-pin" />;
  }

  render() {
    const userIsAuthenticated = authentication.isUserAuthenticated();

    return (
      <div className={`component--new-project-map ${this.props.parcelTool}`} onKeyDown={this.handleKeyDown}>
        <Mapbox
          style={this.getMapStyle()}
          center={this.state.cameraCenter}
          zoom={this.state.cameraZoom}
          pitch={CAMERA_INITIAL_PITCH}
          bearing={CAMERA_INITIAL_BEARING}
          onStyleLoad={this.handleStyleLoad}
          onClick={this.handleMapClick}
          onContextMenu={this.handleMapClick}
          onStyleData={this.displayLayers}
          onMouseMove={this.handleMouseMove}
          onMove={() => clearInterval(smartSearchTimeoutId)}
          onMoveEnd={this.handleMoveEnd}
          containerStyle={{
            height: "100%",
            width: "100%",
            margin: "0 auto"
          }}
          movingMethod="jumpTo"
        >
          <DrawnFeatureLayer />
          <SmartSearchLayer />
          <HoveredFeatureLayer />
          <SelectedFeatureLayer />
          <DrawingInformation />
          <ListingsFeatureLayer />
          {this.renderPin()}
          {this.renderToolTip()}
        </Mapbox>

        <button onClick={this.toggleMapStyle} className={`${this.state.mapStyleIsStreet ? "satellite" : "streets"}-map-button`} />
        <BrandingWatermark />
        {this.state.queryViewport && (userIsAuthenticated || this.props.slideshowDataIsReady) && <BusyPopup text="Gathering parcels data..." />}
      </div>
    );
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(NewProjectMap)
) as any;
