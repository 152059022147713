import React from "react";
import ApplicationLinks from "./ApplicationLinks";
import DevelopmentsList from "./DevelopmentsList";
import Footer from "./Footer";
import database from "../../../../utils/database/database";
import GeocoderSearch from "../../../sharedComponents/GeocoderSearch";
import BusySpinner from "../../../sharedComponents/BusySpinner";

interface State {
  developmentsList: Array<any>;
  isReady: boolean;
}

export default class DashboardContent extends React.PureComponent<{}, State> {
  constructor(props) {
    super(props);

    this.state = {
      developmentsList: [],
      isReady: false,
    }
  }

  /**
   * Ensure this.updateDevelopmentList is called once the project is loaded from the
   * database.
   */
  componentDidMount() {
    if (!database.isInitialized()) {
      database.onInitialized(this.updateDevelopmentList);
    } else {
      this.updateDevelopmentList();
    }
  }

  /**
   * Ensure this.updateDevelopmentList is not called after this component has been
   * unmounted.
   */
  componentWillUnmount() {
    database.removeOnInitializedCallback(this.updateDevelopmentList);
  }

  /**
   * Update developments list.
   */
  updateDevelopmentList = () => {
    const developmentsList = database.getCurrentUserDevelopments();
    this.setState({ developmentsList, isReady: true });
  }

  /**
   * Render development list and application links.
   */
  renderDevelopmentList = () => {
    return (
      <>
        <ApplicationLinks />
        <DevelopmentsList developmentsList={this.state.developmentsList} updateDevelopmentList={this.updateDevelopmentList} />
      </>
    )
  }

  render() {
    if (!this.state.isReady) return <BusySpinner />;
    return (
      <div className="component--dashboard-content">
        {
          this.state.developmentsList.length === 0
            ? <GeocoderSearch />
            : this.renderDevelopmentList()
        }
        <Footer />
      </div>
    )
  }
}
