/**
 * Initialize Intercom with user information.
 */
const initialize = (name: string, email: string) => {
  window["Intercom"]("boot", {
    app_id: process.env.REACT_APP_INTERCOM_APP_ID,
    name: name,
    email: email,
  });
};

/**
 * Initialize Intercom without user information.
 */
const initializeAnonymous = () => {
  window["Intercom"]("boot", {
    app_id: process.env.REACT_APP_INTERCOM_APP_ID,
  });
};

/**
 * Close and shutdown current Intercom session.
 */
const shutdown = () => {
  window["Intercom"]("shutdown");
}

/**
 * Track events with Intercom.
 */
const track = (eventName: string, metadata?) => {
  window["Intercom"]("trackEvent", eventName, { ...metadata });
}

export default {
  initialize,
  initializeAnonymous,
  shutdown,
  track,
};
