import React from "react";
import BaseRow from "../../../sharedComponents/BaseRow";

const BORDER_WIDTH = 0.48;
const FONT_SIZE = 8.16;

const GroupHeaderRow = (props) => {
  props = {
    ...props,
    borderBottom: BORDER_WIDTH,
    borderColor: "#B7BBE3",
    borderStyle: "solid",
    labelFontFamily: "Roboto Condensed Bold",
    valueFontFamily: "Roboto Condensed Bold",
    fontSize: FONT_SIZE
  }

  return <BaseRow {...props} />;
}

export default GroupHeaderRow;
