import React from "react";
import { connect } from "react-redux";
import { newDevelopmentActions } from "../../../../../../state/newDevelopment";
import { ToggleField } from "../../../../../utils/forms/fieldRenderers";
import { Range } from "rc-slider";
import { colorFor } from "../../../../../utils/colors";
import valueFormatter from "../../../../../../utils/valueFormatter";
import Format from "../../../../../../types/Format";
import { Filter } from "../../../../../../types/Filter";
import Unit from "../../../../../../types/Unit";
import unitConversions from "../../../../../../utils/unitConversions";
import analytics from "../../../../../../utils/analytics";
import authentication from "../../../../../../utils/authentication";

const mapDispatchToProps = {
  updateFilter: newDevelopmentActions.updateFilter,
};

type DispatchProps = typeof mapDispatchToProps;
interface OwnProps {
  filter: Filter;
  index: number;
  label: string;
  min?: number;
  max?: number;
  step?: number;
  round?: number;
  sliderCurve?: (x: number) => number;
  inverseCurve?: (x: number) => number;
  unitTarget: Unit.Type;
  isInverse: boolean;
  formatOptions?: Format.Options;
  dataTip: any;
}

type Props = DispatchProps & OwnProps;

interface State {
  value: number[];
}

class SearchRow extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    const { filter, inverseCurve } = props;

    this.state = {
      value: filter.value.map((value) => inverseCurve ? inverseCurve(value) : value),
    };
  }

  /**
   * Handle change on toggles.
   */
  handleToggleChange = () => {
    const { filter } = this.props;
    const userIsAuthenticated = authentication.isUserAuthenticated();
    if (!userIsAuthenticated && !filter.allowUnauthenticated) return;

    const convertedValues = this.state.value.map(this.convertRawValuesToBase);
    this.props.updateFilter(filter.id, !filter.isActive, convertedValues);
    this.trackFilterChange(filter.id, filter.isActive, convertedValues);
  }

  /**
   * Track changes to filters.
   */
  trackFilterChange = (id, isActive, value) => {
    let [min, max] = value;
    analytics.trackFilterToggle(id, isActive, min, max);
  }

  /**
   * Convert Raw values.
   * Raw values will be converted to the current selected unit system and then
   * rounded to prevent rounding errors.
   * Then the values will then be converted back to the base unit for saving.
   */
  convertRawValuesToBase = (rawValue) => {
    const { sliderCurve, round } = this.props;
    return sliderCurve && round
      ? this.convertToBase(Math.round(this.convertFromBase(sliderCurve(rawValue)) / round) * round)
      : rawValue
  }

  /**
   * Handle sliders release.
   */
  handleSliderAfterChange = (rawValues) => {
    const { filter } = this.props;
    const value = rawValues.map(this.convertRawValuesToBase);

    this.setState({ value: rawValues });
    this.props.updateFilter(filter.id, filter.isActive, value);
    this.trackFilterChange(filter.id, filter.isActive, value);
  }

  /**
   * Handle sliders change.
   */
  handleSliderChange = (value) => this.setState({ value });

  /**
   * Convert value to current unit system from the base unit.
   */
  convertFromBase = (value) => {
    const { unitTarget, isInverse } = this.props;
    return unitTarget ? unitConversions.convertFromBase(value, unitTarget, isInverse) : value;
  }

  /**
  * Convert value from current unit system to the base unit.
  */
  convertToBase = (value) => {
    const { unitTarget, isInverse } = this.props;
    return unitTarget ? unitConversions.convertToBase(value, unitTarget, isInverse) : value;
  }

  /**
   * Render slider.
   */
  renderSlider = () => {
    const { inverseCurve, sliderCurve, min, max, step, round, formatOptions, filter } = this.props;
    if (sliderCurve === undefined ||
      inverseCurve === undefined ||
      min === undefined ||
      max === undefined ||
      round === undefined
    ) return;

    return this.state.value.length > 1 &&
      <div className="slider-wrapper">
        <div className="rc-slider-wrapper">
          <Range
            min={inverseCurve(min)}
            max={inverseCurve(max)}
            step={step}
            allowCross={false}
            disabled={!filter.isActive}
            onChange={this.handleSliderChange}
            onAfterChange={this.handleSliderAfterChange}
            value={this.state.value}
            handleStyle={[
              {
                backgroundColor: !filter.isActive ? colorFor("disabled-slider-box") : colorFor("smart-search-slider-box"),
              },
              {
                backgroundColor: !filter.isActive ? colorFor("disabled-slider-box") : colorFor("smart-search-slider-box"),
              }
            ]}
            trackStyle={[{
              backgroundColor: !filter.isActive ? colorFor("disabled-slider-box") : colorFor("smart-search-slider-box"),
            }]}
          />
        </div>
        <div className="values">
          <div>{valueFormatter.format(Math.round(this.convertFromBase(sliderCurve(this.state.value[0])) / round) * round, formatOptions)}</div>
          <div>{valueFormatter.format(Math.round(this.convertFromBase(sliderCurve(this.state.value[1])) / round) * round, formatOptions)}</div>
        </div>
      </div>
  }

  render() {
    const { label, filter } = this.props;

    return (
      <div className="component--search-row" data-tip={this.props.dataTip}>
        <ToggleField
          label={label}
          color={colorFor("smart-search-toggle")}
          toggleElementProps={{
            value: filter.isActive,
            onChange: this.handleToggleChange
          }}
        />
        {this.renderSlider()}
      </div>
    );
  }
}

export default connect(
  null,
  mapDispatchToProps
)(SearchRow);
