import React from "react";
import { Document } from "@react-pdf/renderer";
import FirstPage from "./FirstPage";
import SecondPage from "./SecondPage";
import CoverPage from "./CoverPage";

const PdfDocument = (props) => (
  <Document title="Deepblocks Property Report" onRender={props.onRender}>
    <CoverPage />
    <FirstPage />
    <SecondPage />
  </Document>
);

export default PdfDocument;
