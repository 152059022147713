import React from "react";
import SiteAnalysisLink from "./SiteAnalysisLink";
import ExistingBuildingLink from "./ExistingBuildingLink";
import NewDevelopmentLink from "./NewDevelopmentLink";

export default class ApplicationLinks extends React.PureComponent<{}, {}> {
  render() {
    return (
      <div className="component--application-links">
        <SiteAnalysisLink />
        <NewDevelopmentLink />
        <ExistingBuildingLink />
      </div>
    )
  }
}
