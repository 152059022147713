import React from "react";
import { Redirect } from "react-router";
import { Path } from "../../../types/Path";

interface EmailAuthenticationQueryParameters  {
  mode: string | null;
  actionCode: string | null;
}

/**
 * Parse the url query into an object of parameters names and values.
 */
const parseUrlQuery = () => {
  let queryString = window.location.search.substring(1);
  let searchParamaters = new URLSearchParams(queryString);
  let queryParameters: EmailAuthenticationQueryParameters = {
    mode: searchParamaters.get("mode"),
    actionCode: searchParamaters.get("oobCode")
  }
  return queryParameters;
}

export default class AuthenticationLinkHandler extends React.PureComponent<{}, {}>  {
  render() {
    let query = parseUrlQuery();
    switch (query.mode) {
      case "resetPassword":
        return <Redirect
          to={{
            pathname: Path.ResetPassword,
            state: {actionCode: query.actionCode}
          }}
        />;
      case "verifyEmail":
        return <Redirect
          to={{
            pathname: Path.VerifyEmail,
            state: {actionCode: query.actionCode}
          }}
        />;
      default:
        return null;
    }
  }
}
