import React from "react";
import PdfContext from "../../../PdfContext";
import ContactDetails from "../../sharedComponents/ContactDetails";

const ToContactDetails = () => (
  <PdfContext.Consumer>
    {({ colorPalette, toContactDetails }) => (
      <ContactDetails
        contactDetails={toContactDetails}
        label="Prepared for:"
        textColor={colorPalette.text}
        labelColor={colorPalette.secondary}
        fontSize={10}
      />
    )}
  </PdfContext.Consumer>
);

export default ToContactDetails;
