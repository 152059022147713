import React from "react";
import { GeoJSONLayer } from "react-mapbox-gl";
import { connect } from "react-redux";
import { developmentSelectors } from "../../../../state/development";
import Pdf from "../../../../types/Pdf";
import { ReactMapboxGlCamera } from "../../../../types/ReactMapboxGlCamera";
import turf from "../../../../utils/turf";
import mapboxPresentationProperties from "../../../utils/mapboxPresentationProperties";
import { MapStyleProperties } from "../../../../utils/mapbox/mapStyleProperties";
import Map from "../sharedComponents/Map";

const DEFAULT_ZOOM = 17.5;

const VISIBLE_LAYERS = [
  MapStyleProperties.LayerId.UsaParcelsQuery,
  MapStyleProperties.LayerId.UsaParcelsBorder,
];

interface Props {
  parcelGeoJson: turf.AllGeoJSON;
}

class MapParcel extends React.PureComponent<Props, {}> {
  camera: ReactMapboxGlCamera

  constructor(props) {
    super(props);

    this.camera = {
      zoom: [DEFAULT_ZOOM],
      pitch: [0],
      bearing: [0],
      center: turf.getCoord(turf.centerOfMass(this.props.parcelGeoJson)) as [number, number],
    }
  }

  /**
   * Adjust the map to fit the parcel in the viewport.
   */
  handleStyleLoad = (map) => {
    let boundingBox = turf.bbox(this.props.parcelGeoJson);
    map.fitBounds(
      boundingBox,
      {
        padding: { top: 220, bottom: 220, left: 200, right: 200 },
        animate: false,
        maxZoom: DEFAULT_ZOOM,
      }
    );
  }

  render() {
    return (
      <Map
        pdfImageType={Pdf.ImageType.Parcel}
        camera={this.camera}
        mapStyleUrl={MapStyleProperties.StyleUrl.Satellite}
        onStyleLoad={this.handleStyleLoad}
        visibleLayers={VISIBLE_LAYERS}
      >
        <GeoJSONLayer
          data={this.props.parcelGeoJson as any}
          fillPaint={mapboxPresentationProperties.parcelSelectPdfFillPaint}
          linePaint={mapboxPresentationProperties.parcelSelectLinePaint}
        />
      </Map>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    parcelGeoJson: developmentSelectors.getParcel(state),
  };
}

export default connect(mapStateToProps)(MapParcel);
