import { Values } from  "../../../../types/Development/Values";

/**
 * @fileoverview This module manages financial calculations that pertain
 *  to the cash flows each building use generates
 */

const DEFAULT_INPUTS: Partial<Values> = {
  condoMicrounitQuantity: 0,
  condoStudioQuantity: 0,
  condoOneBedQuantity: 0,
  condoTwoBedQuantity: 0,
  condoThreeBedQuantity: 0,
  condoSalePricePerMicrounit: 0,
  condoSalePricePerStudio: 0,
  condoSalePricePerOneBed: 0,
  condoSalePricePerTwoBed: 0,
  condoSalePricePerThreeBed: 0,
  condoSaleCommissionPercentage: 0,
  condoTotalDevelopmentCostIncludingParking: 0,

  hotelStabilizedAverageDailyRate: 0,
  hotelRoomQuantity: 0,
  hotelStabilizedOccupancyPercentage: 0,
  hotelOtherRevenuePercentage: 0,
  hotelOperatingExpensePercentage: 0,

  multifamilyMicrounitQuantity: 0,
  multifamilyStudioQuantity: 0,
  multifamilyOneBedQuantity: 0,
  multifamilyTwoBedQuantity: 0,
  multifamilyThreeBedQuantity: 0,
  multifamilyYearOneMonthlyRentPerMicrounit: 0,
  multifamilyYearOneMonthlyRentPerStudio: 0,
  multifamilyYearOneMonthlyRentPerOneBed: 0,
  multifamilyYearOneMonthlyRentPerTwoBed: 0,
  multifamilyYearOneMonthlyRentPerThreeBed: 0,
  multifamilyStabilizedVacancyPercentage: 0,
  multifamilyOperatingExpensePercentage: 0,

  officeGrossLeasableArea: 0,
  officeYearOneRentPerArea: 0,
  officeYearOneExpenseReimbursementFeePerArea: 0,
  officeStabilizedVacancyPercentage: 0,
  officeYearOneOperatingExpensePerArea: 0,

  retailNetLeasableArea: 0,
  retailYearOneRentPerArea: 0,
  retailYearOneExpenseReimbursementFeePerArea: 0,
  retailStabilizedVacancyPercentage: 0,
  retailYearOneOperatingExpensePerArea: 0,

  industrialNetLeasableArea: 0,
  industrialYearOneRentPerArea: 0,
  industrialYearOneExpenseReimbursementFeePerArea: 0,
  industrialStabilizedVacancyPercentage: 0,
  industrialYearOneOperatingExpensePerArea: 0,
};

/**
 * Initialize the given `values` object. Ensures the object has something
 * defined for the minimum set of variables required for this module to operate.
 *
 * @param {Object} values - A development values object. If any required
 *  financial input is missing, the value from DEFAULT_INPUTS is applied.
 */
const ensureRequiredInputs = (values: Values) => {
  Object.assign(
    values,
    {...DEFAULT_INPUTS, ...values}
  );
};

/**
 * Update the financial quantities on the given `values` object.
 *
 * @param {Object} values - A development values object that may not have fully
 *    updated financial quantities.
 */
const update = (values: Values) => {
  updateCondoSales(values);
  updateHotelCashFlows(values);
  updateMultifamilyCashFlows(values);
  updateOfficeCashFlows(values);
  updateRetailCashFlows(values);
  updateIndustrialCashFlows(values);
  updateRentalUsesCashFlows(values);
  updateIncomeProducingUsesCashFlows(values);
  updateTotalProjectCashFlows(values);
};

/**
 * Update the condo sales quantities for the given `values` object.
 */
const updateCondoSales = (values: Values) => {
  // Net Proceeds
  values.condoGrossSales =
      (values.condoMicrounitQuantity * values.condoSalePricePerMicrounit)
    + (values.condoStudioQuantity * values.condoSalePricePerStudio)
    + (values.condoOneBedQuantity * values.condoSalePricePerOneBed)
    + (values.condoTwoBedQuantity * values.condoSalePricePerTwoBed)
    + (values.condoThreeBedQuantity * values.condoSalePricePerThreeBed);

  values.condoTotalSalesCommissions =
      values.condoGrossSales
    * values.condoSaleCommissionPercentage;

  values.condoNetProceeds =
      values.condoGrossSales
    - values.condoTotalSalesCommissions;

  // Profit
  values.condoProfit =
      values.condoNetProceeds
    - values.condoTotalDevelopmentCostIncludingParking;

  // Additional Metrics
  values.condoAverageGrossSalesPerUnit =
    values.condoTotalUnitQuantity === 0
      ? 0 // Guard against divide-by-zero errors.
      : (
          values.condoGrossSales
          /
          values.condoTotalUnitQuantity
        );

  values.condoAverageGrossSalesPerSellableArea =
    values.condoNetSellableArea === 0
      ? 0 // Guard against divide-by-zero errors.
      : (
          values.condoGrossSales
          /
          values.condoNetSellableArea
        );
};

/**
 * Update the hotel cash flow quantities for the given `values` object.
 */
const updateHotelCashFlows = (values: Values) => {
  // Revenue per Available Room (RevPAR)
  values.hotelStabilizedRevenuePerAvailableRoom =
      values.hotelStabilizedAverageDailyRate
    * values.hotelStabilizedOccupancyPercentage;

  // Potential Revenue
  values.hotelAnnualPotentialRoomRevenueForBackOfEnvelope =
      365
    * values.hotelRoomQuantity
    * values.hotelStabilizedAverageDailyRate;

  values.hotelAnnualPotentialOtherRevenueForBackOfEnvelope =
    values.hotelOtherRevenuePercentage === 1
      ? 0 // Guard against divide-by-zero errors.
      : (
          (values.hotelOtherRevenuePercentage * values.hotelAnnualPotentialRoomRevenueForBackOfEnvelope)
          /
          (1 - values.hotelOtherRevenuePercentage)
        );

  values.hotelAnnualPotentialRevenueForBackOfEnvelope =
      values.hotelAnnualPotentialRoomRevenueForBackOfEnvelope
    + values.hotelAnnualPotentialOtherRevenueForBackOfEnvelope;

  // Revenue
  values.hotelAnnualRoomRevenueForBackOfEnvelope =
      values.hotelAnnualPotentialRoomRevenueForBackOfEnvelope
    * values.hotelStabilizedOccupancyPercentage;

  values.hotelAnnualOtherRevenueForBackOfEnvelope =
    values.hotelAnnualPotentialOtherRevenueForBackOfEnvelope
  * values.hotelStabilizedOccupancyPercentage;

  values.hotelAnnualRevenueForBackOfEnvelope =
      values.hotelAnnualRoomRevenueForBackOfEnvelope
    + values.hotelAnnualOtherRevenueForBackOfEnvelope;

  // Operating Expense (OpEx)
  values.hotelAnnualOperatingExpenseForBackOfEnvelope =
      values.hotelOperatingExpensePercentage
    * values.hotelAnnualRevenueForBackOfEnvelope;

  // Net Operating Income (NOI)
  values.hotelAnnualNetOperatingIncomeForBackOfEnvelope =
      values.hotelAnnualRevenueForBackOfEnvelope
    - values.hotelAnnualOperatingExpenseForBackOfEnvelope;

  // Unrealized Revenue
  values.hotelAnnualUnrealizedRevenueForBackOfEnvelope =
      values.hotelAnnualPotentialRevenueForBackOfEnvelope
    - values.hotelAnnualRevenueForBackOfEnvelope;
};

/**
 * Update the multifamily cash flow quantities for the given `values` object.
 */
const updateMultifamilyCashFlows = (values: Values) => {
    // Potential Gross Income (PGI)
    // NOTE: Multifamily PGI comes entirely from rental income.
    values.multifamilyYearOnePotentialGrossMonthlyRentalIncome =
        (values.multifamilyMicrounitQuantity * values.multifamilyYearOneMonthlyRentPerMicrounit)
      + (values.multifamilyStudioQuantity * values.multifamilyYearOneMonthlyRentPerStudio)
      + (values.multifamilyOneBedQuantity * values.multifamilyYearOneMonthlyRentPerOneBed)
      + (values.multifamilyTwoBedQuantity * values.multifamilyYearOneMonthlyRentPerTwoBed)
      + (values.multifamilyThreeBedQuantity * values.multifamilyYearOneMonthlyRentPerThreeBed);

    values.multifamilyYearOnePotentialGrossIncome =
      12 * values.multifamilyYearOnePotentialGrossMonthlyRentalIncome;

    // Vacancy
    values.multifamilyAnnualVacancyExpenseForBackOfEnvelope =
        values.multifamilyStabilizedVacancyPercentage
      * values.multifamilyYearOnePotentialGrossIncome;

    // Effective Gross Income (EGI)
    values.multifamilyAnnualEffectiveGrossIncomeForBackOfEnvelope =
        values.multifamilyYearOnePotentialGrossIncome
      - values.multifamilyAnnualVacancyExpenseForBackOfEnvelope;

    // Operating Expense (OpEx)
    values.multifamilyYearOneOperatingExpense =
        values.multifamilyOperatingExpensePercentage
      * values.multifamilyYearOnePotentialGrossIncome;

    // Net Operating Income (NOI)
    values.multifamilyAnnualNetOperatingIncomeForBackOfEnvelope =
        values.multifamilyAnnualEffectiveGrossIncomeForBackOfEnvelope
      - values.multifamilyYearOneOperatingExpense;

    // Additional Metrics
    values.multifamilyAverageYearOnePotentialGrossMonthlyRentalIncomePerLeasableArea =
      values.multifamilyNetLeasableArea === 0
        ? 0 // Guard against divide-by-zero errors.
        : (
            values.multifamilyYearOnePotentialGrossMonthlyRentalIncome
            /
            values.multifamilyNetLeasableArea
          );

    values.multifamilyAverageYearOnePotentialGrossMonthlyRentalIncomePerUnit =
      values.multifamilyTotalUnitQuantity === 0
        ? 0 // Guard against divide-by-zero errors.
        : (
            values.multifamilyYearOnePotentialGrossMonthlyRentalIncome
            /
            values.multifamilyTotalUnitQuantity
          );
};

/**
 * Update the office cash flow quantities for the given `values` object.
 */
const updateOfficeCashFlows = (values: Values) => {
  // Potential Gross Income (PGI)
  // NOTE: Office income is charged by gross area, rather than by net usable
  // area.
  values.officeYearOnePotentialGrossRentalIncome =
      values.officeGrossLeasableArea
    * values.officeYearOneRentPerArea;

  values.officeYearOnePotentialGrossExpenseReimbursementIncome =
      values.officeGrossLeasableArea
    * values.officeYearOneExpenseReimbursementFeePerArea;

  values.officeYearOnePotentialGrossIncome =
      values.officeYearOnePotentialGrossRentalIncome
    + values.officeYearOnePotentialGrossExpenseReimbursementIncome;

  // Vacancy
  values.officeAnnualVacancyExpenseForBackOfEnvelope =
      values.officeStabilizedVacancyPercentage
    * values.officeYearOnePotentialGrossIncome;

  // Effective Gross Income (EGI)
  values.officeAnnualEffectiveGrossIncomeForBackOfEnvelope =
      values.officeYearOnePotentialGrossIncome
    - values.officeAnnualVacancyExpenseForBackOfEnvelope;

  // Operating Expense (OpEx)
  values.officeYearOneOperatingExpense =
      values.officeGrossLeasableArea
    * values.officeYearOneOperatingExpensePerArea;

  // Net Operating Income (NOI)
  values.officeAnnualNetOperatingIncomeForBackOfEnvelope =
      values.officeAnnualEffectiveGrossIncomeForBackOfEnvelope
    - values.officeYearOneOperatingExpense;
};

/**
 * Update the retail cash flow quantities for the given `values` object.
 */
const updateRetailCashFlows = (values: Values) => {
  // Potential Gross Income (PGI)
  // NOTE: Retail income is charged by the net leasable (i.e., usable) area.
  values.retailYearOnePotentialGrossRentalIncome =
      values.retailNetLeasableArea
    * values.retailYearOneRentPerArea;

  values.retailYearOnePotentialGrossExpenseReimbursementIncome =
      values.retailNetLeasableArea
    * values.retailYearOneExpenseReimbursementFeePerArea;

  values.retailYearOnePotentialGrossIncome =
      values.retailYearOnePotentialGrossRentalIncome
    + values.retailYearOnePotentialGrossExpenseReimbursementIncome;

  // Vacancy
  values.retailAnnualVacancyExpenseForBackOfEnvelope =
      values.retailStabilizedVacancyPercentage
    * values.retailYearOnePotentialGrossIncome;

  // Effective Gross Income (EGI)
  values.retailAnnualEffectiveGrossIncomeForBackOfEnvelope =
      values.retailYearOnePotentialGrossIncome
    - values.retailAnnualVacancyExpenseForBackOfEnvelope;

  // Operating Expense (OpEx)
  values.retailYearOneOperatingExpense =
      values.retailNetLeasableArea
    * values.retailYearOneOperatingExpensePerArea;

  // Net Operating Income (NOI)
  values.retailAnnualNetOperatingIncomeForBackOfEnvelope =
      values.retailAnnualEffectiveGrossIncomeForBackOfEnvelope
    - values.retailYearOneOperatingExpense
};

/**
 * Update the industrial cash flow quantities for the given `values` object.
 */
const updateIndustrialCashFlows = (values: Values) => {
  // Potential Gross Income (PGI)
  // NOTE: Industrial income is charged by the net leasable (i.e. usable) area.
  values.industrialYearOnePotentialGrossRentalIncome =
      values.industrialNetLeasableArea
    * values.industrialYearOneRentPerArea;

  values.industrialYearOnePotentialGrossExpenseReimbursementIncome =
      values.industrialNetLeasableArea
    * values.industrialYearOneExpenseReimbursementFeePerArea;

  values.industrialYearOnePotentialGrossIncome =
      values.industrialYearOnePotentialGrossRentalIncome
    + values.industrialYearOnePotentialGrossExpenseReimbursementIncome;

  // Vacancy
  values.industrialAnnualVacancyExpenseForBackOfEnvelope =
      values.industrialStabilizedVacancyPercentage
    * values.industrialYearOnePotentialGrossIncome;

  // Effective Gross Income (EGI)
  values.industrialAnnualEffectiveGrossIncomeForBackOfEnvelope =
      values.industrialYearOnePotentialGrossIncome
    - values.industrialAnnualVacancyExpenseForBackOfEnvelope;

  // Operating Expense (OpEx)
  values.industrialYearOneOperatingExpense =
      values.industrialNetLeasableArea
    * values.industrialYearOneOperatingExpensePerArea;

  // Net Operating Income (NOI)
  values.industrialAnnualNetOperatingIncomeForBackOfEnvelope =
      values.industrialAnnualEffectiveGrossIncomeForBackOfEnvelope
    - values.industrialYearOneOperatingExpense;
};

/**
 * Update the rental uses cash flow quantities for the given `values` object.
 */
const updateRentalUsesCashFlows = (values: Values) => {
  // Potential Gross Income (PGI)
  values.rentalUsesYearOnePotentialGrossIncome =
      values.multifamilyYearOnePotentialGrossIncome
    + values.officeYearOnePotentialGrossIncome
    + values.retailYearOnePotentialGrossIncome
    + values.industrialYearOnePotentialGrossIncome;

  // Vacancy
  values.rentalUsesAnnualVacancyExpenseForBackOfEnvelope =
      values.multifamilyAnnualVacancyExpenseForBackOfEnvelope
    + values.officeAnnualVacancyExpenseForBackOfEnvelope
    + values.retailAnnualVacancyExpenseForBackOfEnvelope
    + values.industrialAnnualVacancyExpenseForBackOfEnvelope;

  // Operating Expense (OpEx)
  values.rentalUsesYearOneOperatingExpense =
      values.multifamilyYearOneOperatingExpense
    + values.officeYearOneOperatingExpense
    + values.retailYearOneOperatingExpense
    + values.industrialYearOneOperatingExpense;

  // Net Operating Income (NOI)
  values.rentalUsesAnnualNetOperatingIncomeForBackOfEnvelope =
      values.multifamilyAnnualNetOperatingIncomeForBackOfEnvelope
    + values.officeAnnualNetOperatingIncomeForBackOfEnvelope
    + values.retailAnnualNetOperatingIncomeForBackOfEnvelope
    + values.industrialAnnualNetOperatingIncomeForBackOfEnvelope;
};

/**
 * Update the income producing uses cash flow quantities for the given `values` object.
 */
const updateIncomeProducingUsesCashFlows = (values: Values) => {
  // Potential Gross Income (PGI)
  values.incomeProducingUsesAnnualPotentialGrossIncomeForBackOfEnvelope =
      values.rentalUsesYearOnePotentialGrossIncome
    + values.hotelAnnualPotentialRevenueForBackOfEnvelope;

  // Vacancy
  values.incomeProducingUsesAnnualVacancyExpenseForBackOfEnvelope =
      values.rentalUsesAnnualVacancyExpenseForBackOfEnvelope
    + values.hotelAnnualUnrealizedRevenueForBackOfEnvelope;

  // Operating Expense (OpEx)
  values.incomeProducingUsesAnnualOperatingExpenseForBackOfEnvelope =
      values.rentalUsesYearOneOperatingExpense
    + values.hotelAnnualOperatingExpenseForBackOfEnvelope;

  // Net Operating Income (NOI)
  values.incomeProducingUsesAnnualNetOperatingIncomeForBackOfEnvelope =
      values.rentalUsesAnnualNetOperatingIncomeForBackOfEnvelope
    + values.hotelAnnualNetOperatingIncomeForBackOfEnvelope;
};

/**
 * Update the project's total cash flow quantities for the given `values`
 * object.
 */
const updateTotalProjectCashFlows = (values: Values) => {
  values.projectAnnualNetOperatingIncomeForBackOfEnvelope =
    values.incomeProducingUsesAnnualNetOperatingIncomeForBackOfEnvelope
};

export default {
  ensureRequiredInputs,
  update,
};
