/**
 * An Introduction to Service Workers can be found at:
 * https://developers.google.com/web/fundamentals/primers/service-workers/
 *
 * ServiceWorkerRegistration API by MDN:
 * https://developer.mozilla.org/en-US/docs/Web/API/ServiceWorkerRegistration
 *
 * ServiceWorkerRegistration.showNotification() API by MDN:
 * https://developer.mozilla.org/en-US/docs/Web/API/ServiceWorkerRegistration
 *
 * Notifications visual aspects can be found at:
 * https://developers.google.com/web/fundamentals/push-notifications/display-a-notification
 */

// Check if its the localhost.
const isLocalhost = (
      window.location.hostname === "localhost"
  ||  window.location.hostname === "[::1]" // [::1] is the IPv6 localhost address.
  ||  window.location.hostname.match(      // 127.0.0.1/8 is considered localhost for IPv4.
        /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
      )
)

/**
 * Register the new service worker.
 */
const registerServiceWorker = async () => {
  // Register service worker only if the browser accepts it and is not the localhost.
  if ("serviceWorker" in navigator && !isLocalhost) {
    let registration = await navigator.serviceWorker.register("/serviceWorker.js");
    try {
      navigator.serviceWorker.addEventListener("message", (event) => {
        // Catch message from service worker notifying that is active.
        if (event.data === "ACTIVE") {
          // Launch notification of new release.
          notifyNewRelease(registration);
        }
      });
      // Check every two minutes if a a new service worker is available.
      setInterval(() => {registration.update();}, 120000);
    } catch(error) {
      console.warn("Service Worker Failed to Register ", error);
    }
  }
}

/**
 * Show notification of new release.
 */
const notifyNewRelease = async (registration) => {
  if ("Notification" in window) {
    let permission = await Notification.requestPermission();
    if (permission === "granted") {
      const title = "New Release Available";
      const options = {
        body: "Please reload the page",
        requireInteraction: true
      };
      registration.showNotification(title, options);
    }
  }
}

export default registerServiceWorker;
