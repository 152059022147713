import { Values } from  "../../../../types/Development/Values";

/**
 * @fileoverview This module is responsible for computations pertaining to
 *  buildable quantities for a project. This includes such values as the number
 *  of units or rooms for certain usages, as well as floor area totals.
 */

const DEFAULT_INPUTS: Partial<Values> = {
  // Toggles
  multifamilyToggle: false,
  condoToggle: false,
  hotelToggle: false,
  officeToggle: false,
  retailToggle: false,
  industrialToggle: false,

  // Multifamily
  multifamilyMicrounitQuantityToggleable: 0,
  multifamilyStudioQuantityToggleable: 0,
  multifamilyOneBedQuantityToggleable: 0,
  multifamilyTwoBedQuantityToggleable: 0,
  multifamilyThreeBedQuantityToggleable: 0,
  multifamilyAmenitiesAreaToggleable: 0,
  multifamilyMicrounitArea: 0,
  multifamilyStudioArea: 0,
  multifamilyOneBedArea: 0,
  multifamilyTwoBedArea: 0,
  multifamilyThreeBedArea: 0,
  multifamilyLossFactor: 0,

  // Condo
  condoMicrounitQuantityToggleable: 0,
  condoStudioQuantityToggleable: 0,
  condoOneBedQuantityToggleable: 0,
  condoTwoBedQuantityToggleable: 0,
  condoThreeBedQuantityToggleable: 0,
  condoAmenitiesAreaToggleable: 0,
  condoMicrounitArea: 0,
  condoStudioArea: 0,
  condoOneBedArea: 0,
  condoTwoBedArea: 0,
  condoThreeBedArea: 0,
  condoLossFactor: 0,

  // Hotel
  hotelRoomQuantityToggleable: 0,
  hotelAmenitiesAreaToggleable: 0,
  hotelAverageRoomArea: 0,
  hotelLossFactor: 0,

  // Office
  officeNetUsableAreaToggleable: 0,
  officeCommonAreaFactor: 0,

  // Retail
  retailNetLeasableAreaToggleable: 0,
  retailLossFactor: 0,

  // Industrial
  industrialNetLeasableAreaToggleable: 0,
  industrialLossFactor: 0,

  // Parking
  parkingSpaceArea: 0,
  parkingReductionFactor: 0,
  parkingRatioCondo: 0,
  parkingRatioMultifamily: 0,
  parkingRatioHotel: 0,
  parkingRatioOffice: 0,
  parkingRatioRetail: 0,
  parkingRatioIndustrial: 0,
};

/**
 * Initialize the buildable quantities of the given values object.
 *
 * @param {Object} values - A development values object. If any required
 *     buildable input is missing, the value from DEFAULT_INPUTS is applied.
 */
const ensureRequiredInputs = (values: Values) => {
  Object.assign(
    values,
    {...DEFAULT_INPUTS, ...values}
  );
};

/**
 * Update the buildable quantities on the given values object.
 *
 * @param {Object} values - A development values object that may not have fully
 *    updated buildable quantities.
 */
const update = (values: Values) => {
  updateToggledValues(values);
  updateCondo(values);
  updateHotel(values);
  updateMultifamily(values);
  updateOffice(values);
  updateRetail(values);
  updateIndustrial(values);
  updateRequiredParking(values);
  updateTotalProject(values);
  updateCombinationTotals(values);
  updateUseFractions(values);
};

/**
 * Update the toggleable values.
 */
const updateToggledValues = (values: Values) => {
  // Condo
  values.condoMicrounitQuantity = values.condoToggle
      ? values.condoMicrounitQuantityToggleable
      : 0;
  values.condoStudioQuantity = values.condoToggle
      ? values.condoStudioQuantityToggleable
      : 0;
  values.condoOneBedQuantity = values.condoToggle
      ? values.condoOneBedQuantityToggleable
      : 0;
  values.condoTwoBedQuantity = values.condoToggle
      ? values.condoTwoBedQuantityToggleable
      : 0;
  values.condoThreeBedQuantity = values.condoToggle
      ? values.condoThreeBedQuantityToggleable
      : 0;
  values.condoAmenitiesArea = values.condoToggle
      ? values.condoAmenitiesAreaToggleable
      : 0;

  // Hotel
  values.hotelRoomQuantity = values.hotelToggle
      ? values.hotelRoomQuantityToggleable
      : 0;
  values.hotelAmenitiesArea = values.hotelToggle
      ? values.hotelAmenitiesAreaToggleable
      : 0;

  // Multifamily
  values.multifamilyMicrounitQuantity = values.multifamilyToggle
      ? values.multifamilyMicrounitQuantityToggleable
      : 0;
  values.multifamilyStudioQuantity = values.multifamilyToggle
      ? values.multifamilyStudioQuantityToggleable
      : 0;
  values.multifamilyOneBedQuantity = values.multifamilyToggle
      ? values.multifamilyOneBedQuantityToggleable
      : 0;
  values.multifamilyTwoBedQuantity = values.multifamilyToggle
      ? values.multifamilyTwoBedQuantityToggleable
      : 0;
  values.multifamilyThreeBedQuantity = values.multifamilyToggle
      ? values.multifamilyThreeBedQuantityToggleable
      : 0;
  values.multifamilyAmenitiesArea = values.multifamilyToggle
      ? values.multifamilyAmenitiesAreaToggleable
      : 0;

  // Office
  values.officeNetUsableArea = values.officeToggle
      ? values.officeNetUsableAreaToggleable
      : 0;

  // Retail
  values.retailNetLeasableArea = values.retailToggle
      ? values.retailNetLeasableAreaToggleable
      : 0;

  // Industrial
  values.industrialNetLeasableArea = values.industrialToggle
      ? values.industrialNetLeasableAreaToggleable
      : 0;
};

/**
 * Update the condo buildable quantities on the given values object.
 */
const updateCondo = (values: Values) => {
  values.condoTotalUnitQuantity =
      values.condoMicrounitQuantity
    + values.condoStudioQuantity
    + values.condoOneBedQuantity
    + values.condoTwoBedQuantity
    + values.condoThreeBedQuantity;

  values.condoNonMicrounitQuantity =
      values.condoTotalUnitQuantity
    - values.condoMicrounitQuantity;

  values.condoTotalMicrounitArea =
      values.condoMicrounitQuantity
    * values.condoMicrounitArea;

  values.condoTotalStudioArea =
      values.condoStudioQuantity
    * values.condoStudioArea;

  values.condoTotalOneBedArea =
      values.condoOneBedQuantity
    * values.condoOneBedArea;

  values.condoTotalTwoBedArea =
      values.condoTwoBedQuantity
    * values.condoTwoBedArea;

  values.condoTotalThreeBedArea =
      values.condoThreeBedQuantity
    * values.condoThreeBedArea;

  // Make amenities zero if there are no units.
  values.condoAmenitiesArea =
    values.condoTotalUnitQuantity > 0
      ? values.condoAmenitiesArea
      : 0;

  values.condoNetSellableArea =
      values.condoTotalMicrounitArea
    + values.condoTotalStudioArea
    + values.condoTotalOneBedArea
    + values.condoTotalTwoBedArea
    + values.condoTotalThreeBedArea;

  values.condoGrossBuildableArea =
    (
        (values.condoNetSellableArea + values.condoAmenitiesArea)
      / (1 - values.condoLossFactor)
    );

  // Additional Metrics
  values.condoAverageAreaPerUnit =
    (
        values.condoNetSellableArea
      / values.condoTotalUnitQuantity
    )
    || 0; // Guard against NaN errors.
};

/**
 * Update the hotel buildable quantities on the given values object.
 */
const updateHotel = (values: Values) => {
  values.hotelNetBookableArea =
      values.hotelRoomQuantity
    * values.hotelAverageRoomArea;

  // Make amenities zero if there are no rooms.
  values.hotelAmenitiesArea =
    values.hotelRoomQuantity > 0
      ? values.hotelAmenitiesArea
      : 0;

  values.hotelGrossBuildableArea =
    (
        (values.hotelNetBookableArea + values.hotelAmenitiesArea)
      / (1 - values.hotelLossFactor)
    );
};

/**
 * Update the multifamily buildable quantities on the given values object.
 */
const updateMultifamily = (values: Values) => {
  values.multifamilyTotalUnitQuantity =
      values.multifamilyMicrounitQuantity
    + values.multifamilyStudioQuantity
    + values.multifamilyOneBedQuantity
    + values.multifamilyTwoBedQuantity
    + values.multifamilyThreeBedQuantity;

  values.multifamilyNonMicrounitQuantity =
      values.multifamilyTotalUnitQuantity
    - values.multifamilyMicrounitQuantity;

  values.multifamilyTotalMicrounitArea =
      values.multifamilyMicrounitQuantity
    * values.multifamilyMicrounitArea;

  values.multifamilyTotalStudioArea =
      values.multifamilyStudioQuantity
    * values.multifamilyStudioArea;

  values.multifamilyTotalOneBedArea =
      values.multifamilyOneBedQuantity
    * values.multifamilyOneBedArea;

  values.multifamilyTotalTwoBedArea =
      values.multifamilyTwoBedQuantity
    * values.multifamilyTwoBedArea;

  values.multifamilyTotalThreeBedArea =
      values.multifamilyThreeBedQuantity
    * values.multifamilyThreeBedArea;

  // Make amenities zero if there are no units.
  values.multifamilyAmenitiesArea =
    values.multifamilyTotalUnitQuantity > 0
      ? values.multifamilyAmenitiesArea
      : 0;

  values.multifamilyNetLeasableArea =
      values.multifamilyTotalMicrounitArea
    + values.multifamilyTotalStudioArea
    + values.multifamilyTotalOneBedArea
    + values.multifamilyTotalTwoBedArea
    + values.multifamilyTotalThreeBedArea;

  values.multifamilyGrossBuildableArea =
    (
        (values.multifamilyNetLeasableArea + values.multifamilyAmenitiesArea)
      / (1 - values.multifamilyLossFactor)
    );

  // Additional Metrics
  values.multifamilyAverageAreaPerUnit =
    (
        values.multifamilyNetLeasableArea
      / values.multifamilyTotalUnitQuantity
    )
    || 0; // Guard against NaN errors.
};

/**
 * Update the office buildable quantities on the given values object.
 */
const updateOffice = (values: Values) => {
  values.officeGrossLeasableArea =
      values.officeNetUsableArea
    / (1 - values.officeCommonAreaFactor);

  values.officeGrossBuildableArea =
    values.officeGrossLeasableArea;
};

/**
 * Update the retail buildable quantities on the given values object.
 */
const updateRetail = (values: Values) => {
  values.retailGrossBuildableArea =
      values.retailNetLeasableArea
    / (1 - values.retailLossFactor);
};

/**
 * Update the industrial buildable quantities on the given values object.
 */
const updateIndustrial = (values: Values) => {
  values.industrialGrossBuildableArea =
      values.industrialNetLeasableArea
    / (1 - values.industrialLossFactor);
};

/**
 * Update the required and buildable parking values.
 */
const updateRequiredParking = (values: Values) => {
  // Before Reduction
  values.parkingRequiredSpacesBeforeReductionCondo =
    Math.ceil(values.parkingRatioCondo * values.condoTotalUnitQuantity);

  values.parkingRequiredSpacesBeforeReductionHotel =
    Math.ceil(values.parkingRatioHotel * values.hotelRoomQuantity);

  values.parkingRequiredSpacesBeforeReductionMultifamily =
    Math.ceil(values.parkingRatioMultifamily * values.multifamilyTotalUnitQuantity);

  // NOTE: Calculated on a per-92.903-m² (~ 1000-sf ) basis.
  values.parkingRequiredSpacesBeforeReductionOffice =
    Math.ceil(values.parkingRatioOffice * values.officeGrossBuildableArea / 92.903);

  // NOTE: Calculated on a per-92.903-m² (~ 1000-sf ) basis.
  values.parkingRequiredSpacesBeforeReductionRetail =
    Math.ceil(values.parkingRatioRetail * values.retailGrossBuildableArea / 92.903);

  // NOTE: Calculated on a per-92.903-m² (~ 1000-sf ) basis.
  values.parkingRequiredSpacesBeforeReductionIndustrial =
    Math.ceil(values.parkingRatioIndustrial * values.industrialGrossBuildableArea / 92.903);

  values.parkingRequiredSpacesBeforeReductionRentalUses =
      values.parkingRequiredSpacesBeforeReductionMultifamily
    + values.parkingRequiredSpacesBeforeReductionOffice
    + values.parkingRequiredSpacesBeforeReductionRetail
    + values.parkingRequiredSpacesBeforeReductionIndustrial;

  values.parkingRequiredSpacesBeforeReduction =
      values.parkingRequiredSpacesBeforeReductionCondo
    + values.parkingRequiredSpacesBeforeReductionHotel
    + values.parkingRequiredSpacesBeforeReductionRentalUses;

  // After Reduction
  values.parkingRequiredSpacesAfterReductionCondo =
    Math.ceil(
        (1 - values.parkingReductionFactor)
      * values.parkingRequiredSpacesBeforeReductionCondo
    );

  values.parkingRequiredSpacesAfterReductionHotel =
    Math.ceil(
        (1 - values.parkingReductionFactor)
      * values.parkingRequiredSpacesBeforeReductionHotel
    );

  values.parkingRequiredSpacesAfterReductionMultifamily =
    Math.ceil(
        (1 - values.parkingReductionFactor)
      * values.parkingRequiredSpacesBeforeReductionMultifamily
    );

  values.parkingRequiredSpacesAfterReductionOffice =
    Math.ceil(
        (1 - values.parkingReductionFactor)
      * values.parkingRequiredSpacesBeforeReductionOffice
    );

  values.parkingRequiredSpacesAfterReductionRetail =
    Math.ceil(
        (1 - values.parkingReductionFactor)
      * values.parkingRequiredSpacesBeforeReductionRetail
    );

  values.parkingRequiredSpacesAfterReductionIndustrial =
    Math.ceil(
        (1 - values.parkingReductionFactor)
      * values.parkingRequiredSpacesBeforeReductionIndustrial
    );

  values.parkingRequiredSpacesAfterReductionRentalUses =
      values.parkingRequiredSpacesAfterReductionMultifamily
    + values.parkingRequiredSpacesAfterReductionOffice
    + values.parkingRequiredSpacesAfterReductionRetail
    + values.parkingRequiredSpacesAfterReductionIndustrial;

  values.parkingRequiredSpacesAfterReduction =
      values.parkingRequiredSpacesAfterReductionCondo
    + values.parkingRequiredSpacesAfterReductionHotel
    + values.parkingRequiredSpacesAfterReductionRentalUses;

  // Eliminated Spaces
  values.parkingSpacesEliminatedByReductionCondo =
      values.parkingRequiredSpacesBeforeReductionCondo
    - values.parkingRequiredSpacesAfterReductionCondo;

  values.parkingSpacesEliminatedByReductionHotel =
      values.parkingRequiredSpacesBeforeReductionHotel
    - values.parkingRequiredSpacesAfterReductionHotel;

  values.parkingSpacesEliminatedByReductionMultifamily =
      values.parkingRequiredSpacesBeforeReductionMultifamily
    - values.parkingRequiredSpacesAfterReductionMultifamily;

  values.parkingSpacesEliminatedByReductionOffice =
      values.parkingRequiredSpacesBeforeReductionOffice
    - values.parkingRequiredSpacesAfterReductionOffice;

  values.parkingSpacesEliminatedByReductionRetail =
      values.parkingRequiredSpacesBeforeReductionRetail
    - values.parkingRequiredSpacesAfterReductionRetail;

  values.parkingSpacesEliminatedByReductionIndustrial =
      values.parkingRequiredSpacesBeforeReductionIndustrial
    - values.parkingRequiredSpacesAfterReductionIndustrial;

  values.parkingSpacesEliminatedByReductionRentalUses =
      values.parkingRequiredSpacesBeforeReductionRentalUses
    - values.parkingRequiredSpacesAfterReductionRentalUses;

  values.parkingSpacesEliminatedByReduction =
      values.parkingRequiredSpacesBeforeReduction
    - values.parkingRequiredSpacesAfterReduction;

  // Gross Buildable Areas
  // These are computed using the reduced parking requirements.
  values.parkingGrossBuildableAreaForCondo =
      values.parkingRequiredSpacesAfterReductionCondo
    * values.parkingSpaceArea;

  values.parkingGrossBuildableAreaForHotel =
      values.parkingRequiredSpacesAfterReductionHotel
    * values.parkingSpaceArea;

  values.parkingGrossBuildableAreaForRentalUses =
      values.parkingRequiredSpacesAfterReductionRentalUses
    * values.parkingSpaceArea;

  values.parkingGrossBuildableArea =
      values.parkingGrossBuildableAreaForCondo
    + values.parkingGrossBuildableAreaForHotel
    + values.parkingGrossBuildableAreaForRentalUses;
};

/**
 * Update the overall project buildable quantities on the given values object.
 */
const updateTotalProject = (values: Values) => {
  values.projectNetLeasableOrSellableArea =
      values.multifamilyNetLeasableArea
    + values.condoNetSellableArea
    + values.hotelNetBookableArea
    + values.officeGrossLeasableArea
    + values.retailNetLeasableArea
    + values.industrialNetLeasableArea;

  values.projectGrossBuildableArea =
      values.multifamilyGrossBuildableArea
    + values.condoGrossBuildableArea
    + values.hotelGrossBuildableArea
    + values.officeGrossBuildableArea
    + values.industrialGrossBuildableArea
    + values.retailGrossBuildableArea
    + values.parkingGrossBuildableArea;
};

/**
 * Update totals for specific combinations of uses.
 */
const updateCombinationTotals = (values: Values) => {
  values.nonParkingGrossBuildableArea =
      values.projectGrossBuildableArea
    - values.parkingGrossBuildableArea;

  values.rentalUsesGrossBuildableArea =
      values.multifamilyGrossBuildableArea
    + values.officeGrossBuildableArea
    + values.retailGrossBuildableArea
    + values.industrialGrossBuildableArea;

  values.condoGrossBuildableAreaIncludingParking =
      values.condoGrossBuildableArea
    + values.parkingGrossBuildableAreaForCondo;

  values.hotelGrossBuildableAreaIncludingParking =
      values.hotelGrossBuildableArea
    + values.parkingGrossBuildableAreaForHotel;

  values.rentalUsesGrossBuildableAreaIncludingParking =
      values.rentalUsesGrossBuildableArea
    + values.parkingGrossBuildableAreaForRentalUses;
};

/**
 * Update the fractions of project totals that are attributed to each use.
 */
const updateUseFractions = (values: Values) => {
  values.condoFractionOfNonParkingGrossBuildableArea =
    values.nonParkingGrossBuildableArea !== 0
      ? values.condoGrossBuildableArea / values.nonParkingGrossBuildableArea
      : 0; // Guard against divide-by-zero errors.

  values.hotelFractionOfNonParkingGrossBuildableArea =
    values.nonParkingGrossBuildableArea !== 0
      ? values.hotelGrossBuildableArea / values.nonParkingGrossBuildableArea
      : 0; // Guard against divide-by-zero errors.

  values.rentalUsesFractionOfNonParkingGrossBuildableArea =
    values.nonParkingGrossBuildableArea !== 0
      ? values.rentalUsesGrossBuildableArea / values.nonParkingGrossBuildableArea
      : 0; // Guard against divide-by-zero errors.
};

export default {
  ensureRequiredInputs,
  update
};
