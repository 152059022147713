import React from "react";
import BaseRow from "../../../sharedComponents/BaseRow";

const BORDER_WIDTH = 0.6;
const FONT_SIZE = 7.68;

const DottedBorderRow = (props) => {
  props = {
    ...props,
    borderTop: BORDER_WIDTH,
    borderColor: "#999999",
    borderStyle: "dashed",
    labelFontFamily: "Roboto",
    valueFontFamily: "Roboto",
    fontSize: FONT_SIZE
  }

  return <BaseRow {...props} />;
}

export default DottedBorderRow;
