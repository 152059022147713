import React from "react";
import { connect } from "react-redux";
import Pdf from "../../../../../../types/Pdf";
import { pdfActions, pdfSelectors } from "../../../../../../state/pdf";
import valueFormatter from "../../../../../../utils/valueFormatter";
import Format from "../../../../../../types/Format";

const mapStateToProps = (state) => {
  return {
    toContactDetails: pdfSelectors.getToContactDetails(state),
  }
}

const mapDispatchToProps = {
  setToContactsDetails: pdfActions.setToContactsDetails,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;
type Props = StateProps & DispatchProps;

class ToContactDetails extends React.PureComponent<Props, {}>  {
  /**
   * Save to contact details changes in the store.
   */
  onInputChange = (value, contactDetailsKey: keyof Pdf.ContactDetails) => {
    this.props.setToContactsDetails({ [contactDetailsKey]: value });
  }

  render() {
    const { toContactDetails } = this.props;

    return (
      <div className="component--to-contact-details" >
        <div className="label-container">
          <div className="contact-icon" />
          <label>To</label>
        </div>
        <div className="inputs-container">
          <input
            className="name"
            onChange={(event) => this.onInputChange(event.target.value, "name")}
            placeholder="name"
            autoComplete="is-off"
            value={toContactDetails.name}
          />
          <input
            className="company-name"
            onChange={(event) => this.onInputChange(event.target.value, "companyName")}
            placeholder="company name"
            autoComplete="is-off"
            value={toContactDetails.companyName}
          />
          <div className="email-container with-label">
            <span className="email-icon input-label" />
            <input
              className="email"
              onChange={(event) => this.onInputChange(event.target.value, "email")}
              placeholder="email address"
              autoComplete="is-off"
              value={toContactDetails.email}
            />
          </div>
          <div className="phone-container with-label">
            <span className="phone-icon input-label" />
            <input
              className="phone-number"
              onChange={(event) => this.onInputChange(valueFormatter.format(event.target.value, { type: Format.Type.PhoneNumber }), "phoneNumber")}
              placeholder="phone number"
              autoComplete="is-off"
              value={toContactDetails.phoneNumber}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ToContactDetails);
