/**
 * Repair corrupted data that could occur in certain rare conditions, in which migration 3 could be run
 * multiple times on the user document, resulting in multiple levels of nested objects being assigned
 * to the `name` fields of the user's projects list.
 */
const repairProjectsList = (user) => {
  for (let projectId of Object.keys(user.projects)) {
    let projectObject = user.projects[projectId];

    while (typeof projectObject.name !== "string") projectObject = projectObject.name;
    const projectName = projectObject.name;

    user.projects[projectId].name = projectName;
  }
}

export default repairProjectsList;
