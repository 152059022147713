import { Values } from  "../../../../types/Development/Values";

/**
 * @fileoverview This module manages financial calculations that pertain
 *  to the costs of developing a project.
 */

const DEFAULT_INPUTS: Partial<Values> = {
  // Buildables
  parkingGrossBuildableArea: 0,
  parkingGrossBuildableAreaForCondo: 0,
  parkingGrossBuildableAreaForHotel: 0,
  parkingGrossBuildableAreaForRentalUses: 0,

  condoGrossBuildableArea: 0,
  hotelGrossBuildableArea: 0,
  multifamilyGrossBuildableArea: 0,
  officeGrossBuildableArea: 0,
  retailGrossBuildableArea: 0,
  industrialGrossBuildableArea: 0,

  condoGrossBuildableAreaIncludingParking: 0,
  hotelGrossBuildableAreaIncludingParking: 0,
  rentalUsesGrossBuildableAreaIncludingParking: 0,

  // Cost Parameters
  parcelArea: 0,
  parcelPurchasePrice: 0,
  existingStructureArea: 0,
  existingStructureDemolitionCostPerArea: 0,

  parkingHardCostPerArea: 0,
  condoHardCostPerArea: 0,
  hotelHardCostPerArea: 0,
  multifamilyHardCostPerArea: 0,
  officeHardCostPerArea: 0,
  retailHardCostPerArea: 0,
  industrialHardCostPerArea: 0,

  softCostPercentage: 0,
  contingencyCostPercentage: 0,

  parkingReductionFeePerSpace: 0,
  parkingSpacesEliminatedByReductionCondo: 0,
  parkingSpacesEliminatedByReductionHotel: 0,
  parkingSpacesEliminatedByReductionRentalUses: 0,
  parkingSpacesEliminatedByReduction: 0,
};

/**
 * Initialize the given `values` object. Ensures the object has something
 * defined for the minimum set of variables required for this module to operate.
 *
 * @param {Object} values - A development values object. If any required
 *  financial input is missing, the value from DEFAULT_INPUTS is applied.
 */
const ensureRequiredInputs = (values: Values) => {
  Object.assign(
    values,
    {...DEFAULT_INPUTS, ...values}
  );
};

/**
 * Update the financial quantities on the given `values` object.
 *
 * @param {Object} values - A development values object that may not have fully
 *    updated financial quantities.
 */
const update = (values: Values) => {
  updatePurchaseCosts(values);
  updateDemolitionCosts(values);
  updateParkingConstructionCosts(values);
  updateParkingReductionFees(values);
  updateCondoCosts(values);
  updateHotelCosts(values);
  updateMultifamilyCosts(values);
  updateOfficeCosts(values);
  updateRetailCosts(values);
  updateIndustrialCosts(values);
  updateRentalUsesCosts(values);
  updateIncomeProducingUsesCosts(values);
  updateTotalProjectCosts(values);
  updatePerAreaAverageCosts(values);
};

/**
 * Update the breakdowns of property purchase price.
 */
const updatePurchaseCosts = (values: Values) => {
  values.parcelPurchasePricePerArea =
    values.parcelArea === 0
      ? 0 // Guard against divide-by-zero errors.
      : (
          values.parcelPurchasePrice
          /
          values.parcelArea
        );

  values.parcelPurchasePriceAttributedToCondo =
      values.parcelPurchasePrice
    * values.condoFractionOfNonParkingGrossBuildableArea;

  values.parcelPurchasePriceAttributedToHotel =
      values.parcelPurchasePrice
    * values.hotelFractionOfNonParkingGrossBuildableArea;

  values.parcelPurchasePriceAttributedToRentalUses =
      values.parcelPurchasePrice
    * values.rentalUsesFractionOfNonParkingGrossBuildableArea;
};

/**
 * Update the breakdowns of existing structure demolition costs.
 */
const updateDemolitionCosts = (values: Values) => {
  values.existingStructureDemolitionCost =
      values.existingStructureDemolitionCostPerArea
    * values.existingStructureArea;

  values.existingStructureDemolitionCostAttributedToCondo =
      values.existingStructureDemolitionCost
    * values.condoFractionOfNonParkingGrossBuildableArea;

  values.existingStructureDemolitionCostAttributedToHotel =
      values.existingStructureDemolitionCost
    * values.hotelFractionOfNonParkingGrossBuildableArea;

  values.existingStructureDemolitionCostAttributedToRentalUses =
      values.existingStructureDemolitionCost
    * values.rentalUsesFractionOfNonParkingGrossBuildableArea;
};

/**
 * Update parking construction costs for the given `values` object.
 */
const updateParkingConstructionCosts = (values: Values) => {
  values.parkingHardCost =
      values.parkingGrossBuildableArea
    * values.parkingHardCostPerArea;

  values.parkingSoftCost =
      values.softCostPercentage
    * values.parkingHardCost;

  values.parkingContingencyCost =
      values.contingencyCostPercentage
    * (values.parkingHardCost + values.parkingSoftCost);

  values.parkingConstructionCost =
      values.parkingHardCost
    + values.parkingSoftCost
    + values.parkingContingencyCost;

  // Condo Parking
  values.parkingHardCostAttributedToCondo =
      values.parkingGrossBuildableAreaForCondo
    * values.parkingHardCostPerArea;

  values.parkingSoftCostAttributedToCondo =
      values.softCostPercentage
    * values.parkingHardCostAttributedToCondo;

  values.parkingContingencyCostAttributedToCondo =
      values.contingencyCostPercentage
    * (values.parkingHardCostAttributedToCondo + values.parkingSoftCostAttributedToCondo);

  values.parkingConstructionCostAttributedToCondo =
      values.parkingHardCostAttributedToCondo
    + values.parkingSoftCostAttributedToCondo
    + values.parkingContingencyCostAttributedToCondo;

  // Hotel Parking
  values.parkingHardCostAttributedToHotel =
      values.parkingGrossBuildableAreaForHotel
    * values.parkingHardCostPerArea;

  values.parkingSoftCostAttributedToHotel =
      values.softCostPercentage
    * values.parkingHardCostAttributedToHotel;

  values.parkingContingencyCostAttributedToHotel =
      values.contingencyCostPercentage
    * (values.parkingHardCostAttributedToHotel + values.parkingSoftCostAttributedToHotel);

  values.parkingConstructionCostAttributedToHotel =
      values.parkingHardCostAttributedToHotel
    + values.parkingSoftCostAttributedToHotel
    + values.parkingContingencyCostAttributedToHotel;

  // Rental Uses Parking
  values.parkingHardCostAttributedToRentalUses =
      values.parkingGrossBuildableAreaForRentalUses
    * values.parkingHardCostPerArea;

  values.parkingSoftCostAttributedToRentalUses =
      values.softCostPercentage
    * values.parkingHardCostAttributedToRentalUses;

  values.parkingContingencyCostAttributedToRentalUses =
      values.contingencyCostPercentage
    * (values.parkingHardCostAttributedToRentalUses + values.parkingSoftCostAttributedToRentalUses);

  values.parkingConstructionCostAttributedToRentalUses =
      values.parkingHardCostAttributedToRentalUses
    + values.parkingSoftCostAttributedToRentalUses
    + values.parkingContingencyCostAttributedToRentalUses;

  // Additional Metrics
  values.parkingHardCostPerSpace =
    values.parkingRequiredSpacesAfterReduction === 0
      ? 0 // Guard against divide-by-zero errors.
      : (
          values.parkingHardCost
          /
          values.parkingRequiredSpacesAfterReduction
        );
};

/**
 * Update the cost of parking reduction for the given `values` object.
 */
const updateParkingReductionFees = (values: Values) => {
  values.parkingReductionFeeAttributedToCondo =
      values.parkingReductionFeePerSpace
    * values.parkingSpacesEliminatedByReductionCondo;

  values.parkingReductionFeeAttributedToHotel =
      values.parkingReductionFeePerSpace
    * values.parkingSpacesEliminatedByReductionHotel;

  values.parkingReductionFeeAttributedToRentalUses =
      values.parkingReductionFeePerSpace
    * values.parkingSpacesEliminatedByReductionRentalUses;

  values.parkingReductionFee =
      values.parkingReductionFeePerSpace
    * values.parkingSpacesEliminatedByReduction;
};

/**
 * Update condo development costs for the given `values` object.
 */
const updateCondoCosts = (values: Values) => {
  values.condoHardCost =
      values.condoGrossBuildableArea
    * values.condoHardCostPerArea;

  values.condoSoftCost =
      values.softCostPercentage
    * values.condoHardCost;

  values.condoContingencyCost =
      values.contingencyCostPercentage
    * (values.condoHardCost + values.condoSoftCost);

  values.condoConstructionCostNotIncludingParking =
      values.condoHardCost
    + values.condoSoftCost
    + values.condoContingencyCost;

  values.condoTotalDevelopmentCostIncludingParking =
      values.condoConstructionCostNotIncludingParking
    + values.parkingConstructionCostAttributedToCondo
    + values.parkingReductionFeeAttributedToCondo
    + values.parcelPurchasePriceAttributedToCondo
    + values.existingStructureDemolitionCostAttributedToCondo;

  values.condoDevelopmentCostPerAreaIncludingParking =
    values.condoGrossBuildableAreaIncludingParking === 0
      ? 0 // Guard against divide-by-zero errors.
      : (
            values.condoTotalDevelopmentCostIncludingParking
          / values.condoGrossBuildableAreaIncludingParking
        );
};

/**
 * Update hotel development costs for the given `values` object.
 */
const updateHotelCosts = (values: Values) => {
  values.hotelHardCost =
      values.hotelGrossBuildableArea
    * values.hotelHardCostPerArea;

  values.hotelSoftCost =
      values.softCostPercentage
    * values.hotelHardCost;

  values.hotelContingencyCost =
      values.contingencyCostPercentage
    * (values.hotelHardCost + values.hotelSoftCost);

  values.hotelConstructionCostNotIncludingParking =
      values.hotelHardCost
    + values.hotelSoftCost
    + values.hotelContingencyCost;

  values.hotelTotalDevelopmentCostIncludingParking =
      values.hotelConstructionCostNotIncludingParking
    + values.parkingConstructionCostAttributedToHotel
    + values.parkingReductionFeeAttributedToHotel
    + values.parcelPurchasePriceAttributedToHotel
    + values.existingStructureDemolitionCostAttributedToHotel;

  values.hotelDevelopmentCostPerAreaIncludingParking =
    values.hotelGrossBuildableAreaIncludingParking === 0
      ? 0 // Guard against divide-by-zero errors.
      : (
            values.hotelTotalDevelopmentCostIncludingParking
          / values.hotelGrossBuildableAreaIncludingParking
        );
};

/**
 * Update multifamily development costs for the given `values` object.
 */
const updateMultifamilyCosts = (values: Values) => {
  values.multifamilyHardCost =
      values.multifamilyGrossBuildableArea
    * values.multifamilyHardCostPerArea;

  values.multifamilySoftCost =
      values.softCostPercentage
    * values.multifamilyHardCost;

  values.multifamilyContingencyCost =
      values.contingencyCostPercentage
    * (values.multifamilyHardCost + values.multifamilySoftCost);

  values.multifamilyConstructionCostNotIncludingParking =
      values.multifamilyHardCost
    + values.multifamilySoftCost
    + values.multifamilyContingencyCost;
};

/**
 * Update office development costs for the given `values` object.
 */
const updateOfficeCosts = (values: Values) => {
  values.officeHardCost =
      values.officeGrossBuildableArea
    * values.officeHardCostPerArea;

  values.officeSoftCost =
      values.softCostPercentage
    * values.officeHardCost;

  values.officeContingencyCost =
      values.contingencyCostPercentage
    * (values.officeHardCost + values.officeSoftCost);

  values.officeConstructionCostNotIncludingParking =
      values.officeHardCost
    + values.officeSoftCost
    + values.officeContingencyCost;
};

/**
 * Update retail development costs for the given `values` object.
 */
const updateRetailCosts = (values: Values) => {
  values.retailHardCost =
      values.retailGrossBuildableArea
    * values.retailHardCostPerArea;

  values.retailSoftCost =
      values.softCostPercentage
    * values.retailHardCost;

  values.retailContingencyCost =
      values.contingencyCostPercentage
    * (values.retailHardCost + values.retailSoftCost);

  values.retailConstructionCostNotIncludingParking =
      values.retailHardCost
    + values.retailSoftCost
    + values.retailContingencyCost;
};

/**
 * Update industrial development costs for the given `values` object.
 */
const updateIndustrialCosts = (values: Values) => {
  values.industrialHardCost =
      values.industrialGrossBuildableArea
    * values.industrialHardCostPerArea;

  values.industrialSoftCost =
      values.softCostPercentage
    * values.industrialHardCost;

  values.industrialContingencyCost =
      values.contingencyCostPercentage
    * (values.industrialHardCost + values.industrialSoftCost);

  values.industrialConstructionCostNotIncludingParking =
      values.industrialHardCost
    + values.industrialSoftCost
    + values.industrialContingencyCost;
};

/**
 * Update aggregate rental use development costs for the given `values` object.
 */
const updateRentalUsesCosts = (values: Values) => {
  values.rentalUsesConstructionCostNotIncludingParking =
      values.multifamilyConstructionCostNotIncludingParking
    + values.officeConstructionCostNotIncludingParking
    + values.retailConstructionCostNotIncludingParking
    + values.industrialConstructionCostNotIncludingParking;

  values.rentalUsesTotalDevelopmentCostIncludingParking =
      values.rentalUsesConstructionCostNotIncludingParking
    + values.parkingConstructionCostAttributedToRentalUses
    + values.parkingReductionFeeAttributedToRentalUses
    + values.parcelPurchasePriceAttributedToRentalUses
    + values.existingStructureDemolitionCostAttributedToRentalUses;

  values.rentalUsesDevelopmentCostPerAreaIncludingParking =
    values.rentalUsesGrossBuildableAreaIncludingParking === 0
      ? 0 // Guard against divide-by-zero errors.
      : (
            values.rentalUsesTotalDevelopmentCostIncludingParking
          / values.rentalUsesGrossBuildableAreaIncludingParking
        );
};

/**
 * Update aggregate income producing use development costs for the given `values` object.
 */
const updateIncomeProducingUsesCosts = (values: Values) => {
  values.incomeProducingUsesTotalDevelopmentCostIncludingParking =
      values.rentalUsesTotalDevelopmentCostIncludingParking
    + values.hotelTotalDevelopmentCostIncludingParking;
}

/**
 * Update the overall project costs for the given `values` object.
 */
const updateTotalProjectCosts = (values: Values) => {
  values.projectHardCost =
      values.parkingHardCost
    + values.condoHardCost
    + values.hotelHardCost
    + values.multifamilyHardCost
    + values.officeHardCost
    + values.retailHardCost
    + values.industrialHardCost;

  values.projectSoftCost =
      values.parkingSoftCost
    + values.condoSoftCost
    + values.hotelSoftCost
    + values.multifamilySoftCost
    + values.officeSoftCost
    + values.retailSoftCost
    + values.industrialSoftCost;

  values.projectContingencyCost =
      values.parkingContingencyCost
    + values.condoContingencyCost
    + values.hotelContingencyCost
    + values.multifamilyContingencyCost
    + values.officeContingencyCost
    + values.retailContingencyCost
    + values.industrialContingencyCost;

  values.projectConstructionCost =
      values.projectHardCost
    + values.projectSoftCost
    + values.projectContingencyCost
    + values.parkingReductionFee;

  values.projectConstructionCostIncludingDemolition =
      values.projectConstructionCost
    + values.existingStructureDemolitionCost;

  values.projectTotalDevelopmentCost =
      values.projectConstructionCostIncludingDemolition
    + values.parcelPurchasePrice;
};

/**
 * Update the average costs per area for the given `values` object.
 */
const updatePerAreaAverageCosts = (values: Values) => {
  values.projectHardCostPerGrossBuildableArea =
    values.projectGrossBuildableArea === 0
      ? 0 // Guard against divide-by-zero errors.
      : (
          values.projectHardCost
          /
          values.projectGrossBuildableArea
        );

  values.projectConstructionCostIncludingDemolitionPerBuildableArea =
    values.projectGrossBuildableArea === 0
      ? 0 // Guard against divide-by-zero errors.
      : (
          values.projectConstructionCostIncludingDemolition
          /
          values.projectGrossBuildableArea
        );
};

export default {
  ensureRequiredInputs,
  update,
};
