import React from "react";
import wrapComponentWithPopup from "../wrapComponentWithPopup";
import LogIn from "./LogIn";
import { PopupProps } from "../wrapComponentWithPopup/wrapComponentWithPopup";

interface OwnProps {
  onStateChange(): void;
  onLogIn?(): void;
  classes?: string;
}

type Props = OwnProps & PopupProps;

class LogInPopup extends React.PureComponent<Props, {}>  {
  render() {
    const { classes, closePopup, onLogIn } = this.props;

    return (
      <div className={`component--log-in-popup ${classes || ""}`}>
        <LogIn closePopup={closePopup} onLogIn={onLogIn} />
      </div>
    );
  }
}

export default wrapComponentWithPopup(LogInPopup);
