import firebase from "firebase/app";
import databaseHelper from "../../databaseHelper";

const MISSING_RETURN_ON_COST = -101; // Flag indicating that the project still doesn't have a return on cost value.

/**
 * Add condoReturnOnCost and leaseUsesReturnOnCost to user projects.
 */
const returnOnCost = async (userData) => {
  let userModified = false;
  let projectData;
  for (let projectId of Object.keys(userData.projects)) {
    if (userData.projects[projectId].condoReturnOnCost === MISSING_RETURN_ON_COST) {
      let projectReference = firebase.firestore().doc(`projects/${projectId}`);
      projectData = await databaseHelper.getDocument(projectReference);
      userData.projects[projectId].condoReturnOnCost = projectData.values.condoReturnOnCost || 0;
      userModified = true;
    }

    if (userData.projects[projectId].leaseUsesReturnOnCost === MISSING_RETURN_ON_COST) {
      let projectReference = firebase.firestore().doc(`projects/${projectId}`);
      if (!projectData) projectData = await databaseHelper.getDocument(projectReference);
      userData.projects[projectId].leaseUsesReturnOnCost = projectData.values.incomeProducingUsesAnnualReturnOnCostForBackOfEnvelope || 0;
      userModified = true;
    }
  }

  return userModified;
}

export default returnOnCost;
