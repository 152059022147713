import React from "react";
import { Page } from "@react-pdf/renderer";
import styled from "@react-pdf/styled-components";
import Footer from "../sharedComponents/Footer";
import Header from "../sharedComponents/Header";
import Images from "./Images";
import Summary from "./Summary";
import companyInfo from "../../../../../../utils/companyInfo";
import FromContactDetails from "./FromContactDetails";
import ToContactDetails from "./ToContactDetails";
import PdfContext from "../../PdfContext";

const LEFT_PADDING = 41;

const Content = styled.View`
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  background-color: #FFFFFF;
`;

const LeftContainer = styled.View`
  height: 100%;
  width: 269px;
`;

const RightContainer = styled.View`
  flex-grow: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const Link = styled.Link`
  font-family: "Roboto Condensed Bold";
`;

const ContactsContainer = styled.View`
  height: 109px;
  padding-left: ${() => LEFT_PADDING};
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color:${(props) => props.backgroundColor}
  border-top: 1px;
  border-color: #fff;
`;

const FirstPage = () => (
  <PdfContext.Consumer>
    {({ colorPalette }) => (
      <Page size="LETTER" wrap={false}>
        <Content>
          <LeftContainer>
            <Images />
          </LeftContainer>
          <RightContainer>
            <Header leftPadding={LEFT_PADDING} />
            <ContactsContainer backgroundColor={colorPalette.primary}>
              <FromContactDetails />
              <ToContactDetails />
            </ContactsContainer>
            <Summary />
          </RightContainer>
        </Content>
        <Footer>
          Prepared with Deepblocks. Find out more at <Link href={companyInfo.WEBSITE_URL}>deepblocks.com</Link>.
        </Footer>
      </Page>
    )}
  </PdfContext.Consumer>
);

export default FirstPage;
