import firebase from "firebase/app";
import "firebase/firestore";
import { jsonDeepCopy } from "../deepCopy";
import databaseHelper, { FirebaseOperation, FirebaseOperationType } from "./utils/databaseHelper";
import pdf from "./pdf";
import DocumentNotFoundError from "../../errors/DocumentNotFoundError";

/**
 * Create a development project.
 */
const create = async (development, userAttributes, pdfDocument) => {
  const projectsCollectionReference = firebase.firestore().collection("projects");
  const newProjectDocumentReference = projectsCollectionReference.doc();
  let newProjectId: string | null = newProjectDocumentReference.id;
  let temporaryProjectData = jsonDeepCopy(development);

  const createProject: FirebaseOperation = {
    type: FirebaseOperationType.Set,
    documentReference: newProjectDocumentReference,
    data: temporaryProjectData
  }

  const createPdf = pdf.createOperation(newProjectId, pdfDocument);

  let updateUser: FirebaseOperation | null = null;
  let createDemoProject: FirebaseOperation | null = null;
  let userData;
  if (userAttributes) {
    const userReference = firebase.firestore().doc(`users/${userAttributes.id}`);

    userData = jsonDeepCopy(userAttributes.data);
    userData.currentProjectId = newProjectId;
    userData.projects[newProjectId] = {
      name: temporaryProjectData.name,
      leaseUsesReturnOnCost: temporaryProjectData.values.incomeProducingUsesAnnualReturnOnCostForBackOfEnvelope,
      condoReturnOnCost: temporaryProjectData.values.condoReturnOnCost,
      timeModified: firebase.firestore.Timestamp.now().toMillis(),
      isFromShared: temporaryProjectData.isFromShared || null,
    }

    updateUser = {
      type: FirebaseOperationType.Update,
      documentReference: userReference,
      data: userData
    }
  } else {
    const demoProjectReference = firebase.firestore().doc(`demoProjects/${newProjectId}`);

    createDemoProject = {
      type: FirebaseOperationType.Set,
      documentReference: demoProjectReference,
      data: {}
    }
  }

  try {
    if (updateUser) {
      await databaseHelper.batchWrite([updateUser, createProject, createPdf]);
    } else if (createDemoProject) {
      await databaseHelper.batchWrite([createDemoProject, createProject, createPdf]);
    }
  } catch (error) {
    console.warn(error);
    newProjectId = null;
  }

  return {
    newProjectId,
    userData,
  };
}

/**
 * Get a development project, making a copy of it when needed.
 */
const read = async (projectId, userAttributes) => {
  try {
    let projectReference = firebase.firestore().doc(`projects/${projectId}`);
    const projectData = await databaseHelper.getDocument(projectReference);
    let userData = userAttributes ? userAttributes.data : null;

    let projectIsBeingShared;
    try {
      const sharedProjectReference = firebase.firestore().doc(`sharedProjects/${projectId}`);
      projectIsBeingShared = Boolean(await databaseHelper.getDocument(sharedProjectReference));
    } catch (error) {
      if (error instanceof DocumentNotFoundError) {
        projectIsBeingShared = false;
      } else {
        throw error;
      }
    }

    let projectIsDemo;
    try {
      const demoProjectReference = firebase.firestore().doc(`demoProjects/${projectId}`);
      projectIsDemo = Boolean(await databaseHelper.getDocument(demoProjectReference));
    } catch (error) {
      if (error instanceof DocumentNotFoundError) {
        projectIsDemo = false;
      } else {
        throw error;
      }
    }

    let copyProject = false;
    if (!userAttributes && projectIsBeingShared && !projectIsDemo) copyProject = true;

    if (userAttributes) {
      const userReference = firebase.firestore().doc(`users/${userAttributes.id}`);
      const userDocument = await databaseHelper.getDocument(userReference);
      const userProjectsList = Object.keys(userDocument.projects);
      if (!userProjectsList.includes(projectId) && (projectIsBeingShared || projectIsDemo)) {
        copyProject = true;
      };
    }

    if (copyProject) {
      if (projectIsBeingShared) projectData.isFromShared = true;
      const result = await create(projectData, userAttributes, {});
      if (result) {
        projectId = result.newProjectId;
        userData = result.userData;
        projectReference = firebase.firestore().doc(`projects/${projectId}`);
      }
    }

    return {
      userData,
      projectData,
      projectReference,
      newProjectId: projectId,
    };
  } catch (error) {
    console.warn(error);
    return null;
  }
}

/**
 * Update a development project.
 */
const update = async (userAttributes, projectId, newProjectData, pdfData) => {
  let updateUser: FirebaseOperation | null = null;
  let userData;
  if (userAttributes) {
    let userReference = firebase.firestore().doc(`users/${userAttributes.id}`);
    userData = jsonDeepCopy(userAttributes.data);
    userData.projects[projectId] = {
      name: newProjectData.name,
      leaseUsesReturnOnCost: newProjectData.values.incomeProducingUsesAnnualReturnOnCostForBackOfEnvelope,
      condoReturnOnCost: newProjectData.values.condoReturnOnCost,
      timeModified: firebase.firestore.Timestamp.now().toMillis(),
      isFromShared: newProjectData.isFromShared || null,
    }

    updateUser = {
      type: FirebaseOperationType.Update,
      documentReference: userReference,
      data: userData
    }
  }

  let projectReference = firebase.firestore().doc(`projects/${projectId}`);
  let updateProject: FirebaseOperation = {
    type: FirebaseOperationType.Update,
    documentReference: projectReference,
    data: newProjectData
  }

  let updatePdf: FirebaseOperation = pdf.updateOperation(projectId, pdfData);

  try {
    updateUser
        ? await databaseHelper.batchWrite([updateProject, updatePdf, updateUser])
        : await databaseHelper.batchWrite([updateProject, updatePdf]);

    return {
      userData,
    };
  } catch (error) {
    console.warn(error);
    return null;
  }
}

/**
 * Delete a development project.
 */
const remove = async (projectId, userAttributes) => {
  const projectDocumentReference = firebase.firestore().doc(`projects/${projectId}`);
  let deleteProject: FirebaseOperation = {
    type: FirebaseOperationType.Delete,
    documentReference: projectDocumentReference,
  };

  let updateUser: FirebaseOperation | null = null;
  let userData;
  if (userAttributes) {
    let userReference = firebase.firestore().doc(`users/${userAttributes.id}`);
    userData = jsonDeepCopy(userAttributes.data);

    delete userData.projects[projectId];

    if (userData.currentProjectId === projectId) userData.currentProjectId = "";
    updateUser = {
      type: FirebaseOperationType.Update,
      documentReference: userReference,
      data: userData
    }
  }

  let deletePdf = pdf.removeOperation(projectId);

  try {
    updateUser
        ? await databaseHelper.batchWrite([updateUser, deleteProject, deletePdf])
        : await databaseHelper.batchWrite([deleteProject, deletePdf]);

    return {
      userData,
    }
  } catch (error) {
    console.error(error);
    return null;
  }
}

export default {
  create,
  read,
  update,
  remove,
}
