import React from "react";
import companyInfo from "../../../../../utils/companyInfo";

const STANDARD_MAX_INDEX = 9;
const ADVANCED_MAX_INDEX = 13;
const PRO_MAX_INDEX = 15;

const PLAN_DETAILS = [
  "Unlimited Projects",
  "Unlimited PDF Reports",
  "Demographic Data (US)",
  "Opportunity Zones (US)",
  "Local Construction Cost",
  "Local Income Values",
  "3D Building Visuals",
  "Return Analysis",
  "Draw Parcel Functionality",
  "Metric System",
  "Search Engine",
  "Parcels (1000+ cities)",
  "Property Information",
  "Combine Parcels",
  <>Zoning Tables (<a href={companyInfo.CITIES_URL} target="_blank" rel="noopener noreferrer">selected cities</a>)</>,
  "Search Engine (with Zoning)",
];

interface OwnProps {
  selectedColumn?: number;
}

type Props = OwnProps;

class SubscriptionRows extends React.PureComponent<Props, {}> {
  /**
   * Render row.
   */
  renderRow = (text, index) => {
    const { selectedColumn } = this.props;

    return (
      <div className={`feature-row ${index % 2 === 1 ? "even" : ""}`} key={index}>
        <div className="box label">{text}</div>
        <div className={`box ${selectedColumn === 0 ? "selected" : ""}`}>
          <div className={`${index <= STANDARD_MAX_INDEX ? "checked" : ""}`} />
        </div>
        <div className={`box ${selectedColumn === 1 ? "selected" : ""}`}>
          <div className={`${index <= ADVANCED_MAX_INDEX ? "checked" : ""}`} />
        </div>
        <div className={`box ${selectedColumn === 2 ? "selected" : ""}`}>
          <div className={`${index <= PRO_MAX_INDEX ? "checked" : ""}`} />
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="component--subscription-rows">
        {
          PLAN_DETAILS.map((line, index) => (
            this.renderRow(line, index)
          ))
        }
      </div>
    );
  }
}

export default SubscriptionRows;
