import turf from "../../../../utils/turf";
import presets from "../../../../utils/presets";
import developmentAccessors from "../../../../state/development/utils/developmentAccessors";
import parcelAccessors from "../../../../utils/parcel/parcelAccessors";
import { BuildingUse } from "../../../../types/BuildingUse";
import { Development } from "../../../../types/Development/Development";
import { VariableId } from "../../../../types/VariableId";
import { SetbackType } from "../../../../types/Setback";
import Unit from "../../../../types/Unit";
import { ParcelProperty } from "../../../../utils/parcel/ParcelProperty";

/**
 * Generate a complete new development object based off of the parcel data.
 */
const initializeNewDevelopment = (selectedParcel, unitSystem: Unit.System, initialValues) => {
  // `as any` is needed because of a bug in turfJS that has the `reverse` option typed as `reversed`.
  let parcel = turf.rewind(selectedParcel, { reverse: true } as any);
  // Set the properties directly from the selectedParcel object because of an issue
  // with turf that turns empty arrays into empty objects.
  parcel.properties = { ...selectedParcel.properties };
  const name = parcelAccessors.getPropertyFromLargestAssemblyParcel(selectedParcel, ParcelProperty.Address);

  let newDevelopment: Development = {
    name: name || "",
    parcel: parcel,
    camera: {
      pitch: 60,
      bearing: 45,
      zoom: 17.9,
      target: turf.getCoord(turf.centerOfMass(selectedParcel)) as [number, number],
    },
    buildingModel: {
      partitionGuideVector: { x: 1, y: 0 },
      setbackSchedule: [
        {
          highestFloor: null,
          setbacks: initializeSetbacks(selectedParcel),
        }
      ],
      usageStackingOrder: [
        BuildingUse.Retail,
        BuildingUse.Parking,
        BuildingUse.Industrial,
        BuildingUse.Office,
        BuildingUse.Hotel,
        BuildingUse.Multifamily,
        BuildingUse.Condo
      ],
      floors: []
    },
    values: initializeValues(initialValues),
    constraints: { minimums: {}, maximums: {}, increments: {} },
    unitSystem: unitSystem,
    floorsWithSetbacks: [0],
    selectedSetbackFloor: 0,
    selectedSetbackFloorIndex: 0,
  };

  presets.initialize(newDevelopment);
  applyPurchasePrice(newDevelopment, parcelAccessors.getPurchasePrice(selectedParcel));
  applyExistingStructureArea(newDevelopment, parcelAccessors.getExistingStructureArea(selectedParcel));

  return newDevelopment;
}

/**
 * Generate initial toggle values based on the given set of allowed uses.
 */
const initializeValues = (initialValues): any => {
  let values = {
    condoToggle: false,
    multifamilyToggle: false,
    hotelToggle: false,
    officeToggle: false,
    retailToggle: false,
    industrialToggle: false,
    ...initialValues
  }

  return values;
}

/**
 * Apply the purchase price value and constraints if the purchase price was available from the map data.
 */
const applyPurchasePrice = (development: Development, purchasePrice) => {
  if (purchasePrice && purchasePrice > 0) {
    const maximumPurchasePriceFactor = 3;
    const maximumPurchasePrice = purchasePrice * maximumPurchasePriceFactor;
    developmentAccessors.setInputMinimumConstrained(development, VariableId.ParcelPurchasePrice, 0);
    developmentAccessors.setInputMaximumConstrained(development, VariableId.ParcelPurchasePrice, maximumPurchasePrice);
    developmentAccessors.setInputValueConstrained(development, VariableId.ParcelPurchasePrice, purchasePrice);
  }
}

/**
 * Apply the existing structure area value and constraints if the purchase price was available from the map data.
 */
const applyExistingStructureArea = (development: Development, existingStructureArea) => {
  if (existingStructureArea && existingStructureArea > 0) {
    const maximumExistingStructureAreaFactor = 2;
    const maximumExistingStructureArea = existingStructureArea * maximumExistingStructureAreaFactor;
    developmentAccessors.setInputMinimumConstrained(development, VariableId.ExistingStructureArea, 0);
    developmentAccessors.setInputMaximumConstrained(development, VariableId.ExistingStructureArea, maximumExistingStructureArea);
    developmentAccessors.setInputValueConstrained(development, VariableId.ExistingStructureArea, existingStructureArea);
  }
}

/**
 * Initialize the parcel setbacks to type "B" given a GeoJSON feature that represents it.
 */
const initializeSetbacks = (geoJson) => {
  let type = turf.getType(geoJson);
  let coordinates = turf.getCoords(geoJson);

  let setbacks: SetbackType[][] = [];
  if (type === "Polygon") {
    setbacks = [initializeRingSetbacks(coordinates[0])]
  } else if (type === "MultiPolygon") {
    setbacks = coordinates.map(
      (polygonRings) => initializeRingSetbacks(polygonRings[0])
    );
  }
  return setbacks;
}

/**
 * Initialize to type "B" the setbacks of a given individual ring.
 */
const initializeRingSetbacks = (ring): SetbackType[] => {
  return ring.slice(0, -1).map(() => SetbackType.B);
}

export {
  initializeNewDevelopment
};
