import { AccordionSectionState , INITIAL_STATE as panelsInitialState } from "./accordionSection/reducers";
import accordionSectionReducers from "./accordionSection/reducers";

export interface SharedState {
  accordionSection: AccordionSectionState;
}

export const INITIAL_STATE: SharedState = {
  accordionSection: panelsInitialState,
}

/**
 * Determine which reducer, if any, should handle reducing the given action and
 * state.
 */
const reducer = (previousState = INITIAL_STATE, action): SharedState => {
  switch (true) {
    case /^ui\/shared\/accordionSection\/|^(?!ui).*/.test(action.type): return accordionSection(previousState, action);
    default: return previousState;
  }
}

/**
 * Delegate to the accordion section sub-concern.
 */
const accordionSection = (previousState, action): SharedState => {
  return {
    ...previousState,
    accordionSection: accordionSectionReducers(previousState.accordionSection, action),
  }
}

export default reducer;
