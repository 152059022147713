import React from "react";
import parcelAccessors from "../../../../../utils/parcel/parcelAccessors";
import authentication from "../../../../../utils/authentication";
import CellRow from "../../../CellRow";
import Cell from "../../../Cell";

type OwnProps = {
  parcelFeature: any;
}

type Props = OwnProps;

/**
 * Render unit density ratio section.
 */
const UnitDensityRatio = (props: Props) => {
  const { parcelFeature } = props;
  const userIsAuthenticated = authentication.isUserAuthenticated();
  const livingDensityRatio = parcelAccessors.getLivingDensityRatio(parcelFeature);
  const hotelDensityRatio = parcelAccessors.getHotelDensityRatio(parcelFeature);

  if (livingDensityRatio.length === 0 && hotelDensityRatio.length === 0) return null;

  return (
    <table>
      <thead>
        <tr>
          <th colSpan={2}> Unit Density</th>
        </tr>
      </thead>
      <tbody>
        {livingDensityRatio.length > 0
            ? <CellRow>
                <Cell text="Residential" />
                <Cell value={livingDensityRatio} styleVariation="bold capitalize" hiddenValues={!userIsAuthenticated} />
              </CellRow>
            : null
        }
        {hotelDensityRatio.length > 0
            ? <CellRow>
                <Cell text="Hotel" />
                <Cell value={hotelDensityRatio} styleVariation="bold capitalize" hiddenValues={!userIsAuthenticated} />
              </CellRow>
            : null
        }
      </tbody>
    </table>
  );
}

export default UnitDensityRatio;
