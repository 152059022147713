import { Config } from "../../../../../types/Config";
import { VariableId } from "../../../../../types/VariableId";
import { BuildingUse } from "../../../../../types/BuildingUse";
import Format from "../../../../../types/Format";
import Unit from "../../../../../types/Unit";

const config: Config = {
  [VariableId.RetailGrossBuildableArea]: {
    variableId: VariableId.RetailGrossBuildableArea,
    text: "Retail Gross Buildable",
    formatOptions: { type: Format.Type.Number, },
    [Unit.System.Imperial]: {
      unitTarget: Unit.Type.SquareFeet,
      formatOptions: { suffix: " SF" }
    },
    [Unit.System.Metric]: {
      unitTarget: Unit.Type.SquareMeters,
      formatOptions: { suffix: " m²" }
    }
  },

  [VariableId.RetailNetLeasableAreaToggleable]: {
    variableId: VariableId.RetailNetLeasableAreaToggleable,
    buildingUse: BuildingUse.Retail,
    text: "Net Leasable",
    formatOptions: { type: Format.Type.Number, },
    [Unit.System.Imperial]: {
      unitTarget: Unit.Type.SquareFeet,
      formatOptions: { suffix: " SF" }
    },
    [Unit.System.Metric]: {
      unitTarget: Unit.Type.SquareMeters,
      formatOptions: { suffix: " m²" }
    }
  },

  [VariableId.RetailLossFactor]: {
    variableId: VariableId.RetailLossFactor,
    buildingUse: BuildingUse.Retail,
    text: "Loss Factor",
    formatOptions: { type: Format.Type.Percentage }
  },

  [VariableId.RetailYearOnePotentialGrossIncome]: {
    variableId: VariableId.RetailYearOnePotentialGrossIncome,
    text: "Retail Income",
    formatOptions: { type: Format.Type.Currency }
  },

  [VariableId.RetailYearOneRentPerArea]: {
    variableId: VariableId.RetailYearOneRentPerArea,
    buildingUse: BuildingUse.Retail,
    text: "Lease Income",
    formatOptions: { type: Format.Type.Currency, decimalPlaces: 2 },
    [Unit.System.Imperial]: {
      unitTarget: Unit.Type.SquareFeet,
      unitIsInverse: true,
      formatOptions: { suffix: "/SF" }
    },
    [Unit.System.Metric]: {
      unitTarget: Unit.Type.SquareMeters,
      unitIsInverse: true,
      formatOptions: { suffix: "/m²" }
    }
  },

  [VariableId.RetailYearOneExpenseReimbursementFeePerArea]: {
    variableId: VariableId.RetailYearOneExpenseReimbursementFeePerArea,
    buildingUse: BuildingUse.Retail,
    text: "Expense Reimbursement",
    formatOptions: {
      type: Format.Type.Currency,
      decimalPlaces: 2
    },
    [Unit.System.Imperial]: {
      unitTarget: Unit.Type.SquareFeet,
      unitIsInverse: true,
      formatOptions: { suffix: "/SF" }
    },
    [Unit.System.Metric]: {
      unitTarget: Unit.Type.SquareMeters,
      unitIsInverse: true,
      formatOptions: { suffix: "/m²" }
    }
  },

  [VariableId.RetailAnnualNetOperatingIncomeForBackOfEnvelope]: {
    variableId: VariableId.RetailAnnualNetOperatingIncomeForBackOfEnvelope,
    text: "Retail NOI",
    formatOptions: { type: Format.Type.Currency }
  },

  [VariableId.RetailStabilizedVacancyPercentage]: {
    variableId: VariableId.RetailStabilizedVacancyPercentage,
    buildingUse: BuildingUse.Retail,
    text: "Vacancy",
    formatOptions: {
      type: Format.Type.Percentage,
      decimalPlaces: 1
    }
  },

  [VariableId.RetailYearOneOperatingExpensePerArea]: {
    variableId: VariableId.RetailYearOneOperatingExpensePerArea,
    buildingUse: BuildingUse.Retail,
    text: "OpEx per Year",
    formatOptions: {
      type: Format.Type.Currency,
      decimalPlaces: 2
    },
    [Unit.System.Imperial]: {
      unitTarget: Unit.Type.SquareFeet,
      unitIsInverse: true,
      formatOptions: { suffix: "/SF" }
    },
    [Unit.System.Metric]: {
      unitTarget: Unit.Type.SquareMeters,
      unitIsInverse: true,
      formatOptions: { suffix: "/m²" }
    }
  }
};

export default config;
