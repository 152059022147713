import React from "react";
import Unit from "../../../types/Unit";
import configHelper from "../../../utils/configHelper";

interface OwnProps {
  component: any;
  config: any;
  unitSystem: Unit.System;
  textModifier?(): string;
  [x: string]: any;
}

type Props = OwnProps;

class ConfigurationHandler extends React.Component<Props, {}> {
  render() {
    const { config, unitSystem, textModifier, ...rest } = this.props;
    if (!config) {
      console.warn("Not config");
      return null;
    }

    let currentConfig = config;
    if(currentConfig.requiresAuthentication) {
      currentConfig = configHelper.hydrateHiddenValue(currentConfig);
    }

    let unitSpecificConfig = configHelper.getConfigForUnitSystem(currentConfig, unitSystem);

    const { text } = unitSpecificConfig;
    let modifiedText = text;
    if (typeof text === "function") {
      modifiedText = textModifier ? text(textModifier()) : text();
    }

    unitSpecificConfig.text = modifiedText;

    return (
      <this.props.component {...{ ...unitSpecificConfig, ...rest }} />
    )
  }
}

export default ConfigurationHandler;
