import React from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router";
import { Route } from "react-router-dom";
import { subscriptionSelectors } from "../../../state/subscription";
import authentication from "../../../utils/authentication";
import { Path } from "../../../types/Path";
import { UserStatus } from "../../../types/UserStatus";
import { Chargebee } from "../../../types/Service/Chargebee";
import UserLoadingFeedback from "../../sharedComponents/UserLoadingFeedback";
import userHelper from "../../../utils/userHelper";

interface State {
  userIsAuthenticated: boolean | null;
}

interface Props {
  subscriptionStatus: Chargebee.SubscriptionStatus | undefined;
  allow: UserStatus[];
  component: any;
}

const DEFAULT_PATH = {
  [UserStatus.NotAuthenticated]: Path.Home,
  [UserStatus.Authenticated]: Path.Subscribe,
  [UserStatus.Verified]: Path.Subscribe,
  [UserStatus.Subscribed]: Path.Dashboard,
}

class RouteComponent extends React.Component<Props, State> {
  unsubscribeFromAuthenticationStateChange?: () => void;

  constructor(props) {
    super(props);

    this.state = {
      // This value will be null while loading the user authentication state
      // and it will be true or false once the state is loaded.
      userIsAuthenticated: null,
    };
  }

  /**
   * Adds listener to authentication.onStateChange
   * to update the state of the component when the
   * authentication state change.
   */
  componentDidMount() {
    this.unsubscribeFromAuthenticationStateChange = authentication.onStateChange(this.updateState);
  }

  /**
   * Unsubscribe from authentication onStateChange when component will unmount.
   */
  componentWillUnmount() {
    if (this.unsubscribeFromAuthenticationStateChange) {
      this.unsubscribeFromAuthenticationStateChange();
    }
  }

  /**
   * Updates userIsAuthenticated and userIsVerified states when the
   * authentication state changes.
   */
  updateState = (user) => {
    let userIsAuthenticated = user !== null;
    this.setState({
      userIsAuthenticated: userIsAuthenticated,
    });
  }

  /**
   * Renders the component based if the current user status has access to the given path,
   * otherwise redirect it to the status default path.
   */
  render() {
    const { userIsAuthenticated } = this.state;
    const { subscriptionStatus } = this.props;
    const user = authentication.getCurrentUser();
    const userIsVerified = Boolean(user && user.emailVerified)

    if (!userHelper.userStatusIsReady(userIsAuthenticated, userIsVerified, subscriptionStatus)) {
      // We are fetching the current user status
      // Let's render a user loading feedback while we wait.
      return <UserLoadingFeedback />
    }

    const { allow, component: Component, ...rest } = this.props;
    const currentUserStatus = userHelper.getUserStatus(userIsAuthenticated, userIsVerified, subscriptionStatus);

    return (
      <Route {...rest} render={
        (props) => {
          return allow.includes(currentUserStatus)
              ? <Component {...props} />
              : <Redirect to={DEFAULT_PATH[currentUserStatus]} />
        }
      } />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    subscriptionStatus: subscriptionSelectors.getStatus(state)
  }
}

export default connect(mapStateToProps)(RouteComponent);
