import React from "react";
import wrapComponentWithPopup from "../../wrapComponentWithPopup";

interface Props {
  closePopup(): void;
}

class CloseBackground extends React.PureComponent<Props, {}> {
  render() {
    return <div className="close-cross" onClick={this.props.closePopup}/>;
  }
}

export default wrapComponentWithPopup(CloseBackground);
