import React from "react";
import { View } from "@react-pdf/renderer";
import styled from "@react-pdf/styled-components";
import assumptionsIcon from "../../../../../../../assets/images/pdf-icon-assumptions.png";
import ConfigurationHandler from "../../../../../ConfigurationHandler";
import PdfContext from "../../../PdfContext";
import Logo from "../../sharedComponents/Logo/Logo";
import SectionTitle from "../../sharedComponents/SectionTitle"
import GroupHeaderRow from "./GroupHeaderRow";
import Row from "./Row";
import config from "./config";

const Container = styled.View`
  flex-grow: 1;
  padding: 23px 38px 40px;
`;

const Group = styled.View`
  margin-top: 16px;
`;

const Assumptions = () => (
  <PdfContext.Consumer>
    {({ unitSystem }) => {
      return (
        <Container>
          <View>
            <SectionTitle title={"ASSUMPTIONS"} titleIcon={assumptionsIcon} />

            <Group>
              <GroupHeaderRow text={"Site / Building"} />
              <ConfigurationHandler config={config.parcelArea} component={Row} unitSystem={unitSystem} />
              <ConfigurationHandler config={config.projectGrossBuildableArea} component={Row} unitSystem={unitSystem} />
              <ConfigurationHandler config={config.projectNumberOfFloors} component={Row} unitSystem={unitSystem} />
            </Group>

            <Group>
              <GroupHeaderRow text={"Areas"} />
              <ConfigurationHandler config={config.condoTotalUnitQuantity} component={Row} unitSystem={unitSystem} />
              <ConfigurationHandler config={config.condoAverageAreaPerUnit} component={Row} unitSystem={unitSystem} />
              <ConfigurationHandler config={config.multifamilyTotalUnitQuantity} component={Row} unitSystem={unitSystem} />
              <ConfigurationHandler config={config.multifamilyAverageAreaPerUnit} component={Row} unitSystem={unitSystem} />
              <ConfigurationHandler config={config.hotelRoomQuantity} component={Row} unitSystem={unitSystem} />
              <ConfigurationHandler config={config.hotelAverageRoomArea} component={Row} unitSystem={unitSystem} />
              <ConfigurationHandler config={config.officeGrossLeasableArea} component={Row} unitSystem={unitSystem} />
              <ConfigurationHandler config={config.retailNetLeasableArea} component={Row} unitSystem={unitSystem} />
              <ConfigurationHandler config={config.industrialNetLeasableArea} component={Row} unitSystem={unitSystem} />
              <ConfigurationHandler config={config.parkingRequiredSpacesAfterReduction} component={Row} unitSystem={unitSystem} />
            </Group>

            <Group>
              <GroupHeaderRow text={"Economics"} />
              <ConfigurationHandler config={config.condoAverageGrossSalesPerSellableArea} component={Row} unitSystem={unitSystem} />
              <ConfigurationHandler config={config.multifamilyAverageYearOnePotentialGrossMonthlyRentalIncomePerUnit} component={Row} unitSystem={unitSystem} />
              <ConfigurationHandler config={config.hotelStabilizedAverageDailyRate} component={Row} unitSystem={unitSystem} />
              <ConfigurationHandler config={config.hotelStabilizedRevenuePerAvailableRoom} component={Row} unitSystem={unitSystem} />
              <ConfigurationHandler config={config.officeYearOneRentPerArea} component={Row} unitSystem={unitSystem} />
              <ConfigurationHandler config={config.retailYearOneRentPerArea} component={Row} unitSystem={unitSystem} />
              <ConfigurationHandler config={config.industrialYearOneRentPerArea} component={Row} unitSystem={unitSystem} />
            </Group>

            <Group>
              <GroupHeaderRow text={"Cost"} />
              <ConfigurationHandler config={config.projectHardCost} component={Row} unitSystem={unitSystem} />
              <ConfigurationHandler config={config.projectSoftCost} component={Row} unitSystem={unitSystem} />
              <ConfigurationHandler config={config.projectContingencyCost} component={Row} unitSystem={unitSystem} />
              <ConfigurationHandler config={config.existingStructureDemolitionCost} component={Row} unitSystem={unitSystem} />
              <ConfigurationHandler config={config.parkingReductionFee} component={Row} unitSystem={unitSystem} />
              <ConfigurationHandler config={config.parcelPurchasePrice} component={Row} unitSystem={unitSystem} />
              <ConfigurationHandler config={config.projectTotalDevelopmentCost} component={Row} unitSystem={unitSystem} />
            </Group>
          </View>
          <Logo />
        </Container>
      )
    }}
  </PdfContext.Consumer>
);

export default Assumptions;
