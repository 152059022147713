import React from "react";
import { Panel } from "../../../../types/uiElements";

// Panels.
import ProjectInfoPanel from "./ProjectInfoPanel";
import BackOfEnvelopePanel from "./BackOfEnvelopePanel";
import ProjectCostPanel from "./ProjectCostPanel";
import HeightAndSetbacksPanel from "./HeightAndSetbacksPanel";
import ParkingConstraintsPanel from "./ParkingConstraintsPanel";
import CondoPanel from "./CondoPanel";
import MultifamilyPanel from "./MultifamilyPanel";
import HotelPanel from "./HotelPanel";
import OfficePanel from "./OfficePanel";
import RetailPanel from "./RetailPanel/RetailPanel";
import IndustrialPanel from "./IndustrialPanel";
import PdfPanel from "./PdfPanel";

interface Props {
  panels: Array<Panel>;
  setbackMode: boolean;
  toggleSetbackSelectionMode();
  onPanelToggle(panel: Panel);
  onPanelPin(panel: Panel);
}

class Panels extends React.PureComponent<Props, any>  {
  container: any;

  /**
   * After component updates move the scroll to the left if a new panel
   * was added.
   */
  componentDidUpdate(prevProps, prevState, snapshot) {
    // Move scroll to the left only when new panel is added.
    if (this.props.panels.length > prevProps.panels.length) {
      this.container.scrollLeft = 0;
    }
  }

  render() {
    if (this.props.panels.length > 0) {
      return (
        <div className="panels-wrapper" ref={(element) => this.container = element}>
          {this.props.panels
            .map(
              (panel) => {
                switch (panel.description) {
                  case "cost_panel":
                    return (
                      <ProjectCostPanel
                        key={panel.description}
                        panel={panel}
                        onPanelClose={this.props.onPanelToggle}
                        onPanelPin={this.props.onPanelPin}
                      />
                    );
                  case "constraints_panel":
                    return (
                      <HeightAndSetbacksPanel
                        key={panel.description}
                        panel={panel}
                        onPanelClose={this.props.onPanelToggle}
                        onPanelPin={this.props.onPanelPin}
                        toggleSetbackSelectionMode={this.props.toggleSetbackSelectionMode}
                        setbackMode={this.props.setbackMode}
                      />
                    );
                  case "project_info_panel":
                    return (
                      <ProjectInfoPanel
                        key={panel.description}
                        panel={panel}
                        panels={this.props.panels}
                        onPanelToggle={this.props.onPanelToggle}
                        onPanelPin={this.props.onPanelPin}
                      />
                    );
                  case "back_of_envelope":
                    return (
                      <BackOfEnvelopePanel
                        key={panel.description}
                        panel={panel}
                        isPinned={panel.isPinned}
                        onPanelClose={this.props.onPanelToggle}
                        onPanelPin={this.props.onPanelPin}
                      />
                    );
                  case "parking_panel":
                    return (
                      <ParkingConstraintsPanel
                        key={panel.description}
                        panel={panel}
                        onPanelClose={this.props.onPanelToggle}
                        onPanelPin={this.props.onPanelPin}
                      />
                    );
                  case "condo_panel":
                    return (
                      <CondoPanel
                        key={panel.description}
                        panel={panel}
                        onPanelClose={this.props.onPanelToggle}
                        onPanelPin={this.props.onPanelPin}
                      />
                    );
                  case "multifamily_panel":
                    return (
                      <MultifamilyPanel
                        key={panel.description}
                        panel={panel}
                        onPanelClose={this.props.onPanelToggle}
                        onPanelPin={this.props.onPanelPin}
                      />
                    );
                  case "hotel_panel":
                    return (
                      <HotelPanel
                        key={panel.description}
                        panel={panel}
                        onPanelClose={this.props.onPanelToggle}
                        onPanelPin={this.props.onPanelPin}
                      />
                    );
                  case "office_panel":
                    return (
                      <OfficePanel
                        key={panel.description}
                        panel={panel}
                        onPanelClose={this.props.onPanelToggle}
                        onPanelPin={this.props.onPanelPin}
                      />
                    );
                  case "retail_panel":
                    return (
                      <RetailPanel
                        key={panel.description}
                        panel={panel}
                        onPanelClose={this.props.onPanelToggle}
                        onPanelPin={this.props.onPanelPin}
                      />
                    );
                  case "industrial_panel":
                    return (
                      <IndustrialPanel
                        key={panel.description}
                        panel={panel}
                        onPanelClose={this.props.onPanelToggle}
                        onPanelPin={this.props.onPanelPin}
                      />
                    );
                  case "pdf_panel":
                    return (
                      <PdfPanel
                        key={panel.description}
                        panel={panel}
                        onPanelClose={this.props.onPanelToggle}
                        onPanelPin={this.props.onPanelPin}
                      />
                    );
                  default:
                    return null;
                }
              }
            )
          }
        </div>
      );
    }
    else
      return null;
  };
}

export default Panels;
