import React from "react";
import styled from "@react-pdf/styled-components";

const Container = styled.View`
  position: absolute;
  align-self: center;
  bottom: 40px;
  width: 100%;
`;

const Text = styled.Text`
  font-family: "Roboto";
  letter-spacing: 0.1px;
  font-size: 6.25px;
  color: #999999;
`;

const Disclaimer = () => (
  <Container>
    <Text>
      As stated in our Terms of Services, all the data that is accessible through this platform
      is provided as a convenience only, is not official, and should not be relied upon. The
      data is provided "AS IS, WHERE IS AND WITH ALL FAULTS". Deep Blocks, Inc. disclaims any express
      or implied warranties of accuracy, completeness, merchantability or fitness of the data
      for any particular purpose.
    </Text>
  </Container>
);

export default Disclaimer;
