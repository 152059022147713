import React from "react";
import { Panel } from "../../../../../../types/uiElements";

interface Props {
  panel: Panel;
  isPinned: boolean | undefined;
  onPanelClose(panel: Panel);
  onPanelPin(panel: Panel);
  hideTitle?: boolean;
}

class PanelHeader extends React.PureComponent<Props, any> {

  /**
   * Handler for when the panel close icon is clicked.
   */
  handlePanelCloseClick = () => {
    this.props.onPanelClose(this.props.panel);
  }

  /**
   * Handler for when the pin icon is clicked.
   */
  handlePanelPinClick = () => {
    this.props.onPanelPin(this.props.panel);
  }

  render() {
    const { hideTitle, panel } = this.props;

    return (
      <div className="component--panel-header ">
        <span className="link-back" onClick={this.handlePanelCloseClick}>
          <span className="icon icon-cross-out" />
        </span>

        {hideTitle ? null : panel.title}

        <span className="pin">
          <span
            className={`icon icon-pin ${this.props.isPinned ? "pinned" : ""}`}
            onClick={this.handlePanelPinClick}
          />
        </span>
      </div>
    )
  }
}

export default (PanelHeader);
