import { Config } from "../../../../../types/Config";
import { VariableId } from "../../../../../types/VariableId";
import { BuildingUse } from "../../../../../types/BuildingUse";
import Format from "../../../../../types/Format";
import Unit from "../../../../../types/Unit";

const config: Config = {
  [VariableId.ProjectTotalDevelopmentCost]: {
    variableId: VariableId.ProjectTotalDevelopmentCost,
    text: "Project Cost",
    formatOptions: { type: Format.Type.Currency }
  },

  [VariableId.ParcelPurchasePrice]: {
    variableId: VariableId.ParcelPurchasePrice,
    buildingUse: BuildingUse.Project,
    text: "Property Purchase Price",
    formatOptions: { type: Format.Type.Currency }
  },

  [VariableId.ExistingStructureArea]: {
    variableId: VariableId.ExistingStructureArea,
    buildingUse: BuildingUse.Project,
    text: "Existing Structure Area",
    formatOptions: { type: Format.Type.Number, },
    [Unit.System.Imperial]: {
      unitTarget: Unit.Type.SquareFeet,
      formatOptions: { suffix: " SF" }
    },
    [Unit.System.Metric]: {
      unitTarget: Unit.Type.SquareMeters,
      formatOptions: { suffix: " m²" }
    }
  },

  [VariableId.ExistingStructureDemolitionCostPerArea]: {
    variableId: VariableId.ExistingStructureDemolitionCostPerArea,
    buildingUse: BuildingUse.Project,
    text: "Demolition Cost",
    formatOptions: { type: Format.Type.Currency, },
    [Unit.System.Imperial]: {
      unitTarget: Unit.Type.SquareFeet,
      unitIsInverse: true,
      formatOptions: { suffix: "/SF" }
    },
    [Unit.System.Metric]: {
      unitTarget: Unit.Type.SquareMeters,
      unitIsInverse: true,
      formatOptions: { suffix: "/m²" }
    }
  },

  [VariableId.SoftCostPercentage]: {
    variableId: VariableId.SoftCostPercentage,
    buildingUse: BuildingUse.Project,
    text: "Soft Cost",
    formatOptions: { type: Format.Type.Percentage }
  },

  [VariableId.ContingencyCostPercentage]: {
    variableId: VariableId.ContingencyCostPercentage,
    buildingUse: BuildingUse.Project,
    text: "Contingencies",
    formatOptions: { type: Format.Type.Percentage }
  },

  [VariableId.ProjectHardCostPerGrossBuildableArea]: {
    variableId: VariableId.ProjectHardCostPerGrossBuildableArea,
    text: "Hard Cost",
    formatOptions: {
      type: Format.Type.Currency,
      decimalPlaces: 2
    },
    [Unit.System.Imperial]: {
      unitTarget: Unit.Type.SquareFeet,
      unitIsInverse: true,
      formatOptions: { suffix: "/SF" }
    },
    [Unit.System.Metric]: {
      unitTarget: Unit.Type.SquareMeters,
      unitIsInverse: true,
      formatOptions: { suffix: "/m²" }
    }
  },

  [VariableId.CondoHardCostPerArea]: {
    variableId: VariableId.CondoHardCostPerArea,
    buildingUse: BuildingUse.Condo,
    text: "Condo",
    formatOptions: { type: Format.Type.Currency, },
    [Unit.System.Imperial]: {
      unitTarget: Unit.Type.SquareFeet,
      unitIsInverse: true,
      formatOptions: { suffix: "/SF" }
    },
    [Unit.System.Metric]: {
      unitTarget: Unit.Type.SquareMeters,
      unitIsInverse: true,
      formatOptions: { suffix: "/m²" }
    }
  },

  [VariableId.MultifamilyHardCostPerArea]: {
    variableId: VariableId.MultifamilyHardCostPerArea,
    buildingUse: BuildingUse.Multifamily,
    text: "Multifamily",
    formatOptions: { type: Format.Type.Currency, },
    [Unit.System.Imperial]: {
      unitTarget: Unit.Type.SquareFeet,
      unitIsInverse: true,
      formatOptions: { suffix: "/SF" }
    },
    [Unit.System.Metric]: {
      unitTarget: Unit.Type.SquareMeters,
      unitIsInverse: true,
      formatOptions: { suffix: "/m²" }
    }
  },

  [VariableId.HotelHardCostPerArea]: {
    variableId: VariableId.HotelHardCostPerArea,
    buildingUse: BuildingUse.Hotel,
    text: "Hotel",
    formatOptions: { type: Format.Type.Currency, },
    [Unit.System.Imperial]: {
      unitTarget: Unit.Type.SquareFeet,
      unitIsInverse: true,
      formatOptions: { suffix: "/SF" }
    },
    [Unit.System.Metric]: {
      unitTarget: Unit.Type.SquareMeters,
      unitIsInverse: true,
      formatOptions: { suffix: "/m²" }
    }
  },

  [VariableId.OfficeHardCostPerArea]: {
    variableId: VariableId.OfficeHardCostPerArea,
    buildingUse: BuildingUse.Office,
    text: "Office",
    formatOptions: { type: Format.Type.Currency, },
    [Unit.System.Imperial]: {
      unitTarget: Unit.Type.SquareFeet,
      unitIsInverse: true,
      formatOptions: { suffix: "/SF" }
    },
    [Unit.System.Metric]: {
      unitTarget: Unit.Type.SquareMeters,
      unitIsInverse: true,
      formatOptions: { suffix: "/m²" }
    }
  },

  [VariableId.RetailHardCostPerArea]: {
    variableId: VariableId.RetailHardCostPerArea,
    buildingUse: BuildingUse.Retail,
    text: "Retail",
    formatOptions: { type: Format.Type.Currency, },
    [Unit.System.Imperial]: {
      unitTarget: Unit.Type.SquareFeet,
      unitIsInverse: true,
      formatOptions: { suffix: "/SF" }
    },
    [Unit.System.Metric]: {
      unitTarget: Unit.Type.SquareMeters,
      unitIsInverse: true,
      formatOptions: { suffix: "/m²" }
    }
  },

  [VariableId.IndustrialHardCostPerArea]: {
    variableId: VariableId.IndustrialHardCostPerArea,
    buildingUse: BuildingUse.Industrial,
    text: "Industrial",
    formatOptions: { type: Format.Type.Currency, },
    [Unit.System.Imperial]: {
      unitTarget: Unit.Type.SquareFeet,
      unitIsInverse: true,
      formatOptions: { suffix: "/SF" }
    },
    [Unit.System.Metric]: {
      unitTarget: Unit.Type.SquareMeters,
      unitIsInverse: true,
      formatOptions: { suffix: "/m²" }
    }
  },

  [VariableId.ParkingHardCostPerArea]: {
    variableId: VariableId.ParkingHardCostPerArea,
    buildingUse: BuildingUse.Parking,
    text: "Parking",
    formatOptions: { type: Format.Type.Currency, },
    [Unit.System.Imperial]: {
      unitTarget: Unit.Type.SquareFeet,
      unitIsInverse: true,
      formatOptions: { suffix: "/SF" }
    },
    [Unit.System.Metric]: {
      unitTarget: Unit.Type.SquareMeters,
      unitIsInverse: true,
      formatOptions: { suffix: "/m²" }
    }
  }
};

export default config;
