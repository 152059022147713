import { usageColor } from "../views/utils/buildingUsageProperties";
import splitPolygons from "./splitPolygons";
import turf from "./turf";
import { Floor } from  "../types/Development/Floor";
import { BuildingModel } from  "../types/Development/BuildingModel";

/**
 * @fileoverview This module is responsible for generating representations of
 *  a building model that conform to the GeoJSON standard.
 *
 *  See: http://geojson.org/
 *  See: https://en.wikipedia.org/wiki/GeoJSON
 */

const VISUAL_SPACE_BETWEEN_FLOORS = 0.24384; // meters

/**
 * A GeoJSON object representing the geometries for each usage of the given
 * floor object.
 */
const floorGeoJson = (floor: Floor, guideVector) => {
  let remainingPolygons = turf.clone(floor.footprint.polygons as any);
  let results: any[] = [];

  floor.usageAreas.forEach((usageArea, index) => {
    if (remainingPolygons) {
      let usageAreaAdjustedToGeometryArea = usageArea.area / floor.footprint.publishedDataAreaUnitsPerGeometricAreaUnit;

      let split = splitPolygons(remainingPolygons, guideVector, usageAreaAdjustedToGeometryArea);
      let usagePolygons = split[0];
      remainingPolygons = split[1];

      usagePolygons.properties = {
        base_height: floor.baseElevation + VISUAL_SPACE_BETWEEN_FLOORS,
        height: floor.height + floor.baseElevation,
        color: usageColor(usageArea.usage),
        usage: usageArea.usage,
      };

      results.push(usagePolygons);
    }
  });

  return results;
};

/**
 * A GeoJSON object containing all of the geometry information necessary to
 * represent the building modeled by the given `buildingModel`.
 */
const geoJsonFor = (buildingModel: BuildingModel) => {
  if (buildingModel.floors.length === 0 || buildingModel.floors[0].footprint.area === 0) {
    return null;
  }

  return {
    type: "FeatureCollection",
    features: buildingModel.floors
      .reduce((features: any[], floor) => features.concat(floorGeoJson(floor, buildingModel.partitionGuideVector)), [])
  };
};

export default {
  geoJsonFor
};
