import React from "react";
import DeleteDevelopmentPopup from "./DeleteDevelopmentPopup";
import database from "../../../utils/database/database";
import analytics from "../../../utils/analytics";

interface State {
  deleteDevelopmentPopupIsActive: boolean;
}

interface Props {
  developmentId: string;
  developmentName: string;
  showText?: boolean;
  onDeleteDevelopment?();
}

class DeleteDevelopmentButton extends React.PureComponent<Props, State>  {
  constructor(props) {
    super(props);

    this.state = {
      deleteDevelopmentPopupIsActive: false
    };
  }

  /**
   * Callback for confirm button in DeleteDevelopmentPopup.
   */
  handleDeleteDevelopmentConfirm = async () => {
    await database.removeProject(this.props.developmentId);
    analytics.trackDeleteProject(this.props.developmentId);

    if (this.props.onDeleteDevelopment) this.props.onDeleteDevelopment();
  }

  /**
   * Toggle the dialog box active state.
   */
  handleDeleteDevelopmentPopupStateChange = (event) => {
    if (event) event.stopPropagation();

    this.setState({ deleteDevelopmentPopupIsActive: !this.state.deleteDevelopmentPopupIsActive })
  }

  /**
   * Render the delete development popup.
   */
  renderPopup = () => {
    if (!this.state.deleteDevelopmentPopupIsActive) return null;

    return (
      <DeleteDevelopmentPopup
        onStateChange={this.handleDeleteDevelopmentPopupStateChange}
        onConfirm={this.handleDeleteDevelopmentConfirm}
        developmentName={this.props.developmentName}
      />
    );
  }

  render() {
    return (
      <div
        className={`component--delete-development ${this.state.deleteDevelopmentPopupIsActive ? "active" : ""}`}
        onClick={this.handleDeleteDevelopmentPopupStateChange}
      >
        <div className="icon" />
        {this.props.showText && <div className="text">Discard</div>}
        {this.renderPopup()}
      </div>
    );
  }
}

export default DeleteDevelopmentButton;
