import actionTypes from "./actionTypes";
import { ThumbnailState } from "./thumbnail/reducers";
import thumbnailReducer from "./thumbnail/reducers";

export type ThumbnailsCollectionState = { [developmentId: string]: ThumbnailState };

export interface ThumbnailsState {
  collection: ThumbnailsCollectionState
}

const INITIAL_STATE: ThumbnailsState = {
  collection: {}
}

/**
 * Determine which reducer, if any, should handle reducing the given action and
 * state.
 */
const reducer = (previousState = INITIAL_STATE, action): ThumbnailsState => {
  switch (action.type) {
    case actionTypes.DELETE: return deleteThumbnail(previousState, action.payload);

    case actionTypes.LOAD_SUCCESS:
    case actionTypes.LOAD_ERROR:
      return reduceMember(previousState, action);

    default: return previousState;
  }
}

/**
 * Update a particular member of the thumbnail collection.
 */
const reduceMember = (previousState, action): ThumbnailsState => {
  let developmentId: string = action.payload.developmentId;
  let thumbnail: ThumbnailState = previousState.collection[developmentId];

  return {
    collection: {
      ...previousState.collection,
      [developmentId]: thumbnailReducer(thumbnail, action)
    }
  }
}

/**
 * Delete a particular member of the thumbnail collection.
 */
const deleteThumbnail = (previousState, payload): ThumbnailsState => {
  let developmentId: string = payload.developmentId;
  let newCollection = {...previousState.collection};
  delete newCollection[developmentId];

  return {
    collection: {...newCollection}
  }
}

export default reducer;
