import React from "react";
import { withRouter, RouteComponentProps } from "react-router";
import authentication from "../../../../utils/authentication";
import database from "../../../../utils/database/database";
import { Path } from "../../../../types/Path";

type Props = RouteComponentProps;

class SignOutDropdownButton extends React.PureComponent<Props, {}> {
  /**
   * Signs out the user.
   */
  handleSignOutClick = async () => {
    if (this.props.location.pathname.includes(Path.Explorer)) await database.saveCurrentProject();
    await authentication.signOut();
    this.props.history.push(Path.Home);
  }

  render() {
    const userIsAuthenticated = authentication.isUserAuthenticated();

    return userIsAuthenticated
        ? <li key="sign-out" onClick={this.handleSignOutClick}><div className="button">Log Out</div></li>
        : null;
  }
};

export default withRouter(SignOutDropdownButton);
