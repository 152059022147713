import React from "react";
import BusySpinner from "../BusySpinner";

class UserLoadingFeedback extends React.PureComponent<{}, {}> {
  render() {
    return (
      <div className="component--user-loading-feedback">
        <div className="text-box">Loading Data...</div>
        <BusySpinner />
      </div>
    );
  }
}

export default UserLoadingFeedback;
