import React from "react";
import BusySpinner from "../BusySpinner";
import wrapComponentWithPopup from "../wrapComponentWithPopup";

interface Props {
  text: string;
}

class BusyPopup extends React.PureComponent<Props, {}> {
  render() {
    return (
      <div className="component--busy-popup">
        <div className="text-box">{this.props.text}</div>
        <BusySpinner />
      </div>
    );
  }
}

export default wrapComponentWithPopup(BusyPopup);
