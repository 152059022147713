import React from "react";

/**
 * @fileoverview This presentational component generates a dropdown element with
 *  the provided child content.
 */

const activator = (isActive) => {
  return isActive ? "active" : "";
}

const Dropdown = (props) => {
  let isActive = props.isActive;
  let wrapperClass = props.wrapperClassName || "";

  return (
    <div
      className={`component--dropdown ${wrapperClass}`}
      onClick={props.onClick}
      onMouseOver={props.onMouseOver}
      onMouseOut={props.onMouseOut}
      onBlur={props.onBlur}
      tabIndex={0}
    >
      {props.toggleButton}

      <div className={`dropdown-content ${activator(isActive)} ${props.children.length === 0 ? "empty" : ""}`}>
        <div className="dropdown-content-inner">
          <ul>{props.children}</ul>
        </div>
      </div>

    </div>
  );
};

export default Dropdown;
