import { Listing, ListingProperty } from "../types/Service/Listings/Listings";
import turf from "./turf";

/**
 * Return listings that fall inside the parcel.
 */
const getListingsInsideParcel = (listings: Array<Listing>, parcel) => {
  return listings.filter((listing: Listing) => {
    return turf.inside(listing[ListingProperty.Coordinates], parcel);
  });
}

/**
 * Return listings that fall inside any of the given parcels.
 */
const getListingsInsideParcels = (listings: Array<Listing>, parcels: Array<any>) => {
  return listings.filter((listing: Listing) => {
    return parcels.some((parcel) => turf.inside(listing[ListingProperty.Coordinates], parcel));
  });
}

/**
 * Returns boolean representing if listing value is not null or array is not empty.
 */
const propertyValueIsValid = (listing: Listing, listingProperty: ListingProperty): boolean => {
  return Array.isArray(listing[listingProperty])
      ? listing[listingProperty].length > 0
      : listing[listingProperty] !== undefined && listing[listingProperty] !== null;
}

/**
 * Returns text to display for boolean values.
 */
const getBooleanText = (value: boolean) => {
  return value ? "Yes" : "No";
}

export default {
  getListingsInsideParcel,
  propertyValueIsValid,
  getBooleanText,
  getListingsInsideParcels,
}
