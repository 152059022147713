import React from "react";
import { connect } from "react-redux";
import { developmentSelectors } from "../../../../../../state/development";
import Dropdown from "../../../../../../views/sharedComponents/Dropdown";
import valueFormatter from "../../../../../../utils/valueFormatter";
import { buildingUsageIsEnabled } from "../../../../../utils/uiToggleHelper";
import unitConversions from "../../../../../../utils/unitConversions";
import { BuildingUse } from "../../../../../../types/BuildingUse";
import Unit from "../../../../../../types/Unit";
import Format from "../../../../../../types/Format";

const mapStateToProps = (state, ownProps) => {
  return {
    value: developmentSelectors.getValue(state, ownProps.variableId),
    toggles: developmentSelectors.getBuildingUsageToggles(state),
  };
}

interface OwnProps {
  buildingUse: BuildingUse;
  kpiIndex: number;
  text: string;
  kpiList: Array<any>;
  isActive: boolean;
  unitSystem: Unit.System;
  value?: number;
  unitTarget?: Unit.Type;
  unitIsInverse?: boolean;
  formatOptions?: Format.Options;
  setActive(value: boolean);
  onSelectKpi(index: number, kpiId: string);
}

type StateProps = ReturnType<typeof mapStateToProps>;
type Props = OwnProps & StateProps;

class Kpi extends React.PureComponent<Props, {}>  {
  /**
   * This function generates the text for the title section of the KPI based on whether the selected KPI is enabled or not.
   * Returns the title of the selected KPI if enabled and the string "Key Performance Indicator" otherwise.
   */
  renderKpiTitle() {
    if (buildingUsageIsEnabled(this.props.toggles, this.props.buildingUse))
      return this.props.text
    else
      return "Key Performance Indicator"
  }

  /**
   * This function generates the text for the content section of the KPI based on whether the selected KPI is enabled or not.
   * Returns the formatted value of the selected KPI if enabled and the string "Select" otherwise.
   */
  renderKpiContent() {
    if (buildingUsageIsEnabled(this.props.toggles, this.props.buildingUse)) {
      let value = this.props.value;
      if (this.props.unitTarget) {
        value = unitConversions.convertFromBase(value, this.props.unitTarget, this.props.unitIsInverse);
      }

      return valueFormatter.format(value, this.props.formatOptions);
    }
    else
      return "Select"
  }

  render() {
    return (
      <div className="component--kpi">
        <div className="kpi-item-container flx-col full v-center">
          <Dropdown
            isActive={this.props.isActive}
            onClick={this.props.setActive(!this.props.isActive)}
            onBlur={this.props.setActive(false)}
            toggleButton={
              <div className="kpi-item">
                <div className="kpi-title">
                  {this.renderKpiTitle()}
                </div>
                <div className="kpi-values">
                  {this.renderKpiContent()}
                  <div className={`kpi-triangle ${this.props.isActive ? "active" : ""}`} />
                </div>
              </div>
            }
          >
            {
              this.props.kpiList
                .filter((config) => buildingUsageIsEnabled(this.props.toggles, config.buildingUse))
                .filter((config) => config.text !== this.props.text)
                .sort((a, b) => a.text.localeCompare(b.text))
                .map(
                  (option) => (
                    <li key={`kpiDropDownOption__${option.variableId}`}>
                      <div
                        className="button"
                        onClick={this.props.onSelectKpi(this.props.kpiIndex, option.variableId)}
                      >
                        {option.text}
                      </div>
                    </li>
                  )
                )
            }
          </Dropdown>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps)(Kpi);
