import React from "react";
import ReactMapboxGl from "react-mapbox-gl";
import Measure from "react-measure";
import { connect } from "react-redux";
import MapLegend from "./MapLegend";
import BuildingLayer from "./BuildingLayer";
import { pdfActions } from "../../../../state/pdf";
import { ReactMapboxGlCamera } from "../../../../types/ReactMapboxGlCamera";
import { MapStyleProperties } from "../../../../utils/mapbox/mapStyleProperties";

/**
 * @fileoverview This container wraps the Mapbox map component and manages
 *  any data and functionality it requires.
 */

const Mapbox = ReactMapboxGl({
  accessToken: process.env.REACT_APP_MAPBOX_ACCESS_TOKEN as string
});

interface Props {
  camera: any;
  setCameraForPdf(camera: ReactMapboxGlCamera);
}

interface State {
  mapStyleIsStreet: boolean;
}

class Map extends React.Component<Props, State> {
  innerMap: any;
  camera: ReactMapboxGlCamera;

  constructor(props) {
    super(props);

    // This attribute helps us to manage the quirky behavior of the `<Mapbox>`
    // component, implemented by `react-mapbox-gl`.
    // See: https://github.com/alex3165/react-mapbox-gl#why-are-zoom-bearing-and-pitch-arrays-
    // TODO: Fix the Mapbox implementation. (https://deepblocks.tpondemand.com/entity/1789-fix-the-camera-implementation)
    this.camera = {
      zoom: [props.camera.zoom],
      bearing: [props.camera.bearing],
      pitch: [props.camera.pitch],
      center: props.camera.target
    };

    this.state = {
      mapStyleIsStreet: true,
    };

    this.props.setCameraForPdf(this.camera);
  }

  /**
   * Resize the Map when component updates.
   */
  componentDidUpdate() {
    if (this.innerMap) {
      this.innerMap.resize();
    }
  }

  /**
   * Resize the Map.
   */
  resizeMap = () => {
    if (this.innerMap) {
      this.innerMap.resize();
    }
  }

  /**
   * Updates map style.
   */
  toggleMapStyle = () => {
    this.setState({
      mapStyleIsStreet: !this.state.mapStyleIsStreet
    })
  }

  /**
   * Returns mapbox style.
   */
  getMapStyle = () => {
    return this.state.mapStyleIsStreet
      ? MapStyleProperties.StyleUrl.Streets
      : MapStyleProperties.StyleUrl.Satellite;
  }

  /**
   * The `<Mapbox>` component, implemented by `react-mapbox-gl`, requires the
   * camera props to be wrapped in arrays, and will jump the camera to the
   * indicated position if the array objects that are passed change (NOT if the
   * values they contain change). In other words, in order to tell the camera
   * to jump to a new location, it is necessary to break the array equality, and
   * replace the old array objects with new array objects.
   *
   * See: https://github.com/alex3165/react-mapbox-gl#why-are-zoom-bearing-and-pitch-arrays-
   *
   * This method helps work around this behavior, by ensuring that the array
   * objects that are passed to the `<Mapbox>` props are only replaced with a
   * new array objects at the appropriate time.
   *
   * TODO: Fix the Mapbox implementation. (https://deepblocks.tpondemand.com/entity/1789-fix-the-camera-implementation)
   */
  updateCameraArrays = () => {
    let cameraIsDifferent = (
         this.props.camera.zoom       !== this.camera.zoom[0]
      || this.props.camera.pitch      !== this.camera.pitch[0]
      || this.props.camera.bearing    !== this.camera.bearing[0]
      || this.props.camera.target[0]  !== this.camera.center[0]
      || this.props.camera.target[1]  !== this.camera.center[1]
    );

    if (cameraIsDifferent) {
      this.camera.zoom    = [this.props.camera.zoom];
      this.camera.pitch   = [this.props.camera.pitch];
      this.camera.bearing = [this.props.camera.bearing];
      this.camera.center  = this.props.camera.target.slice();
    }
  }

  /**
   * Set the camera for PDF generation.
   */
  handleMoveEnd = () => {
    if (this.innerMap) {
      let mapCenter = this.innerMap.getCenter();
      this.props.setCameraForPdf({
        zoom: [this.innerMap.getZoom()],
        pitch: [this.innerMap.getPitch()],
        bearing: [this.innerMap.getBearing()],
        center: [mapCenter.lng, mapCenter.lat]
      });
    }
  }

  render() {
    this.updateCameraArrays();

    return (
      <Measure onResize={this.resizeMap} >
        {
          ({ measureRef }) =>
            <div ref={measureRef} className="component--map">
              <Mapbox
                style={this.getMapStyle()}
                zoom={this.camera.zoom}
                pitch={this.camera.pitch}
                bearing={this.camera.bearing}
                center={this.camera.center}
                onStyleLoad={(innerMap) => {
                  this.innerMap = innerMap;
                }}
                onMoveEnd={this.handleMoveEnd}
                containerStyle={{
                  height: "100%",
                  width: "100%",
                  margin: "0 auto"
                }}
                movingMethod="jumpTo"
              >
                <BuildingLayer />
              </Mapbox>
              <div className="map-controls">
                <MapLegend />
              </div>
              <button
                onClick={this.toggleMapStyle}
                className={`${this.state.mapStyleIsStreet ? "satellite" : "streets"}-map-button`}
              />
            </div>
        }
      </Measure>
    );
  }
}

const mapDispatchToProps = {
  setCameraForPdf: pdfActions.setCamera,
}

export default connect(
  null,
  mapDispatchToProps
)(Map);
