// Distance
// ------
const METERS_PER_FOOT = 0.3048;
const feetToMeters = (feet) => feet * METERS_PER_FOOT;

// Area conversion functions
// ----
const SQUARE_METERS_PER_SQUARE_FOOT = 0.09290304;
const squareFeetToSquareMeters = (squareFeet) => squareFeet * SQUARE_METERS_PER_SQUARE_FOOT;
const squareFeetToSquareMetersInverse = (squareFeetInverse) => squareFeetInverse / SQUARE_METERS_PER_SQUARE_FOOT;

const SQUARE_METERS_PER_ACRE = 4046.8564224;
const acresToSquareMeters = (acres) => acres * SQUARE_METERS_PER_ACRE;

const AREA_VARIABLES_TO_UPDATE = [
  "condoAmenitiesArea",
  "condoAmenitiesAreaToggleable",
  "condoFractionOfNonParkingGrossBuildableArea",
  "condoGrossBuildableArea",
  "condoGrossBuildableAreaIncludingParking",
  "condoMicrounitArea",
  "condoNetSellableArea",
  "condoOneBedArea",
  "condoStudioArea",
  "condoThreeBedArea",
  "condoTotalMicrounitArea",
  "condoTotalOneBedArea",
  "condoTotalStudioArea",
  "condoTotalThreeBedArea",
  "condoTotalTwoBedArea",
  "condoTwoBedArea",
  "condoAverageAreaPerUnit",
  "existingStructureArea",
  "hotelAmenitiesArea",
  "hotelAmenitiesAreaToggleable",
  "hotelAverageRoomArea",
  "hotelFractionOfNonParkingGrossBuildableArea",
  "hotelGrossBuildableArea",
  "hotelGrossBuildableAreaIncludingParking",
  "hotelNetBookableArea",
  "industrialGrossBuildableArea",
  "industrialNetLeasableArea",
  "industrialNetLeasableAreaToggleable",
  "multifamilyAmenitiesArea",
  "multifamilyAmenitiesAreaToggleable",
  "multifamilyGrossBuildableArea",
  "multifamilyMicrounitArea",
  "multifamilyNetLeasableArea",
  "multifamilyOneBedArea",
  "multifamilyStudioArea",
  "multifamilyThreeBedArea",
  "multifamilyTotalMicrounitArea",
  "multifamilyTotalOneBedArea",
  "multifamilyTotalStudioArea",
  "multifamilyTotalThreeBedArea",
  "multifamilyTotalTwoBedArea",
  "multifamilyTwoBedArea",
  "multifamilyAverageAreaPerUnit",
  "nonParkingGrossBuildableArea",
  "officeGrossBuildableArea",
  "officeGrossLeasableArea",
  "officeNetUsableArea",
  "officeNetUsableAreaToggleable",
  "parcelArea",
  "parkingGrossBuildableArea",
  "parkingGrossBuildableAreaForCondo",
  "parkingGrossBuildableAreaForHotel",
  "parkingGrossBuildableAreaForRentalUses",
  "parkingSpaceArea",
  "projectGrossBuildableArea",
  "projectNetLeasableOrSellableArea",
  "rentalUsesFractionOfNonParkingGrossBuildableArea",
  "rentalUsesGrossBuildableArea",
  "rentalUsesGrossBuildableAreaIncludingParking",
  "retailGrossBuildableArea",
  "retailNetLeasableArea",
  "retailNetLeasableAreaToggleable",
]

const INVERSE_AREA_VARIABLES_TO_UPDATE = [
  "condoDevelopmentCostPerAreaIncludingParking",
  "condoHardCostPerArea",
  "existingStructureDemolitionCostPerArea",
  "hotelDevelopmentCostPerAreaIncludingParking",
  "hotelHardCostPerArea",
  "industrialHardCostPerArea",
  "industrialYearOneExpenseReimbursementFeePerArea",
  "industrialYearOneOperatingExpensePerArea",
  "industrialYearOneRentPerArea",
  "multifamilyHardCostPerArea",
  "officeHardCostPerArea",
  "officeYearOneExpenseReimbursementFeePerArea",
  "officeYearOneOperatingExpensePerArea",
  "officeYearOneRentPerArea",
  "parcelPurchasePricePerArea",
  "parkingHardCostPerArea",
  "rentalUsesDevelopmentCostPerAreaIncludingParking",
  "retailHardCostPerArea",
  "retailYearOneExpenseReimbursementFeePerArea",
  "retailYearOneOperatingExpensePerArea",
  "retailYearOneRentPerArea",
  "condoAverageGrossSalesPerSellableArea",
  "multifamilyAverageYearOnePotentialGrossMonthlyRentalIncomePerLeasableArea",
  "projectConstructionCostIncludingDemolitionPerBuildableArea",
  "projectHardCostPerGrossBuildableArea",
]

const DISTANCE_VARIABLES_TO_UPDATE = [
  "setbackA",
  "setbackB",
  "setbackC",
  "setbackD",
  "heightOfGroundFloor",
  "heightOfTypicalFloor"
]

const AREA_VARIABLES_TO_MIGRATE_MAP = {
  "condoAverageGrossSalesPerSellableSquareFoot": "condoAverageGrossSalesPerSellableArea",
  "condoDevelopmentCostPerSquareFootIncludingParking": "condoDevelopmentCostPerAreaIncludingParking",
  "condoHardCostPerSquareFoot": "condoHardCostPerArea",
  "existingStructureDemolitionCostPerSquareFoot": "existingStructureDemolitionCostPerArea",
  "hotelDevelopmentCostPerSquareFootIncludingParking": "hotelDevelopmentCostPerAreaIncludingParking",
  "hotelHardCostPerSquareFoot": "hotelHardCostPerArea",
  "industrialHardCostPerSquareFoot": "industrialHardCostPerArea",
  "industrialYearOneExpenseReimbursementFeePerSquareFoot": "industrialYearOneExpenseReimbursementFeePerArea",
  "industrialYearOneOperatingExpensePerSquareFoot": "industrialYearOneOperatingExpensePerArea",
  "industrialYearOneRentPerSquareFoot": "industrialYearOneRentPerArea",
  "multifamilyAverageYearOnePotentialGrossMonthlyRentalIncomePerLeasableSquareFoot": "multifamilyAverageYearOnePotentialGrossMonthlyRentalIncomePerLeasableArea",
  "multifamilyHardCostPerSquareFoot": "multifamilyHardCostPerArea",
  "officeHardCostPerSquareFoot": "officeHardCostPerArea",
  "officeYearOneExpenseReimbursementFeePerSquareFoot": "officeYearOneExpenseReimbursementFeePerArea",
  "officeYearOneOperatingExpensePerSquareFoot": "officeYearOneOperatingExpensePerArea",
  "officeYearOneRentPerSquareFoot": "officeYearOneRentPerArea",
  "parcelPurchasePricePerSquareFoot": "parcelPurchasePricePerArea",
  "parkingHardCostPerSquareFoot": "parkingHardCostPerArea",
  "projectConstructionCostIncludingDemolitionPerBuildableSquareFoot": "projectConstructionCostIncludingDemolitionPerBuildableArea",
  "projectHardCostPerGrossBuildableSquareFoot": "projectHardCostPerGrossBuildableArea",
  "rentalUsesDevelopmentCostPerSquareFootIncludingParking": "rentalUsesDevelopmentCostPerAreaIncludingParking",
  "retailHardCostPerSquareFoot": "retailHardCostPerArea",
  "retailYearOneExpenseReimbursementFeePerSquareFoot": "retailYearOneExpenseReimbursementFeePerArea",
  "retailYearOneOperatingExpensePerSquareFoot": "retailYearOneOperatingExpensePerArea",
  "retailYearOneRentPerSquareFoot": "retailYearOneRentPerArea",
}

/**
 * Update the variable name and delete the old one.
 */
const updateVariableName = (object, oldVariableId, newVariableId) => {
  if (object[oldVariableId] !== undefined) {
    object[newVariableId] = object[oldVariableId];
  }
  delete object[oldVariableId];
}

/**
 * Converts variable in object with conversionFunction.
 */
const convertVariableValue = (object, variableId, conversionFunction) => {
  if (typeof object[variableId] === "number") object[variableId] = conversionFunction(object[variableId]);
}

/**
 * Transform the development parcel object to be a GEOJson object with
 * properties set to default values.
 */
const convertToMetricSystem = (development) => {
  // Add default unit system to development object.
  development.unitSystem = "imperial";

  let values = development.values;
  let constraintIncrements = development.constraints.increments;
  let constraintMinimums = development.constraints.minimums;
  let constraintMaximums = development.constraints.maximums;

  // Update variable names that contained "SquareFoot" to "Area", delete old variable names.
  for (let oldVariableId of Object.keys(AREA_VARIABLES_TO_MIGRATE_MAP)) {
    let newVariableId = AREA_VARIABLES_TO_MIGRATE_MAP[oldVariableId];

    // Check values object
    updateVariableName(values, oldVariableId, newVariableId);

    // Check constraints object
    updateVariableName(constraintIncrements, oldVariableId, newVariableId);
    updateVariableName(constraintMinimums, oldVariableId, newVariableId);
    updateVariableName(constraintMaximums, oldVariableId, newVariableId);
  }

  // Convert all area variables to metric system.
  for (let variableId of AREA_VARIABLES_TO_UPDATE) {
    convertVariableValue(values, variableId, squareFeetToSquareMeters);
    convertVariableValue(constraintMinimums, variableId, squareFeetToSquareMeters);
    convertVariableValue(constraintMaximums, variableId, squareFeetToSquareMeters);
  }

  // Convert all inverse area variables to metric system.
  for (let variableId of INVERSE_AREA_VARIABLES_TO_UPDATE) {
    convertVariableValue(values, variableId, squareFeetToSquareMetersInverse);
    convertVariableValue(constraintMinimums, variableId, squareFeetToSquareMetersInverse);
    convertVariableValue(constraintMaximums, variableId, squareFeetToSquareMetersInverse);
  }

  // Convert all distance variables to metric system.
  for (let variableId of DISTANCE_VARIABLES_TO_UPDATE) {
    convertVariableValue(values, variableId, feetToMeters);
    convertVariableValue(constraintMinimums, variableId, feetToMeters);
    convertVariableValue(constraintMaximums, variableId, feetToMeters);
  }

  // Re-calculate ratios
  values.unitsPerParcelArea = values.unitsPerParcelAcre === 0
      ? 0
      : 1 / acresToSquareMeters(1 / values.unitsPerParcelAcre);
  delete values.unitsPerParcelAcre;
  delete values.parcelAreaInAcres;

  // Convert relevant properties from parcel object.
  let properties = development.parcel && development.parcel.properties;
  if (!properties) return;

  const areaPublishedInSquareMeters = squareFeetToSquareMeters(properties.areaPublished);
  properties.areaPublished = areaPublishedInSquareMeters ? areaPublishedInSquareMeters : properties.areaPublished;
  if (properties.areaPublished === undefined) delete properties.areaPublished;

  const existingStructureAreaInSquareMeters = squareFeetToSquareMeters(properties.existingStructureArea);
  properties.existingStructureArea = existingStructureAreaInSquareMeters ? existingStructureAreaInSquareMeters : properties.existingStructureArea;
  if(properties.existingStructureArea === undefined) delete properties.existingStructureArea;

  const allowedBuildableAreaInSquareMeters = squareFeetToSquareMeters(properties.allowedBuildableArea);
  properties.allowedBuildableArea = allowedBuildableAreaInSquareMeters ? allowedBuildableAreaInSquareMeters : properties.allowedBuildableArea;
  if(properties.allowedBuildableArea === undefined) delete properties.allowedBuildableArea;
}

export default convertToMetricSystem;
