import database from "../utils/database/database";
import developmentActionTypes from "./development/actionTypes";
import pdfActionTypes from "./pdf/actionTypes";

const AUTOSAVE_TRIGGER_ACTIONS = [
  developmentActionTypes.RE_CALCULATE,
  developmentActionTypes.SET_UNIT_SYSTEM,
  developmentActionTypes.SET_NAME,
  developmentActionTypes.SET_RANGED_INPUT,
  developmentActionTypes.SET_RANGED_INPUT_MINIMUM,
  developmentActionTypes.SET_RANGED_INPUT_MAXIMUM,
  developmentActionTypes.SET_SETBACK_TYPE,
  developmentActionTypes.TOGGLE_BOOLEAN_INPUT,
  developmentActionTypes.SET_CAMERA,
  developmentActionTypes.ADD_SETBACK_FLOOR,
  developmentActionTypes.REMOVE_SETBACK_FLOOR,
  pdfActionTypes.SET_TITLE,
  pdfActionTypes.SET_SUMMARY,
  pdfActionTypes.SET_TO_CONTACTS_DETAILS,
  pdfActionTypes.SET_FROM_CONTACTS_DETAILS,
  pdfActionTypes.SET_COLOR_PALETTE,
  pdfActionTypes.SET_ADDRESS,
]

const autosaveMiddleware = (store) => (next) => (action) => {
  if (AUTOSAVE_TRIGGER_ACTIONS.includes(action.type)) {
    database.startAutoSave();
  }

  return next(action);
}

export default autosaveMiddleware;
