import developmentAccessors from "./utils/developmentAccessors";
import { Development } from "../../types/Development/Development";

/**
 * This is the "default" development object, which can be used before actual
 * data has been initialized and populated.
 */
const initialDevelopment = {
  name: "",
  parcel: {},
  buildingModel: {},
  camera: {},
  values: {},
};

/**
 * Select the whole development.
 */
const getDevelopment = (state): Development => {
  return (state && state.development) || initialDevelopment;
};

/**
 * Select the development's parcel.
 */
const getParcel = (state) => {
  let development = getDevelopment(state);
  return developmentAccessors.getParcel(development);
};

/**
 * Select the development's building model.
 */
const getBuildingModel = (state) => {
  let development = getDevelopment(state);
  return development.buildingModel;
};

/**
 * Select the development's camera.
 */
const getCamera = (state) => {
  let development = getDevelopment(state);
  return development.camera;
};

/**
 * Select the development's name.
 */
const getName = (state) => {
  let development = getDevelopment(state);
  return developmentAccessors.getName(development);
}

/**
 * Select the development's working data values.
 */
const getValues = (state) => {
  let development = getDevelopment(state);
  return developmentAccessors.getValues(development);
};

/**
 * Select an specific development value given its id.
 */
const getValue = (state, valueId, index?) => {
  let values = getValues(state);
  return (index !== undefined) ? values[valueId][index] : values[valueId];
};

/**
 * Selecting only the building usage toggle values from the development's
 * working data values.
 */
const getBuildingUsageToggles = (state) => {
  let development = getDevelopment(state);
  return developmentAccessors.getBuildingUsageToggles(development);
};

/**
 * Select a single increment constraint from the development.
 */
const getIncrementConstraint = (state, incrementId) => {
  let development = getDevelopment(state);
  return developmentAccessors.getInputIncrement(development, incrementId);
};

/**
 * Select a single minimum value constraint from the development.
 */
const getMinimumConstraint = (state, minimumId, index?) => {
  let development = getDevelopment(state);
  return developmentAccessors.getInputMinimum(development, minimumId, index);
};

/**
 * Select a single maximum value constraint from the development.
 */
const getMaximumConstraint = (state, maximumId, index?) => {
  let development = getDevelopment(state);
  return developmentAccessors.getInputMaximum(development, maximumId, index);
};

/**
 * Select the setback type from the development, given the indexes of the polygon and setback.
 */
const getSetbackType = (state, polygonIndex, setbackIndex) => {
  let development = getDevelopment(state);
  return developmentAccessors.getSetbackType(development, development.selectedSetbackFloorIndex, polygonIndex, setbackIndex);
};

/**
 * Select the development's setbacks object.
 */
const getSetbacks = (state) => {
  let development = getDevelopment(state);
  return developmentAccessors.getSetbacks(development, development.selectedSetbackFloorIndex);
};

/**
 * Select the unit system from the development.
 */
const getUnitSystem = (state) => {
  let development = getDevelopment(state);
  return developmentAccessors.getUnitSystem(development);
}

/**
 * Select the is edited flag.
 */
const getIsEdited = (state) => {
  return state.development.isEdited;
}

/**
 * Select the is from shared flag.
 */
const getIsFromShared = (state) => {
  let development = getDevelopment(state);
  return development.isFromShared;
}

/**
 * Get the `floorsWithSetbacks` array from the development object.
 */
const getFloorsWithSetbacks = (state) => {
  let development = getDevelopment(state);
  return development.floorsWithSetbacks;
}

/**
 * Get the `selectedSetbackFloor` value from the development object.
 */
const getSelectedSetbackFloor = (state) => {
  let development = getDevelopment(state);
  return development.selectedSetbackFloor;
}

/**
 * Get the `selectedSetbackFloorIndex` value from the development object.
 */
const getSelectedSetbackFloorIndex = (state) => {
  let development = getDevelopment(state);
  return development.selectedSetbackFloorIndex;
}

export default {
  getDevelopment,
  getParcel,
  getBuildingModel,
  getCamera,
  getName,
  getValues,
  getValue,
  getBuildingUsageToggles,
  getIncrementConstraint,
  getMinimumConstraint,
  getMaximumConstraint,
  getSetbackType,
  getSetbacks,
  getUnitSystem,
  getIsEdited,
  getIsFromShared,
  getFloorsWithSetbacks,
  getSelectedSetbackFloor,
  getSelectedSetbackFloorIndex,
};
