import Unit from "../types/Unit";
import authentication from "./authentication";

/**
 * Create configuration object specific for unitSystem, if there is one available.
 */
const getConfigForUnitSystem = (config, unitSystem: Unit.System) => {
  let unitSpecificConfig: any = {
    ...config,
    ...config[unitSystem],
  }

  unitSpecificConfig.formatOptions = {
    ...config.formatOptions,
    ...unitSpecificConfig.formatOptions
  }

  Object.values(Unit.System).forEach((unitSystem) => delete unitSpecificConfig[unitSystem]);

  return unitSpecificConfig;
}

/**
 * Add the hidden value to the format options of a given config file.
 */
const hydrateHiddenValue = (config) => {
  const userIsAuthenticated = authentication.isUserAuthenticated();
  const hiddenOptions = {
    formatOptions: {
      ...config.formatOptions,
      hidden: !userIsAuthenticated,
    },
  };

  return {
    ...config,
    ...hiddenOptions,
  }
}

export default {
  getConfigForUnitSystem,
  hydrateHiddenValue,
}
