import React from "react";
import { connect } from "react-redux";
import { subscriptionSelectors } from "../../../../state/subscription";
import { Chargebee } from "../../../../types/Service/Chargebee";
import { UserStatus } from "../../../../types/UserStatus";
import authentication from "../../../../utils/authentication";
import userHelper from "../../../../utils/userHelper";
import SignUp from "./SignUp";
import VerifyEmail from "./VerifyEmail";
import wrapComponentWithPopup from "../../../sharedComponents/wrapComponentWithPopup";
import Subscribe from "./Subscribe";
import Welcome from "./Welcome";
import { PopupProps } from "../../../sharedComponents/wrapComponentWithPopup/wrapComponentWithPopup";

const mapStateToProps = (state) => {
  return {
    subscriptionStatus: subscriptionSelectors.getStatus(state),
  }
}

interface OwnProps {
  onClickLogIn(): void;
  subscriptionPlanId: Chargebee.PlanId;
}

type StateProps = ReturnType<typeof mapStateToProps>;
type Props = OwnProps & StateProps & PopupProps;

interface State {
  userIsAuthenticated: boolean | null;
  userIsVerified: boolean;
}

const HEADER_TEXT_MAP = {
  [UserStatus.NotAuthenticated]: "Sign up",
  [UserStatus.Authenticated]: "Email Verification",
  [UserStatus.Verified]: "Checkout",
  [UserStatus.Subscribed]: "Welcome To Deepblocks",
}

const AT_LEAST_AUTHENTICATED = [UserStatus.Authenticated, UserStatus.Verified];

class SignUpFlowPopup extends React.PureComponent<Props, State>  {
  unsubscribeFromAuthenticationStateChange?: () => void;

  constructor(props: Props) {
    super(props);

    this.state = {
      // This value will be null while loading the user authentication state
      // and it will be true or false once the state is loaded.
      userIsAuthenticated: null,
      userIsVerified: false,
    }
  }

  /**
   * Adds listener to authentication.onStateChange
   * to update the state of the component when the
   * authentication state change.
   */
  componentDidMount() {
    this.unsubscribeFromAuthenticationStateChange = authentication.onStateChange(this.updateState);
  }

  /**
   * Unsubscribe from authentication onStateChange when component will unmount.
   */
  componentWillUnmount() {
    if (this.unsubscribeFromAuthenticationStateChange) {
      this.unsubscribeFromAuthenticationStateChange();
    }
  }

  /**
   * Updates userIsAuthenticated and userIsVerified states when the
   * authentication state changes.
   */
  updateState = (user) => {
    let userIsAuthenticated = (user !== null);
    this.setState({
      userIsAuthenticated: userIsAuthenticated,
      userIsVerified: userIsAuthenticated && user.emailVerified,
    });
  }

  /**
   * Set user is verified flag.
   */
  setEmailVerified = () => {
    this.setState({ userIsVerified: true });
  }

  /**
   * Render header section.
   */
  renderHeader = () => {
    let userStatus = this.getUserStatus();

    return <div className="header-container">{HEADER_TEXT_MAP[userStatus]}</div>;
  }

  /**
   * Render navigation icons.
   */
  renderIcons = () => {
    let userStatus = this.getUserStatus();
    if (userStatus === UserStatus.Subscribed) return null;

    return (
      <div className="navigation">
        <div className="icon-wrapper">
          <div className="section active">
            <div className="icon sign-up " />
          </div>
          <div className={`section ${AT_LEAST_AUTHENTICATED.includes(userStatus) ? "active" : ""}`}>
            <div className={`line-divider ${AT_LEAST_AUTHENTICATED.includes(userStatus) ? "active" : ""}`} />
            <div className="icon email-verification " />
          </div>
          <div className={`section ${userStatus === UserStatus.Verified ? "active" : ""}`}>
            <div className={`line-divider ${userStatus === UserStatus.Verified ? "active" : ""}`} />
            <div className="icon checkout" />
          </div>
        </div>
        <div className="label-wrapper">
          <p className="active">Sign Up</p>
          <p className={`${AT_LEAST_AUTHENTICATED.includes(userStatus) ? "active" : ""}`}>Email Verification</p>
          <p className={`${userStatus === UserStatus.Verified ? "active" : ""}`}>Checkout</p>
        </div>
      </div>
    );
  }

  /**
   * Get current user status.
   */
  getUserStatus = () => {
    const { userIsAuthenticated, userIsVerified } = this.state;
    const { subscriptionStatus } = this.props;
    let userStatus = userHelper.getUserStatus(userIsAuthenticated, userIsVerified, subscriptionStatus);

    return userStatus;
  }

  /**
   * Render content based on current user status.
   */
  renderContent = () => {
    const { subscriptionPlanId, onClickLogIn, closePopup } = this.props;
    let userStatus = this.getUserStatus();

    switch (userStatus) {
      case UserStatus.NotAuthenticated: return <SignUp onClickLogIn={onClickLogIn} />;
      case UserStatus.Authenticated: return <VerifyEmail onEmailVerified={this.setEmailVerified} />;
      case UserStatus.Verified: return <Subscribe subscriptionPlanId={subscriptionPlanId} />;
      case UserStatus.Subscribed: return <Welcome handleClickStartWorking={closePopup} />;
      default: return null;
    }
  }

  render() {
    return (
      <div className="component--sign-up-flow-popup">
        {this.renderHeader()}
        {this.renderIcons()}
        {this.renderContent()}
      </div>
    );
  }
}

export default wrapComponentWithPopup(
  connect(mapStateToProps)(SignUpFlowPopup)
);
