import React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { withRouter } from "react-router-dom";
import { subscriptionSelectors } from "../../../state/subscription";
import { Path } from "../../../types/Path";
import authentication from "../../../utils/authentication";
import database from "../../../utils/database/database";
import RedirectButton from "../../sharedComponents/RedirectButton";
import { Chargebee } from "../../../types/Service/Chargebee";
import LogInPopup from "../../sharedComponents/LogInPopup";
import SignUpFlowPopup from "./SignUpFlowPopup";
import SubscriptionRequiredToolbar from "./SubscriptionRequiredToolbar";
import SubscriptionRequiredContent from "./SubscriptionRequiredContent";
import UserLoadingFeedback from "../../sharedComponents/UserLoadingFeedback";
import analytics from "../../../utils/analytics";

const mapStateToProps = (state) => {
  return {
    subscriptionId: subscriptionSelectors.getId(state),
  }
}

type StateProps = ReturnType<typeof mapStateToProps>;
type Props = StateProps & RouteComponentProps;

interface State {
  selectPlanInformationIsOpen: boolean;
  logInIsOpen: boolean;
  subscriptionPlanId: Chargebee.PlanId | null;
  databaseIsReady: boolean;
}

class SubscriptionRequiredPage extends React.PureComponent<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      selectPlanInformationIsOpen: true,
      logInIsOpen: false,
      subscriptionPlanId: null,
      databaseIsReady: false,
    };

    analytics.trackPageview(Path.Subscribe);
  }

  /**
   * Ensure this.initialize is called once the database is initialized.
   */
  componentDidMount() {
    if (!database.isInitialized()) {
      database.onInitialized(this.initialize);
    } else {
      this.initialize();
    }
  }

  /**
   * Ensure this.initialize is not called after this component has been
   * unmounted.
   */
  componentWillUnmount() {
    database.removeOnInitializedCallback(this.initialize);
  }

  /**
   * Set the databaseIsReady flag to true.
   */
  initialize = () => {
    this.setState({ databaseIsReady: true });
  }

  /*
   * Redirect to dashboard.
   */
  redirectToDashboard = () => this.props.history.push(Path.Dashboard);

  /**
   * Set the setSubscriptionPlanId.
   */
  setSubscriptionPlanId = (subscriptionPlanId: Chargebee.PlanId) => {
    this.setState({ subscriptionPlanId });
  }

  /**
   * Close the SignUpFlowPopup.
   */
  closeSignUpFlowPopup = () => {
    if (this.props.subscriptionId) {
      this.props.history.push(Path.Dashboard);
    } else {
      this.setState({ subscriptionPlanId: null });
    }
  }

  /**
   * Toggle the sign up popup.
   */
  toggleLogInIsOpen = () => {
    this.setState({ logInIsOpen: !this.state.logInIsOpen, subscriptionPlanId: null });
  }

  /**
   * Sign out and redirect home.
   */
  signOut = async () => {
    await authentication.signOut();
    this.forceUpdate();
    this.props.history.push(Path.Home);
  }

  /**
   * Render the side buttons.
   */
  renderButtons = () => {
    const userIsAuthenticated = authentication.isUserAuthenticated();

    if (userIsAuthenticated) {
      return (
        <button className="btn sign-out" onClick={this.signOut}>
          Log Out
        </button>
      );
    } else {
      return (
        <>
          <button className="btn authentication" onClick={this.toggleLogInIsOpen}>
            Log In
          </button>
          <RedirectButton
            text="Go to Demo"
            path={Path.Home}
            classes="go-to-demo"
          />
        </>
      );
    }
  }

  /**
   * Render the select plan information.
   */
  renderSelectPlanInformation = () => {
    const user = database.getCurrentUser();
    return (
      <div className="select-plan-information">
        <div className="header">{`Hi${(user && user.firstName) ? ` ${user.firstName}` : ""},`}</div>
        <div className="separator" />
        <div className="header">
          {this.props.subscriptionId
              ? "Changing your subscription?"
              : "Select plan to start your subscription."
          }
        </div>
        <div className="text">These prices are BETA prices and subject to change. Subscribe to yearly plan and keep the price for 12 months.</div>
      </div>
    );
  }

  render() {
    if (!this.state.databaseIsReady) return <UserLoadingFeedback />;

    return (
      <div className="component--subscription-required-page">
        <SubscriptionRequiredToolbar />
        <div className="side">
          {this.renderSelectPlanInformation()}
          <div className="buttons-container">
            {this.renderButtons()}
          </div>
        </div>
        <SubscriptionRequiredContent onSelect={this.setSubscriptionPlanId} />
        {this.state.subscriptionPlanId && <SignUpFlowPopup subscriptionPlanId={this.state.subscriptionPlanId} onStateChange={this.closeSignUpFlowPopup} onClickLogIn={this.toggleLogInIsOpen} />}
        {this.state.logInIsOpen && <LogInPopup onStateChange={this.toggleLogInIsOpen} classes="on-subscribe-page" onLogIn={this.redirectToDashboard}/>}
      </div>
    );
  }
}

export default withRouter(connect(mapStateToProps)(SubscriptionRequiredPage)) as any;
