import React from "react";
import { connect } from "react-redux";
import { newDevelopmentSelectors, newDevelopmentActions } from "../../../state/newDevelopment";
import { KeyCode } from "../../../types/KeyCodes";
import analytics from "../../../utils/analytics";
import { userSelectors } from "../../../state/user";
import { MapStyleProperties } from "../../../utils/mapbox/mapStyleProperties";

const mapDispatchToProps = {
  addressKeystroke: newDevelopmentActions.addressKeystroke,
  addressSubmit: newDevelopmentActions.addressSubmit,
  clearFeatureSelection: newDevelopmentActions.clearFeatureSelection,
  suggestedFeatureNext: newDevelopmentActions.suggestedFeatureNext,
  suggestedFeaturePrevious: newDevelopmentActions.suggestedFeaturePrevious,
}

const mapStateToProps = (state) => {
  return {
    pinPosition: newDevelopmentSelectors.getPinPosition(state),
    suggestedFeatures: newDevelopmentSelectors.getSuggestedFeatures(state),
    searchAddress: newDevelopmentSelectors.getSearchAddress(state),
    proximityCenter: newDevelopmentSelectors.getGeocoderProximityCenter(state) || userSelectors.getLocation(state),
    isTyping: newDevelopmentSelectors.getUserIsTyping(state),
    selectedFeature: newDevelopmentSelectors.getSelectedFeature(state),
    activeSuggestionIndex: newDevelopmentSelectors.getSuggestedFeaturesSelectionIndex(state)
  }
}

interface OwnProps {
  placeholder?: string;
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;
type Props = StateProps & DispatchProps & OwnProps;

interface State {
  showSuggestionDropdown: boolean;
  placeholder: string;
}

const MIN_QUERY_LENGTH = 2;

class MapboxGeocoder extends React.Component<Props, State> {
  readonly INITIAL_STATE: State;

  constructor(props: Props) {
    super(props);
    this.INITIAL_STATE = {
      showSuggestionDropdown: false,
      placeholder: props.placeholder || "Search",
    }

    this.state = this.INITIAL_STATE;
  }

  /**
   * This function resets the geocode state in redux, along with the internal state of the component.
   */
  clearFeatureSelection = () => {
    this.props.clearFeatureSelection();
    this.setState(this.INITIAL_STATE)
  }

  /**
   * This function selects the first feature from the suggestion list.
   * The suggestion list is ordered by relevance.
   * See https://www.mapbox.com/api-documentation/?language=JavaScript#response-object
   */
  handleSearchClick = (event) => {
    if (this.props.suggestedFeatures.length > 0) {
      const suggestedFeature = this.props.suggestedFeatures[0];
      this.props.addressSubmit(suggestedFeature);
      analytics.trackAddressSubmit(suggestedFeature.place_name);
    }
  }

  /**
   * This function handles the changes in the input element and does a forward geocode query
   * to mapbox with the value of the input box.
   * This function handles changes including pasting either by keyboard (e.g. Ctrl + V) or pasting with the mouse.
   */
  handleInputChange = (event) => {
    const { proximityCenter } = this.props;
    const geocoderProximityCenter = proximityCenter || MapStyleProperties.camera.center;

    let searchAddress = event.target.value;
    let addressIsValid = this.searchAddressIsValid(searchAddress);
    this.props.addressKeystroke(searchAddress, geocoderProximityCenter, addressIsValid);

    this.setState({ showSuggestionDropdown: addressIsValid });
  }

  /**
   * Returns conditional boolean indicating input value is valid.
   */
  searchAddressIsValid = (searchAddress) => {
    // TODO: Clean up handling of minimum address length.
    // See https://deepblocks.tpondemand.com/entity/4076-clean-up-handling-of-minimum-address
    return searchAddress.length >= MIN_QUERY_LENGTH;
  }

  /**
   * This function handles the navigation of the suggestion dropdown. Either by highlighting
   * suggestions with the up and down key, selecting a suggestion or hiding the dropdown.
   */
  handleInputKeyDown = (event) => {
    switch (event.key) {
      case KeyCode.Esc:
        this.handleKeyPressEsc();
        break;
      case KeyCode.Enter:
        this.handleKeyPressEnter();
        break;
      case KeyCode.ArrowDown:
        this.props.suggestedFeatureNext();
        break;
      case KeyCode.ArrowUp:
        this.props.suggestedFeaturePrevious();
        break;
      default: break;
    }
  }

  /**
   * Hide the suggestion dropdown when the Esc key is pressed.
   */
  handleKeyPressEsc = () => {
    this.hideSuggestionDropdown();
  }

  /**
   * Selects feature highlighted in the suggestion dropdown, or selects
   * the first one if no suggestion is highlighted.
   */
  handleKeyPressEnter = () => {
    let activeIndex = this.props.activeSuggestionIndex;
    if (activeIndex >= 0 || this.props.suggestedFeatures.length > 0) {
      activeIndex = activeIndex === -1 ? 0 : activeIndex;
      let suggestedFeature = this.props.suggestedFeatures[activeIndex];
      this.props.addressSubmit(suggestedFeature);
      analytics.trackAddressSubmit(suggestedFeature.place_name);
    }
  }

  /**
   * Hide the suggestion dropdown when the user clicks outside of the input element.
   */
  handleInputBlur = (event) => {
    this.hideSuggestionDropdown();
  }

  /**
   * Show the suggestion dropdown when the input element gains focus.
   */
  handleInputFocus = (event) => {
    this.setState({ showSuggestionDropdown: this.searchAddressIsValid(this.props.searchAddress) })
  }

  /**
   * This function hides the suggestion dropdown.
   */
  hideSuggestionDropdown = () => {
    this.setState({ showSuggestionDropdown: false })
  }

  /**
   * Normalizes `value` to lowercase letters.
   */
  normalize = (value) => {
    return value.toLowerCase();
  }

  /**
   * Returns a React JSX component in which each occurrence of the keyword in the
   * string has been wrapped to bold the occurrences.
   */
  markAllOccurrences = (message, keyword) => {
    let normalizedPlaceName = this.normalize(message);
    let normalizedKeyword = this.normalize(keyword);
    let offset = 0;
    return normalizedPlaceName
      .split(normalizedKeyword)
      .reduce((previous, current, index) => {
        let next = index === 0
          ? [message.substr(offset, offset + current.length)]
          : previous.concat(<strong key={index}>{keyword}</strong>, message.substr(offset, offset + current.length))
        offset += keyword.length + current.length;

        return next;
      }, [])
  }

  /**
   * Returns conditional boolean for rendering the suggestion dropdown.
   */
  suggestionDropdownIsEnabled = () => {
    return this.state.showSuggestionDropdown && this.props.isTyping && this.props.suggestedFeatures.length > 0;
  }

  /**
   * Returns function wrapper that submits address and tracks address submit.
   */
  handleSuggestionMouseDown = (feature) => {
    return () => {
      this.props.addressSubmit(feature);
      analytics.trackAddressSubmit(feature.place_name);
    }
  }

  /**
   * Returns React JSX component corresponding to the appropriate icon in the geocoder search bar.
   */
  renderGeocoderIcons = () => {
    if (this.props.searchAddress && !this.props.isTyping) {
      return (
        <span
          onClick={this.clearFeatureSelection}
          className="geocoder-icon geocoder-icon-close"
        />
      )
    } else {
      return (
        <span
          onClick={this.handleSearchClick}
          className={`geocoder-icon geocoder-icon-search ${this.searchAddressIsValid(this.props.searchAddress) ? "active" : "inactive"}`}
        />
      )
    }
  }

  /**
   * Returns React JSX component of the suggestion dropdown when it is appropriate to render.
   */
  renderDropdown = () => {
    if (this.suggestionDropdownIsEnabled()) {
      return (
        <ul>
          {this.props.suggestedFeatures.map((feature, index) => {
            return (
              <li
                key={feature.id}
                className={this.props.activeSuggestionIndex === index ? "active" : ""}
              >
                <div className="suggestion" onMouseDown={this.handleSuggestionMouseDown(feature)}>
                  {this.markAllOccurrences(feature.place_name, this.props.searchAddress)}
                </div>
              </li>
            )
          })}
        </ul>
      )
    }
    return null;
  }

  render() {
    return (
      <div className="component--mapbox-geocoder" >
        <div className="geocoder-input">
          {this.renderGeocoderIcons()}
          <input
            type="text"
            placeholder={this.state.placeholder}
            onKeyDown={this.handleInputKeyDown}
            onChange={this.handleInputChange}
            onFocus={this.handleInputFocus}
            onBlur={this.handleInputBlur}
            value={this.props.searchAddress}
            autoFocus
          />
          {this.renderDropdown()}
        </div>
      </div>
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MapboxGeocoder);
