import actionTypes from "./actionTypes";
import { GeoJSON, Position } from "geojson";
import { KeyCode } from "../../types/KeyCodes";
import { ParcelTool } from "../../types/ParcelTool";
import Unit from "../../types/Unit";
import { FilterId } from "../../types/Filter";
import { VariableId } from "../../types/VariableId";
import { Values } from "../../types/Development/Values";

/**
 * Initialize the newDevelopment state with default values.
 */
const initialize = () => {
  return {
    type: actionTypes.INITIALIZE
  };
}

/**
 * Update the search bar geocoding.
 *
 * @param {string} searchAddress
 * @param {[number, number]} proximityCenter
 * @param {boolean} proceedToQuery
 */
const addressKeystroke = (
  searchAddress: string,
  proximityCenter: [number, number],
  proceedToQuery: boolean,
) => {
  return {
    type: actionTypes.ADDRESS_KEYSTROKE,
    payload: {
      searchAddress,
      proximityCenter,
      proceedToQuery,
    },
  };
}

/**
 * Set the input value in the state.
 *
 * @param {string} searchAddress
 * @param {boolean} geocoderIsQuerying
 */
const addressKeystrokeAndForwardGeocodeStart = (searchAddress: string, geocoderIsQuerying: boolean) => {
  return {
    type: actionTypes.ADDRESS_KEYSTROKE_AND_FORWARD_GEOCODE_START,
    payload: {
      searchAddress,
      geocoderIsQuerying,
    },
  };
}

/**
 * Submit address search.
 *
 * @param {Object} suggestedFeature
 */
const addressSubmit = (suggestedFeature: GeoJSON) => {
  return {
    type: actionTypes.ADDRESS_SUBMIT,
    payload: {
      suggestedFeature,
    },
  };
}

/**
 * Clears feature selection.
 */
const clearFeatureSelection = () => {
  return {
    type: actionTypes.CLEAR_FEATURE_SELECTION,
  };
}

/**
 * Success retrieval of feature or features from mapbox geocode service.
 * See https://www.mapbox.com/api-documentation/?language=JavaScript#response-object
 *
 * @param {Object} results
 */
const forwardGeocodeSuccess = (results: Array<GeoJSON>) => {
  return {
    type: actionTypes.FORWARD_GEOCODE_SUCCESS,
    payload: {
      results,
    },
  };
}

/**
 * Error response from the mapbox geocode service.
 *
 * @param error
 */
const geocodeError = (error: string) => {
  return {
    type: actionTypes.GEOCODE_ERROR,
    payload: {
      error,
    },
  };
}

/**
 * Set the hovered feature in the state.
 *
 * @param {GeoJSON} hoveredFeature
 */
const hoverFeature = (hoveredFeature: GeoJSON) => {
  return {
    type: actionTypes.HOVER_FEATURE,
    payload: {
      hoveredFeature,
    },
  };
}

/**
 * Reverse geocode the clicked coordinates and dispatch the selectParcelSuccess action.
 *
 * @param {GeoJSON} selectedParcel
 * @param {[number, number]} clickedCoordinates
 */
const selectParcelStart = (selectedParcel: GeoJSON, clickedCoordinates: [number, number]) => {
  return {
    type: actionTypes.SELECT_PARCEL_START,
    payload: {
      selectedParcel,
      clickedCoordinates,
    },
  };
}

/**
 * Set the selected feature in the state and update the searchAddress.
 *
 * @param {GeoJSON} selectedParcel
 * @param {GeoJSON} geocodedFeature
 */
const selectParcelSuccess = (selectedParcel: GeoJSON, geocodedFeature: GeoJSON) => {
  return {
    type: actionTypes.SELECT_PARCEL_SUCCESS,
    payload: {
      selectedParcel,
      geocodedFeature,
    },
  };
}

/**
 * Reverse geocode the clicked coordinates and dispatch the combineParcelsSuccess action.
 */
const combineParcelsStart = (clickedParcel: GeoJSON, clickedCoordinates: [number, number], selectedFeatureMembers) => {
  return {
    type: actionTypes.COMBINE_PARCELS_START,
    payload: {
      clickedParcel,
      clickedCoordinates,
      selectedFeatureMembers,
    },
  };
}

/**
 * Set the selected feature in the state and update the searchAddress.
 *
 * @param {GeoJSON} clickedParcel
 * @param {GeoJSON} geocodedFeature
 */
const combineParcelsSuccess = (clickedParcel: GeoJSON, geocodedFeature: GeoJSON, selectedFeatureMembers: Array<GeoJSON>) => {
  return {
    type: actionTypes.COMBINE_PARCELS_SUCCESS,
    payload: {
      clickedParcel,
      geocodedFeature,
      selectedFeatureMembers,
    },
  };
}

/**
 * Set the given drawn parcels.
 *
 * @param {Array<GeoJSON>} drawnParcels
 */
const setDrawnParcels = (drawnParcels: Array<GeoJSON>) => {
  return {
    type: actionTypes.SET_DRAWN_PARCELS,
    payload: {
      drawnParcels,
    },
  };
}

/**
 * Set the proximityCenter in the state.
 *
 * @param {[number,number]} geocoderProximityCenter
 */
const setProximityCenter = (geocoderProximityCenter: Position) => {
  return {
    type: actionTypes.SET_PROXIMITY_CENTER,
    payload: {
      geocoderProximityCenter,
    },
  };
}

/**
 * Handle when user presses up key.
 */
const suggestedFeatureNext = () => {
  return {
    type: actionTypes.SUGGESTED_FEATURE_NEXT,
  };
}

/**
 * Handle when user presses down key.
 */
const suggestedFeaturePrevious = () => {
  return {
    type: actionTypes.SUGGESTED_FEATURE_PREVIOUS,
  };
}

/**
 * Set the parcelTool and parcelToolFromToolbar in the state.
 */
const setParcelTool = (parcelTool: ParcelTool) => {
  return {
    type: actionTypes.SET_PARCEL_TOOL,
    payload: {
      parcelTool,
    },
  };
}

/**
 * Set the mapIsReady in the state.
 */
const setMapIsReady = (mapIsReady: boolean) => {
  return {
    type: actionTypes.SET_MAP_IS_READY,
    payload: {
      mapIsReady,
    },
  };
}

/**
 * Set the polygon measurements in the state.
 */
const setPolygonMeasurements = (feature: GeoJSON) => {
  return {
    type: actionTypes.SET_POLYGON_MEASUREMENTS,
    payload: {
      feature,
    },
  };
}

/**
 * Reset polygon measurements to default values.
 */
const resetPolygonMeasurements = () => {
  return {
    type: actionTypes.RESET_POLYGON_MEASUREMENTS
  };
}

/**
 * Set the polygonIsBeingChanged in the state.
 */
const setPolygonIsBeingChanged = (polygonIsBeingChanged: boolean) => {
  return {
    type: actionTypes.SET_POLYGON_IS_BEING_CHANGED,
    payload: {
      polygonIsBeingChanged,
    },
  };
}

/**
 * Set the appropriate parcelTool in the state.
 */
const modifierKeyDown = (keyCode: KeyCode) => {
  return {
    type: actionTypes.MODIFIER_KEY_DOWN,
    payload: {
      keyCode,
    },
  };
}

/**
 * Set the appropriate parcel tool in the state.
 */
const modifierKeyUp = (keyCode: KeyCode) => {
  return {
    type: actionTypes.MODIFIER_KEY_UP,
    payload: {
      keyCode,
    },
  };
}

/**
 * Set unit system in the state.
 */
const setUnitSystem = (unitSystem: Unit.System) => {
  return {
    type: actionTypes.SET_UNIT_SYSTEM,
    payload: {
      unitSystem,
    }
  }
}

/**
 * Set the smartSearchIsOpen flag in the state.
 */
const setSmartSearchIsOpen = (smartSearchIsOpen: boolean) => {
  return {
    type: actionTypes.SET_SMART_SEARCH_IS_OPEN,
    payload: {
      smartSearchIsOpen,
    },
  };
}

/**
 * Set the displayPin flag in the state.
 */
const setDisplayPin = (displayPin: boolean) => {
  return {
    type: actionTypes.SET_DISPLAY_PIN,
    payload: {
      displayPin,
    },
  };
}

/**
 * Set the parcels visible in the Viewport.
 *
 * @param {Array<GeoJSON>} parcelsInViewport
 */
const setParcelsInViewport = (parcelsInViewport: Array<GeoJSON>) => {
  return {
    type: actionTypes.SET_PARCELS_IN_VIEWPORT,
    payload: {
      parcelsInViewport,
    },
  };
}

/**
 * Update the filter at the given index.
 */
const updateFilter = (filterId: FilterId, isActive: boolean, value: number[]) => {
  return {
    type: actionTypes.UPDATE_FILTER,
    payload: {
      filterId,
      isActive,
      value,
    },
  };
}

/**
 * Update initial development values.
 */
const updateInitialValues = (values: { [key in VariableId]?: Values[key] }) => {
  return {
    type: actionTypes.UPDATE_INITIAL_VALUES,
    payload: {
      values,
    }
  }
}

/**
 * Set the parcelDataInViewport and zoningDataInViewport flags.
 */
const setDataInViewport = (parcelDataInViewport: boolean, zoningDataInViewport: boolean) => {
  return {
    type: actionTypes.SET_DATA_IN_VIEWPORT,
    payload: {
      parcelDataInViewport,
      zoningDataInViewport,
    },
  };
}

/**
 * Set the default open panel.
 */
const setDefaultPanel = () => {
  return {
    type: actionTypes.SET_DEFAULT_PANEL,
  };
}

/**
 * Set the drawParcelInformationPanelIsOpen flag.
 */
const setDrawParcelInformationPanelIsOpen = (drawParcelInformationPanelIsOpen: boolean) => {
  return {
    type: actionTypes.SET_DRAW_PARCEL_INFORMATION_PANEL_IS_OPEN,
    payload: {
      drawParcelInformationPanelIsOpen,
    }
  };
}

export default {
  initialize,
  addressKeystroke,
  addressKeystrokeAndForwardGeocodeStart,
  addressSubmit,
  clearFeatureSelection,
  forwardGeocodeSuccess,
  geocodeError,
  hoverFeature,
  selectParcelStart,
  selectParcelSuccess,
  combineParcelsStart,
  combineParcelsSuccess,
  setProximityCenter,
  suggestedFeatureNext,
  suggestedFeaturePrevious,
  setPolygonMeasurements,
  resetPolygonMeasurements,
  setPolygonIsBeingChanged,
  setParcelTool,
  setMapIsReady,
  modifierKeyDown,
  modifierKeyUp,
  setDrawnParcels,
  setUnitSystem,
  setSmartSearchIsOpen,
  setDisplayPin,
  setParcelsInViewport,
  updateFilter,
  updateInitialValues,
  setDataInViewport,
  setDefaultPanel,
  setDrawParcelInformationPanelIsOpen,
}
