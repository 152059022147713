import React from "react";
import styled from "@react-pdf/styled-components";
import developmentAccessors from "../../../../../../../state/development/utils/developmentAccessors";
import { incomeProducingUsesIsEnabled, forSaleUsesIsEnabled } from "../../../../../../utils/uiToggleHelper";
import ConfigurationHandler from "../../../../../ConfigurationHandler";
import PdfContext from "../../../PdfContext";
import Section from "./Section";
import DottedBorderRow from "./DottedBorderRow";
import SolidBorderRow from "./SolidBorderRow";
import BoldBorderRow from "./BoldBorderRow";
import config from "./config";

const Container = styled.View`
  min-height: 425px;
  background-color: #FFFFFF;
  padding: 15px 21px 32px;
`;

const BackOfEnvelope = () => (
  <PdfContext.Consumer>
    {({ development, unitSystem }) => {
      let toggles = developmentAccessors.getBuildingUsageToggles(development);
      let shouldDisplayIncomeProducingUses = incomeProducingUsesIsEnabled(toggles);
      let shouldDisplayForSale = forSaleUsesIsEnabled(toggles);

      return (
        <Container>
          <Section title={"INCOME PRODUCING"} displaySection={shouldDisplayIncomeProducingUses}>
            <ConfigurationHandler config={config.incomeProducingUsesAnnualPotentialGrossIncomeForBackOfEnvelope} component={DottedBorderRow} unitSystem={unitSystem} />
            <ConfigurationHandler config={config.incomeProducingUsesAnnualVacancyExpenseForBackOfEnvelope} component={DottedBorderRow} unitSystem={unitSystem} />
            <ConfigurationHandler config={config.incomeProducingUsesAnnualOperatingExpenseForBackOfEnvelope} component={DottedBorderRow} unitSystem={unitSystem} />
            <ConfigurationHandler config={config.incomeProducingUsesAnnualNetOperatingIncomeForBackOfEnvelope} component={SolidBorderRow} unitSystem={unitSystem} />
            <ConfigurationHandler config={config.incomeProducingUsesTotalDevelopmentCostIncludingParking} component={DottedBorderRow} unitSystem={unitSystem} />
            <ConfigurationHandler config={config.incomeProducingUsesAnnualReturnOnCostForBackOfEnvelope} component={BoldBorderRow} unitSystem={unitSystem} />
          </Section>

          <Section title={"FOR SALE"} displaySection={shouldDisplayForSale}>
            <ConfigurationHandler config={config.condoNetProceeds} component={DottedBorderRow} unitSystem={unitSystem} />
            <ConfigurationHandler config={config.condoTotalDevelopmentCostIncludingParking} component={DottedBorderRow} unitSystem={unitSystem} />
            <ConfigurationHandler config={config.condoProfit} component={SolidBorderRow} unitSystem={unitSystem} />
            <ConfigurationHandler config={config.condoReturnOnCost} component={BoldBorderRow} unitSystem={unitSystem} />
          </Section>
        </Container>
      );
    }}
  </PdfContext.Consumer>
);

export default BackOfEnvelope;
