import analytics from "../../../../../utils/analytics";
import configHelper from "../../../../../utils/configHelper";
import Unit from "../../../../../types/Unit";
import kpiList from "./kpiList";

const selectedKpis = [kpiList.projectGrossBuildableArea, kpiList.floorAreaRatio, kpiList.projectNumberOfFloors];

const getKpiListForUnitSystem = (unitSystem: Unit.System) => {
  return Object.values(kpiList).map((config) => {
    if (!config) return {};

    const unitSpecificConfig = configHelper.getConfigForUnitSystem(config, unitSystem);

    return {
      variableId: unitSpecificConfig.variableId,
      buildingUse: unitSpecificConfig.buildingUse,
      text: unitSpecificConfig.text,
    }
  });
}

const freshToggles = selectedKpis.map((kpi, index) => index).reduce(
  (acc, id) =>
    Object.assign(acc, {
      [id]: false,
    }),
  {}
);

const setToggles = (toggles, id, flip) => ({
  toggles: Object.assign(
    Object.keys(toggles).reduce(
      (acc, id) =>
        Object.assign(acc, {
          [id]: false,
        }),
      {}
    ),
    {
      [id]: flip,
    }
  )
});

const swap = (selectedKpis, index, kpiId) => {
  analytics.trackSelectKpi(kpiId);
  selectedKpis[index] = Object.values(kpiList).find((kpi) => kpi && kpi.variableId === kpiId);
}

export {
  selectedKpis,
  kpiList,
  freshToggles,
  setToggles,
  swap,
  getKpiListForUnitSystem,
};
