import React from "react";
import { ChromePicker, ColorResult } from "react-color";
import { connect } from "react-redux";
import { pdfActions, pdfSelectors } from "../../../../../../state/pdf";
import { PdfState } from "../../../../../../state/pdf/reducers";

const mapStateToProps = (state) => {
  return {
    colorPalette: pdfSelectors.getColorPalette(state),
  }
}

const mapDispatchToProps = {
  setColorPalette: pdfActions.setColorPalette,
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;
type Props = StateProps & DispatchProps;

type State = {
  colorPaletteKey?: keyof PdfState["colorPalette"];
}

class ColorPalette extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  /**
   * Set color in the store.
   */
  setColor = (color: string, stateKey: keyof PdfState["colorPalette"]) => {
    this.props.setColorPalette({ [stateKey]: color });
  }

  /**
   * Open color picker.
   */
  openColorPicker = (stateKey: keyof PdfState["colorPalette"]) => {
    this.setState({ colorPaletteKey: stateKey });
  }

  /**
   * Close Color picker.
   */
  closeColorPicker = () => {
    this.setState({ colorPaletteKey: undefined });
  }

  /**
   * Render the color picker.
   */
  renderSketchPicker = () => {
    const { colorPaletteKey } = this.state;
    if (!colorPaletteKey) return null;
    const { colorPalette } = this.props;

    return (
      <div className="color-picker-wrapper">
        <div className="cover" onClick={() => this.closeColorPicker()} />
        <ChromePicker color={colorPalette[colorPaletteKey]} onChange={(props: ColorResult) => this.setColor(props.hex, colorPaletteKey)} />
        <div className="close-color-picker" onClick={() => this.closeColorPicker()}>X</div>
      </div>
    );
  }

  render() {
    const { colorPalette } = this.props;

    return (
      <div className="component--color-palette">
        {this.renderSketchPicker()}
        <div className="label-container">
          <div className="palette-icon" />
          <label>Choose Color Palette</label>
        </div>
        <div className="color-selection-container">
          <div className="color-section">
            <label>Primary Color</label>
            <div
              className="color-preview"
              style={{ backgroundColor: colorPalette.primary }}
              onClick={() => this.openColorPicker("primary")}
            />
            <span className="pick-color">Click to Pick a Color</span>
          </div>
          <div className="color-section">
            <label>Secondary Color</label>
            <div
              className="color-preview"
              style={{ backgroundColor: colorPalette.secondary }}
              onClick={() => this.openColorPicker("secondary")}
            />
            <span className="pick-color">Click to Pick a Color</span>
          </div>
          <div className="color-section">
            <label>Text Color</label>
            <div
              className="color-preview"
              style={{ backgroundColor: colorPalette.text }}
              onClick={() => this.openColorPicker("text")}
            />
            <span className="pick-color">Click to Pick a Color</span>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ColorPalette);
