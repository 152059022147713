import firebase from "firebase/app";
import "firebase/firestore";
import migration from "./migration";
import hydration from "./hydration";
import missingAsyncData from "./missingAsyncData";
import { FirestoreCollection } from "..";
import { jsonDeepCopy } from "../../deepCopy";
import DocumentNotFoundError from "../../../errors/DocumentNotFoundError"

export enum FirebaseOperationType {
  Set = "SET",
  Update = "UPDATE",
  Delete = "DELETE"
};

export interface FirebaseOperation {
  type: FirebaseOperationType;
  documentReference: firebase.firestore.DocumentReference;
  data?: firebase.firestore.DocumentData;
};

/**
 * Get a Firebase document given its reference and return the migrated and
 * hydrated data.
 */
const getDocument = async (documentReference: firebase.firestore.DocumentReference) => {
  const documentSnapshot = await documentReference.get();
  if (!documentSnapshot.exists) throw new DocumentNotFoundError(`The document at "${documentReference.path}" does not exist.`);

  const rawData = documentSnapshot.data();
  let incomingData = timestampsToMilliseconds(rawData);

  const collection = getCollectionName(documentReference);

  if (migration.requiresMigration(incomingData, collection)) {
    // A migration is needed.
    migration.migrateData(incomingData, collection);

    // Preserve the existing server timestamps.
    stripTimestamps(incomingData);

    // Write migration back to database.
    await documentReference.update(incomingData);

    // Read data from database again. Important if the migration included
    // deleting information. Otherwise, the migrated copy of the data will still
    // contain the sentinel values used to trigger deletions on the back end.
    return await getDocument(documentReference);
  }

  const missingDataWasPopulated = await missingAsyncData.populate(incomingData, collection);
  if (missingDataWasPopulated) {
    // Preserve the existing server timestamps.
    stripTimestamps(incomingData);

    // Write changes back to database.
    await documentReference.update(incomingData);

    return await getDocument(documentReference);
  }

  hydration.hydrateData(incomingData, collection);
  return incomingData;
}

/**
 * De-initialize the given data and write it (by overriding it) into the firebase document
 * at the given reference.
 */
const createDocument = async (documentReference: firebase.firestore.DocumentReference, data: firebase.firestore.DocumentData) => {
  data = prepareOutgoingData(documentReference, data);
  data.timeCreated = firebase.firestore.FieldValue.serverTimestamp();
  data.timeModified = firebase.firestore.FieldValue.serverTimestamp();
  await documentReference.set(data);
}

/**
 * De-initialize the given data and update with it the Firebase document at the given reference.
 */
const updateDocument = async (documentReference: firebase.firestore.DocumentReference, data: firebase.firestore.DocumentData) => {
  data = prepareOutgoingData(documentReference, data);
  data.timeModified = firebase.firestore.FieldValue.serverTimestamp();
  await documentReference.update(data);
}

/**
 * Batch write an array of "firebase operations". Each operation has:
 * - type: set, update or delete.
 * - reference: a reference to the document to write.
 * - data: the data to write, this field is optional as it is not needed for deletes.
 */
const batchWrite = async (operations: FirebaseOperation[]) => {
  let batch = firebase.firestore().batch();
  operations.forEach(
    (operation) => {
      switch (operation.type) {
        case FirebaseOperationType.Set:
          if (operation.data) {
            let data = prepareOutgoingData(operation.documentReference, operation.data);
            data.timeCreated = firebase.firestore.FieldValue.serverTimestamp();
            data.timeModified = firebase.firestore.FieldValue.serverTimestamp();
            batch.set(operation.documentReference, data);
          }
          break;
        case FirebaseOperationType.Update:
          if (operation.data) {
            let data = prepareOutgoingData(operation.documentReference, operation.data);
            data.timeModified = firebase.firestore.FieldValue.serverTimestamp();
            batch.update(operation.documentReference, data);
          }
          break;
        case FirebaseOperationType.Delete:
          batch.delete(operation.documentReference);
          break;
        default:
          break;
      }
    }
  );
  await batch.commit();
}

/**
 * Prepare data from local memory to be uploaded to the database.
 *
 * Note: This function does not modify its arguments, it creates a
 * deep copy of the `data` argument and modifies the copy instead.
 */
const prepareOutgoingData = (documentReference: firebase.firestore.DocumentReference, data) => {
  let outgoingData = jsonDeepCopy(data);
  const collection = getCollectionName(documentReference);
  hydration.dehydrateData(outgoingData, collection);
  migration.versionData(outgoingData, collection);
  stripTimestamps(outgoingData);
  return outgoingData;
}

/**
 * Get the collection name given a reference of a document within it.
 */
const getCollectionName = (documentReference: firebase.firestore.DocumentReference) => {
  const path = documentReference.path;
  const collection = path.split("/")[0];
  return collection as FirestoreCollection;
}

/**
 * Make a copy of the document object where the timeCreated and timeModified timestamps
 * are translated into milliseconds since epoch.
 */
const timestampsToMilliseconds = (rawData) => {
  let timeCreated, timeModified;
  if (rawData && rawData.timeModified instanceof firebase.firestore.Timestamp) {
    timeModified = rawData.timeModified.toMillis();
    delete rawData.timeModified;
  }

  if (rawData && rawData.timeCreated instanceof firebase.firestore.Timestamp) {
    timeCreated = rawData.timeCreated.toMillis();
    delete rawData.timeCreated;
  }

  let modifiedData = jsonDeepCopy(rawData);
  modifiedData.timeCreated = timeCreated;
  modifiedData.timeModified = timeModified;

  return modifiedData;
}

/**
 * Strip the the `timeCreated` and `timeModified` timestamps from the given data.
 * When using Firestore `update()`, this will cause existing server timestamps
 * to be preserved unless they are explicitly overwritten.
 */
const stripTimestamps = (data) => {
  delete data.timeCreated;
  delete data.timeModified;
}

export default {
  getDocument,
  createDocument,
  updateDocument,
  batchWrite
}
