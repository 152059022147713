/**
 * Add the `firstName`, `middleName`, `lastName`, `companyName` and `companySize` fields.
 */
const addUserInformationFields = (user) => {
  user.firstName = null;
  user.middleName = null;
  user.lastName = null;
  user.companyName = null;
  user.companySize = null;
}

export default addUserInformationFields;
