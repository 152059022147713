import React from "react";
import { connect } from "react-redux";
import { developmentSelectors } from "../../../../../../../state/development";
import authentication from "../../../../../../../utils/authentication";
import CellsContainer from "../../../../../../sharedComponents/CellsContainer";
import CellRow from "../../../../../../sharedComponents/CellRow";
import ConfigurationHandler from "../../../../../../sharedComponents/ConfigurationHandler";
import config from "./config";

const mapStateToProps = (state) => {
  return {
    unitSystem: developmentSelectors.getUnitSystem(state),
  }
}

interface OwnProps {
  index: number;
  currentTab: number;
}

type StateProps = ReturnType<typeof mapStateToProps>;
type Props = OwnProps & StateProps;

class BackOfEnvelopeCondoWorksheet extends React.PureComponent<Props, {}> {
  /**
   * Render Condo Net Proceeds section.
   */
  renderCondoNetProceedsSection = (userIsAuthenticated) => (
    <tbody>
      <CellRow>
        <ConfigurationHandler config={config.condoGrossSales} component={CellsContainer} unitSystem={this.props.unitSystem} hiddenValues={!userIsAuthenticated} isFromBackOfEnvelope />
      </CellRow>
      <CellRow>
        <ConfigurationHandler config={config.condoTotalSalesCommissions} component={CellsContainer} styleVariation="border-below" unitSystem={this.props.unitSystem} hiddenValues={!userIsAuthenticated} isFromBackOfEnvelope />
      </CellRow>
      <CellRow>
        <ConfigurationHandler config={config.condoNetProceeds} component={CellsContainer} styleVariation="bold-17 border-above" unitSystem={this.props.unitSystem} hiddenValues={!userIsAuthenticated} isFromBackOfEnvelope />
      </CellRow>
      <CellRow
        classes="break"
      />
    </tbody>
  )

  /**
   * Render Condo Profit section.
   */
  renderCondoProfitSection = (userIsAuthenticated) => (
    <tbody>
      <CellRow>
        <ConfigurationHandler config={config.condoProfit} component={CellsContainer} styleVariation="bold-17 border-above" unitSystem={this.props.unitSystem} hiddenValues={!userIsAuthenticated} isFromBackOfEnvelope />
      </CellRow>
      <CellRow
        classes="break"
      />
    </tbody>
  )

  /**
   * Render All-In Cost PSF section.
   */
  renderAllInCostPSFSection = (userIsAuthenticated) => (
    <tbody data-tour="condo-cost">
      <CellRow>
        <ConfigurationHandler config={config.condoTotalDevelopmentCostIncludingParking} component={CellsContainer} unitSystem={this.props.unitSystem} hiddenValues={!userIsAuthenticated} isFromBackOfEnvelope />
      </CellRow>
      <CellRow>
        <ConfigurationHandler config={config.condoGrossBuildableAreaIncludingParking} component={CellsContainer} unitSystem={this.props.unitSystem} hiddenValues={!userIsAuthenticated} isFromBackOfEnvelope />
      </CellRow>
      <CellRow>
        <ConfigurationHandler config={config.condoDevelopmentCostPerAreaIncludingParking} component={CellsContainer} styleVariation="border-above bold-17" unitSystem={this.props.unitSystem} hiddenValues={!userIsAuthenticated} isFromBackOfEnvelope />
      </CellRow>
    </tbody>
  )

  render() {
    if (this.props.index !== this.props.currentTab) return null;
    const userIsAuthenticated = authentication.isUserAuthenticated();

    return (
      <table>
        {this.renderCondoNetProceedsSection(userIsAuthenticated)}
        {this.renderCondoProfitSection(userIsAuthenticated)}
        {this.renderAllInCostPSFSection(userIsAuthenticated)}
      </table>
    )
  }
};

export default connect(mapStateToProps)(BackOfEnvelopeCondoWorksheet);
