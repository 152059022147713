import numeral from "numeral";
import Format from "../types/Format";

/**
 * Handle formatting by sending value and options to the appropriate formatter
 * and add the suffix.
 */
const format = (value, options: Format.Options | undefined) => {
  if (typeof value === "undefined" || value === null) return "";
  if (!options) return value;

  if (options.hidden) return "*******";

  if (options.type === Format.Type.Currency) {
    return currencyFormatter(value, options);
  }

  let formattedValue;
  switch (options.type) {
    case Format.Type.PlainText:
      formattedValue = value;
      break;
    case Format.Type.Percentage:
      formattedValue = percentageFormatter(value, options);
      break;
    case Format.Type.Number:
      formattedValue = numberFormatter(value, options.decimalPlaces || 0, Boolean(options.abbreviate));
      break;
    case Format.Type.PhoneNumber:
      formattedValue = phoneNumberFormatter(value);
      break;
    default:
      // If the type cannot be matched, use the value without alteration.
      formattedValue = value;
      break;
  }

  if (options.type !== Format.Type.PlainText) {
    if (options.prefix) formattedValue = addPrefix(formattedValue, options.prefix);
    if (options.suffix) formattedValue = addSuffix(formattedValue, options.suffix);
  }

  return formattedValue;
}

/**
 * Format currency.
 */
const currencyFormatter = (value, options: Format.Options) => {
  if (!isNumeric(value)) return value;

  let currency = "$";
  if (options.units) {
    currency = options.units;
  }

  if (options.negate) {
    value = -value;
  }

  let numberFormat = generateNumberFormat(options.decimalPlaces || 0, options.abbreviate);
  // Absolute value is used to prevent `-` from being added to the formattedValue
  let formattedValue = `${numeral(Math.abs(value)).format(`${currency}${numberFormat}`)}`;
  if (options.suffix) formattedValue = addSuffix(formattedValue, options.suffix);

  // Negative values are handle here. Wrapping in `()` instead of using `-`.
  if (value < 0) {
    formattedValue = `(${formattedValue})`;
  }

  return formattedValue;
}

/**
 * Format phone numbers to +#? (###) ###-####.
 */
const phoneNumberFormatter = (value: string) => {
  if (value.length === 0) return value;

  // Remove all characters that are not digits
  let cleanedValue = value.replace(/\D/g, "");
  if (value[0] === "+") cleanedValue = `+${cleanedValue}`;

  // Capture groups and separate with a space. Ignore the rest of the string.
  return cleanedValue.replace(/(\+\d{1})?(\d{3})(\d{3})(\d{4})/, "$1 ($2) $3-$4").trim();
}

/**
 * Format percentages.
 */
const percentageFormatter = (value, options: Format.Options) => {
  if (!isNumeric(value)) return value;

  let numberFormat = generateNumberFormat(options.decimalPlaces || 0, options.abbreviate);
  return `${numeral(value).format(`${numberFormat}%`)}`;
}

/**
 * Format numbers.
 */
const numberFormatter = (value, decimalPlaces: number, abbreviate: boolean) => {
  if (typeof value === "undefined") return "";
  if (!isNumeric(value)) return value;

  let numberFormat = generateNumberFormat(decimalPlaces, abbreviate);
  return `${numeral(value).format(`${numberFormat}`)}`;
}

/**
 * Checks whether the argument value represents a number or not.
 */
const isNumeric = (value) => {
  return !isNaN(parseFloat(value)) && isFinite(value);
};

/**
 * Returns value with the suffix appended.
 */
const addSuffix = (value, suffix: string) => {
  return `${value}${suffix}`;
}

/**
 * Returns value with prefix.
 */
const addPrefix = (value, prefix: string) => {
  return `${prefix}${value}`;
}

/**
 * Given the format options, generates a format string to pass to Numeral.format().
 */
const generateNumberFormat = (decimalPlaces: number, abbreviate: boolean | undefined) => {
  let result = "0,0";
  if (decimalPlaces) {
    result += ".";
    for (let i = 0; i < decimalPlaces; i++) {
      result += "0";
    }
  }
  if (abbreviate) result += "a";
  return result;
};

export default {
  format,
  isNumeric,
};
