import vector2D from "../../../../utils/vector2D";
import turf from "../../../../utils/turf";
import geoTransformations from "../../../../utils/geoTransformations";
import developmentAccessors from "../developmentAccessors";
import geometry from "../../../../utils/geometry";
import log from "loglevel";
import { Values } from  "../../../../types/Development/Values";
import { Development } from  "../../../../types/Development/Development";
import { Footprint } from  "../../../../types/Development/Footprint";

const ORIGIN = vector2D.create(0,0);
const EMPTY_POLYGON = turf.polygon([]);
const CORNER_BUFFER = 0.0000254; // 1 inch in kilometers.
const OUT_SETBACK = 0.01; // 0.01 feet.

/**
 * Receive a project as argument, compute the setback footprints and add them to the given project.
 *
 * @param project - The project.
 */
const addFootprints = (development: Development) => {
  // Add the footprint for each setbackRule in the development.
  development.buildingModel.setbackSchedule.forEach(
    (setbackRule, scheduleIndex) => {
      setbackRule.footprint = getSetbackFootprint(
        setbackRule.setbacks,
        developmentAccessors.getValues(development),
        developmentAccessors.getParcel(development),
        scheduleIndex,
      );
    }
  );
};

/**
 * Return the setbackFootprint for a given setback rule.
 */
const getSetbackFootprint = (setbacks, values: Values, parcelFeature, scheduleIndex): Footprint => {
  let setbackPolygons = getSetbackPolygons(setbacks, values, parcelFeature, scheduleIndex);
  let footprintArea = turf.area(setbackPolygons);
  let areaFromPublishedData = footprintArea * values.publishedDataAreaUnitsPerGeometricAreaUnit;
  return {
    area: areaFromPublishedData,
    publishedDataAreaUnitsPerGeometricAreaUnit: values.publishedDataAreaUnitsPerGeometricAreaUnit,
    polygons: setbackPolygons,
  };
};

/**
 * Get the setback polygon or polygons if the parcel is represented by a MultiPolygon.
 */
const getSetbackPolygons = (setbacks, values: Values, parcelFeature, scheduleIndex) => {
  try {
    let parcelFeatureCopy = turf.clone(parcelFeature);
    let setbackPolygons: any[] = [];

    turf.flattenEach(
      parcelFeatureCopy,
      (currentFeature, featureIndex, polygonIndex) => {
        let ring = turf.getCoords(currentFeature)[0];
        let vector2DRing = ring.map((vertex) => vector2D.create(vertex[0], vertex[1]));
        let polygonWithSetback = getIndividualSetbackPolygon(currentFeature, vector2DRing, setbacks[polygonIndex], values, scheduleIndex);
        if (polygonWithSetback) {
          setbackPolygons.push(polygonWithSetback);
        }
      }
    );

    let parcelPolygonsWithSetback = geometry.featuresUnion(setbackPolygons);
    return parcelPolygonsWithSetback ? parcelPolygonsWithSetback : EMPTY_POLYGON;
  } catch (error) {
    log.warn(error);
    return EMPTY_POLYGON;
  }
}

/**
 * Calculates individual setback polygons by subtracting setback rectangles and "smoothing" polygons around the corners.
 * Details of this algorithm can be found in `/docs/SetbacksAlgorithm.md`.
 */
const getIndividualSetbackPolygon = (polygon, vector2DRing, setbacks, values: Values, scheduleIndex) => {
  let polygonSize = vector2DRing.length;
  // Subtract setback rectangles.
  for (let index = 0; index < polygonSize - 1; index++) {
    let vertex = vector2DRing[index];

    let nextIndex = index + 1;
    let nextVertex = vector2DRing[nextIndex];

    // Scale setback distance to be in proportion with the parcel published area.
    let setback = values[`setback${setbacks[index]}`][scheduleIndex] / values.publishedDataDistanceUnitsPerGeometricDistanceUnit;
    if (setback > 0) {
      let setbackRectangleGeoJson = getSetbackRectangleGeoJson(vertex, nextVertex, setback);
      if (polygon !== null && setbackRectangleGeoJson !== null) {
        try {
          polygon = turf.difference(polygon, setbackRectangleGeoJson);
        } catch (error) {
          polygon = geometry.cleanFeature(polygon);
          log.warn(error);
          if (polygon === null) return null;
        }
      }
    }
  }

  // Subtract corner polygons.
  for (let index = 0; index < polygonSize - 1; index++) {
    let vertex = vector2DRing[index];
    let nextIndex = index + 1;
    let nextVertex = vector2DRing[nextIndex];
    let previousIndex = (index === 0) ? polygonSize - 2 : index - 1;
    let previousVertex = vector2DRing[previousIndex];

    // Scale setbacks distance to be in proportion with the parcel published area.
    let previousSetback = values[`setback${setbacks[previousIndex]}`][scheduleIndex] / values.publishedDataDistanceUnitsPerGeometricDistanceUnit;
    let setback = values[`setback${setbacks[index]}`][scheduleIndex] / values.publishedDataDistanceUnitsPerGeometricDistanceUnit;
    let cornerPolygon = getCornerPolygonGeoJson(
      vertex,
      previousVertex,
      nextVertex,
      previousSetback,
      setback,
    );

    if (polygon !== null && cornerPolygon !== null) {
      try {
        polygon = turf.difference(polygon, turf.buffer(cornerPolygon, CORNER_BUFFER));
      } catch (error) {
        polygon = geometry.cleanFeature(polygon);
        log.warn(error);
        if (polygon === null) return null;
      }
    }
  }

  return geometry.cleanFeature(polygon);
}

/**
 * Construct a setback rectangle given a segment and a setback.
 */
const getSetbackRectangleGeoJson = (vertex, nextVertex, setback) => {
  if (setback > 0) {
    // Initialize local geometry.
    let [localSegment, originLatLon] = geoTransformations.initializeLocalGeometryFromLatLon([vertex, nextVertex]);

    // Get the Normal vectors.
    let clockwiseUnitNormal = vector2D.clockwiseUnitNormal(vector2D.subtract(localSegment[1], localSegment[0]));
    let clockwiseNormal = vector2D.scale(clockwiseUnitNormal, setback);

    // Add the new points.
    localSegment.push(vector2D.add(localSegment[1], clockwiseNormal));
    localSegment.push(vector2D.add(localSegment[0], clockwiseNormal));
    localSegment.push(localSegment[0]);

    // Translate from local geometry to lat/lon.
    let latLonRectangle = geoTransformations.pointsToLatLonFromLocalGeometry(localSegment, originLatLon);

    latLonRectangle[0] = vertex;
    latLonRectangle[1] = nextVertex;
    latLonRectangle[latLonRectangle.length - 1] = vertex;

    // Return the rectangle as GeoJson.
    return turf.polygon([latLonRectangle.map((point) => [point.x, point.y])]);
  } else {
    return null;
  }
}

/**
 * Construct the corner polygons depending on the quadrant that the angle between the sides lies.
 * Details of this algorithm can be found in `/docs/SetbacksAlgorithm.md`.
 */
const getCornerPolygonGeoJson = (
  vertex,
  previousVertex,
  nextVertex,
  previousSetback,
  nextSetback,
) => {
  if (previousSetback > 0 || nextSetback > 0) {
    // Initialize local geometry.
    let originLatLon = geoTransformations.initializeLocalGeometryFromLatLon([vertex])[1];
    let localPreviousVertex = geoTransformations.pointsToLocalGeometryFromLatLon([previousVertex], originLatLon)[0];
    let localNextVertex = geoTransformations.pointsToLocalGeometryFromLatLon([nextVertex], originLatLon)[0];

    let previousSetbackVector = vector2D.scale(vector2D.counterClockwiseUnitNormal(localPreviousVertex), previousSetback);
    let nextSetbackVector = vector2D.scale(vector2D.clockwiseUnitNormal(localNextVertex), nextSetback);

    // Calculate the sine and cosine of the angle between the two sides.
    let sine =
        vector2D.cross(localPreviousVertex, localNextVertex)
      / (vector2D.magnitude(localPreviousVertex) * vector2D.magnitude(localNextVertex));
    let cosine =
        vector2D.dot(localPreviousVertex, localNextVertex)
      / (vector2D.magnitude(localPreviousVertex) * vector2D.magnitude(localNextVertex));

    let polygonLatLon;

    // Second quadrant corner.
    if (sine > 0 && cosine < 0) {
      if (previousSetback === 0) {
        previousSetbackVector = vector2D.scale(vector2D.clockwiseUnitNormal(localPreviousVertex), OUT_SETBACK);
      }
      if (nextSetback === 0) {
        nextSetbackVector = vector2D.scale(vector2D.counterClockwiseUnitNormal(localNextVertex), OUT_SETBACK);
      }

      polygonLatLon = getQuadrantTwoCornerPolygonVertices(
        originLatLon,
        localPreviousVertex,
        localNextVertex,
        previousSetback,
        previousSetbackVector,
        nextSetback,
        nextSetbackVector
      );
    // Third quadrant corner.
    } else if (sine < 0 && cosine < 0 && previousSetback > 0 && nextSetback > 0) {
      polygonLatLon = getQuadrantThreeCornerPolygonVertices(
        originLatLon,
        localPreviousVertex,
        localNextVertex,
        previousSetback,
        previousSetbackVector,
        nextSetback,
        nextSetbackVector,
        cosine
      );
    // Forth quadrant corner.
    } else if (sine < 0 && cosine > 0 && previousSetback > 0 && nextSetback > 0) {
      polygonLatLon = getQuadrantFourCornerPolygonVertices(
        originLatLon,
        localPreviousVertex,
        localNextVertex,
        previousSetback,
        previousSetbackVector,
        nextSetback,
        nextSetbackVector,
        cosine
      );
    } else {
      return null;
    }

    if (polygonLatLon !== null) {
      polygonLatLon.unshift(vertex);
      polygonLatLon.push(vertex);
      return turf.polygon([polygonLatLon.map((point) => [point.x, point.y])]);
    } else {
      return null;
    }
  } else {
    return null;
  }
}

/**
 * Construct the corner polygon for angles in the second quadrant.
 * Details of this algorithm can be found in `/docs/SetbacksAlgorithm.md`.
 */
const getQuadrantTwoCornerPolygonVertices = (
  originLatLon,
  previousPoint,
  nextPoint,
  previousSetback,
  previousSetbackVector,
  nextSetback,
  nextSetbackVector
) => {
  let normalsIntersection = vector2D.normalsIntersection(previousSetbackVector, nextSetbackVector);
  if (vector2D.equal(normalsIntersection, ORIGIN)) { // Consecutive sides are collinear.
    return null;
  } else {
    let vectorList: any[] = [];
    if (previousSetback > nextSetback) {
      let scalingFactor = vector2D.magnitude(vector2D.subtract(normalsIntersection, nextSetbackVector));
      if (vector2D.magnitude(nextPoint) < scalingFactor) {
        return null;
      } else {
        vectorList.push(middlePointOfSetback(previousSetbackVector, previousPoint));
        vectorList.push(normalsIntersection);
      }
    } else {
      let scalingFactor = vector2D.magnitude(vector2D.subtract(previousSetbackVector, normalsIntersection));
      if (vector2D.magnitude(previousPoint) < scalingFactor) {
        return null;
      } else {
        vectorList.push(normalsIntersection);
        vectorList.push(middlePointOfSetback(nextSetbackVector, nextPoint));
      }
    }
    return geoTransformations.pointsToLatLonFromLocalGeometry(vectorList, originLatLon);
  }
}

/**
 * Construct the corner polygon for angles in the third quadrant.
 * Details of this algorithm can be found in `/docs/SetbacksAlgorithm.md`.
 */
const getQuadrantThreeCornerPolygonVertices = (
  originLatLon,
  previousPoint,
  nextPoint,
  previousSetback,
  previousSetbackVector,
  nextSetback,
  nextSetbackVector,
  cosine
) => {
  let intersectionFactor = (-1 * Math.min(previousSetback, nextSetback) / cosine);
  let extendSmallerSetback =
    Math.max(previousSetback, nextSetback) > intersectionFactor;

  let vectorList = [middlePointOfSetback(previousSetbackVector, previousPoint)];
  if (extendSmallerSetback) {
    if (previousSetback > nextSetback) {
      let setbackExtensionIntersection =
        vector2D.scale(
          vector2D.unitVector(previousSetbackVector),
          intersectionFactor
        );
      vectorList.push(setbackExtensionIntersection);
    } else {
      let setbackExtensionIntersection =
        vector2D.scale(
          vector2D.unitVector(nextSetbackVector),
          intersectionFactor
        );
      vectorList.push(setbackExtensionIntersection);
    }
  } else {
    let normalsIntersection = vector2D.normalsIntersection(previousSetbackVector, nextSetbackVector);
    if (vector2D.equal(normalsIntersection, ORIGIN)) { // Consecutive sides are collinear.
      return null;
    } else {
      vectorList.push(normalsIntersection);
    }
  }
  vectorList.push(middlePointOfSetback(nextSetbackVector, nextPoint));

  return geoTransformations.pointsToLatLonFromLocalGeometry(vectorList, originLatLon);
}

/**
 * Construct the corner polygon for angles in the fourth quadrant.
 * Details of this algorithm can be found in `/docs/SetbacksAlgorithm.md`.
 */
const getQuadrantFourCornerPolygonVertices = (
  originLatLon,
  previousPoint,
  nextPoint,
  previousSetback,
  previousSetbackVector,
  nextSetback,
  nextSetbackVector,
  cosine
) => {
  let shortestSetback = Math.min(previousSetback, nextSetback);
  let largestSetback = Math.max(previousSetback, nextSetback);
  let scalingFactor = Math.max(shortestSetback, largestSetback * Math.sqrt((1 + cosine) / 2));

  let bisectorVector =
    vector2D.scale(
      vector2D.bisectorUnitVector(previousPoint, nextPoint),
      -1 * scalingFactor
    );

  let vectorList = [middlePointOfSetback(previousSetbackVector, previousPoint)];
  let previousNormalsIntersection = vector2D.normalsIntersection(previousSetbackVector, bisectorVector);
  let nextNormalsIntersection = vector2D.normalsIntersection(bisectorVector, nextSetbackVector);
  if (vector2D.equal(previousNormalsIntersection, ORIGIN) || vector2D.equal(nextNormalsIntersection, ORIGIN)) { // Consecutive sides are collinear.
    return null;
  } else {
    vectorList.push(previousNormalsIntersection);
    vectorList.push(nextNormalsIntersection);
    vectorList.push(middlePointOfSetback(nextSetbackVector, nextPoint));

    return geoTransformations.pointsToLatLonFromLocalGeometry(vectorList, originLatLon);
  }
}

/**
 * Get the middle point of the setback segment. This is needed to avoid precision issues with turfJS library.
 */
const middlePointOfSetback = (setbackVector, sideVector) => {
  let halfSideVector = vector2D.scale(sideVector, 0.5);
  return vector2D.add(setbackVector, halfSideVector);
}

export default {
  addFootprints
};
