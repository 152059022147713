import React from "react";
import { connect } from "react-redux";
import { developmentSelectors } from "../../../../../state/development";
import ConfigurationHandler from "../../../../sharedComponents/ConfigurationHandler";
import Kpi from "./Kpi";

const mapStateToProps = (state) => {
  return {
    unitSystem: developmentSelectors.getUnitSystem(state),
  }
}

interface OwnProps {
  selectedKpis,
  kpiList,
  toggles,
  toggle,
  onSelectKpi
}

type StateProps = ReturnType<typeof mapStateToProps>;
type Props = OwnProps & StateProps;

class KpiList extends React.Component<Props, {}> {
  render() {
    const {
      selectedKpis,
      kpiList,
      toggles,
      toggle,
      onSelectKpi,
      unitSystem,
    } = this.props;

    return (
      <div className="kpis-container">
        <div className="responsive-helper" />
        {selectedKpis.map((config, index) => (
          <ConfigurationHandler
            component={Kpi}
            config={config}
            key={`kpi_${index}`}
            kpiList={kpiList}
            kpiIndex={index}
            isActive={toggles[index + 1]}
            setActive={toggle(index + 1)}
            onSelectKpi={onSelectKpi}
            unitSystem={unitSystem}
          />
        ))}
      </div>
    );
  }
}

export default connect(mapStateToProps)(KpiList);
