import actionTypes from "./actionTypes";
import { Listing } from "../../types/Service/Listings/Listings";

/**
 * Get list of listings.
 */
const getListingsStart = (boundingBox: [number, number, number, number]) => {
  return {
    type: actionTypes.GET_LISTINGS_START,
    payload: { boundingBox },
  };
}

/**
 * Update the listings concern in the state.
 */
const getListingsSuccess = (listings: Array<Listing>) => {
  return {
    type: actionTypes.GET_LISTINGS_SUCCESS,
    payload: { listings }
  }
}

/**
 * Set selected listing in the state.
 */
const setSelectedListing = (listings: Array<Listing> | null) => {
  return {
    type: actionTypes.SET_SELECTED_LISTING,
    payload: { listings }
  }
}

/**
 * Clear listings array in the state.
 */
const clearListings = () => {
  return {
    type: actionTypes.CLEAR_LISTINGS,
  }
}

export default {
  getListingsStart,
  getListingsSuccess,
  setSelectedListing,
  clearListings,
}
