import authentication from "./authentication";
import database from "./database/database";
import { UserDocument } from "../types/UserDocument";

const DEFAULT_USER_DOCUMENT: UserDocument = {
  firstName: null,
  middleName: null,
  lastName: null,
  companyName: null,
  companySize: null,
  isOnboarded: false,
  chargebeeCustomerId: null,
  projects: {},
}

/**
 * Perform all of the common actions that must be completed after a new user
 * account is created. This includes creating any required records for this user
 * in third-party services, dispatching verification emails, etc.
 */
const completeSignUp = async (userData: Partial<UserDocument>) => {
  let userDocument = { ...DEFAULT_USER_DOCUMENT };

  userDocument.firstName = userData.firstName || DEFAULT_USER_DOCUMENT.firstName;
  userDocument.middleName = userData.middleName || DEFAULT_USER_DOCUMENT.middleName;
  userDocument.lastName = userData.lastName || DEFAULT_USER_DOCUMENT.lastName;
  userDocument.companyName = userData.companyName || DEFAULT_USER_DOCUMENT.companyName;
  userDocument.companySize = userData.companySize || DEFAULT_USER_DOCUMENT.companySize;

  // Create user in Firebase database.
  await database.createUser(userDocument);

  const userEmailIsVerified = await authentication.userEmailIsVerified();
  if (!userEmailIsVerified) authentication.sendEmailVerification();
}

export default completeSignUp;
