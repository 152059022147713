import React from "react";
import BaseRow from "../../../sharedComponents/BaseRow";

const BORDER_WIDTH = 0.96;
const FONT_SIZE = 10.8;

const BoldBorderRow = (props) => {
  props = {
    ...props,
    borderTop: BORDER_WIDTH,
    borderColor: "#333333",
    borderStyle: "solid",
    labelFontFamily: "Roboto Condensed Bold",
    valueFontFamily: "Roboto Condensed Bold",
    fontSize: FONT_SIZE
  }

  return <BaseRow {...props} />;
}

export default BoldBorderRow;
