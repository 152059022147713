import { UserStatus } from "../types/UserStatus";
import subscriptionHelper from "./chargebee/subscriptionStatus";
import { Chargebee } from "../types/Service/Chargebee";

/**
 * Get user status. Status can be:
 *
 * - "NOT_AUTHENTICATED" -> User is logged out.
 * - "AUTHENTICATED" -> User is logged in but hasn't verified its email.
 * - "VERIFIED" -> User is logged in with email verified but doesn't have a valid subscription.
 * - "SUBSCRIBED" -> User is logged in with email verified and has a valid paid subscription.
 *
 */
const getUserStatus = (userIsAuthenticated: boolean | null,  userIsVerified: boolean, subscriptionStatus: Chargebee.SubscriptionStatus | undefined) => {
  if (!userIsAuthenticated) return UserStatus.NotAuthenticated;
  if (!userIsVerified) return UserStatus.Authenticated;
  if (!subscriptionHelper.isEnabled(subscriptionStatus)) return UserStatus.Verified;
  return UserStatus.Subscribed;
}

/**
 * Check if we have the user's authentication.
 */
const userStatusIsReady = (userIsAuthenticated: boolean | null, userIsVerified: boolean, subscriptionStatus: Chargebee.SubscriptionStatus | undefined) => {
  if (userIsAuthenticated === null) return false;
  if (!userIsVerified) return true;
  if (!subscriptionStatus) return false;
  return true;
}

export default {
  getUserStatus,
  userStatusIsReady,
}
