import { Filters, FilterId } from "../types/Filter"
import { ParcelProperty } from "./parcel/ParcelProperty"

const INITIAL_FILTERS: Filters = {
  [FilterId.Vacant]: {
    id: FilterId.Vacant,
    variableId: ParcelProperty.ExistingStructureArea,
    shouldDisplay: true,
    isActive: true,
    allowUnauthenticated: true,
    value: [0],
  },
  [FilterId.AreaComputed]: {
    id: FilterId.AreaComputed,
    variableId: ParcelProperty.AreaComputed,
    shouldDisplay: true,
    isActive: false,
    allowUnauthenticated: true,
    value: [0, 929030],
  },
  [FilterId.ExistingStructureArea]: {
    id: FilterId.ExistingStructureArea,
    variableId: ParcelProperty.ExistingStructureArea,
    shouldDisplay: true,
    isActive: false,
    value: [46.4515, 929030],
  },
  [FilterId.FloorAreaRatio]: {
    id: FilterId.FloorAreaRatio,
    variableId: ParcelProperty.FloorAreaRatio,
    shouldDisplay: true,
    isActive: false,
    value: [0, 100],
  },
  [FilterId.ExistingYearBuilt]: {
    id: FilterId.ExistingYearBuilt,
    variableId: ParcelProperty.ExistingStructureYearBuilt,
    shouldDisplay: true,
    isActive: false,
    value: [1900, 2020],
  },
  [FilterId.MedianIncomeTotal]: {
    id: FilterId.MedianIncomeTotal,
    variableId: ParcelProperty.MedianIncomeTotal,
    shouldDisplay: true,
    isActive: false,
    value: [0, 10000000],
  },
  [FilterId.GrossMedianRent]: {
    id: FilterId.GrossMedianRent,
    variableId: ParcelProperty.GrossMedianRent,
    shouldDisplay: true,
    isActive: false,
    value: [0, 10000],
  },
  [FilterId.NumberOfUnitsAllowed]: {
    id: FilterId.NumberOfUnitsAllowed,
    variableId: ParcelProperty.NumberOfUnitsAllowed,
    shouldDisplay: true,
    isActive: false,
    value: [0, 5000],
  },
}

/**
 * Returns initial filter values;
 */
const initializeFilters = () => {
  return INITIAL_FILTERS;
}

/**
 * Get the smart search result features by applying the filters to the parcels in the viewport.
 */
const applyFilters = (parcelsInViewport, filters: Filters) => {
  let smartSearchResult = parcelsInViewport;
  // Return empty list if no filter is active.
  if (!Object.values(filters).some((filter) => filter.isActive && filter.shouldDisplay)) return [];

  Object.values(filters).forEach((filter) => {
    if (filter.isActive && filter.shouldDisplay) {
      if (filter.value.length === 1) {
        smartSearchResult = smartSearchResult.filter(
          (parcel) => {
            return parcel.properties[filter.variableId] !== null
              && Number(parcel.properties[filter.variableId]) === filter.value[0];
          }
        );
      } else {
        smartSearchResult = smartSearchResult.filter(
          (parcel) => {
            return parcel.properties[filter.variableId] !== null
              && (Number(parcel.properties[filter.variableId]) >= filter.value[0])
              && (Number(parcel.properties[filter.variableId]) <= filter.value[1]);
          }
        );
      }
    }
  });

  return smartSearchResult;
}

/**
 * Update filters shouldDisplay based on the current parcels available in the viewport.
 * If no parcel has the data to be filtered on, the filter is not displayed.
 */
const updateFiltersToDisplay = (parcelsInViewport, filters: Filters): Filters => {
  let finalFilters = { ...filters };
  let filterIdsToHide: any[] = Object.values(FilterId);

  for (let parcel of parcelsInViewport) {
    if (filterIdsToHide.length === 0) break;
    filterIdsToHide = filterIdsToHide.filter((filterIdToHide) => {
      // If there are no buildings with existing structure area of 0, it means there are no
      // vacant parcels, so we remove that filter.
      const hideVacant = filterIdToHide === FilterId.Vacant
          ? Number(parcel.properties[finalFilters[filterIdToHide].variableId]) !== 0
          : false;

      return parcel.properties[finalFilters[filterIdToHide].variableId] === null
          || parcel.properties[finalFilters[filterIdToHide].variableId] === undefined
          || hideVacant;
    });
  }

  Object.keys(finalFilters).forEach((filterId) => {
    finalFilters[filterId].shouldDisplay = !filterIdsToHide.includes(filterId);
  });

  return finalFilters;
}

export default {
  initializeFilters,
  updateFiltersToDisplay,
  applyFilters
}
