import { Floor } from  "../../../../types/Development/Floor";

/**
 * @fileoverview This module is responsible for calculations related to an
 *  individual floor of a building.
 */

/**
 * The total buildable area of the given floor. If the entire floor area has
 * been allocated for usage, the result will be the total area of the floor's
 * footprint.
 *
 * @param {Object} floor - The floor for which to calculate the buildable area.
 *
 * @returns {number} - The total buildable area of the floor.
 */
const buildableArea = (floor) => {
  return floor.usageAreas.reduce((total, usageArea) => {
    return total + usageArea.area;
  }, 0);
};

/**
 * Create a new floor.
 *
 * @param {Object} footprint - The footprint to assign to the new floor.
 * @param {number} baseElevation - The base elevation of the new floor.
 *
 * @returns {Object} - The new floor.
 */
const create = (footprint, baseElevation, height): Floor => {
  return {
    baseElevation: baseElevation,
    footprint: footprint,
    usageAreas: [],
    height: height,
  };
};

export default {
  buildableArea,
  create,
}
