import buildables from "./simulator/buildables";
import floorAllocation from "./simulator/floorAllocation";
import measurements from "./simulator/measurements";
import financialDevelopmentCosts from "./simulator/financialDevelopmentCosts";
import financialCashFlows from "./simulator/financialCashFlows";
import financialReturns from "./simulator/financialReturns";
import site from "./simulator/site";
import setbacks from "./simulator/setbacks";
import log from "loglevel";
import universalConstraints from "./universalConstraints";
import { Development } from "../../../types/Development/Development";
import { VariableId } from "../../../types/VariableId";
import { SetbackType } from "../../../types/Setback";
import Unit from "../../../types/Unit";
import { jsonDeepCopy } from "../../../utils/deepCopy";

/**
 * @fileoverview This module provides functions for working with development
 *  objects. The functions provided here are NOT required to be pure functions.
 *  They may make modifications to the development object they receive.
 *
 * NOTE: As a rule of thumb, every function exported from this file should take
 *  a development object as its first argument.
 */

/**
 * Modify the development object by fully updating it. This function runs the given
 * development through the entire update sequence, ensuring that it is valid and
 * consistent with its inputs.
 */
const update = (development: Development) => {
  site.update(development);
  setbacks.addFootprints(development);
  buildables.update(development.values);
  floorAllocation.update(development.buildingModel, development.values);
  measurements.update(development);
  financialDevelopmentCosts.update(development.values);
  financialCashFlows.update(development.values);
  financialReturns.update(development.values);
};

/**
 * Modify the development object by initializing the given development object.
 * This function ensures that the given development has a value for all of the inputs required
 * for composition.
 */
const ensureRequiredInputs = (development: Development) => {
  buildables.ensureRequiredInputs(development.values);
  financialDevelopmentCosts.ensureRequiredInputs(development.values);
  financialCashFlows.ensureRequiredInputs(development.values);
  financialReturns.ensureRequiredInputs(development.values);
};

/**
 * The values sub-object of the given development object.
 */
const getValues = (development: Development) => {
  return development.values;
};

/**
 * Get the value of the identified input on the given development object.
 */
const getInputValue = (development: Development, inputId: VariableId, index?) => {
  const values = getValues(development);
  return (index !== undefined) ? values[inputId][index] : values[inputId];
};

/**
 * Modify the development object by directly setting the value of the
 * identified input. This function is a direct accessor and makes no other
 * modifications to the given arguments.
 */
const setInputValue = (development: Development, inputId: VariableId, value, index?) => {
  if (index !== undefined) {
    development.values[inputId as any][index] = value;
  } else {
    development.values[inputId as any] = value;
  }
};

/**
 * Modify the development object by setting the name of the given development.
 * If the value passed is null or undefined, assign the empty string instead.
 */
const setName = (development: Development, value) => {
  development.name = value || "";
};

/**
 * Get the development name.
 */
const getName = (development: Development) => {
  return development.name;
}

/**
 * Modify the development object by setting the value of the identified input on the
 * given development object. This function differs from `setInputValue()` in that it
 * enforces minimum and maximum bounds. If the given value is outside of the current bounds
 * for the identified input, the assigned value will be either the minimum or maximum, as
 * appropriate.
 */
const setInputValueConstrained = (development: Development, inputId: VariableId, value, index?) => {
  let minimum = getInputMinimum(development, inputId, index);
  let maximum = getInputMaximum(development, inputId, index);

  if (value < minimum) {
    log.warn(`The input "${inputId}" was assigned the value ${value}, which is less than the minimum ${minimum}. The minimum value was applied instead.`);
    setInputValue(development, inputId, minimum, index);
  }
  else if (value > maximum) {
    log.warn(`The input "${inputId}" was assigned the value ${value}, which is greater than the maximum ${maximum}. The maximum value was applied instead.`);
    setInputValue(development, inputId, maximum, index);
  }
  else { setInputValue(development, inputId, value, index); }
};

/**
 * The minimum value of the identified input on the given development object.
 */
const getInputMinimum = (development: Development, inputId: VariableId, index?): number => {
  return (index !== undefined)
      ? development.constraints.minimums[inputId][index]
      : development.constraints.minimums[inputId];
};

/**
 * Modify the development object by directly setting the minimum value of the identified
 * input. This function is a direct accessor and makes no other modifications to the given arguments.
 */
const setInputMinimum = (development: Development, inputId: VariableId, minimum, index?) => {
  if (index !== undefined) {
    development.constraints.minimums[inputId][index] = minimum;
  } else {
    development.constraints.minimums[inputId] = minimum;
  }
};

/**
 * Modify the development object by setting the minimum value of the identified
 * input. This function differs from `setInputMinimum()` in that it enforces the
 * logical constraints between the minimum, the maximum, and the value. In
 * particular:
 *
 * - If the given minimum is outside the universal minimum and maximum for the
 *  given input, the appropriate universal bound is used instead of the given
 *  minimum.
 * - If the resulting minimum value is greater than the current maximum value,
 *  the maximum is increased to equal the minimum.
 * - If the value of the input is outside of the resulting bounds, it is
 *  coerced into the bounds.
 */
const setInputMinimumConstrained = (development: Development, inputId: VariableId, minimum, index?) => {
  minimum = Math.max(universalConstraints.minimum[inputId], minimum);
  minimum = Math.min(universalConstraints.maximum[inputId], minimum);
  setInputMinimum(development, inputId, minimum, index);

  let maximum = getInputMaximum(development, inputId, index);
  if (maximum < minimum) {
    setInputMaximum(development, inputId, minimum, index);
    log.warn(`The input "${inputId}" was assigned the minimum value ${minimum}, which is greater than the maximum ${maximum}. The maximum was moved to equal the minimum.`);
  }

  let value = getInputValue(development, inputId, index);
  if (value < minimum) {
    setInputValue(development, inputId, minimum, index);
    log.warn(`The input "${inputId}" was assigned the minimum value ${minimum}, which is greater than the current value ${value}. The value was moved to equal the minimum.`);
  }
};

/**
 * The maximum value of the identified input on the given development object.
 */
const getInputMaximum = (development: Development, inputId: VariableId, index?) => {
  return (index !== undefined)
      ? development.constraints.maximums[inputId][index]
      : development.constraints.maximums[inputId];
};

/**
 * Modify the development object by directly setting the maximum value of the identified input.
 * This function is a direct accessor and makes no other modifications to the given arguments.
 */
const setInputMaximum = (development: Development, inputId: VariableId, value, index?) => {
  if (index !== undefined) {
    development.constraints.maximums[inputId][index] = value;
  } else {
    development.constraints.maximums[inputId] = value;
  }
};

/**
 * Modify the development object by setting the maximum value of the identified
 * input. This function differs from `setInputMaximum()` in that it enforces the
 * logical constraints between the maximum, the minimum, and the value. In
 * particular:
 *
 * - If the given maximum is outside the universal minimum and maximum for the
 *  given input, the appropriate universal bound is used instead of the given
 *  maximum.
 * - If the resulting maximum value is lower than the current minimum value,
 *  the minimum is decreased to equal the maximum.
 * - If the value of the input is outside of the resulting bounds, it is
 *  coerced into the bounds.
 */
const setInputMaximumConstrained = (development: Development, inputId: VariableId, maximum, index?) => {
  maximum = Math.min(universalConstraints.maximum[inputId], maximum);
  maximum = Math.max(universalConstraints.minimum[inputId], maximum);
  setInputMaximum(development, inputId, maximum, index);

  let minimum = getInputMinimum(development, inputId, index);
  if (minimum > maximum) {
    setInputMinimum(development, inputId, maximum, index);
    log.warn(`The input "${inputId}" was assigned the maximum value ${maximum}, which is less than the minimum ${minimum}. The minimum was moved to equal the maximum.`);
  }

  let value = getInputValue(development, inputId);
  if (value > maximum) {
    setInputValue(development, inputId, maximum, index);
    log.warn(`The input "${inputId}" was assigned the maximum value ${maximum}, which is less than the current value ${value}. The value was moved to equal the maximum.`);
  }
};

/**
 * Modify the development object by setting the camera object on the given development.
 */
const setCamera = (development: Development, camera) => {
  development.camera = camera;
};

/**
 * The increment for the identified input on the given development object.
 */
const getInputIncrement = (development: Development, inputId: VariableId) => {
  return development.constraints.increments[inputId];
};

/**
 * Modify the development object by setting the increment for the identified input on the given development.
 */
const setInputIncrement = (development: Development, inputId: VariableId, increment) => {
  development.constraints.increments[inputId] = increment;
};

/**
 * Get the setback type from the development, given the indexes of the polygon and setback.
 */
const getSetbackType = (development: Development, floorIndex, polygonIndex, setbackIndex) => {
  return development.buildingModel.setbackSchedule[floorIndex].setbacks[polygonIndex][setbackIndex];
};

/**
 * Modify the development object by setting the setback type of the the setback at a given index.
 */
const setSetbackType = (development: Development, floorIndex, polygonIndex, setbackIndex, setbackType: SetbackType) => {
  development.buildingModel.setbackSchedule[floorIndex].setbacks[polygonIndex][setbackIndex] = setbackType;
}

/**
 * Get the setbacks object for the given development.
 */
const getSetbacks = (development: Development, index: number) => {
  return development.buildingModel.setbackSchedule[index].setbacks;
}

/*
 * Get the array of floors for the given development.
 */
const getFloors = (development: Development) => {
  return development.buildingModel.floors;
};

/**
 * Get the parcel object for the given development.
 */
const getParcel = (development: Development) => {
  return development.parcel;
};

/**
 * Get the building usage toggle values for the given development.
 */
const getBuildingUsageToggles = (development: Development) => {
  let result = {};
  ["multifamily", "condo", "hotel", "office", "industrial", "retail"]
    .forEach((toggleId) => {
      result[toggleId] = development.values[`${toggleId}Toggle`] || false;
    });
  return result;
};

/**
 * Modify the development object by updating the unit system.
 */
const setUnitSystem = (development: Development, unitSystem: Unit.System) => {
  development.unitSystem = unitSystem;
}

/**
 * Get the unit system for the given development.
 */
const getUnitSystem = (development: Development) => {
  return development.unitSystem;
}

/**
 * Modify the development object by adding a new setback at the given floor.
 */
const addSetbackFloor = (development: Development, floorIndex: number) => {
  if (floorIndex === 0) {
    updateSelectedSetbackFloor(development, floorIndex);
    return;
  }

  let setbackSchedule = development.buildingModel.setbackSchedule;
  let index = 0;

  while (index < setbackSchedule.length && setbackSchedule[index].highestFloor && Number(setbackSchedule[index].highestFloor) <= floorIndex) {
    if (Number(setbackSchedule[index].highestFloor) === floorIndex) {
      updateSelectedSetbackFloor(development, floorIndex);
      return;
    }
    index++;
  }

  setbackSchedule.splice(index, 0, jsonDeepCopy(setbackSchedule[index]));
  setbackSchedule[index].highestFloor = floorIndex;

  development.values.setbackA.splice(index, 0, development.values.setbackA[index]);
  development.values.setbackB.splice(index, 0, development.values.setbackB[index]);
  development.values.setbackC.splice(index, 0, development.values.setbackC[index]);
  development.values.setbackD.splice(index, 0, development.values.setbackD[index]);
  development.constraints.maximums["setbackA"].splice(index, 0, development.constraints.maximums["setbackA"][index]);
  development.constraints.maximums["setbackB"].splice(index, 0, development.constraints.maximums["setbackB"][index]);
  development.constraints.maximums["setbackC"].splice(index, 0, development.constraints.maximums["setbackC"][index]);
  development.constraints.maximums["setbackD"].splice(index, 0, development.constraints.maximums["setbackD"][index]);
  development.constraints.minimums["setbackA"].splice(index, 0, development.constraints.minimums["setbackA"][index]);
  development.constraints.minimums["setbackB"].splice(index, 0, development.constraints.minimums["setbackB"][index]);
  development.constraints.minimums["setbackC"].splice(index, 0, development.constraints.minimums["setbackC"][index]);
  development.constraints.minimums["setbackD"].splice(index, 0, development.constraints.minimums["setbackD"][index]);

  updateFloorsWithSetback(development);
  updateSelectedSetbackFloor(development, floorIndex);
}

/**
 * Update the `floorsWithSetbacks` array.
 */
const updateFloorsWithSetback = (development) => {
  const setbackSchedule = development.buildingModel.setbackSchedule;
  let floorsWithSetbacks = [0];
  for (let index = 0; index < setbackSchedule.length - 1; index++) {
    if (setbackSchedule[index].highestFloor) floorsWithSetbacks.push(setbackSchedule[index].highestFloor);
  }

  development.floorsWithSetbacks = floorsWithSetbacks;
}

/**
 * Update the `selectedSetbackFloor` and `selectedSetbackFloorIndex` values.
 */
const updateSelectedSetbackFloor = (development, floor) => {
  development.selectedSetbackFloor = floor;
  development.selectedSetbackFloorIndex = development.floorsWithSetbacks
      ? development.floorsWithSetbacks.indexOf(floor)
      : 0;
}

/**
 * Modify the development object by removing the setback at the given floor.
 */
const removeSetbackFloor = (development: Development, floorIndex: number) => {
  if (floorIndex === 0) return;

  let setbackSchedule = development.buildingModel.setbackSchedule;
  let index = 0;
  let floorFound = false;
  while (index < setbackSchedule.length && !floorFound) {
    if (Number(setbackSchedule[index].highestFloor) === floorIndex) floorFound = true;
    index++;
  }

  if (!floorFound) return;

  const highestFloor = setbackSchedule[index].highestFloor
  setbackSchedule.splice(index, 1);
  setbackSchedule[index - 1].highestFloor = highestFloor;

  development.values.setbackA.splice(index, 1);
  development.values.setbackB.splice(index, 1);
  development.values.setbackC.splice(index, 1);
  development.values.setbackD.splice(index, 1);
  development.constraints.maximums["setbackA"].splice(index, 1);
  development.constraints.maximums["setbackB"].splice(index, 1);
  development.constraints.maximums["setbackC"].splice(index, 1);
  development.constraints.maximums["setbackD"].splice(index, 1);
  development.constraints.minimums["setbackA"].splice(index, 1);
  development.constraints.minimums["setbackB"].splice(index, 1);
  development.constraints.minimums["setbackC"].splice(index, 1);
  development.constraints.minimums["setbackD"].splice(index, 1);

  updateFloorsWithSetback(development);

  let selectedFloor = development.floorsWithSetbacks[index - 1];
  updateSelectedSetbackFloor(development, selectedFloor);
}

export default {
  update,
  ensureRequiredInputs,
  getValues,
  getInputValue,
  setInputValue,
  setInputValueConstrained,
  getInputMinimum,
  setInputMinimum,
  setInputMinimumConstrained,
  getInputMaximum,
  setInputMaximum,
  setInputMaximumConstrained,
  setCamera,
  getInputIncrement,
  setInputIncrement,
  setName,
  getName,
  setSetbackType,
  getFloors,
  getParcel,
  getSetbackType,
  getSetbacks,
  getBuildingUsageToggles,
  setUnitSystem,
  getUnitSystem,
  addSetbackFloor,
  removeSetbackFloor,
  updateFloorsWithSetback,
};
