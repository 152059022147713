
import React from "react";
import { connect } from "react-redux";
import { thumbnailsActions } from "../../../../../../../state/thumbnails";
import { thumbnailSelectors } from "../../../../../../../state/thumbnails/thumbnail";
import BusySpinner from "../../../../../../sharedComponents/BusySpinner";

const mapStateToProps = (state, ownProps) => {
  return {
    imageUrl: thumbnailSelectors.getImageUrl(state, ownProps.developmentId),
    isReady: thumbnailSelectors.getIsReady(state, ownProps.developmentId),
    exists: thumbnailSelectors.getExists(state, ownProps.developmentId),
  }
}

const mapDispatchToProps = {
  loadThumbnail: thumbnailsActions.loadStart,
  deleteThumbnail: thumbnailsActions.deleteThumbnail
}

type OwnProps = {
  developmentId: string;
  onClick(): void;
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;
type Props = StateProps & DispatchProps & OwnProps;

class Thumbnail extends React.PureComponent<Props, {}> {
  /**
   * Load development thumbnail.
   */
  componentDidMount() {
    this.props.loadThumbnail(this.props.developmentId);
  }

  /**
   * Delete the development thumbnail from the store.
   */
  componentWillUnmount() {
    this.props.deleteThumbnail(this.props.developmentId);
  }

  render() {
    let {isReady, exists, imageUrl} = this.props;

    return (
      <div
        className="component--thumbnail"
        style={(isReady && exists && { backgroundImage: `url(${imageUrl})` }) || {}}
        onClick={this.props.onClick}
      >
        {!isReady && <BusySpinner />}
        {isReady && !exists && `NO IMAGE AVAILABLE`}
      </div>
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Thumbnail)
