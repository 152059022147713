import React from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router";
import { thumbnailSelectors } from "../../../../../../state/thumbnails/thumbnail";
import analytics from "../../../../../../utils/analytics";
import developmentUiHelper from "../../../../../utils/developmentUiHelper";
import { Path } from "../../../../../../types/Path";
import DeleteDevelopment from "../../../../../sharedComponents/DeleteDevelopment";
import Thumbnail from "./Thumbnail";
import ReturnOnCost from "../../../../../sharedComponents/ReturnOnCost";
import ShareProject from "../../../../../sharedComponents/ShareProject";

const mapStateToProps = (state, ownProps) => {
  return {
    thumbnailUrl: thumbnailSelectors.getImageUrl(state, ownProps.development.id),
  }
}

interface OwnProps {
  development: any;
  onDeleteDevelopment();
}

type StateProps = ReturnType<typeof mapStateToProps>;
type Props = OwnProps & StateProps;

interface State {
  redirectToExplorer: boolean;
}

class DevelopmentCard extends React.PureComponent<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      redirectToExplorer: false,
    }
  }

  /**
   * Load the development and redirect to the explorer page.
   */
  loadDevelopment = async () => {
    analytics.trackOpenProject(this.props.development.id);
    this.setState({ redirectToExplorer: true });
  }

  render() {
    if (this.state.redirectToExplorer) {
      return (
        <Redirect
          to={{
            pathname: `${Path.Explorer}/${this.props.development.id}`,
          }}
          push
        />
      );
    }

    const { development, onDeleteDevelopment } = this.props;
    const developmentName = developmentUiHelper.getDisplayName(development.name);

    return (
      <div className="component--development-card">
        <Thumbnail developmentId={development.id} onClick={this.loadDevelopment} />
        <ReturnOnCost condoReturnOnCost={development.condoReturnOnCost} leaseUsesReturnOnCost={development.leaseUsesReturnOnCost} />

        {development.isFromShared && <div className="shared">Shared with me</div>}
        <div className="card-content">
          <div className="development-name" onClick={this.loadDevelopment}>
            {developmentName}
          </div>
          <div className="last-opened">
            {developmentUiHelper.getLastModifiedString(development.timeModified)}
          </div>

          <div className="buttons-container">
            <ShareProject developmentId={development.id} developmentName={developmentName} />

            <DeleteDevelopment
              developmentId={development.id}
              developmentName={developmentName}
              onDeleteDevelopment={onDeleteDevelopment}
              showText
            />
          </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps)(DevelopmentCard);
