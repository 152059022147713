import React from "react";
import { connect } from "react-redux";
import Map from "../sharedComponents/Map";
import BuildingLayer from "../../../pages/ExplorerPage/Map/BuildingLayer";
import { pdfSelectors } from "../../../../state/pdf";
import Pdf from "../../../../types/Pdf";
import { MapStyleProperties } from "../../../../utils/mapbox/mapStyleProperties";

const mapStateToProps = (state) => {
  return {
    camera: pdfSelectors.getCamera(state),
    coverPageImage: pdfSelectors.getCoverPageImage(state, Pdf.ImageType.Cover),
  }
}

type StateProps = ReturnType<typeof mapStateToProps>;
type Props = StateProps;

class MapCover extends React.PureComponent<Props, {}> {
  render() {
    const { coverPageImage } = this.props;
    if (coverPageImage && coverPageImage.url) return null;

    return (
      <Map
        pdfImageType={Pdf.ImageType.Cover}
        camera={this.props.camera}
        mapStyleUrl={MapStyleProperties.StyleUrl.Satellite}
      >
        <BuildingLayer />
      </Map>
    );
  }
}

export default connect(
  mapStateToProps,
)(MapCover);
