import React from "react";
import companyInfo from "../../../../../utils/companyInfo";

export default class Support extends React.PureComponent<{}, {}> {
  render() {
    return (
      <div className="component--support">
        <div className="header" >
          SUPPORT
        </div>

        <div className="links-container">
          {/* Support */}
          <a href={companyInfo.CITIES_URL} target="_blank" className="side-bar-link" rel="noopener noreferrer">
            <span className="icon icon-cities" />
            Cities with Data
          </a>
          <a href={companyInfo.WEBINAR_URL} target="_blank" className="side-bar-link" rel="noopener noreferrer">
            <span className="icon icon-webinar" />
            Weekly Webinar
          </a>
          <a href={companyInfo.TUTORIALS_URL} target="_blank" className="side-bar-link" rel="noopener noreferrer">
            <span className="icon icon-support" />
            Product Tutorials
          </a>
          <a href={companyInfo.BLOG_URL} target="_blank" className="side-bar-link" rel="noopener noreferrer">
            <span className="icon icon-blog" />
            Blog
          </a>
          <a href={companyInfo.CONTACT_US_URL} target="_blank" className="side-bar-link" rel="noopener noreferrer">
            <span className="icon icon-contact-us" />
            Contact Us
          </a>
        </div>
      </div>
    )
  }
}
