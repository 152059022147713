export interface ForwardGeocodeOptions {
  query: string;
  proximity?: [number, number],
}

// See https://docs.mapbox.com/api/search/#geocoding-response-object
export enum GeocodePlaceType {
  Address = "address",
  Country = "country",
  Region = "region",
  Postcode = "postcode",
  District = "district",
  Place = "place",
  Locality = "locality",
  Neighborhood = "neighborhood",
  PointOfInterest = "poi"
}
