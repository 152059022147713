import React, {PureComponent} from "react";
import BackOfEnvelopeSummaryWorksheet from "./BackOfEnvelopeSummaryWorksheet";
import BackOfEnvelopeAssumptionsWorksheet from "./BackOfEnvelopeAssumptionsWorksheet";
import BackOfEnvelopeCondoWorksheet from "./BackOfEnvelopeCondoWorksheet";
import BackOfEnvelopeRentalUsesWorksheet from "./BackOfEnvelopeRentalUsesWorksheet";
import BackOfEnvelopeHotelWorksheet from "./BackOfEnvelopeHotelWorksheet";

interface Props {
  currentTab: number
}

class BackOfEnvelopeSheets extends PureComponent<Props, {}> {
  render() {
    return (
      <div className="back-of-envelope-sheets sheet-wrapper">
        <div className="break" />
        <BackOfEnvelopeSummaryWorksheet index={0} currentTab={this.props.currentTab} />
        <BackOfEnvelopeAssumptionsWorksheet index={1} currentTab={this.props.currentTab} />
        <BackOfEnvelopeCondoWorksheet index={2} currentTab={this.props.currentTab} />
        <BackOfEnvelopeRentalUsesWorksheet index={3} currentTab={this.props.currentTab} />
        <BackOfEnvelopeHotelWorksheet index={4} currentTab={this.props.currentTab} />
      </div>
    )
  }
}

export default BackOfEnvelopeSheets;
