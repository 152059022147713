import { ParcelProperty } from "../../../parcel/ParcelProperty";
import nestedArrays from "./utils/nestedArrays";
import parcelAccessors from "../../../../utils/parcel/parcelAccessors";
import { Development } from "../../../../types/Development/Development";
import turf from "../../../turf";

/**
 * Dehydrate redundant data from a development object.
 */
const dehydrate = (development: Development) => {
  development.parcel.geometry.coordinates
    = nestedArrays.toFirestoreCompatibleFormat(development.parcel.geometry.coordinates);

  const assemblyParcels = (development.parcel.properties && development.parcel.properties[ParcelProperty.AssemblyParcels]);
  if (assemblyParcels) {
    for (let assemblyParcel of assemblyParcels) {
      assemblyParcel.geometry.coordinates
        = nestedArrays.toFirestoreCompatibleFormat(assemblyParcel.geometry.coordinates);
    }
  }

  development.buildingModel.setbackSchedule.forEach((setback) => {
    setback.setbacks = nestedArrays.toFirestoreCompatibleFormat(setback.setbacks);
    delete setback.footprint;
  });

  if (parcelAccessors.getIsAnAssembly(development.parcel)) {
    for (let assemblyParcel of parcelAccessors.getAssemblyParcels(development.parcel)) {
      delete assemblyParcel.properties[ParcelProperty.AreaComputed];
    }
  } else if (development.parcel.properties) {
    delete development.parcel.properties[ParcelProperty.AreaComputed];
  }

  delete development.buildingModel.floors;
  delete development.values.projectNumberOfFloors;
  delete development.values.floorAreaRatioWithoutParking;
  delete development.isEdited;
  delete development.floorsWithSetbacks;
  delete development.selectedSetbackFloor;
  delete development.selectedSetbackFloorIndex;
}

/**
 * Hydrate a development object with necessary data.
 */
const hydrate = (development) => {
  development.parcel.geometry.coordinates
    = nestedArrays.fromFirestoreCompatibleFormat(development.parcel.geometry.coordinates);

  const assemblyParcels = (development.parcel.properties && development.parcel.properties[ParcelProperty.AssemblyParcels]);
  if (assemblyParcels) {
    for (let assemblyParcel of assemblyParcels) {
      assemblyParcel.geometry.coordinates
        = nestedArrays.fromFirestoreCompatibleFormat(assemblyParcel.geometry.coordinates);
    }
  }

  if (parcelAccessors.getIsAnAssembly(development.parcel)) {
    for (let assemblyParcel of parcelAccessors.getAssemblyParcels(development.parcel)) {
      assemblyParcel.properties[ParcelProperty.AreaComputed] = turf.area(assemblyParcel);
    }
  } else if (development.parcel.properties) {
    development.parcel.properties[ParcelProperty.AreaComputed] = turf.area(development.parcel);
  }

  development.buildingModel.setbackSchedule.forEach((setback) => {
    setback.setbacks = nestedArrays.fromFirestoreCompatibleFormat(setback.setbacks);
  });
}

export default {
  dehydrate,
  hydrate
};
