export interface ListingsService {
  getListings(boundingBox: [number, number, number, number]);
}

export type ListingLabelMap = {
  [key in Exclude<ListingProperty, "url" | "listingKey" | "coordinates">]: string;
}

export type ListingKeyMap = {
  [key in ListingProperty]: string;
}

export interface Media {
  MediaURL: string
}

export enum ListingProperty {
  ListingKey = "listingKey",
  Url = "url",
  Coordinates = "coordinates",
  MlsStatus = "mlsStatus",
  Address = "address",
  ListPrice = "listPrice",
  CapRate = "capRate",
  LeaseConsideredYN = "leaseConsideredYN",
  LotSizeAcres = "lotSizeAcres",
  LotSizeSquareFeet = "lotSizeSquareFeet",
  LotSizeDimensions = "lotSizeDimensions",
  FrontageLength = "frontageLength",
  LotFeatures = "lotFeatures",
  ListingTerms = "listingTerms",
  ExpirationDate = "expirationDate",
  MajorChangeType = "majorChangeType",
  PreviousListPrice = "previousListPrice",
  DaysOnMarket = "daysOnMarket",
  OnMarketDate = "onMarketDate",
  ListingId = "listingId",
  PropertyType = "propertyType",
  ParcelNumber = "parcelNumber",
  PostalCode = "postalCode",
  City = "city",
  CountyOrParish = "countyOrParish",
  StateOrProvince = "stateOrProvince",
  WaterfrontYN = "waterfrontYN",
  RoadFrontageType = "roadFrontageType",
  Media = "media",
  VirtualTourURLUnbranded = "virtualTourURLUnbranded",
  Supplements = "supplements",
  DocumentsAvailable = "documentsAvailable",
  TaxLegalDescription = "taxLegalDescription",
  AdditionalParcelsDescription = "additionalParcelsDescription",
  PublicRemarks = "publicRemarks",
  PrivateRemarks = "privateRemarks",
  Disclaimer = "disclaimer",
  SyndicationRemarks = "syndicationRemarks",
  TaxAnnualAmount = "taxAnnualAmount",
  TaxAssessedValue = "taxAssessedValue",
  TaxYear = "taxYear",
  WaterSource = "waterSource",
  Sewer = "sewer",
  Utilities = "utilities",
  BuildingName = "buildingName",
  CurrentUse = "currentUse",
  BuildingAreaTotal = "buildingAreaTotal",
  StoriesTotal = "storiesTotal",
  YearBuilt = "yearBuilt",
  StructureType = "structureType",
  GreenBuildingVerificationType = "greenBuildingVerificationType",
  NumberOfSeparateWaterMeters = "numberOfSeparateWaterMeters",
  LivingArea = "livingArea",
  NumberOfUnitsInCommunity = "numberOfUnitsInCommunity",
  HabitableResidenceYN = "habitableResidenceYN",
  LeasableAreaUnits = "leasableAreaUnits",
  OccupantType = "occupantType",
  LeaseTerm = "leaseTerm",
  GrossScheduledIncome = "grossScheduledIncome",
  OperatingExpenseIncludes = "operatingExpenseIncludes",
  NetOperatingIncome = "netOperatingIncome",
  ListOfficeName = "listOfficeName",
  ListOfficePhone = "listOfficePhone",
  ListingAgreement = "listingAgreement",
  ListAgentMlsId = "listAgentMlsId",
  ListAgentFullName = "listAgentFullName",
  ListAgentOfficePhone = "listAgentOfficePhone",
  ListAgentCellPhone = "listAgentCellPhone",
  ListAgentEmail = "listAgentEmail",
  ShowingInstructions = "showingInstructions",
  BuyerAgencyCompensation = "buyerAgencyCompensation",
  TransactionBrokerCompensation = "transactionBrokerCompensation",
}

export const listingLabelMap: ListingLabelMap = {
  [ListingProperty.MlsStatus]: "Listing Status",
  [ListingProperty.Address]: "Property Address",
  [ListingProperty.ListPrice]: "Listing Price",
  [ListingProperty.CapRate]: "Cap Rate",
  [ListingProperty.LeaseConsideredYN]: "Lease Considered (Y or N)",
  [ListingProperty.LotSizeAcres]: "Lot Size in Acres",
  [ListingProperty.LotSizeSquareFeet]: "Lot Size Square Feet",
  [ListingProperty.LotSizeDimensions]: "Lot Size Dimensions",
  [ListingProperty.FrontageLength]: "Frontage Lenght",
  [ListingProperty.LotFeatures]: "Lot Features",
  [ListingProperty.ListingTerms]: "Listing Terms",
  [ListingProperty.ExpirationDate]: "Listing Expiration Date",
  [ListingProperty.MajorChangeType]: "Change Type",
  [ListingProperty.PreviousListPrice]: "Previous Listing Price",
  [ListingProperty.DaysOnMarket]: "Days on Market",
  [ListingProperty.OnMarketDate]: "On Market Date",
  [ListingProperty.ListingId]: "Listing ID",
  [ListingProperty.PropertyType]: "Property Type",
  [ListingProperty.ParcelNumber]: "Parcel Number",
  [ListingProperty.PostalCode]: "Postal Code",
  [ListingProperty.City]: "City",
  [ListingProperty.CountyOrParish]: "County",
  [ListingProperty.StateOrProvince]: "State",
  [ListingProperty.WaterfrontYN]: "Waterfront (Y or N)",
  [ListingProperty.RoadFrontageType]: "Road Frontage Type",
  [ListingProperty.Media]: "Media Links",
  [ListingProperty.VirtualTourURLUnbranded]: "Virtual Tour Link",
  [ListingProperty.Supplements]: "Supplements Link",
  [ListingProperty.DocumentsAvailable]: "Documents Available",
  [ListingProperty.TaxLegalDescription]: "Legal Description",
  [ListingProperty.AdditionalParcelsDescription]: "Additional Description",
  [ListingProperty.PublicRemarks]: "Public Remarks",
  [ListingProperty.PrivateRemarks]: "Private Remarks",
  [ListingProperty.Disclaimer]: "Disclaimer",
  [ListingProperty.SyndicationRemarks]: "Syndication Remarks",
  [ListingProperty.TaxAnnualAmount]: "Tax Annual Amount",
  [ListingProperty.TaxAssessedValue]: "Tax Assessed Value",
  [ListingProperty.TaxYear]: "Tax Year",
  [ListingProperty.WaterSource]: "Water Source",
  [ListingProperty.Sewer]: "Sewer",
  [ListingProperty.Utilities]: "Utilities",
  [ListingProperty.BuildingName]: "Building Name",
  [ListingProperty.CurrentUse]: "Current Use",
  [ListingProperty.BuildingAreaTotal]: "Building Area",
  [ListingProperty.StoriesTotal]: "Stories Total",
  [ListingProperty.YearBuilt]: "Year Built",
  [ListingProperty.StructureType]: "Structure Type",
  [ListingProperty.GreenBuildingVerificationType]: "Green Building Verification Type",
  [ListingProperty.NumberOfSeparateWaterMeters]: "Number of Water Meters",
  [ListingProperty.LivingArea]: "Living Area",
  [ListingProperty.NumberOfUnitsInCommunity]: "Number of Units in Community",
  [ListingProperty.HabitableResidenceYN]: "Habitable Residence (Y or N)",
  [ListingProperty.LeasableAreaUnits]: "Leasable Units",
  [ListingProperty.OccupantType]: "Occupant Type",
  [ListingProperty.LeaseTerm]: "Lease Term",
  [ListingProperty.GrossScheduledIncome]: "Gross Income",
  [ListingProperty.OperatingExpenseIncludes]: "Operating Expenses",
  [ListingProperty.NetOperatingIncome]: "Net Operating Income",
  [ListingProperty.ListOfficeName]: "Listing Office Name",
  [ListingProperty.ListOfficePhone]: "Listing Office Phone",
  [ListingProperty.ListingAgreement]: "Listing Agreement",
  [ListingProperty.ListAgentMlsId]: "Listing Agent MLS ID",
  [ListingProperty.ListAgentFullName]: "Listing Agent Name",
  [ListingProperty.ListAgentOfficePhone]: "Agent Office Phone",
  [ListingProperty.ListAgentCellPhone]: "Agent Cell Phone",
  [ListingProperty.ListAgentEmail]: "Listing Agent Email",
  [ListingProperty.ShowingInstructions]: "Showing Instructions",
  [ListingProperty.BuyerAgencyCompensation]: "Buyer Agency Compensation",
  [ListingProperty.TransactionBrokerCompensation]: "Transaction Broker Compensation",
}

export const listingKeyMap: ListingKeyMap = {
  [ListingProperty.ListingKey]: "ListingKey",
  [ListingProperty.Url]: "url",
  [ListingProperty.Coordinates]: "Coordinates",
  [ListingProperty.MlsStatus]: "MlsStatus",
  [ListingProperty.Address]: "UnparsedAddress",
  [ListingProperty.ListPrice]: "ListPrice",
  [ListingProperty.CapRate]: "CapRate",
  [ListingProperty.LeaseConsideredYN]: "LeaseConsideredYN",
  [ListingProperty.LotSizeAcres]: "LotSizeAcres",
  [ListingProperty.LotSizeSquareFeet]: "LotSizeSquareFeet",
  [ListingProperty.LotSizeDimensions]: "LotSizeDimensions",
  [ListingProperty.FrontageLength]: "FrontageLength",
  [ListingProperty.LotFeatures]: "LotFeatures",
  [ListingProperty.ListingTerms]: "ListingTerms",
  [ListingProperty.ExpirationDate]: "ExpirationDate",
  [ListingProperty.MajorChangeType]: "MajorChangeType",
  [ListingProperty.PreviousListPrice]: "PreviousListPrice",
  [ListingProperty.DaysOnMarket]: "DaysOnMarket",
  [ListingProperty.OnMarketDate]: "OnMarketDate",
  [ListingProperty.ListingId]: "ListingId",
  [ListingProperty.PropertyType]: "PropertyType",
  [ListingProperty.ParcelNumber]: "ParcelNumber",
  [ListingProperty.PostalCode]: "PostalCode",
  [ListingProperty.City]: "City",
  [ListingProperty.CountyOrParish]: "CountyOrParish",
  [ListingProperty.StateOrProvince]: "StateOrProvince",
  [ListingProperty.WaterfrontYN]: "WaterfrontYN",
  [ListingProperty.RoadFrontageType]: "RoadFrontageType",
  [ListingProperty.Media]: "Media",
  [ListingProperty.VirtualTourURLUnbranded]: "VirtualTourURLUnbranded",
  [ListingProperty.Supplements]: "Supplements",
  [ListingProperty.DocumentsAvailable]: "DocumentsAvailable",
  [ListingProperty.TaxLegalDescription]: "TaxLegalDescription",
  [ListingProperty.AdditionalParcelsDescription]: "AdditionalParcelsDescription",
  [ListingProperty.PublicRemarks]: "PublicRemarks",
  [ListingProperty.PrivateRemarks]: "PrivateRemarks",
  [ListingProperty.Disclaimer]: "Disclaimer",
  [ListingProperty.SyndicationRemarks]: "SyndicationRemarks",
  [ListingProperty.TaxAnnualAmount]: "TaxAnnualAmount",
  [ListingProperty.TaxAssessedValue]: "TaxAssessedValue",
  [ListingProperty.TaxYear]: "TaxYear",
  [ListingProperty.WaterSource]: "WaterSource",
  [ListingProperty.Sewer]: "Sewer",
  [ListingProperty.Utilities]: "Utilities",
  [ListingProperty.BuildingName]: "BuildingName",
  [ListingProperty.CurrentUse]: "CurrentUse",
  [ListingProperty.BuildingAreaTotal]: "BuildingAreaTotal",
  [ListingProperty.StoriesTotal]: "StoriesTotal",
  [ListingProperty.YearBuilt]: "YearBuilt",
  [ListingProperty.StructureType]: "StructureType",
  [ListingProperty.GreenBuildingVerificationType]: "GreenBuildingVerificationType",
  [ListingProperty.NumberOfSeparateWaterMeters]: "NumberOfSeparateWaterMeters",
  [ListingProperty.LivingArea]: "LivingArea",
  [ListingProperty.NumberOfUnitsInCommunity]: "NumberOfUnitsInCommunity",
  [ListingProperty.HabitableResidenceYN]: "HabitableResidenceYN",
  [ListingProperty.LeasableAreaUnits]: "LeasableAreaUnits",
  [ListingProperty.OccupantType]: "OccupantType",
  [ListingProperty.LeaseTerm]: "LeaseTerm",
  [ListingProperty.GrossScheduledIncome]: "GrossScheduledIncome",
  [ListingProperty.OperatingExpenseIncludes]: "OperatingExpenseIncludes",
  [ListingProperty.NetOperatingIncome]: "NetOperatingIncome",
  [ListingProperty.ListOfficeName]: "ListOfficeName",
  [ListingProperty.ListOfficePhone]: "ListOfficePhone",
  [ListingProperty.ListingAgreement]: "ListingAgreement",
  [ListingProperty.ListAgentMlsId]: "ListAgentMlsId",
  [ListingProperty.ListAgentFullName]: "ListAgentFullName",
  [ListingProperty.ListAgentOfficePhone]: "ListAgentOfficePhone",
  [ListingProperty.ListAgentCellPhone]: "ListAgentCellPhone",
  [ListingProperty.ListAgentEmail]: "ListAgentEmail",
  [ListingProperty.ShowingInstructions]: "ShowingInstructions",
  [ListingProperty.BuyerAgencyCompensation]: "BuyerAgencyCompensation",
  [ListingProperty.TransactionBrokerCompensation]: "TransactionBrokerCompensation",
}

export interface Listing {
  [ListingProperty.ListingKey]: string,
  [ListingProperty.Url]: string,
  [ListingProperty.MlsStatus]: string,
  [ListingProperty.Address]: string,
  [ListingProperty.ListPrice]: number
  [ListingProperty.CapRate]: string,
  [ListingProperty.LeaseConsideredYN]: boolean,
  [ListingProperty.LotSizeAcres]: number,
  [ListingProperty.LotSizeSquareFeet]: number
  [ListingProperty.LotSizeDimensions]: string,
  [ListingProperty.FrontageLength]: string,
  [ListingProperty.LotFeatures]: Array<string>,
  [ListingProperty.ListingTerms]: Array<string>,
  [ListingProperty.ExpirationDate]: string,
  [ListingProperty.MajorChangeType]: string,
  [ListingProperty.PreviousListPrice]: number
  [ListingProperty.DaysOnMarket]: number
  [ListingProperty.OnMarketDate]: string,
  [ListingProperty.ListingId]: string,
  [ListingProperty.PropertyType]: string,
  [ListingProperty.ParcelNumber]: string,
  [ListingProperty.PostalCode]: string,
  [ListingProperty.City]: string,
  [ListingProperty.CountyOrParish]: string,
  [ListingProperty.StateOrProvince]: string,
  [ListingProperty.WaterfrontYN]: boolean,
  [ListingProperty.RoadFrontageType]: Array<string>,
  [ListingProperty.Media]: Array<Media>,
  [ListingProperty.VirtualTourURLUnbranded]: string,
  [ListingProperty.Supplements]: Array<Media>,
  [ListingProperty.DocumentsAvailable]: Array<string>,
  [ListingProperty.TaxLegalDescription]: string,
  [ListingProperty.AdditionalParcelsDescription]: string,
  [ListingProperty.PublicRemarks]: string,
  [ListingProperty.PrivateRemarks]: string,
  [ListingProperty.Disclaimer]: string,
  [ListingProperty.SyndicationRemarks]: string,
  [ListingProperty.TaxAnnualAmount]: number
  [ListingProperty.TaxAssessedValue]: string,
  [ListingProperty.TaxYear]: number
  [ListingProperty.WaterSource]: Array<string>,
  [ListingProperty.Sewer]: Array<string>,
  [ListingProperty.Utilities]: Array<string>,
  [ListingProperty.BuildingName]: string,
  [ListingProperty.CurrentUse]: Array<string>,
  [ListingProperty.BuildingAreaTotal]: string,
  [ListingProperty.StoriesTotal]: string,
  [ListingProperty.YearBuilt]: number,
  [ListingProperty.StructureType]: Array<string>,
  [ListingProperty.GreenBuildingVerificationType]: string,
  [ListingProperty.NumberOfSeparateWaterMeters]: string,
  [ListingProperty.LivingArea]: string,
  [ListingProperty.NumberOfUnitsInCommunity]: string,
  [ListingProperty.HabitableResidenceYN]: boolean,
  [ListingProperty.LeasableAreaUnits]: string,
  [ListingProperty.OccupantType]: string,
  [ListingProperty.LeaseTerm]: string,
  [ListingProperty.GrossScheduledIncome]: number
  [ListingProperty.OperatingExpenseIncludes]: Array<string>,
  [ListingProperty.NetOperatingIncome]: string,
  [ListingProperty.ListOfficeName]: string,
  [ListingProperty.ListOfficePhone]: string,
  [ListingProperty.ListingAgreement]: string,
  [ListingProperty.ListAgentMlsId]: string,
  [ListingProperty.ListAgentFullName]: string,
  [ListingProperty.ListAgentOfficePhone]: string,
  [ListingProperty.ListAgentCellPhone]: string,
  [ListingProperty.ListAgentEmail]: string,
  [ListingProperty.ShowingInstructions]: string,
  [ListingProperty.BuyerAgencyCompensation]: string,
  [ListingProperty.TransactionBrokerCompensation]: string,
}
