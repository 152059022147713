import React from "react";
import NewDevelopmentPopup from "../NewDevelopmentPopup";
import analytics from "../../../../../../utils/analytics";

interface Props {
  text: string
}

interface State {
  newDevelopmentPopupIsOpen: boolean;
}

export default class NewDevelopmentButton extends React.PureComponent<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      newDevelopmentPopupIsOpen: false,
    }
  }

  /**
   * Toggle the newDevelopmentPopupIsOpen state.
   */
  toggleNewDevelopmentPopupIsOpen = () => {
    if(!this.state.newDevelopmentPopupIsOpen) analytics.trackOpenNewDevelopmentPopup();
    this.setState({ newDevelopmentPopupIsOpen: !this.state.newDevelopmentPopupIsOpen });
  }

  render() {
    const { text } = this.props;

    return (
      <>
        <button className="bottom btn" onClick={this.toggleNewDevelopmentPopupIsOpen}>{text}</button>
        {this.state.newDevelopmentPopupIsOpen && <NewDevelopmentPopup onStateChange={this.toggleNewDevelopmentPopupIsOpen} />}
      </>
    )
  }
}
