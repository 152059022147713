const INITIALIZE = "development/INITIALIZE";
const RE_CALCULATE = "development/RE_CALCULATE";
const SET_NAME = "development/SET_NAME";
const SET_RANGED_INPUT = "development/SET_RANGED_INPUT";
const SET_RANGED_INPUT_MINIMUM = "development/SET_RANGED_INPUT_MINIMUM";
const SET_RANGED_INPUT_MAXIMUM = "development/SET_RANGED_INPUT_MAXIMUM";
const SET_SETBACK_TYPE = "development/SET_SETBACK_TYPE";
const SET_CAMERA = "development/SET_CAMERA";
const TOGGLE_BOOLEAN_INPUT = "development/TOGGLE_BOOLEAN_INPUT";
const SET_UNIT_SYSTEM = "development/SET_UNIT_SYSTEM";
const RESET_STATE = "development/RESET_STATE";
const PROJECT_SAVED = "development/PROJECT_SAVED";
const KEEP_PROJECT = "development/KEEP_PROJECT";
const ADD_SETBACK_FLOOR = "development/ADD_SETBACK_FLOOR";
const REMOVE_SETBACK_FLOOR = "development/REMOVE_SETBACK_FLOOR";
const RESET_SELECTED_SETBACK_FLOOR = "development/RESET_SELECTED_SETBACK_FLOOR";

export default {
  INITIALIZE,
  RE_CALCULATE,
  SET_NAME,
  SET_RANGED_INPUT,
  SET_RANGED_INPUT_MINIMUM,
  SET_RANGED_INPUT_MAXIMUM,
  SET_SETBACK_TYPE,
  TOGGLE_BOOLEAN_INPUT,
  SET_CAMERA,
  SET_UNIT_SYSTEM,
  RESET_STATE,
  PROJECT_SAVED,
  KEEP_PROJECT,
  ADD_SETBACK_FLOOR,
  REMOVE_SETBACK_FLOOR,
  RESET_SELECTED_SETBACK_FLOOR,
};
