import developmentAccessors from "../../../../state/development/utils/developmentAccessors";
import { Development } from  "../../../../types/Development/Development";
import { VariableId } from  "../../../../types/VariableId";
import generateLinearRegressionPreset from "./generatorForLinearRegression";

/**
 * Generate the hotel buildables presets.
 */
const generatePresets = (development: Development, targetArea: number): object => {
  const lossFactor = developmentAccessors.getInputValue(development, VariableId.HotelLossFactor) as number;
  const unitArea = developmentAccessors.getInputValue(development, VariableId.HotelAverageRoomArea) as number;

  const usableArea = (targetArea * (1 - lossFactor));
  let amenitiesArea = usableArea * 0.1;

  // Guard against amenities consuming all of the usable area.
  if (usableArea - amenitiesArea < unitArea) amenitiesArea = 0;

  const areaAvailableForUnits = usableArea ? usableArea - amenitiesArea : 0;
  const unitQuantity = unitArea ? Math.floor(areaAvailableForUnits / unitArea) : 0;
  const leftoverArea = areaAvailableForUnits - (unitQuantity * unitArea);
  amenitiesArea += leftoverArea;

  const previousMaximumUnits = developmentAccessors.getInputMaximum(development,  VariableId.HotelRoomQuantityToggleable);
  const previousMaximumAmenities = developmentAccessors.getInputMaximum(development,  VariableId.HotelAmenitiesAreaToggleable);

  return {
    [VariableId.HotelRoomQuantityToggleable]: {
      "minimum":  0,
      "value":    unitQuantity,
      "maximum":  2 * unitQuantity || previousMaximumUnits
    },
    [VariableId.HotelAmenitiesAreaToggleable]: {
      "minimum":  0,
      "value": amenitiesArea,
      "maximum": 10 * amenitiesArea || previousMaximumAmenities
    },
    [VariableId.HotelStabilizedAverageDailyRate]: generateLinearRegressionPreset(development, VariableId.HotelStabilizedAverageDailyRate),
    [VariableId.HotelHardCostPerArea]: generateLinearRegressionPreset(development, VariableId.HotelHardCostPerArea),
  };
}

export default generatePresets;
