import React, { PureComponent } from "react";
import { Panel } from "../../../../../types/uiElements";
import BackOfEnvelopeTabs from "./BackOfEnvelopeTabs";
import BackOfEnvelopeSheets from "./BackOfEnvelopeSheets";
import PanelHeader from "../sharedComponents/PanelHeader";

interface Props {
  panel: Panel;
  isPinned: boolean | undefined;
  onPanelClose(panel: Panel);
  onPanelPin(panel: Panel);
}

interface State {
  currentTab: number
}

class BackOfEnvelopePanel extends PureComponent<Props, State>  {
  constructor(props) {
    super(props);
    this.state = {
      currentTab: 0
    };
  }

  /**
   * Update the currently-selected tab. This changes which tab is highlighted, and
   * which worksheet is displayed in the Back of Envelope's contents.
   */
  handleTabClick = (tab) => {
    this.setState({ currentTab: tab });
  };

  render() {
    return (
      <div className="component--back-of-envelope panel">
        <PanelHeader
          isPinned={this.props.isPinned}
          panel={this.props.panel}
          onPanelClose={this.props.onPanelClose}
          onPanelPin={this.props.onPanelPin}
        />
        <div className="panel-inner-wrapper">
          <BackOfEnvelopeTabs onTabClick={this.handleTabClick} currentTab={this.state.currentTab} />
          <BackOfEnvelopeSheets currentTab={this.state.currentTab} />
        </div>
      </div>
    );
  }
}

export default BackOfEnvelopePanel;
