import React from "react";
import ReactMapboxGl, { GeoJSONLayer, Marker } from "react-mapbox-gl";
import Measure from "react-measure";
import { connect } from "react-redux";
import { developmentSelectors } from "../../../../state/development";
import turf from "../../../../utils/turf";
import SetbackIcon from "./SetbackIcon";
import SelectSetback from "./SelectSetback";
import mapboxPresentationProperties from "../../../utils/mapboxPresentationProperties";

/**
 * @fileoverview This container wraps the Mapbox map for setback type selection and manages
 *  any required data and functionality.
 */

const PARCEL_SELECTION_ZOOM = 18;
const TOP_VIEW = 0;
const NORTH = 0;

const mapStateToProps = (state, ownProps) => {
  const setbackSchedule = developmentSelectors.getBuildingModel(state).setbackSchedule;
  const selectedSetbackFloorIndex = developmentSelectors.getSelectedSetbackFloorIndex(state);

  return {
    setbackList: setbackSchedule[selectedSetbackFloorIndex].setbacks,
    footprint: setbackSchedule[selectedSetbackFloorIndex].footprint,
    parcelGeoJson: developmentSelectors.getParcel(state),
    selectedSetbackFloor: developmentSelectors.getSelectedSetbackFloor(state),
  };
};

interface OwnProps {
  toggleSetbackSelectionMode: any;
};

type StateProps = ReturnType<typeof mapStateToProps>;
type Props = StateProps & OwnProps;

interface State {
  selectedPolygonIndex: number;
  selectedEdgeIndex: number;
};

class SetbackSelectionMap extends React.PureComponent<Props, State> {
  cameraZoom: [number];
  cameraPitch: [number];
  cameraBearing: [number];
  cameraCenter: number[];
  Mapbox: any;
  setbackCoordinates: any;
  setbackGeoJson: any;
  innerMap: any;

  constructor(props) {
    super(props);

    this.state = {
      selectedPolygonIndex: 0,
      selectedEdgeIndex: 0
    };

    this.cameraZoom = [PARCEL_SELECTION_ZOOM];
    this.cameraPitch = [TOP_VIEW];
    this.cameraBearing = [NORTH];
    this.cameraCenter = turf.getCoord(turf.centerOfMass(this.props.parcelGeoJson));

    this.Mapbox = ReactMapboxGl({
      accessToken: process.env.REACT_APP_MAPBOX_ACCESS_TOKEN as string,
      dragRotate: false,
      touchZoomRotate: false,
    });
  }

  componentDidUpdate() {
    this.resizeMap();
  }

  /**
   * Add identifying icons/markers to the middle point of each segment of external rings of the parcel polygon(s).
   */
  getMarkers = () => {
    let coordinates = turf.getCoords(this.props.parcelGeoJson);

    if (turf.getType(this.props.parcelGeoJson) === "Polygon") {
      return this.getPolygonMarkers(0, coordinates[0]);
    } else { // geometryType === "MultiPolygon"
      return (
        coordinates
          .map((polygon) => polygon[0])
          .map(
            (polygonCoordinates, polygonIndex) => {
              return this.getPolygonMarkers(polygonIndex, polygonCoordinates);
            }
          )
      );
    }
  }

  /**
   * Add identifying icons/markers to the middle point of each segment of external rings of an individual polygon.
   */
  getPolygonMarkers = (polygonIndex, coordinates) => {
    return (
      coordinates.slice(0, -1).map(
        (point, edgeIndex, array) => {
          let nextPoint = array[(edgeIndex + 1) % array.length];
          let midPoint = turf.getCoords(turf.midpoint(point, nextPoint));
          return (
            <Marker
              key={`setbackMarker_${polygonIndex}_${edgeIndex}`}
              coordinates={midPoint}
              anchor="center"
              onClick={() => this.selectEdge(polygonIndex, edgeIndex)}
            >
              <SetbackIcon
                setbackType={this.props.setbackList[polygonIndex][edgeIndex]}
                selected={this.state.selectedPolygonIndex === polygonIndex && this.state.selectedEdgeIndex === edgeIndex}
              />
            </Marker>
          );
        }
      )
    );
  }

  /**
   * Activate the indicated edge for editing.
   */
  selectEdge = (polygonIndex, edgeIndex) => {
    this.setState({
      selectedPolygonIndex: polygonIndex,
      selectedEdgeIndex: edgeIndex
    })
  }

  /**
   * Deactivate all edges from editing mode.
   */
  deselectEdges = () => {
    this.setState({
      selectedPolygonIndex: -1,
      selectedEdgeIndex: -1
    })
  }

  /**
   * Conditionally show the select setback form.
   */
  selectSetbackBox = () => {
    if (this.state.selectedPolygonIndex >= 0 && this.state.selectedEdgeIndex >= 0) {
      return (
        <SelectSetback
          selectedPolygonIndex={this.state.selectedPolygonIndex}
          selectedEdgeIndex={this.state.selectedEdgeIndex}
        />
      );
    } else {
      return null;
    }
  }

  /**
   * Conditionally render the parcel and setback polygons.
   */
  renderSetback = () => {
    if (!this.props.footprint || this.props.footprint.area === 0) {
      return (
        <div>
          <GeoJSONLayer
            data={this.props.parcelGeoJson}
            fillPaint={mapboxPresentationProperties.setbackParcelFillPaint}
            linePaint={mapboxPresentationProperties.setbackLinePaint}
          />
        </div>
      );
    }

    return (
      <div>
        <GeoJSONLayer
          data={this.props.parcelGeoJson}
          fillPaint={mapboxPresentationProperties.setbackParcelFillPaint}
          linePaint={mapboxPresentationProperties.setbackLinePaint}
        />

        <GeoJSONLayer
          data={this.props.footprint.polygons}
          fillPaint={mapboxPresentationProperties.setbackBuildingFillPaint}
          linePaint={mapboxPresentationProperties.setbackLinePaint}
        />
      </div>
    );
  }

  resizeMap = () => {
    if (this.innerMap) {
      this.innerMap.resize();
    }
  }

  render() {
    return (
      <Measure onResize={this.resizeMap} >
        {
          ({ measureRef }) =>
            <div ref={measureRef} className="component--map">
              <this.Mapbox
                // eslint-disable-next-line
                style="mapbox://styles/nmccrea-db/cjkk73m6m0zwm2rpe4cdaevyd"
                center={this.cameraCenter}
                zoom={this.cameraZoom}
                pitch={this.cameraPitch}
                bearing={this.cameraBearing}
                onStyleLoad={(innerMap) => this.innerMap = innerMap}
                onClick={this.deselectEdges}
                containerStyle={{
                  height: "100%",
                  width: "100%",
                  margin: "0 auto"
                }}
              >
                {this.renderSetback()}
                {this.getMarkers()}
              </this.Mapbox>

              <div className="floor">{this.props.selectedSetbackFloor ? `Floor ${this.props.selectedSetbackFloor + 1}` : "Ground Floor"}</div>

              <div
                className="close-cross"
                onClick={this.props.toggleSetbackSelectionMode}
              />

              {this.selectSetbackBox()}
            </div>
        }
      </Measure>
    );
  }
}

export default connect(
  mapStateToProps,
)(SetbackSelectionMap);
