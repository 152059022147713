import React from "react";
import companyInfo from "../../../utils/companyInfo";

interface Tooltip {
  className: string;
  thinHeader: string;
  boldHeader: string;
  contentText: any;
  buttonText: string;
}

export enum TooltipType {
  BackOfEnvelope,
  Dashboard,
  InformationPanel,
  PdfPanel,
  SmartSearch,
}

const informationPanelTooltip: Tooltip = {
  className: "tooltip-information-panel",
  thinHeader: "Property Data",
  boldHeader: "All the info in one place",
  contentText: <>In <a href={companyInfo.CITIES_URL} target="_blank" rel="noopener noreferrer">selected cities</a>{" get a quick understanding \nof what you can do with a site. In other cities, \ndraw your parcel and model return."}</>,
  buttonText: "Subscribe",
}

const dashboardTooltip: Tooltip = {
  className: "tooltip-dashboard",
  thinHeader: "Save your Projects",
  boldHeader: "Increase transactions",
  contentText: "Speed up the process of getting information \nto the right people. Spend less time evaluating \nand underwriting, and more time on calls.",
  buttonText: "Subscribe",
}

const pdfTooltip: Tooltip = {
  className: "tooltip-pdf",
  thinHeader: "Branded Report",
  boldHeader: "Increase deal-flow",
  contentText: "Distinguish yourself while reducing the \nburden of creating a presentation. \nProvide a professional summary.",
  buttonText: "Subscribe",
}

const backOfEnvelopeTooltip: Tooltip = {
  className: "tooltip-back-of-envelope",
  thinHeader: "Financial Assumptions",
  boldHeader: "Faster than building a proforma",
  contentText: "Save up to 30% of your time when evaluating \na deal and forecasting an investment \napproach.",
  buttonText: "Subscribe",
}

const smartSearchTooltip: Tooltip = {
  className: "tooltip-smart-search",
  thinHeader: "Targeted Searches",
  boldHeader: "Faster prospecting of sites",
  contentText: "Save time searching for candidate sites \nthat meet investment criteria. Get a view \nof what’s going on in the city map.",
  buttonText: "Subscribe",
}

const config = {
  [TooltipType.BackOfEnvelope]: backOfEnvelopeTooltip,
  [TooltipType.Dashboard]: dashboardTooltip,
  [TooltipType.InformationPanel]: informationPanelTooltip,
  [TooltipType.PdfPanel]: pdfTooltip,
  [TooltipType.SmartSearch]: smartSearchTooltip
}

export default config;
