import React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { withRouter } from "react-router-dom";
import DragAndDrop from "./DragAndDrop";
import Pdf from "../../../../../../types/Pdf";
import { pdfActions, pdfSelectors } from "../../../../../../state/pdf";
import BusySpinner from "../../../../../sharedComponents/BusySpinner";

interface OwnProps {
  classPrefix: string;
  labelText: string;
  imageType: Pdf.ImageType.Logo | Pdf.ImageType.Cover
}

const mapStateToProps = (state, ownProps: OwnProps) => {
  return {
    image: pdfSelectors.getCoverPageImage(state, ownProps.imageType),
  }
}

const mapDispatchToProps = {
  uploadCoverPageImageStart: pdfActions.uploadCoverPageImageStart,
  deleteCoverPageImageStart: pdfActions.deleteCoverPageImageStart,
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;
type RouterProps = RouteComponentProps<{ projectId: string }>;
type Props = DispatchProps & StateProps & RouterProps & OwnProps;

class ImageUpload extends React.PureComponent<Props, {}> {
  /**
   * Handle file drop.
   */
  setCoverImage = (file?: File) => {
    const developmentId = this.props.match.params.projectId;
    if (!developmentId) return;
    if (file) {
      this.props.uploadCoverPageImageStart(developmentId, file, this.props.imageType);
    } else {
      this.props.deleteCoverPageImageStart(developmentId, this.props.imageType);
    }
  }

  /**
   * Clear image in the store.
   */
  clearImage = () => {
    this.setCoverImage();
  }

  /**
   * Render file name and size.
   */
  renderFileInformation = () => {
    const { image } = this.props;
    if (!image) return <DragAndDrop handleUpload={this.setCoverImage} />;
    if (image.isLoading) return <BusySpinner />;

    return (
      <p className="file-name">
        {image.fileName} <span className="file-remove" onClick={this.clearImage} />
      </p>
    );
  }

  render() {
    const { classPrefix, labelText, image } = this.props;

    return (
      <div className="component--image-upload">
        <div className="label-container">
          <div className={`${classPrefix}-icon icon`} />
          <label>{labelText}</label>
        </div>
        <div className="drag-and-drop-container">
          <label className="title">{image ? "Uploaded Image" : "Upload"}</label>
          {this.renderFileInformation()}
        </div>
      </div>
    )
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ImageUpload)
) as any;
