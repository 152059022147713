import React from "react";

interface OwnProps {
  handleClickStartWorking(): void;
}

type Props = OwnProps;

class Welcome extends React.PureComponent<Props, {}> {
  render() {
    return (
      <div className="component--welcome">
        <div className="welcome-image" />
        <p>Thank you for your payment, you are all set.</p>
        <button onClick={this.props.handleClickStartWorking}>Start Working</button>
      </div>
    );
  }
}

export default Welcome;
