import forPurchasePriceByArea from "./generatePresetsForPurchasePriceByArea";
import forBuildables from "./generatePresetsForBuildables";
import forParking from "./generatePresetsForBuildables/generatorForParking";
import forSetbacks from "./generatePresetsForSetbacks";

export default {
  forPurchasePriceByArea,
  forBuildables,
  forParking,
  forSetbacks,
}
