import { Config } from "../../../../../../../types/Config";
import Unit from "../../../../../../../types/Unit";
import Format from "../../../../../../../types/Format";
import { VariableId } from "../../../../../../../types/VariableId";
import { BuildingUse } from "../../../../../../../types/BuildingUse";

const config: Config = {
  [VariableId.ParcelArea]: {
    variableId: VariableId.ParcelArea,
    text: "Lot Size",
    formatOptions: { type: Format.Type.Number },
    requiresAuthentication: true,
    [Unit.System.Imperial]: {
      unitTarget: Unit.Type.SquareFeet,
      formatOptions: { suffix: " SF" }
    },
    [Unit.System.Metric]: {
      unitTarget: Unit.Type.SquareMeters,
      formatOptions: { suffix: " m²" }
    }
  },

  [VariableId.ExistingStructureArea]: {
    variableId: VariableId.ExistingStructureArea,
    text: "Existing Buildings",
    formatOptions: { type: Format.Type.Number },
    requiresAuthentication: true,
    [Unit.System.Imperial]: {
      unitTarget: Unit.Type.SquareFeet,
      formatOptions: { suffix: " SF" }
    },
    [Unit.System.Metric]: {
      unitTarget: Unit.Type.SquareMeters,
      formatOptions: { suffix: " m²" }
    }
  },

  [VariableId.CondoTotalUnitQuantity]: {
    variableId: VariableId.CondoTotalUnitQuantity,
    text: "Condo Units",
    buildingUse: BuildingUse.Condo,
    formatOptions: {
      type: Format.Type.Number,
      suffix: " units",
    },
  },

  [VariableId.MultifamilyTotalUnitQuantity]: {
    variableId: VariableId.MultifamilyTotalUnitQuantity,
    text: "Multifamily Units",
    buildingUse: BuildingUse.Multifamily,
    formatOptions: {
      type: Format.Type.Number,
      suffix: " units",
    },
  },

  [VariableId.HotelRoomQuantity]: {
    variableId: VariableId.HotelRoomQuantity,
    text: "Hotel Keys",
    buildingUse: BuildingUse.Hotel,
    formatOptions: {
      type: Format.Type.Number,
      suffix: " keys",
    },
  },

  [VariableId.OfficeGrossLeasableArea]: {
    variableId: VariableId.OfficeGrossLeasableArea,
    text: "Office Leasable Area",
    buildingUse: BuildingUse.Office,
    formatOptions: { type: Format.Type.Number },
    [Unit.System.Imperial]: {
      unitTarget: Unit.Type.SquareFeet,
      formatOptions: { suffix: " SF" }
    },
    [Unit.System.Metric]: {
      unitTarget: Unit.Type.SquareMeters,
      formatOptions: { suffix: " m²" }
    }
  },

  [VariableId.RetailNetLeasableArea]: {
    variableId: VariableId.RetailNetLeasableArea,
    text: "Retail Leasable Area",
    buildingUse: BuildingUse.Retail,
    formatOptions: { type: Format.Type.Number },
    [Unit.System.Imperial]: {
      unitTarget: Unit.Type.SquareFeet,
      formatOptions: { suffix: " SF" }
    },
    [Unit.System.Metric]: {
      unitTarget: Unit.Type.SquareMeters,
      formatOptions: { suffix: " m²" }
    }
  },

  [VariableId.IndustrialNetLeasableArea]: {
    variableId: VariableId.IndustrialNetLeasableArea,
    text: "Industrial Leasable Area",
    buildingUse: BuildingUse.Industrial,
    formatOptions: { type: Format.Type.Number },
    [Unit.System.Imperial]: {
      unitTarget: Unit.Type.SquareFeet,
      formatOptions: { suffix: " SF" }
    },
    [Unit.System.Metric]: {
      unitTarget: Unit.Type.SquareMeters,
      formatOptions: { suffix: " m²" }
    }
  },

  [VariableId.ParkingRequiredSpacesAfterReduction]: {
    variableId: VariableId.ParkingRequiredSpacesAfterReduction,
    text: "Total Structured Parking",
    formatOptions: {
      type: Format.Type.Number,
      suffix: " spaces",
    },
  },

  [VariableId.ProjectNumberOfFloors]: {
    variableId: VariableId.ProjectNumberOfFloors,
    text: "Number of Floors",
    formatOptions: {
      type: Format.Type.Number,
      suffix: " floors",
    },
  },
};

export default config;
