import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { developmentSelectors } from "../../../../../../../state/development";
import { buildingUsageIsEnabled } from "../../../../../../utils/uiToggleHelper";

interface Props {
  toggles: object;
  usageGroup: string;
  title: string;
  index: number;
  onClick(currentTab: number): void;
  currentTab: number;
}

class Tab extends PureComponent<Props, {}>  {
  /**
   * Update the state of the currently-selected tab.
   */
  handleClick = () => { this.props.onClick(this.props.index); };

  /**
   * A boolean indicating whether this is the currently-selected tab.
   * If this is the first tab (index 0) and no tab is currently selected, defaults to true.
   */
  isActive() { return this.props.currentTab === this.props.index || (!this.props.currentTab && this.props.index === 0); }

  /**
   * This function checks to see if current tab becomes disabled (e.g. disabled in settings panel),
   * then the summary tab will be selected by default if so.
   * This applies only to Condo, Hotel and Lease Uses tabs.
   */
  isCurrentTabEnabled = () => {
    if (this.props.currentTab === this.props.index && !buildingUsageIsEnabled(this.props.toggles, this.props.usageGroup)){
      this.props.onClick(0);
    }
  };

  render() {
    this.isCurrentTabEnabled();
    if (this.props.usageGroup && !buildingUsageIsEnabled(this.props.toggles, this.props.usageGroup)) { return "";}
    let classes = "component--tab tab-wrapper" + (this.isActive() ? " active" : "");
    return (
      <div className={classes} onClick={this.handleClick}>
        <span className="tab-content">{this.props.title}</span>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    toggles: developmentSelectors.getBuildingUsageToggles(state)
  };
};

export default connect(
  mapStateToProps
)(Tab);
