import firebase from "firebase/app";
import "firebase/functions";
import { Chargebee } from "../../types/Service/Chargebee";

/**
 * Open chargebee checkout modal for existing subscriptions or create new subscription if no subscriptionId exists.
 *
 * @param {string | undefined} subscriptionId
 * @param {Chargebee.PlanId} paymentPlanId
 *
 * @returns {Promise} - A promise containing a Chargebee hosted page object.
 */
const getHostedCheckoutPage = async (paymentPlanId: Chargebee.PlanId, subscriptionId: string): Promise<Chargebee.HostedPage> => {
  const callable = firebase.functions().httpsCallable("chargebee-getHostedCheckoutPageForExistingSubscription");
  const result = await callable({ subscriptionId, paymentPlanId });

  return result.data.hosted_page;
}

/**
 * Create chargebee portal session.
 *
 * @returns {Promise} - A promise containing a Chargebee portal session object.
 */
const createChargebeePortalSession = async (): Promise<Chargebee.PortalSession> => {
  const callable = firebase.functions().httpsCallable("chargebee-createPortalSession");
  const result = await callable();

  return result.data.portal_session;
}

/**
 * Get the identified Chargebee subscription object.
 *
 * @param {string} subscriptionId - The ID of the Chargebee subscription to retrieve.
 *
 * @returns {Promise} - A promise containing a Chargebee subscription object.
 */
const getSubscription = async (subscriptionId: string): Promise<Chargebee.Subscription> => {
  const callable = firebase.functions().httpsCallable("chargebee-getSubscription");
  const result = await callable(subscriptionId);

  return result.data;
}

/**
 * Get the subscription object for the indicated Chargebee customer.
 *
 * @param {string} customerId - The ID of the Chargebee customer whose subscription
 *  information to fetch.
 *
 * @returns {Promise} - A promise containing a Chargebee subscription object.
 */
const getCustomerSubscription = async (customerId: string): Promise<Chargebee.Subscription> => {
  const callable = firebase.functions().httpsCallable("chargebee-getCustomerSubscription");
  const result = await callable(customerId);

  return result.data;
}

/**
 * Get the price of a payment plan.
 *
 * @param {PlanId} paymentPlanId
 *
 * @returns {Promise} - A promise containing the price of the payment plan.
 */
const getPaymentPlan = async (paymentPlanId: Chargebee.PlanId): Promise<Chargebee.Plan> => {
  const callable = firebase.functions().httpsCallable("chargebee-getPaymentPlan");
  const result = await callable(paymentPlanId);

  return result.data.plan;
}

/**
 * Create a subscription and a customer in Chargebee.
 *
 * @param {PlanId} paymentPlanId
 * @param {Customer} customer
 * @param {string} paymentTokenId
 * @param {Array<string>} couponIds
 *
 * @returns {Promise} - A promise containing a Chargebee create-subscription result object.
 */
const createSubscriptionAndCustomer = async (paymentPlanId: Chargebee.PlanId, customer: Chargebee.Customer, paymentIntentId: string, couponIds: Array<string>): Promise<Chargebee.CreateSubscriptionResult> => {
  const callable = firebase.functions().httpsCallable("chargebee-createSubscriptionAndCustomer");
  const result = await callable({ paymentPlanId, customer, paymentIntentId, couponIds });

  if (result.data.error_code) throw Error(result.data.message);

  return result.data;
}

/**
 * Get coupon object by coupon ID.
 *
 * @param {string} couponId
 *
 * @returns {Promise} - A promise containing a Chargebee coupon result object.
 */
const getCoupon = async (couponId: string): Promise<Chargebee.Coupon | null> => {
  const callable = firebase.functions().httpsCallable("chargebee-getCoupon");
  const result = await callable(couponId);

  return result.data.coupon;
}

/**
 * Get payment estimate for the payment plan ID with potential discounts.
 *
 * @param {string} paymentPlanId - payment plan ID
 * @param {Array<string>} couponIds - coupon IDs
 *
 * @returns {Promise} - A promise containing a Chargebee estimate result object.
 */
const getEstimate = async (paymentPlanId: string, couponIds: Array<string>): Promise<Chargebee.Estimate> => {
  const callable = firebase.functions().httpsCallable("chargebee-getEstimate");
  const result = await callable({ paymentPlanId, couponIds });

  return result.data.estimate;
}

/**
 * Get payment intent for 3Ds authorization.
 *
 * @param {number} amount - amount to be authorized.
 *
 * @returns {Promise} - A promise containing a Chargebee payment intent result object.
 */
const createPaymentIntent = async (amount: number): Promise<Chargebee.PaymentIntent> => {
  const callable = firebase.functions().httpsCallable("chargebee-createPaymentIntent");
  const result = await callable(amount);

  return result.data.payment_intent;
}

export default {
  getSubscription,
  getCustomerSubscription,
  createChargebeePortalSession,
  getHostedCheckoutPage,
  getPaymentPlan,
  createSubscriptionAndCustomer,
  getCoupon,
  getEstimate,
  createPaymentIntent,
}
