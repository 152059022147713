/**
 * @fileoverview The primary responsibility of this module is to enrich and sanitize the
 * parcel feature data with zoning features data and opportunity features data.
 */

import { ParcelProperty } from "./ParcelProperty";
import { MapStyleProperties } from "../mapbox/mapStyleProperties";
import { GeoJSON } from "geojson";
import sanitizer from "./sanitizer";
import turf from "../turf";
import unitConversions from "../../utils/unitConversions";
import Unit from "../../types/Unit";

interface ParcelPopulatorFeatures {
  parcelFeature: GeoJSON;
  zoningFeature?: GeoJSON;
  opportunityZoneFeature?: GeoJSON;
  demographicsFeature?: GeoJSON;
}

/**
 * Enrich parcel feature properties with data from available features.
 */
const populateParcelProperties = (features: ParcelPopulatorFeatures) => {
  let enrichedParcelFeature = {
    ...features.parcelFeature,
    properties: {
      ...getPropertiesFromParcelFeature(features.parcelFeature),
      ...getPropertiesFromZoningFeature(features.zoningFeature),
      ...getPropertiesFromDemographicsFeature(features.demographicsFeature),

      [ParcelProperty.AreaComputed]: turf.area(features.parcelFeature as any),
      [ParcelProperty.IsInOpportunityZone]: Boolean(features.opportunityZoneFeature),
      [ParcelProperty.IsAnAssembly]: false,
    }
  };

  return enrichedParcelFeature;
}

/**
 * Get properties from raw parcel feature.
 */
const getPropertiesFromParcelFeature = (rawParcelFeature) => {
  if (!rawParcelFeature || !rawParcelFeature.properties) return {};
  const rawParcelFeatureProperties = rawParcelFeature.properties;

  const areaPublishedInSquareFeet = sanitizer.forNumber(rawParcelFeatureProperties[MapStyleProperties.RawParcelFieldId.AreaPublished]);
  const areaPublishedInSquareMeters = unitConversions.convert(areaPublishedInSquareFeet, Unit.Type.SquareFeet, Unit.Type.SquareMeters);

  const existingStructureAreaInSquareFeet = sanitizer.forNumber(rawParcelFeatureProperties[MapStyleProperties.RawParcelFieldId.ExistingStructureArea]);
  const existingStructureAreaInSquareMeters = unitConversions.convert(existingStructureAreaInSquareFeet, Unit.Type.SquareFeet, Unit.Type.SquareMeters);

  return {
    [ParcelProperty.Id]: sanitizer.forLowerCaseString(rawParcelFeatureProperties[MapStyleProperties.RawParcelFieldId.Id]),
    [ParcelProperty.Address]: sanitizer.forString(rawParcelFeatureProperties[MapStyleProperties.RawParcelFieldId.Address]),
    [ParcelProperty.City]: sanitizer.forString(rawParcelFeatureProperties[MapStyleProperties.RawParcelFieldId.City]),
    [ParcelProperty.State]: sanitizer.forString(rawParcelFeatureProperties[MapStyleProperties.RawParcelFieldId.State]),
    [ParcelProperty.ZipCode]: sanitizer.forNumber(rawParcelFeatureProperties[MapStyleProperties.RawParcelFieldId.ZipCode]),
    [ParcelProperty.AreaPublished]: areaPublishedInSquareMeters,
    [ParcelProperty.PurchasePrice]: sanitizer.forNumber(rawParcelFeatureProperties[MapStyleProperties.RawParcelFieldId.PurchasePrice]),
    [ParcelProperty.ExistingStructureArea]: existingStructureAreaInSquareMeters,
    [ParcelProperty.ExistingStructureYearBuilt]: sanitizer.forExistingStructureYearBuilt(rawParcelFeatureProperties[MapStyleProperties.RawParcelFieldId.ExistingStructureYearBuilt]),
    [ParcelProperty.BasicStratum]: sanitizer.forString(rawParcelFeatureProperties[MapStyleProperties.RawParcelFieldId.BasicStratum]),
    [ParcelProperty.LandUseCode]: sanitizer.forString(rawParcelFeatureProperties[MapStyleProperties.RawParcelFieldId.LandUseCode]),
    [ParcelProperty.PublicLand]: sanitizer.forString(rawParcelFeatureProperties[MapStyleProperties.RawParcelFieldId.PublicLand]),
    [ParcelProperty.ParcelId]: sanitizer.forString(rawParcelFeatureProperties[MapStyleProperties.RawParcelFieldId.ParcelId]),
    [ParcelProperty.NumberOfResidentialUnits]: sanitizer.forNumber(rawParcelFeatureProperties[MapStyleProperties.RawParcelFieldId.NumberOfResidentialUnits]),
    [ParcelProperty.NumberOfBuildings]: sanitizer.forNumber(rawParcelFeatureProperties[MapStyleProperties.RawParcelFieldId.NumberOfBuildings]),
    [ParcelProperty.ConstructionClass]: sanitizer.forString(rawParcelFeatureProperties[MapStyleProperties.RawParcelFieldId.ConstructionClass]),
    [ParcelProperty.ImprovementQuality]: sanitizer.forString(rawParcelFeatureProperties[MapStyleProperties.RawParcelFieldId.ImprovementQuality]),
    [ParcelProperty.LandValue]: sanitizer.forNumber(rawParcelFeatureProperties[MapStyleProperties.RawParcelFieldId.LandValue]),
    [ParcelProperty.SalePrice]: sanitizer.forNumber(rawParcelFeatureProperties[MapStyleProperties.RawParcelFieldId.SalePrice]),
    [ParcelProperty.SaleYear]: sanitizer.forNumber(rawParcelFeatureProperties[MapStyleProperties.RawParcelFieldId.SaleYear]),
    [ParcelProperty.SaleMonth]: sanitizer.forString(rawParcelFeatureProperties[MapStyleProperties.RawParcelFieldId.SaleMonth]),
    [ParcelProperty.MultiParcelSale]: sanitizer.forString(rawParcelFeatureProperties[MapStyleProperties.RawParcelFieldId.MultiParcelSale]),
    [ParcelProperty.OwnerName]: sanitizer.forString(rawParcelFeatureProperties[MapStyleProperties.RawParcelFieldId.OwnerName]),
    [ParcelProperty.OwnerAddress]: sanitizer.forString(rawParcelFeatureProperties[MapStyleProperties.RawParcelFieldId.OwnerAddress]),
    [ParcelProperty.OwnerCity]: sanitizer.forString(rawParcelFeatureProperties[MapStyleProperties.RawParcelFieldId.OwnerCity]),
    [ParcelProperty.OwnerState]: sanitizer.forString(rawParcelFeatureProperties[MapStyleProperties.RawParcelFieldId.OwnerState]),
    [ParcelProperty.OwnerZipCode]: sanitizer.forNumber(rawParcelFeatureProperties[MapStyleProperties.RawParcelFieldId.OwnerZipCode]),
  }
}

/**
 * Get properties from raw zoning feature.
 */
const getPropertiesFromZoningFeature = (rawZoningFeature) => {
  if (!rawZoningFeature || !rawZoningFeature.properties) return {};
  const rawZoningFeatureProperties = rawZoningFeature.properties;

  const allowedBuildableAreaInSquareFeet = sanitizer.forNumber(rawZoningFeatureProperties[MapStyleProperties.RawZoningFieldId.AllowedBuildableArea]);
  const allowedBuildableAreaInSquareMeters = unitConversions.convert(allowedBuildableAreaInSquareFeet, Unit.Type.SquareFeet, Unit.Type.SquareMeters);

  return {
    [ParcelProperty.AllowedBuildableArea]: allowedBuildableAreaInSquareMeters,
    [ParcelProperty.ZoneId]: sanitizer.forLowerCaseString(rawZoningFeatureProperties[MapStyleProperties.RawZoningFieldId.ZoningCode]),
    [ParcelProperty.AllowedUses]: sanitizer.forAllowedUses(rawZoningFeatureProperties[MapStyleProperties.RawZoningFieldId.AllowedUses]),
    [ParcelProperty.ArchitectName]: sanitizer.forString(rawZoningFeatureProperties[MapStyleProperties.RawZoningFieldId.ArchitectFirm]),
    [ParcelProperty.ArchitectUrl]: sanitizer.forUrl(rawZoningFeatureProperties[MapStyleProperties.RawZoningFieldId.ArchitectUrl]),
    [ParcelProperty.BuildingHeight]: sanitizer.forLowerCaseString(rawZoningFeatureProperties[MapStyleProperties.RawZoningFieldId.BuildingHeight]),
    [ParcelProperty.LotCoverage]: sanitizer.forLowerCaseString(rawZoningFeatureProperties[MapStyleProperties.RawZoningFieldId.LotCoverage]),
    [ParcelProperty.FloorAreaRatio]: sanitizer.forLowerCaseString(rawZoningFeatureProperties[MapStyleProperties.RawZoningFieldId.FloorAreaRatio]),
    [ParcelProperty.NumberOfFloors]: sanitizer.forLowerCaseString(rawZoningFeatureProperties[MapStyleProperties.RawZoningFieldId.NumberOfFloors]),
    [ParcelProperty.NumberOfUnitsAllowed]: sanitizer.forLowerCaseString(rawZoningFeatureProperties[MapStyleProperties.RawZoningFieldId.NumberOfUnitsAllowed]),
    [ParcelProperty.AllowedDetailedUses]: sanitizer.forLowerCaseString(rawZoningFeatureProperties[MapStyleProperties.RawZoningFieldId.AllowedDetailedUses]),
    [ParcelProperty.SetbacksPrimary]: sanitizer.forLowerCaseString(rawZoningFeatureProperties[MapStyleProperties.RawZoningFieldId.PrimarySetback]),
    [ParcelProperty.SetbacksSideStreet]: sanitizer.forLowerCaseString(rawZoningFeatureProperties[MapStyleProperties.RawZoningFieldId.SideStreetSetback]),
    [ParcelProperty.SetbacksSideInterior]: sanitizer.forLowerCaseString(rawZoningFeatureProperties[MapStyleProperties.RawZoningFieldId.InteriorSideSetback]),
    [ParcelProperty.SetbacksRear]: sanitizer.forLowerCaseString(rawZoningFeatureProperties[MapStyleProperties.RawZoningFieldId.RearSetback]),
    [ParcelProperty.LivingUnitDensityNumerator]: sanitizer.forLowerCaseString(rawZoningFeatureProperties[MapStyleProperties.RawZoningFieldId.LivingUnitDensityNumerator]),
    [ParcelProperty.LivingUnitDensityDenominator]: sanitizer.forLowerCaseString(rawZoningFeatureProperties[MapStyleProperties.RawZoningFieldId.LivingUnitDensityDenominator]),
    [ParcelProperty.LivingUnitDensityUnitOfMeasure]: sanitizer.forLowerCaseString(rawZoningFeatureProperties[MapStyleProperties.RawZoningFieldId.LivingUnitDensityUnitOfMeasure]),
    [ParcelProperty.HotelUnitDensityNumerator]: sanitizer.forLowerCaseString(rawZoningFeatureProperties[MapStyleProperties.RawZoningFieldId.HotelUnitDensityNumerator]),
    [ParcelProperty.HotelUnitDensityDenominator]: sanitizer.forLowerCaseString(rawZoningFeatureProperties[MapStyleProperties.RawZoningFieldId.HotelUnitDensityDenominator]),
    [ParcelProperty.HotelUnitDensityUnitOfMeasure]: sanitizer.forLowerCaseString(rawZoningFeatureProperties[MapStyleProperties.RawZoningFieldId.HotelUnitDensityUnitOfMeasure]),
    [ParcelProperty.ParkingSpacesResidentialNumerator]: sanitizer.forLowerCaseString(rawZoningFeatureProperties[MapStyleProperties.RawZoningFieldId.ParkingSpacesResidentialNumerator]),
    [ParcelProperty.ParkingSpacesResidentialDenominator]: sanitizer.forLowerCaseString(rawZoningFeatureProperties[MapStyleProperties.RawZoningFieldId.ParkingSpacesResidentialDenominator]),
    [ParcelProperty.ParkingSpacesResidentialUnitOfMeasure]: sanitizer.forLowerCaseString(rawZoningFeatureProperties[MapStyleProperties.RawZoningFieldId.ParkingSpacesResidentialUnitOfMeasure]),
    [ParcelProperty.ParkingSpacesSingleFamilyNumerator]: sanitizer.forLowerCaseString(rawZoningFeatureProperties[MapStyleProperties.RawZoningFieldId.ParkingSpacesSingleFamilyNumerator]),
    [ParcelProperty.ParkingSpacesSingleFamilyDenominator]: sanitizer.forLowerCaseString(rawZoningFeatureProperties[MapStyleProperties.RawZoningFieldId.ParkingSpacesSingleFamilyDenominator]),
    [ParcelProperty.ParkingSpacesSingleFamilyUnitOfMeasure]: sanitizer.forLowerCaseString(rawZoningFeatureProperties[MapStyleProperties.RawZoningFieldId.ParkingSpacesSingleFamilyUnitOfMeasure]),
    [ParcelProperty.ParkingSpacesHotelNumerator]: sanitizer.forLowerCaseString(rawZoningFeatureProperties[MapStyleProperties.RawZoningFieldId.ParkingSpacesHotelNumerator]),
    [ParcelProperty.ParkingSpacesHotelDenominator]: sanitizer.forLowerCaseString(rawZoningFeatureProperties[MapStyleProperties.RawZoningFieldId.ParkingSpacesHotelDenominator]),
    [ParcelProperty.ParkingSpacesHotelUnitOfMeasure]: sanitizer.forLowerCaseString(rawZoningFeatureProperties[MapStyleProperties.RawZoningFieldId.ParkingSpacesHotelUnitOfMeasure]),
    [ParcelProperty.ParkingSpacesOfficeNumerator]: sanitizer.forLowerCaseString(rawZoningFeatureProperties[MapStyleProperties.RawZoningFieldId.ParkingSpacesOfficeNumerator]),
    [ParcelProperty.ParkingSpacesOfficeDenominator]: sanitizer.forLowerCaseString(rawZoningFeatureProperties[MapStyleProperties.RawZoningFieldId.ParkingSpacesOfficeDenominator]),
    [ParcelProperty.ParkingSpacesOfficeUnitOfMeasure]: sanitizer.forLowerCaseString(rawZoningFeatureProperties[MapStyleProperties.RawZoningFieldId.ParkingSpacesOfficeUnitOfMeasure]),
    [ParcelProperty.ParkingSpacesRetailNumerator]: sanitizer.forLowerCaseString(rawZoningFeatureProperties[MapStyleProperties.RawZoningFieldId.ParkingSpacesRetailNumerator]),
    [ParcelProperty.ParkingSpacesRetailDenominator]: sanitizer.forLowerCaseString(rawZoningFeatureProperties[MapStyleProperties.RawZoningFieldId.ParkingSpacesRetailDenominator]),
    [ParcelProperty.ParkingSpacesRetailUnitOfMeasure]: sanitizer.forLowerCaseString(rawZoningFeatureProperties[MapStyleProperties.RawZoningFieldId.ParkingSpacesRetailUnitOfMeasure]),
    [ParcelProperty.ParkingSpacesIndustrialNumerator]: sanitizer.forLowerCaseString(rawZoningFeatureProperties[MapStyleProperties.RawZoningFieldId.ParkingSpacesIndustrialNumerator]),
    [ParcelProperty.ParkingSpacesIndustrialDenominator]: sanitizer.forLowerCaseString(rawZoningFeatureProperties[MapStyleProperties.RawZoningFieldId.ParkingSpacesIndustrialDenominator]),
    [ParcelProperty.ParkingSpacesIndustrialUnitOfMeasure]: sanitizer.forLowerCaseString(rawZoningFeatureProperties[MapStyleProperties.RawZoningFieldId.ParkingSpacesIndustrialUnitOfMeasure]),
  }
}

/**
 * Get properties from raw demographics feature.
 */
const getPropertiesFromDemographicsFeature = (demographicsFeature) => {
  if (!demographicsFeature || !demographicsFeature.properties) return {};
  const rawDemographicsFeatureProperties = demographicsFeature.properties;
  const sanitizedGdp = sanitizer.forNumber(rawDemographicsFeatureProperties[MapStyleProperties.RawDemographicsFieldId.Gdp]);

  return {
    [ParcelProperty.MedianIncomeTotal]: sanitizer.forNumber(rawDemographicsFeatureProperties[MapStyleProperties.RawDemographicsFieldId.MedianIncomeTotal]),
    [ParcelProperty.GrossMedianRent]: sanitizer.forNumber(rawDemographicsFeatureProperties[MapStyleProperties.RawDemographicsFieldId.GrossMedianRent]),
    [ParcelProperty.PopulationDensity]: sanitizer.forNumber(rawDemographicsFeatureProperties[MapStyleProperties.RawDemographicsFieldId.PopulationDensity]),
    [ParcelProperty.Gdp]: sanitizedGdp ? sanitizedGdp / 1000000 : sanitizedGdp,
  }
}

export default populateParcelProperties;
