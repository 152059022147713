import React from "react";
import { Link } from "react-router-dom";
import analytics from "../../../../utils/analytics";
import authentication, { AuthenticationProviderId } from "../../../../utils/authentication";
import { Path } from "../../../../types/Path";
import FederatedAuthenticationProviders from "../../FederatedAuthenticationProviders";
import companyInfo from "../../../../utils/companyInfo";
import Authentication from "../../../../types/Authentication";
import AuthenticationError from "../../../../errors/AuthenticationError";

interface State {
  email: string;
  password: string;
  errorMessage: string;
  termsOfServiceAccepted: boolean;
};

interface OwnProps {
  closePopup(): void;
  onLogIn?(): void;
}

type Props = OwnProps;

class LogIn extends React.PureComponent<Props, State>  {
  emailInput: any;

  constructor(props) {
    super(props);

    this.state = {
      email: "",
      password: "",
      errorMessage: "",
      termsOfServiceAccepted: false,
    };
  }

  /**
   * Throws error when the form required fields are not all filled or the terms of service is not checked.
   */
  verifyRequiredFields = (state) => {
    let errorCode;
    if (!state.email || !state.password) {
      errorCode = Authentication.CustomErrorCode.MissingFields;
    } else if (!state.termsOfServiceAccepted) {
      errorCode = Authentication.CustomErrorCode.TermsOfServiceNotAccepted;
    }

    if (errorCode) throw new AuthenticationError(errorCode);
  }

  /**
   * Update the error message to the given value.
   */
  setErrorMessage = (errorMessage: string) => {
    this.setState({ errorMessage: errorMessage });
  }

  /**
   * This function sets the state for the required fields in this form.
   */
  updateState = (newState) => {
    newState.errorMessage = "";
    this.setState(newState);
  }

  /**
   * Updates the state based on whether the user was able to sign in or not.
   */
  handleFormSubmit = async (event) => {
    event.preventDefault();

    try {
      this.verifyRequiredFields(this.state);

      await authentication.signInWithEmailAndPassword(this.state.email, this.state.password);
      analytics.trackLogIn(AuthenticationProviderId.EmailAndPassword);
      if (this.props.onLogIn) this.props.onLogIn();
      this.props.closePopup();
    } catch (error) {
      this.setErrorMessage(authentication.getErrorMessage(error.code));
      analytics.trackAuthenticationError(error, AuthenticationProviderId.EmailAndPassword, "Log In");
    }
  }

  /**
   * Updates the state when the email changes.
   */
  handleEmailChange = (event) => {
    this.updateState({ email: event.target.value });
  }

  /**
   * Updates the state when the password changes.
   */
  handlePasswordChange = (event) => {
    this.updateState({ password: event.target.value });
  }

  /**
   * Updates the state when the user clicks the terms of service checkbox.
   */
  handleTermsOfServiceAcceptedChange = () => {
    this.updateState({ termsOfServiceAccepted: !this.state.termsOfServiceAccepted });
  }

  /**
   * Setting the focus on the email input.
   */
  componentDidMount() {
    if (this.emailInput) this.emailInput.focus();
  }

  render() {
    return (
      <div className="component--log-in">
        <div className="header">Welcome Back</div>
        <form onSubmit={this.handleFormSubmit}>
          <div className="input-section">
            <div className="input-field">
              <input
                type="email"
                name="email"
                placeholder="Email address"
                autoComplete="username"
                ref={(input) => this.emailInput = input}
                onChange={this.handleEmailChange}
              />
            </div>
          </div>

          <div className="input-section">
            <div className="input-field">
              <input
                type="password"
                name="password"
                placeholder="Password"
                autoComplete="current-password"
                onChange={this.handlePasswordChange}
              />
            </div>
          </div>

          <div className="error-container">
            <p>{this.state.errorMessage}</p>
          </div>

          <div className="tos-agreement">
            <input
              type="checkbox"
              className="tos"
              name="tos"
              onChange={this.handleTermsOfServiceAcceptedChange}
            />
            <div className="text-box">
              I agree to the <a href={companyInfo.TERMS_OF_SERVICE_URL} className="underlined-link" target="_blank" rel="noopener noreferrer">Terms of Service</a>
            </div>
            <Link className="forgot-password" to={Path.ForgotPassword}>Forgot Password</Link>
          </div>
          <button type="submit" className="submit">Log In</button>
        </form>

        <FederatedAuthenticationProviders onError={this.setErrorMessage} termsOfServiceAccepted={this.state.termsOfServiceAccepted} />
      </div>
    );
  }
}

export default LogIn;
