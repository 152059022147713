import React from "react";
import { connect } from "react-redux";
import { developmentSelectors } from "../../../../../state/development";
import { Panel } from "../../../../../types/uiElements";
import ConfigurationHandler from "../../../../sharedComponents/ConfigurationHandler";
import PanelHeader from "../sharedComponents/PanelHeader";
import SliderBox from "../sharedComponents/SliderBox";
import PanelSectionTitle from "../sharedComponents/PanelSectionTitle";
import config from "./config";

const mapStateToProps = (state) => {
  return {
    unitSystem: developmentSelectors.getUnitSystem(state),
    values: developmentSelectors.getValues(state),
  }
}

interface OwnProps {
  panel: Panel;
  onPanelClose(panel: Panel);
  onPanelPin(panel: Panel);
}

type StateProps = ReturnType<typeof mapStateToProps>;
type Props = OwnProps & StateProps;

class OfficePanel extends React.Component<Props, any>  {
  /**
   * Render Office Gross Buildable section.
   */
  renderGrossBuildableSection = () => {
    return (
      <>
        <ConfigurationHandler config={config.officeGrossBuildableArea} component={PanelSectionTitle} unitSystem={this.props.unitSystem} />
        <div>
          <ConfigurationHandler config={config.officeNetUsableAreaToggleable} component={SliderBox} unitSystem={this.props.unitSystem} />
          {
            this.props.values.officeGrossBuildableArea
              ? <ConfigurationHandler config={config.officeCommonAreaFactor} component={SliderBox} unitSystem={this.props.unitSystem} />
              : null
          }
        </div>
      </>
    );
  }

  /**
   * Render Office Income section.
   */
  renderIncomeSection = () => {
    if (this.props.values.officeGrossBuildableArea === 0) return null;

    return (
      <>
        <ConfigurationHandler config={config.officeYearOnePotentialGrossIncome} component={PanelSectionTitle} unitSystem={this.props.unitSystem} />
        <div>
          <ConfigurationHandler config={config.officeYearOneRentPerArea} component={SliderBox} unitSystem={this.props.unitSystem} />
          <ConfigurationHandler config={config.officeYearOneExpenseReimbursementFeePerArea} component={SliderBox} unitSystem={this.props.unitSystem} />
        </div>
      </>
    );
  }

  /**
   * Render Office NOI section.
   */
  renderNOISection = () => {
    if (this.props.values.officeGrossBuildableArea === 0) return null;

    return (
      <>
        <ConfigurationHandler config={config.officeAnnualNetOperatingIncomeForBackOfEnvelope} component={PanelSectionTitle} unitSystem={this.props.unitSystem} />
        <div>
          <ConfigurationHandler config={config.officeStabilizedVacancyPercentage} component={SliderBox} unitSystem={this.props.unitSystem} />
          <ConfigurationHandler config={config.officeYearOneOperatingExpensePerArea} component={SliderBox} unitSystem={this.props.unitSystem} />
        </div>
      </>
    );
  }

  render() {
    return (
      <div className="panel">
        <PanelHeader
          isPinned={this.props.panel.isPinned}
          panel={this.props.panel}
          onPanelClose={this.props.onPanelClose}
          onPanelPin={this.props.onPanelPin}
        />
        <div className="panel-inner-wrapper">
          {this.renderGrossBuildableSection()}
          {this.renderIncomeSection()}
          {this.renderNOISection()}
        </div>
      </div>
    );
  }
}

export default connect(
  mapStateToProps
)(OfficePanel);
