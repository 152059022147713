import { VariableId } from "../../../types/VariableId";

const increments = {
  [VariableId.HeightOfGroundFloor]: {
    "increment": 0.5
  },
  [VariableId.HeightOfTypicalFloor]: {
    "increment": 0.5
  },
  [VariableId.SetbackA]: {
    "increment": 0.5
  },
  [VariableId.SetbackB]: {
    "increment": 0.5
  },
  [VariableId.SetbackC]: {
    "increment": 0.5
  },
  [VariableId.SetbackD]: {
    "increment": 0.5
  },
  [VariableId.ParcelPurchasePrice]: {
    "increment": 1000
  },
  [VariableId.ExistingStructureArea]: {
    "increment": 1
  },
  [VariableId.ExistingStructureDemolitionCostPerArea]: {
    "increment": 1
  },
  [VariableId.SoftCostPercentage]: {
    "increment": 0.01
  },
  [VariableId.ContingencyCostPercentage]: {
    "increment": 0.01
  },
  [VariableId.CondoHardCostPerArea]: {
    "increment": 5
  },
  [VariableId.MultifamilyHardCostPerArea]: {
    "increment": 5
  },
  [VariableId.HotelHardCostPerArea]: {
    "increment": 5
  },
  [VariableId.OfficeHardCostPerArea]: {
    "increment": 5
  },
  [VariableId.RetailHardCostPerArea]: {
    "increment": 5
  },
  [VariableId.IndustrialHardCostPerArea]: {
    "increment": 5
  },
  [VariableId.ParkingHardCostPerArea]: {
    "increment": 5
  },
  [VariableId.ParkingReductionFactor]: {
    "increment": 0.01
  },
  [VariableId.ParkingReductionFeePerSpace]: {
    "increment": 500
  },
  [VariableId.ParkingSpaceArea]: {
    "increment": 10
  },
  [VariableId.ParkingRatioCondo]: {
    "increment": 0.5
  },
  [VariableId.ParkingRatioMultifamily]: {
    "increment": 0.5
  },
  [VariableId.ParkingRatioHotel]: {
    "increment": 0.5
  },
  [VariableId.ParkingRatioOffice]: {
    "increment": 0.5
  },
  [VariableId.ParkingRatioRetail]: {
    "increment": 0.5
  },
  [VariableId.ParkingRatioIndustrial]: {
    "increment": 0.5
  },
  [VariableId.CondoMicrounitQuantityToggleable]: {
    "increment": 1
  },
  [VariableId.CondoStudioQuantityToggleable]: {
    "increment": 1
  },
  [VariableId.CondoOneBedQuantityToggleable]: {
    "increment": 1
  },
  [VariableId.CondoTwoBedQuantityToggleable]: {
    "increment": 1
  },
  [VariableId.CondoThreeBedQuantityToggleable]: {
    "increment": 1
  },
  [VariableId.CondoMicrounitArea]: {
    "increment": 10
  },
  [VariableId.CondoStudioArea]: {
    "increment": 10
  },
  [VariableId.CondoOneBedArea]: {
    "increment": 10
  },
  [VariableId.CondoTwoBedArea]: {
    "increment": 10
  },
  [VariableId.CondoThreeBedArea]: {
    "increment": 10
  },
  [VariableId.CondoLossFactor]: {
    "increment": 0.01
  },
  [VariableId.CondoAmenitiesAreaToggleable]: {
    "increment": 10
  },
  [VariableId.CondoSaleCommissionPercentage]: {
    "increment": 0.005
  },
  [VariableId.CondoSalePricePerMicrounit]: {
    "increment": 5000
  },
  [VariableId.CondoSalePricePerStudio]: {
    "increment": 5000
  },
  [VariableId.CondoSalePricePerOneBed]: {
    "increment": 5000
  },
  [VariableId.CondoSalePricePerTwoBed]: {
    "increment": 5000
  },
  [VariableId.CondoSalePricePerThreeBed]: {
    "increment": 5000
  },
  [VariableId.MultifamilyMicrounitQuantityToggleable]: {
    "increment": 1
  },
  [VariableId.MultifamilyStudioQuantityToggleable]: {
    "increment": 1
  },
  [VariableId.MultifamilyOneBedQuantityToggleable]: {
    "increment": 1
  },
  [VariableId.MultifamilyTwoBedQuantityToggleable]: {
    "increment": 1
  },
  [VariableId.MultifamilyThreeBedQuantityToggleable]: {
    "increment": 1
  },
  [VariableId.MultifamilyMicrounitArea]: {
    "increment": 10
  },
  [VariableId.MultifamilyStudioArea]: {
    "increment": 10
  },
  [VariableId.MultifamilyOneBedArea]: {
    "increment": 10
  },
  [VariableId.MultifamilyTwoBedArea]: {
    "increment": 10
  },
  [VariableId.MultifamilyThreeBedArea]: {
    "increment": 10
  },
  [VariableId.MultifamilyLossFactor]: {
    "increment": 0.01
  },
  [VariableId.MultifamilyAmenitiesAreaToggleable]: {
    "increment": 10
  },
  [VariableId.MultifamilyYearOneMonthlyRentPerMicrounit]: {
    "increment": 10
  },
  [VariableId.MultifamilyYearOneMonthlyRentPerStudio]: {
    "increment": 10
  },
  [VariableId.MultifamilyYearOneMonthlyRentPerOneBed]: {
    "increment": 10
  },
  [VariableId.MultifamilyYearOneMonthlyRentPerTwoBed]: {
    "increment": 10
  },
  [VariableId.MultifamilyYearOneMonthlyRentPerThreeBed]: {
    "increment": 10
  },
  [VariableId.MultifamilyStabilizedVacancyPercentage]: {
    "increment": 0.005
  },
  [VariableId.MultifamilyOperatingExpensePercentage]: {
    "increment": 0.005
  },
  [VariableId.HotelRoomQuantityToggleable]: {
    "increment": 1
  },
  [VariableId.HotelAverageRoomArea]: {
    "increment": 10
  },
  [VariableId.HotelLossFactor]: {
    "increment": 0.01
  },
  [VariableId.HotelAmenitiesAreaToggleable]: {
    "increment": 10
  },
  [VariableId.HotelStabilizedAverageDailyRate]: {
    "increment": 1
  },
  [VariableId.HotelOtherRevenuePercentage]: {
    "increment": 0.01
  },
  [VariableId.HotelStabilizedOccupancyPercentage]: {
    "increment": 0.01
  },
  [VariableId.HotelOperatingExpensePercentage]: {
    "increment": 0.01
  },
  [VariableId.OfficeNetUsableAreaToggleable]: {
    "increment": 10
  },
  [VariableId.OfficeCommonAreaFactor]: {
    "increment": 0.01
  },
  [VariableId.OfficeYearOneRentPerArea]: {
    "increment": 0.5
  },
  [VariableId.OfficeYearOneExpenseReimbursementFeePerArea]: {
    "increment": 0.5
  },
  [VariableId.OfficeStabilizedVacancyPercentage]: {
    "increment": 0.005
  },
  [VariableId.OfficeYearOneOperatingExpensePerArea]: {
    "increment": 0.5
  },
  [VariableId.RetailNetLeasableAreaToggleable]: {
    "increment": 10
  },
  [VariableId.RetailLossFactor]: {
    "increment": 0.01
  },
  [VariableId.RetailYearOneRentPerArea]: {
    "increment": 0.5
  },
  [VariableId.RetailYearOneExpenseReimbursementFeePerArea]: {
    "increment": 0.5
  },
  [VariableId.RetailStabilizedVacancyPercentage]: {
    "increment": 0.005
  },
  [VariableId.RetailYearOneOperatingExpensePerArea]: {
    "increment": 0.5
  },
  [VariableId.IndustrialNetLeasableAreaToggleable]: {
    "increment": 10
  },
  [VariableId.IndustrialLossFactor]: {
    "increment": 0.01
  },
  [VariableId.IndustrialYearOneRentPerArea]: {
    "increment": 0.5
  },
  [VariableId.IndustrialYearOneExpenseReimbursementFeePerArea]: {
    "increment": 0.5
  },
  [VariableId.IndustrialStabilizedVacancyPercentage]: {
    "increment": 0.005
  },
  [VariableId.IndustrialYearOneOperatingExpensePerArea]: {
    "increment": 0.5
  }
}

export default increments;
