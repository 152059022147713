const EPSILON = 0.000000001;

/**
 * Check if two floats are equal by checking if their difference
 * is smaller than a prefixed EPSILON.
 */
const equalWithinEpsilon = (a,b, epsilon = EPSILON) => {
  return Math.abs(a-b) < epsilon;
}

export default equalWithinEpsilon;
