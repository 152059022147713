const LOAD_START    = "thumbnails/LOAD_START";
const LOAD_SUCCESS  = "thumbnails/LOAD_SUCCESS";
const LOAD_ERROR    = "thumbnails/LOAD_ERROR";
const DELETE        = "thumbnails/DELETE";

export default {
  LOAD_START,
  LOAD_SUCCESS,
  LOAD_ERROR,
  DELETE
};
