/**
 * @fileoverview This module provides Deep Blocks company information constants.
 */

const CONTACT_EMAIL = "info@deepblocks.com";
const WEBSITE_URL = "https://www.deepblocks.com/";
const TERMS_OF_SERVICE_URL = "https://www.deepblocks.com/terms-of-service/";
const UNSUPPORTED_BROWSER_URL = "https://www.deepblocks.com/unsupported-browser/";
const SUPPORT_URL = "https://www.deepblocks.com/support";
const CITIES_URL = "https://www.deepblocks.com/cities";
const PLAN_STANDARD_URL = "https://www.deepblocks.com/standard";
const PLAN_ADVANCED_URL = "https://www.deepblocks.com/advanced";
const PLAN_PRO_URL = "https://www.deepblocks.com/pro";
const WEBINAR_URL = "https://www.deepblocks.com/webinar";
const USE_CASE_URL = "https://www.deepblocks.com/use-case";
const TUTORIALS_URL = "https://www.deepblocks.com/tutorials";
const BLOG_URL = "https://blog.deepblocks.com/";
const CONTACT_US_URL = "https://www.deepblocks.com/contact-us";
const LISTINGS_URLS = "https://www.deepblocks.com/listings";

const FACEBOOK_URL = "https://www.facebook.com/DeepBlocks-2004031763185330/";
const INSTAGRAM_URL = "https://www.instagram.com/deepblocks/";
const LINKEDIN_URL = "https://www.linkedin.com/company/deepblocks";
const TWITTER_URL = "https://twitter.com/deepblocks";

export default {
  SUPPORT_URL,
  CONTACT_EMAIL,
  WEBSITE_URL,
  FACEBOOK_URL,
  INSTAGRAM_URL,
  LINKEDIN_URL,
  TWITTER_URL,
  TERMS_OF_SERVICE_URL,
  UNSUPPORTED_BROWSER_URL,
  CITIES_URL,
  LISTINGS_URLS,
  PLAN_STANDARD_URL,
  PLAN_ADVANCED_URL,
  PLAN_PRO_URL,
  WEBINAR_URL,
  USE_CASE_URL,
  TUTORIALS_URL,
  BLOG_URL,
  CONTACT_US_URL,
};
