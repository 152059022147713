import React, {PureComponent} from "react";
import { connect } from "react-redux";
import { developmentSelectors } from "../../../../../../state/development";
import { BuildingUse } from "../../../../../../types/BuildingUse";
import { getRentalUseText } from "../utils/backOfEnvelopeHelper";
import Tab from "./Tab";

interface Props {
  currentTab: number;
  onTabClick(tab: number): void;
  toggles: object;
}

class BackOfEnvelopeTabs extends PureComponent<Props, {}> {
  render() {
    return (
      <div className="component--back-of-envelope-tabs tab-bar-wrapper">
        <Tab title="Project Summary" currentTab={this.props.currentTab} index={0} onClick={ this.props.onTabClick } />
        <Tab title="Assumptions" currentTab={this.props.currentTab} index={1} onClick={ this.props.onTabClick } />
        <Tab title="Condo" currentTab={this.props.currentTab} index={2} onClick={ this.props.onTabClick } usageGroup={BuildingUse.Condo} />
        <Tab title={getRentalUseText(this.props.toggles)} currentTab={this.props.currentTab} index={3} onClick={ this.props.onTabClick } usageGroup={BuildingUse.RentalUses} />
        <Tab title="Hotel" currentTab={this.props.currentTab} index={4} onClick={ this.props.onTabClick } usageGroup={BuildingUse.Hotel} />
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    toggles: developmentSelectors.getBuildingUsageToggles(state)
  }
};

export default connect(
  mapStateToProps
)(BackOfEnvelopeTabs);
