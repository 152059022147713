import React from "react";
import wrapComponentWithPopup from "../../../../sharedComponents/wrapComponentWithPopup";
import { PopupProps } from "../../../../sharedComponents/wrapComponentWithPopup/wrapComponentWithPopup";
import RedirectButton from "../../../../sharedComponents/RedirectButton";
import { Path } from "../../../../../types/Path";

interface OwnProps {
  onClickSubscribe(): void;
}

type Props = PopupProps & OwnProps;

class RedirectWarning extends React.PureComponent<Props, {}>  {
  render() {
    return (
      <div className="component--redirect-warning-popup">
        <div className="top">
          <div className="text">The current project will not be saved and all the changes will be lost. This action cannot be undone. <br /> Would you like to continue?</div>
          <div className="top-separator" />
          <div className="buttons-container">
            <button className="cancel" onClick={this.props.closePopup}>cancel</button>
            <RedirectButton path={Path.NewProject} text="Continue" classes="continue" />
          </div>
        </div>

        <div className="separator"><div className="circle">Or</div></div>

        <div className="subscribe-message">You can subscribe and have all your projects in one place saved automatically.</div>
        <RedirectButton path={Path.Subscribe} text="Subscribe" classes="subscribe" onClick={this.props.onClickSubscribe} />
      </div>
    );
  }
}

export default wrapComponentWithPopup(RedirectWarning);
