import React from "react";
import ReactTooltip from "react-tooltip";
import { RouteComponentProps } from "react-router";
import { withRouter } from "react-router-dom";
import config from "./config";
import { Path } from "../../../types/Path";
import RedirectButton from "../RedirectButton";
import database from "../../../utils/database/database";

type Props = RouteComponentProps;

export class Tooltip extends React.PureComponent<Props, {}> {
  tooltipClickReference: any;
  tooltipHoverReference: any;

  /**
   * Hide tooltips when url changes
   */
  componentDidUpdate(previousProps: Props) {
    if (previousProps.location.pathname !== this.props.location.pathname) {
      this.tooltipClickReference.globalHide();
      this.tooltipHoverReference.globalHide();
    }
  }

  /**
   * This current implementation uses two HACKs to force
   * the tooltip to hide immediately even when the cursor is hovering on top of the element at the time of hiding.
   * This method cannot be used when hiding on page change like on `componentDidUpdate` because it
   * causes an unknown error.
   */
  hideTooltip = (element) => {
    // HACK: This is a hack due to the way "ReactTooltip" is currently working.
    // see: https://github.com/wwayne/react-tooltip/issues/449
    element.tooltipRef = null;
    // HACK: This is a direct call to the hideToolTip to bypass the
    // delayHide timeout and hide immediately. This is specially needed for the
    // hover tooltip. For an unknown reason, the hover tooltip does not close
    // when the mouse is hovered over it unless the direct `hideTooltip` method is called in
    // conjunction with the previous HACK.
    element.hideTooltip({}, false, { isScroll: true });
  }

  /**
   * Save current project before redirecting.
   */
  saveCurrentProject = (element) => {
    database.saveCurrentProject();
    this.hideTooltip(element);
  }

  /**
   * Default tooltip content.
   */
  withConfig = (tooltipType, element) => {
    if (!config[tooltipType]) return null;
    const { className, thinHeader, boldHeader, contentText, buttonText } = config[tooltipType];

    return (
      <div className="component--tooltip">
        <div className={`image ${className}`} />
        <div className="header thin">{thinHeader}</div>
        <div className="header bold">{boldHeader}</div>
        <div className="content">{contentText}</div>
        <RedirectButton onClick={() => this.saveCurrentProject(element)} path={Path.Subscribe} text={buttonText} />
        <p className="not-now" onClick={() => this.hideTooltip(element)}>not now</p>
      </div>
    );
  }

  render() {
    return (
      <>
        <ReactTooltip
          bodyMode
          clickable
          isCapture
          effect="solid"
          place="right"
          event="click"
          globalEventOff="click"
          type="light"
          className="component-react-tooltip"
          ref={(reference) => this.tooltipClickReference = reference}
          getContent={(tooltipType) => this.withConfig(tooltipType, this.tooltipClickReference)}
        />
        <ReactTooltip
          bodyMode
          clickable
          delayHide={100}
          effect="solid"
          place="right"
          id="hover"
          type="light"
          className="component-react-tooltip"
          ref={(reference) => this.tooltipHoverReference = reference}
          getContent={(tooltipType) => this.withConfig(tooltipType, this.tooltipHoverReference)}
        />
      </>
    );
  }
}

export default withRouter(Tooltip);
