import React from "react";
import { connect } from "react-redux";
import { subscriptionActions, subscriptionSelectors } from "../../../../../state/subscription";
import chargebeeInstance from "../../../../../utils/chargebee/instance";
import analytics from "../../../../../utils/analytics";
import { Path } from "../../../../../types/Path";
import RedirectComponent from "../../../../sharedComponents/RedirectComponent";

const mapStateToProps = (state) => {
  return {
    subscriptionStatus: subscriptionSelectors.getStatus(state),
    subscriptionId: subscriptionSelectors.getId(state)
  }
}

const mapDispatchToProps = {
  refreshSubscription: subscriptionActions.loadStart
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;
type Props = StateProps & DispatchProps;

class AccountLinks extends React.PureComponent<Props, {}> {
  /**
   * Close the chargebee portal before un-mounting.
   */
  componentWillUnmount() {
    chargebeeInstance().closeAll();
  }

  /**
   * Open chargebee portal.
   */
  openChargebeePortal = () => {
    chargebeeInstance().createChargebeePortal().open({
      close: () => {
        chargebeeInstance().logout();
        this.props.subscriptionId && this.props.refreshSubscription(this.props.subscriptionId);
      },
      subscriptionChanged: (data) => {
        analytics.trackChangeSubscription();
      },
      subscriptionCancelled: (data) => {
        analytics.trackCancelSubscription();
      }
    });
  }

  /**
   * Handle click on the billing section.
   */
  handleBillingClick = () => {
    this.openChargebeePortal();
  }

  render() {
    return (
      <div className="component--account-links">
        <div className="header">ACCOUNT</div>

        <div className="links-container">
          {/* Billing */}
          <div className="side-bar-link" onClick={this.handleBillingClick}>
            <span className="icon icon-billing" />
            Billing
          </div>
          {/* Plans */}
          <RedirectComponent path={Path.Subscribe} >
            {({ redirect }) => (
              <div className="side-bar-link" onClick={redirect}>
                <span className="icon icon-subscribe" />
                Subscription
              </div>
            )}
          </RedirectComponent>
        </div>
      </div>
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AccountLinks);
