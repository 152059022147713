import React from "react";
import Navbar from "../../sharedComponents/Navbar";
import ForgotPasswordEmailSent from "./ForgotPasswordEmailSent";
import ForgotPasswordForm from "./ForgotPasswordForm";
import analytics from "../../../utils/analytics";
import { Path } from "../../../types/Path";

interface State {
  emailIsSent: boolean;
};

export default class ForgotPasswordPage extends React.PureComponent<{}, State> {
  constructor(props){
    super(props);

    this.state = {
      emailIsSent: false,
    }

    analytics.trackPageview(Path.ForgotPassword);
  }

  setEmailIsSent = () => {
    this.setState({ emailIsSent: true });
  }

  render() {
    return (
      <div className="component--forgot-password-page">
        <Navbar />
        <div className="content-wrapper">
          {this.state.emailIsSent ? <ForgotPasswordEmailSent /> : <ForgotPasswordForm onEmailSent={this.setEmailIsSent} />}
        </div>
      </div>
    );
  }
}
