/**
 * This module provides general color services.
 */

/**
 * The list of colors available for programmatic coloring.
 * Please note that the names of the colors are generated using
 * the following tool: http://chir.ag/projects/name-that-color/
 */
const colorPalette = {
  "white": "#FFFFFF",
  "mine-shaft": "#333333",
  "half-baked": "#87C5D7",
  "de-york": "#6FCE81",
  "amethyst": "#C161C6",
  "illusion": "#F291C1",
  "jaffa": "#ED7D4A",
  "saffron-mango": "#F9CB58",
  "frostee": "#D8F2E0",
  "bismark": "#4D7887",
  "carnation": "#F75959",
  "sunglo": "#E86969",
  "tonys-pink": "#E89898",
  "fuzzy-wuzzy-brown": "#C65D5D",
  "silver": "#CCCCCC",
  "san-marino": "#4A56B9",
  "bright-turquoise": "#25F8C8",
  "turquoise": "#28E5B4",
  "blue-marguerite": "#6F76C7",
  "teal-blue": "#033D53",
}

/**
 * The color scheme to apply according to usage.
 */
const colorScheme = {
  "building-usage-condo": colorPalette["saffron-mango"],
  "building-usage-hotel": colorPalette["amethyst"],
  "building-usage-industrial": colorPalette["half-baked"],
  "building-usage-office": colorPalette["san-marino"],
  "building-usage-parking": colorPalette["silver"],
  "building-usage-project": colorPalette["bismark"],
  "building-usage-multifamily": colorPalette["de-york"],
  "building-usage-singlefamily": colorPalette["illusion"],
  "building-usage-retail": colorPalette["jaffa"],
  "building-usage-unused": colorPalette["mine-shaft"],
  "parcel-footprint": colorPalette["frostee"],
  "parcel-hover-fill": colorPalette["san-marino"],
  "parcel-hover-line": colorPalette["san-marino"],
  "parcel-select-fill": colorPalette["carnation"],
  "parcel-select-line": colorPalette["carnation"],
  "setback-selection-parcel-fill": colorPalette["tonys-pink"],
  "setback-selection-line": colorPalette["fuzzy-wuzzy-brown"],
  "setback-selection-fill": colorPalette["carnation"],
  "parcel-footprint-line": colorPalette["mine-shaft"],
  "disabled-slider-box": colorPalette["silver"],
  "smart-search-fill": colorPalette["bright-turquoise"],
  "smart-search-slider-box": colorPalette["turquoise"],
  "smart-search-toggle": colorPalette["turquoise"],
  "drawn-parcel-fill": colorPalette["white"],
  "drawn-parcel-line": colorPalette["san-marino"],
  "drawing-parcel-fill": colorPalette["white"],
  "drawing-parcel-line": colorPalette["sunglo"],
  "drawing-parcel-vertex-inactive": colorPalette["sunglo"],
  "drawing-parcel-vertex-halo": colorPalette["mine-shaft"],
  "subscription-toggle": colorPalette["blue-marguerite"],
  "pdf-primary-default": colorPalette["teal-blue"],
  "pdf-secondary-default": colorPalette["sunglo"],
  "pdf-text-default": colorPalette["white"],
}

/**
 * The RGB string for the color with the given name.
 *
 * @param color - The name of a color.
 *
 * @returns - A hex RGB color string.
 */
const color = (color) => {
  return colorPalette[color] || null;
}

/**
 * The RGB string for the identified item in the color scheme.
 *
 * @param key - The name of a particular element in the color scheme.
 *
 * @returns - A hex RGB color string.
 */
const colorFor = (key) => {
  return colorScheme[key] || null;
}

export {
  color,
  colorFor,
}
