import React from "react";
import { RouteComponentProps } from "react-router";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { userSelectors } from "../../../state/user";
import { Tier } from "../../../state/user/reducers";
import { Path } from "../../../types/Path";
import database from "../../../utils/database/database";
import authentication from "../../../utils/authentication";
import RedirectComponent from "../RedirectComponent";

const mapStateToProps = (state) => {
  return {
    tier: userSelectors.getTier(state),
  }
}

type StateProps = ReturnType<typeof mapStateToProps>;
type Props = RouteComponentProps & StateProps;

class Toolbar extends React.PureComponent<Props, {}> {
  /**
   * Save current project.
   */
  saveCurrentProject = async () => {
    if (this.props.location.pathname.includes(Path.Explorer)) await database.saveCurrentProject();
  }

  render() {
    let tier = this.props.tier;
    const userIsAuthenticated = authentication.isUserAuthenticated();
    if (!userIsAuthenticated) tier = Tier.None;

    return (
      <div className="component--toolbar">
        <RedirectComponent path={Path.Home} onClick={this.saveCurrentProject}>
          {({ redirect }) => (
            <div className="logo-container">
              <div onClick={redirect} className={`logo ${tier}`} />
            </div>
          )}
        </RedirectComponent>
        <div className="toolbar-children-container">
          {this.props.children}
        </div>
      </div>
    );
  }
}

export default withRouter(
  connect(mapStateToProps)(Toolbar)
);
