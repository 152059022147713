import React from "react";
import ShareProjectPopup from "./ShareProjectPopup";

interface OwnProps {
  developmentId: string;
  developmentName: string;
  classes?: string;
}

type Props = OwnProps;

interface State {
  shareProjectPopupIsActive: boolean;
}

class ShareProject extends React.PureComponent<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      shareProjectPopupIsActive: false,
    };
  }

  /**
   * Toggle the shareProjectPopupIsActive state.
   */
  toggleShareProjectPopupState = () => {
    this.setState({ shareProjectPopupIsActive: !this.state.shareProjectPopupIsActive });
  }

  render() {
    const { developmentId, developmentName, classes } = this.props;
    return (
      <div className={`component--share-project ${this.state.shareProjectPopupIsActive ? "active" : ""}`} onClick={this.toggleShareProjectPopupState}>
        <div className="icon" />
        <div className="text">Share</div>
        {this.state.shareProjectPopupIsActive
            && <ShareProjectPopup developmentId={developmentId} developmentName={developmentName} classes={classes || ""} onStateChange={this.toggleShareProjectPopupState} />}
      </div>
    );
  }
}

export default ShareProject;
