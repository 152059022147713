/**
 * Create the chargebeeCustomerId to users that don't have one.
 */
const createChargebeeCustomerId = (user) => {
  // We assign a falsy string. A separate subsystem will detect this and perform the
  // asynchronous activities required to attach a real Chargebee customer.
  if (!user.chargebeeCustomerId) user.chargebeeCustomerId = "";
}

export default createChargebeeCustomerId;
