import React from "react";
import Toolbar from "../../../sharedComponents/Toolbar";
import ToolbarSettings from "../../../sharedComponents/ToolbarSettings";
import RedirectDropdownButton from "../../../sharedComponents/RedirectDropdownButton";
import { Path } from "../../../../types/Path";

class DashboardToolbar extends React.PureComponent<{}, {}>  {
  render() {
    return (
      <div className="component--dashboard-toolbar">
        <Toolbar>
          <ToolbarSettings>
            <RedirectDropdownButton  path={Path.NewProject} text="New Project"/>
          </ToolbarSettings>
        </Toolbar>
      </div>
    )
  }
}

export default DashboardToolbar
