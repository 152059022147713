import parser from "parse-address";

/**
 * Parses the address and returns the city.
 */
const getCity = (address): null | string => {
  if (!address) return null;
  let parsedAddress = parser.parseLocation(address);
  return parsedAddress && parsedAddress.city;
}

/**
 * Build a full address.
 */
const buildFullAddress = (address, city, state, zipCode) => {
  if (!address && !city && !state && !zipCode) return null;
  return `${address || ""}, ${city || ""}, ${state || ""} ${zipCode || ""}`;
}

export default {
  getCity,
  buildFullAddress,
}
