import actionTypes from "./actionTypes";
import { Chargebee } from "../../types/Service/Chargebee";

/**
 * Load a subscription.
 *
 * @param {string} subscriptionId - The ID of the Chargebee subscription to load.
 */
const loadStart = (subscriptionId: string) => {
  return {
    type: actionTypes.LOAD_START,
    payload: { subscriptionId },
  };
}

/**
 * Load the subscription belonging to the identified customer.
 *
 * @param {string} customerId - The ID of the Chargebee customer whose subscription
 *  information to load.
 */
const loadByCustomerStart = (customerId: string | null) => {
  return {
    type: actionTypes.LOAD_BY_CUSTOMER_START,
    payload: { customerId },
  };
}

/**
 * Update the subscription concern in the state.
 *
 * @param {Chargebee.Subscription} subscription
 */
const loadSuccess = (subscription: Chargebee.Subscription | null) => {
  return {
    type: actionTypes.LOAD_SUCCESS,
    payload: { subscription },
  };
}

/**
 * Set plan prices in the state.
 *
 * @param {number} monthlyPrice
 * @param {number} yearlyPrice
 */
const setPlanPrices = (planPrices: Chargebee.PlanPrices) => {
  return {
    type: actionTypes.SET_PLAN_PRICES,
    payload: { planPrices },
  };
}

export default {
  loadStart,
  loadByCustomerStart,
  loadSuccess,
  setPlanPrices,
}
