export enum SlideshowType {
  WithZoning = "withZoning",
  WithoutZoning = "withoutZoning",
  OnlyDrawing = "OnlyDrawing",
}

export interface Slideshow {
  label: string;
  text: string;
  className: string;
}
