import React from "react";
import { Feature, Image, Layer } from "react-mapbox-gl";
import { connect } from "react-redux";
import listingIcon from "../../../../../assets/images/icon-listings.png";
import { listingsSelectors } from "../../../../../state/listings";
import { newDevelopmentSelectors } from "../../../../../state/newDevelopment";
import Format from "../../../../../types/Format";
import { ListingProperty } from "../../../../../types/Service/Listings/Listings";
import { MapStyleProperties } from "../../../../../utils/mapbox/mapStyleProperties";
import roundToDecimal from "../../../../../utils/roundToDecimal";
import valueFormatter from "../../../../../utils/valueFormatter";

const mapStateToProps = (state) => {
  return {
    listings: listingsSelectors.getListings(state),
    hoveredFeature: newDevelopmentSelectors.getHoveredFeature(state),
  }
}

const PRICE_LABEL = "priceLabel";

type StateProps = ReturnType<typeof mapStateToProps>;
type Props = StateProps;

const LAYER_LAYOUT = {
  "icon-image": "icon-listing",
  "icon-ignore-placement": true,
  "icon-allow-overlap": true,
  "icon-size": [
    "interpolate",
    ["linear"],
    ["zoom"],
    15,
    0.35,
    20,
    0.65
  ],
  "icon-offset": [0, 25],
  "text-allow-overlap": true,
  "text-ignore-placement": true,
  "text-justify": "center",
  "text-anchor": "center",
  "text-offset": [
    "interpolate",
    ["linear"],
    ["zoom"],
    15,
    ["literal", [0, 1.10]],
    15.7,
    ["literal", [0, 1.25]],
    20,
    ["literal", [0, 1.75]]
  ],
  "text-size": [
    "interpolate",
    ["linear"],
    ["zoom"],
    15,
    15,
    16,
    16,
    20,
    18
  ],
  "text-field": [
    "to-string",
    ["get", PRICE_LABEL],
  ],
  "text-font": [
    "Open Sans Bold",
  ],
  "text-transform": "uppercase",
};

const LAYER_PAINT = {
  "text-color": "#fff",
  "text-opacity": 1,
}

class ListingsFeatureLayer extends React.PureComponent<Props, {}> {
  render() {
    if (!this.props.listings) return null;

    return (
      <>
        <Image id="icon-listing" url={listingIcon} />
        <Layer
          id={MapStyleProperties.LayerId.ListingsQuery}
          type="symbol"
          layout={LAYER_LAYOUT}
          paint={LAYER_PAINT}
        >
          {this.props.listings.map((listing, index) => {
            let priceLabel = valueFormatter.format(roundToDecimal(listing[ListingProperty.ListPrice], 1), { type: Format.Type.Number, abbreviate: true });

            return (
              <Feature
                coordinates={listing[ListingProperty.Coordinates]}
                properties={{
                  [PRICE_LABEL]: priceLabel,
                  listing: listing,
                }}
                key={listing[ListingProperty.ListingKey]}
              />
            )
          })}
        </Layer>
      </>
    );
  }
}

export default connect(
  mapStateToProps,
)(ListingsFeatureLayer);
