import { Config } from "../../../../../../../types/Config";
import { VariableId } from "../../../../../../../types/VariableId";
import Format from "../../../../../../../types/Format";
import Unit from "../../../../../../../types/Unit";

const config: Config = {
  [VariableId.FloorAreaRatio]: {
    variableId: VariableId.FloorAreaRatio,
    text: "Floor Area Ratio",
    formatOptions: {
      decimalPlaces: 1,
      type: Format.Type.Number,
      prefix: "FAR ",
    },
  },

  [VariableId.UnitsPerParcelArea]: {
    variableId: VariableId.UnitsPerParcelArea,
    text: "Unit Density Ratio",
    formatOptions: {
      type: Format.Type.Number,
      decimalPlaces: 1,
    },
    [Unit.System.Imperial]: {
      unitTarget: Unit.Type.Acres,
      unitIsInverse: true,
      formatOptions: { suffix: " units / acre" },
    },
    [Unit.System.Metric]: {
      unitTarget: Unit.Type.Hectares,
      unitIsInverse: true,
      formatOptions: { suffix: " units / hectare" },
    }
  },

  [VariableId.ProjectNumberOfFloors]: {
    variableId: VariableId.ProjectNumberOfFloors,
    text: "Number of Floors",
    formatOptions: {
      type: Format.Type.Number,
      suffix: " floors",
    },
  },

  [VariableId.ParcelArea]: {
    variableId: VariableId.ParcelArea,
    text: "Lot Area",
    formatOptions: { type: Format.Type.Number },
    [Unit.System.Imperial]: {
      unitTarget: Unit.Type.SquareFeet,
      formatOptions: { suffix: " SF" },
    },
    [Unit.System.Metric]: {
      unitTarget: Unit.Type.SquareMeters,
      formatOptions: { suffix: " m²", },
    }
  },

  [VariableId.ParcelPurchasePrice]: {
    variableId: VariableId.ParcelPurchasePrice,
    text: "Purchase Price",
    formatOptions: { type: Format.Type.Currency },
  },

  [VariableId.ParcelPurchasePricePerArea]: {
    variableId: VariableId.ParcelPurchasePricePerArea,
    formatOptions: {
      type: Format.Type.Currency,
      decimalPlaces: 2,
    },
    [Unit.System.Imperial]: {
      text: "Purchase Price PSF (Per Square Foot)",
      unitTarget: Unit.Type.SquareFeet,
      unitIsInverse: true,
      formatOptions: { suffix: " / SF", },
    },
    [Unit.System.Metric]: {
      text: "Purchase Price PSM (Per Square Meter)",
      unitTarget: Unit.Type.SquareMeters,
      unitIsInverse: true,
      formatOptions: { suffix: " / m²", },
    }
  },

  [VariableId.ProjectConstructionCostIncludingDemolition]: {
    variableId: VariableId.ProjectConstructionCostIncludingDemolition,
    text: "Total Building Cost",
    formatOptions: { type: Format.Type.Currency },
  },

  [VariableId.ProjectGrossBuildableArea]: {
    variableId: VariableId.ProjectGrossBuildableArea,
    text: "Project Gross Buildable",
    formatOptions: { type: Format.Type.Number },
    [Unit.System.Imperial]: {
      unitTarget: Unit.Type.SquareFeet,
      formatOptions: { suffix: " SF" },
    },
    [Unit.System.Metric]: {
      unitTarget: Unit.Type.SquareMeters,
      formatOptions: { suffix: " m²" },
    }
  },

  [VariableId.ProjectConstructionCostIncludingDemolitionPerBuildableArea]: {
    variableId: VariableId.ProjectConstructionCostIncludingDemolitionPerBuildableArea,
    formatOptions: {
      type: Format.Type.Currency,
      decimalPlaces: 2
    },
    [Unit.System.Imperial]: {
      text: "All-In Construction Cost PSF (Per Square Foot)",
      unitTarget: Unit.Type.SquareFeet,
      unitIsInverse: true,
      formatOptions: { suffix: " / SF" },
    },
    [Unit.System.Metric]: {
      text: "All-In Construction Cost PSM (Per Square Meter)",
      unitTarget: Unit.Type.SquareMeters,
      unitIsInverse: true,
      formatOptions: { suffix: " / m²" },
    }
  },

  [VariableId.MultifamilyTotalUnitQuantity]: {
    variableId: VariableId.MultifamilyTotalUnitQuantity,
    text: "Multifamily Units",
    formatOptions: {
      type: Format.Type.Number,
      suffix: " units",
    },
  },

  [VariableId.CondoTotalUnitQuantity]: {
    variableId: VariableId.CondoTotalUnitQuantity,
    text: "Condo Units",
    formatOptions: {
      type: Format.Type.Number,
      suffix: " units",
    },
  },

  [VariableId.HotelRoomQuantity]: {
    variableId: VariableId.HotelRoomQuantity,
    text: "Hotel Units",
    formatOptions: {
      type: Format.Type.Number,
      suffix: " keys",
    },
  },

  [VariableId.OfficeGrossLeasableArea]: {
    variableId: VariableId.OfficeGrossLeasableArea,
    text: "Office Leasable Area",
    formatOptions: { type: Format.Type.Number },
    [Unit.System.Imperial]: {
      unitTarget: Unit.Type.SquareFeet,
      formatOptions: { suffix: " SF" },
    },
    [Unit.System.Metric]: {
      unitTarget: Unit.Type.SquareMeters,
      formatOptions: { suffix: " m²" },
    }
  },

  [VariableId.RetailNetLeasableArea]: {
    variableId: VariableId.RetailNetLeasableArea,
    text: "Retail Leasable Area",
    formatOptions: { type: Format.Type.Number },
    [Unit.System.Imperial]: {
      unitTarget: Unit.Type.SquareFeet,
      formatOptions: { suffix: " SF" },
    },
    [Unit.System.Metric]: {
      unitTarget: Unit.Type.SquareMeters,
      formatOptions: { suffix: " m²" },
    }
  },

  [VariableId.IndustrialNetLeasableArea]: {
    variableId: VariableId.IndustrialNetLeasableArea,
    text: "Industrial Leasable Area",
    formatOptions: { type: Format.Type.Number },
    [Unit.System.Imperial]: {
      unitTarget: Unit.Type.SquareFeet,
      formatOptions: { suffix: " SF" },
    },
    [Unit.System.Metric]: {
      unitTarget: Unit.Type.SquareMeters,
      formatOptions: { suffix: " m²" },
    }
  },

  [VariableId.ParkingRequiredSpacesAfterReduction]: {
    variableId: VariableId.ParkingRequiredSpacesAfterReduction,
    text: "Parking After Reduction",
    formatOptions: {
      type: Format.Type.Number,
      suffix: " spaces",
    },
  },

  [VariableId.CondoAverageGrossSalesPerSellableArea]: {
    variableId: VariableId.CondoAverageGrossSalesPerSellableArea,
    text: "Condo Sales",
    formatOptions: { type: Format.Type.Currency },
    [Unit.System.Imperial]: {
      unitTarget: Unit.Type.SquareFeet,
      unitIsInverse: true,
      formatOptions: { suffix: " / SF" },
    },
    [Unit.System.Metric]: {
      unitTarget: Unit.Type.SquareMeters,
      unitIsInverse: true,
      formatOptions: { suffix: " / m²" },
    }
  },

  [VariableId.CondoSaleCommissionPercentage]: {
    variableId: VariableId.CondoSaleCommissionPercentage,
    text: "Sales Costs",
    formatOptions: { type: Format.Type.Percentage },
  },

  [VariableId.MultifamilyAverageYearOnePotentialGrossMonthlyRentalIncomePerLeasableArea]: {
    variableId: VariableId.MultifamilyAverageYearOnePotentialGrossMonthlyRentalIncomePerLeasableArea,
    text: "Multifamily Rent Per Month",
    formatOptions: {
      type: Format.Type.Currency,
      decimalPlaces: 2
    },
    [Unit.System.Imperial]: {
      unitTarget: Unit.Type.SquareFeet,
      unitIsInverse: true,
      formatOptions: { suffix: " / SF" },
    },
    [Unit.System.Metric]: {
      unitTarget: Unit.Type.SquareMeters,
      unitIsInverse: true,
      formatOptions: { suffix: " / m²" },
    }
  },

  [VariableId.OfficeYearOneRentPerArea]: {
    variableId: VariableId.OfficeYearOneRentPerArea,
    text: "Office Lease",
    formatOptions: {
      type: Format.Type.Currency,
      decimalPlaces: 2,
    },
    [Unit.System.Imperial]: {
      unitTarget: Unit.Type.SquareFeet,
      unitIsInverse: true,
      formatOptions: { suffix: " / SF" },
    },
    [Unit.System.Metric]: {
      unitTarget: Unit.Type.SquareMeters,
      unitIsInverse: true,
      formatOptions: { suffix: " / m²" },
    }
  },

  [VariableId.RetailYearOneRentPerArea]: {
    variableId: VariableId.RetailYearOneRentPerArea,
    text: "Retail Lease",
    formatOptions: {
      type: Format.Type.Currency,
      decimalPlaces: 2,
    },
    [Unit.System.Imperial]: {
      unitTarget: Unit.Type.SquareFeet,
      unitIsInverse: true,
      formatOptions: { suffix: " / SF" },
    },
    [Unit.System.Metric]: {
      unitTarget: Unit.Type.SquareMeters,
      unitIsInverse: true,
      formatOptions: { suffix: " / m²" },
    }
  },

  [VariableId.IndustrialYearOneRentPerArea]: {
    variableId: VariableId.IndustrialYearOneRentPerArea,
    text: "Industrial Lease",
    formatOptions: {
      type: Format.Type.Currency,
      decimalPlaces: 2,
    },
    [Unit.System.Imperial]: {
      unitTarget: Unit.Type.SquareFeet,
      unitIsInverse: true,
      formatOptions: { suffix: " / SF" },
    },
    [Unit.System.Metric]: {
      unitTarget: Unit.Type.SquareMeters,
      unitIsInverse: true,
      formatOptions: { suffix: " / m²" },
    }
  },

  [VariableId.HotelStabilizedAverageDailyRate]: {
    variableId: VariableId.HotelStabilizedAverageDailyRate,
    text: "Hotel ADR (Average Daily Rate)",
    formatOptions: {
      type: Format.Type.Currency,
      suffix: " / room"
    },
  },

  [VariableId.HotelStabilizedOccupancyPercentage]: {
    variableId: VariableId.HotelStabilizedOccupancyPercentage,
    text: "Hotel Occupancy",
    formatOptions: { type: Format.Type.Percentage },
  },

  [VariableId.HotelStabilizedRevenuePerAvailableRoom]: {
    variableId: VariableId.HotelStabilizedRevenuePerAvailableRoom,
    text: "Hotel RevPAR (Revenue Per Available Room)",
    formatOptions: { type: Format.Type.Currency },
  },
};

export default config;
