import React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { withRouter } from "react-router-dom";
import { developmentSelectors, developmentActions } from "../../../../state/development";
import developmentUiHelper from "../../../utils/developmentUiHelper";
import authentication from "../../../../utils/authentication";
import { Path } from "../../../../types/Path";
import database from "../../../../utils/database/database";
import DeleteDevelopmentPopup from "../../../sharedComponents/DeleteDevelopment/DeleteDevelopmentPopup";
import analytics from "../../../../utils/analytics";

const mapStateToProps = (state) => {
  return {
    isFromShared: developmentSelectors.getIsFromShared(state),
    developmentName: developmentSelectors.getName(state),
  };
};

const mapDispatchToProps = {
  keepProject: developmentActions.keepProject,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;
type RouterProps = RouteComponentProps<{ projectId: string }>;
type Props = StateProps & DispatchProps & RouterProps;

interface State {
  show: boolean;
  deleteDevelopmentPopupIsActive: boolean;
}

class KeepProject extends React.PureComponent<Props, State>  {
  constructor(props) {
    super(props);

    const userIsAuthenticated = authentication.getCurrentUser();
    this.state = {
      show: this.props.isFromShared || !Boolean(userIsAuthenticated),
      deleteDevelopmentPopupIsActive: false,
    };
  }

  /**
   * Remove component.
   */
  close = () => {
    this.setState({ show: false });
  }

  /**
   * Mark this project as non from shared project.
   */
  keepProject = () => {
    const userIsAuthenticated = authentication.getCurrentUser();
    if (userIsAuthenticated) {
      this.props.keepProject();
      database.saveCurrentProject();
    } else {
      this.props.history.push(Path.Subscribe);
    }
    this.setState({ show: false });
  }

  /**
   * Callback for confirm button in DeleteDevelopmentPopup.
   */
  handleDeleteDevelopmentConfirm = async () => {
    const developmentId = this.props.match.params.projectId;
    await database.removeProject(developmentId);
    analytics.trackDeleteProject(developmentId);
    this.props.history.replace(Path.Home);
  }

  /**
   * Toggle the dialog box active state.
   */
  handleDeleteDevelopmentPopupStateChange = (event) => {
    if (event) event.stopPropagation();
    this.setState({ deleteDevelopmentPopupIsActive: !this.state.deleteDevelopmentPopupIsActive })
  }

  /**
   * Render the delete development popup.
   */
  renderPopup = () => {
    if (!this.state.deleteDevelopmentPopupIsActive) return null;

    return (
      <DeleteDevelopmentPopup
        onStateChange={this.handleDeleteDevelopmentPopupStateChange}
        onConfirm={this.handleDeleteDevelopmentConfirm}
        developmentName={this.props.developmentName}
        redirect
      />
    );
  }

  render() {
    if (!this.state.show) return null;

    return (
      <div className="component--keep-project">
        <div className="close" onClick={this.close} />
        <div className="icon-building" />
        <div className="text">Project Name</div>
        <div className="separator" />
        <div className="project-name">{developmentUiHelper.getDisplayName(this.props.developmentName)}</div>
        <button onClick={this.keepProject}>Keep Project</button>
        <div className="underlined-text" onClick={this.handleDeleteDevelopmentPopupStateChange}>Discard</div>
        {this.renderPopup()}
      </div>
    );
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(KeepProject)
);
