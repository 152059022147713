import React from "react";
import { Link } from "react-router-dom";
import { Path } from "../../../types/Path";

interface OwnProps {
  disableLink?: boolean
}

type Props = OwnProps;

class Navbar extends React.Component<Props, {}> {
  /**
   * Render logo.
   */
  renderLogo = () => <div className="logo" />

  /**
   * Render logo wrapped with Link.
   */
  renderLogoWithLink = () => (
    <Link to={Path.Home}>
      {this.renderLogo()}
    </Link>
  )

  render() {
    return (
      <div className="component--navbar">
        {
          this.props.disableLink
              ? this.renderLogo()
              : this.renderLogoWithLink()
        }
      </div>
    );
  }
}

export default Navbar;
