import { SharedState, INITIAL_STATE as sharedInitialState } from "./shared/reducers";
import sharedReducers from "./shared/reducers";

export interface UiState {
  shared: SharedState;
}

export const INITIAL_STATE: UiState = {
  shared: sharedInitialState,
}

/**
 * Determine which reducer, if any, should handle reducing the given action and
 * state.
 */
const reducer = (previousState = INITIAL_STATE, action): UiState => {
  switch (true) {
    case /^ui\/shared\/|^(?!ui).*/.test(action.type): return reduceShared(previousState, action);
    default: return previousState;
  }
}

/**
 * Delegate to the shared sub-concern.
 */
const reduceShared = (previousState, action): UiState => {
  return {
    ...previousState,
    shared: sharedReducers(previousState.shared, action),
  }
}

export default reducer;
