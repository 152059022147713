import actionTypes from "./actionTypes";

/**
 * User succesfully signed out.
 */
const signOutSuccess = () => {
  return {
    type: actionTypes.SIGN_OUT_SUCCESS,
  }
}

/**
 * User succesfully signed in.
 */
const signInSuccess = () => {
  return {
    type: actionTypes.SIGN_IN_SUCCESS,
  }
}

export default {
  signOutSuccess,
  signInSuccess,
}
