import developments from "./developments";
import { FirestoreCollection } from "../..";
import firebase from "firebase";
import { Development } from  "../../../../types/Development/Development";

type Hydration = (data: firebase.firestore.DocumentData) => void;
type Dehydration = (data: Development) => void;

const IDENTITY_FUNCTION = (data) => data;

/**
 * Return the correct dehydrator function depending on the given collection.
 */
const dehydratorFor = (collection: FirestoreCollection): Dehydration => {
  switch (collection) {
    case FirestoreCollection.Developments:  return developments.dehydrate;
    default:                                return IDENTITY_FUNCTION;
  }
}

/**
 * Return the correct hydrator function depending on the given collection.
 */
const hydratorFor = (collection: FirestoreCollection): Hydration => {
  switch (collection) {
    case FirestoreCollection.Developments:  return developments.hydrate;
    default:                                return IDENTITY_FUNCTION;
  }
}

/**
 * Apply the proper dehydration depending on the collection the document is in.
 */
let dehydrateData = (data: Development, collection: FirestoreCollection) => {
  dehydratorFor(collection)(data);
}

/**
* Apply the proper hydration depending on the collection the document is in.
*/
let hydrateData = (data: firebase.firestore.DocumentData, collection: FirestoreCollection) => {
  hydratorFor(collection)(data);
}

export default {
  dehydrateData,
  hydrateData
};
