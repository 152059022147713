import React from "react";
import SiteAnalysisPopup from "../SiteAnalysisPopup";
import analytics from "../../../../../../utils/analytics";

interface Props {
  text: string
}

interface State {
  siteAnalysisPopupIsOpen: boolean;
}

export default class SiteAnalysisButton extends React.PureComponent<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      siteAnalysisPopupIsOpen: false,
    }
  }

  /**
   * Toggle SiteAnalysisPopup.
   */
  toggleSiteAnalysisPopup = () => {
    if (!this.state.siteAnalysisPopupIsOpen) analytics.trackOpenAnalyzePropertyPopup();
    this.setState({ siteAnalysisPopupIsOpen: !this.state.siteAnalysisPopupIsOpen })
  };

  render() {
    const { text } = this.props;

    return (
      <>
        <button className="bottom btn" onClick={this.toggleSiteAnalysisPopup}>{text}</button>
        {this.state.siteAnalysisPopupIsOpen && <SiteAnalysisPopup onStateChange={this.toggleSiteAnalysisPopup} />}
      </>
    )
  }
}
