/**
 * @fileoverview This component allows the user to authenticate using
 * Facebook Login as the authentication provider.
 */

import React from "react";
import analytics from "../../../../utils/analytics";
import authentication, { AuthenticationProviderId } from "../../../../utils/authentication";
import database from "../../../../utils/database/database";
import completeSignUp from "../../../../utils/completeSignUp";
import Authentication from "../../../../types/Authentication";

interface Props {
  onError(errorCode: Authentication.ErrorCode): void;
  termsOfServiceAccepted?: boolean;
}

class AuthenticateWithFacebook extends React.PureComponent<Props, {}> {

  /**
   * Authenticate the user using Facebook as the authentication provider. If the
   * user is a new user, also perform necessary sign-up actions after
   * authenticating.
   */
  signInWithFacebook = async () => {
    if (!this.props.termsOfServiceAccepted) {
      this.props.onError(Authentication.CustomErrorCode.TermsOfServiceNotAccepted);
      return;
    }

    try {
      const userCredential = await authentication.signInWithFacebookPopup();
      const isNewUser = userCredential
          && userCredential.additionalUserInfo
          && userCredential.additionalUserInfo.isNewUser;

      const firstName = userCredential
          && userCredential.additionalUserInfo
          && userCredential.additionalUserInfo.profile
          && userCredential.additionalUserInfo.profile["first_name"];
      const middleName = userCredential
          && userCredential.additionalUserInfo
          && userCredential.additionalUserInfo.profile
          && userCredential.additionalUserInfo.profile["middle_name"];
      const lastName = userCredential
          && userCredential.additionalUserInfo
          && userCredential.additionalUserInfo.profile
          && userCredential.additionalUserInfo.profile["last_name"];

      if (isNewUser) {
        await completeSignUp({
          firstName,
          middleName,
          lastName
        });

        await database.loadCurrentUser();
        analytics.trackSignUp(AuthenticationProviderId.Facebook);
      } else {
        analytics.trackLogIn(AuthenticationProviderId.Facebook);
      }
    } catch (error) {
      this.props.onError(error.code);
      analytics.trackAuthenticationError(error, AuthenticationProviderId.Facebook);
    }
  }

  render() {
    return <button className="component--button-facebook" onClick={this.signInWithFacebook}>Log In with Facebook</button>;;
  }
}

export default AuthenticateWithFacebook;
