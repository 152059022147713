import React from "react";
import { Redirect } from "react-router";
import authentication from "../../../utils/authentication";
import analytics from "../../../utils/analytics";
import ActivityFeedback from "../../sharedComponents/ActivityFeedback";
import Navbar from "../../sharedComponents/Navbar";
import { Path } from "../../../types/Path";

enum Status {
  Initial = "INITIAL",
  Sending = "SENDING",
  Sent = "SENT"
}

interface State {
  status: Status;
  redirectToSignIn: boolean;
  newPassword: string;
  newPasswordConfirmation: string;
  errorMessage: string;
};

interface Props {
  location: any;
}

const FEEDBACK_TEXT = {
  sending: "Resetting your password...",
  sent: "Your password has been reset."
};

const SPINNING_TIME = 1000 // 1 second.
const ANSWER_TIME = 2000 // 2 second.

export default class ResetPasswordPage extends React.PureComponent<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      status: Status.Initial,
      redirectToSignIn: false,
      newPassword: "",
      newPasswordConfirmation: "",
      errorMessage: "",
    }

    analytics.trackPageview(Path.ResetPassword);
  }

  /**
   * Reset the user password and manage the state changes to provide user with feedback.
   */
  handleFormSubmit = async (event) => {
    event.preventDefault();
    if (this.state.newPassword === this.state.newPasswordConfirmation) {
      try {
        await authentication.verifyPasswordResetCode(this.props.location.state.actionCode);
        await authentication.confirmPasswordReset(this.props.location.state.actionCode, this.state.newPassword);
        analytics.trackChangePassword();
        this.setState({ status: Status.Sending });
        setTimeout(
          () => {
              this.setState({ status: Status.Sent });
              setTimeout(
                () => {
                  this.setState({ redirectToSignIn: true, errorMessage: "" });
                }, ANSWER_TIME
              );
          }, SPINNING_TIME
        );
      } catch (error) {
        this.setState({ errorMessage: authentication.getErrorMessage(error.code) });
      }
    } else {
      this.setState({ errorMessage: "Passwords don't match." });
    }
  }

  /**
   * Updates the newPassword state when it changes and set the errorMessage state to the empty string.
   */
  handleNewPasswordChange = (event) => {
    this.setState({ newPassword: event.target.value, errorMessage: "" });
  }

  /**
   * Updates the newPasswordConfirmation state when it changes and set the errorMessage state to the empty string.
   */
  handleNewPasswordConfirmationChange = (event) => {
    this.setState({ newPasswordConfirmation: event.target.value, errorMessage: "" });
  }

  /**
   * Render the content of the page depending on the status state.
   */
  renderContent = () => {
    switch (this.state.status) {
      case Status.Initial:
        return (
          <div className="content-wrapper">
            <div className="header">Let’s reset your password.</div>
            <div className="error-message">{this.state.errorMessage}</div>

            <div className="form-container">
              <form onSubmit={this.handleFormSubmit}>
                <div className="input-section hidden">
                    <input
                      type="email"
                      name="email"
                      autoComplete="username"
                    />
                </div>

                <div className="input-section">
                  <div className="input-label">New password</div>
                  <div className="input-field">
                    <input
                      type="password"
                      name="new-password"
                      autoComplete="new-password"
                      onChange={this.handleNewPasswordChange}
                    />
                  </div>
                </div>

                <div className="input-section">
                  <div className="input-label">Repeat new password</div>
                  <div className="input-field">
                    <input
                      type="password"
                      name="new-password-confirmation"
                      autoComplete="new-password"
                      onChange={this.handleNewPasswordConfirmationChange}
                    />
                  </div>
                </div>

                <button type="submit" className="submit">Submit</button>
              </form>
            </div>
          </div>
        );
      case Status.Sending:
        return (
          <ActivityFeedback
            message={FEEDBACK_TEXT.sending}
            displaySpinner={true}
          />
        );
      case Status.Sent:
        return (
          <ActivityFeedback
            message={FEEDBACK_TEXT.sent}
            displaySpinner={false}
          />
        );
      default:
        return null;
    }
  }

  render() {
    if (this.state.redirectToSignIn) {
      return <Redirect to={Path.Home}/>;
    }
    return (
      <div className="component--reset-password-page">
        <Navbar />
        {this.renderContent()}
      </div>
    )
  }
}
