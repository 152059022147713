import React from "react";
import { connect } from "react-redux";
import { developmentSelectors } from "../../../../../state/development";
import { Panel } from "../../../../../types/uiElements";
import PanelHeader from "../sharedComponents/PanelHeader";
import SliderBox from "../sharedComponents/SliderBox";
import PanelSectionTitle from "../sharedComponents/PanelSectionTitle";
import ConfigurationHandler from "../../../../sharedComponents/ConfigurationHandler";
import config from "./config";

const mapStateToProps = (state) => {
  return {
    unitSystem: developmentSelectors.getUnitSystem(state),
    values: developmentSelectors.getValues(state),
  }
}

interface OwnProps {
  panel: Panel;
  onPanelClose(panel: Panel);
  onPanelPin(panel: Panel);
}

type StateProps = ReturnType<typeof mapStateToProps>;
type Props = OwnProps & StateProps;

class HotelPanel extends React.Component<Props, any>  {
  /**
   * Render Hotel Gross Buildable section.
   */
  renderGrossBuildableSection = () => {
    return (
      <>
        <ConfigurationHandler config={config.hotelGrossBuildableArea} component={PanelSectionTitle} unitSystem={this.props.unitSystem} />
        <div>
          <ConfigurationHandler config={config.hotelRoomQuantityToggleable} component={SliderBox} unitSystem={this.props.unitSystem} />
          {
            this.props.values.hotelRoomQuantityToggleable
              ? <ConfigurationHandler config={config.hotelAverageRoomArea} component={SliderBox} unitSystem={this.props.unitSystem} />
              : null
          }
          {
            this.props.values.hotelRoomQuantityToggleable
              ? <ConfigurationHandler config={config.hotelLossFactor} component={SliderBox} unitSystem={this.props.unitSystem} />
              : null
          }
          {
            this.props.values.hotelRoomQuantityToggleable
              ? <ConfigurationHandler config={config.hotelAmenitiesAreaToggleable} component={SliderBox} unitSystem={this.props.unitSystem} />
              : null
          }
        </div>
      </>
    );
  }

  /**
   * Render Hotel Income section.
   */
  renderIncomeSection = () => {
    if (this.props.values.hotelRoomQuantityToggleable === 0) return null;

    return (
      <>
        <ConfigurationHandler config={config.hotelAnnualRevenueForBackOfEnvelope} component={PanelSectionTitle} unitSystem={this.props.unitSystem} />
        <div>
          <ConfigurationHandler config={config.hotelStabilizedAverageDailyRate} component={SliderBox} unitSystem={this.props.unitSystem} />
          <ConfigurationHandler config={config.hotelOtherRevenuePercentage} component={SliderBox} unitSystem={this.props.unitSystem} />
        </div>
      </>
    );
  }

  /**
   * Render Hotel NOI section.
   */
  renderNOISection = () => {
    if (this.props.values.hotelRoomQuantityToggleable === 0) return null;

    return (
      <>
        <ConfigurationHandler config={config.hotelAnnualNetOperatingIncomeForBackOfEnvelope} component={PanelSectionTitle} unitSystem={this.props.unitSystem} />
        <div>
          <ConfigurationHandler config={config.hotelStabilizedOccupancyPercentage} component={SliderBox} unitSystem={this.props.unitSystem} />
          <ConfigurationHandler config={config.hotelOperatingExpensePercentage} component={SliderBox} unitSystem={this.props.unitSystem} />
        </div>
      </>
    );
  }

  render() {
    return (
      <div className="panel">
        <PanelHeader
          isPinned={this.props.panel.isPinned}
          panel={this.props.panel}
          onPanelClose={this.props.onPanelClose}
          onPanelPin={this.props.onPanelPin}
        />
        <div className="panel-inner-wrapper">
          {this.renderGrossBuildableSection()}
          {this.renderIncomeSection()}
          {this.renderNOISection()}
        </div>
      </div>
    );
  }
}

export default connect(
  mapStateToProps
)(HotelPanel);
