import Authentication from "../types/Authentication";
import authentication from "../utils/authentication";

export default class AuthenticationError extends Error {
  public code: Authentication.ErrorCode;

  constructor(errorCode: Authentication.ErrorCode) {
    super(authentication.getErrorMessage(errorCode));
    this.code = errorCode;

    Object.setPrototypeOf(this, AuthenticationError.prototype);
  }
}
