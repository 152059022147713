import React from "react";
import { connect } from "react-redux";
import { developmentSelectors } from "../../../../../state/development";
import { Panel } from "../../../../../types/uiElements";
import ConfigurationHandler from "../../../../sharedComponents/ConfigurationHandler";
import PanelHeader from "../sharedComponents/PanelHeader";
import SliderBox from "../sharedComponents/SliderBox";
import PanelSectionTitle from "../sharedComponents/PanelSectionTitle";
import config from "./config";

const mapStateToProps = (state) => {
  return {
    unitSystem: developmentSelectors.getUnitSystem(state),
    values: developmentSelectors.getValues(state),
  }
}

interface OwnProps {
  panel: Panel;
  onPanelClose(panel: Panel);
  onPanelPin(panel: Panel);
}

type StateProps = ReturnType<typeof mapStateToProps>;
type Props = OwnProps & StateProps;

class MultifamilyPanel extends React.Component<Props, any>  {
  /**
   * Render Multifamily Unit Mix section.
   */
  renderUnitMixSection = () => {
    return (
      <div data-tour="multifamily-unit-mix">
        <ConfigurationHandler config={config.multifamilyTotalUnitQuantity} component={PanelSectionTitle} unitSystem={this.props.unitSystem} />
        <div>
          <ConfigurationHandler config={config.multifamilyMicrounitQuantityToggleable} component={SliderBox} unitSystem={this.props.unitSystem} />
          <ConfigurationHandler config={config.multifamilyStudioQuantityToggleable} component={SliderBox} unitSystem={this.props.unitSystem} />
          <ConfigurationHandler config={config.multifamilyOneBedQuantityToggleable} component={SliderBox} unitSystem={this.props.unitSystem} />
          <ConfigurationHandler config={config.multifamilyTwoBedQuantityToggleable} component={SliderBox} unitSystem={this.props.unitSystem} />
          <ConfigurationHandler config={config.multifamilyThreeBedQuantityToggleable} component={SliderBox} unitSystem={this.props.unitSystem} />
        </div>
      </div>
    );
  }

  /**
   * Render Multifamily Gross Buildable section.
   */
  renderGrossBuildableSection = () => {
    if (this.props.values.multifamilyTotalUnitQuantity === 0) return null;

    return (
      <div data-tour="multifamily-gross-buildable">
        <ConfigurationHandler config={config.multifamilyGrossBuildableArea} component={PanelSectionTitle} unitSystem={this.props.unitSystem} />
        <div>
          {
            this.props.values.multifamilyMicrounitQuantityToggleable
              ? <ConfigurationHandler config={config.multifamilyMicrounitArea} component={SliderBox} unitSystem={this.props.unitSystem} />
              : null
          }
          {
            this.props.values.multifamilyStudioQuantityToggleable
              ? <ConfigurationHandler config={config.multifamilyStudioArea} component={SliderBox} unitSystem={this.props.unitSystem} />
              : null
          }
          {
            this.props.values.multifamilyOneBedQuantityToggleable
              ? <ConfigurationHandler config={config.multifamilyOneBedArea} component={SliderBox} unitSystem={this.props.unitSystem} />
              : null
          }
          {
            this.props.values.multifamilyTwoBedQuantityToggleable
              ? <ConfigurationHandler config={config.multifamilyTwoBedArea} component={SliderBox} unitSystem={this.props.unitSystem} />
              : null
          }
          {
            this.props.values.multifamilyThreeBedQuantityToggleable
              ? <ConfigurationHandler config={config.multifamilyThreeBedArea} component={SliderBox} unitSystem={this.props.unitSystem} />
              : null
          }
          <ConfigurationHandler config={config.multifamilyLossFactor} component={SliderBox} unitSystem={this.props.unitSystem} />
          <ConfigurationHandler config={config.multifamilyAmenitiesAreaToggleable} component={SliderBox} unitSystem={this.props.unitSystem} />
        </div>
      </div>
    );
  }

  /**
   * Render Multifamily Income section.
   */
  renderIncomeSection = () => {
    if (this.props.values.multifamilyTotalUnitQuantity === 0) return null;

    return (
      <div data-tour="multifamily-income">
        <ConfigurationHandler config={config.multifamilyYearOnePotentialGrossIncome} component={PanelSectionTitle} unitSystem={this.props.unitSystem} />
        <div>
          {
            this.props.values.multifamilyMicrounitQuantityToggleable
              ? <ConfigurationHandler config={config.multifamilyYearOneMonthlyRentPerMicrounit} component={SliderBox} unitSystem={this.props.unitSystem} />
              : null
          }
          {
            this.props.values.multifamilyStudioQuantityToggleable
              ? <ConfigurationHandler config={config.multifamilyYearOneMonthlyRentPerStudio} component={SliderBox} unitSystem={this.props.unitSystem} />
              : null
          }
          {
            this.props.values.multifamilyOneBedQuantityToggleable
              ? <ConfigurationHandler config={config.multifamilyYearOneMonthlyRentPerOneBed} component={SliderBox} unitSystem={this.props.unitSystem} />
              : null
          }
          {
            this.props.values.multifamilyTwoBedQuantityToggleable
              ? <ConfigurationHandler config={config.multifamilyYearOneMonthlyRentPerTwoBed} component={SliderBox} unitSystem={this.props.unitSystem} />
              : null
          }
          {
            this.props.values.multifamilyThreeBedQuantityToggleable
              ? <ConfigurationHandler config={config.multifamilyYearOneMonthlyRentPerThreeBed} component={SliderBox} unitSystem={this.props.unitSystem} />
              : null
          }
        </div>
      </div>
    );
  }

  /**
   * Render Multifamily NOI section.
   */
  renderNOISection = () => {
    if (this.props.values.multifamilyTotalUnitQuantity === 0) return null;

    return (
      <div data-tour="multifamily-noi">
        <ConfigurationHandler config={config.multifamilyAnnualNetOperatingIncomeForBackOfEnvelope} component={PanelSectionTitle} unitSystem={this.props.unitSystem} />
        <div>
          <ConfigurationHandler config={config.multifamilyStabilizedVacancyPercentage} component={SliderBox} unitSystem={this.props.unitSystem} />
          <ConfigurationHandler config={config.multifamilyOperatingExpensePercentage} component={SliderBox} unitSystem={this.props.unitSystem} />
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="panel">
        <PanelHeader
          isPinned={this.props.panel.isPinned}
          panel={this.props.panel}
          onPanelClose={this.props.onPanelClose}
          onPanelPin={this.props.onPanelPin}
        />

        <div className="panel-inner-wrapper">
          {this.renderUnitMixSection()}
          {this.renderGrossBuildableSection()}
          {this.renderIncomeSection()}
          {this.renderNOISection()}
        </div>
      </div>
    );
  }
}

export default connect(
  mapStateToProps
)(MultifamilyPanel);
