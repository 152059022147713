import { AccordionSectionState } from "./reducers";

/**
 * Get the accordionSection object.
 */
const getAccordionSectionState = (state): AccordionSectionState => {
  let accordionSectionState = state.ui.shared.accordionSection;
  return accordionSectionState;
}

/**
 * Get the static accordions are open object.
 */
const getStaticAccordionsAreOpen = (state) => {
  let accordionSectionState = getAccordionSectionState(state);
  return accordionSectionState.staticAccordionsAreOpen;
}

/**
 * Get a specific accordion is open value.
 */
const getStaticAccordionIsOpen = (state, id): boolean => {
  let accordionsAreOpen = getStaticAccordionsAreOpen(state);
  return accordionsAreOpen[id];
}

/**
 * Get listings accordion are open object.
 */
const getListingsAccordionsAreOpen = (state) => {
  let accordionSectionState = getAccordionSectionState(state);
  return accordionSectionState.listingAccordionsAreOpen;
}

export default {
  getStaticAccordionsAreOpen,
  getStaticAccordionIsOpen,
  getListingsAccordionsAreOpen,
}
