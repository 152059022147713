import React from "react";
import authentication from "../../../../utils/authentication";
import analytics from "../../../../utils/analytics";

interface State {
  emailAddress: string;
}

interface Props {
  onEmailSent: () => void;
}

export default class ForgotPasswordForm extends React.PureComponent<Props, State> {
  constructor(props){
    super(props);

    this.state = {
      emailAddress: "",
    }
  }

  /**
   * Send a reset password email to the provided email address.
   */
  sendForgotPasswordEmail = (event) => {
    event.preventDefault();
    authentication.sendResetPasswordEmail(this.state.emailAddress);
    analytics.trackRequestPasswordChange(this.state.emailAddress);
    this.props.onEmailSent();
  }

  /**
   * Updates the state when the email changes.
   */
  handleEmailChange = (event) => {
    this.setState({ emailAddress: event.target.value });
  }

  /**
   * Check if the email address field has data or not.
   */
  buttonIsDisabled = () => this.state.emailAddress === "";

  render() {
    return (
      <>
        <div className="header">Reset your password.</div>
        <div className="text-box">
          Please enter the email address you used to register your account.
          We will send you a link with instructions to reset your password.
        </div>

        <div className="form-container">
          <form onSubmit={this.sendForgotPasswordEmail}>
            <div className="input-section">
              <div className="input-field">
                <input
                  type="email"
                  name="email"
                  placeholder="Email address"
                  autoComplete="username"
                  onChange={this.handleEmailChange}
                />
              </div>
            </div>

            <button
              className={`submit ${this.buttonIsDisabled() ? "disabled" : ""}`}
              type="submit"
              disabled={this.buttonIsDisabled()}
            >
              Send
            </button>
          </form>
        </div>
      </>
    );
  }
}
