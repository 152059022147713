import React from "react";
import styled from "@react-pdf/styled-components";
import Pdf from "../../../../../../../types/Pdf";
import PdfContext from "../../../PdfContext";

const ParcelImage = styled.Image`
  margin-top: 5px;
`;

const Image = () => (
  <PdfContext.Consumer>
    {({ mapImages }) => (
      <ParcelImage src={mapImages[Pdf.ImageType.Parcel]} />
    )}
  </PdfContext.Consumer>
);

export default Image;
