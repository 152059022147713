import { newDevelopmentProcesses } from "./newDevelopment"
import { subscriptionProcesses } from "./subscription";
import { thumbnailsProcesses } from "./thumbnails";
import { userProcesses } from "./user";
import { pdfProcesses } from "./pdf";
import { listingsProcesses } from "./listings";

/**
 * Each saga has to be registered with the redux-saga middleware in order to run.
 * Register all sagas here.
 *
 * NOTE: Make sure only sagas (i.e. Generator functions) are passed in to the
 * `SAGAS` array. Also, make sure to include only parent sagas and not to
 * include child sagas as this might have unintended consequences.
 *
 * For example:
 *
 *    import { helloWorldProcesses } from ".../helloWorld"
 *
 *    const SAGAS = [
 *      ...Object.values(helloWorldProcesses)
 *    ]
 *
 */

const SAGAS = [
  ...Object.values(newDevelopmentProcesses),
  ...Object.values(subscriptionProcesses),
  ...Object.values(thumbnailsProcesses),
  ...Object.values(userProcesses),
  ...Object.values(pdfProcesses),
  ...Object.values(listingsProcesses),
];

const registerWithMiddleWare = (middleware: { run: Function }) => {
  for (let saga of SAGAS) {
    middleware.run(saga);
  }
}

export default { registerWithMiddleWare };
