import React from "react";
import { connect } from "react-redux";
import { pdfSelectors, pdfActions } from "../../../../../../state/pdf";

const MAX_CHARACTER_COUNT = 13000;
const MAX_LINE_COUNT = 23;
// NOTE: This should match the css for className summary-text.
const LINE_HEIGHT = 13;
// NOTE: This should match the padding for className summary-text offset in css for a better line count accuracy;
const PADDING_OFFSET = 8;

const mapStateToProps = (state) => {
  return {
    summary: pdfSelectors.getSummary(state),
  }
}

const mapDispatchToProps = {
  setSummary: pdfActions.setSummary,
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;
type Props = StateProps & DispatchProps;

interface State {
  displayWarning: boolean;
}

export class Summary extends React.PureComponent<Props, State> {
  textAreaReference?: HTMLTextAreaElement | null;

  constructor(props: Props) {
    super(props);

    this.state = {
      displayWarning: false,
    }
  }

  /**
   * Return number of characters remaining.
   */
  getLineCount = () => {
    if (this.textAreaReference) {
      let lineCount = Math.floor((this.textAreaReference.scrollHeight - PADDING_OFFSET) / LINE_HEIGHT);
      return lineCount;
    }

    return 0;
  }

  /**
   * Update summary in the store.
   */
  updateSummary = (event) => {
    const value: string = event.target.value;

    if (this.getLineCount() <= MAX_LINE_COUNT) {
      this.props.setSummary(value.slice(0, MAX_CHARACTER_COUNT));
    } else {
      this.setState({ displayWarning: true });
    }

    if (this.state.displayWarning) this.setState({ displayWarning: false });
  }

  render() {
    return (
      <div className="component--summary">
        <div className="label-container">
          <div className="summary-label" />
          <span>Summary</span>
        </div>
        <div className="text-container">
          <textarea
            ref={(reference) => { this.textAreaReference = reference }}
            className="summary-text"
            value={this.props.summary}
            onChange={this.updateSummary}
            placeholder="Write Report Summary…"
          />
          <span className="warning-message">{this.state.displayWarning ? "* You have exceeded the allowed number of lines" : " "}</span>
        </div>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Summary)
