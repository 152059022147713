import { InputPresets } from "../../types/Presets";
import developmentAccessors from "../../state/development/utils/developmentAccessors";
import staticList from "./staticList"
import generators from "./generators";
import { Development } from  "../../types/Development/Development";
import { VariableId } from  "../../types/VariableId";

const DEFAULT_FLOOR_AREA_RATIO = 0.0;

/**
 * Apply the given preset JSON directly to the given development.
 */
const setPresets = (development: Development, presetJson: object): void => {
  for (let inputId of Object.keys(presetJson) as Array<VariableId>) {
    let inputPresets: InputPresets = presetJson[inputId];

    if (inputPresets.minimum !== undefined) {
      developmentAccessors.setInputMinimum(development, inputId, inputPresets.minimum);
    }

    if (inputPresets.maximum !== undefined) {
      developmentAccessors.setInputMaximum(development, inputId, inputPresets.maximum);
    }

    if (inputPresets.value !== undefined) {
      developmentAccessors.setInputValueConstrained(development, inputId, inputPresets.value);
    }

    if (inputPresets.increment !== undefined) {
      developmentAccessors.setInputIncrement(development, inputId, inputPresets.increment);
    }
  }
};

/**
 * Apply the identified preset lists to the given development. The preset lists
 * are applied from first to last in the order defined by the given array of
 * preset JSONs.
 */
const applyStaticPresets = (development: Development, presetList: object[]): void => {
  for (let preset of presetList) {
    setPresets(development, preset);
  }
};

/**
 * Modify the development object with values, minimums, maximums and increments applied as
 * dictated by the floor to area ratio. The development object is fully updated
 * after applying the presets.
 *
 * This presets can be applied at any time.
 */
const update = (development: Development, floorAreaRatio: number): void => {
  applyStaticPresets(development, staticList.forUpdate());
  setPresets(development, generators.forBuildables(development, floorAreaRatio));
  setPresets(development, generators.forParking(development));
}

/**
 * Modify the development object with values, minimums, maximums and increments applied as
 * dictated by the provided market and density preferences. The development object is fully updated
 * after applying the presets.
 *
 * This presets should only be applied at project creation.
 */
const initialize = (development: Development): void => {
  const targetFloorAreaRatio = Number(developmentAccessors.getInputValue(development, VariableId.FloorAreaRatio) || DEFAULT_FLOOR_AREA_RATIO);

  applyStaticPresets(development, staticList.forInitialization());
  setPresets(development, generators.forBuildables(development, targetFloorAreaRatio));
  setPresets(development, generators.forParking(development));
  setPresets(development, generators.forPurchasePriceByArea(development));
  setPresets(development, generators.forSetbacks(development));
}

export default {
  update,
  initialize,
};
