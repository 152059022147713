import React from "react";
import RouteComponent from "./routes/RouteComponent";
import SupportedBrowserSwitch from "./routes/SupportedBrowserSwitch";
import HomePage from "./pages/HomePage";
import NewProjectPage from "./pages/NewProjectPage";
import ExplorerPage from "./pages/ExplorerPage";
import ForgotPasswordPage from "./pages/ForgotPasswordPage";
import ResetPasswordPage from "./pages/ResetPasswordPage";
import EmailVerificationCompletePage from "./pages/EmailVerificationCompletePage";
import SubscriptionRequiredPage from "./pages/SubscriptionRequiredPage";
import AuthenticationLinkHandler from "./sharedComponents/AuthenticationLinkHandler";
import DashboardPage from "./pages/DashboardPage";
import database from "../utils/database";
import { Path } from "../types/Path";
import { UserStatus } from "../types/UserStatus";
import { BrowserSupportRequirement } from "../types/BrowserSupportRequirement";
import "../app.css";
import Tooltip from "./sharedComponents/Tooltip";
import { Redirect } from "react-router-dom";

class App extends React.Component {
  constructor(props) {
    super(props);
    database.initialize();
  }

  render() {
    return (
      <div>
        <div id="pop-up-container" />
        <Tooltip />
        <SupportedBrowserSwitch requiredSupportLevel={BrowserSupportRequirement.Partial}>
          <Redirect from={Path.SignUp} to={Path.Home} />
          <RouteComponent exact path={Path.AuthAction} component={AuthenticationLinkHandler} allow={[UserStatus.NotAuthenticated]} />
          <RouteComponent exact path={Path.VerifyEmail} component={EmailVerificationCompletePage} allow={[UserStatus.NotAuthenticated]} />
          <RouteComponent exact path={Path.ForgotPassword} component={ForgotPasswordPage} allow={[UserStatus.NotAuthenticated]} />
          <RouteComponent exact path={Path.ResetPassword} component={ResetPasswordPage} allow={[UserStatus.NotAuthenticated]} />
          <SupportedBrowserSwitch requiredSupportLevel={BrowserSupportRequirement.Full}>
            <RouteComponent exact path={Path.Home} component={HomePage} allow={[UserStatus.NotAuthenticated]} />
            <RouteComponent exact path={Path.Dashboard} component={DashboardPage} allow={[UserStatus.Subscribed]} />
            <RouteComponent exact path={`${Path.Explorer}/:projectId`} component={ExplorerPage} allow={[UserStatus.Subscribed, UserStatus.NotAuthenticated]} />
            <RouteComponent exact path={`${Path.NewProject}/:camera?`} component={NewProjectPage} allow={[UserStatus.Subscribed, UserStatus.NotAuthenticated]} />
            <RouteComponent exact path={Path.Subscribe} component={SubscriptionRequiredPage} allow={[UserStatus.Verified, UserStatus.Subscribed, UserStatus.NotAuthenticated, UserStatus.Authenticated]} />
          </SupportedBrowserSwitch>
        </SupportedBrowserSwitch>
      </div>
    );
  }
}

export default App;
