import React from "react";
import { Path } from "../../../types/Path";
import RedirectComponent from "../RedirectComponent";

interface Props {
  path: Path | string;
  text: string;
  onClick?(): void;
  shouldContinue?(): boolean;
  classes?: string;
  state?: object;
}

class RedirectButton extends React.PureComponent<Props, {}> {
  render() {
    const { path, text, onClick, classes, state, shouldContinue } = this.props;

    return (
      <RedirectComponent path={path} onClick={onClick} state={state} shouldContinue={shouldContinue}>
        {
          ({ redirect }) =>
            <button className={`component--redirect-button btn ${classes || ""}`} onClick={redirect}>{text}</button>
        }
      </RedirectComponent>
    );
  }
};

export default RedirectButton;
