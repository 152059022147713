import React from "react";
import styled from "@react-pdf/styled-components";
import PdfContext from "../../../PdfContext";
import Pdf from "../../../../../../../types/Pdf";
import logo from "../../../../../../../assets/images/branding-watermark.png";

const Container = styled.View`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 151px;
  height: 60px;
  margin-right: auto;
  margin-left: auto;
  `;

const Image = styled.Image`
  object-fit: scale-down;
  width: auto;
  max-width: 100%;
  height: auto;
`;

const LogoImage = () => (
  <PdfContext.Consumer>
    {({ coverImages }) => {
      let imageUrl = coverImages[Pdf.ImageType.Logo] && coverImages[Pdf.ImageType.Logo].url;

      return (
        <Container>
          <Image src={imageUrl || logo} />
        </Container>
      )
    }}
  </PdfContext.Consumer>
);

export default LogoImage;
