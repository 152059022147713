import { Config } from "../../../../../../../types/Config";
import Format from "../../../../../../../types/Format";
import { VariableId } from "../../../../../../../types/VariableId";

const config: Config = {
  [VariableId.IncomeProducingUsesAnnualPotentialGrossIncomeForBackOfEnvelope]: {
    variableId: VariableId.IncomeProducingUsesAnnualPotentialGrossIncomeForBackOfEnvelope,
    text: "Total Income",
    requiresAuthentication: true,
    formatOptions: { type: Format.Type.Currency },
  },

  [VariableId.IncomeProducingUsesAnnualVacancyExpenseForBackOfEnvelope]: {
    variableId: VariableId.IncomeProducingUsesAnnualVacancyExpenseForBackOfEnvelope,
    text: "Total Vacancy",
    requiresAuthentication: true,
    formatOptions: {
      type: Format.Type.Currency,
      negate: true,
    },
  },

  [VariableId.IncomeProducingUsesAnnualOperatingExpenseForBackOfEnvelope]: {
    variableId: VariableId.IncomeProducingUsesAnnualOperatingExpenseForBackOfEnvelope,
    text: "Total Operating Expenses",
    requiresAuthentication: true,
    formatOptions: {
      type: Format.Type.Currency,
      negate: true,
    },
  },

  [VariableId.IncomeProducingUsesAnnualNetOperatingIncomeForBackOfEnvelope]: {
    variableId: VariableId.IncomeProducingUsesAnnualNetOperatingIncomeForBackOfEnvelope,
    text: "Net Operating Income (NOI)",
    requiresAuthentication: true,
    formatOptions: { type: Format.Type.Currency },
  },

  [VariableId.IncomeProducingUsesTotalDevelopmentCostIncludingParking]: {
    variableId: VariableId.IncomeProducingUsesTotalDevelopmentCostIncludingParking,
    text: "Cost",
    requiresAuthentication: true,
    formatOptions: {
      type: Format.Type.Currency,
      negate: true,
    },
  },

  [VariableId.IncomeProducingUsesAnnualReturnOnCostForBackOfEnvelope]: {
    variableId: VariableId.IncomeProducingUsesAnnualReturnOnCostForBackOfEnvelope,
    text: "Return on Cost (ROC)",
    formatOptions: {
      type: Format.Type.Percentage,
      decimalPlaces: 2,
    },
  },

  [VariableId.CondoNetProceeds]: {
    variableId: VariableId.CondoNetProceeds,
    text: "Condo Net Proceeds",
    requiresAuthentication: true,
    formatOptions: { type: Format.Type.Currency },
  },

  [VariableId.CondoTotalDevelopmentCostIncludingParking]: {
    variableId: VariableId.CondoTotalDevelopmentCostIncludingParking,
    text: "Condo Cost",
    requiresAuthentication: true,
    formatOptions: {
      type: Format.Type.Currency,
      negate: true,
    },
  },

  [VariableId.CondoProfit]: {
    variableId: VariableId.CondoProfit,
    text: "Condo Profit",
    requiresAuthentication: true,
    formatOptions: { type: Format.Type.Currency },
  },

  [VariableId.CondoReturnOnCost]: {
    variableId: VariableId.CondoReturnOnCost,
    text: "Return on Cost (ROC)",
    formatOptions: {
      type: Format.Type.Percentage,
      decimalPlaces: 2,
    },
  },
};

export default config;
