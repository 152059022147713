import developmentAccessors from "../../../state/development/utils/developmentAccessors";
import parcelAccessors from "../../parcel/parcelAccessors";
import { Development } from  "../../../types/Development/Development";
import { VariableId } from  "../../../types/VariableId";

/**
 * Generate a JSON object with the purchase price presets.
 *
 * This implementation applies a simple price-per-square-meter formula for the
 * land.
 */
const generatePresetsForPurchasePrice = (development: Development) => {
  const parcel = developmentAccessors.getParcel(development);
  const parcelArea = parcelAccessors.getAreaPreferPublished(parcel);

  return {
    [VariableId.ParcelPurchasePrice]: {
      "minimum": 0,
      "maximum": Math.round(parcelArea * 1500.00),
      "value": Math.round(parcelArea * 600.00)
    }
  };
}

export default generatePresetsForPurchasePrice;
