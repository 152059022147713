import { Panel } from "../../../../types/uiElements";

const projectInfoPanel: Panel = {
  title: "Project Info",
  description: "project_info_panel",
  requireUsages: false,
  closeOtherPanels: false,
}

const projectCostPanel: Panel = {
  title: "Project Cost",
  description: "cost_panel",
  requireUsages: false,
  closeOtherPanels: false,
}

const heightAndSetbacksPanel: Panel = {
  title: "Height & Setbacks",
  description: "constraints_panel",
  requireUsages: true,
  closeOtherPanels: false,
}

const parkingConstraintsPanel: Panel = {
  title: "Parking",
  description: "parking_panel",
  requireUsages: true,
  closeOtherPanels: false,
}

const condoPanel: Panel = {
  title: "Condo",
  description: "condo_panel",
  requireUsages: true,
  closeOtherPanels: false,
  usageGroup: "condo"
}

const multifamilyPanel: Panel = {
  title: "Multifamily",
  description: "multifamily_panel",
  requireUsages: true,
  closeOtherPanels: false,
  usageGroup: "multifamily"
}

const hotelPanel: Panel = {
  title: "Hotel",
  description: "hotel_panel",
  requireUsages: true,
  closeOtherPanels: false,
  usageGroup: "hotel"
}

const officePanel: Panel = {
  title: "Office",
  description: "office_panel",
  requireUsages: true,
  closeOtherPanels: false,
  usageGroup: "office"
}

const retailPanel: Panel = {
  title: "Retail",
  description: "retail_panel",
  requireUsages: true,
  closeOtherPanels: false,
  usageGroup: "retail"
}

const industrialPanel: Panel = {
  title: "Industrial",
  description: "industrial_panel",
  requireUsages: true,
  closeOtherPanels: false,
  usageGroup: "industrial"
}

const pdfPanel: Panel = {
  title: "Export PDF Report",
  description: "pdf_panel",
  requireUsages: false,
  closeOtherPanels: false,
}

const backOfEnvelopePanel: Panel = {
  title: "Return Analysis",
  description: "back_of_envelope",
  requireUsages: true,
  closeOtherPanels: false,
}

export default {
  projectInfoPanel,
  projectCostPanel,
  heightAndSetbacksPanel,
  parkingConstraintsPanel,
  condoPanel,
  multifamilyPanel,
  hotelPanel,
  officePanel,
  retailPanel,
  industrialPanel,
  pdfPanel,
  backOfEnvelopePanel
}
