import { Slideshow, SlideshowType } from "../../../../types/Slideshow"

const smartSearch: Slideshow = {
  label: "Search Engine",
  text: "Remove the tediousness of searching. With targeted searches, you can quickly identify prospect properties.",
  className: "smart-search"
}

const zoningData: Slideshow = {
  label: "Zoning Data",
  text: "Easier access to information about a site, allowing for a quicker understanding of what you can do with the current zoning.",
  className: "zoning-data"
}

const assumptions: Slideshow = {
  label: "Model Assumptions",
  text: "Save time finding the best development approach by modeling your assumptions and seeing the financial returns.",
  className: "assumptions"
}

const backOfEnvelope: Slideshow = {
  label: "Return Analysis",
  text: "Quicker than building a proforma, forecast the financial approach for any investment and see what can be done on a site.",
  className: "back-of-envelope"
}

const customPdf: Slideshow = {
  label: "Custom PDF",
  text: "Improve deal-flow with a strong visual report to present investment options. Expedite creation of property-level information.",
  className: "custom-pdf"
}

const parcelAndUses: Slideshow = {
  label: "Parcel & Uses",
  text: "Determine which deals are worth deeper exploration by testing multiple scenarios for different property uses.",
  className: "parcel-and-uses"
}

const drawParcel: Slideshow = {
  label: "Draw Parcel",
  text: "Identify sites of interest, draw the parcel outline over the map and do quick studies of the development potential.",
  className: "draw-parcel"
}

const config = {
  [SlideshowType.WithZoning]: [
    smartSearch,
    zoningData,
    assumptions,
    backOfEnvelope,
    customPdf,
  ],
  [SlideshowType.WithoutZoning]: [
    smartSearch,
    parcelAndUses,
    assumptions,
    backOfEnvelope,
    customPdf,
  ],
  [SlideshowType.OnlyDrawing]: [
    drawParcel,
    parcelAndUses,
    assumptions,
    backOfEnvelope,
    customPdf,
  ],
}

export default config;
