import React from "react";
import { connect } from "react-redux";
import { userSelectors } from "../../../../../../state/user";
import { Tier } from "../../../../../../state/user/reducers";
import { Path } from "../../../../../../types/Path";
import RedirectButton from "../../../../../sharedComponents/RedirectButton";
import SiteAnalysisButton from "../../sharedComponents/SiteAnalysisButton";

const mapStateToProps = (state) => {
  return {
    tier: userSelectors.getTier(state),
  }
}

type MapStateProps = ReturnType<typeof mapStateToProps>;
type Props = MapStateProps;

class SiteAnalysisLink extends React.PureComponent<Props, {}> {
  render() {
    return (
      <div className="application-link">
        <div className="site-analysis-icon" />
        <h5>Site Analysis</h5>
        <p>Filter property criteria, like vacant land and lot size, to search for investment opportunities.</p>
        {(this.props.tier === Tier.Standard)
            ? <RedirectButton text="UPGRADE" path={Path.Subscribe} classes="basic" />
            : <SiteAnalysisButton text="SEARCH ENGINE"/>
        }
      </div>
    )
  }
}

export default connect(mapStateToProps)(SiteAnalysisLink);
