import { Config } from "../../../../../../../types/Config";
import { VariableId } from "../../../../../../../types/VariableId";
import Format from "../../../../../../../types/Format";

const config: Config = {
  [VariableId.CondoProfit]: {
    variableId: VariableId.CondoProfit,
    text: "Condo Profit",
    formatOptions: { type: Format.Type.Currency },
  },

  [VariableId.RentalUsesAnnualNetOperatingIncomeForBackOfEnvelope]: {
    variableId: VariableId.RentalUsesAnnualNetOperatingIncomeForBackOfEnvelope,
    text: (buildingUse) => `${buildingUse} NOI (Net Operating Income)`,
    formatOptions: { type: Format.Type.Currency },
  },

  [VariableId.HotelAnnualNetOperatingIncomeForBackOfEnvelope]: {
    variableId: VariableId.HotelAnnualNetOperatingIncomeForBackOfEnvelope,
    text: "Hotel NOI (Net Operating Income)",
    formatOptions: { type: Format.Type.Currency },
  },

  [VariableId.CondoTotalDevelopmentCostIncludingParking]: {
    variableId: VariableId.CondoTotalDevelopmentCostIncludingParking,
    text: "Condo Cost",
    formatOptions: { type: Format.Type.Currency },
  },

  [VariableId.RentalUsesTotalDevelopmentCostIncludingParking]: {
    variableId: VariableId.RentalUsesTotalDevelopmentCostIncludingParking,
    text: (buildingUse) => `${buildingUse}  Cost`,
    formatOptions: { type: Format.Type.Currency },
  },

  [VariableId.HotelTotalDevelopmentCostIncludingParking]: {
    variableId: VariableId.HotelTotalDevelopmentCostIncludingParking,
    text: "Hotel Cost",
    formatOptions: { type: Format.Type.Currency },
  },

  [VariableId.ProjectTotalDevelopmentCost]: {
    variableId: VariableId.ProjectTotalDevelopmentCost,
    text: "Total Project Cost",
    formatOptions: { type: Format.Type.Currency },
  },

  [VariableId.CondoReturnOnCost]: {
    variableId: VariableId.CondoReturnOnCost,
    text: "Condo",
    formatOptions: {
      type: Format.Type.Percentage,
      decimalPlaces: 2,
    },
  },

  [VariableId.RentalUsesAnnualReturnOnCostForBackOfEnvelope]: {
    variableId: VariableId.RentalUsesAnnualReturnOnCostForBackOfEnvelope,
    text: (buildingUse) => `${buildingUse}`,
    formatOptions: {
      type: Format.Type.Percentage,
      decimalPlaces: 2,
    },
  },

  [VariableId.HotelAnnualReturnOnCostForBackOfEnvelope]: {
    variableId: VariableId.HotelAnnualReturnOnCostForBackOfEnvelope,
    text: "Hotel",
    formatOptions: {
      type: Format.Type.Percentage,
      decimalPlaces: 2,
    },
  }
};

export default config;
