import firebase from "firebase/app";
import databaseHelper from "../../databaseHelper";

const MISSING_TIME_MODIFIED = -1; // Flag indicating that the project still doesn't have a timeModified value.

/**
 * Add timeModified value to entries in user projects list.
 */
const userProjectsTimestamp = async (userData) => {
  let userModified = false;
  for (let projectId of Object.keys(userData.projects)) {
    if (userData.projects[projectId].timeModified === MISSING_TIME_MODIFIED) {
      let projectReference = firebase.firestore().doc(`projects/${projectId}`);
      let projectData = await databaseHelper.getDocument(projectReference);
      userData.projects[projectId].timeModified = projectData.timeModified || null;
      userModified = true;
    }
  }

  return userModified;
}

export default userProjectsTimestamp;
