import "./polyfill"; // IMPORTANT: This should be the first import of our entire Application.
import React from "react";
import ReactDOM from "react-dom";
import { Provider as ReduxProvider } from "react-redux";
import { BrowserRouter } from "react-router-dom"
import App from "./views/App";
import reduxStore from "./state/reduxStore";
import registerServiceWorker from "./registerServiceWorker";
import initializeFirebase from "./utils/initializeFirebase";

// Initialize Firebase app.
initializeFirebase();

ReactDOM.render(
  <ReduxProvider store={reduxStore}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </ReduxProvider>,
  document.getElementById("root")
);

registerServiceWorker();
