const LOAD_START = "subscription/LOAD_START";
const LOAD_BY_CUSTOMER_START = "subscription/LOAD_BY_CUSTOMER_START";
const LOAD_SUCCESS = "subscription/LOAD_SUCCESS";
const SET_PLAN_PRICES = "subscription/SET_PLAN_PRICES";

export default {
  LOAD_START,
  LOAD_BY_CUSTOMER_START,
  LOAD_SUCCESS,
  SET_PLAN_PRICES,
};
