/**
 * @fileoverview Cloud Firestore cannot store nested arrays in the saved JSON.
 * To overcome this, we "wrap" every nested array in an object, whose only
 * element is the nested array, indexed by a special flag value to indicate the
 * contained value should be a nested array. This file manages the
 * transformation of JSON objects to and from the Firestore-compatible format.
 *
 * Example:
 *
 * Original:
 *
 * ```
 * [
 *   [a, b],
 *   {
 *     x: valueX,
 *     y: [
 *       [c, d],
 *       [e, f]
 *     ]
 *   },
 *   valueZ
 * ]
 * ```
 *
 * Is transformed into:
 *
 * Firestore-Compatible:
 *
 * ```
 * [
 *   { NESTED_ARRAY: [a, b] },
 *   {
 *     x: valueX,
 *     y: [
 *       { NESTED_ARRAY: [c, d] },
 *       { NESTED_ARRAY: [e, f] },
 *     ]
 *   },
 *   valueZ
 * ]
 * ```
 */

 // WARNING: This value is a "magic string" that is saved to the database and used to identify nested arrays.
 // Any changes to this value _must_ be accompanied by a migration to update the existing values in the database.
const NESTED_ARRAY_FLAG = "NESTED_ARRAY_QwI6xefsky";

/**
 * Transform nested arrays into an object that can be saved on Cloud Firestore.
 */
const toFirestoreCompatibleFormat = (root) => {
  if (typeof root !== "object" || root === null) return root;

  if (Array.isArray(root)) {
    let newArray: any[] = [];
    root.forEach(
      (element) => {
        if (Array.isArray(element)) {
          let wrapperObject = {};
          wrapperObject[NESTED_ARRAY_FLAG] = toFirestoreCompatibleFormat(element);
          newArray.push(wrapperObject);
        } else {
          newArray.push(toFirestoreCompatibleFormat(element));
        }
      }
    );

    return newArray;
  } else {
    let newObject = {};
    Object.keys(root).forEach(
      (key) => {
        newObject[key] = toFirestoreCompatibleFormat(root[key]);
      }
    );

    return newObject;
  }
}

/**
 * Transform an object that can be saved on Cloud Firestore into nested arrays.
 */
const fromFirestoreCompatibleFormat = (root) => {
  if (typeof root !== "object" || root === null) return root;

  if (Array.isArray(root)) {
    let newArray: any[] = [];
    root.forEach(
      (element) => {
        newArray.push(fromFirestoreCompatibleFormat(element));
      }
    );

    return newArray;
  } else {
    let keysArray = Object.keys(root);
    if (keysArray.length === 1 && keysArray[0] === NESTED_ARRAY_FLAG) {
      return fromFirestoreCompatibleFormat(root[NESTED_ARRAY_FLAG]);
    } else {
      let newObject = {};
      Object.keys(root).forEach(
        (key) => {
          newObject[key] = fromFirestoreCompatibleFormat(root[key]);
        }
      );

      return newObject;
    }
  }
}

export default {
  toFirestoreCompatibleFormat,
  fromFirestoreCompatibleFormat
}
