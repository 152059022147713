import { capitalizeFirstLetter } from "../../../../../../utils/stringHelper";
import { getEnabledRentalUses } from "../../../../../utils/uiToggleHelper";

/**
 * Returns text for the header in `Lease Uses` tab on the back of envelope.
 */
const getRentalHeaderText = (toggles) => {
  let enabledRentalUses = getEnabledRentalUses(toggles);
  if (enabledRentalUses.length <= 1) return null;

  return enabledRentalUses.map((rentalUse) => {
    return capitalizeFirstLetter(rentalUse) // Capitalize first letter of each element in array
  }).reduce((previous, current, currentIndex) => {
    // Start string with first element
    if (!previous) return current;

    // Insert `&` before last element
    if (currentIndex > 0 && currentIndex === enabledRentalUses.length - 1) {
      return `${previous} & ${current}`;
    }

    // Insert `,` between middle elements
    return `${previous}, ${current}`;
  }, "")
}

/**
 * Returns text to display for multiple or single rental uses.
 */
const getRentalUseText = (toggles) => {
  let enabledRentalUses = getEnabledRentalUses(toggles);
  return enabledRentalUses.length === 1
      ? capitalizeFirstLetter(enabledRentalUses[0])
      : "Lease Uses"
}

export {
  getRentalUseText,
  getRentalHeaderText,
}
