import { takeEvery, call, put } from "redux-saga/effects";
import actionTypes from "./actionTypes";
import thumbnails from "../../utils/storage/developments/thumbnails";
import thumbnailActions from "./actions";

/**
 * Load the thumbnail of the given developmentId.
 */
function* loadStart(action) {
  try {
    let thumbnailImage = yield call(thumbnails.download, action.payload.developmentId);
    yield put(thumbnailActions.loadSuccess(action.payload.developmentId, thumbnailImage));
  } catch (error) {
    yield put(thumbnailActions.loadError(action.payload.developmentId));
  }
}

/**
 * Watcher for the loadStart task.
 */
function* watchLoadStart() {
  yield takeEvery(actionTypes.LOAD_START, loadStart);
}

export default {
  watchLoadStart
}
