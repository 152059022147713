import { Config } from "../../../../../../../types/Config";
import { VariableId } from "../../../../../../../types/VariableId";
import Format from "../../../../../../../types/Format";
import Unit from "../../../../../../../types/Unit";

const config: Config = {
  [VariableId.HotelAnnualRoomRevenueForBackOfEnvelope]: {
    variableId: VariableId.HotelAnnualRoomRevenueForBackOfEnvelope,
    text: "Room Revenue",
    formatOptions: { type: Format.Type.Currency },
  },

  [VariableId.HotelAnnualOtherRevenueForBackOfEnvelope]: {
    variableId: VariableId.HotelAnnualOtherRevenueForBackOfEnvelope,
    text: "Other Revenue",
    formatOptions: { type: Format.Type.Currency },
  },

  [VariableId.HotelAnnualOperatingExpenseForBackOfEnvelope]: {
    variableId: VariableId.HotelAnnualOperatingExpenseForBackOfEnvelope,
    text: "Operating Expenses",
    formatOptions: {
      type: Format.Type.Currency,
      negate: true,
    },
  },

  [VariableId.HotelAnnualNetOperatingIncomeForBackOfEnvelope]: {
    variableId: VariableId.HotelAnnualNetOperatingIncomeForBackOfEnvelope,
    text: "Hotel NOI",
    formatOptions: { type: Format.Type.Currency },
  },

  [VariableId.HotelTotalDevelopmentCostIncludingParking]: {
    variableId: VariableId.HotelTotalDevelopmentCostIncludingParking,
    text: "Hotel Project Cost",
    formatOptions: { type: Format.Type.Currency },
  },

  [VariableId.HotelGrossBuildableAreaIncludingParking]: {
    variableId: VariableId.HotelGrossBuildableAreaIncludingParking,
    text: "Hotel Buildable (Including Parking)",
    formatOptions: { type: Format.Type.Number },
    [Unit.System.Imperial]: {
      unitTarget: Unit.Type.SquareFeet,
      formatOptions: { suffix: " SF" },
    },
    [Unit.System.Metric]: {
      unitTarget: Unit.Type.SquareMeters,
      formatOptions: { suffix: " m²" },
    }
  },

  [VariableId.HotelDevelopmentCostPerAreaIncludingParking]: {
    variableId: VariableId.HotelDevelopmentCostPerAreaIncludingParking,
    formatOptions: {
      type: Format.Type.Currency,
      decimalPlaces: 2,
    },
    [Unit.System.Imperial]: {
      text: "Hotel All-In Cost PSF",
      abbreviationExpandedText: "Per Square Foot",
      unitTarget: Unit.Type.SquareFeet,
      unitIsInverse: true,
      formatOptions: { suffix: " / SF" },
    },
    [Unit.System.Metric]: {
      text: "Hotel All-In Cost PSM",
      abbreviationExpandedText: "Per Square Meter",
      unitTarget: Unit.Type.SquareMeters,
      unitIsInverse: true,
      formatOptions: { suffix: " / m²" },
    }
  },
};

export default config;
