import React from "react";
import BusySpinner from "../BusySpinner";

interface Props {
  message: string;
  secondaryMessage?: string;
  displaySpinner?: boolean;
}

export default class ActivityFeedback extends React.PureComponent<Props, {}> {
  render() {
    return (
      <div className="component--activity-feedback">
        <div className="header">
          {this.props.message}
        </div>
        <div className="secondary-text">
          {this.props.secondaryMessage}
        </div>
        <div className="spinner-wrapper">
          {this.props.displaySpinner ? <BusySpinner /> : null}
        </div>
      </div>
    );
  }
}
