import turf from "../../../../turf";

/**
 * - Migrate the parcel geometry from using an array of [latitude, longitude] to use a "pseudoGeoJson" format.
 * This format is going to be identical to the actual GeoJSON geometry with the exception that the nested
 * coordinates arrays will be nested integer-indexed objects. This is needed because Cloud Firestore does not accept
 * nested arrays.
 *
 * - Migrate the camera to be stored as an array rather than an object.
 *
 * - Migrate the setbacks from an array of setback types to an integer-indexed object in which each entry is an array of
 * setback types. This is needed to support multi polygon parcels.
 *
 * - Delete `parcel.center` and `parcel.vertices`. The new GeoJSON format stores the vertices natively in
 * `parcel.feature`, and allows us to derive the center easily.
 *
 * - Delete `buildingModel.partitionCount`. GeoJSON makes it easy to split polygons programatically, making this field
 * obsolete.
 */
const convertParcelToGeoJson = (development) => {
  // Migrate parcel data.
  let feature = latLonToGeoJson(development.parcel.vertices);
  development.parcel.feature = toPseudoGeoJson(feature);

  // Migrate camera target.
  development.camera.target = [development.camera.target.longitude, development.camera.target.latitude];

  // Migrate setbacks.
  development.buildingModel.setbackSchedule[0].setbacks = [
    setbacksToDatabaseFormat(development.buildingModel.setbackSchedule[0].setbacks)
  ];

  // Remove obsolete parcel geometry data.
  // Firestore sentinel values are not needed for deletion because this is a nested object.
  delete development.parcel.vertices;
  delete development.parcel.center;

  // Remove obsolete geometry partitioning data.
  // Firestore sentinel values are not needed for deletion because this is a nested object.
  delete development.buildingModel.partitionCount;
}

/**
 * Convert vertices array to coordinates object.
 */
const latLonToGeoJson = (vertices) => {
  let verticesCopy = vertices.slice();

  verticesCopy.push(verticesCopy[0]);
  let ring = verticesCopy.map((vertex) => [vertex.longitude, vertex.latitude]);
  return turf.polygon([ring]);
}

/**
 * Transform GeoJSON object to a "pseudo GeoJSON" (coordinates are nested integer-indexed objects instead
 * of nested arrays) object.
 */
const toPseudoGeoJson = (geoJson) => {
  let coordinates = getCoordinates(geoJson);
  coordinates = coordinatesToPseudoCoordinates(coordinates);
  return setCoordinates(geoJson, coordinates);
}

/**
 * Get coordinates from a GeoJSON object.
 */
const getCoordinates = (geoJson) => {
  return geoJson.geometry.coordinates;
}

/**
 * Convert from coordinates (i.e. nested arrays) to pseudo coordinates (i.e. nested integer indexed objects).
 */
const coordinatesToPseudoCoordinates = (input) => {
  // Base Case:
  if (typeof input === "number") return input;

  let result = {};
  input.forEach((element, index) => { result[index] = coordinatesToPseudoCoordinates(element) });
  return result;
}

/**
 * Set coordinates to a GeoJSON object.
 */
const setCoordinates = (geoJson, coordinates) => {
  return {
    ...geoJson,
    geometry: {
      ...geoJson.geometry,
      coordinates
    }
  }
};

/**
 * Convert setbacks from  nested arrays to nested integer indexed objects.
 */
const setbacksToDatabaseFormat = (setbacks) => {
  // Base Case:
  if (typeof setbacks === "string") return setbacks;

  let result = {};
  setbacks.forEach((element, index) => { result[index] = setbacksToDatabaseFormat(element) });
  return result;
}

export default convertParcelToGeoJson;
