import log from "loglevel";
import React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { withRouter } from "react-router-dom";
import { Chargebee } from "../../../../../types/Service/Chargebee";
import { subscriptionActions, subscriptionSelectors } from "../../../../../state/subscription";
import chargebee from "../../../../../utils/functions/chargebee";
import chargebeeInstance from "../../../../../utils/chargebee/instance";
import analytics from "../../../../../utils/analytics";
import authentication from "../../../../../utils/authentication";
import { Path } from "../../../../../types/Path";

// The number of milliseconds a user views a thank you message before being
// redirected away from the subscribe page after completing a purchase.
const THANK_YOU_MESSAGE_DELAY = 5000;

const mapStateToProps = (state) => {
  return {
    subscriptionId: subscriptionSelectors.getId(state),
    currentPlanId: subscriptionSelectors.getPlanId(state),
  }
}

const mapDispatchToProps = {
  loadSubscription: subscriptionActions.loadStart,
}

type OwnProps = {
  paymentPlanId: Chargebee.PlanId;
  text: string;
  onSelect(subscriptionPlanId: Chargebee.PlanId);
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;
type Props = StateProps & DispatchProps & RouteComponentProps & OwnProps;

class SubscribeButton extends React.PureComponent<Props, {}> {
  successTimerId: any;
  successfulCheckout: boolean;
  hostedPageId: string | null;

  constructor(props) {
    super(props);

    this.successfulCheckout = false;
    this.hostedPageId = null;
  }

  /**
   * Close the chargebee portal before un-mounting.
   */
  componentWillUnmount() {
    chargebeeInstance().closeAll();
  }

  /**
   * Cleans up the Chargebee modal and updates the user's subscription status.
   */
  finish = (): void => {
    clearTimeout(this.successTimerId);
    analytics.trackCheckoutClose(this.successfulCheckout ? this.props.paymentPlanId : this.props.currentPlanId);
    chargebeeInstance().closeAll();

    this.props.subscriptionId && this.props.loadSubscription(this.props.subscriptionId);
    this.successfulCheckout && this.props.history.push(Path.Dashboard);
  }

  /**
   * Gives the user a moment to view a thank you message and then updates the
   * subscription state.
   */
  handleSuccess = (hostedPageId): void => {
    this.hostedPageId = hostedPageId;
    this.successfulCheckout = true;
    analytics.trackCheckoutSuccess(this.props.paymentPlanId);
    this.successTimerId = setTimeout(() => this.finish(), THANK_YOU_MESSAGE_DELAY);
  }

  /**
   *  Handle checkout error.
   */
  handleError = (error): void => {
    log.error(error);
  }

  /**
   * Returns a Promise that resolves to a hosted page object.
   */
  fetchHostedPage = async (): Promise<Chargebee.HostedPage> => {
    const { subscriptionId, paymentPlanId } = this.props;
    if (!subscriptionId) throw Error("Subscription ID missing");

    return await chargebee.getHostedCheckoutPage(paymentPlanId, subscriptionId);
  }

  /**
   * Open chargebee checkout modal.
   */
  handleSubscribeClick = (): void => {
    const userIsAuthenticated = authentication.isUserAuthenticated();
    if (!userIsAuthenticated || (userIsAuthenticated && !this.props.subscriptionId)) {
      this.props.onSelect(this.props.paymentPlanId);
      return;
    }

    analytics.trackCheckoutStart(this.props.paymentPlanId);
    chargebeeInstance().openCheckout({
      hostedPage: this.fetchHostedPage,
      success: (hostedPageId) => this.handleSuccess(hostedPageId),
      error: this.handleError,
      close: () => this.finish(),
    });
  }

  render() {
    return (
      <button className="component--subscribe-button" onClick={() => this.handleSubscribeClick()}>{this.props.text}</button>
    );
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(SubscribeButton)
) as any;
