import { PdfState } from "./reducers";
import Pdf from "../../types/Pdf";
import { ReactMapboxGlCamera } from "../../types/ReactMapboxGlCamera";

/**
 * Select the whole pdf object.
 */
const getPdf = (state): PdfState => {
  return state.pdf;
}

/**
 * Select the isActive value.
 */
const getIsActive = (state): boolean => {
  let pdf = getPdf(state);
  return pdf.isActive;
}

/**
 * Select the isReady value.
 */
const getIsReady = (state): boolean => {
  let pdf = getPdf(state);
  return Boolean(pdf.isReady);
}

/**
 * Select the mapImages object.
 */
const getMapImages = (state) => {
  let pdf = getPdf(state);
  return pdf.mapImages;
}

/**
 * Select the map image for the given image type.
 */
const getMapImage = (state, imageType: Pdf.ImageType): string | null => {
  let mapImages = getMapImages(state);
  return (mapImages && mapImages[imageType]) || null;
}

/**
 * Select the camera object.
 */
const getCamera = (state): ReactMapboxGlCamera | null => {
  let pdf = getPdf(state);
  return pdf.camera || null;
}

/**
 * Select the cover page image for the given type.
 */
const getCoverPageImage = (state, imageType: Pdf.ImageType): Pdf.Image | null => {
  let pdf = getPdf(state);
  return (pdf.coverPageImages && pdf.coverPageImages[imageType]) || null;
}

/**
 * Select the coverPageImages object.
 */
const getCoverPageImages = (state) => {
  let pdf = getPdf(state);
  return pdf.coverPageImages;
}

/**
 * Select the title value.
 */
const getTitle = (state) => {
  let pdf = getPdf(state);
  return pdf.title;
}

/**
 * Select the summary value.
 */
const getSummary = (state) => {
  let pdf = getPdf(state);
  return pdf.summary;
}

/**
 * Select the fromContactDetails object.
 */
const getFromContactDetails = (state) => {
  let pdf = getPdf(state);
  return pdf.fromContactDetails;
}

/**
 * Select the toContactDetails object.
 */
const getToContactDetails = (state) => {
  let pdf = getPdf(state);
  return pdf.toContactDetails;
}

/**
 * Select the colorPalette object.
 */
const getColorPalette = (state) => {
  let pdf = getPdf(state);
  return pdf.colorPalette;
}

/**
 * Select Document properties from pdf object for saving.
 */
const getPdfDocument = (state): Pdf.Document => {
  let pdf = getPdf(state);

  return {
    colorPalette: pdf.colorPalette,
    fromContactDetails: pdf.fromContactDetails,
    toContactDetails: pdf.toContactDetails,
    summary: pdf.summary,
    title: pdf.title,
    address: pdf.address,
  }
}

/**
 * Select Address object.
 */
const getAddress = (state): Pdf.Address => {
  let pdf = getPdf(state);
  return pdf.address;
}

export default {
  getIsActive,
  getIsReady,
  getMapImages,
  getMapImage,
  getCamera,
  getTitle,
  getSummary,
  getFromContactDetails,
  getToContactDetails,
  getColorPalette,
  getCoverPageImages,
  getCoverPageImage,
  getPdfDocument,
  getAddress,
}
