import React from "react";
import { connect } from "react-redux";
import { developmentSelectors } from "../../../../../../state/development";
import developmentAccessors from "../../../../../../state/development/utils/developmentAccessors";
import Format from "../../../../../../types/Format";
import Unit from "../../../../../../types/Unit";
import valueFormatter from "../../../../../../utils/valueFormatter";
import unitConversions from "../../../../../../utils/unitConversions";

interface Props {
  value: number;
  variableId: string;
  text: string;
  renderOwnBorder: boolean;
  formatOptions: Format.Options;
  unitTarget: Unit.Type;
  unitIsInverse: boolean;
}

class PanelSectionTitle extends React.PureComponent<Props, any> {
  render() {
    const { text, value, renderOwnBorder, formatOptions, unitTarget, unitIsInverse } = this.props;
    let finalValue = unitTarget
      ? unitConversions.convertFromBase(value, unitTarget, unitIsInverse)
      : value;

    finalValue = formatOptions
      ? valueFormatter.format(finalValue, formatOptions)
      : finalValue;

    return (
      <div className={`panel-section-title ${renderOwnBorder ? "render-own-border" : ""}`}>
        <h1>{text}</h1>
        <span>{finalValue}</span>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  let development = developmentSelectors.getDevelopment(state);
  return {
    value: developmentAccessors.getInputValue(development, ownProps.variableId) as number,
  };
};

export default connect(
  mapStateToProps
)(PanelSectionTitle);
