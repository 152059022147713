import React from "react";
import { connect } from "react-redux";
import { newDevelopmentSelectors, newDevelopmentActions } from "state/newDevelopment";
import SearchPanel from "./SearchPanel";
import { userSelectors } from "../../../../state/user";
import { Tier } from "../../../../state/user/reducers";
import { listingsSelectors } from "../../../../state/listings";

const mapStateToProps = (state) => {
  return {
    smartSearchIsOpen: newDevelopmentSelectors.getSmartSearchIsOpen(state),
    selectedParcel: newDevelopmentSelectors.getSelectedFeature(state),
    selectedListing: listingsSelectors.getSelectedListings(state),
    tier: userSelectors.getTier(state),
  }
}

const mapDispatchToProps = {
  setSmartSearchIsOpen: newDevelopmentActions.setSmartSearchIsOpen,
};

type MapStateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

type Props = MapStateProps & DispatchProps;

class SmartSearchPanel extends React.PureComponent<Props, {}> {
  /**
   * Open smart search.
   */
  openSmartSearch = () => this.props.setSmartSearchIsOpen(true);

  /**
   * Close smart search.
   */
  closeSmartSearch = () => this.props.setSmartSearchIsOpen(false);

  render() {
    const { smartSearchIsOpen, selectedParcel, tier, selectedListing } = this.props;
    if (selectedParcel || selectedListing) return null;
    if (tier === Tier.Standard) return null;

    return (
      <div className="component--smart-search-panel">
        <div className="header">Search Engine</div>
        {smartSearchIsOpen && <div className="experimental">Experimental feature.</div>}
        {smartSearchIsOpen && <div className="disclaimer">(Only fields with available data are shown)</div>}
        {smartSearchIsOpen && <SearchPanel />}
        {smartSearchIsOpen
          ? <div className="collapse" onClick={this.closeSmartSearch} />
          : <div className="expand" onClick={this.openSmartSearch} />
        }
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SmartSearchPanel);
