import { thumbnailsActionTypes, thumbnailsActions } from "./thumbnails";
import { pdfActionTypes, pdfSelectors, pdfActions } from "./pdf";
import { thumbnailSelectors } from "./thumbnails/thumbnail";
import Pdf from "../types/Pdf";
import { developmentActionTypes } from "./development";

/**
 * @fileoverview This middleware is responsible for ensuring that object URLs
 * are revoked at the appropriate times. URLs for objects that are no longer
 * being used will take up memory until they are revoked, so this functionality
 * is important for preventing memory leaks.
 */

/**
 * The middleware function. Detects actions that involve object URL updates and
 * revokes the appropriate object URLs.
 */
const objectUrlMiddleware = (store) => (next) => (action) => {
  switch (action.type) {
    case developmentActionTypes.INITIALIZE:
    case pdfActionTypes.CLEAR_STATE:
      revokeAllPdfCoverImages(store);
      break;
    case pdfActionTypes.SET_MAP_IMAGE:
      revokeSetMapImage(store, action);
      break;
    case thumbnailsActionTypes.DELETE:
      revokeDevelopmentThumbnailForDelete(store, action);
      break;
    case thumbnailsActionTypes.LOAD_SUCCESS:
      revokeDevelopmentThumbnailForLoad(store, action);
      break;
    case pdfActionTypes.DELETE_COVER_PAGE_IMAGE_SUCCESS:
      revokePdfCoverImagesForDelete(store, action);
      break;
    case pdfActionTypes.FINISH:
      revokePdfMapImages(store);
      break;
    default: break;
  }

  return next(action);
}

/**
 * Revoke previous map image before setting new one on the store.
 */
const revokeSetMapImage = (store, action: ReturnType<typeof pdfActions.setMapImage>) => {
  revokePdfMapImage(store, action.payload.imageType);
}

/**
 * Revokes all pdf cover images from the the store.
 */
const revokeAllPdfCoverImages = (store) => {
  revokePdfCoverImage(store, Pdf.ImageType.Cover);
  revokePdfCoverImage(store, Pdf.ImageType.Logo);
}

/**
 * Revokes all map images from the the store.
 */
const revokePdfMapImages = (store) => {
  revokePdfMapImage(store, Pdf.ImageType.Building);
  revokePdfMapImage(store, Pdf.ImageType.Location);
  revokePdfMapImage(store, Pdf.ImageType.Parcel);
  revokePdfMapImage(store, Pdf.ImageType.Cover);
}

/**
 * Revoke specific cover page image before deleting.
 */
const revokePdfCoverImagesForDelete = (store, action: ReturnType<typeof pdfActions.deleteCoverPageImageSuccess>) => {
  revokePdfCoverImage(store, action.payload.imageType);
}

/**
 * Revoke existing thumbnail image before loading.
 */
const revokeDevelopmentThumbnailForLoad = (store, action: ReturnType<typeof thumbnailsActions.loadSuccess>) => {
  revokeDevelopmentThumbnail(store, action.payload.developmentId);
}

/**
 * Revoke existing thumbnail image before deleting.
 */
const revokeDevelopmentThumbnailForDelete = (store, action: ReturnType<typeof thumbnailsActions.deleteThumbnail>) => {
  revokeDevelopmentThumbnail(store, action.payload.developmentId);
}

/**
 * Revokes the object URL for a particular development thumbnail in the store.
 */
const revokeDevelopmentThumbnail = (store, developmentId: string): void => {
  let imageUrl = thumbnailSelectors.getImageUrl(store.getState(), developmentId);
  if (imageUrl) URL.revokeObjectURL(imageUrl);
}

/**
 * Revokes the object URL for a particular pdf cover image in the store.
 */
const revokePdfCoverImage = (store, imageType: Pdf.ImageType): void => {
  let image = pdfSelectors.getCoverPageImage(store.getState(), imageType);
  if (image && image.url) URL.revokeObjectURL(image.url);
}

/**
 * Revokes the object URL for a particular pdf map image in the store.
 */
const revokePdfMapImage = (store, imageType: Pdf.ImageType): void => {
  let image = pdfSelectors.getMapImage(store.getState(), imageType);
  if (image) URL.revokeObjectURL(image);
}

export default objectUrlMiddleware;
