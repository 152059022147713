import React, { ReactElement } from "react";
import ReactDOM from "react-dom";
import { CSSTransitionGroup } from "react-transition-group";

interface State {
  isOpen: boolean;
}

interface Props {
  preventClose?: boolean;
  className?: string;
  onStateChange?();
}

export default class Popup extends React.PureComponent<Props, State> {
  animationTime: number;
  popUpContainer: HTMLElement;

  constructor(props) {
    super(props);

    this.state = {
      isOpen: true
    };

    // This animation time MUST match the one on in the .scss file.
    this.animationTime = 300;
    this.popUpContainer = document.getElementById("pop-up-container") as HTMLElement;
  }

  /**
   * Handle the popup closing.
   */
  closePopup = () => {
    if (this.props.preventClose) return;

    // Setting isOpen state is important to make the child component of CSSTransitionGroup
    // leave the parents. Without this step transitionLeave is never triggered.
    this.setState({ isOpen: false });

    // It is important that we do not re-render the parent component before the transition ends.
    if (this.props.onStateChange) setTimeout(this.props.onStateChange, this.animationTime);
  }

  /**
   * Conditionally render the popup.
   */
  renderPopup = () => {
    // Add the closePopup prop to the children.
    let children = this.props.children;
    let childrenWithProps = React.Children.map(children, (child) =>
      React.cloneElement(child as ReactElement<any>, { ...this.props, closePopup: this.closePopup }));

    if (this.state.isOpen) {
      return (
        <div className={`popup ${this.props.className || ""}`} onMouseDown={this.closePopup}>
          <div onMouseDown={(event) => event.stopPropagation()}>
            {childrenWithProps}
          </div>
        </div>
      );
    }

    return null;
  }

  /**
   * Render CSSTransitionGroup component.
   */
  renderCssTransitionGroup = () => {
    return (
      <CSSTransitionGroup
        transitionName="popup-container"
        transitionAppear={true}
        transitionAppearTimeout={this.animationTime}
        transitionEnterTimeout={this.animationTime}
        transitionLeaveTimeout={this.animationTime}
      >
        {this.renderPopup()}
      </CSSTransitionGroup>
    )
  }

  render() {
    return ReactDOM.createPortal(this.renderCssTransitionGroup(), this.popUpContainer);
  }
}
