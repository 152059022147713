import developmentAccessors from "../developmentAccessors"
import parcelAccessors from "../../../../utils/parcel/parcelAccessors";
import { Development } from  "../../../../types/Development/Development";

/**
 * @fileoverview This module is responsible for computations pertaining to
 *  a project's parcel of land.
 */

/**
 * Modify the development object by updating data about the building site
 * for the given development object.
 */
const update = (development: Development) => {
  updateArea(development);
};

/**
 * Modify the development object by updating the site area.
 */
const updateArea = (development: Development) => {
  const parcel = developmentAccessors.getParcel(development);

  development.values.parcelArea =
    parcelAccessors.getAreaPreferPublished(parcel);

  development.values.publishedDataAreaUnitsPerGeometricAreaUnit =
    development.values.parcelArea / parcelAccessors.getAreaComputed(parcel);

  development.values.publishedDataDistanceUnitsPerGeometricDistanceUnit =
    Math.sqrt(development.values.publishedDataAreaUnitsPerGeometricAreaUnit);
}

export default {
  update
};
