import React from "react";
import styled from "@react-pdf/styled-components";
import PdfContext from "../../../PdfContext";

const TOTAL_PIXELS_FOR_TITLE = 320;
const DEFAULT_TITLE = "Property Report";

const Container = styled.View`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 132px;
  right: 0;
  width: 232px;
  height: 88px;
  background-color: ${(props) => props.backgroundColor};
  padding: 16px 4px;
`;

const TitleText = styled.Text`
  font-size: ${(props) => `${getTitleFontSize(props.textLength)}px`};
  color: ${(props) => props.color};
  text-transform: uppercase;
`;

const getTitleFontSize = (textLength: number) => {
  return Math.min(TOTAL_PIXELS_FOR_TITLE / textLength, 40);
}

const Title = () => (
  <PdfContext.Consumer>
    {({ title, colorPalette }) => {
      const coverTitle = title || DEFAULT_TITLE;

      return (
        <Container backgroundColor={colorPalette.primary}>
          <TitleText color={colorPalette.text} textLength={coverTitle.length}>
            {coverTitle}
          </TitleText>
        </Container>
      )
    }}
  </PdfContext.Consumer>
);

export default Title;
