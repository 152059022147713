import { createStore, applyMiddleware, compose } from "redux";
import rootReducer from "./rootReducer";
import autosaveMiddleware from "./autosaveMiddleware";
import objectUrlMiddleware from "./objectUrlMiddleware";
import createSagaMiddleware from "redux-saga";
import sagas from "./sagas";

// Saga Middleware set up
const sagaMiddleware = createSagaMiddleware()

// For use with `redux-devtools-extension` in your browser:
// https://github.com/zalmoxisus/redux-devtools-extension
//
// NOTE: `NODE_ENV` is a built-in create-react-app variable that is not defined
// by our own environment variables. It's value is `development` whenever the
// application is launched with `npm start`.
// See https://github.com/facebook/create-react-app/blob/master/packages/react-scripts/template/README.md#adding-custom-environment-variables
const reduxDevtoolsEnhancer = process.env.NODE_ENV === "development"
  ? window["__REDUX_DEVTOOLS_EXTENSION__"] && window["__REDUX_DEVTOOLS_EXTENSION__"]()
  : undefined;

const middleWare = applyMiddleware(
  autosaveMiddleware,
  objectUrlMiddleware,
  sagaMiddleware
);

// Some browsers (e.g. Firefox) will throw error if an undefined value is passed to the `compose` method.
const enhancers = reduxDevtoolsEnhancer ? compose(middleWare, reduxDevtoolsEnhancer) : middleWare

let reduxStore = createStore(
  rootReducer,
  enhancers
);

sagas.registerWithMiddleWare(sagaMiddleware);

export default reduxStore;
