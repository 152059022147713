import React from "react";
import styled from "@react-pdf/styled-components";

const Container = styled.View`
  position: absolute;
  top: 355px;
  right: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 3px 9px;
  background-color: #F5F7F8;
  border-radius: 2px;
  box-shadow: 10px 10px 8px #888888;
`;

const Box = styled.View`
  height: 7px;
  width: 14px;
  margin-right: 3px;
  background-color: #F6DC9F;
  border: 0.5px solid #F9B924;
  box-shadow: 20px 20px 1px black;
`;

const Text = styled.Text`
  font-family: "Roboto Condensed";
  font-size: 6.25px;
  color: #333333;
  text-transform: capitalize;
`;

const OpportunityZoneLegend = () => (
  <Container>
    <Box />
    <Text>Opportunity Zones</Text>
  </Container>
);

export default OpportunityZoneLegend;
