import * as convertUnits from "convert-units";
import Unit from "../types/Unit";

/**
 * @fileoverview This module provides unit conversion services.
 */

/**
 * Convert given value from unit type to another unit type.
 * Returns passed in value in case of error.
 */
const convert = (value, from: Unit.Type, to: Unit.Type, isInverse: boolean = false) => {
  try {
    if (typeof value !== "number") return value;

    if (isInverse) {
      return 1 / convertUnits(1 / value).from(from).to(to);
    }

    return convertUnits(value).from(from).to(to);
  } catch (error) {
    console.warn(error);
    return value;
  }
}

/**
 * Converts value from the base unit to the passed in unit type.
 */
const convertFromBase = (value, to: Unit.Type, isInverse: boolean = false) => {
  const baseUnit = getBaseUnit(to);
  return convert(value, baseUnit, to, isInverse);
}

/**
 * Converts value to the base unit from the passed in unit type.
 */
const convertToBase = (value, from: Unit.Type, isInverse: boolean = false) => {
  const baseUnit = getBaseUnit(from);
  return convert(value, from, baseUnit, isInverse);
}

/**
 * Get base unit of given unit type.
 */
const getBaseUnit = (unitType): Unit.Type => {
  return Unit.BASE_UNIT_TYPE_MAP[unitType];
}

export default {
  convert,
  convertFromBase,
  convertToBase,
};
