import React from "react";
import styled from "@react-pdf/styled-components";
import { View } from "@react-pdf/renderer";
import Pdf from "../../../../../../../types/Pdf";
import PdfContext from "../../../PdfContext";
import UsesLegend from "./UsesLegend";
import OpportunityZoneLegend from "./OpportunityZoneLegend";

const LocationImage = styled.Image`
  top: 0;
`;

const BuildingImage = styled.Image`
  top: 6px;
`;

const Images = () => (
  <PdfContext.Consumer>
    {({ mapImages }) => {
      return (
        <View>
          <LocationImage src={mapImages[Pdf.ImageType.Location]} />
          <OpportunityZoneLegend />
          <BuildingImage src={mapImages[Pdf.ImageType.Building]} />
          <UsesLegend />
        </View>
      )
    }}
  </PdfContext.Consumer>
);

export default Images;
