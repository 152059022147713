import floor from "../buildingModel/floor";
import equalWithinEpsilon from "../../../../utils/floatCompare";
import { Values } from  "../../../../types/Development/Values";
import { BuildingModel } from  "../../../../types/Development/BuildingModel";
import { BuildingUse } from  "../../../../types/BuildingUse";

/**
 * @fileoverview This module is responsible for determining how usages will be
 *  distributed throughout the floors of a building and generating a data
 *  structure to represent those floors.
 */

const MAXIMUM_NUMBER_OF_FLOORS = 400;

/**
 * An object containing all the gross buildable areas from the given set of
 * buildable quantities. The keys are the corresponding building usages.
 */

const grossBuildableAreas = (values: Values) => {
  return {
    [BuildingUse.Multifamily]: values.multifamilyGrossBuildableArea,
    [BuildingUse.Condo]: values.condoGrossBuildableArea,
    [BuildingUse.Hotel]: values.hotelGrossBuildableArea,
    [BuildingUse.Office]: values.officeGrossLeasableArea,
    [BuildingUse.Retail]: values.retailGrossBuildableArea,
    [BuildingUse.Industrial]: values.industrialGrossBuildableArea,
    [BuildingUse.Parking]: values.parkingGrossBuildableArea,
  };
};

/**
 * Allocate floors for the given building model based on the given buildable
 * quantities. The `floors` property of the given `buildingModel` is updated.
 *
 * @param {Object} buildingModel - The building model whose floors to update.
 * @param {Array} buildingModel.usageStackingOrder - An ordered array of usages
 *    (strings). The list is ordered first to last - the first usage in the list
 *    is allocated first.
 * @param {Object} buildingModel.setbackSchedule - An ordered list of setback
 *    objects. Each setback includes a `highestFloor` and a `footprint`. The
 *    `area` of the footprint is the area of each floor that conforms to that
 *    setback. Setbacks are ordered first to last - the first floor will conform
 *    to the first setback in the list.
 * @param {Object} values - An object containing the project values that will
 *    dictate how much of each usage must me allocated.
 * @param {number} baseElevation - The absolute elevation of the first floor.
 *    Defaults to 0.
 */
const update = (buildingModel: BuildingModel, values: Values, baseElevation = 0) => {
  let availableBuildableAreas = grossBuildableAreas(values);
  let availableSetbacks = buildingModel.setbackSchedule.slice();
  let usageStackingOrder = buildingModel.usageStackingOrder;

  // Ignore usages that are not provided for in the given totals.
  let remainingUsages = usageStackingOrder.filter((usage) => {
    return Boolean(availableBuildableAreas[usage]);
  });

  let results: any[] = [];

  let currentElevation = baseElevation;
  let currentUsage = remainingUsages.shift();
  let currentSetback = availableSetbacks.shift();
  if (currentSetback === undefined) {
    buildingModel.floors = [];
    return;
  }

  let currentFloor = floor.create(currentSetback.footprint, currentElevation, values.heightOfGroundFloor);
  let availableFloorArea = currentFloor.footprint.area;
  let areaToFill;
  while (currentUsage) {
    if (availableFloorArea === 0) break;

    // This is part of the HACK solution described bellow.
    if (results.length >= MAXIMUM_NUMBER_OF_FLOORS) break;

    areaToFill = Math.min(availableFloorArea, availableBuildableAreas[currentUsage]);
    currentFloor.usageAreas.push({ usage: currentUsage, area: areaToFill });
    availableFloorArea -= areaToFill;
    availableBuildableAreas[currentUsage] -= areaToFill;

    // If we have filled the current floor, push it to the results and move to the next floor.
    if (equalWithinEpsilon(availableFloorArea, 0)) {
      results.push(currentFloor);
      currentElevation += currentFloor.height;

      // Apply a new setback if appropriate.
      if (currentSetback && currentSetback.highestFloor === results.length && availableSetbacks.length > 0) {
        const newSetback = availableSetbacks.shift();
        if (newSetback) currentSetback = newSetback;
      }

      currentFloor = floor.create(currentSetback.footprint, currentElevation, values.heightOfTypicalFloor);
      availableFloorArea = currentFloor.footprint.area;
    }

    // If we have filled the required area for the current usage, move to the
    // next usage.
    if (equalWithinEpsilon(availableBuildableAreas[currentUsage], 0)) {
      currentUsage = remainingUsages.shift();
    }
  }

  // Add the final floor if it was only partially filled.
  if (availableFloorArea > 0 && floor.buildableArea(currentFloor) > 0) {
    results.push(currentFloor);
  }

  // HACK: This is a temporary bandaid for a performance issue that results from
  // tall buildings. When a building has many floors, an enormous JSON object
  // is produced. Combined with our inefficient methods for making copies of
  // this data, the result is unacceptable lag that may even crash the application.
  //
  // This is a short-term solution which simply returns no floors if the
  // building would otherwise have more than we are prepared to handle. While
  // we may still want to limit the number of floors, we should not be doing it
  // this way, and eliminating or replacing this implementation should be
  // considered HIGH PRIORITY.
  //
  // Once the performance issues have been addressed, and/or a better floor-
  // limiting solution has been designed, this patch should be replaced
  // immediately.
  //
  // If you come across this in the course of your work, please check with your
  // supervisor what the status of this issue is. This comment may be edited as
  // appropriate to keep it up to date.
  if (results.length < MAXIMUM_NUMBER_OF_FLOORS) {
    buildingModel.floors = results;
  } else {
    buildingModel.floors = [];
  }
};

export default {
  update,
}
