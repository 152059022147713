import React from "react";
import companyInfo from "../../../utils/companyInfo";

const BrandingWatermark = () => {
  return (
    <div className="component--branding-watermark">
      <a href={companyInfo.WEBSITE_URL} target="_blank" rel="noopener noreferrer">
        <div className="icon-watermark"/>
      </a>
    </div>
  );
}

export default BrandingWatermark;
