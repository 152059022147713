import React from "react";
import styled from "@react-pdf/styled-components";
import AddressBar from "../AddressBar";
import PdfContext from "../../../PdfContext";

const DEFAULT_LEFT_PADDING = 25;

const Container = styled.View`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 48px;
  background: ${(props) => props.backgroundColor};
  padding-top: 20px;
  padding-left: ${(props) => props.leftPadding || DEFAULT_LEFT_PADDING}px;
`;

interface Props {
  leftPadding?: number
}

const Header = (props: Props) => (
  <PdfContext.Consumer>
    {({ colorPalette }) => (
      <Container backgroundColor={colorPalette.primary} leftPadding={props.leftPadding} fixed>
        <AddressBar textColor={colorPalette.text} />
      </Container>
    )}
  </PdfContext.Consumer>
);

export default Header;
