import { combineReducers } from "redux";
import developmentReducer from "./development";
import newDevelopmentReducer from "./newDevelopment";
import subscriptionReducer from "./subscription";
import pdfReducer from "./pdf";
import thumbnailsReducer from "./thumbnails";
import userReducer from "./user";
import listingsReducer from "./listings";
import uiReducer from "./ui";
import { authenticationActionTypes } from "./authentication";

const appReducer = combineReducers({
  development: developmentReducer,
  newDevelopment: newDevelopmentReducer,
  subscription: subscriptionReducer,
  pdf: pdfReducer,
  thumbnails: thumbnailsReducer,
  user: userReducer,
  listings: listingsReducer,
  ui: uiReducer,
});

export default (state, action) => {
  switch (action.type) {
    case authenticationActionTypes.SIGN_OUT_SUCCESS:
      return appReducer(undefined, action);
    default:
      return appReducer(state, action);
  }
}
