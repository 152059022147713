import { ListingsState } from "./reducers";

/**
 * Select the listings state.
 */
const getListingsState = (state): ListingsState => {
  return state.listings;
}

/**
 * Select the listings list.
 */
const getListings = (state) => {
  let listingsState = getListingsState(state);
  return listingsState.listings;
}

/**
 * Select the selected listing.
 */
const getSelectedListings = (state) => {
  let listingsState = getListingsState(state);
  return listingsState.selectedListings;
}

export default {
  getListings,
  getSelectedListings,
}
