import React from "react";
import { connect } from "react-redux";
import { newDevelopmentSelectors, newDevelopmentActions } from "../../../../state/newDevelopment";
import ProjectInformationTable from "../../../sharedComponents/ParcelInformationTable";
import InitialValuesPanel from "../InitialValuesPanel";
import analytics from "../../../../utils/analytics";
import { userSelectors } from "../../../../state/user";
import { Tier } from "../../../../state/user/reducers";
import { PropertyListingTabContainer } from "../../../sharedComponents/PropertyListingTabContainer/PropertyListingTabContainer";
import { listingsSelectors } from "../../../../state/listings";

const mapStateToProps = (state) => {
  return {
    selectedFeature: newDevelopmentSelectors.getSelectedFeature(state),
    selectedListing: listingsSelectors.getSelectedListings(state),
    tier: userSelectors.getTier(state),
  }
}

const mapDispatchToProps = {
  setSmartSearchIsOpen: newDevelopmentActions.setSmartSearchIsOpen,
  clearFeatureSelection: newDevelopmentActions.clearFeatureSelection,
};

interface OwnProps {
  openInitialValuesPanel();
}

type MapStateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;
type Props = MapStateProps & DispatchProps & OwnProps;

interface State {
  initialValuesIsOpen: boolean;
}

class ParcelInformationPanel extends React.PureComponent<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      initialValuesIsOpen: false
    }
  }

  /**
   * Open the smart search.
   */
  openSmartSearch = () => this.props.setSmartSearchIsOpen(true);

  /**
   * Track whenever users scroll on the panel.
   */
  trackScroll = () => analytics.trackParcelInfoPanelScroll()

  render() {
    const { selectedFeature, selectedListing, tier } = this.props;

    const panelIsActive = Boolean(selectedFeature || selectedListing);
    return (
      <div className={`component--parcel-information-panel ${panelIsActive ? "active" : ""}`} onScroll={this.trackScroll}>
        <div className="actions-container">
          {(tier !== Tier.Standard) && <div className="action" onClick={this.openSmartSearch}>Go to Search Engine</div>}
          <div className="action" onClick={this.props.clearFeatureSelection}>Close Panel</div>
        </div>
        <PropertyListingTabContainer tabText="Property Data" selectedFeature={selectedFeature}>
          <ProjectInformationTable parcelFeature={selectedFeature} />
          <button onClick={() => { this.setState({ initialValuesIsOpen: true }) }}>START PROJECT</button>
        </PropertyListingTabContainer>

        {this.state.initialValuesIsOpen && <InitialValuesPanel onStateChange={() => { this.setState({ initialValuesIsOpen: false }) }} />}
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ParcelInformationPanel);
