import log from "loglevel";
import Unit from "../types/Unit";
import turf from "./turf";
import vector2D from "./vector2D";
import unitConversions from "./unitConversions";
import equalWithinEpsilon from "./floatCompare";

const DISTANCE_EPSILON = 0.0006096; // 2 feet in kilometers.
const AREA_EPSILON = 0.092903;      // 1 square foot in square meters.
const SCALING_FACTOR = 1.05;

const EMPTY_POLYGON = turf.polygon([]);

/**
 * This method split a Polygon or MultiPolygon GeoJSON feature with a line which is orthogonal to a
 * given guide vector in a way that the split polygons before the line (in the guide vector direction)
 * have an area "as close as possible" to a given area. We do so by:
 *
 * - Using binary search to find the two "clipping boxes" (a.k.a two adjacent rectangles such that their union covers
 * the given polygons) that have two of their sides parallel to a given guideVector and "best split" (a.k.a make the
 * rear part to have an area close enough to the given area) the given polygons.
 * - Use the two clipping boxes to clip the original polygons.
 */
const splitPolygons = (polygons, guideVector, targetArea) => {
  // Skip polygon splitting if the target area is almost equal to the polygon's total area.
  if (equalWithinEpsilon(turf.area(polygons), targetArea, AREA_EPSILON)) {
    return [polygons, null];
  }
  try {
    let clippingBoxes = binarySearchClippingBoxes(polygons, guideVector, targetArea);
    let resultPolygons = turf.difference(polygons, clippingBoxes[0]);
    let remainingPolygons = turf.difference(polygons, clippingBoxes[1]);
    return remainingPolygons ? [resultPolygons, remainingPolygons] : [polygons, null];
  } catch (error) {
    return [polygons, null];
  }
}

/**
 * Binary search the pair of of boxes that better split the given polygon with a line which is orthogonal
 * to a given guide vector in a way that the split polygons before the line (in the guide vector direction)
 * have an area "as close as possible" to a given area. We do so by:
 *
 * - Compute an initial bounding box around the initial geometry.
 * - Binary search which traversing line of the initial bounding box gives the rear part an area that is
 * within certain epsilon of the given area. Binary search also stops if the limits get within a certain
 * distance.
 * - Split the initial bounding box along the computed line.
 * - Return both new boxes.
 */
const binarySearchClippingBoxes = (polygons, guideVector, targetArea) => {
  // Get initial bounding box.
  let boundingBox = getBoundingBox(polygons, guideVector)
  if (boundingBox) {
    // Get the four corners of the bounding box.
    let ring = turf.getCoords(boundingBox)[0];
    let rightInitialPoint = turf.point(ring[0]);
    let rightFinalPoint = turf.point(ring[1]);
    let leftInitialPoint = turf.point(ring[3]);
    let leftFinalPoint = turf.point(ring[2]);
    // Binary search.
    let forwardClippingBox;
    let backwardClippingBox;
    while (turf.distance(leftInitialPoint, leftFinalPoint) > DISTANCE_EPSILON) {
      let leftMiddlePoint = turf.midpoint(leftInitialPoint, leftFinalPoint);
      let rightMiddlePoint = turf.midpoint(rightInitialPoint, rightFinalPoint);
      forwardClippingBox = getForwardClippingBox(leftMiddlePoint, rightMiddlePoint, boundingBox);
      try {
        let clippedPolygon = turf.difference(polygons, forwardClippingBox);
        if (clippedPolygon) {
          let clippedArea = turf.area(clippedPolygon);
          if (equalWithinEpsilon(clippedArea, targetArea, AREA_EPSILON) && clippedArea > targetArea) {
            backwardClippingBox = getBackwardClippingBox(leftMiddlePoint, rightMiddlePoint, boundingBox);
            return [forwardClippingBox, backwardClippingBox];
          } else if (clippedArea > targetArea) {
            leftFinalPoint = leftMiddlePoint;
            rightFinalPoint = rightMiddlePoint;
          } else {
            leftInitialPoint = leftMiddlePoint;
            rightInitialPoint = rightMiddlePoint;
          }
        } else {
          // As the bounding box is slightly larger than the polygon, it can be the case, with a small target
          // area, that the binary search continues until the difference returns an empty polygon.
          break;
        }
      } catch (error) {
        // This is failsafe that should never occur as long as the polygons are a valid GeoJSON.
        // In case of error, log it and return the entire bounding box as the forward clipping box
        // and an empty polygon as the backward clipping box, this will cause the building to
        // render only the first usage for the whole floor.
        log.warn(error);
        return [EMPTY_POLYGON, boundingBox];
      }
    }
    forwardClippingBox = getForwardClippingBox(leftFinalPoint, rightFinalPoint, boundingBox);
    backwardClippingBox = getBackwardClippingBox(leftFinalPoint, rightFinalPoint, boundingBox);
    return [forwardClippingBox, backwardClippingBox];
  }
  // This line should never be reached as long as the polygons are a valid GeoJSON.
  return [EMPTY_POLYGON, boundingBox];
}

/**
 * Get an inclined rectangle that contains the given polygon with two of it sides parallel to the given guide vector.
 */
const getBoundingBox = (polygons, guideVector) => {
  let rotationAngle = unitConversions.convert(vector2D.angleOfVector(guideVector), Unit.Type.Radians, Unit.Type.Degrees);
  let polygonCentroid = turf.centroid(polygons);
  let rotatedPolygon = turf.transformRotate(polygons, rotationAngle, { pivot: polygonCentroid });
  let rotatedBoundingBox = turf.envelope(rotatedPolygon);
  let opositeRotationAngle = rotationAngle * -1;
  let boundingBox = turf.transformRotate(rotatedBoundingBox, opositeRotationAngle, { pivot: polygonCentroid });
  return turf.transformScale(boundingBox, SCALING_FACTOR);
}

/**
 * Get a forward clipping box.
 */
const getForwardClippingBox = (leftMiddlePoint, rightMiddlePoint, boundingBox) => {
  let clippingBox = turf.clone(boundingBox);
  if (clippingBox && clippingBox.geometry) {
    clippingBox.geometry.coordinates[0][0] = turf.getCoord(rightMiddlePoint);
    clippingBox.geometry.coordinates[0][4] = turf.getCoord(rightMiddlePoint);
    clippingBox.geometry.coordinates[0][3] = turf.getCoord(leftMiddlePoint);
  }
  return clippingBox;
}

/**
 * Get a backward clipping box.
 */
const getBackwardClippingBox = (leftMiddlePoint, rightMiddlePoint, boundingBox) => {
  let clippingBox = turf.clone(boundingBox);
  if (clippingBox && clippingBox.geometry) {
    clippingBox.geometry.coordinates[0][1] = turf.getCoord(rightMiddlePoint);
    clippingBox.geometry.coordinates[0][2] = turf.getCoord(leftMiddlePoint);
  }
  return clippingBox;
}

export default splitPolygons;
