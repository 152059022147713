import log from "loglevel";
import { call, put, takeEvery, all } from "redux-saga/effects";
import { Chargebee } from "../../types/Service/Chargebee";
import chargebee from "../../utils/functions/chargebee";
import subscriptionActions from "./actions";
import actionTypes from "./actionTypes";
import authenticationActionTypes from "../authentication/actionTypes";

/**
 * Load a subscription.
 */
function* load(action: ReturnType<typeof subscriptionActions.loadStart>) {
  try {
    const subscription: Chargebee.Subscription = yield call(chargebee.getSubscription, action.payload.subscriptionId);
    if (subscription) yield put(subscriptionActions.loadSuccess(subscription));
  } catch (error) {
    log.error(error);
  }
}

/**
 * Load the subscription belonging to a specific customer.
 */
function* loadByCustomer(action: ReturnType<typeof subscriptionActions.loadByCustomerStart>) {
  try {
    let subscription: Chargebee.Subscription | null = null;
    if (action.payload.customerId) {
      subscription = yield call(chargebee.getCustomerSubscription, action.payload.customerId);
    }
    yield put(subscriptionActions.loadSuccess(subscription));
  } catch (error) {
    log.error(error);
  }
}

/**
 * Load the payment plan prices.
 */
function* loadPlanPrices(action) {
  try {
    const [
      standardMonthlyPlan,
      standardYearlyPlan,
      advancedMonthlyPlan,
      advancedYearlyPlan,
      proMonthlyPlan,
      proYearlyPlan
    ]: Chargebee.Plan[] = yield all([
      call(chargebee.getPaymentPlan, Chargebee.PlanId.StandardMonthly),
      call(chargebee.getPaymentPlan, Chargebee.PlanId.StandardYearly),
      call(chargebee.getPaymentPlan, Chargebee.PlanId.AdvancedMonthly),
      call(chargebee.getPaymentPlan, Chargebee.PlanId.AdvancedYearly),
      call(chargebee.getPaymentPlan, Chargebee.PlanId.ProMonthly),
      call(chargebee.getPaymentPlan, Chargebee.PlanId.ProYearly),
    ]);

    yield put(
      subscriptionActions.setPlanPrices({
        [Chargebee.PlanId.StandardMonthly]: standardMonthlyPlan.price / 100,
        [Chargebee.PlanId.StandardYearly]: standardYearlyPlan.price / 100,
        [Chargebee.PlanId.AdvancedMonthly]: advancedMonthlyPlan.price / 100,
        [Chargebee.PlanId.AdvancedYearly]: advancedYearlyPlan.price / 100,
        [Chargebee.PlanId.ProMonthly]: proMonthlyPlan.price / 100,
        [Chargebee.PlanId.ProYearly]: proYearlyPlan.price / 100,
      })
    );
  } catch (error) {
    log.error(error);
  }
}

/**
 * Process all sign in success actions.
 */
function* watchSignInSuccess() {
  yield takeEvery([authenticationActionTypes.SIGN_IN_SUCCESS, authenticationActionTypes.SIGN_OUT_SUCCESS], loadPlanPrices);
}

/**
 * Process all subscription load actions.
 */
function* watchLoad() {
  yield takeEvery(actionTypes.LOAD_START, load);
}

/**
 * Process all customer subscription load actions.
 */
function* watchLoadByCustomer() {
  yield takeEvery(actionTypes.LOAD_BY_CUSTOMER_START, loadByCustomer);
}

export default {
  watchLoad,
  watchLoadByCustomer,
  watchSignInSuccess,
}
