import React, { Component } from "react";
import { connect } from "react-redux";
import { developmentSelectors } from "../../../../../state/development";
import { displayableUsages, usageColor } from "../../../../../views/utils/buildingUsageProperties"
import { buildingUsageIsEnabled, allTogglesDisabled } from "../../../../utils/uiToggleHelper";
import analytics from "../../../../../utils/analytics";

interface Props {
  toggles: Array<any>;
  allTogglesDisabled: boolean;
}

interface State {
  expanded: boolean;
}

class MapLegend extends Component<Props, State>  {
  constructor(props) {
    super(props);

    this.state = {
      expanded: false
    };
  }

  /**
   * Toggles the state of expanded to show or hide the map legend
   */
  handleToggleClick = (event) => {
    let updatedExpandedValue = !this.state.expanded;
    analytics.trackLegendToggle(updatedExpandedValue);
    this.setState({ expanded: updatedExpandedValue });
  }

  render() {
    if (this.props.allTogglesDisabled) {
      return null;
    }

    let displayableUsagesList = displayableUsages().filter((usage) =>
      buildingUsageIsEnabled(this.props.toggles, usage)
    )

    return (
      <div className="component--map-legend">
        <div className="legend-toggle" onClick={this.handleToggleClick}>
          {displayableUsagesList.map((usage) => (
            <div key={`mapLegendItem__${usage}`} className="legend-item flx v-center">
              <span className="legend-toggle-symbol" style={{ background: usageColor(usage) }} />
            </div>
          ))}
        </div>
        <div className={`flx expanded-legend ${this.state.expanded ? "" : " hidden"}`}>
          {displayableUsagesList.map((usage) => (
            <div key={`mapLegendItem__${usage}`} className="legend-item flx v-center">
              <span className="legend-symbol" style={{ background: usageColor(usage) }} />
              <span className="legend-label">{usage}</span>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  let toggleValues = developmentSelectors.getBuildingUsageToggles(state);

  return {
    toggles: toggleValues,
    allTogglesDisabled: allTogglesDisabled(toggleValues)
  };
};

export default connect(
  mapStateToProps
)(MapLegend);
