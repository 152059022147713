import listingsHelper from "../../utils/listingsHelper";
import { newDevelopmentActionTypes } from "../newDevelopment";
import actionTypes from "./actionTypes";
import { Listing } from "../../types/Service/Listings/Listings";
import parcelAccessors from "../../utils/parcel/parcelAccessors";

export interface ListingsState {
  selectedListings?: Array<Listing> | null;
  listings: Array<Listing>;
}

const INITIAL_STATE: ListingsState = {
  listings: [],
};

/**
 * Determine which reducer, if any, should handle reducing the given action and
 * state.
 */
const reducer = (previousState = INITIAL_STATE, action): ListingsState => {
  switch (action.type) {
    case actionTypes.GET_LISTINGS_SUCCESS: return getListingsSuccess(previousState, action.payload);
    case actionTypes.SET_SELECTED_LISTING: return setSelectedListing(previousState, action.payload);
    case actionTypes.CLEAR_LISTINGS: return clearListings(previousState, action.payload);
    case newDevelopmentActionTypes.SELECT_PARCEL_SUCCESS: return selectListing(previousState, action.payload);
    case newDevelopmentActionTypes.COMBINE_PARCELS_SUCCESS: return combineListings(previousState, action.payload);
    case newDevelopmentActionTypes.INITIALIZE:
    case newDevelopmentActionTypes.SET_SMART_SEARCH_IS_OPEN:
    case newDevelopmentActionTypes.CLEAR_FEATURE_SELECTION: return clearSelectedListing(previousState, action.payload);
    default: return previousState;
  }
}

/**
 * Clear selected listing from the store.
 */
const clearSelectedListing = (previousState, payload): ListingsState => {
  return {
    ...previousState,
    selectedListings: null,
  }
}

/**
 * Combine listings for combined parcels.
 */
const combineListings = (previousState, payload): ListingsState => {
  let selectedFeatureMembers;
  const clickedParcel = payload.clickedParcel;
  const parcelId = parcelAccessors.getParcelId(clickedParcel);

  if (parcelId && !payload.selectedFeatureMembers[parcelId]) {
    selectedFeatureMembers = [...Object.values(payload.selectedFeatureMembers), clickedParcel];
  } else {
    selectedFeatureMembers = Object.values(payload.selectedFeatureMembers).filter((featureMember) =>
      parcelAccessors.getParcelId(featureMember) !== parcelId);
  }

  const matchingListings = listingsHelper.getListingsInsideParcels(previousState.listings, selectedFeatureMembers);

  return {
    ...previousState,
    selectedListings: matchingListings.length > 0 ? matchingListings : null,
  }
}

/*
 * See `clearListings` action creator.
 */
const clearListings = (previousState, payload): ListingsState => {
  return {
    ...previousState,
    listings: INITIAL_STATE.listings
  }
}

/**
 * See `newDevelopment.selectParcelSuccess` action creator.
 *
 * Select listing that falls inside the selected parcel geometry.
 */
const selectListing = (previousState, payload): ListingsState => {
  const { selectedParcel } = payload;

  const matchingListings = listingsHelper.getListingsInsideParcel(previousState.listings, selectedParcel);

  return {
    ...previousState,
    selectedListings: matchingListings.length > 0 ? matchingListings : null,
  }
}

/**
 * See `setSelectedListing` action creator.
 *
 * Return state with selected listing updated.
 */
const setSelectedListing = (previousState, payload): ListingsState => {
  return {
    ...previousState,
    selectedListings: payload.listings,
  }
}

/**
 * See `getListingsSuccess` action creator.
 *
 * Return state with listings updated.
 */
const getListingsSuccess = (previousState, payload): ListingsState => {
  return {
    ...previousState,
    listings: payload.listings
  };
}

export default reducer;
