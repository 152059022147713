import { SubscriptionState } from "./reducers";

/**
 * Select the whole subscription.
 */
const getSubscription = (state): SubscriptionState => {
  return state.subscription;
}

/**
 * Get subscription status.
 */
const getStatus = (state) => {
  const subscription = getSubscription(state);
  return subscription.status;
}

/**
 * Get subscription id.
 */
const getId = (state) => {
  const subscription = getSubscription(state);
  return subscription.id;
}

/**
 * Get subscription plan id.
 */
const getPlanId = (state) => {
  const subscription = getSubscription(state);
  return subscription.planId;
}

/**
 * Get plan prices.
 */
const getPlanPrices = (state) => {
  const subscription = getSubscription(state);
  return subscription.planPrices;
}

export default {
  getStatus,
  getId,
  getPlanId,
  getPlanPrices,
}
