const CHARACTERS = "abcdefghijklmnopqrstuvwxyz0123456789";

/**
 * Returns string with first letter capitalized.
 */
const capitalizeFirstLetter = (string:string) => {
  return string[0].toUpperCase() + string.slice(1);
}

/**
 * Generate a random string of a given size, composed of digits and lowercase letters.
 */
const randomString = (size: number) => {
  let randomCharacters = Array<string>(size);
  for (let index = 0; index < size; index++) {
    randomCharacters[index] = CHARACTERS[Math.floor(Math.random() * CHARACTERS.length)];
  }
  return randomCharacters.join("");
}

export {
  capitalizeFirstLetter,
  randomString
}
