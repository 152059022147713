/**
 * Convert setbacks related values and constraints into arrays.
 */
const setbacksInEveryFloor = (development) => {
  development.values.setbackA = [development.values.setbackA] ;
  development.values.setbackB = [development.values.setbackB] ;
  development.values.setbackC = [development.values.setbackC] ;
  development.values.setbackD = [development.values.setbackD] ;
  development.constraints.maximums["setbackA"] = [development.constraints.maximums["setbackA"]] ;
  development.constraints.maximums["setbackB"] = [development.constraints.maximums["setbackB"]] ;
  development.constraints.maximums["setbackC"] = [development.constraints.maximums["setbackC"]] ;
  development.constraints.maximums["setbackD"] = [development.constraints.maximums["setbackD"]] ;
  development.constraints.minimums["setbackA"] = [development.constraints.minimums["setbackA"]] ;
  development.constraints.minimums["setbackB"] = [development.constraints.minimums["setbackB"]] ;
  development.constraints.minimums["setbackC"] = [development.constraints.minimums["setbackC"]] ;
  development.constraints.minimums["setbackD"] = [development.constraints.minimums["setbackD"]] ;
}

export default setbacksInEveryFloor;
