import firebase from "firebase/app";
import "firebase/firestore";
import authentication from "../authentication";
import database from "./index";
import { jsonDeepCopy } from "../deepCopy";
import developmentAccessors from "../../state/development/utils/developmentAccessors";
import reduxStore from "../../state/reduxStore";
import log from "loglevel";
import databaseHelper from "./utils/databaseHelper";
import AsynchronousTaskQueue from "../AsynchronousTaskQueue";
import thumbnails from "../storage/developments/thumbnails";
import { subscriptionActions } from "../../state/subscription";
import { UserDocument } from "../../types/UserDocument";
import { Development } from "../../types/Development/Development";
import intercom from "../intercom";
import Pdf from "../../types/Pdf";
import { pdfSelectors } from "../../state/pdf";
import pdfCovers from "../storage/developments/pdfCovers";
import pdfLogos from "../storage/developments/pdfLogos";
import { developmentActions } from "../../state/development";
import { Path } from "../../types/Path";

interface DocumentAttributes {
  id?: string;
  reference?: any;
  snapshot?: any;
  data?: any;
}

const AUTOSAVE_TIMEOUT = 3000; // 3 seconds

let autoSaveId: any;
let databaseIsInitialized = false;
let onInitializedCallbacks: any[] = [];

/**
 * Create an AsynchronousTaskQueue object. This will make sure that asynchronous tasks (a.k.a read
 * and writes to the database) are run one by one.
 *
 * IMPORTANT: Do not push to this taskQueue any task that awaits for another one that is also pushed.
 * This would create a deadlock and application will freeze. In case of doubt contact your supervisor.
 */
const taskQueue = new AsynchronousTaskQueue();

// User
let currentUserAttributes: DocumentAttributes | null = null;

// Project
let currentProjectAttributes: DocumentAttributes | null = null;

// Project thumbnail
let currentProjectThumbnail: Blob | null = null;

/**
 * Set the value of the `currentProjectAttributes.data` based on the current
 * user. This function should be called after the user is logged in to Firebase.
 */
const initialize = async () => {
  databaseIsInitialized = false;
  await loadCurrentUser();
  databaseIsInitialized = true;

  onInitializedCallbacks.forEach((callback) => {
    callback();
  });
};

/**
 * Subscribe a callback to the `databaseInitialized` event.
 *
 * @param {function} callback - The callback function.
 */
const onInitialized = (callback) => {
  onInitializedCallbacks.push(callback);
};

/**
 * Remove the given callback from the list of registered onInitialize callbacks.
 */
const removeOnInitializedCallback = (callbackToRemove) => {
  onInitializedCallbacks = onInitializedCallbacks
    .filter((callback) => callback !== callbackToRemove);
};

/**
 * A boolean indicating whether this database module has completed its
 * initialization sequence.
 */
const isInitialized = () => {
  return databaseIsInitialized;
};

/**
 * Load the current user. This function is wrapped inside a task that is pushed to the taskQueue
 * to be run synchronously.
 */
const loadCurrentUser = async () => {
  const task = async () => {
    currentUserAttributes = null;
    let currentUser = authentication.getCurrentUser();

    if (currentUser) {
      // IMPORTANT: Apply all the changes to a temporary variable and update the state
      // of the system with the temporary variable only after the transaction was successfully
      // completed. That way we guarantee that the system is updated in a transactional manner.
      let temporaryUserAttributes: any = {};
      temporaryUserAttributes.id = currentUser.uid;
      let userReference = firebase.firestore().doc(`users/${temporaryUserAttributes.id}`);

      let temporaryUserData;
      try {
        // TODO: Do not catch errors generated by this call. This is currently
        // necessary because of bad sequencing when creating a new user.
        // See: https://github.com/DeepBlocks/developer/pull/475/files#r258153247
        temporaryUserData = await databaseHelper.getDocument(userReference);
      } catch (error) {
        log.warn(error.message);
        return;
      }

      if (temporaryUserData) {
        const chargebeeCustomerId = temporaryUserData.chargebeeCustomerId || null;
        reduxStore.dispatch(subscriptionActions.loadByCustomerStart(chargebeeCustomerId));

        intercom.shutdown();
        intercom.initialize(temporaryUserData.firstName, currentUser.email || "");

        temporaryUserAttributes.reference = userReference;
        temporaryUserAttributes.data = temporaryUserData;
        if (currentProjectAttributes) {
          const result = await database.development.read(currentProjectAttributes.id, temporaryUserAttributes);
          if (result) {
            currentProjectAttributes.id = result.newProjectId;
            currentProjectAttributes.reference = result.projectReference;
            currentProjectAttributes.data = result.projectData;

            temporaryUserAttributes.data = result.userData;
            if (currentProjectThumbnail) {
              await thumbnails.upload(currentProjectThumbnail, result.newProjectId);
            }

            // TODO: This is Hack to prevent double copying a demo project when a user logs in from the Explorer page.
            // A proper solution needs to be implemented.
            if (window.location.pathname.includes(Path.Explorer)) {
              window.location.replace(`${Path.Explorer}/${result.newProjectId}`);
            }
          }
        }
        currentUserAttributes = temporaryUserAttributes;
      }
    }
  }
  await taskQueue.push(task);
};

/**
 * Update user document with passed in customer ID.
 */
const updateCurrentUserChargebeeCustomerId = async (customerId: string) => {
  if (currentUserAttributes) {
    currentUserAttributes.data.chargebeeCustomerId = customerId;
    await databaseHelper.updateDocument(currentUserAttributes.reference, currentUserAttributes.data);
  }
}

/**
 * Return the current user or null if the user has not yet been loaded.
 */
const getCurrentUser = () => {
  return currentUserAttributes ? currentUserAttributes.data : null;
};

/**
 * Create a new user. This function is wrapped inside a task that is pushed to the taskQueue
 * to be run synchronously.
 */
const createUser = async (userData: UserDocument) => {
  const task = async () => {
    const user = authentication.getCurrentUser();
    let temporaryUserData = jsonDeepCopy(userData);

    if (user) {
      const newUserDocumentReference = firebase.firestore().doc(`users/${user.uid}`);
      await databaseHelper.createDocument(newUserDocumentReference, temporaryUserData);

      currentUserAttributes = {
        id: user.uid,
        reference: newUserDocumentReference,
        data: temporaryUserData,
      }
    }
  }
  await taskQueue.push(task);
};

/**
 * Load the project associated with the provided projectId.
 */
const loadProject = async (projectId) => {
  const task = async () => {
    currentProjectAttributes = {};
    const result = await database.development.read(projectId, currentUserAttributes);
    if (result) {
      currentProjectAttributes.id = result.newProjectId;
      currentProjectAttributes.reference = result.projectReference;
      currentProjectAttributes.data = result.projectData;

      if (currentUserAttributes) {
        currentUserAttributes.data = result.userData;
      }

      return {
        projectData: result.projectData,
        newProjectId: result.newProjectId,
      };
    } else {
      return {
        projectData: null,
        newProjectId: null,
      };
    }
  }

  let { projectData, newProjectId } = await taskQueue.push(task);
  return {
    projectData,
    newProjectId,
  };
}

/**
 * Save the current project.
 */
const saveCurrentProject = async () => {
  const task = async () => {
    // TODO: These two lines contains knowledge of the structure of the Redux store.
    // This knowledge should not be exposed here. This should be refactored to
    // ensure that the knowledge of the store's internal structure remains
    // encapsulated in the appropriate place. To achieve this, the Save Project
    // event must be moved into a Redux action, where the appropriate parts
    // of the store are provided naturally by Redux.
    let temporaryProjectData = jsonDeepCopy(reduxStore.getState().development);
    let temporaryPdfData = jsonDeepCopy(pdfSelectors.getPdfDocument(reduxStore.getState()));

    if (currentProjectAttributes && currentProjectAttributes.id) {
      stopAutoSave();
      const result = await database.development.update(currentUserAttributes, currentProjectAttributes.id, temporaryProjectData, temporaryPdfData);

      if (result) {
        try {
          if (currentProjectThumbnail && currentProjectAttributes.id) {
            await thumbnails.upload(currentProjectThumbnail, currentProjectAttributes.id);
          }
        } catch (error) {
          log.warn(error);
        }

        if (currentProjectAttributes) {
          currentProjectAttributes.data = temporaryProjectData;
        }

        if (currentUserAttributes) {
          currentUserAttributes.data = result.userData;
        }

        reduxStore.dispatch(developmentActions.projectSaved());
      }
    }
  }
  await taskQueue.push(task);
};

/**
 * Create a new project belonging to the current user. This function updates both the user and
 * project documents in the database. This function is wrapped inside a task that is pushed to
 * the taskQueue to be run synchronously.
 *
 * @returns - The project id of the newly created project or null if
 * any error happens.
 */
const createNewProject = async (development: Development, pdfDocument?: Pdf.Document) => {
  let newProjectId: string | null = null;
  const task = async () => {
    currentProjectThumbnail = null;
    const result = await database.development.create(development, currentUserAttributes, pdfDocument);
    newProjectId = result.newProjectId;

    if (currentUserAttributes && result.userData) {
      currentUserAttributes.data = result.userData;
    }
  }
  await taskQueue.push(task);
  return newProjectId;
};

/**
 * Make a copy of the current project.
 *
 * @returns - The project id of the newly created project (aka the copy) or null if
 * any error happens.
 */
const duplicateProject = async (projectName, pdfDocument: Pdf.Document) => {
  if (currentProjectAttributes) {
    // A copy of currentProjectAttributes.data needs to be used to create the copy because if
    // the currentProjectAttributes.data gets dehydrated the application could crash attempting
    // to access undefined data.
    let projectStateCopy = jsonDeepCopy(currentProjectAttributes.data);
    developmentAccessors.setName(projectStateCopy, projectName);
    let newProjectId = await createNewProject(projectStateCopy, pdfDocument);
    return newProjectId;
  } else {
    return null;
  }
}

/**
 * Return the current user projects or null if there is no user data.
 */
const getCurrentUserDevelopments = () => {
  if (!currentUserAttributes) return [];
  let rawDevelopments = currentUserAttributes.data.projects;

  let developmentsList = Object.keys(rawDevelopments).map((key) => {
    return {
      id: key,
      ...rawDevelopments[key]
    }
  });

  // By default sort alphabetically by project name. This is important for developments without timeModified.
  developmentsList.sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1);

  // Sort by timeModified.
  developmentsList.sort((a, b) => (b.timeModified || 0) - (a.timeModified || 0));

  return developmentsList;
}

/**
 * Remove a project from the database. This function is wrapped inside a task that is pushed to the taskQueue
 * to be run synchronously.
 */
const removeProject = async (projectId) => {
  const task = async () => {
    const result = await database.development.remove(projectId, currentUserAttributes);

    if (result) {
      await thumbnails.remove(projectId);
      await pdfCovers.remove(projectId);
      await pdfLogos.remove(projectId);

      if (currentUserAttributes) {
        currentUserAttributes.data = result.userData;
      }
    }
  }
  await taskQueue.push(task);
}

/**
 * Save the latest development object after a giving amount of time from the last time it was modified.
 */
const startAutoSave = () => {
  stopAutoSave();

  let projectDatabaseReference = currentProjectAttributes ? currentProjectAttributes.reference : null;
  autoSaveId = setTimeout(
    () => {
      if (currentProjectAttributes && currentProjectAttributes.reference === projectDatabaseReference) {
        saveCurrentProject();
      }
    }, AUTOSAVE_TIMEOUT
  );
}

/**
 * Set the current project thumbnail.
 */
const setThumbnail = (thumbnail: Blob) => {
  currentProjectThumbnail = thumbnail;
}

/**
 * Stop the auto saving timeout.
 */
const stopAutoSave = () => {
  clearInterval(autoSaveId);
}

/**
 * Set the current user isOnboarded flag to true and save the current user.
 */
const setUserIsOnboarded = async () => {
  const task = async () => {
    if (currentUserAttributes) {
      // Usually it is better to update the `currentUserAttributes` only after
      // the database write has completed successfully. In this case we make an
      // exception. In the result of a write failure, this will create a
      // slightly better user experience, as the user will not have to dismiss
      // the onboarding UI a second time during the same session.
      currentUserAttributes.data.isOnboarded = true;
      await databaseHelper.updateDocument(currentUserAttributes.reference, currentUserAttributes.data);
    }
  }
  await taskQueue.push(task);
}

/**
 *
 */
const setSharedProject = async (developmentId) => {
  const task = async () => {
    const sharedProjectReference = firebase.firestore().doc(`sharedProjects/${developmentId}`);
    databaseHelper.createDocument(sharedProjectReference, {});
  }
  await taskQueue.push(task);
}

export default {
  initialize,
  isInitialized,
  onInitialized,
  removeOnInitializedCallback,
  getCurrentUser,
  createUser,
  saveCurrentProject,
  createNewProject,
  duplicateProject,
  getCurrentUserDevelopments,
  removeProject,
  startAutoSave,
  loadCurrentUser,
  setThumbnail,
  setUserIsOnboarded,
  loadProject,
  setSharedProject,
  updateCurrentUserChargebeeCustomerId,
};
