import mapboxGeocoding from "@mapbox/mapbox-sdk/services/geocoding";
import Mapbox from "../../types/Mapbox";

/**
 * GeocoderClient to make requests to mapbox geocoding service.
 */
const geocoderClient = mapboxGeocoding({
  accessToken: process.env.REACT_APP_MAPBOX_ACCESS_TOKEN as string
});

/**
 * Get the forward geocoding data.
 */
const getForwardGeocodingData = async (options: Mapbox.ForwardGeocodeOptions) => {
  let response = await geocoderClient.forwardGeocode(options).send();

  return response.body;
}

/**
 * Get the geocoded feature.
 */
const getGeocodedFeature = async (coordinates: [number, number]) => {
  let response = await geocoderClient.reverseGeocode({
    query: coordinates,
  }).send()

  let geocodedFeature;
  if (response.body.features && response.body.features.length > 0) {
    geocodedFeature = response.body.features[0];
  }

  return geocodedFeature;
}

export default {
  getForwardGeocodingData,
  getGeocodedFeature
}
