import React from "react";
import { connect } from "react-redux";
import wrapComponentWithPopup from "../../../../../sharedComponents/wrapComponentWithPopup"
import { PopupProps } from "../../../../../sharedComponents/wrapComponentWithPopup/wrapComponentWithPopup";
import { developmentSelectors } from "../../../../../../state/development";

const mapStateToProps = (state) => {
  return {
    floorsWithSetbacks: developmentSelectors.getFloorsWithSetbacks(state),
  }
}

type StateProps = ReturnType<typeof mapStateToProps>;

interface OwnProps {
  setbackToRemove: number;
  onConfirm();
}

type Props = OwnProps & StateProps & PopupProps;

class RemoveSetbackPopup extends React.PureComponent<Props, {}> {
  /**
   * Confirm action and close popup.
   */
  handleConfirm = () => {
    this.props.onConfirm();
    this.props.closePopup();
  }

  /**
   * Cancel action and close popup.
   */
  handleCancel = () => {
    this.props.closePopup();
  }

  /**
   * Stop click event from propagating.
   */
  preventPropagation = (event) => event.stopPropagation();

  render() {
    const { floorsWithSetbacks, setbackToRemove } = this.props;
    const index = floorsWithSetbacks.indexOf(setbackToRemove);
    if (index < 0) return null;

    const isLast = (index === (floorsWithSetbacks.length - 1));

    return (
      <div className="component--remove-setback-popup" onClick={this.preventPropagation}>
        <div className="top">
          Are you sure you want to remove setbacks from <br />
          <span className="bold">{`Floor ${floorsWithSetbacks[index] + 1}`}</span>
          {isLast ? " and " : " to "}
          {isLast ? <span className="bold">Up?</span> : <span className="bold">{`Floor ${floorsWithSetbacks[index + 1] + 1}?`}</span>}
        </div>

        <div className="bottom">
          <div className="buttons-container">
            <button
              className="cancel"
              onClick={this.handleCancel}
            >
              Cancel
            </button>
            <button
              className="confirm"
              onClick={this.handleConfirm}
            >
              Remove
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default wrapComponentWithPopup(
  connect(mapStateToProps)(RemoveSetbackPopup)
);
