import React from "react";
import Unit from "../../../types/Unit";
import analytics from "../../../utils/analytics";
import { withRouter, RouteComponentProps } from "react-router-dom";

type OwnProps = {
  unitSystem: Unit.System
  setUnitSystem(unitSystem: Unit.System): void;
}

type Props = OwnProps & RouteComponentProps;

class UnitSystemInput extends React.PureComponent<Props, {}> {
  /**
   * Set the unit system.
   */
  handleInputChange = (event) => {
    analytics.trackSetUnitSystem(event.target.value, this.props.location.pathname);
    this.props.setUnitSystem(event.target.value);
  }

  render() {
    return (
      <div className="component--unit-system-input">
        <label>
          <input
            type="radio"
            value={Unit.System.Imperial}
            name="unitSystem"
            onChange={this.handleInputChange}
          />
          <span className={`custom-radio ${this.props.unitSystem === Unit.System.Imperial ? "selected" : ""}`} />
          Feet and Inches
        </label>
        <label>
          <input
            type="radio"
            value={Unit.System.Metric}
            name="unitSystem"
            onChange={this.handleInputChange}
          />
          <span className={`custom-radio ${this.props.unitSystem === Unit.System.Metric ? "selected" : ""}`} />
          Metric System
        </label>
      </div>
    );
  }
}

export default withRouter(UnitSystemInput);
