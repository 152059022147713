import chargebee from "../functions/chargebee";

const Chargebee = window["Chargebee"];

document.addEventListener("DOMContentLoaded", (event) => {
  Chargebee.init({ site: process.env.REACT_APP_CHARGEBEE_SITE, publishableKey: process.env.REACT_APP_CHARGEBEE_PUBLISHABLE_KEY });
  chargebeeInstance().setPortalSession(chargebee.createChargebeePortalSession);

  // HACK: This "hack" removes y-scrolling in the chargebee container.
  let chargebeeContainer = document.getElementById("cb-container") as HTMLElement;
  chargebeeContainer.style.overflowY = "unset";
});

/**
 * The Chargebee instance.
 */
const chargebeeInstance = () => {
  return Chargebee.getInstance();
}

export default chargebeeInstance;
