import styled from "@react-pdf/styled-components";
import React from "react";
import Pdf from "../../../../../../../types/Pdf";

const DEFAULT_FONT_SIZE = 13;

const Container = styled.View`
  flex-grow: 1;
`;

const Name = styled.Text`
  font-size: ${(props) => props.fontSize || DEFAULT_FONT_SIZE}px;
  font-family: "Roboto Bold";
  margin-top: 6px;
  color: ${(props) => props.color};
`;

const Company = styled.Text`
  font-weight: bold;
  font-size: ${(props) => props.fontSize || DEFAULT_FONT_SIZE}px;
  font-family: "Roboto";
  margin-bottom: 10px;
  color: ${(props) => props.color};
`;

const Detail = styled.Text`
  font-size: ${(props) => { return (props.fontSize || DEFAULT_FONT_SIZE) - 3 }}px;
  color: ${(props) => props.color};
`;

const Label = styled.Text`
  color: ${(props) => props.color};
  padding-bottom: 4px;
  margin-bottom: 4px;
  border-bottom: ${(props) => props.labelHasBorder ? "1px" : "0px"};
  border-color: ${(props) => props.color};
  font-size: ${(props) => props.fontSize || DEFAULT_FONT_SIZE}px;
  width: 100px;
  font-family: "Roboto Condensed";
`;

interface Props {
  label: string;
  labelHasBorder?: boolean;
  contactDetails: Pdf.ContactDetails;
  textColor: string;
  labelColor: string;
  fontSize?: number;
}

const ContactDetails = (props: Props) => (
  <Container>
    <Label fontSize={props.fontSize} color={props.labelColor} labelHasBorder={props.labelHasBorder}>{props.label}</Label>
    <Name fontSize={props.fontSize} color={props.textColor}>{props.contactDetails.name || " "}</Name>
    <Company fontSize={props.fontSize} color={props.textColor}>{props.contactDetails.companyName || " "}</Company>
    <Detail fontSize={props.fontSize} color={props.textColor}>{props.contactDetails.email || " "}</Detail>
    <Detail fontSize={props.fontSize} color={props.textColor}>{props.contactDetails.phoneNumber || " "}</Detail>
  </Container>
);

export default ContactDetails;
