import React from "react";
import CellRow from "../../../sharedComponents/BaseRow";

const BORDER_WIDTH = 0.6;
const FONT_SIZE = 7.68;

const SolidBorderRow = (props) => {
  props = {
    ...props,
    borderTop: BORDER_WIDTH,
    borderColor: "#333333",
    borderStyle: "solid",
    labelFontFamily: "Roboto",
    valueFontFamily: "Roboto",
    fontSize: FONT_SIZE
  }

  return <CellRow {...props} />;
}

export default SolidBorderRow;
