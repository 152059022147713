import React from "react";
import PropertyInformation from "./PropertyInformation";
import Demographics from "./Demographics";
import Zoning from "./Zoning";

type OwnProps = {
  parcelFeature: any;
}

type Props = OwnProps;

class ParcelInformationTable extends React.PureComponent<Props, {}> {
  render() {
    const { parcelFeature } = this.props;
    return (
      <div className="component--parcel-information-table">
        <Demographics parcelFeature={parcelFeature} />
        <PropertyInformation parcelFeature={parcelFeature} />
        <Zoning parcelFeature={parcelFeature} />
      </div>
    );
  }
}

export default ParcelInformationTable;
