import { Config } from "../../../../../types/Config";
import { VariableId } from "../../../../../types/VariableId";
import { BuildingUse } from "../../../../../types/BuildingUse";
import Format from "../../../../../types/Format";
import Unit from "../../../../../types/Unit";

const config: Config = {
  [VariableId.OfficeGrossBuildableArea]: {
    variableId: VariableId.OfficeGrossBuildableArea,
    text: "Office Gross Buildable",
    formatOptions: { type: Format.Type.Number },
    [Unit.System.Imperial]: {
      unitTarget: Unit.Type.SquareFeet,
      formatOptions: { suffix: " SF" }
    },
    [Unit.System.Metric]: {
      unitTarget: Unit.Type.SquareMeters,
      formatOptions: { suffix: " m²" }
    }
  },

  [VariableId.OfficeNetUsableAreaToggleable]: {
    variableId: VariableId.OfficeNetUsableAreaToggleable,
    buildingUse: BuildingUse.Office,
    text: "Usable Area",
    formatOptions: { type: Format.Type.Number },
    [Unit.System.Imperial]: {
      unitTarget: Unit.Type.SquareFeet,
      formatOptions: { suffix: " SF" }
    },
    [Unit.System.Metric]: {
      unitTarget: Unit.Type.SquareMeters,
      formatOptions: { suffix: " m²" }
    }
  },

  [VariableId.OfficeCommonAreaFactor]: {
    variableId: VariableId.OfficeCommonAreaFactor,
    buildingUse: BuildingUse.Office,
    text: "Common Area Factor",
    formatOptions: { type: Format.Type.Percentage }
  },

  [VariableId.OfficeYearOnePotentialGrossIncome]: {
    variableId: VariableId.OfficeYearOnePotentialGrossIncome,
    text: "Office Income",
    formatOptions: { type: Format.Type.Currency }
  },

  [VariableId.OfficeYearOneRentPerArea]: {
    variableId: VariableId.OfficeYearOneRentPerArea,
    buildingUse: BuildingUse.Office,
    text: "Lease Income",
    formatOptions: {
      type: Format.Type.Currency,
      decimalPlaces: 2
    },
    [Unit.System.Imperial]: {
      unitTarget: Unit.Type.SquareFeet,
      unitIsInverse: true,
      formatOptions: { suffix: "/SF" }
    },
    [Unit.System.Metric]: {
      unitTarget: Unit.Type.SquareMeters,
      unitIsInverse: true,
      formatOptions: { suffix: "/m²" }
    }
  },

  [VariableId.OfficeYearOneExpenseReimbursementFeePerArea]: {
    variableId: VariableId.OfficeYearOneExpenseReimbursementFeePerArea,
    buildingUse: BuildingUse.Office,
    text: "Expense Reimbursement",
    formatOptions: {
      type: Format.Type.Currency,
      decimalPlaces: 2
    },
    [Unit.System.Imperial]: {
      unitTarget: Unit.Type.SquareFeet,
      unitIsInverse: true,
      formatOptions: { suffix: "/SF" }
    },
    [Unit.System.Metric]: {
      unitTarget: Unit.Type.SquareMeters,
      unitIsInverse: true,
      formatOptions: { suffix: "/m²" }
    }
  },

  [VariableId.OfficeAnnualNetOperatingIncomeForBackOfEnvelope]: {
    variableId: VariableId.OfficeAnnualNetOperatingIncomeForBackOfEnvelope,
    text: "Office NOI",
    formatOptions: { type: Format.Type.Currency }
  },

  [VariableId.OfficeStabilizedVacancyPercentage]: {
    variableId: VariableId.OfficeStabilizedVacancyPercentage,
    buildingUse: BuildingUse.Office,
    text: "Vacancy",
    formatOptions: {
      type: Format.Type.Percentage,
      decimalPlaces: 1
    }
  },

  [VariableId.OfficeYearOneOperatingExpensePerArea]: {
    variableId: VariableId.OfficeYearOneOperatingExpensePerArea,
    buildingUse: BuildingUse.Office,
    text: "OpEx per Year",
    formatOptions: {
      type: Format.Type.Currency,
      decimalPlaces: 2
    },
    [Unit.System.Imperial]: {
      unitTarget: Unit.Type.SquareFeet,
      unitIsInverse: true,
      formatOptions: { suffix: "/SF" }
    },
    [Unit.System.Metric]: {
      unitTarget: Unit.Type.SquareMeters,
      unitIsInverse: true,
      formatOptions: { suffix: "/m²" }
    }
  }
};

export default config;
