import userPopulators from "./users";
import { FirestoreCollection } from "../..";

export type AsyncDataPopulator = (data: firebase.firestore.DocumentData) => Promise<boolean>;

/**
 * Return the correct array of AsyncDataPopulators depending on the given collection.
 *
 * If the given collection does not have any AsyncDataPopulators, the empty array is
 * returned.
 */
const populatorsFor = (collection: FirestoreCollection): Array<AsyncDataPopulator> => {
  switch (collection) {
    case FirestoreCollection.Users: return userPopulators;
    default: return [];
  }
}

/**
 * Populate missing data that depends on asynchronous calls.
 *
 * @return - true if there was data added and false otherwise.
 */
const populate = async (data: firebase.firestore.DocumentData, collection: FirestoreCollection): Promise<boolean> => {
  let result = false;
  const dataPopulators = populatorsFor(collection);

  // Out of an abundance of caution, we run each populator one at a time. It may
  // be safe to run them in parallel but this requires more thought.
  for (let populator of dataPopulators) {
    result = await populator(data) || result;
  }

  return result;
}

export default {
  populate: populate
}
