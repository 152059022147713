import React from "react";
import { RouteComponentProps } from "react-router";
import { withRouter } from "react-router-dom";
import { Path } from "../../../types/Path";

interface State {
  redirect: boolean;
}

interface OwnProps {
  path: Path | string;
  state?: object;
  children({ redirect }): React.ReactElement;
  shouldContinue?(): boolean;
  onClick?(): void;
}

type Props = RouteComponentProps & OwnProps;

class RedirectComponent extends React.PureComponent<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      redirect: false,
    }
  }

  /**
   * Redirect to the given path.
   */
  redirectToPath = async () => {
    const { shouldContinue, onClick } = this.props;
    if (onClick) await onClick();
    if (shouldContinue && !shouldContinue()) return;
    this.setState({ redirect: true });
  }

  render() {
    const { path, state } = this.props;

    if (this.state.redirect) {
      this.props.history.push({ pathname: path, state: state });
      this.setState({ redirect: false });
    }

    return (
      <>
        {
          this.props.children({
            redirect: this.redirectToPath
          })
        }
      </>
    );
  }
};

export default withRouter(RedirectComponent);
