import React from "react";
import { connect } from "react-redux";
import { GeoJsonObject } from "geojson";
import Format from "../../../../types/Format";
import Unit from "../../../../types/Unit";
import parcelAccessors from "../../../../utils/parcel/parcelAccessors";
import valueFormatter from "../../../../utils/valueFormatter";
import unitConversions from "../../../../utils/unitConversions";
import authentication from "../../../../utils/authentication";
import Cell from "../../Cell";
import ConditionalCellRow from "../ConditionalCellRow";
import Setbacks from "./Setbacks";
import UnitDensityRatio from "./UnitDensityRatio";
import ParkingRatio from "./ParkingRatio";
import AllowedUses from "./AllowedUses";
import AccordionSection from "../../AccordionSection";
import { accordionSectionSelectors, accordionSectionActions } from "../../../../state/ui/shared/accordionSection";
import { Accordions } from "../../../../state/ui/shared/accordionSection/reducers";

type OwnProps = {
  parcelFeature: GeoJsonObject;
}

const mapStateToProps = (state) => {
  return {
    accordionIsOpen: accordionSectionSelectors.getStaticAccordionIsOpen(state, Accordions.Zoning),
  }
}

const mapDispatchToProps = {
  setStaticAccordionIsOpen: accordionSectionActions.setStaticAccordionIsOpen,
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;
type Props = StateProps & DispatchProps & OwnProps;

class Zoning extends React.PureComponent<Props, {}> {
  /**
   * Render architect firm and link when available.
   */
  renderCollaborationMessage = () => {
    const { parcelFeature } = this.props;
    const architectFirmData = parcelAccessors.getArchitectData(parcelFeature);
    if (!architectFirmData.name) return null;

    let url = architectFirmData.url;
    if (!url) return <p className="collaboration-container">In collaboration with {architectFirmData.name}</p>;

    return (
      <p className="collaboration-container">
        In collaboration with <a href={url} target="_blank" rel="noopener noreferrer">{architectFirmData.name}</a>
      </p>
    );
  }

  /**
   * Toggle the accordion is open prop.
   */
  toggleIsOpen = () => {
    this.props.setStaticAccordionIsOpen(Accordions.Zoning, !this.props.accordionIsOpen);
  }

  render() {
    const { parcelFeature } = this.props;
    const { isNumeric } = valueFormatter;
    const userIsAuthenticated = authentication.isUserAuthenticated();

    const buildableArea = unitConversions.convert(parcelAccessors.getAllowedBuildableArea(parcelFeature), Unit.Type.SquareMeters, Unit.Type.SquareFeet);

    const floorAreaRatio = parcelAccessors.getFloorAreaRatio(parcelFeature);
    const numberOfFloors = parcelAccessors.getNumberOfFloors(parcelFeature);
    const numberOfFloorsMap = (value) => ({ value, formatOptions: { suffix: " stories", type: isNumeric(value) ? Format.Type.Number : Format.Type.PlainText } });
    const numberOfUnitsAllowed = parcelAccessors.getNumberOfUnitsAllowed(parcelFeature);
    const numberOfUnitsAllowedMap = (value) => ({ value, formatOptions: { suffix: " units", type: isNumeric(value) ? Format.Type.Number : Format.Type.PlainText } });
    const buildingHeight = parcelAccessors.getBuildingHeight(parcelFeature);
    const buildingHeightMap = (value) => ({ value, formatOptions: { suffix: " FT", type: isNumeric(value) ? Format.Type.Number : Format.Type.PlainText } });
    const lotCoverage = parcelAccessors.getLotCoverage(parcelFeature);
    const lotCoverageMap = (value) => ({ value, formatOptions: { type: isNumeric(value) ? Format.Type.Percentage : Format.Type.PlainText } });
    const zoneId = parcelAccessors.getZoneId(parcelFeature);

    if (zoneId.length === 0) return null;

    return (
      <AccordionSection
        title="Zoning Limits"
        iconClass="zoning"
        isOpen={this.props.accordionIsOpen}
        toggleIsOpen={this.toggleIsOpen}
      >
        {this.renderCollaborationMessage()}
        <table>
          <tbody>
            {ConditionalCellRow(buildableArea,
              <Cell text="Total Gross Buildable" />,
              <Cell formatOptions={{ type: isNumeric(buildableArea) ? Format.Type.Number : Format.Type.PlainText, suffix: " SF" }} styleVariation="bold" hiddenValues={!userIsAuthenticated} />
            )}
            {ConditionalCellRow(floorAreaRatio,
              <Cell text="FAR" />,
              <Cell styleVariation="bold" hiddenValues={!userIsAuthenticated} />
            )}
            {ConditionalCellRow(numberOfFloors,
              <Cell text="Number of Floors" />,
              <Cell styleVariation="bold capitalize" formattingMap={numberOfFloorsMap} hiddenValues={!userIsAuthenticated} />
            )}
            {ConditionalCellRow(numberOfUnitsAllowed,
              <Cell text="Units Allowed" />,
              <Cell styleVariation="bold capitalize" formattingMap={numberOfUnitsAllowedMap} hiddenValues={!userIsAuthenticated} />
            )}
            {ConditionalCellRow(buildingHeight,
              <Cell text="Building Height" />,
              <Cell styleVariation="bold capitalize" formattingMap={buildingHeightMap} hiddenValues={!userIsAuthenticated} />
            )}
            {ConditionalCellRow(lotCoverage,
              <Cell text="Lot Coverage" />,
              <Cell styleVariation="bold capitalize" formattingMap={lotCoverageMap} hiddenValues={!userIsAuthenticated} />
            )}
            {ConditionalCellRow(zoneId,
              <Cell text="Zoning District" />,
              <Cell styleVariation="bold uppercase" hiddenValues={!userIsAuthenticated} />
            )}
          </tbody>
        </table>
        <UnitDensityRatio parcelFeature={parcelFeature} />
        <ParkingRatio parcelFeature={parcelFeature} />
        <AllowedUses parcelFeature={parcelFeature} />
        <Setbacks parcelFeature={parcelFeature} />
      </AccordionSection>
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Zoning);
