const MISSING_RETURN_ON_COST = -101; // Flag indicating that the project still doesn't have a return on cost value.

/**
 * Add condoReturnOnCost and leaseUsesReturnOnCost to user projects.
 */
const addReturnOnCost = (user) => {
  for (let projectId of Object.keys(user.projects)) {
    user.projects[projectId] = {
      ...user.projects[projectId],
      leaseUsesReturnOnCost: MISSING_RETURN_ON_COST,
      condoReturnOnCost: MISSING_RETURN_ON_COST,
    }
  }
}

export default addReturnOnCost;
