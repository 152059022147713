import React from "react";
import { connect } from "react-redux";
import { newDevelopmentSelectors, newDevelopmentActions } from "state/newDevelopment";

const mapStateToProps = (state) => {
  return {
    drawParcelInformationPanelIsOpen: newDevelopmentSelectors.getDrawParcelInformationPanelIsOpen(state),
  }
}

const mapDispatchToProps = {
  setDrawParcelInformationPanelIsOpen: newDevelopmentActions.setDrawParcelInformationPanelIsOpen,
};

type MapStateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

type Props = MapStateProps & DispatchProps;

class DrawParcelInformationPanel extends React.PureComponent<Props, {}> {
  /**
   * Close draw parcel information panel.
   */
  closeDrawParcelInformationPanel = () => this.props.setDrawParcelInformationPanelIsOpen(false);

  render() {
    const { drawParcelInformationPanelIsOpen } = this.props;
    if (!drawParcelInformationPanelIsOpen) return null;

    return (
      <div className="component--draw-parcel-information-panel">
        <div className="header">Draw Parcel</div>

        <div className="block">
          <div className="number">1.</div>
          <div className="image draw-parcel" />
          <div className="text">
            <div className="text-header">Draw Parcel</div>
            Select the pen icon to draw a parcel over the map.
          </div>
        </div>
        <div className="block">
          <div className="number">2.</div>
          <div className="image edit-parcel" />
          <div className="text">
            <div className="text-header">Edit Drawn Parcels</div>
            The parcel edit icon allows you to adjust the shape.
          </div>
        </div>
        <div className="block">
          <div className="number">3.</div>
          <div className="image select-parcel" />
          <div className="text">
            <div className="text-header">Select Parcel</div>
            With the arrow icon, you can select the drawn parcel.
          </div>
        </div>
        <div className="block">
          <div className="number">4.</div>
          <div className="image combine-parcels" />
          <div className="text">
            <div className="text-header">Select Multiple Parcel</div>
            With the arrow plus icon, you can select multiple parcels.
          </div>
        </div>

        <div className="button-container">
          <button className="btn get-started" onClick={this.closeDrawParcelInformationPanel}>Close</button>
        </div>
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DrawParcelInformationPanel);
