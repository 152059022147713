import actionTypes from "../actionTypes";

export interface ThumbnailState {
  isReady: boolean;
  imageUrl?: string;
  exists?: boolean;
}

const INITIAL_STATE: ThumbnailState = {
  isReady: false
}

/**
 * Determine which reducer, if any, should handle reducing the given action and
 * state.
 */
const reducer = (previousState = INITIAL_STATE, action): ThumbnailState => {
  switch (action.type) {
    case actionTypes.LOAD_SUCCESS:  return loadSuccess(previousState, action.payload);
    case actionTypes.LOAD_ERROR:    return loadError(previousState, action.payload);
    default:                        return previousState;
  }
}

/**
 * See `thumbnails/loadSuccess` action creator.
 *
 * Returns a new state with the updated thumbnail.
 */
const loadSuccess = (previousState, payload): ThumbnailState => {
  return {
    imageUrl: payload.imageUrl,
    isReady: true,
    exists: true
  }
}

/**
 * See `thumbnails/loadError` action creator.
 *
 * Returns a new state with the updated thumbnail.
 */
const loadError = (previousState, payload): ThumbnailState => {
  return {
    isReady: true,
    exists: false
  }
}

export default reducer;
