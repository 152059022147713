export enum StyleUrl {
  Streets = "mapbox://styles/nmccrea-db/ck9a0olk90gus1ipl3otsd7o1", // v11
  Satellite = "mapbox://styles/nmccrea-db/ck9bhh4nl01aw1ipg7txkzfmn", // v11
}

export enum LayerId {
  UsaOpportunityZoneFill = "usa-opportunity-zone-fill",
  UsaParcelsZoningQuery = "usa-parcels-zoning-query",
  UsaDemographicsQuery = "usa-demographics-query",
  UsaParcelsQuery = "usa-parcels-query",
  UsaParcelsSymbol = "usa-parcels-symbol",
  UsaParcelsBorder = "usa-parcels-border",
  SetbackBackground = "setback-background",
  UsaCountiesFill = "usa-counties-fill",
  UsaCountiesBorder = "usa-counties-border",
  UsaZoningSymbol = "usa-zoning-symbol",
  UsaZoningBorder = "usa-zoning-border",
  DrawnParcels = "drawn-parcels",
  DrawnParcelsBorder = "drawn-parcels-border",
  ListingsQuery = "listings-query",
}

// Field IDs in Mapbox parcel layer
export enum RawParcelFieldId {
  Id = "ID",
  Address = "ADDRESS",
  City = "CITY",
  State = "STATE",
  ZipCode = "ZIP_CODE",
  AreaPublished = "LAND_SF",
  PurchasePrice = "AMKT_VALUE",
  ExistingStructureArea = "TOT_BL_SF",
  ExistingStructureYearBuilt = "YEAR_BUILT",
  BasicStratum = "BAS_STRT",
  LandUseCode = "DOR_UC",
  PublicLand = "PUBLIC_LND",
  ParcelId = "PARCEL_ID",
  NumberOfResidentialUnits = "NO_RES_UNT",
  NumberOfBuildings = "NO_BULDNG",
  ConstructionClass = "CONST_CLAS",
  ImprovementQuality = "IMP_QUAL",
  LandValue = "LND_VAL",
  SalePrice = "SALE_PRC1",
  SaleYear = "SALE_YR1",
  SaleMonth = "SALE_MO1",
  MultiParcelSale = "M_PAR_SAL1",
  OwnerName = "OWN_NAME",
  OwnerAddress = "OWN_ADDR1",
  OwnerCity = "OWN_CITY",
  OwnerState = "OWN_STATE",
  OwnerZipCode = "OWN_ZIPCD",
}

// Field IDs in Mapbox zoning layer
export enum RawZoningFieldId {
  AllowedBuildableArea = "BUILDABLE_SQFT",
  ZoningCode = "ZONING_CODE_ABBREVIATION",
  AllowedUses = "USES",
  ArchitectFirm = "ARCHITECT_FIRM",
  ArchitectUrl = "ARCHITECT_URL",
  BuildingHeight = "BUILDING_HEIGHT",
  LotCoverage = "LOT_COVERAGE",
  FloorAreaRatio = "FAR",
  NumberOfFloors = "NUMBER_OF_FLOORS",
  NumberOfUnitsAllowed = "MAX_NUMBER_UNITS_ALLOWED",
  AllowedDetailedUses = "ALL_USES",
  PrimarySetback = "PRIMARY_SETBACK",
  SideStreetSetback = "SIDE_STREET_SETBACK",
  InteriorSideSetback = "INTERIOR_SIDE_SETBACK",
  RearSetback = "REAR_SETBACK",
  LivingUnitDensityNumerator = "LIVING_UNIT_DENSITY_NUMERATOR",
  LivingUnitDensityDenominator = "LIVING_UNIT_DENSITY_DENOMINATOR",
  LivingUnitDensityUnitOfMeasure = "LIVING_UNIT_DENSITY_UNIT_OF_MEASURE",
  HotelUnitDensityNumerator = "HOTEL_UNIT_DENSITY_NUMERATOR",
  HotelUnitDensityDenominator = "HOTEL_UNIT_DENSITY_DENOMINATOR",
  HotelUnitDensityUnitOfMeasure = "HOTEL_UNIT_DENSITY_UNIT_OF_MEASURE",
  ParkingSpacesResidentialNumerator = "PARKING_SPACES_RESIDENTIAL_NUMERATOR",
  ParkingSpacesResidentialDenominator = "PARKING_SPACES_RESIDENTIAL_DENOMINATOR",
  ParkingSpacesResidentialUnitOfMeasure = "PARKING_SPACES_RESIDENTIAL_UNIT_OF_MEASURE",
  ParkingSpacesSingleFamilyNumerator = "PARKING_SPACES_SINGLE_FAMILY_NUMERATOR",
  ParkingSpacesSingleFamilyDenominator = "PARKING_SPACES_SINGLE_FAMILY_DENOMINATOR",
  ParkingSpacesSingleFamilyUnitOfMeasure = "PARKING_SPACES_SINGLE_FAMILY_UNIT_OF_MEASURE",
  ParkingSpacesHotelNumerator = "PARKING_SPACES_HOTEL_NUMERATOR",
  ParkingSpacesHotelDenominator = "PARKING_SPACES_HOTEL_DENOMINATOR",
  ParkingSpacesHotelUnitOfMeasure = "PARKING_SPACES_HOTEL_UNIT_OF_MEASURE",
  ParkingSpacesOfficeNumerator = "PARKING_SPACES_OFFICE_NUMERATOR",
  ParkingSpacesOfficeDenominator = "PARKING_SPACES_OFFICE_DENOMINATOR",
  ParkingSpacesOfficeUnitOfMeasure = "PARKING_SPACES_OFFICE_UNIT_OF_MEASURE",
  ParkingSpacesRetailNumerator = "PARKING_SPACES_RETAIL_NUMERATOR",
  ParkingSpacesRetailDenominator = "PARKING_SPACES_RETAIL_DENOMINATOR",
  ParkingSpacesRetailUnitOfMeasure = "PARKING_SPACES_RETAIL_UNIT_OF_MEASURE",
  ParkingSpacesIndustrialNumerator = "PARKING_SPACES_INDUSTRIAL_NUMERATOR",
  ParkingSpacesIndustrialDenominator = "PARKING_SPACES_INDUSTRIAL_DENOMINATOR",
  ParkingSpacesIndustrialUnitOfMeasure = "PARKING_SPACES_INDUSTRIAL_UNIT_OF_MEASURE",
}

export enum RawDemographicsFieldId {
  MedianIncomeTotal = "MEDIAN_INCOME_TOTAL",
  GrossMedianRent = "GROSS_MEDIAN_RENT",
  PopulationDensity = "POPULATION_DENSITY",
  Gdp = "GDP",
}

export const camera: { center: [number, number], zoom: number } = {
  center: [-94.110, 38.364],
  zoom: 3.65
}
