import React from "react";
import parcelAccessors from "../../../../../utils/parcel/parcelAccessors";
import authentication from "../../../../../utils/authentication";
import CellRow from "../../../CellRow";
import Cell from "../../../Cell";

type OwnProps = {
  parcelFeature: any;
}

type Props = OwnProps;

/**
 * Render Allowed Uses Details.
 */
const renderDetails = (parcelFeature, userIsAuthenticated) => {
  const allowedUses = parcelAccessors.getAllowedUses(parcelFeature);
  const mainUseCategories = Object.keys(allowedUses).sort();
  const hasDetailedUses = mainUseCategories.some((mainCategory) => allowedUses[mainCategory].length > 0);

  if (!hasDetailedUses) return null;

  return (
    <table>
      <thead>
        <tr>
          <th colSpan={2}>Uses Breakdown</th>
        </tr>
      </thead>
      <tbody>
        {mainUseCategories.map((mainCategory) => {
          if (allowedUses[mainCategory].length === 0) return null;
          return (
            <CellRow key={mainCategory}>
              <Cell text={`${mainCategory}`} styleVariation="capitalize" />
              <Cell value={allowedUses[mainCategory].sort()} styleVariation="bold capitalize" hiddenValues={!userIsAuthenticated} />
            </CellRow>
          );
        })}
      </tbody>
    </table>
  );
}

/**
 * Render Allowed uses section.
 */
const AllowedUses = (props: Props) => {
  const { parcelFeature } = props;
  if (!parcelFeature) return null;
  const userIsAuthenticated = authentication.isUserAuthenticated();
  const allowedUses = parcelAccessors.getAllowedUses(parcelFeature);
  const mainUseCategories = Object.keys(allowedUses).sort();

  if (mainUseCategories.length === 0) return null;

  return (
    <>
      <table>
        <thead>
          <tr>
            <th colSpan={2}>Uses Allowed</th>
          </tr>
        </thead>
        <tbody>
          {mainUseCategories.map((mainUse, index) => (
            <CellRow classes="center" key={index}>
              <Cell value={mainUse} styleVariation="bold capitalize" tdElementProps={{ colSpan: 2 }} hiddenValues={!userIsAuthenticated} />
            </CellRow>
          ))}
        </tbody>
      </table>
      {renderDetails(parcelFeature, userIsAuthenticated)}
    </>
  );
}

export default AllowedUses;
