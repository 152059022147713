import React from "react";
import { connect } from "react-redux";
import DashboardContent from "./DashboardContent";
import DashboardSidebar from "./DashboardSidebar";
import DashboardToolbar from "./DashboardToolbar";
import { userActions } from "../../../state/user";
import analytics from "../../../utils/analytics";
import { Path } from "../../../types/Path";

const mapDispatchToProps = {
  getUserLocation: userActions.getLocationStart,
}

type DispatchProps = typeof mapDispatchToProps;
type Props = DispatchProps;

class DashboardPage extends React.PureComponent<Props, {}> {
  constructor(props: Props) {
    super(props);
    props.getUserLocation();
    analytics.trackPageview(Path.Dashboard);
  }

  render() {
    return (
      <div className="component--dashboard-page">
        <DashboardToolbar />
        <DashboardSidebar />
        <DashboardContent />
      </div >
    )
  }
}

export default connect(null, mapDispatchToProps)(DashboardPage);
