const START = "pdf/START";
const FINISH = "pdf/FINISH";
const SET_MAP_IMAGE = "pdf/SET_MAP_IMAGE";
const SET_CAMERA = "pdf/SET_CAMERA";
const SET_TITLE = "pdf/SET_TITLE";
const SET_SUMMARY = "pdf/SET_SUMMARY";
const SET_FROM_CONTACTS_DETAILS = "pdf/SET_FROM_CONTACTS_DETAILS";
const SET_TO_CONTACTS_DETAILS = "pdf/SET_TO_CONTACTS_DETAILS";
const SET_COLOR_PALETTE = "pdf/SET_COLOR_PALETTE";
const UPLOAD_COVER_PAGE_IMAGE_START = "pdf/UPLOAD_COVER_PAGE_IMAGE_START";
const UPLOAD_COVER_PAGE_IMAGE_SUCCESS = "pdf/UPLOAD_COVER_PAGE_IMAGE_SUCCESS";
const DELETE_COVER_PAGE_IMAGE_START = "pdf/DELETE_COVER_PAGE_IMAGE_START";
const DELETE_COVER_PAGE_IMAGE_SUCCESS = "pdf/DELETE_COVER_PAGE_IMAGE_SUCCESS";
const CLEAR_STATE = "pdf/CLEAR_STATE";
const LOAD_DATA_SUCCESS = "pdf/LOAD_DATA_SUCCESS";
const SET_ADDRESS = "pdf/SET_ADDRESS";

export default {
  START,
  FINISH,
  SET_MAP_IMAGE,
  SET_CAMERA,
  SET_TITLE,
  SET_SUMMARY,
  SET_FROM_CONTACTS_DETAILS,
  SET_TO_CONTACTS_DETAILS,
  SET_COLOR_PALETTE,
  CLEAR_STATE,
  LOAD_DATA_SUCCESS,
  UPLOAD_COVER_PAGE_IMAGE_START,
  UPLOAD_COVER_PAGE_IMAGE_SUCCESS,
  DELETE_COVER_PAGE_IMAGE_START,
  DELETE_COVER_PAGE_IMAGE_SUCCESS,
  SET_ADDRESS,
};
