import { User } from "./reducers";

/**
 * Get user concern object.
 */
const getUser = (state): User => {
  return state.user;
}

/**
 * Get user location.
 */
const getLocation = (state) => {
  let user = getUser(state);
  return user.location;
}

/**
 * Get user tier.
 */
const getTier = (state) => {
  let user = getUser(state);
  return user.tier;
}

export default {
  getLocation,
  getTier,
}
