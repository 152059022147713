import actionTypes from "./actionTypes";

/**
 * Get user geolocation.
 */
const getLocationStart = () => {
  return {
    type: actionTypes.GET_LOCATION_START,
  }
}

/**
 * Success fetch of user's geolocation.
 */
const getLocationSuccess = (location: [any, any]) => {
  return {
    type: actionTypes.GET_LOCATION_SUCCESS,
    payload: {
      location
    }
  }
}

/**
 * Error fetching user's location.
 */
const getLocationError = () => {
  return {
    type: actionTypes.GET_LOCATION_ERROR,
  }
}

export default {
  getLocationSuccess,
  getLocationStart,
  getLocationError,
}
