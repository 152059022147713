import actionTypes from "./actionTypes";
import { VariableId } from "../../types/VariableId";
import Unit from "../../types/Unit";

/**
 * Initialize a new development state.
 *
 * @param {Object} initialState - A complete development state object with which
 *  to initialize the platform.
 */
const initialize = (initialState, projectId) => {
  return {
    type: actionTypes.INITIALIZE,
    payload: { initialState, projectId },
  };
};

/**
 * Recalculate the development state.
 *
 * @param {Object} newState - A complete development state object with which
 *  to re-initialize the platform.
 */
const recalculate = (newState) => {
  return {
    type: actionTypes.RE_CALCULATE,
    payload: { newState },
  };
};

/**
 * Set the value of a ranged development input.
 *
 * @param {string} inputId - The ID of the input.
 * @param {*} inputValue - The value of the input.
 */
const setRangedInput = (inputId: VariableId, inputValue: number, index?: number) => {
  return {
    type: actionTypes.SET_RANGED_INPUT,
    payload: { inputId, inputValue, index },
  };
};

/**
 * Set the maximum value of a ranged development input.
 *
 * @param {string} inputId - The ID of the input.
 * @param {*} inputMaximum - The maximum value of the input.
 */
const setRangedInputMaximum = (inputId: VariableId, inputMaximum, index?: number) => {
  return {
    type: actionTypes.SET_RANGED_INPUT_MAXIMUM,
    payload: { inputId, inputMaximum, index },
  };
};

/**
 * Set the minimum value of a ranged development input.
 *
 * @param {string} inputId - The ID of the input.
 * @param {*} inputMinimum - The minimum value of the input.
 */
const setRangedInputMinimum = (inputId: VariableId, inputMinimum, index?: number) => {
  return {
    type: actionTypes.SET_RANGED_INPUT_MINIMUM,
    payload: { inputId, inputMinimum, index },
  };
};

/**
 * Set the development's name.
 *
 * @param {string} name - The new name of the development.
 */
const setName = (name) => {
  return {
    type: actionTypes.SET_NAME,
    payload: { name },
  };
};

/**
 * Set the type of a given setback.
 *
 * @param {number} polygonIndex - The index of the polygon.
 * @param {number} setbackIndex - The index of the setback.
 * @param {string} setbackType - The setback type.
 */
const setSetbackType = (polygonIndex, setbackIndex, setbackType) => {
  return {
    type: actionTypes.SET_SETBACK_TYPE,
    payload: { polygonIndex, setbackIndex, setbackType },
  };
};

/**
 * Set the camera values.
 *
 * @param camera - Camera object
 */
const setCamera = (camera) => {
  return {
    type: actionTypes.SET_CAMERA,
    payload: { camera },
  }
};

/**
 * Toggle the value of a boolean development input.
 *
 * @param {string} inputId - The ID of the input.
 */
const toggleBooleanInput = (inputId: VariableId) => {
  return {
    type: actionTypes.TOGGLE_BOOLEAN_INPUT,
    payload: { inputId },
  };
};

/**
 * Set the unit system.
 *
 * @param unitSystem - Unit system.
 */
const setUnitSystem = (unitSystem:  Unit.System) => {
  return {
    type: actionTypes.SET_UNIT_SYSTEM,
    payload: { unitSystem },
  }
}

/**
 * Reset the development concern to its initial state.
 */
const resetState = () => {
  return {
    type: actionTypes.RESET_STATE
  }
}

/**
 * Update flag when project has been saved.
 */
const projectSaved = () => {
  return {
    type: actionTypes.PROJECT_SAVED
  }
}

/**
 * Set the isFromShared flag to false.
 */
const keepProject = () => {
  return {
    type: actionTypes.KEEP_PROJECT
  }
}

/**
 * Add a setback to the given floor.
 */
const addSetbackFloor = (floor: number) => {
  return {
    type: actionTypes.ADD_SETBACK_FLOOR,
    payload: { floor },
  }
}

/**
 * Remove the setback at the given floor.
 */
const removeSetbackFloor = (floor: number) => {
  return {
    type: actionTypes.REMOVE_SETBACK_FLOOR,
    payload: { floor },
  }
}

/**
 * Reset values for selected setback floor variables.
 */
const resetSelectedSetbackFloor = () => {
  return {
    type: actionTypes.RESET_SELECTED_SETBACK_FLOOR
  }
}

export default {
  initialize,
  recalculate,
  setRangedInput,
  setRangedInputMaximum,
  setRangedInputMinimum,
  setName,
  setSetbackType,
  setCamera,
  toggleBooleanInput,
  setUnitSystem,
  resetState,
  projectSaved,
  keepProject,
  addSetbackFloor,
  removeSetbackFloor,
  resetSelectedSetbackFloor,
};
