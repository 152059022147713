import React from "react";
import { connect } from "react-redux";
import { subscriptionSelectors } from "../../../../state/subscription";
import { Chargebee } from "../../../../types/Service/Chargebee";
import subscriptionStatus from "../../../../utils/chargebee/subscriptionStatus";
import Toolbar from "../../../sharedComponents/Toolbar";
import ToolbarSettings from "../../../sharedComponents/ToolbarSettings";
import RedirectDropdownButton from "../../../sharedComponents/RedirectDropdownButton";
import { Path } from "../../../../types/Path";

interface Props {
  subscriptionStatus: Chargebee.SubscriptionStatus | undefined;
}

class SubscriptionRequiredToolbar extends React.PureComponent<Props, {}>  {
  render() {
    return (
      <Toolbar >
        <ToolbarSettings>
          {subscriptionStatus.isEnabled(this.props.subscriptionStatus) && <RedirectDropdownButton path={Path.Dashboard} text="Dashboard" />}
        </ToolbarSettings>
      </Toolbar>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    subscriptionStatus: subscriptionSelectors.getStatus(state)
  }
}

export default connect(
  mapStateToProps
)(SubscriptionRequiredToolbar)
