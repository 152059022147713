import { ParcelProperty } from "utils/parcel/ParcelProperty";

export enum FilterId {
  Vacant = "vacant",
  ExistingStructureArea = "existingStructureArea",
  MedianIncomeTotal = "medianIncomeTotal",
  GrossMedianRent = "grossMedianRent",
  AreaComputed = "areaComputed",
  FloorAreaRatio = "floorAreaRatio",
  ExistingYearBuilt = "existingYearBuilt",
  NumberOfUnitsAllowed = "numberOfUnitsAllowed",
}

export type Filters = {
  [key in FilterId]: Filter;
}

export interface Filter {
  id: FilterId;
  variableId: ParcelProperty;
  isActive: boolean;
  shouldDisplay: boolean;
  value: Array<number>;
  allowUnauthenticated?: boolean;
}
