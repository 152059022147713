import firebase from "firebase/app";
import "firebase/storage";
import log from "loglevel";
import Pdf from "../../../types/Pdf";

/**
 * Upload a project pdf logo image to Firebase Storage.
 */
const upload = async (image: File, developmentId: string): Promise<void> => {
  let imageReference = firebase.storage().ref(pathToPdfCover(developmentId));

  try {
    await imageReference.put(image, { customMetadata: { fileName: image.name } });
  } catch (error) {
    log.warn(error);
  }
}

/**
 * Delete a project pdf logo image from Firebase Storage.
 */
const remove = async (developmentId: string): Promise<void> => {
  let imageReference = firebase.storage().ref(pathToPdfCover(developmentId));

  try {
    await imageReference.delete();
  } catch (error) {
    log.warn(error);
  }
}

/**
 * Construct the path to the pdf logo image of the given development in the Firebase Storage.
 */
const pathToPdfCover = (developmentId: string): string => {
  return `developments/pdfLogos/${developmentId}`;
}

/**
 * Download a project pdf logo image from Firebase Storage.
 */
const download = async (developmentId: string): Promise<Pdf.Image> => {
  let imageReference = firebase.storage().ref(pathToPdfCover(developmentId));
  let imageUrl = await imageReference.getDownloadURL();
  let imageMetadata = await imageReference.getMetadata();
  let response = await fetch(imageUrl);

  if (!response.ok) throw new Error(`A cover with ID "${developmentId}" could not be retrieved. The request return with status ${response.status}.`);

  let blob = await response.blob();
  let objectUrl = URL.createObjectURL(blob);
  return {
    fileName: imageMetadata.customMetadata.fileName,
    url: objectUrl,
    isLoading: false,
  }
}

export default {
  upload,
  remove,
  download
}
