import React from "react";
import ReactMapboxGl from "react-mapbox-gl";
import { connect } from "react-redux";
import { developmentSelectors } from "../../../../state/development";
import BuildingLayer from "../../../pages/ExplorerPage/Map/BuildingLayer";
import database from "../../../../utils/database/database";
import turf from "../../../../utils/turf";
import { MapStyleProperties } from "../../../../utils/mapbox/mapStyleProperties";

const PITCH = 60;
const BEARING = 30;

// The generated thumbnail should have these dimensions in pixels.
const THUMBNAIL_IMAGE_WIDTH = 600;
const THUMBNAIL_IMAGE_HEIGHT = 600;

// We have to adjust the canvas size by the device pixel ratio. This helps
// ensure thumbnail images are always saved with the same dimensions regardless
// of device, more info at:
// https://developer.mozilla.org/en-US/docs/Web/API/Window/devicePixelRatio
// https://stackoverflow.com/questions/12058574/pixel-density-retina-display-and-font-size-in-css
const CANVAS_WIDTH = `${THUMBNAIL_IMAGE_WIDTH / window.devicePixelRatio}px`;
const CANVAS_HEIGHT = `${THUMBNAIL_IMAGE_HEIGHT / window.devicePixelRatio}px`;

// Shrinking the canvas dimensions crops and distorts the resulting image.
// The effect can be countered by zooming the camera out. Each unit of zoom
// subtracted increases the map scale (map distance per pixel) by a power of 2.
const DEFAULT_ZOOM = 17;
const ADJUSTED_ZOOM = DEFAULT_ZOOM - Math.log2(window.devicePixelRatio);

const Mapbox = ReactMapboxGl({
  accessToken: process.env.REACT_APP_MAPBOX_ACCESS_TOKEN as string,
  interactive: false,
  preserveDrawingBuffer: true,
});

interface Props {
  parcel: turf.AllGeoJSON;
}

class ThumbnailMap extends React.PureComponent<Props, {}> {
  /**
   * Capture the map canvas once the style and building are fully loaded.
   */
  handleStyleLoad = (map) => {
    map.on(
      "idle",
      async () => {
        // The following three lines are a HACK to go around the fact that Microsoft Edge
        // does not support canvas.toBlob()
        let thumbnailUrl = map.getCanvas().toDataURL();
        let response = await fetch(thumbnailUrl);
        let thumbnailBlob = await response.blob();

        database.setThumbnail(thumbnailBlob)
      }
    );
  }

  render() {
    return (
      <div
        className="component--thumbnail-map"
        style={{ width: CANVAS_WIDTH, height: CANVAS_HEIGHT }}
      >
        <Mapbox
          style={MapStyleProperties.StyleUrl.Streets}
          zoom={[ADJUSTED_ZOOM]}
          pitch={[PITCH]}
          bearing={[BEARING]}
          movingMethod="jumpTo"
          center={turf.getCoord(turf.centerOfMass(this.props.parcel)) as [number, number]}
          onStyleLoad={this.handleStyleLoad}
          containerStyle={{
            height: "100%",
            width: "100%",
          }}
        >
          <BuildingLayer />
        </Mapbox>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    parcel: developmentSelectors.getParcel(state)
  };
}

export default connect(
  mapStateToProps
)(ThumbnailMap);
