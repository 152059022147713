import React from "react";
import { connect } from "react-redux";
import { pdfActions, pdfSelectors } from "../../../../../state/pdf";
import { Panel } from "../../../../../types/uiElements";
import Pdf from "../../../../../types/Pdf";
import PanelHeader from "../sharedComponents/PanelHeader";
import Summary from "./Summary";
import ImageUpload from "./ImageUpload";
import ColorPalette from "./ColorPalette/";
import ToContactDetails from "./ToContactDetails";
import FromContactDetails from "./FromContactDetails";
import analytics from "../../../../../utils/analytics";

const mapStateToProps = (state) => {
  return {
    pdfIsActive: pdfSelectors.getIsActive(state),
    title: pdfSelectors.getTitle(state),
    address: pdfSelectors.getAddress(state),
  }
}

const mapDispatchToProps = {
  startPdf: pdfActions.start,
  setTitle: pdfActions.setTitle,
  setAddress: pdfActions.setAddress,
}

interface OwnProps {
  panel: Panel;
  onPanelClose(panel: Panel);
  onPanelPin(panel: Panel);
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;
type Props = OwnProps & StateProps & DispatchProps;

class PdfPanel extends React.PureComponent<Props, {}> {
  /**
   * Start the generation of the pdf document if there isn't one already being generated.
   */
  startPdf = () => {
    if (!this.props.pdfIsActive) {
      analytics.trackGeneratePdfReport();
      this.props.startPdf();
    }
  }

  /**
   * Save report title in the store.
   */
  updateReportTitle = (event) => {
    this.props.setTitle(event.target.value);
  }

  /**
   * Save address details changes in the store.
   */
  handleAddressChanges = (value, addressKey: keyof Pdf.Address) => {
    this.props.setAddress({ [addressKey]: value });
  }

  render() {
    return (
      <div className="component--pdf-panel panel" >
        <PanelHeader
          isPinned={this.props.panel.isPinned}
          panel={this.props.panel}
          onPanelClose={this.props.onPanelClose}
          onPanelPin={this.props.onPanelPin}
        />
        <div className="panel-inner-wrapper">
          <input
            className="report-title"
            value={this.props.title}
            onChange={this.updateReportTitle}
            autoComplete="is-off"
            placeholder="Enter Report Title"
          />

          <input
            className="address"
            value={this.props.address.address}
            onChange={(event) => this.handleAddressChanges(event.target.value, "address")}
            autoComplete="is-off"
            placeholder="Property Address"
          />

          <div className="address-container">
            <input
              className="city"
              value={this.props.address.city}
              onChange={(event) => this.handleAddressChanges(event.target.value, "city")}
              autoComplete="is-off"
              placeholder="City"
            />
            <input
              className="state"
              value={this.props.address.state}
              onChange={(event) => this.handleAddressChanges(event.target.value, "state")}
              autoComplete="is-off"
              placeholder="State"
            />
            <input
              className="zip-code"
              value={this.props.address.zipCode}
              onChange={(event) => this.handleAddressChanges(event.target.value, "zipCode")}
              autoComplete="is-off"
              placeholder="Zip Code"
            />
          </div>

          <FromContactDetails />
          <ToContactDetails />

          <Summary />

          <ImageUpload
            classPrefix="cover"
            labelText="Choose Cover Image"
            imageType={Pdf.ImageType.Cover}
          />
          <span className="dimension-message">826px by 566px (recommended)</span>

          <ImageUpload
            classPrefix="logo"
            labelText="Choose Your Company Logo"
            imageType={Pdf.ImageType.Logo}
          />
          <span className="dimension-message">152px by 29px (recommended)</span>

          <ColorPalette />

          <button id="export-pdf-button" onClick={this.startPdf}>Generate PDF Report</button>
        </div>
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PdfPanel);
