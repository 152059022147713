import developmentAccessors from "../../../state/development/utils/developmentAccessors";
import parcelAccessors from "../../parcel/parcelAccessors";
import { Development } from  "../../../types/Development/Development";
import { VariableId } from  "../../../types/VariableId";

/**
 * Generate a JSON object with the setbacks presets.
 */
const generatePresetsForSetbacks = (development: Development) => {
  const parcel = developmentAccessors.getParcel(development);
  const parcelArea = parcelAccessors.getAreaPreferPublished(parcel);
  const maximumSetback = Math.round(Math.sqrt(parcelArea) / 2);

  return {
    [VariableId.SetbackA]: {
      "minimum": [0],
      "maximum": [maximumSetback],
      "value": [0],
    },
    [VariableId.SetbackB]: {
      "minimum": [0],
      "maximum": [maximumSetback],
      "value": [0],
    },
    [VariableId.SetbackC]: {
      "minimum": [0],
      "maximum": [maximumSetback],
      "value": [0],
    },
    [VariableId.SetbackD]: {
      "minimum": [0],
      "maximum": [maximumSetback],
      "value": [0],
    },
  };
}

export default generatePresetsForSetbacks;
