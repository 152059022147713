import React from "react";
import { connect } from "react-redux";
import { GeoJSONLayer } from "react-mapbox-gl";
import { newDevelopmentSelectors } from "../../../../../state/newDevelopment";
import mapboxPresentationProperties from "../../../../utils/mapboxPresentationProperties";

interface Props {
  selectedFeature: any;
}

class SelectedFeatureLayer extends React.PureComponent<Props, {}> {
  render() {
    return (
      <GeoJSONLayer
        data={{
          type: "FeatureCollection",
          features: this.props.selectedFeature ? [this.props.selectedFeature] : []
        }}
        fillPaint={mapboxPresentationProperties.parcelSelectFillPaint}
        linePaint={mapboxPresentationProperties.parcelSelectLinePaint}
      />
    )
  }
}

const mapStateToProps = (state) => {
  return ({
    selectedFeature: newDevelopmentSelectors.getSelectedFeature(state),
  })
}

export default connect(
  mapStateToProps
)(SelectedFeatureLayer);
