import React from "react";

const BusySpinner = (props) => {
  return (
    <div className={`component--busy-spinner ${props.classes ? props.classes : ""}`}>
      <div className="spinner" />
    </div>
  );
}

export default BusySpinner;
