import React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { withRouter } from "react-router-dom";
import { Path } from "../../../types/Path";
import MapboxGeocoder from "../MapboxGeocoder";
import RedirectButton from "../RedirectButton";
import { newDevelopmentSelectors, newDevelopmentActions } from "../../../state/newDevelopment";
import { GeocodePlaceType } from "../../../types/Mapbox/Mapbox";

const mapStateToProps = (state) => {
  return {
    pinPosition: newDevelopmentSelectors.getPinPosition(state),
    searchAddress: newDevelopmentSelectors.getSearchAddress(state),
    suggestedFeaturePlaceType: newDevelopmentSelectors.getSuggestedFeaturePlaceType(state),
  }
}

const mapDispatchToProps = {
  initializeNewDevelopment: newDevelopmentActions.initialize,
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;
type RouterProps = RouteComponentProps;
type Props = StateProps & DispatchProps & RouterProps;

interface State {
  errorMessage: string;
}

class GeocoderSearch extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      errorMessage: "",
    }

    props.initializeNewDevelopment();
  }

  /**
   * Check if user has selected item from the dropdown.
   */
  inputIsValid = () => {
    if (!Boolean(this.props.pinPosition) || !this.props.searchAddress) {
      this.setState({ errorMessage: "Please enter a city and select from the dropdown list." });
      return false;
    }

    return true;
  }

  render() {
    const { pinPosition, suggestedFeaturePlaceType } = this.props;
    const path = pinPosition ? `${Path.NewProject}/${pinPosition[0]},${pinPosition[1]},16.1` : "";

    return (
      <div className="component--geocoder-search">
        <div className="logo" />
        <div className={`geocoder-container ${this.state.errorMessage ? "error" : ""}`}>
          <MapboxGeocoder placeholder="Enter city to get started…" />

          <div className="error-container">
            <p>{this.state.errorMessage}</p>
          </div>

          <RedirectButton
            text="START"
            path={path}
            shouldContinue={this.inputIsValid}
            state={{
              smartSearchIsOpen: !suggestedFeaturePlaceType.includes(GeocodePlaceType.Address),
              displayPin: suggestedFeaturePlaceType.includes(GeocodePlaceType.Address),
            }}
          />
        </div>
      </div>
    );
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(GeocoderSearch)
);
