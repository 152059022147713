import React from "react";
import { connect } from "react-redux";
import { listingsSelectors } from "../../../state/listings";
import { ListingProperty, listingLabelMap, Listing, Media } from "../../../types/Service/Listings/Listings";
import CellRow from "../CellRow";
import Cell from "../Cell";
import listingsHelper from "../../../utils/listingsHelper";
import Format from "../../../types/Format";
import companyInfo from "../../../utils/companyInfo";
import AccordionSection from "../AccordionSection";
import valueFormatter from "../../../utils/valueFormatter";
import roundToDecimal from "../../../utils/roundToDecimal";
import { accordionSectionSelectors, accordionSectionActions } from "../../../state/ui/shared/accordionSection";

const mapStateToProps = (state) => {
  return {
    selectedListings: listingsSelectors.getSelectedListings(state),
    accordionsAreOpen: accordionSectionSelectors.getListingsAccordionsAreOpen(state),
  };
}

const mapDispatchToProps = {
  setListingAccordionIsOpen: accordionSectionActions.setListingAccordionIsOpen,
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;
type Props = StateProps & DispatchProps;

export class PropertyListing extends React.PureComponent<Props, {}> {
  /**
   * Render a row conditionally.
   */
  renderConditionalRow = (selectedListing: Listing, listingProperty: ListingProperty, formatOptions?: Format.Options, styleVariation?: string) => {
    if (!listingsHelper.propertyValueIsValid(selectedListing, listingProperty)) return null;
    const rawValue = selectedListing[listingProperty];
    const value = typeof selectedListing[listingProperty] === "boolean" ? listingsHelper.getBooleanText(rawValue) : rawValue;

    return (
      <CellRow key={listingProperty}>
        <Cell text={listingLabelMap[listingProperty]} />
        <Cell value={value} formatOptions={formatOptions} styleVariation={styleVariation} />
      </CellRow>
    );
  }

  /**
   * Render table section.
   */
  renderTableSection = (
    selectedListing: Listing,
    tableLabel: string,
    listingProperties: Array<ListingProperty>,
    formatOptionsMap: { [key in ListingProperty]?: Format.Options } = {},
    styleVariationsMap: { [key in ListingProperty]?: string } = {},
  ) => {
    if (!selectedListing) return null;

    if (!listingProperties.some((listingProperty) => listingsHelper.propertyValueIsValid(selectedListing, listingProperty))) {
      return null;
    }

    return (
      <table>
        <thead>
          <tr>
            <th colSpan={2}>{tableLabel}</th>
          </tr>
        </thead>
        <tbody>
          {
            listingProperties.map((listingProperty: ListingProperty) =>
              this.renderConditionalRow(selectedListing, listingProperty, formatOptionsMap[listingProperty], styleVariationsMap[listingProperty]))
          }
        </tbody>
      </table>
    );
  }

  /**
   * Render the links table section.
   */
  renderLinksTableSection = (selectedListing: Listing, tableLabel: string, listingProperties: Array<ListingProperty>) => {
    if (!selectedListing) return null;

    if (!listingProperties.some((listingProperty) => listingsHelper.propertyValueIsValid(selectedListing, listingProperty))) {
      return null;
    }

    return (
      <table>
        <thead>
          <tr>
            <th colSpan={2}>{tableLabel}</th>
          </tr>
        </thead>
        <tbody>
          {
            listingProperties.map((listingProperty) => {
              if (!listingsHelper.propertyValueIsValid(selectedListing, listingProperty)) return null;

              return (
                <tr key={listingProperty}>
                  <td>{listingLabelMap[listingProperty]}</td>
                  <td>
                    {
                      listingProperty === ListingProperty.Media || listingProperty === ListingProperty.Supplements
                          ? selectedListing[listingProperty].map((media: Media, index) => <span key={index}><a href={media.MediaURL} target="_blank" rel="noreferrer noopener">{`link ${index + 1}`}</a><br /></span>)
                          : <a href={selectedListing[listingProperty]} target="_blank" rel="noreferrer noopener">link</a>
                    }
                  </td>
                </tr>
              );
            })
          }
        </tbody>
      </table>
    );
  }

  /**
   * Get boolean value from the accordion is open flag.
   */
  getAccordionSectionIsOpen = (accordionId) => {
    return this.props.accordionsAreOpen[accordionId] === undefined ? true : this.props.accordionsAreOpen[accordionId];
  }

  /**
   * Toggle accordion is open flag by id.
   */
  toggleAccordionSection = (accordionId) => {
    const currentValue = this.getAccordionSectionIsOpen(accordionId);
    this.props.setListingAccordionIsOpen(accordionId, !currentValue)
  }

  /**
   * Render the listing table.
   */
  renderListingTable = (selectedListings: Array<Listing>) => {
    return (
      <>
        <div className="disclaimer">Listing data provided by the Miami Association of Realtors.</div>
        {selectedListings.map((listing, index) => {
          const priceLabel = valueFormatter.format(roundToDecimal(listing[ListingProperty.ListPrice], 1), { type: Format.Type.Number, abbreviate: true });
          const isOpen = this.getAccordionSectionIsOpen(listing[ListingProperty.ListingId]);

          return (
            <AccordionSection
              title={`Listing ${index + 1}: ${priceLabel}`}
              key={index}
              isOpen={isOpen}
              toggleIsOpen={() => this.toggleAccordionSection(listing[ListingProperty.ListingId])}
            >
              <div className="table-container">
                {this.renderTableSection(listing, "Listing Intro",
                  [
                    ListingProperty.MlsStatus,
                    ListingProperty.Address,
                    ListingProperty.ListPrice,
                  ],
                  {
                    [ListingProperty.ListPrice]: { type: Format.Type.Currency, decimalPlaces: 2 },
                  },
                )}
                {this.renderTableSection(listing, "Listing Facts",
                  [
                    ListingProperty.ListingId,
                    ListingProperty.ListingTerms,
                    ListingProperty.ExpirationDate,
                    ListingProperty.MajorChangeType,
                    ListingProperty.PreviousListPrice,
                    ListingProperty.DaysOnMarket,
                    ListingProperty.OnMarketDate,
                    ListingProperty.LeaseConsideredYN,
                  ],
                  {
                    [ListingProperty.PreviousListPrice]: { type: Format.Type.Currency, decimalPlaces: 2 },
                    [ListingProperty.DaysOnMarket]: { type: Format.Type.Number, suffix: " Days" },
                  },
                )}
                {this.renderTableSection(listing, "Property",
                  [
                    ListingProperty.LotSizeAcres,
                    ListingProperty.LotSizeSquareFeet,
                    ListingProperty.LotSizeDimensions,
                    ListingProperty.FrontageLength,
                    ListingProperty.LotFeatures,
                    ListingProperty.PropertyType,
                    ListingProperty.WaterfrontYN,
                    ListingProperty.RoadFrontageType,
                  ],
                  {
                    [ListingProperty.LotSizeAcres]: { type: Format.Type.Number, suffix: " Acres" },
                    [ListingProperty.LotSizeSquareFeet]: { type: Format.Type.Number, suffix: " SF" },
                    [ListingProperty.LotSizeDimensions]: { type: Format.Type.Number, suffix: " ft" },
                    [ListingProperty.FrontageLength]: { type: Format.Type.Number, suffix: " ft" },
                  },
                )}
                {this.renderTableSection(listing, "Building",
                  [
                    ListingProperty.BuildingName,
                    ListingProperty.CurrentUse,
                    ListingProperty.BuildingAreaTotal,
                    ListingProperty.StoriesTotal,
                    ListingProperty.YearBuilt,
                    ListingProperty.StructureType,
                    ListingProperty.GreenBuildingVerificationType,
                    ListingProperty.NumberOfUnitsInCommunity,
                    ListingProperty.HabitableResidenceYN,
                    ListingProperty.LeasableAreaUnits,
                    ListingProperty.OccupantType,
                    ListingProperty.LeaseTerm,
                  ],
                  {
                    [ListingProperty.BuildingAreaTotal]: { type: Format.Type.Number, suffix: " SF" },
                  },
                )}
                {this.renderTableSection(listing, "Building Financials",
                  [
                    ListingProperty.CapRate,
                    ListingProperty.NetOperatingIncome,
                    ListingProperty.GrossScheduledIncome,
                    ListingProperty.OperatingExpenseIncludes,
                  ],
                  {
                    [ListingProperty.CapRate]: { type: Format.Type.Number, suffix: "%" },
                    [ListingProperty.NetOperatingIncome]: { type: Format.Type.Currency },
                    [ListingProperty.GrossScheduledIncome]: { type: Format.Type.Currency },
                  },
                )}
                {this.renderTableSection(listing, "Utilities",
                  [
                    ListingProperty.NumberOfSeparateWaterMeters,
                    ListingProperty.WaterSource,
                    ListingProperty.Sewer,
                    ListingProperty.Utilities,
                  ],
                )}
                {this.renderTableSection(listing, "Listing Descriptions",
                  [
                    ListingProperty.TaxLegalDescription,
                    ListingProperty.AdditionalParcelsDescription,
                    ListingProperty.PublicRemarks,
                    ListingProperty.PrivateRemarks,
                    ListingProperty.Disclaimer,
                    ListingProperty.SyndicationRemarks,
                    ListingProperty.DocumentsAvailable,
                  ],
                  {},
                  {
                    [ListingProperty.AdditionalParcelsDescription]: "justify-left",
                    [ListingProperty.PublicRemarks]: "justify-left",
                    [ListingProperty.PrivateRemarks]: "justify-left",
                    [ListingProperty.Disclaimer]: "justify-left",
                    [ListingProperty.SyndicationRemarks]: "justify-left",
                  },
                )}
                {this.renderLinksTableSection(listing, "Listing Links",
                  [
                    ListingProperty.Media,
                    ListingProperty.VirtualTourURLUnbranded,
                    ListingProperty.Supplements,
                  ],
                )}
                {this.renderTableSection(listing, "Taxes",
                  [
                    ListingProperty.TaxAnnualAmount,
                    ListingProperty.TaxAssessedValue,
                    ListingProperty.TaxYear,
                  ],
                  {
                    [ListingProperty.TaxAnnualAmount]: { type: Format.Type.Currency },
                    [ListingProperty.TaxAssessedValue]: { type: Format.Type.Currency },
                  },
                )}
                {this.renderTableSection(listing, "Address (more info)",
                  [
                    ListingProperty.ParcelNumber,
                    ListingProperty.PostalCode,
                    ListingProperty.City,
                    ListingProperty.CountyOrParish,
                    ListingProperty.StateOrProvince,
                  ],
                )}
                {this.renderTableSection(listing, "Listing Agent",
                  [
                    ListingProperty.ListAgentFullName,
                    ListingProperty.ListAgentOfficePhone,
                    ListingProperty.ListAgentCellPhone,
                    ListingProperty.ListAgentEmail,
                  ],
                )}
                {this.renderTableSection(listing, "Listing Office",
                  [
                    ListingProperty.ListOfficeName,
                    ListingProperty.ListOfficePhone,
                    ListingProperty.ListingAgreement,
                    ListingProperty.ListAgentMlsId,
                  ],
                )}
                {this.renderTableSection(listing, "Listing (more info)",
                  [
                    ListingProperty.ShowingInstructions,
                    ListingProperty.BuyerAgencyCompensation,
                    ListingProperty.TransactionBrokerCompensation,
                  ],
                )}
              </div>
            </AccordionSection>
          )
        }
        )}
      </>
    );
  }

  /**
   * Render missing list.
   */
  renderMissingListing = () => (
    <>
      <div className="missing-listing-icon" />
      There is no active listing in our records for this property. <br />
      Would you like to showcase your listing?
      <a href={companyInfo.LISTINGS_URLS} className="learn-more" target="_blank" rel="noreferrer noopener">Learn More</a>
    </>
  )

  render() {
    const { selectedListings } = this.props;

    return (
      <div className="component--property-listing">
        {
          selectedListings
              ? this.renderListingTable(selectedListings)
              : this.renderMissingListing()
        }
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PropertyListing);
