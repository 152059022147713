import React from "react";
import authentication from "../../../utils/authentication";
import analytics from "../../../utils/analytics";
import ActivityFeedback from "../../sharedComponents/ActivityFeedback";
import Navbar from "../../sharedComponents/Navbar";
import { Path } from "../../../types/Path";

enum Status {
  Sending = "SENDING",
  Sent = "SENT"
}

interface State {
  status: Status;
};

interface Props {
  location: any;
}

const FEEDBACK_TEXT = {
  verifying: "Verifying your email...",
  success: "Your email has been verified!",
  failure: "This link has expired."
}

const SPINNING_TIME = 1000; // 1 second.

export default class EmailVerificationComplete extends React.PureComponent<Props, State> {
  emailIsVerified: boolean;

  constructor(props) {
    super(props);

    if (this.props.location.state && this.props.location.state.actionCode) {
      this.verifyEmail();
    }

    this.state = {
      status: Status.Sending,
    }

    this.emailIsVerified = false;
    analytics.trackPageview(Path.VerifyEmail);
  }

  /**
   * Verify the user email and manage the state changes to provide user with feedback.
   */
  verifyEmail = async () => {
    try {
      await authentication.verifyEmail(this.props.location.state.actionCode);
      this.emailIsVerified = true;
      analytics.trackVerifyEmail();
    } catch {
      this.emailIsVerified = false;
    }

    setTimeout(
      () => {
        this.setState({ status: Status.Sent });
      }, SPINNING_TIME
    );
  }

  /**
   * Render the feedback to the user.
   */
  renderFeedback = () => {
    switch (this.state.status) {
      case Status.Sending:
        return (
          <ActivityFeedback
            message={FEEDBACK_TEXT.verifying}
            displaySpinner={true}
          />
        );
      case Status.Sent:
        if (this.emailIsVerified) {
          return (
            <ActivityFeedback
              message={FEEDBACK_TEXT.success}
              secondaryMessage="You may close this tab."
              displaySpinner={false}
            />
          );
        } else {
          return (
            <ActivityFeedback
              message={FEEDBACK_TEXT.failure}
              secondaryMessage="You may close this tab."
              displaySpinner={false}
            />
          );
        }
      default:
        return null;
    }
  }

  render() {
    return (
      <div className="component--email-verification-complete-page">
        <Navbar disableLink />
        {this.renderFeedback()}
      </div>
    );
  }
}
