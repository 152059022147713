import React from "react";
import { connect } from "react-redux";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { developmentSelectors } from "../../../../state/development";
import analytics from "../../../../utils/analytics";
import database from "../../../../utils/database/database";
import Toolbar from "../../../sharedComponents/Toolbar";
import Dropdown from "../../../sharedComponents/Dropdown";
import developmentUiHelper from "../../../utils/developmentUiHelper";
import ToolbarSettings from "../../../sharedComponents/ToolbarSettings";
import DeleteDevelopment from "../../../sharedComponents/DeleteDevelopment";
import KpiList from "./KpiList";
import { freshToggles, selectedKpis, setToggles, swap, getKpiListForUnitSystem } from "./utils/kpiHelper";
import RedirectDropdownButton from "../../../sharedComponents/RedirectDropdownButton";
import { Path } from "../../../../types/Path";
import authentication from "../../../../utils/authentication";
import AuthenticationButtons from "../../../sharedComponents/AuthenticationButtons";
import RedirectWarningPopup from "./RedirectWarningPopup";
import CopyProjectPopup from "./CopyProjectPopup";
import ShareProject from "../../../sharedComponents/ShareProject";

const mapStateToProps = (state) => {
  return {
    developmentName: developmentSelectors.getName(state),
    unitSystem: developmentSelectors.getUnitSystem(state),
  }
}

type StateProps = ReturnType<typeof mapStateToProps>;
type RouterProps = RouteComponentProps<{ projectId: string }>;
type Props = StateProps & RouterProps;

interface State {
  selectedKpis: any;
  kpiList: any;
  toggles: any;
  projectsMenuIsActive: boolean;
  copyProjectPopupIsActive: boolean;
  shareProjectPopupIsActive: boolean;
  projectIdToRemove: any;
  redirectWarningIsActive: boolean;
}

class ExplorerToolbar extends React.Component<Props, State> {
  developmentsList: any;

  constructor(props) {
    super(props);

    let kpiList = getKpiListForUnitSystem(props.unitSystem);

    this.state = {
      selectedKpis: selectedKpis,
      kpiList: kpiList,
      toggles: freshToggles,
      projectsMenuIsActive: false,
      copyProjectPopupIsActive: false,
      shareProjectPopupIsActive: false,
      projectIdToRemove: null,
      redirectWarningIsActive: false,
    };

    this.developmentsList = database.getCurrentUserDevelopments();
  }

  /**
   * Update the KPI List when the unit system is changed.
   */
  componentDidUpdate(previousProps: Props) {
    if (previousProps.unitSystem !== this.props.unitSystem) {
      this.setState({ kpiList: getKpiListForUnitSystem(this.props.unitSystem) })
    }

    if (previousProps.match.params.projectId !== this.props.match.params.projectId) {
      this.updateProjectList();
    }
  }

  /**
   * Toggle a KPI.
   */
  toggleKpi = (id) => {
    return (flip) => () =>
      this.setState(({ toggles }) => setToggles(toggles, id, flip));
  }

  /**
   * Select the provided kpi into the header of the dropdown at the position of the given index.
   */
  selectKpi = (index, kpiId) => {
    return () =>
      this.setState(({ selectedKpis }) => swap(selectedKpis, index, kpiId));
  }

  /**
   * Update the projects list and the current project.
   */
  updateProjectList = () => {
    this.developmentsList = database.getCurrentUserDevelopments();
  }

  /**
   * Set the active/inactive state of the Projects Menu dropdown to the given
   * value.
   */
  setProjectsMenuState = (state) => {
    this.setState({ projectsMenuIsActive: state });
  }

  /**
   * Load a Project.
   */
  switchProject = async (projectId) => {
    // IMPORTANT: Empty the list of projects so they don't show up in the dropdown while the project
    // is loading. That way the user can not change projects while a project is loading. This
    // is a safety measure to eliminate any possible issue that may appear as a result of a
    // racing condition.
    this.developmentsList = [];

    await database.saveCurrentProject();
    this.props.history.push(`${Path.Explorer}/${projectId}`);
    analytics.trackOpenProject(projectId);
    this.updateProjectList();
  }

  /**
   * Toggle the copyProjectPopupIsActive state.
   */
  toggleCopyProjectPopupState = () => {
    this.setState({ copyProjectPopupIsActive: !this.state.copyProjectPopupIsActive });
  }

  /**
   * Callback for deleting a development.
   */
  handleDeleteDevelopment = () => {
    this.updateProjectList();
    this.setProjectsMenuState(false);
  }

  /**
   * Render the list of projects on the projects dropdown.
   */
  renderDropdownList = () => {
    let developmentList = this.developmentsList.filter((development) => development.id !== this.props.match.params.projectId);

    return developmentList.map((development) =>
      <li key={`development_${development.id}`}>
        <div className="button" onClick={() => this.switchProject(development.id)} >
          <span className="project-name">{developmentUiHelper.getDisplayName(development.name)}</span>

          <DeleteDevelopment
            developmentId={development.id}
            developmentName={developmentUiHelper.getDisplayName(development.name)}
            onDeleteDevelopment={this.handleDeleteDevelopment}
          />
        </div>
      </li>
    );
  }

  /**
   * Render the project selection menu.
   */
  renderProjectSelectionMenu = () => {
    return (
      <Dropdown
        isActive={this.state.projectsMenuIsActive}
        onClick={() => this.setProjectsMenuState(!this.state.projectsMenuIsActive)}
        onBlur={() => this.setProjectsMenuState(false)}
        toggleButton={
          <div className="project-name-container">
            <span className="project-name"> {developmentUiHelper.getDisplayName(this.props.developmentName)} </span>
            {this.developmentsList.length > 1 && <div className={`kpi-triangle ${this.state.projectsMenuIsActive && "active"}`} />}
          </div>
        }
      >
        {this.renderDropdownList()}
      </Dropdown>
    );
  }

  /**
   * Render the copy project dropdown button.
   */
  renderCopyProjectDropdownButton = () => (
    <li key="copy-project" onClick={this.toggleCopyProjectPopupState}>
      <div className="button">Copy Project</div>
    </li>
  );

  /**
   * Save project
   */
  saveCurrentProject = async () => {
    await database.saveCurrentProject();
  }

  /**
   * Set flag to hide redirect warning.
   */
  hideRedirectWarning = () => {
    this.setState({ redirectWarningIsActive: false });
  }

  /**
   * Handler for redirect to new project page button.
   */
  handleRedirectToNewProjectButton = async () => {
    const userIsAuthenticated = authentication.isUserAuthenticated();
    if (userIsAuthenticated) {
      await this.saveCurrentProject();
      this.props.history.push(Path.NewProject);
      return;
    }

    this.setState({ redirectWarningIsActive: true });
  }

  render() {
    const userIsAuthenticated = authentication.isUserAuthenticated();
    const projectName = developmentUiHelper.getDisplayName(this.props.developmentName);
    const projectId = this.props.match.params.projectId;

    return (
      <div className="component--explorer-toolbar">
        <Toolbar>
          <div className="projects-container">
            {this.renderProjectSelectionMenu()}
          </div>

          <KpiList
            selectedKpis={this.state.selectedKpis}
            kpiList={this.state.kpiList}
            toggles={this.state.toggles}
            toggle={this.toggleKpi}
            onSelectKpi={this.selectKpi}
          />

          <div className="buttons-container">
            <ShareProject developmentName={projectName} developmentId={projectId} classes="explorer" />

            {userIsAuthenticated &&
                <div className={`button ${this.state.copyProjectPopupIsActive ? "active" : ""}`} onClick={this.toggleCopyProjectPopupState}>
                  <div className="icon duplicate-project" />
                  <div className="text">Duplicate</div>
                </div>
            }

            <div className={`button ${this.state.redirectWarningIsActive ? "active" : ""}`} onClick={this.handleRedirectToNewProjectButton}>
              <div className="icon new-project" />
              <div className="text">New</div>
            </div>

            <AuthenticationButtons />

            {this.state.redirectWarningIsActive && <RedirectWarningPopup onStateChange={this.hideRedirectWarning} onClickSubscribe={this.saveCurrentProject} />}
            {this.state.copyProjectPopupIsActive && <CopyProjectPopup onStateChange={this.toggleCopyProjectPopupState} />}
          </div>

          <ToolbarSettings>
            {userIsAuthenticated && <RedirectDropdownButton path={Path.NewProject} text="New Project" onClick={this.saveCurrentProject} />}
            {userIsAuthenticated && this.renderCopyProjectDropdownButton()}
            {userIsAuthenticated && <RedirectDropdownButton path={Path.Dashboard} shouldContinue={() => Boolean(userIsAuthenticated)} text="Dashboard" onClick={this.saveCurrentProject} />}
          </ToolbarSettings>
        </Toolbar>
      </div>
    );
  }
}

export default withRouter(
  connect(
    mapStateToProps,
  )(ExplorerToolbar)
) as any;
