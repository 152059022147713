import React from "react";
import { connect } from "react-redux";
import { CardComponent, CardNumber, CardExpiry, CardCVV } from "@chargebee/chargebee-js-react-wrapper";
import BusySpinner from "../../../../sharedComponents/BusySpinner";
import { Chargebee } from "../../../../../types/Service/Chargebee";
import chargebee from "../../../../../utils/functions/chargebee";
import authentication from "../../../../../utils/authentication";
import { subscriptionActions } from "../../../../../state/subscription";
import database from "../../../../../utils/database/database";
import valueFormatter from "../../../../../utils/valueFormatter";
import Format from "../../../../../types/Format";

const mapDispatchToProps = {
  loadSubscription: subscriptionActions.loadStart,
}

interface OwnProps {
  subscriptionPlanId: Chargebee.PlanId;
}

type DispatchProps = typeof mapDispatchToProps;
type Props = DispatchProps & OwnProps;

type Errors = {
  [field in string]: { message: string }
}

interface State {
  firstName: string;
  lastName: string;
  coupon: Chargebee.Coupon | null;
  estimate: Chargebee.Estimate | null;
  couponId: string;
  discountPrice: number | null;
  errors: Errors;
  errorMessage: string;
  paymentFieldsAreReady: boolean;
  fetchingCoupon: boolean;
  submittingPayment: boolean;
};

const PLAN_TEXT_MAP = {
  [Chargebee.PlanId.StandardMonthly]: "Standard Monthly",
  [Chargebee.PlanId.StandardYearly]: "Standard Yearly",
  [Chargebee.PlanId.AdvancedMonthly]: "Advanced Monthly",
  [Chargebee.PlanId.AdvancedYearly]: "Advanced Yearly",
  [Chargebee.PlanId.ProMonthly]: "Pro Monthly",
  [Chargebee.PlanId.ProYearly]: "Pro Yearly",
}

const COUPON_DOES_NOT_EXIST_ERROR_MESSAGE = "This coupon code is incorrect or expired.";
const COUPON_FOR_DIFFERENT_SUBSCRIPTION = "This coupon is for a different plan.";
const MISSING_NAME_ERROR_MESSAGE = "First and last names are required";
const FETCH_COUPON_TIMEOUT = 1000;

const STYLES = {
  base: {
    color: "#000",
    fontSize: "13px",
    fontSmoothing: "antialiased",

    ":focus": {
      color: "#424770",
    },

    "::placeholder": {
      color: "#BDBDBD",
      textAlign: "center",
    },
  },
  invalid: {
    color: "red",
    ":focus": {
      color: "#F26C6C",
    },
    "::placeholder": {
      color: "#F26C6C",
    },
  },
}

const CLASSES = {
  "focus": "focus-css-class",
  "complete": "complete-css-class",
  "invalid": "invalid-css-class",
  "empty": "empty-css-class",
}

export class Subscribe extends React.PureComponent<Props, State> {
  cardReference;
  fetchCouponTimeout;

  constructor(props: Props) {
    super(props);

    this.cardReference = React.createRef()

    this.state = {
      firstName: "",
      lastName: "",
      couponId: "",
      coupon: null,
      estimate: null,
      discountPrice: null,
      errors: {},
      errorMessage: "",
      paymentFieldsAreReady: false,
      submittingPayment: false,
      fetchingCoupon: false,
    };

    this.updateEstimate();
  }

  /**
   * Update estimate for current plan and coupons if available.
   */
  updateEstimate = async (coupon: Chargebee.Coupon | null = null, partialState = {}) => {
    let couponIds: Array<string> = [];

    if (coupon) {
      couponIds.push(coupon.id);
    }

    try {
      const estimate = await chargebee.getEstimate(this.props.subscriptionPlanId, couponIds);
      this.setState({ ...partialState, estimate });
    } catch (error) {
      console.warn(error);
      this.setState({ ...partialState, errorMessage: "Internal Error. Could not load price information." });
    }
  }

  /**
   * Returns true when the form required fields are filled and false otherwise.
   */
  hasRequiredFields = () => {
    let errors: Errors = {
      ...this.state.errors
    }

    if (!this.state.firstName) errors["firstName"] = { message: MISSING_NAME_ERROR_MESSAGE };
    if (!this.state.lastName) errors["lastName"] = { message: MISSING_NAME_ERROR_MESSAGE };

    this.updateStateWithErrorMessage(errors);

    return this.state.firstName && this.state.lastName;
  }

  /**
   * Update state and error message.
   */
  updateStateWithErrorMessage = (errors: Errors, partialState = {}) => {
    let errorMessages = Object.values(errors).filter(message => Boolean(message));
    let errorMessage = errorMessages.pop();

    this.setState({
      ...partialState,
      errors,
      errorMessage: (errorMessage && errorMessage.message) || "",
    })
  }

  /**
   * Update the error message to the given value.
   */
  setErrorMessage = (errorMessage: string, partialState = {}) => {
    this.setState({ ...partialState, errorMessage: errorMessage });
  }

  /**
   * Handle card fields value change.
   */
  handleCardChange = (status, partialState = {}) => {
    let errors = {
      ...this.state.errors,
      [status.field]: status.error
    };

    this.updateStateWithErrorMessage(errors, partialState);
  }

  /**
   * Callback when payment fields are done rendering.
   */
  handleReady = () => {
    this.setState({ paymentFieldsAreReady: true });
  }

  /**
   * Handle form submit.
   */
  handleFormSubmit = async () => {
    if (
      this.state.submittingPayment
          || !this.hasRequiredFields()
          || this.state.fetchingCoupon
          || this.fetchCouponTimeout
    ) return;

    try {
      this.setState({ submittingPayment: true });

      let additionalData = {
        firstName: this.state.firstName,
        lastName: this.state.lastName,
      }

      let paymentIntent = await chargebee.createPaymentIntent(this.state.estimate!.invoice_estimate.amount_due);
      if (!paymentIntent) {
        this.setState({ errorMessage: "Could not process payment." });
        return;
      }

      paymentIntent = await this.cardReference.current.authorizeWith3ds(paymentIntent, additionalData);
      if (!paymentIntent || (paymentIntent.status !== Chargebee.PaymentIntentStatus.Authorized)) {
        this.setState({ errorMessage: "Could not authorize payment." });
        return;
      }

      const currentUser = authentication.getCurrentUser();

      // Create customer subscription with chargebee.
      let customer: Chargebee.Customer = {
        email: (currentUser && currentUser.email) || "",
        first_name: this.state.firstName,
        last_name: this.state.lastName,
      }

      let couponIds: Array<string> = [];
      if (this.state.coupon) couponIds.push(this.state.coupon.id);

      try {
        let chargebeeResult: Chargebee.CreateSubscriptionResult =
            await chargebee.createSubscriptionAndCustomer(this.props.subscriptionPlanId, customer, paymentIntent.id, couponIds);

        await database.updateCurrentUserChargebeeCustomerId(chargebeeResult.customer.id as string);
        this.props.loadSubscription(chargebeeResult.subscription.id);
      } catch {
        throw Error("Internal error.");
      }
    } catch (error) {
      this.setErrorMessage(error.message, { submittingPayment: false });
    }
  }

  /**
   * Get the discount price from the estimate.
   */
  getDiscountPrice = () => {
    const { estimate } = this.state;
    if (estimate && estimate.invoice_estimate.discounts && estimate.invoice_estimate.discounts.length > 0) {
      return estimate.invoice_estimate.discounts[0].amount / 100;
    }

    return null;
  }

  /**
   * Get formatted amount due from estimate.
   */
  getFormattedFinalPrice = () => {
    const { estimate } = this.state;
    if (estimate) {
      let finalPrice = estimate.invoice_estimate.amount_due / 100;
      return valueFormatter.format(finalPrice, { type: Format.Type.Currency, decimalPlaces: 2 });
    }
  }

  /**
   * Get formatted sub total price from the estimate.
   */
  getFormattedSubTotalPrice = () => {
    const { estimate } = this.state;
    if (estimate) {
      let subTotalPrice = estimate.invoice_estimate.sub_total / 100;
      return valueFormatter.format(subTotalPrice, { type: Format.Type.Currency, decimalPlaces: 2 });
    }
  }

  /**
   * Get coupon object if it exists.
   */
  fetchCoupon = async () => {
    let { couponId } = this.state;

    let coupon: Chargebee.Coupon | null = null;
    let partialState;
    if (couponId) {
      if (this.state.coupon && this.state.coupon.id === couponId) return;

      this.setState({ fetchingCoupon: true });
      let errorMessage = "";
      try {
        coupon = await chargebee.getCoupon(couponId);

        if (!coupon || coupon.status !== Chargebee.CouponStatus.Active) {
          errorMessage = COUPON_DOES_NOT_EXIST_ERROR_MESSAGE;
          // We set back to null to get a price estimate.
          // Chargebee throws error if coupon status is not valid.
          coupon = null;
        }

        if (coupon && !coupon.plan_ids.includes(this.props.subscriptionPlanId)) {
          errorMessage = COUPON_FOR_DIFFERENT_SUBSCRIPTION;
          coupon = null;
        }
      } catch (error) {
        errorMessage = COUPON_DOES_NOT_EXIST_ERROR_MESSAGE;
      }

      partialState = {
        fetchingCoupon: false,
        coupon,
        errorMessage,
      };
    } else {
      this.setState({ coupon: coupon, errorMessage: "" });
    }

    delete this.fetchCouponTimeout;
    this.updateEstimate(coupon, partialState);
  }

  /**
   * Set coupon ID on the state.
   */
  handleCouponIdChange = (event) => {
    let couponId = event.target.value && event.target.value.replace(" ", "").toUpperCase();
    this.setState({ couponId });

    if (this.fetchCouponTimeout) clearInterval(this.fetchCouponTimeout);
    this.fetchCouponTimeout = setTimeout(this.fetchCoupon, FETCH_COUPON_TIMEOUT);
  }

  /**
   * Message to display while coupon is being loaded and applied to price if it exists.
   */
  displayFetchingCouponMessage = () => (
    <div className="fetching-coupon-message">
      <BusySpinner classes="fetching-coupon-spinner" />
    </div>
  );

  /**
   * Render discount price if available.
   */
  renderDiscountRow = () => {
    let discountPrice = this.getDiscountPrice();
    if (!discountPrice) return null;

    return (
      <div className="price-row discount">
        <label>Discount</label>
        <p className="price">{valueFormatter.format(discountPrice, { type: Format.Type.Currency, decimalPlaces: 2 })}</p>
      </div>
    );
  }

  /**
   * Handle name change on first and last name inputs.
   */
  onNameChange = (event, fieldName) => {
    let value = event.target.value && event.target.value.trim();

    let status = {
      field: fieldName,
    }

    if (!value) {
      status["error"] = { message: MISSING_NAME_ERROR_MESSAGE };
    }

    this.handleCardChange(status, { [fieldName]: value });
  }

  /**
   * Check if all information is ready for component to render.
   */
  componentIsReady = () => {
    const { paymentFieldsAreReady, estimate } = this.state;
    return estimate && paymentFieldsAreReady;
  }

  render() {
    const { subscriptionPlanId } = this.props;
    const {
      submittingPayment,
      couponId,
      fetchingCoupon,
      errorMessage,
      firstName,
      lastName,
      errors,
    } = this.state;

    return (
      <div className="component--subscribe">
        {!this.componentIsReady() && <BusySpinner classes="initial-spinner" />}
        <div className="input-row">
          <input
            placeholder="First name"
            value={firstName}
            className={`field ${errors["firstName"] ? "error" : ""}`}
            onChange={(event) => this.onNameChange(event, "firstName")}
          />
          <input
            placeholder="Last name"
            value={lastName}
            className={`field ${errors["lastName"] ? "error" : ""}`}
            onChange={(event) => this.onNameChange(event, "lastName")}
          />
        </div>
        <CardComponent
          ref={this.cardReference}
          onReady={this.handleReady}
          classes={CLASSES}
          styles={STYLES}
        >
          <CardNumber
            placeholder="Card number"
            className="field empty"
            onChange={this.handleCardChange}
            onReady={this.handleReady}
          />
          <div className="input-row">
            <CardExpiry
              placeholder="MM / YY"
              className="field empty"
              onChange={this.handleCardChange}
            />
            <CardCVV
              placeholder="CVV"
              className="field empty"
              onChange={this.handleCardChange}
            />
          </div>
        </CardComponent>
        <div className="input-row">
          <label>Have a discount code?</label>
          <input
            className="field discount-input"
            value={couponId}
            onChange={this.handleCouponIdChange}
          />
        </div>

        <div className="error-container">
          {errorMessage}
        </div>

        <div className="divisor" />

        <div className="prices-container">
          {fetchingCoupon
              ? this.displayFetchingCouponMessage()
              : null
          }
          <div className="price-row">
            <label>{`Deepblocks ${PLAN_TEXT_MAP[subscriptionPlanId]} Plan`}</label>
            <p className="price">{this.getFormattedSubTotalPrice()}</p>
          </div>
          {this.renderDiscountRow()}
          <div className="price-row final-price">
            <label>Total</label>
            <p className="price">{this.getFormattedFinalPrice()}</p>
          </div>
        </div>
        <button
          type="submit"
          className="submit"
          onClick={this.handleFormSubmit}
        >
          {submittingPayment ? <BusySpinner classes="paying-spinner" /> : "Subscribe"}
        </button>
      </div>
    );
  }
}

export default connect(null, mapDispatchToProps)(Subscribe);
