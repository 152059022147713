/**
 * Add setbackD information to values and constraints.
 */
const addSetbackD = (development) => {
  development.values.setbackD = 10;
  development.constraints.minimums.setbackD = 0;
  development.constraints.maximums.setbackD = 50;
  development.constraints.increments.setbackD = 0.5;
}

export default addSetbackD;
