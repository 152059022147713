import { Config } from "../../../../../../../types/Config";
import { VariableId } from "../../../../../../../types/VariableId";
import Format from "../../../../../../../types/Format";
import Unit from "../../../../../../../types/Unit";

const config: Config = {
  [VariableId.MultifamilyYearOnePotentialGrossIncome]: {
    variableId: VariableId.MultifamilyYearOnePotentialGrossIncome,
    text: "Gross Income",
    formatOptions: { type: Format.Type.Currency }
  },

  [VariableId.MultifamilyAnnualVacancyExpenseForBackOfEnvelope]: {
    variableId: VariableId.MultifamilyAnnualVacancyExpenseForBackOfEnvelope,
    text: "Vacancy",
    formatOptions: {
      type: Format.Type.Currency,
      negate: true
    }
  },

  [VariableId.MultifamilyYearOneOperatingExpense]: {
    variableId: VariableId.MultifamilyYearOneOperatingExpense,
    text: "Operating Expenses",
    formatOptions: {
      type: Format.Type.Currency,
      negate: true
    }
  },

  [VariableId.MultifamilyAnnualNetOperatingIncomeForBackOfEnvelope]: {
    variableId: VariableId.MultifamilyAnnualNetOperatingIncomeForBackOfEnvelope,
    text: "Multifamily NOI",
    formatOptions: { type: Format.Type.Currency }
  },

  [VariableId.OfficeYearOnePotentialGrossIncome]: {
    variableId: VariableId.OfficeYearOnePotentialGrossIncome,
    text: "Gross Income",
    formatOptions: { type: Format.Type.Currency }
  },

  [VariableId.OfficeAnnualVacancyExpenseForBackOfEnvelope]: {
    variableId: VariableId.OfficeAnnualVacancyExpenseForBackOfEnvelope,
    text: "Vacancy",
    formatOptions: {
      type: Format.Type.Currency,
      negate: true
    }
  },

  [VariableId.OfficeYearOneOperatingExpense]: {
    variableId: VariableId.OfficeYearOneOperatingExpense,
    text: "Operating Expenses",
    formatOptions: {
      type: Format.Type.Currency,
      negate: true
    }
  },

  [VariableId.OfficeAnnualNetOperatingIncomeForBackOfEnvelope]: {
    variableId: VariableId.OfficeAnnualNetOperatingIncomeForBackOfEnvelope,
    text: "Office NOI",
    abbreviationExpandedText: "Net Operating Income",
    formatOptions: { type: Format.Type.Currency }
  },

  [VariableId.RetailYearOnePotentialGrossIncome]: {
    variableId: VariableId.RetailYearOnePotentialGrossIncome,
    text: "Gross Income",
    formatOptions: { type: Format.Type.Currency }
  },

  [VariableId.RetailAnnualVacancyExpenseForBackOfEnvelope]: {
    variableId: VariableId.RetailAnnualVacancyExpenseForBackOfEnvelope,
    text: "Vacancy",
    formatOptions: {
      type: Format.Type.Currency,
      negate: true
    }
  },

  [VariableId.RetailYearOneOperatingExpense]: {
    variableId: VariableId.RetailYearOneOperatingExpense,
    text: "Operating Expenses",
    formatOptions: {
      type: Format.Type.Currency,
      negate: true
    }
  },

  [VariableId.RetailAnnualNetOperatingIncomeForBackOfEnvelope]: {
    variableId: VariableId.RetailAnnualNetOperatingIncomeForBackOfEnvelope,
    text: "Retail NOI",
    abbreviationExpandedText: "Net Operating Income",
    formatOptions: { type: Format.Type.Currency }
  },

  [VariableId.IndustrialYearOnePotentialGrossIncome]: {
    variableId: VariableId.IndustrialYearOnePotentialGrossIncome,
    text: "Gross Income",
    formatOptions: { type: Format.Type.Currency }
  },

  [VariableId.IndustrialAnnualVacancyExpenseForBackOfEnvelope]: {
    variableId: VariableId.IndustrialAnnualVacancyExpenseForBackOfEnvelope,
    text: "Vacancy",
    formatOptions: {
      type: Format.Type.Currency,
      negate: true
    }
  },

  [VariableId.IndustrialYearOneOperatingExpense]: {
    variableId: VariableId.IndustrialYearOneOperatingExpense,
    text: "Operating Expenses",
    formatOptions: {
      type: Format.Type.Currency,
      negate: true
    }
  },

  [VariableId.IndustrialAnnualNetOperatingIncomeForBackOfEnvelope]: {
    variableId: VariableId.IndustrialAnnualNetOperatingIncomeForBackOfEnvelope,
    text: "Industrial NOI",
    abbreviationExpandedText: "Net Operating Income",
    formatOptions: { type: Format.Type.Currency }
  },

  [VariableId.RentalUsesTotalDevelopmentCostIncludingParking]: {
    variableId: VariableId.RentalUsesTotalDevelopmentCostIncludingParking,
    text: (buildingUse) => `${buildingUse} Project Cost`,
    formatOptions: { type: Format.Type.Currency }
  },

  [VariableId.RentalUsesGrossBuildableAreaIncludingParking]: {
    variableId: VariableId.RentalUsesGrossBuildableAreaIncludingParking,
    text: (buildingUse) => `${buildingUse} Buildable (Including Parking)`,
    formatOptions: { type: Format.Type.Number },
    [Unit.System.Imperial]: {
      unitTarget: Unit.Type.SquareFeet,
      formatOptions: { suffix: " SF" }
    },
    [Unit.System.Metric]: {
      unitTarget: Unit.Type.SquareMeters,
      formatOptions: { suffix: " m²" }
    }
  },

  [VariableId.RentalUsesDevelopmentCostPerAreaIncludingParking]: {
    variableId: VariableId.RentalUsesDevelopmentCostPerAreaIncludingParking,
    formatOptions: { type: Format.Type.Currency },
    [Unit.System.Imperial]: {
      text: (buildingUse) => `${buildingUse} All-In Cost PSF`,
      unitTarget: Unit.Type.SquareFeet,
      unitIsInverse: true,
      formatOptions: { suffix: " / SF" }
    },
    [Unit.System.Metric]: {
      text: (buildingUse) => `${buildingUse} All-In Cost PSM`,
      unitTarget: Unit.Type.SquareMeters,
      unitIsInverse: true,
      formatOptions: { suffix: " / m²" }
    }
  },
};

export default config;
