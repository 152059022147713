import React from "react";
import { connect } from "react-redux";
import { newDevelopmentActions, newDevelopmentSelectors } from "../../../../../state/newDevelopment";
import { ParcelTool } from "../../../../../types/ParcelTool";
import analytics from "../../../../../utils/analytics";

const mapStateToProps = (state) => {
  return {
    parcelTool: newDevelopmentSelectors.getParcelTool(state),
    mapIsReady: newDevelopmentSelectors.getMapIsReady(state),
  }
}

const mapDispatchToProps = {
  setParcelTool: newDevelopmentActions.setParcelTool,
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

type Props = StateProps & DispatchProps;

class ParcelTools extends React.PureComponent<Props, {}> {
  /**
   * Set parcel tool.
   */
  setParcelTool = (parcelTool) => {
    return () => {
      this.props.setParcelTool(parcelTool);
      analytics.trackSelectParcelTool(parcelTool);
    }
  }

  /**
   * Render individual tool icons.
   */
  renderToolIcon = (parcelTool: ParcelTool, description: string) => {
    let className = `control-icon ${parcelTool}`
      + ` ${this.props.parcelTool === parcelTool ? "active" : ""}`
      + ` ${this.props.mapIsReady ? "" : "disabled"}`;

    return <div title={description} className={className} onClick={this.setParcelTool(parcelTool)} />;
  }

  render() {
    return (
      <div className="component--parcel-tools">
        {this.renderToolIcon(ParcelTool.SelectParcel, "Select Parcel")}
        {this.renderToolIcon(ParcelTool.CombineParcels, "Select Multiple Parcels")}
        {this.renderToolIcon(ParcelTool.DrawParcel, "Draw Parcels")}
        {this.renderToolIcon(ParcelTool.EditParcel, "Edit Drawn Parcels")}
        {this.renderToolIcon(ParcelTool.DeleteParcel, "Delete Drawn Parcels")}

      </div>
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ParcelTools);
