import React from "react";
import { connect } from "react-redux";
import Toolbar from "../../../sharedComponents/Toolbar";
import ToolbarSettings from "../../../sharedComponents/ToolbarSettings";
import UnitSystemInput from "../../../sharedComponents/UnitSystemInput";
import { newDevelopmentSelectors, newDevelopmentActions } from "../../../../state/newDevelopment";
import RedirectDropdownButton from "../../../sharedComponents/RedirectDropdownButton";
import { Path } from "../../../../types/Path";
import AuthenticationButtons from "../../../sharedComponents/AuthenticationButtons";
import authentication from "../../../../utils/authentication";

const mapStateToProps = (state) => {
  return {
    newDevelopmentUnitSystem: newDevelopmentSelectors.getUnitSystem(state),
  }
}

const mapDispatchToProps = {
  setNewDevelopmentUnitSystem: newDevelopmentActions.setUnitSystem,
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;
type Props = StateProps & DispatchProps;

class NewProjectToolbar extends React.PureComponent<Props, {}> {
  render() {
    const { setNewDevelopmentUnitSystem, newDevelopmentUnitSystem } = this.props;
    const userIsAuthenticated = authentication.isUserAuthenticated();

    return (
      <div className="component--new-project-toolbar">
        <Toolbar>
          <UnitSystemInput setUnitSystem={setNewDevelopmentUnitSystem} unitSystem={newDevelopmentUnitSystem} />
          <div className="buttons-container">
            <AuthenticationButtons />
          </div>
          <ToolbarSettings>
            {userIsAuthenticated && <RedirectDropdownButton path={Path.Dashboard} text="Dashboard" />}
          </ToolbarSettings>
        </Toolbar>
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewProjectToolbar);
