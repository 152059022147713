import { Config } from "../../../../../types/Config";
import { VariableId } from "../../../../../types/VariableId";
import { BuildingUse } from "../../../../../types/BuildingUse";
import Format from "../../../../../types/Format";
import Unit from "../../../../../types/Unit";

const config: Config = {
  [VariableId.BuildingHeight]: {
    variableId: VariableId.BuildingHeight,
    text: "Building Height",
    formatOptions: {
      type: Format.Type.Number,
      decimalPlaces: 1
    },
    [Unit.System.Imperial]: {
      unitTarget: Unit.Type.Feet,
      formatOptions: { suffix: " feet" }
    },
    [Unit.System.Metric]: {
      unitTarget: Unit.Type.Meters,
      formatOptions: { suffix: " meters" }
    }
  },

  [VariableId.HeightOfGroundFloor]: {
    variableId: VariableId.HeightOfGroundFloor,
    buildingUse: BuildingUse.Project,
    text: "Ground Floor Height",
    formatOptions: {
      type: Format.Type.Number,
      decimalPlaces: 1
    },
    [Unit.System.Imperial]: {
      unitTarget: Unit.Type.Feet,
      formatOptions: { suffix: " ft" }
    },
    [Unit.System.Metric]: {
      unitTarget: Unit.Type.Meters,
      formatOptions: { suffix: " m" }
    }
  },

  [VariableId.HeightOfTypicalFloor]: {
    variableId: VariableId.HeightOfTypicalFloor,
    buildingUse: BuildingUse.Project,
    text: "Typical Floor Height",
    formatOptions: {
      type: Format.Type.Number,
      decimalPlaces: 1
    },
    [Unit.System.Imperial]: {
      unitTarget: Unit.Type.Feet,
      formatOptions: { suffix: " ft" }
    },
    [Unit.System.Metric]: {
      unitTarget: Unit.Type.Meters,
      formatOptions: { suffix: " m" }
    }
  },

  [VariableId.SiteCoveragePercentage]: {
    variableId: VariableId.SiteCoveragePercentage,
    text: "Building Setbacks",
    formatOptions: {
      type: Format.Type.Percentage,
      suffix: " Lot Coverage"
    }
  },

  [VariableId.SetbackA]: {
    variableId: VariableId.SetbackA,
    buildingUse: BuildingUse.Project,
    text: "Setback A - Primary",
    formatOptions: {
      type: Format.Type.Number,
      decimalPlaces: 1
    },
    [Unit.System.Imperial]: {
      unitTarget: Unit.Type.Feet,
      formatOptions: { suffix: " ft" }
    },
    [Unit.System.Metric]: {
      unitTarget: Unit.Type.Meters,
      formatOptions: { suffix: " m" }
    }
  },

  [VariableId.SetbackB]: {
    variableId: VariableId.SetbackB,
    buildingUse: BuildingUse.Project,
    text: "Setback B - Secondary",
    formatOptions: {
      type: Format.Type.Number,
      decimalPlaces: 1
    },
    [Unit.System.Imperial]: {
      unitTarget: Unit.Type.Feet,
      formatOptions: { suffix: " ft" }
    },
    [Unit.System.Metric]: {
      unitTarget: Unit.Type.Meters,
      formatOptions: { suffix: " m" }
    }
  },

  [VariableId.SetbackC]: {
    variableId: VariableId.SetbackC,
    buildingUse: BuildingUse.Project,
    text: "Setback C - Side",
    formatOptions: {
      type: Format.Type.Number,
      decimalPlaces: 1
    },
    [Unit.System.Imperial]: {
      unitTarget: Unit.Type.Feet,
      formatOptions: { suffix: " ft" }
    },
    [Unit.System.Metric]: {
      unitTarget: Unit.Type.Meters,
      formatOptions: { suffix: " m" }
    }
  },

  [VariableId.SetbackD]: {
    variableId: VariableId.SetbackD,
    buildingUse: BuildingUse.Project,
    text: "Setback D - Rear",
    formatOptions: {
      type: Format.Type.Number,
      decimalPlaces: 1
    },
    [Unit.System.Imperial]: {
      unitTarget: Unit.Type.Feet,
      formatOptions: { suffix: " ft" }
    },
    [Unit.System.Metric]: {
      unitTarget: Unit.Type.Meters,
      formatOptions: { suffix: " m" }
    }
  }
};

export default config;
