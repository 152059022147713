import developmentAccessors from "../../../../state/development/utils/developmentAccessors";
import { Development } from  "../../../../types/Development/Development";
import { VariableId } from  "../../../../types/VariableId";
import generateLinearRegressionPreset from "./generatorForLinearRegression";

/**
 * Generate the retail buildables presets.
 */
const generatePresets = (development: Development, targetArea: number): object => {
  const lossFactor = developmentAccessors.getInputValue(development, VariableId.RetailLossFactor) as number;
  const netLeasableArea = targetArea * (1 - lossFactor);

  const previousMaximum = developmentAccessors.getInputMaximum(development,  VariableId.RetailNetLeasableAreaToggleable);

  return {
    [VariableId.RetailNetLeasableAreaToggleable]: {
      "minimum":  0,
      "maximum":  2 * netLeasableArea || previousMaximum,
      "value":    netLeasableArea
    },
    [VariableId.RetailYearOneRentPerArea]: generateLinearRegressionPreset(development, VariableId.RetailYearOneRentPerArea),
    [VariableId.RetailHardCostPerArea]: generateLinearRegressionPreset(development, VariableId.RetailHardCostPerArea),
  };
}

export default generatePresets;
