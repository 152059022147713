import { NewDevelopment } from "./reducers";

/**
 * Select the whole newDevelopment.
 */
const getNewDevelopment = (state): NewDevelopment => {
  return state.newDevelopment;
}

/**
 * Select the suggestion list.
 */
const getSuggestedFeatures = (state) => {
  let newDevelopment = getNewDevelopment(state);
  return newDevelopment.suggestedFeatures;
}

/**
 * Select the proximityCenter.
 */
const getGeocoderProximityCenter = (state) => {
  let newDevelopment = getNewDevelopment(state);
  return newDevelopment.geocoderProximityCenter;
}

/**
 * Select the input value.
 */
const getSearchAddress = (state) => {
  let newDevelopment = getNewDevelopment(state);
  return newDevelopment.searchAddress;
}

/**
 * Select the selected feature.
 */
const getSelectedFeature = (state) => {
  let newDevelopment = getNewDevelopment(state);
  return newDevelopment.selectedFeature;
}

/**
 * Select getIsTyping.
 */
const getUserIsTyping = (state) => {
  let newDevelopment = getNewDevelopment(state);
  return newDevelopment.userIsTyping;
}

/**
 * Select active suggestion index.
 */
const getSuggestedFeaturesSelectionIndex = (state) => {
  let newDevelopment = getNewDevelopment(state);
  return newDevelopment.suggestedFeaturesSelectionIndex;
}

/**
 * Select newDevelopmentIsQuerying.
 */
const getGeocoderIsQuerying = (state) => {
  let newDevelopment = getNewDevelopment(state);
  return newDevelopment.geocoderIsQuerying;
}

/**
 * Select the hovered feature.
 */
const getHoveredFeature = (state) => {
  let newDevelopment = getNewDevelopment(state);
  return newDevelopment.hoveredFeature;
}

/**
 * Select pinPosition.
 */
const getPinPosition = (state) => {
  let newDevelopment = getNewDevelopment(state);
  return newDevelopment.pinPosition;
}

/**
 * Get selectedFeatureMembers value.
 */
const getSelectedFeatureMembers = (state) => {
  let newDevelopment = getNewDevelopment(state);
  return newDevelopment.selectedFeatureMembers;
}

/**
 * Get the polygonArea value.
 */
const getPolygonArea = (state) => {
  let newDevelopment = getNewDevelopment(state);
  return newDevelopment.polygonArea;
}

/**
 * Get the polygonPerimeter value.
 */
const getPolygonPerimeter = (state) => {
  let newDevelopment = getNewDevelopment(state);
  return newDevelopment.polygonPerimeter;
}

/**
 * Get the polygonIsBeingChanged value.
 */
const getPolygonIsBeingChanged = (state) => {
  let newDevelopment = getNewDevelopment(state);
  return newDevelopment.polygonIsBeingChanged;
}

/*
 * Get the parcelTool value.
 */
const getParcelTool = (state) => {
  let newDevelopment = getNewDevelopment(state);
  return newDevelopment.parcelTool;
}

/**
 * Get the parcelTooFromToolbar value.
 */
const getParcelToolFromToolbar = (state) => {
  let newDevelopment = getNewDevelopment(state);
  return newDevelopment.parcelToolFromToolbar;
}

/**
 * Get the mapIsReady value.
 */
const getMapIsReady = (state) => {
  let newDevelopment = getNewDevelopment(state);
  return newDevelopment.mapIsReady;
}

/**
 * Get the drawnParcels array.
 */
const getDrawnParcels = (state) => {
  let newDevelopment = getNewDevelopment(state);
  return newDevelopment.drawnParcels;
}

/**
 * Get unit system.
 */
const getUnitSystem = (state) => {
  let newDevelopment = getNewDevelopment(state);
  return newDevelopment.unitSystem;
}

/**
 * Get the smartSearchIsOpen flag.
 */
const getSmartSearchIsOpen = (state) => {
  let newDevelopment = getNewDevelopment(state);
  return newDevelopment.smartSearchIsOpen;
}

/**
 * Get the displayPin flag.
 */
const getDisplayPin = (state) => {
  let newDevelopment = getNewDevelopment(state);
  return newDevelopment.displayPin;
}

/**
 * Get the drawParcelInformationPanelIsOpen flag.
 */
const getDrawParcelInformationPanelIsOpen = (state) => {
  let newDevelopment = getNewDevelopment(state);
  return newDevelopment.drawParcelInformationPanelIsOpen;
}

/**
 * Get the parcelsInViewport array.
 */
const getParcelsInViewport = (state) => {
  let newDevelopment = getNewDevelopment(state);
  return newDevelopment.parcelsInViewport;
}

/**
 * Get the smartSearchResult array.
 */
const getSmartSearchResult = (state) => {
  let newDevelopment = getNewDevelopment(state);
  return newDevelopment.smartSearchResult;
}

/**
 * Get the filters array.
 */
const getFilters = (state) => {
  let newDevelopment = getNewDevelopment(state);
  return newDevelopment.filters;
}

/**
 * Get development initial values.
 */
const getInitialValues = (state) => {
  let newDevelopment = getNewDevelopment(state);
  return newDevelopment.initialValues;
}

/**
 * Get the zoningDataInViewport flag.
 */
const getZoningDataInViewport = (state) => {
  let newDevelopment = getNewDevelopment(state);
  return newDevelopment.zoningDataInViewport;
}

/**
 * Get the parcelDataInViewport flag.
 */
const getParcelDataInViewport = (state) => {
  let newDevelopment = getNewDevelopment(state);
  return newDevelopment.parcelDataInViewport;
}

/**
 * Get the place type of the suggested feature.
 */
const getSuggestedFeaturePlaceType = (state) => {
  let newDevelopment = getNewDevelopment(state);
  return newDevelopment.suggestedFeaturePlaceType;
}

export default {
  getUserIsTyping,
  getSuggestedFeatures,
  getSearchAddress,
  getGeocoderProximityCenter,
  getSelectedFeature,
  getSuggestedFeaturesSelectionIndex,
  getGeocoderIsQuerying,
  getHoveredFeature,
  getPinPosition,
  getPolygonArea,
  getPolygonPerimeter,
  getPolygonIsBeingChanged,
  getParcelToolFromToolbar,
  getParcelTool,
  getMapIsReady,
  getDrawnParcels,
  getUnitSystem,
  getSmartSearchIsOpen,
  getDisplayPin,
  getDrawParcelInformationPanelIsOpen,
  getParcelsInViewport,
  getSmartSearchResult,
  getFilters,
  getInitialValues,
  getZoningDataInViewport,
  getParcelDataInViewport,
  getSuggestedFeaturePlaceType,
  getSelectedFeatureMembers,
}
