import React from "react";
import developmentAccessors from "../../../../../../../../state/development/utils/developmentAccessors";
import zoningIcon from "../../../../../../../../assets/images/pdf-icon-zoning.png";
import Format from "../../../../../../../../types/Format";
import Unit from "../../../../../../../../types/Unit";
import parcelAccessors from "../../../../../../../../utils/parcel/parcelAccessors";
import unitConversions from "../../../../../../../../utils/unitConversions";
import valueFormatter from "../../../../../../../../utils/valueFormatter";
import PdfContext from "../../../../PdfContext";
import Row from "../Row";
import Section from "../Section";
import authentication from "../../../../../../../../utils/authentication";

/**
 * Render Row if value is not null.
 */
const ConditionalRow = (value, Row) => {
  if (value === null || value === undefined || (Array.isArray(value) && value.length === 0)) return null;

  return (
    <Row.type value={value} {...Row.props} />
  );
}

const Zoning = (props) => (
  <PdfContext.Consumer>
    {({ development }) => {
      const userIsAuthenticated = authentication.isUserAuthenticated();

      const { isNumeric } = valueFormatter;
      const parcelFeature = developmentAccessors.getParcel(development);
      const zoneId = parcelAccessors.getZoneId(parcelFeature);
      if (zoneId.length === 0) return null;

      const buildableArea = unitConversions.convert(parcelAccessors.getAllowedBuildableArea(parcelFeature), Unit.Type.SquareMeters, Unit.Type.SquareFeet);
      const floorAreaRatio = parcelAccessors.getFloorAreaRatio(parcelFeature);
      const numberOfFloors = parcelAccessors.getNumberOfFloors(parcelFeature);
      const numberOfFloorsMap = (value) => ({ value, formatOptions: { suffix: " stories", type: isNumeric(value) ? Format.Type.Number : Format.Type.PlainText, hidden: !userIsAuthenticated } });
      const livingDensityRatio = parcelAccessors.getLivingDensityRatio(parcelFeature);

      let numberOfUnitsAllowed = parcelAccessors.getNumberOfUnitsAllowed(parcelFeature);
      // Add number values, and keep the strings in the array.
      let totalUnitsAllowedNumber = 0;
      numberOfUnitsAllowed = numberOfUnitsAllowed.filter((value) => {
        if (isNumeric(value)) {
          totalUnitsAllowedNumber += Number(value);
          return false;
        }
        return true;
      });

      if (totalUnitsAllowedNumber) numberOfUnitsAllowed.unshift(totalUnitsAllowedNumber);
      const numberOfUnitsAllowedMap = (value) => ({ value, formatOptions: { suffix: " units", type: isNumeric(value) ? Format.Type.Number : Format.Type.PlainText, hidden: !userIsAuthenticated } });
      const hiddenMap = (value) => ({ value, formatOptions: { hidden: !userIsAuthenticated } });

      return (
        <Section title={"ZONING SUMMARY"} titleIcon={zoningIcon}>
          {ConditionalRow(
            zoneId,
            <Row text="Zoning Type" formattingMap={hiddenMap} />,
          )}
          {ConditionalRow(
            numberOfUnitsAllowed,
            <Row text="Max. Number of Units" formattingMap={numberOfUnitsAllowedMap} />,
          )}
          {ConditionalRow(
            buildableArea,
            <Row text="Max. Gross Buildable" formatOptions={{ type: isNumeric(buildableArea) ? Format.Type.Number : Format.Type.PlainText, suffix: " SF", hidden: !userIsAuthenticated }} />,
          )}
          {ConditionalRow(
            numberOfFloors,
            <Row text="Max. Number of Floors" formattingMap={numberOfFloorsMap} />,
          )}
          {ConditionalRow(
            floorAreaRatio,
            <Row text="Max. FAR" formattingMap={hiddenMap} />,
          )}
          {ConditionalRow(
            livingDensityRatio,
            <Row text="Residential Density" formattingMap={hiddenMap} />,
          )}
        </Section>
      );
    }}
  </PdfContext.Consumer>
);

export default Zoning;
