 /**
 * @fileoverview Cloud Firestore cannot store nested arrays in the saved JSON.
 * Previously, this was overcome by transforming nested arrays into integer-
 * indexed objects for storage. This migration transforms those objects into a
 * more robust format. In the new format, all the arrays that are elements of
 * another array get wrapped in an object with only one element, indexed by a
 * special flag value.
 *
 * Example:
 *
 * "Native" format with nested arrays:
 *
 *
 * ```
 * [
 *   [ value1, [ value2, value3 ] ],
 *   [ value4 ],
 *   value5
 * ]
 * ```
 *
 * Previous storage format (input of this migration):
 *
 * ```
 * {
 *   0: {
 *     0: value1,
 *     1: {
 *       0: value2,
 *       1: value3
 *     }
 *   },
 *   1: {
 *     0: value4
 *   }
 *   2: value5
 * }
 * ```
 *
 * New storage format (output of this migration):
 *
 * ```
 * [
 *   { NESTED_ARRAY: [ value1, { NESTED_ARRAY: [ value2, value3 ] } ] },
 *   { NESTED_ARRAY: [ value4 ] },
 *   value5
 * ]
 * ```
 */

/**
 * Transform nested arrays from integer indexed objects into our own new format that is
 * acceptable by Cloud Firestore.
 */
const changeNestedArrayDatabaseFormat = (development) => {
  // Migrate parcel data.
  development.parcel.feature = fromOldPseudoGeoJsonFormat(development.parcel.feature);
  development.parcel.feature.geometry.coordinates
    = toNewDatabaseFormat(development.parcel.feature.geometry.coordinates);

  // Migrate setbacks.
  development.buildingModel.setbackSchedule.forEach((setback) => {
    let setbacks = fromOldSetbackFormat(setback.setbacks);
    setback.setbacks = toNewDatabaseFormat(setbacks);
  });
}

/**
 * Transform an object from "pseudo GeoJSON" (coordinates are nested integer-indexed objects instead
 * of nested arrays) to an actual GeoJSON object.
 */
const fromOldPseudoGeoJsonFormat = (pseudoGeoJson) => {
  let coordinates = getCoordinates(pseudoGeoJson);
  coordinates = pseudoCoordinatesToCoordinates(coordinates);
  return setCoordinates(pseudoGeoJson, coordinates);
}

/**
 * Get coordinates from a pseudo GeoJSON object.
 */
const getCoordinates = (pseudoGeoJson) => {
  return pseudoGeoJson.geometry.coordinates;
}

/**
 * Convert from pseudo coordinates (i.e. nested integer indexed objects) to coordinates (i.e. nested arrays).
 */
const pseudoCoordinatesToCoordinates = (input) => {
  // Base Case:
  if (typeof input === "number") return input;

  return Object.values(input).map((element) => pseudoCoordinatesToCoordinates(element));
}

/**
 * Set coordinates to a pseudo GeoJSON object.
 */
const setCoordinates = (pseudoGeoJson, coordinates) => {
  return {
    ...pseudoGeoJson,
    geometry: {
      ...pseudoGeoJson.geometry,
      coordinates
    }
  }
};

/**
 * Convert setbacks from old database format (i.e. nested integer indexed objects) to application format (i.e. nested arrays).
 */
const fromOldSetbackFormat = (setbacks) => {
  // Base Case:
  if (typeof setbacks === "string") return setbacks;

  return Object.values(setbacks).map((element) => fromOldSetbackFormat(element));
}

/**
 * Transform nested arrays into an object that can be saved on Cloud Firestore.
 */
const toNewDatabaseFormat = (root) => {
  // WARNING: This value is a "magic string" and must not be changed for any reason.
  // Modifying this value will cause this migration to generate corrupted data that
  // the application cannot consume.
  const NESTED_ARRAY_FLAG = "NESTED_ARRAY_QwI6xefsky";

  if (typeof root !== "object" || root === null) return root;

  if (Array.isArray(root)) {
    let newArray: any[] = [];
    root.forEach(
      (element) => {
        if (Array.isArray(element)) {
          let wrapperObject = {};
          wrapperObject[NESTED_ARRAY_FLAG] = toNewDatabaseFormat(element);
          newArray.push(wrapperObject);
        } else {
          newArray.push(toNewDatabaseFormat(element));
        }
      }
    );

    return newArray;
  } else {
    let newObject = {};
    Object.keys(root).forEach(
      (key) => {
        newObject[key] = toNewDatabaseFormat(root[key]);
      }
    );

    return newObject;
  }
}

export default changeNestedArrayDatabaseFormat;
