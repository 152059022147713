import React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { withRouter } from "react-router-dom";
import { subscriptionSelectors } from "../../../../state/subscription";
import { Chargebee } from "../../../../types/Service/Chargebee";
import SubscriptionRows from "./SubscriptionRows";
import SubscribeButton from "./SubscribeButton";
import companyInfo from "../../../../utils/companyInfo";

const mapStateToProps = (state) => {
  return {
    subscriptionStatus: subscriptionSelectors.getStatus(state),
    userPlanId: subscriptionSelectors.getPlanId(state),
    planPrices: subscriptionSelectors.getPlanPrices(state),
  }
}

interface OwnProps {
  onSelect(subscriptionPlanId: Chargebee.PlanId);
}

type StateProps = ReturnType<typeof mapStateToProps>;
type Props = StateProps & RouteComponentProps & OwnProps;

interface State {
  planPeriodIsYearly: boolean
}

const YEARLY_SELECTED_COLUMN = {
  [Chargebee.PlanId.StandardYearly]: 0,
  [Chargebee.PlanId.AdvancedYearly]: 1,
  [Chargebee.PlanId.ProYearly]: 2,
}

const MONTHLY_SELECTED_COLUMN = {
  [Chargebee.PlanId.StandardMonthly]: 0,
  [Chargebee.PlanId.AdvancedMonthly]: 1,
  [Chargebee.PlanId.ProMonthly]: 2,
}

const HEADER = ["Standard", "Advanced", "Pro"];
const BUTTON_TEXT = ["select standard", "select advanced", "select pro"];
const LINK_URL = [companyInfo.PLAN_STANDARD_URL, companyInfo.PLAN_ADVANCED_URL, companyInfo.PLAN_PRO_URL];

class SubscriptionRequiredContent extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      planPeriodIsYearly: false,
    }
  }

  /**
   * Get difference in percentages between monthly and yearly plans.
   */
  getPercentageDifference = (monthlyPlanId: Chargebee.PlanId, yearlyPlanId: Chargebee.PlanId) => {
    let monthlyPrice = Number(this.props.planPrices[monthlyPlanId]) * 12;
    let yearlyPrice = Number(this.props.planPrices[yearlyPlanId]);

    return Math.floor(((monthlyPrice - yearlyPrice) / monthlyPrice) * 100);
  }

  /**
   * Calculate the maximum amount of savings for all plans.
   */
  getMaximumSavingsPercentage = () => {
    let maxSavings = Math.max(
      this.getPercentageDifference(Chargebee.PlanId.StandardMonthly, Chargebee.PlanId.StandardYearly),
      this.getPercentageDifference(Chargebee.PlanId.AdvancedMonthly, Chargebee.PlanId.AdvancedYearly),
      this.getPercentageDifference(Chargebee.PlanId.ProMonthly, Chargebee.PlanId.ProYearly),
    );
    return `You can save ${maxSavings}% by selecting`;
  }

  /**
   * Get the plan Ids.
   */
  getPlanIds = (planPeriodIsYearly) => {
    return planPeriodIsYearly
        ? [Chargebee.PlanId.StandardYearly, Chargebee.PlanId.AdvancedYearly, Chargebee.PlanId.ProYearly]
        : [Chargebee.PlanId.StandardMonthly, Chargebee.PlanId.AdvancedMonthly, Chargebee.PlanId.ProMonthly];
  }

  /**
   * Get the currently selected column.
   */
  getSelectedColumn = () => {
    const { userPlanId, subscriptionStatus } = this.props;
    if (!userPlanId || subscriptionStatus !== Chargebee.SubscriptionStatus.Active) return null;

    return this.state.planPeriodIsYearly
        ? YEARLY_SELECTED_COLUMN[userPlanId]
        : MONTHLY_SELECTED_COLUMN[userPlanId];
  }

  /**
   * Get the amount of cents in a given price.
   */
  getCents = (price) => {
    return Math.round((price - Math.floor(price)) * 100);
  }

  /**
   * Render the plan name.
   */
  renderPlanName = (column) => {
    const selectedColumn = this.getSelectedColumn();

    return (
      <div className={`plan-name-wrapper ${selectedColumn === column ? "selected-plan" : ""}`}>
        <div className="plan-name">
          {HEADER[column]}
        </div>
      </div>
    );
  }

  /**
   * Render the plan price.
   */
  renderPrice = (column) => {
    const { planPeriodIsYearly } = this.state;
    const { planPrices } = this.props;
    const selectedColumn = this.getSelectedColumn();
    const planId = this.getPlanIds(planPeriodIsYearly)[column];
    const price = planPrices[planId];

    if (!price) return null;

    return (
      <div className={`price-wrapper ${selectedColumn === column ? "selected-plan" : ""}`}>
        <div className="price">
          {planPeriodIsYearly
              ? `$${Math.floor(price)}.`
              : `$${Math.floor(price)}.`
          }
          <div className="cents">
            {planPeriodIsYearly
                ? `${this.getCents(price)}`
                : `${this.getCents(price)}`
            }
          </div>
          <div className="suffix">
            {planPeriodIsYearly ? "/yr" : "/mo"}
          </div>
        </div>
      </div>
    );
  }

  /**
   * Render the monthly price of the yearly plans.
   */
  renderMonthlyPrice = (column) => {
    const { planPeriodIsYearly } = this.state;
    const { planPrices } = this.props;
    const selectedColumn = this.getSelectedColumn();
    const planId = this.getPlanIds(planPeriodIsYearly)[column];
    const price = planPrices[planId];

    if (!planPeriodIsYearly) return null;

    return (
      <div className={`monthly-price-wrapper ${selectedColumn === column ? "selected-plan" : ""}`}>
        <div className="monthly-price">
          {price && `$${price / 12}/mo`}
        </div>
      </div>
    );
  }

  /**
   * Render the subscribe button.
   */
  renderButton = (column) => {
    const { planPeriodIsYearly } = this.state;
    const selectedColumn = this.getSelectedColumn();
    const planIds = this.getPlanIds(planPeriodIsYearly);

    return (
      <div className="button-wrapper">
        {selectedColumn === column
            ? <div className="selected-plan">You are here</div>
            : <SubscribeButton paymentPlanId={planIds[column]} text={BUTTON_TEXT[column]} onSelect={this.props.onSelect} />}
      </div>
    );
  }

  /**
   * Render the learn more link.
   */
  renderLink = (column) => {
    const selectedColumn = this.getSelectedColumn();

    return (
      <div className={`link-wrapper ${selectedColumn === column ? "selected-plan" : ""}`}>
        <a href={LINK_URL[column]} target="_blank" rel="noopener noreferrer">Learn More</a>
      </div>
    );
  }

  render() {
    const { planPeriodIsYearly } = this.state;
    const selectedColumn = this.getSelectedColumn();

    return (
      <div className="component--subscription-required-content">
        <div className="content">
          <div className="content-header">
            <div className="plan-period">
              <div className="toggle-wrapper">
                <div
                  className={`toggle-button ${planPeriodIsYearly ? "" : "selected"}`}
                  onClick={() => this.setState({ planPeriodIsYearly: false })}
                >
                  Monthly
                </div>
                <div
                  className={`toggle-button ${planPeriodIsYearly ? "selected" : ""}`}
                  onClick={() => this.setState({ planPeriodIsYearly: true })}
                >
                  Yearly
                </div>
              </div>
              <div className="discount-message">
                {this.getMaximumSavingsPercentage()} <br /> the annual plan
              </div>
            </div>
            <div className="information">
              <div className="plan-name-container">
                {this.renderPlanName(0)}
                {this.renderPlanName(1)}
                {this.renderPlanName(2)}
              </div>

              <div className="price-container">
                {this.renderPrice(0)}
                {this.renderPrice(1)}
                {this.renderPrice(2)}
              </div>

              <div className="monthly-price-container">
                {this.renderMonthlyPrice(0)}
                {this.renderMonthlyPrice(1)}
                {this.renderMonthlyPrice(2)}
              </div>

              <div className="buttons-container">
                {this.renderButton(0)}
                {this.renderButton(1)}
                {this.renderButton(2)}
              </div>

              <div className="link-container">
                {this.renderLink(0)}
                {this.renderLink(1)}
                {this.renderLink(2)}
              </div>
            </div>
          </div>
          <SubscriptionRows selectedColumn={selectedColumn} />
        </div>
      </div>
    );
  }
}

export default withRouter(
  connect(mapStateToProps)(SubscriptionRequiredContent)
) as any;
