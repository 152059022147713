import { Config } from "../../../../types/Config";
import { VariableId } from "../../../../types/VariableId";
import Format from "../../../../types/Format";
import Unit from "../../../../types/Unit";

const config: Config = {
  [VariableId.ParcelArea]: {
    variableId: VariableId.ParcelArea,
    text: "Lot Size",
    formatOptions: { type: Format.Type.Number },
    [Unit.System.Imperial]: {
      unitTarget: Unit.Type.SquareFeet,
      formatOptions: { suffix: " SF" },
    },
    [Unit.System.Metric]: {
      unitTarget: Unit.Type.SquareMeters,
      formatOptions: { suffix: " m²" },
    },
  },
  [VariableId.ExistingStructureArea]: {
    variableId: VariableId.ExistingStructureArea,
    text: "Existing Building Area",
    formatOptions: { type: Format.Type.Number },
    [Unit.System.Imperial]: {
      unitTarget: Unit.Type.SquareFeet,
      formatOptions: { suffix: " SF" },
    },
    [Unit.System.Metric]: {
      unitTarget: Unit.Type.SquareMeters,
      formatOptions: { suffix: " m²" },
    },
  },
}

export default config;
