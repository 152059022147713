import React from "react";
import styled from "@react-pdf/styled-components";
import propertyInfoIcon from "../../../../../../../assets/images/pdf-icon-project-summary.png";
import stackingIcon from "../../../../../../../assets/images/pdf-icon-stacking.png";
import ConfigurationHandler from "../../../../../ConfigurationHandler";
import PdfContext from "../../../PdfContext";
import Logo from "../../sharedComponents/Logo";
import Row from "./Row";
import config from "./config";
import Section from "./Section";
import Disclaimer from "./Disclaimer";
import Zoning from "./Zoning";

const Container = styled.View`
  flex-grow: 1;
  padding: 0 38px 100px;
  max-height: 605px;
`;

const Summary = (props) => (
  <PdfContext.Consumer>
    {({ unitSystem }) => {
      return (
        <Container>
          <Section title={"PROPERTY INFO"} titleIcon={propertyInfoIcon}>
            <ConfigurationHandler config={config.parcelArea} component={Row} unitSystem={unitSystem} />
            <ConfigurationHandler config={config.existingStructureArea} component={Row} unitSystem={unitSystem} />
          </Section>

          <Zoning />

          <Section
            title={"STACKING PLAN"}
            titleIcon={stackingIcon}
            requiresUsages
          >
            <ConfigurationHandler config={config.condoTotalUnitQuantity} component={Row} unitSystem={unitSystem} />
            <ConfigurationHandler config={config.multifamilyTotalUnitQuantity} component={Row} unitSystem={unitSystem} />
            <ConfigurationHandler config={config.hotelRoomQuantity} component={Row} unitSystem={unitSystem} />
            <ConfigurationHandler config={config.officeGrossLeasableArea} component={Row} unitSystem={unitSystem} />
            <ConfigurationHandler config={config.retailNetLeasableArea} component={Row} unitSystem={unitSystem} />
            <ConfigurationHandler config={config.industrialNetLeasableArea} component={Row} unitSystem={unitSystem} />
            <ConfigurationHandler config={config.parkingRequiredSpacesAfterReduction} component={Row} unitSystem={unitSystem} />
            <ConfigurationHandler config={config.projectNumberOfFloors} component={Row} unitSystem={unitSystem} />
          </Section>

          <Disclaimer />
          <Logo />
        </Container>
      )
    }}
  </PdfContext.Consumer>
);

export default Summary;
