import React from "react";
import { Page } from "@react-pdf/renderer";
import styled from "@react-pdf/styled-components";
import Footer from "../sharedComponents/Footer";
import Header from "../sharedComponents/Header";
import Image from "./Image";
import Assumptions from "./Assumptions";
import BackOfEnvelope from "./BackOfEnvelope";
import { allTogglesDisabled } from "../../../../../utils/uiToggleHelper";
import PdfContext from "../../PdfContext";
import developmentAccessors from "../../../../../../state/development/utils/developmentAccessors";
import companyInfo from "../../../../../../utils/companyInfo";

const Content = styled.View`
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  background-color: #E5EBED;
`;

const LeftContainer = styled.View`
  width: 344px;
`;

const RightContainer = styled.View`
  display: flex;
  flex-shrink: 1;
`;

const Link = styled.Link`
  font-family: "Roboto Condensed Bold";
`;

const SecondPage = () => (
  <PdfContext.Consumer>
    {({ development }) => {
      let toggles = developmentAccessors.getBuildingUsageToggles(development);

      if (allTogglesDisabled(toggles)) return null;

      return (
        <Page size="LETTER">
          <Content>
            <LeftContainer>
              <Header />
              <Assumptions />
            </LeftContainer>
            <RightContainer>
              <BackOfEnvelope />
              <Image />
            </RightContainer>
          </Content>
          <Footer>
            Create your own project for free. Find out more at <Link href={companyInfo.WEBSITE_URL}>deepblocks.com</Link>.
          </Footer>
        </Page>
      )
    }}
  </PdfContext.Consumer>
);

export default SecondPage;
