import React from "react";
import styled from "@react-pdf/styled-components";
import backOfEnvelopeIcon from "../../../../../../../../assets/images/pdf-icon-back-of-envelope.png";

const Container = styled.View`
  margin-top: 45px;
`;

const TitleContainer = styled.View`
  left: 46px;
  height: 29px;
  margin-bottom: 23px;
`;

const TitleIcon = styled.Image`
  position: absolute;
  width: 19px;
  left: 0;
  top: 3px;
`;

const TitleFirstRow = styled.Text`
  top: 0;
  left: 30px;
  font-family: "Roboto Condensed";
  font-size: 13px;
  letter-spacing: 0px;
  color: #333333;
`;

const TitleSecondRow = styled.Text`
  bottom: 0;
  left: 30px;
  font-family: "Roboto Condensed Bold";
  font-size: 13px;
  letter-spacing: 0.25px;
  color: #333333;
`;

const Section = (props) => {
  if (!props.displaySection) return null;

  return (
    <Container>
      <TitleContainer>
        <TitleIcon src={backOfEnvelopeIcon} />
        <TitleFirstRow>{props.title}</TitleFirstRow>
        <TitleSecondRow>RETURN ANALYSIS</TitleSecondRow>
      </TitleContainer>

      {props.children}
    </Container>
  );
}

export default Section;
