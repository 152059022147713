import React from "react";
import { connect } from "react-redux";
import { developmentSelectors } from "../../../../../../../state/development";
import authentication from "../../../../../../../utils/authentication";
import CellRow from "../../../../../../sharedComponents/CellRow";
import ConfigurationHandler from "../../../../../../sharedComponents/ConfigurationHandler";
import CellsContainer from "../../../../../../sharedComponents/CellsContainer";
import config from "./config";

const mapStateToProps = (state) => {
  return {
    unitSystem: developmentSelectors.getUnitSystem(state),
  }
}

interface OwnProps {
  index: number;
  currentTab: number;
}

type StateProps = ReturnType<typeof mapStateToProps>;
type Props = OwnProps & StateProps;

class BackOfEnvelopeHotelWorksheet extends React.PureComponent<Props, {}> {
  /**
   * Render Hotel NOI section.
   */
  renderHotelNOISection = (userIsAuthenticated) => (
    <>
      <CellRow>
        <ConfigurationHandler config={config.hotelAnnualRoomRevenueForBackOfEnvelope} component={CellsContainer} unitSystem={this.props.unitSystem} hiddenValues={!userIsAuthenticated} isFromBackOfEnvelope />
      </CellRow>
      <CellRow>
        <ConfigurationHandler config={config.hotelAnnualOtherRevenueForBackOfEnvelope} component={CellsContainer} unitSystem={this.props.unitSystem} hiddenValues={!userIsAuthenticated} isFromBackOfEnvelope />
      </CellRow>
      <CellRow>
        <ConfigurationHandler config={config.hotelAnnualOperatingExpenseForBackOfEnvelope} component={CellsContainer} unitSystem={this.props.unitSystem} hiddenValues={!userIsAuthenticated} isFromBackOfEnvelope />
      </CellRow>
      <CellRow>
        <ConfigurationHandler config={config.hotelAnnualNetOperatingIncomeForBackOfEnvelope} component={CellsContainer} styleVariation="bold-17 border-above" unitSystem={this.props.unitSystem} hiddenValues={!userIsAuthenticated} isFromBackOfEnvelope />
      </CellRow>
      <CellRow
        classes="break"
      />
    </>
  )

  /**
   * Render All-In Cost PSF section.
   */
  renderALlInCostPSFSection = (userIsAuthenticated) => (
    <>
      <CellRow>
        <ConfigurationHandler config={config.hotelTotalDevelopmentCostIncludingParking} component={CellsContainer} unitSystem={this.props.unitSystem} hiddenValues={!userIsAuthenticated} isFromBackOfEnvelope />
      </CellRow>
      <CellRow>
        <ConfigurationHandler config={config.hotelGrossBuildableAreaIncludingParking} component={CellsContainer} unitSystem={this.props.unitSystem} hiddenValues={!userIsAuthenticated} isFromBackOfEnvelope />
      </CellRow>
      <CellRow>
        <ConfigurationHandler config={config.hotelDevelopmentCostPerAreaIncludingParking} component={CellsContainer} styleVariation="border-above bold-17" unitSystem={this.props.unitSystem} hiddenValues={!userIsAuthenticated} isFromBackOfEnvelope />
      </CellRow>
    </>
  )

  render() {
    if (this.props.index !== this.props.currentTab) return null;
    const userIsAuthenticated = authentication.isUserAuthenticated();

    return (
      <table>
        <tbody>
          {this.renderHotelNOISection(userIsAuthenticated)}
          {this.renderALlInCostPSFSection(userIsAuthenticated)}
        </tbody>
      </table>
    )
  }
}

export default connect(mapStateToProps)(BackOfEnvelopeHotelWorksheet);
