import React from "react";
import wrapComponentWithPopup from "../../wrapComponentWithPopup"
import { PopupProps } from "../../wrapComponentWithPopup/wrapComponentWithPopup";
import authentication from "../../../../utils/authentication";

interface OwnProps {
  developmentName: string;
  redirect?: boolean;
  onConfirm();
}

type Props = OwnProps & PopupProps;

interface State {
  buttonIsPressed: boolean;
}

class DeleteDevelopmentPopup extends React.PureComponent<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      buttonIsPressed: false // Used to prevent triggering click handlers multiple times.
    }
  }

  /**
   * Confirm action and close popup.
   */
  handleConfirm = () => {
    this.setState({ buttonIsPressed: true });
    this.props.onConfirm();
    this.props.closePopup();
  }

  /**
   * Cancel action and close popup.
   */
  handleCancel = () => {
    this.setState({ buttonIsPressed: true });
    this.props.closePopup();
  }

  /**
   * Stop click event from propagating.
   */
  preventPropagation = (event) => event.stopPropagation();

  render() {
    const userIsAuthenticated = authentication.getCurrentUser();

    return (
      <div className="component--delete-development-popup" onClick={this.preventPropagation}>
        <div className="top">
          Are you sure you want to discard <br />
          <span className="bold">{this.props.developmentName}</span>?
          {this.props.redirect &&
              <>
                <div className="separator" />
                <div className="italic">{`You will be redirected to the ${userIsAuthenticated ? "Dashboard" : "Home Page"}`}</div>
              </>
          }
        </div>

        <div className="bottom">
          <div className="buttons-container">
            <button
              className="cancel"
              onClick={this.handleCancel}
              disabled={this.state.buttonIsPressed}
            >
              Cancel
            </button>
            <button
              className="confirm"
              onClick={this.handleConfirm}
              disabled={this.state.buttonIsPressed}
            >
              Discard Project
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default wrapComponentWithPopup(DeleteDevelopmentPopup);
