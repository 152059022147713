import React from "react";
import styled from "@react-pdf/styled-components";

const TITLE_HEIGHT = 11;

const TitleContainer = styled.View`
  position: relative;
  left: 0;
  height: ${(props) => `${props.height}px`};
  padding-bottom: ${(props) => `${props.paddingBottom || 0}px`};
  border-bottom: ${(props) => `${props.borderBottom || 0}px`};
  border-color: ${(props) => props.borderColor || ""};
`;

const TitleIcon = styled.Image`
  position: absolute;
  height: 11px;
  left: 0;
`;

const TitleText = styled.Text`
  position: absolute;
  top: 1px;
  left: 17px;
  font-family: "Roboto Bold";
  font-size: 9px;
  letter-spacing: 0.25px;
  color: #333333;
`;

const SectionTitle = (props) => (
  <TitleContainer
    borderBottom={props.borderBottom}
    borderColor={props.borderColor}
    paddingBottom={props.paddingBottom}
    height={TITLE_HEIGHT + (props.paddingBottom || 0)}
  >
    <TitleIcon src={props.titleIcon} />
    <TitleText> {props.title} </TitleText>
  </TitleContainer>
);

export default SectionTitle;
