import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import getPdfDocument from "../../state/pdf/utils/defaultPdfHelper";
import Pdf from "../../types/Pdf";
import databaseHelper, { FirebaseOperation, FirebaseOperationType } from "./utils/databaseHelper";

/**
 * @fileoverview This module handles database read/writes on the `pdf` collection.
 */

/**
 * Returns a create operation to create document in the pdf collection.
 */
const createOperation = (developmentId: string, pdfDocument?: Pdf.Document): FirebaseOperation => {
  let pdfReference = getPdfReference(developmentId);

  let data: Pdf.Document = getPdfDocument(pdfDocument);

  let createPdfOperation: FirebaseOperation = {
    type: FirebaseOperationType.Set,
    documentReference: pdfReference,
    data: data,
  }

  return createPdfOperation;
}

/**
 * Returns an update operation to update document in the pdf collection.
 */
const updateOperation = (developmentId: string, pdfDocument: Pdf.Document): FirebaseOperation => {
  let pdfReference = getPdfReference(developmentId);

  let updatePdfOperation: FirebaseOperation = {
    type: FirebaseOperationType.Update,
    documentReference: pdfReference,
    data: pdfDocument,
  }

  return updatePdfOperation;
}

/**
 * Returns a delete operation to delete document in the pdf collection.
 */
const removeOperation = (developmentId: string): FirebaseOperation => {
  let pdfReference = getPdfReference(developmentId);

  return {
    type: FirebaseOperationType.Delete,
    documentReference: pdfReference,
  }
}

/**
 * Create pdf document for the given Development Id.
 */
const create = async (developmentId: string, pdfDocument?: Pdf.Document) => {
  let pdfReference = getPdfReference(developmentId);
  let pdfData = getPdfDocument(pdfDocument);

  await databaseHelper.createDocument(pdfReference, pdfData);
}

/**
 * Read and return pdf document.
 */
const read = async (developmentId: string): Promise<Pdf.Document> => {
  let pdfReference = getPdfReference(developmentId);

  return await databaseHelper.getDocument(pdfReference);
}

/**
 * Return reference to the pdf collection.
 */
const getPdfReference = (developmentId) => {
  return firebase.firestore().doc(`pdf/${developmentId}`);
}

export default {
  createOperation,
  updateOperation,
  removeOperation,
  read,
  create,
}
