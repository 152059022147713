import actionTypes from "./actionTypes";
import { Chargebee } from "../../types/Service/Chargebee";

export interface SubscriptionState {
  planId?: Chargebee.PlanId;
  id?: string;
  status?: Chargebee.SubscriptionStatus;
  planPrices: Chargebee.PlanPrices;
}

const INITIAL_STATE: SubscriptionState = {
  planPrices: {},
};

const reducer = (previousState = INITIAL_STATE, action): SubscriptionState => {
  switch (action.type) {
    case actionTypes.LOAD_SUCCESS: return loadSuccess(previousState, action.payload);
    case actionTypes.SET_PLAN_PRICES: return setPlanPrices(previousState, action.payload);
    default: return previousState;
  }
}

/**
 * See `loadSuccess` action creator.
 *
 * Returns state with updated subscription information.
 */
const loadSuccess = (previousState: SubscriptionState, payload: { subscription: Chargebee.Subscription | null }): SubscriptionState => {
  let id, planId;
  let status = Chargebee.SubscriptionStatus.None;

  if (payload.subscription) {
    id = payload.subscription.id;
    status = payload.subscription.status;
    planId = payload.subscription.plan_id;
  }

  return {
    ...previousState,
    planId,
    id,
    status,
  };
}

/**
 * See `setPlanPrices` action creator.
 *
 * Returns state with updated plan prices information.
 */
const setPlanPrices = (previousState: SubscriptionState, payload): SubscriptionState => {
  return {
    ...previousState,
    planPrices: payload.planPrices,
  };
}

export default reducer;
