import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { developmentSelectors } from "../../../state/development";
import Format from "../../../types/Format";
import Unit from "../../../types/Unit";
import valueFormatter from "../../../utils/valueFormatter";
import unitConversions from "../../../utils/unitConversions";
import { TooltipType } from "../Tooltip/config";

const mapStateToProps = (state, ownProps) => {
  let value = (ownProps.variableId === undefined)
    ? ownProps.text
    : developmentSelectors.getValue(state, ownProps.variableId);

  if (ownProps.value) value = ownProps.value;

  return { value };
};

type ItemToFormat = {
  value: any;
  formatOptions?: Format.Options;
}

type OwnProps = {
  variableId?: string;
  styleVariation?: string;
  value?: any;
  formatOptions?: Format.Options | any;
  unitTarget?: Unit.Type;
  unitIsInverse?: boolean;
  text?: string;
  abbreviationExpandedText?: string;
  tdElementProps?: any;
  hiddenValues?: boolean;
  isFromBackOfEnvelope?: boolean;
  formattingMap?: (value) => ItemToFormat;
}

type StateProps = ReturnType<typeof mapStateToProps>;
type Props = OwnProps & StateProps;

class Cell extends PureComponent<Props, {}>  {
  /**
   * Conditionally render the expansion of the abbreviation within parenthesis.
   * This expansion will be shown right after the formattedValue explaining what
   * the abbreviation stands for.
   */
  renderAbbreviationExpandedText = () => {
    if (this.props.text && this.props.abbreviationExpandedText) {
      return <span className="abbreviation-expanded-text"> ({this.props.abbreviationExpandedText})</span>
    }

    return null;
  }

  render() {
    const { text, value, formatOptions, styleVariation, tdElementProps, formattingMap, unitTarget, unitIsInverse, hiddenValues, isFromBackOfEnvelope } = this.props;

    let array;
    if (Array.isArray(value)) array = value;

    let formattedValue;
    if (array) {
      formattedValue = formattingMap
          ? array.map(formattingMap).map((item) => valueFormatter.format(item.value, item.formatOptions))
          : array;
    } else if (text) {
      formattedValue = text;
    } else {
      const convertedValue = unitTarget
          ? unitConversions.convertFromBase(value, unitTarget, unitIsInverse)
          : value;

      formattedValue = formatOptions
          ? valueFormatter.format(convertedValue, formatOptions)
          : convertedValue;
    }

    let classes = "component--cell";
    if (styleVariation) classes += ` ${styleVariation}`;
    if (text) classes += " text-left";

    return (
      <td {...tdElementProps} className={classes} data-for="hover" data-tip={hiddenValues && (isFromBackOfEnvelope ? TooltipType.BackOfEnvelope : TooltipType.InformationPanel)}>
        {Array.isArray(formattedValue)
            ? formattedValue.map((line, index) => <span className={`array-item ${hiddenValues ? "blurry-text" : ""}`} key={index}>{line}<br /></span>)
            : <span className={`${hiddenValues ? " blurry-text" : ""}`}>{formattedValue}</span>
        }
        {this.renderAbbreviationExpandedText()}
      </td>
    );
  }
}

export default connect(mapStateToProps)(Cell) as React.ComponentClass<OwnProps>;
