import { Migration } from "..";
import migration001_convertParcelToGeoJson from "./001_convertParcelToGeoJson";
import migration002_addSetbackD from "./002_addSetbackD";
import migration003_changeNestedArrayDatabaseFormat from "./003_changeNestedArrayDatabaseFormat";
import migration004_setParcelProperties from "./004_setParcelProperties";
import migration005_convertToMetricSystem from "./005_convertToMetricSystem";
import migration006_setbacksInEveryFloor from "./006_setbacksInEveryFloor";

const MIGRATIONS: Array<Migration> = [
  migration001_convertParcelToGeoJson,
  migration002_addSetbackD,
  migration003_changeNestedArrayDatabaseFormat,
  migration004_setParcelProperties,
  migration005_convertToMetricSystem,
  migration006_setbacksInEveryFloor,
];

export default MIGRATIONS;
