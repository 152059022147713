import React from "react";
import styled from "@react-pdf/styled-components";
import PdfContext from "../../../PdfContext";

const Container = styled.View`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 30px;
`;

const FooterImage = styled.View`
  position: absolute;
  background: ${(props) => props.backgroundColor}
  height: 100%;
  width: 100%;
`;

const PageNumber = styled.Text`
  position: absolute;
  bottom: 9px;
  right: 25px;
  color: #F75959;
  font-family: "Roboto Condensed Bold";
  font-size: 8.5px;
`;

const Content = styled.Text`
  position: absolute;
  font-family: "Roboto Condensed";
  font-size: 7.2px;
  color: #F4F5F8;
  top: 11px;
  left: 25px;
`;

const Footer = (props) => (
  <PdfContext.Consumer>
    {({ colorPalette }) => (
      <Container fixed>
        <FooterImage backgroundColor={colorPalette.primary} />
        <Content>{props.children}</Content>
        <PageNumber render={({ pageNumber }) => `${pageNumber}`} fixed />
      </Container>
    )}
  </PdfContext.Consumer>
);

export default Footer;
