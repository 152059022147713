import React from "react";
import styled from "@react-pdf/styled-components";
import { allTogglesDisabled } from "../../../../../../../utils/uiToggleHelper";
import SectionTitle from "../../../sharedComponents/SectionTitle"
import { color } from "../../../../../../../utils/colors";
import PdfContext from "../../../../PdfContext";
import developmentAccessors from "../../../../../../../../state/development/utils/developmentAccessors";

const PADDING_BOTTOM = 9;
const BORDER_BOTTOM = 0.48;
const BORDER_COLOR = color("silver");

const Container = styled.View`
  margin-top: 20px;
`;

const Section = (props) => (
  <PdfContext.Consumer>
    {({ development }) => {
      let toggles = developmentAccessors.getBuildingUsageToggles(development);
      if (props.requiresUsages && allTogglesDisabled(toggles)) return null;

      return (
        <Container>
          <SectionTitle
            title={props.title}
            titleIcon={props.titleIcon}
            paddingBottom={PADDING_BOTTOM}
            borderBottom={BORDER_BOTTOM}
            borderColor={BORDER_COLOR}
          />

          {props.children}
        </Container>
      )
    }}
  </PdfContext.Consumer>
);

export default Section;
