
import { Development } from  "../../../../types/Development/Development";
import { VariableId } from  "../../../../types/VariableId";
import generateLinearRegressionPreset from "./generatorForLinearRegression";

/**
 * Generate the condo buildables presets. Only one bedroom units will be initialized to non zero values.
 */
const generatePresets = (development: Development): object => {
  return {
    [VariableId.ParkingHardCostPerArea]: generateLinearRegressionPreset(development, VariableId.ParkingHardCostPerArea),
  };
}

export default generatePresets;
