import { put, takeEvery, call } from "redux-saga/effects";
import actions from "./actions";
import actionTypes from "./actionTypes";

/**
 * Fetch user location.
 */
function* getUserLocationStart(action) {
  try {
    const { longitude, latitude } = yield call(getUserCurrentLocation);
    yield put(actions.getLocationSuccess([longitude, latitude]));
  } catch (error) {
    console.warn(error.message);
    yield put(actions.getLocationError());
  }
}

/**
 * Get user geolocation. This is a wrapper that returns a promise.
 */
const getUserCurrentLocation = () => new Promise((resolve, reject) => {
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(
      (location) => resolve(location.coords),
      (error) => reject(error),
    )
  } else {
    reject({ message: "Geolocation is not available in this browser." });
  }
})

/**
 * Watcher for user getLocationStart action.
 */
function* watchGetUserLocationStart() {
  yield takeEvery(actionTypes.GET_LOCATION_START, getUserLocationStart);
}

export default {
  watchGetUserLocationStart,
}
