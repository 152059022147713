import React from "react";
import Format from "../../../../../types/Format";
import parcelAccessors from "../../../../../utils/parcel/parcelAccessors";
import valueFormatter from "../../../../../utils/valueFormatter";
import authentication from "../../../../../utils/authentication";
import CellRow from "../../../CellRow";
import Cell from "../../../Cell";

type OwnProps = {
  parcelFeature: any;
}

type Props = OwnProps;

/**
 * Render unit density ratio section.
 */
class Setbacks extends React.PureComponent<Props, {}> {
  renderSetbackSection = (parcelFeature, index, isAssembly = false) => {
    const { isNumeric } = valueFormatter;
    const userIsAuthenticated = authentication.isUserAuthenticated();
    const address = parcelAccessors.getAddress(parcelFeature) || `Property ${index + 1}`;

    const setbacks = {
      primary: parcelAccessors.getSetbacksPrimary(parcelFeature),
      sideStreet: parcelAccessors.getSetbacksSideStreet(parcelFeature),
      sideInterior: parcelAccessors.getSetbacksSideInterior(parcelFeature),
      rear: parcelAccessors.getSetbacksRear(parcelFeature),
    }

    if (Object.values(setbacks).every((type) => type === null)) return null;

    return (
      <table key={`table_${index}`}>
        <thead>
          <tr>
            <th colSpan={2}>{`Setbacks${isAssembly ? ` for ${address}` : ""}`}</th>
          </tr>
        </thead>
        <tbody>
          {setbacks.primary
              ? <CellRow classes="sub-section">
                  <Cell text="• Front" />
                  <Cell value={setbacks.primary} formatOptions={{ type: isNumeric(setbacks.primary) ? Format.Type.Number : Format.Type.PlainText, suffix: " FT" }} styleVariation="bold capitalize" hiddenValues={!userIsAuthenticated} />
                </CellRow>
              : null
          }
          {setbacks.sideStreet
              ? <CellRow classes="sub-section">
                  <Cell text="• Secondary Front" />
                  <Cell value={setbacks.sideStreet} formatOptions={{ type: isNumeric(setbacks.sideStreet) ? Format.Type.Number : Format.Type.PlainText, suffix: " FT" }} styleVariation="bold capitalize" hiddenValues={!userIsAuthenticated} />
                </CellRow>
              : null
          }
          {setbacks.sideInterior
              ? <CellRow classes="sub-section">
                  <Cell text="• Side" />
                  <Cell value={setbacks.sideInterior} formatOptions={{ type: isNumeric(setbacks.sideInterior) ? Format.Type.Number : Format.Type.PlainText, suffix: " FT" }} styleVariation="bold capitalize" hiddenValues={!userIsAuthenticated} />
                </CellRow>
              : null
          }
          {setbacks.rear
              ? <CellRow classes="sub-section">
                  <Cell text="• Rear" />
                  <Cell value={setbacks.rear} formatOptions={{ type: isNumeric(setbacks.rear) ? Format.Type.Number : Format.Type.PlainText, suffix: " FT" }} styleVariation="bold capitalize" hiddenValues={!userIsAuthenticated} />
                </CellRow>
              : null
          }
        </tbody>
      </table>
    );
  }

  render() {
    const { parcelFeature } = this.props;

    return parcelAccessors.getIsAnAssembly(parcelFeature)
        ? parcelAccessors.getAssemblyParcels(parcelFeature).map((parcel, index) => this.renderSetbackSection(parcel, index, true))
        : this.renderSetbackSection(parcelFeature, 0);
  }
}

export default Setbacks;
