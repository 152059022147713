
/**
 * Transform the development parcel object to be a GEOJson object with
 * properties set to default values.
 */
const setParcelProperties = (development) => {
  let parcel = { ...development.parcel.feature };
  let areaFromPublishedData = development.parcel.areaFromPublishedData;

  // Prevent writing `undefined` to the database.
  areaFromPublishedData =
    areaFromPublishedData === undefined
      ? null
      : areaFromPublishedData;

  parcel.properties = {
    "id": null,
    "address": null,
    "areaPublished": areaFromPublishedData,
    "purchasePrice": null,
    "existingStructureArea": null,
    "existingStructureYearBuilt": null,
    "zoneId": null,
    "allowedBuildableArea": null,
    "allowedUses": null,
    "architectName": null,
    "architectUrl": null,
    "isInOpportunityZone": null,
    "isAnAssembly": false,
  }

  development.parcel = parcel;
}

export default setParcelProperties;
