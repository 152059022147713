import React from "react";
import ReactMapboxGl from "react-mapbox-gl";
import { connect } from "react-redux";
import { pdfActions, pdfSelectors } from "../../../../../state/pdf";
import Pdf from "../../../../../types/Pdf";
import { ReactMapboxGlCamera } from "../../../../../types/ReactMapboxGlCamera";
import { MapStyleProperties } from "../../../../../utils/mapbox/mapStyleProperties";

const Mapbox = ReactMapboxGl({
  accessToken: process.env.REACT_APP_MAPBOX_ACCESS_TOKEN as string,
  interactive: false,
  // TODO: Verify that this property can be removed. Research can start at:
  // https://github.com/mapbox/mapbox-gl-js/issues/2766
  preserveDrawingBuffer: true,
});

interface OwnProps {
  pdfImageType: Pdf.ImageType;
  mapStyleUrl: MapStyleProperties.StyleUrl;
  camera: ReactMapboxGlCamera;
  visibleLayers?: Array<MapStyleProperties.LayerId>;
  onStyleLoad?(map: any);
}

const mapStateToProps = (state, ownProps: OwnProps) => {
  return {
    imageIsTaken: Boolean(pdfSelectors.getMapImage(state, ownProps.pdfImageType)),
  };
}

const mapDispatchToProps = {
  setMapImage: pdfActions.setMapImage,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;
type Props = StateProps & DispatchProps & OwnProps;

class Map extends React.PureComponent<Props, {}> {
  /**
   * Capture the map canvas once the style and building are fully loaded.
   */
  handleStyleLoad = (map) => {
    const { onStyleLoad, visibleLayers, pdfImageType, setMapImage } = this.props;

    if (onStyleLoad) onStyleLoad(map);
    if (visibleLayers) visibleLayers.forEach((layerId) => map.setLayoutProperty(layerId, "visibility", "visible"));

    map.on(
      "idle",
      async () => {
        // NOTE: It's important to use this.props inside this callback to get the updated value of `imageIsTaken`.
        if (!this.props.imageIsTaken) {
          // The following three lines are a HACK to go around the fact that Microsoft Edge
          // does not support canvas.toBlob()
          let mapImageUrl = map.getCanvas().toDataURL();
          let response = await fetch(mapImageUrl);
          let mapImageBlob = await response.blob();

          let mapObjectUrl = URL.createObjectURL(mapImageBlob);
          setMapImage(pdfImageType, mapObjectUrl);
        }
      }
    );
  }

  /**
   * Add classes depending on the image type.
   */
  getMapClass = () => {
    let className = "";
    if (this.props.pdfImageType === Pdf.ImageType.Parcel) className = "parcel";
    else if (this.props.pdfImageType === Pdf.ImageType.Cover) className = "cover";

    return className;
  }

  render() {
    return (
      <div className={`component--map-image ${this.getMapClass()}`}>
        <Mapbox
          style={this.props.mapStyleUrl}
          zoom={this.props.camera.zoom}
          pitch={this.props.camera.pitch}
          bearing={this.props.camera.bearing}
          center={this.props.camera.center}
          onStyleLoad={this.handleStyleLoad}
          containerStyle={{
            height: "100%",
            width: "100%",
          }}
        >
          {this.props.children as any}
        </Mapbox>
      </div>
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Map);
