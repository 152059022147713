import unitConversions from "./unitConversions"
import Unit from "../types/Unit";

const ONE_DEGREE_LONGITUDE_AT_EQUATOR_IN_METERS = 111321;
const ONE_DEGREE_LATITUDE_IN_METERS = 111133;

/**
 * Calculate how many meters one degree of longitude represents at a given latitude.
 */
const getMetersPerLongitudeDegree = (latitude) => {
  let latitudeInRadians = unitConversions.convert(latitude, Unit.Type.Degrees, Unit.Type.Radians);
  let metersPerLongitudeDegree = Math.cos(latitudeInRadians) * ONE_DEGREE_LONGITUDE_AT_EQUATOR_IN_METERS;
  return metersPerLongitudeDegree;
}

/**
 * Transform a point from lat/lon coordinates to local coordinates knowing which lat/lon is represented by the local
 * origin and the ratios of degrees to meters.
 */
const pointFromLatLonToLocal = (latLonPoint, originLatLon, metersPerLongitudeDegree) => {
  return {
    x: metersPerLongitudeDegree * (latLonPoint.x - originLatLon.x),
    y: ONE_DEGREE_LATITUDE_IN_METERS  * (latLonPoint.y - originLatLon.y),
  }
}

/**
 * Transform a point from local coordinates to lat/lon coordinates knowing which lat/lon is represented by the local
 * origin and the ratios of degrees to meters.
 */
const pointFromLocalToLatLon = (localPoint, originLatLon, metersPerLongitudeDegree) => {
  let xCoord = originLatLon.x + (localPoint.x / metersPerLongitudeDegree);
  let yCoord = originLatLon.y + (localPoint.y / ONE_DEGREE_LATITUDE_IN_METERS);

  return {
    x: xCoord,
    y: yCoord,
  }
}

/**
 * Initializes a local geometry by selecting the western most/south most point as the origin. A local geometry
 * is well defined by which lat/lon represents the origin, the degrees to meters ratios are calculated based on that origin.
 */
const initializeLocalGeometryFromLatLon = (globalPoints) => {
  let originLatLon = globalPoints[0];
  for (let point of globalPoints) {
    if ((point.x < originLatLon.x) || (point.x === originLatLon.x && point.y < originLatLon.y)) {
      originLatLon = point;
    }
  }

  let metersPerLongitudeDegree = getMetersPerLongitudeDegree(originLatLon.y);

  let localPoints = globalPoints.map(
    (point) => pointFromLatLonToLocal(point, originLatLon, metersPerLongitudeDegree)
  );

  let result = [localPoints, originLatLon]

  return result;
}

/**
 * Translate a set of points to a previously defined local geometry.
 */
const pointsToLocalGeometryFromLatLon = (latLonPoints, originLatLon) => {
  let metersPerLongitudeDegree = getMetersPerLongitudeDegree(originLatLon.y);
  let localPoints = latLonPoints.map(
    (point) => pointFromLatLonToLocal(point, originLatLon, metersPerLongitudeDegree)
  );
  return localPoints;
}

/**
 * Translate a set of points from a local geometry to lat/lon coordinates.
 */
const pointsToLatLonFromLocalGeometry = (localPoints, originLatLon) => {
  let metersPerLongitudeDegree = getMetersPerLongitudeDegree(originLatLon.y);
  let latLonPoints = localPoints.map(
    (point) => {
      let P = pointFromLocalToLatLon(point, originLatLon, metersPerLongitudeDegree);
      return P;
    }
  );
  return latLonPoints;
}

export default {
  initializeLocalGeometryFromLatLon,
  pointsToLocalGeometryFromLatLon,
  pointsToLatLonFromLocalGeometry,
};
