import { Config } from "../../../../../../../types/Config";
import { VariableId } from "../../../../../../../types/VariableId";
import Format from "../../../../../../../types/Format";
import Unit from "../../../../../../../types/Unit";

const config: Config = {

  [VariableId.CondoGrossSales]: {
    variableId: VariableId.CondoGrossSales,
    text: "Unit Sales",
    formatOptions: { type: Format.Type.Currency }
  },

  [VariableId.CondoTotalSalesCommissions]: {
    variableId: VariableId.CondoTotalSalesCommissions,
    text: "Sales Costs",
    formatOptions: {
      type: Format.Type.Currency,
      negate: true,
    },
  },

  [VariableId.CondoNetProceeds]: {
    variableId: VariableId.CondoNetProceeds,
    text: "Condo Net Proceeds",
    formatOptions: { type: Format.Type.Currency }
  },

  [VariableId.CondoProfit]: {
    variableId: VariableId.CondoProfit,
    text: "Condo Profit",
    formatOptions: { type: Format.Type.Currency }
  },

  [VariableId.CondoTotalDevelopmentCostIncludingParking]: {
    variableId: VariableId.CondoTotalDevelopmentCostIncludingParking,
    text: "Condo Project Cost",
    formatOptions: { type: Format.Type.Currency }
  },

  [VariableId.CondoGrossBuildableAreaIncludingParking]: {
    variableId: VariableId.CondoGrossBuildableAreaIncludingParking,
    text: "Condo Buildable (Including Parking)",
    formatOptions: { type: Format.Type.Number },
    [Unit.System.Imperial]: {
      formatOptions: { suffix: " SF" },
      unitTarget: Unit.Type.SquareFeet,
    },
    [Unit.System.Metric]: {
      formatOptions: { suffix: " m²" },
      unitTarget: Unit.Type.SquareMeters,
    }
  },

  [VariableId.CondoDevelopmentCostPerAreaIncludingParking]: {
    variableId: VariableId.CondoDevelopmentCostPerAreaIncludingParking,
    formatOptions: {
      type: Format.Type.Currency,
      decimalPlaces: 2,
    },
    [Unit.System.Imperial]: {
      text: "Condo All-In Cost PSF",
      abbreviationExpandedText: "Per Square Foot",
      unitTarget: Unit.Type.SquareFeet,
      unitIsInverse: true,
      formatOptions: { suffix: " / SF" },
    },
    [Unit.System.Metric]: {
      text: "Condo All-In Cost PSM",
      abbreviationExpandedText: "Per Square Meter",
      unitTarget: Unit.Type.SquareMeters,
      unitIsInverse: true,
      formatOptions: { suffix: " / m²" },
    }
  }
};

export default config;
