import React from "react";
import { connect } from "react-redux";
import { newDevelopmentSelectors } from "../../../../../state/newDevelopment";
import SearchRow from "./SearchRow";
import ConfigurationHandler from "../../../../sharedComponents/ConfigurationHandler";
import config from "./config"
import { FilterId } from "../../../../../types/Filter";
import { TooltipType } from "../../../../sharedComponents/Tooltip/config";
import authentication from "../../../../../utils/authentication";

const mapStateToProps = (state) => {
  return {
    filters: newDevelopmentSelectors.getFilters(state),
    unitSystem: newDevelopmentSelectors.getUnitSystem(state),
  }
}

type MapStateProps = ReturnType<typeof mapStateToProps>;
type Props = MapStateProps;

class SearchPanel extends React.Component<Props, {}> {
  /**
   * Display tooltip if user is not authenticated.
   */
  getTooltipType = (id) => {
    const userIsAuthenticated = authentication.isUserAuthenticated();
    if (userIsAuthenticated) return;
    if (id === FilterId.Vacant || id === FilterId.AreaComputed) return;
    return TooltipType.SmartSearch;
  }

  render() {
    const displayableFilters = Object.values(this.props.filters).filter((filter) => filter.shouldDisplay);

    if (displayableFilters.length === 0) {
      return (
        <>
          <p>Please zoom in to verify if we have data in this city.</p>
          <p>
            If we don't have data and you'd like to request it, please use <a href="http://bit.ly/2Eo90tz" target="_blank" rel="noopener noreferrer">this link.</a>
          </p>
        </>
      )
    }

    return displayableFilters.map((filter) =>
      <ConfigurationHandler
        config={config[filter.id]}
        component={SearchRow}
        unitSystem={this.props.unitSystem}
        filter={filter}
        key={filter.id}
        dataTip={this.getTooltipType(filter.id)}
      />
    );
  }
}

export default connect(mapStateToProps)(SearchPanel);
