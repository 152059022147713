import React from "react";
import { connect } from "react-redux";
import { developmentSelectors } from "../../../../../state/development";
import { Panel } from "../../../../../types/uiElements";
import ConfigurationHandler from "../../../../sharedComponents/ConfigurationHandler";
import PanelHeader from "../sharedComponents/PanelHeader";
import SliderBox from "../sharedComponents/SliderBox";
import PanelSectionTitle from "../sharedComponents/PanelSectionTitle";
import config from "./config";

const mapStateToProps = (state) => {
  return {
    unitSystem: developmentSelectors.getUnitSystem(state),
    values: developmentSelectors.getValues(state),
  }
}
interface OwnProps {
  panel: Panel;
  onPanelClose(panel: Panel);
  onPanelPin(panel: Panel);
}

type StateProps = ReturnType<typeof mapStateToProps>;
type Props = OwnProps & StateProps;

class CondoPanel extends React.PureComponent<Props, any>  {
  /**
   * Render Condo Unit Mix section.
   */
  renderUnitMixSection = () => {
    return (
      <div data-tour="condo-unit-mix">
        <ConfigurationHandler config={config.condoTotalUnitQuantity} component={PanelSectionTitle} unitSystem={this.props.unitSystem} />
        <div>
          <ConfigurationHandler config={config.condoMicrounitQuantityToggleable} component={SliderBox} unitSystem={this.props.unitSystem} />
          <ConfigurationHandler config={config.condoStudioQuantityToggleable} component={SliderBox} unitSystem={this.props.unitSystem} />
          <ConfigurationHandler config={config.condoOneBedQuantityToggleable} component={SliderBox} unitSystem={this.props.unitSystem} />
          <ConfigurationHandler config={config.condoTwoBedQuantityToggleable} component={SliderBox} unitSystem={this.props.unitSystem} />
          <ConfigurationHandler config={config.condoThreeBedQuantityToggleable} component={SliderBox} unitSystem={this.props.unitSystem} />
        </div>
      </div>
    );
  }

  /**
   * Render Condo Gross Buildable section.
   */
  renderGrossBuildableSection = () => {
    if (this.props.values.condoTotalUnitQuantity === 0) return null;

    return (
      <div data-tour="condo-gross-buildable">
        <ConfigurationHandler config={config.condoGrossBuildableArea} component={PanelSectionTitle} unitSystem={this.props.unitSystem} />
        <div>
          {
            this.props.values.condoMicrounitQuantityToggleable
              ? <ConfigurationHandler config={config.condoMicrounitArea} component={SliderBox} unitSystem={this.props.unitSystem} />
              : null
          }
          {
            this.props.values.condoStudioQuantityToggleable
              ? <ConfigurationHandler config={config.condoStudioArea} component={SliderBox} unitSystem={this.props.unitSystem} />
              : null
          }
          {
            this.props.values.condoOneBedQuantityToggleable
              ? <ConfigurationHandler config={config.condoOneBedArea} component={SliderBox} unitSystem={this.props.unitSystem} />
              : null
          }
          {
            this.props.values.condoTwoBedQuantityToggleable
              ? <ConfigurationHandler config={config.condoTwoBedArea} component={SliderBox} unitSystem={this.props.unitSystem} />
              : null
          }
          {
            this.props.values.condoThreeBedQuantityToggleable
              ? <ConfigurationHandler config={config.condoThreeBedArea} component={SliderBox} unitSystem={this.props.unitSystem} />
              : null
          }
          <ConfigurationHandler config={config.condoLossFactor} component={SliderBox} unitSystem={this.props.unitSystem} />
          <ConfigurationHandler config={config.condoAmenitiesAreaToggleable} component={SliderBox} unitSystem={this.props.unitSystem} />
        </div>
      </div>
    );
  }

  /**
   * Render Condo Sales section.
   */
  renderSalesSection = () => {
    if (this.props.values.condoTotalUnitQuantity === 0) return null;

    return (
      <div data-tour="condo-sales">
        <ConfigurationHandler config={config.condoGrossSales} component={PanelSectionTitle} unitSystem={this.props.unitSystem} />
        <div>
          <ConfigurationHandler config={config.condoSaleCommissionPercentage} component={SliderBox} unitSystem={this.props.unitSystem} />

          {
            this.props.values.condoMicrounitQuantityToggleable
              ? <ConfigurationHandler config={config.condoSalePricePerMicrounit} component={SliderBox} unitSystem={this.props.unitSystem} />
              : null
          }
          {
            this.props.values.condoStudioQuantityToggleable
              ? <ConfigurationHandler config={config.condoSalePricePerStudio} component={SliderBox} unitSystem={this.props.unitSystem} />
              : null
          }
          {
            this.props.values.condoOneBedQuantityToggleable
              ? <ConfigurationHandler config={config.condoSalePricePerOneBed} component={SliderBox} unitSystem={this.props.unitSystem} />
              : null
          }
          {
            this.props.values.condoTwoBedQuantityToggleable
              ? <ConfigurationHandler config={config.condoSalePricePerTwoBed} component={SliderBox} unitSystem={this.props.unitSystem} />
              : null
          }
          {
            this.props.values.condoThreeBedQuantityToggleable
              ? <ConfigurationHandler config={config.condoSalePricePerThreeBed} component={SliderBox} unitSystem={this.props.unitSystem} />
              : null
          }
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="panel">
        <PanelHeader
          isPinned={this.props.panel.isPinned}
          panel={this.props.panel}
          onPanelClose={this.props.onPanelClose}
          onPanelPin={this.props.onPanelPin}
        />
        <div className="panel-inner-wrapper">
          {this.renderUnitMixSection()}
          {this.renderGrossBuildableSection()}
          {this.renderSalesSection()}
        </div>
      </div>
    );
  }
}

export default connect(
  mapStateToProps
)(CondoPanel);
