import React from "react";
import { connect } from "react-redux";
import { newDevelopmentActions, newDevelopmentSelectors } from "../../../../state/newDevelopment";
import { BuildingUse } from "../../../../types/BuildingUse";
import { Path } from "../../../../types/Path";
import { VariableId } from "../../../../types/VariableId";
import analytics from "../../../../utils/analytics";
import database from "../../../../utils/database/database";
import RedirectComponent from "../../../sharedComponents/RedirectComponent";
import { usageColor } from "../../../utils/buildingUsageProperties";
import { ToggleField } from "../../../utils/forms/fieldRenderers";
import { initializeNewDevelopment } from "../utils/initializeNewDevelopment";
import wrapComponentWithPopup from "../../../sharedComponents/wrapComponentWithPopup";
import parcelAccessors from "../../../../utils/parcel/parcelAccessors";
import { PopupProps } from "../../../sharedComponents/wrapComponentWithPopup/wrapComponentWithPopup";

const mapStateToProps = (state) => {
  return {
    selectedFeature: newDevelopmentSelectors.getSelectedFeature(state),
    newDevelopmentUnitSystem: newDevelopmentSelectors.getUnitSystem(state),
    initialValues: newDevelopmentSelectors.getInitialValues(state),
  }
}

const mapDispatchToProps = {
  updateInitialValues: newDevelopmentActions.updateInitialValues,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;
type Props = StateProps & DispatchProps & PopupProps;

interface State {
  floorAreaRatioValue: any;
  projectId: string | null;
}

class InitialValuesPanel extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      floorAreaRatioValue: props.initialValues.floorAreaRatio,
      projectId: null,
    };
  }

  /**
   * Update the floorAreaRatioValue state.
   */
  componentDidUpdate(previousProps: Props) {
    if (this.props.initialValues.floorAreaRatio !== previousProps.initialValues.floorAreaRatio) {
      this.setState({ floorAreaRatioValue: this.props.initialValues.floorAreaRatio });
    }
  }

  /**
   * Create a new project.
   */
  initializeProject = async () => {
    const { selectedFeature, newDevelopmentUnitSystem, initialValues } = this.props;
    if (!selectedFeature) return;

    let newDevelopmentData = initializeNewDevelopment(selectedFeature, newDevelopmentUnitSystem, initialValues);

    let newProjectId = await database.createNewProject(newDevelopmentData);
    analytics.trackCreateNewProject(newProjectId, { ...initialValues, unitSystem: newDevelopmentUnitSystem });
    this.setState({ projectId: newProjectId });
  }

  /**
   * Handle toggle change
   */
  handleToggleChange = (toggle: VariableId) => {
    this.props.updateInitialValues({
      [toggle]: !this.props.initialValues[toggle],
    });
  }

  /**
   * Change local floor area ratio value.
   */
  handleInputChange = (event) => {
    if (!isNaN(event.target.value)) this.setState({ floorAreaRatioValue: event.target.value });
  };

  /**
   * Change floor area ratio value on the store.
   */
  handleInputBlur = (event) => {
    this.props.updateInitialValues({ floorAreaRatio: Number(this.state.floorAreaRatioValue) });
    if (this.state.floorAreaRatioValue.length === 0) this.setState({ floorAreaRatioValue: 0 })
  }

  /**
   * Render floor area ratio section.
   */
  renderFloorAreaRatioContainer = () => {
    let farIsDefault = !Boolean(parcelAccessors.getMinimumFloorAreaRatio(this.props.selectedFeature));

    return (
      <div className="far-container">
        <p className="section-header">Property FAR (adjust if necessary)</p>
        <div className="input-container">
          <input
            name="far"
            value={this.state.floorAreaRatioValue}
            onChange={this.handleInputChange}
            onBlur={this.handleInputBlur}
          />
          {farIsDefault && <p className="default-far-note">This value does not come from the zoning code.</p>}
        </div>
      </div>
    )
  }

  /**
   * Render architect firm and link when available.
   */
  renderUsesContainer = () => {
    const { initialValues } = this.props;

    return (
      <>
        <p className="section-header">Select property uses, we will create your first project.</p>
        <div className="uses-container">
          <div>
            <div>
              <ToggleField
                label="Condominiums"
                color={usageColor(BuildingUse.Condo)}
                toggleElementProps={{
                  value: initialValues[VariableId.CondoToggle],
                  onChange: () => { this.handleToggleChange(VariableId.CondoToggle) }
                }}
              />
            </div>
            <div>
              <ToggleField
                label="Multifamily"
                color={usageColor(BuildingUse.Multifamily)}
                toggleElementProps={{
                  value: initialValues[VariableId.MultifamilyToggle],
                  onChange: () => { this.handleToggleChange(VariableId.MultifamilyToggle) }
                }}
              />
            </div>
            <div>
              <ToggleField
                label="Hotel"
                color={usageColor(BuildingUse.Hotel)}
                toggleElementProps={{
                  value: initialValues[VariableId.HotelToggle],
                  onChange: () => { this.handleToggleChange(VariableId.HotelToggle) }
                }}
              />
            </div>
          </div>
          <div>
            <div>
              <ToggleField
                label="Office"
                color={usageColor(BuildingUse.Office)}
                toggleElementProps={{
                  value: initialValues[VariableId.OfficeToggle],
                  onChange: () => { this.handleToggleChange(VariableId.OfficeToggle) }
                }}
              />
            </div>
            <div>
              <ToggleField
                label="Retail"
                color={usageColor(BuildingUse.Retail)}
                toggleElementProps={{
                  value: initialValues[VariableId.RetailToggle],
                  onChange: () => { this.handleToggleChange(VariableId.RetailToggle) }
                }}
              />
            </div>
            <div>
              <ToggleField
                label="Industrial"
                color={usageColor(BuildingUse.Industrial)}
                toggleElementProps={{
                  value: initialValues[VariableId.IndustrialToggle],
                  onChange: () => { this.handleToggleChange(VariableId.IndustrialToggle) }
                }}
              />
            </div>
          </div>
        </div>
      </>
    )
  }

  /**
   * Render buttons.
   */
  renderButtons = (redirect) => {
    const { initialValues, closePopup } = this.props;

    const allTogglesAreDisabled = !Object.values(BuildingUse).some((buildingUse) => initialValues[`${buildingUse}Toggle`]);

    return (
      <div className="buttons-container">
        <button className="back" onClick={closePopup}>BACK</button>
        <button className={allTogglesAreDisabled ? "disabled" : "continue"} onClick={redirect}>CONTINUE</button>
      </div>
    );
  }

  render() {
    return (
      <RedirectComponent
        path={`${Path.Explorer}/${this.state.projectId}`}
        onClick={this.initializeProject}
      >
        {({ redirect }) => {
          return (
            <div className={`component--initial-values-panel`}>
              {this.renderUsesContainer()}
              {this.renderFloorAreaRatioContainer()}
              <p className="note">
                <span className="bold">Note:</span> The total building area for your project will be based on the FAR (Floor Area Ratio) above.
                The definition of FAR is a multiple of your lot size that defines the total area of the building allowed by zoning.
              </p>
              <p className="bold">On the next screen, you will be able to adjust the project assumptions using the panels on the left menu bar.</p>
              {this.renderButtons(redirect)}
            </div>
          )
        }}
      </RedirectComponent>
    );
  }
}

export default wrapComponentWithPopup(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(InitialValuesPanel)
);
