import actionTypes from "./actionTypes";
import subscriptionActionTypes from "../subscription/actionTypes";
import { Chargebee } from "../../types/Service/Chargebee";

export enum Tier {
  Pro = "pro",
  Advanced = "advanced",
  Standard = "standard",
  None = "none",
}

export interface User {
  location?: [number, number] | null;
  tier?: Tier;
}

const INITIAL_STATE: User = {}

/**
 * Determine which reducer, if any, should handle reducing the given action and
 * state.
 */
const reducer = (previousState: User = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.GET_LOCATION_SUCCESS: return getLocationSuccess(previousState, action.payload);
    case actionTypes.GET_LOCATION_ERROR: return getLocationError(previousState, action.payload);
    case subscriptionActionTypes.LOAD_SUCCESS: return setTier(previousState, action.payload);
    default: return previousState
  }
}

/**
 * See `getLocationSuccess` action creator.
 *
 * Set user's location.
 */
const getLocationSuccess = (previousState, payload): User => {
  let location = payload.location.some((coordinate) => !Number.isFinite(coordinate)) ? null : payload.location;

  return {
    ...previousState,
    location: location,
  }
}

/**
 * See `getLocationError` action creator.
 *
 * Set user's location to null.
 */
const getLocationError = (previousState, payload): User => {
  return {
    ...previousState,
    location: null,
  }
}

/**
 * Set user's plan tier.
 */
const setTier = (previousState, payload): User => {
  let planId = payload.subscription && payload.subscription.plan_id;
  let tier;

  switch (planId) {
    case Chargebee.PlanId.StandardMonthly:
    case Chargebee.PlanId.StandardYearly:
      tier = Tier.Standard;
      break;
    case Chargebee.PlanId.AdvancedMonthly:
    case Chargebee.PlanId.AdvancedYearly:
      tier = Tier.Advanced;
      break;
    case Chargebee.PlanId.ProMonthly:
    case Chargebee.PlanId.ProYearly:
      tier = Tier.Pro;
      break;
    default:
      tier = Tier.None;
      break;
  }

  return {
    ...previousState,
    tier,
  }
}

export default reducer;
