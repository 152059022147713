import developmentAccessors from "../../../../state/development/utils/developmentAccessors";
import { Development } from  "../../../../types/Development/Development";
import { VariableId } from  "../../../../types/VariableId";
import generateLinearRegressionPreset from "./generatorForLinearRegression";

/**
 * Generate the office buildables presets.
 */
const generatePresets = (development: Development, targetArea: number): object => {
  const commonAreaFactor = developmentAccessors.getInputValue(development, VariableId.OfficeCommonAreaFactor) as number;
  const netUsableArea = targetArea * (1 - commonAreaFactor);

  const previousMaximum = developmentAccessors.getInputMaximum(development,  VariableId.OfficeNetUsableAreaToggleable);

  return {
    [VariableId.OfficeNetUsableAreaToggleable]: {
      "minimum": 0,
      "maximum": 2 * netUsableArea || previousMaximum,
      "value": netUsableArea,
    },
    [VariableId.OfficeYearOneRentPerArea]: generateLinearRegressionPreset(development, VariableId.OfficeYearOneRentPerArea),
    [VariableId.OfficeHardCostPerArea]: generateLinearRegressionPreset(development, VariableId.OfficeHardCostPerArea),
  };
}

export default generatePresets;
