import React from "react";
import { Path } from "../../../types/Path";
import RedirectComponent from "../RedirectComponent/RedirectComponent";

type Props = {
  path: Path;
  text: string;
  state?: object;
  onClick?(): void;
  shouldContinue?(): boolean;
}

class RedirectDropdownButton extends React.PureComponent<Props, {}> {
  render() {
    const { path, text, onClick, shouldContinue, state } = this.props;

    return (
      <RedirectComponent path={path} onClick={onClick} shouldContinue={shouldContinue} state={state}>
        {
          ({ redirect }) =>
            <li onClick={redirect}>
              <div className="button">{text}</div>
            </li>
        }
      </RedirectComponent>
    );
  }
};

export default RedirectDropdownButton;
