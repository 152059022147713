import React from "react";
import { connect } from "react-redux";
import { developmentSelectors } from "../../../../../state/development";
import { Panel } from "../../../../../types/uiElements";
import ConfigurationHandler from "../../../../sharedComponents/ConfigurationHandler";
import PanelHeader from "../sharedComponents/PanelHeader";
import SliderBox from "../sharedComponents/SliderBox";
import PanelSectionTitle from "../sharedComponents/PanelSectionTitle";
import config from "./config";

const mapStateToProps = (state) => {
  return {
    unitSystem: developmentSelectors.getUnitSystem(state),
    values: developmentSelectors.getValues(state),
  }
}

interface OwnProps {
  panel: Panel;
  onPanelClose(panel: Panel);
  onPanelPin(panel: Panel);
}

type StateProps = ReturnType<typeof mapStateToProps>;
type Props = OwnProps & StateProps;

class ParkingConstraintsPanel extends React.Component<Props, any>  {
  /**
   * Render Parking After Reduction section.
   */
  renderParkingAfterReductionSection = () => {
    return (
      <>
        <ConfigurationHandler config={config.parkingRequiredSpacesAfterReduction} component={PanelSectionTitle} unitSystem={this.props.unitSystem} />
        <div data-tour="parking-after-reduction">
          <ConfigurationHandler config={config.parkingReductionFactor} component={SliderBox} unitSystem={this.props.unitSystem} />
          <ConfigurationHandler config={config.parkingReductionFeePerSpace} component={SliderBox} unitSystem={this.props.unitSystem} />
          <ConfigurationHandler config={config.parkingSpaceArea} component={SliderBox} unitSystem={this.props.unitSystem} />
        </div>
      </>
    );
  }

  /**
   * Render Parking Requirements section.
   */
  renderParkingRequirementsSection = () => {
    return (
      <div data-tour="parking-requirements">
        <ConfigurationHandler config={config.parkingRequiredSpacesBeforeReduction} component={PanelSectionTitle} unitSystem={this.props.unitSystem} />
        <div>
          <ConfigurationHandler config={config.parkingRatioCondo} component={SliderBox} unitSystem={this.props.unitSystem} />
          <ConfigurationHandler config={config.parkingRatioMultifamily} component={SliderBox} unitSystem={this.props.unitSystem} />
          <ConfigurationHandler config={config.parkingRatioHotel} component={SliderBox} unitSystem={this.props.unitSystem} />
          <ConfigurationHandler config={config.parkingRatioOffice} component={SliderBox} unitSystem={this.props.unitSystem} />
          <ConfigurationHandler config={config.parkingRatioRetail} component={SliderBox} unitSystem={this.props.unitSystem} />
          <ConfigurationHandler config={config.parkingRatioIndustrial} component={SliderBox} unitSystem={this.props.unitSystem} />
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="panel">
        <PanelHeader
          isPinned={this.props.panel.isPinned}
          panel={this.props.panel}
          onPanelClose={this.props.onPanelClose}
          onPanelPin={this.props.onPanelPin}
        />
        <div className="panel-inner-wrapper">
          {this.renderParkingRequirementsSection()}
          {this.renderParkingAfterReductionSection()}
        </div>
      </div>
    );
  }
}

export default connect(
  mapStateToProps
)(ParkingConstraintsPanel);
