import React from "react";
import valueFormatter from "../../../utils/valueFormatter";
import Format from "../../../types/Format";

interface Props {
  isActive?: boolean;
  condoReturnOnCost: number;
  leaseUsesReturnOnCost: number;
}

class ReturnOnCost extends React.PureComponent<Props, {}> {
  render() {
    const { isActive, condoReturnOnCost, leaseUsesReturnOnCost } = this.props;
    if (isActive !== undefined && !isActive) return null;

    const multipleReturnOnCostElements =
      <>
        <div className="value">
          {valueFormatter.format(leaseUsesReturnOnCost, { type: Format.Type.Percentage, decimalPlaces: 2 })}
        </div>
        <div className="secondary">
          <span className="label">Condo:  </span><span className="value">{valueFormatter.format(condoReturnOnCost, { type: Format.Type.Percentage, decimalPlaces: 2 })}</span>
        </div>
      </>;

    const returnOnCost = condoReturnOnCost || leaseUsesReturnOnCost;
    const singleReturnOnCostElement = <div className="value">{valueFormatter.format(returnOnCost, { type: Format.Type.Percentage, decimalPlaces: 2 })}</div>;

    const multipleReturnOnCost = Boolean(condoReturnOnCost && leaseUsesReturnOnCost);
    return (
      <div className="component--return-on-cost">
        <div className="header">Return</div>
        <div className="separator" />
        {multipleReturnOnCost ? multipleReturnOnCostElements : singleReturnOnCostElement}
      </div>
    );
  }
}

export default ReturnOnCost;
