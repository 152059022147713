import React from "react";
import Unit from "../../../../../types/Unit";
import Pdf from "../../../../../types/Pdf";

export type Context = {
  development: any;
  mapImages: Object;
  unitSystem: Unit.System;
  title?: string;
  summary?: string;
  fromContactDetails: Pdf.ContactDetails;
  toContactDetails: Pdf.ContactDetails;
  colorPalette: {
    primary: string;
    secondary: string;
    text: string;
  };
  coverImages: Object;
  address: Pdf.Address;
}

const PdfContext = React.createContext<Context>({
  development: {},
  mapImages: {},
  unitSystem: Unit.System.Imperial,
  coverImages: {},
  colorPalette: {
    primary: "",
    secondary: "",
    text: "",
  },
  fromContactDetails: {},
  toContactDetails: {},
  address: {},
});

export default PdfContext;
