import React from "react";

export default class ForgotPasswordEmailSent extends React.PureComponent<{},{}> {
  render() {
    return (
      <>
        <div className="header">
          Email was sent.
        </div>
        <div className="text-box email-sent">
          We’ve sent you an email with instructions on how to reset your password.
          You may close this window now.
        </div>
      </>
    );
  }
}
