import React from "react";
import { connect } from "react-redux";
import { developmentSelectors } from "../../../../../../../state/development";
import { BuildingUse } from "../../../../../../../types/BuildingUse";
import authentication from "../../../../../../../utils/authentication";
import Cell from "../../../../../../sharedComponents/Cell";
import CellRow from "../../../../../../sharedComponents/CellRow";
import CellsContainer from "../../../../../../sharedComponents/CellsContainer";
import ConfigurationHandler from "../../../../../../sharedComponents/ConfigurationHandler";
import config from "./config";

const mapStateToProps = (state) => {
  return {
    unitSystem: developmentSelectors.getUnitSystem(state),
  }
}

interface OwnProps {
  index: number;
  currentTab: number;
}

type StateProps = ReturnType<typeof mapStateToProps>;
type Props = OwnProps & StateProps;

class BackOfEnvelopeAssumptionsWorksheet extends React.PureComponent<Props, {}> {
  /**
   * Render Site / Building section.
   */
  renderSiteBuildingSection = (userIsAuthenticated) => (
    <>
      <CellRow>
        <Cell text="Site / Building" styleVariation="border-below bold-17" />
        <Cell styleVariation="border-below" />
      </CellRow>
      <CellRow>
        <ConfigurationHandler config={config.floorAreaRatio} component={CellsContainer} valueStyleVariation="bold-15" unitSystem={this.props.unitSystem} hiddenValues={!userIsAuthenticated} isFromBackOfEnvelope />
      </CellRow>
      <CellRow>
        <ConfigurationHandler config={config.unitsPerParcelArea} component={CellsContainer} valueStyleVariation="bold-15" unitSystem={this.props.unitSystem} hiddenValues={!userIsAuthenticated} isFromBackOfEnvelope />
      </CellRow>
      <CellRow>
        <ConfigurationHandler config={config.projectNumberOfFloors} component={CellsContainer} valueStyleVariation="bold-15" unitSystem={this.props.unitSystem} hiddenValues={!userIsAuthenticated} isFromBackOfEnvelope />
      </CellRow>
      <CellRow
        classes="break"
      />
    </>
  )

  /**
   * Render Land Cost section.
   */
  renderLandCostSection = (userIsAuthenticated) => (
    <>
      <CellRow>
        <Cell text="Land Cost" styleVariation="border-below bold-17" />
        <Cell styleVariation="border-below" />
      </CellRow>
      <CellRow>
        <ConfigurationHandler config={config.parcelArea} component={CellsContainer} valueStyleVariation="bold-15" unitSystem={this.props.unitSystem} hiddenValues={!userIsAuthenticated} isFromBackOfEnvelope />
      </CellRow>
      <CellRow>
        <ConfigurationHandler config={config.parcelPurchasePrice} component={CellsContainer} valueStyleVariation="bold-15" unitSystem={this.props.unitSystem} hiddenValues={!userIsAuthenticated} isFromBackOfEnvelope />
      </CellRow>
      <CellRow>
        <ConfigurationHandler config={config.parcelPurchasePricePerArea} component={CellsContainer} valueStyleVariation="bold-15" unitSystem={this.props.unitSystem} hiddenValues={!userIsAuthenticated} isFromBackOfEnvelope />
      </CellRow>
      <CellRow
        classes="break"
      />
    </>
  )

  /**
   * Render Building Cost section.
   */
  renderBuildingCostSection = (userIsAuthenticated) => (
    <>
      <CellRow>
        <Cell text="Building Cost" styleVariation="border-below bold-17" />
        <Cell styleVariation="border-below" />
      </CellRow>
      <CellRow>
        <ConfigurationHandler config={config.projectConstructionCostIncludingDemolition} component={CellsContainer} valueStyleVariation="bold-15" unitSystem={this.props.unitSystem} hiddenValues={!userIsAuthenticated} isFromBackOfEnvelope />
      </CellRow>
      <CellRow>
        <ConfigurationHandler config={config.projectGrossBuildableArea} component={CellsContainer} valueStyleVariation="bold-15" unitSystem={this.props.unitSystem} hiddenValues={!userIsAuthenticated} isFromBackOfEnvelope />
      </CellRow>
      <CellRow>
        <ConfigurationHandler config={config.projectConstructionCostIncludingDemolitionPerBuildableArea} component={CellsContainer} valueStyleVariation="bold-15" unitSystem={this.props.unitSystem} hiddenValues={!userIsAuthenticated} isFromBackOfEnvelope />
      </CellRow>
      <CellRow
        classes="break"
      />
    </>
  )

  /**
   * Render Areas section.
   */
  renderAreasSection = (userIsAuthenticated) => (
    <>
      <CellRow>
        <Cell text="Areas" styleVariation="border-below bold-17" />
        <Cell styleVariation="border-below" />
      </CellRow>
      <CellRow usageGroup={BuildingUse.Multifamily}>
        <ConfigurationHandler config={config.multifamilyTotalUnitQuantity} component={CellsContainer} valueStyleVariation="bold-15" unitSystem={this.props.unitSystem} hiddenValues={!userIsAuthenticated} isFromBackOfEnvelope />
      </CellRow>
      <CellRow usageGroup={BuildingUse.Condo}>
        <ConfigurationHandler config={config.condoTotalUnitQuantity} component={CellsContainer} valueStyleVariation="bold-15" unitSystem={this.props.unitSystem} hiddenValues={!userIsAuthenticated} isFromBackOfEnvelope />
      </CellRow>
      <CellRow usageGroup={BuildingUse.Hotel}>
        <ConfigurationHandler config={config.hotelRoomQuantity} component={CellsContainer} valueStyleVariation="bold-15" unitSystem={this.props.unitSystem} hiddenValues={!userIsAuthenticated} isFromBackOfEnvelope />
      </CellRow>
      <CellRow usageGroup={BuildingUse.Office}>
        <ConfigurationHandler config={config.officeGrossLeasableArea} component={CellsContainer} valueStyleVariation="bold-15" unitSystem={this.props.unitSystem} hiddenValues={!userIsAuthenticated} isFromBackOfEnvelope />
      </CellRow>
      <CellRow usageGroup={BuildingUse.Retail}>
        <ConfigurationHandler config={config.retailNetLeasableArea} component={CellsContainer} valueStyleVariation="bold-15" unitSystem={this.props.unitSystem} hiddenValues={!userIsAuthenticated} isFromBackOfEnvelope />
      </CellRow>
      <CellRow usageGroup={BuildingUse.Industrial}>
        <ConfigurationHandler config={config.industrialNetLeasableArea} component={CellsContainer} valueStyleVariation="bold-15" unitSystem={this.props.unitSystem} hiddenValues={!userIsAuthenticated} isFromBackOfEnvelope />
      </CellRow>
      <CellRow>
        <ConfigurationHandler config={config.parkingRequiredSpacesAfterReduction} component={CellsContainer} valueStyleVariation="bold-15" unitSystem={this.props.unitSystem} hiddenValues={!userIsAuthenticated} isFromBackOfEnvelope />
      </CellRow>
      <CellRow
        classes="break"
      />
    </>
  )

  /**
   * Render Condo Economics section.
   */
  renderCondoEconomicsSection = (userIsAuthenticated) => (
    <>
      <CellRow usageGroup={BuildingUse.Condo} >
        <Cell text="Condo Economics" styleVariation="border-below bold-17" />
        <Cell styleVariation="border-below" />
      </CellRow>
      <CellRow usageGroup={BuildingUse.Condo}>
        <ConfigurationHandler config={config.condoAverageGrossSalesPerSellableArea} component={CellsContainer} valueStyleVariation="bold-15" unitSystem={this.props.unitSystem} hiddenValues={!userIsAuthenticated} isFromBackOfEnvelope />
      </CellRow>
      <CellRow usageGroup={BuildingUse.Condo}>
        <ConfigurationHandler config={config.condoSaleCommissionPercentage} component={CellsContainer} valueStyleVariation="bold-15" unitSystem={this.props.unitSystem} hiddenValues={!userIsAuthenticated} isFromBackOfEnvelope />
      </CellRow>
      <CellRow
        classes="break"
        usageGroup={BuildingUse.Condo}
      />
    </>
  )

  /**
   * Render Lease Economics section.
   */
  renderLeaseEconomicsSection = (userIsAuthenticated) => (
    <>
      <CellRow usageGroup={BuildingUse.RentalUses} >
        <Cell text="Lease Economics" styleVariation="border-below bold-17" />
        <Cell styleVariation="border-below" />
      </CellRow>
      <CellRow usageGroup={BuildingUse.Multifamily}>
        <ConfigurationHandler config={config.multifamilyAverageYearOnePotentialGrossMonthlyRentalIncomePerLeasableArea} component={CellsContainer} valueStyleVariation="bold-15" unitSystem={this.props.unitSystem} hiddenValues={!userIsAuthenticated} isFromBackOfEnvelope />
      </CellRow>
      <CellRow usageGroup={BuildingUse.Office}>
        <ConfigurationHandler config={config.officeYearOneRentPerArea} component={CellsContainer} valueStyleVariation="bold-15" unitSystem={this.props.unitSystem} hiddenValues={!userIsAuthenticated} isFromBackOfEnvelope />
      </CellRow>
      <CellRow usageGroup={BuildingUse.Retail}>
        <ConfigurationHandler config={config.retailYearOneRentPerArea} component={CellsContainer} valueStyleVariation="bold-15" unitSystem={this.props.unitSystem} hiddenValues={!userIsAuthenticated} isFromBackOfEnvelope />
      </CellRow>
      <CellRow usageGroup={BuildingUse.Industrial}>
        <ConfigurationHandler config={config.industrialYearOneRentPerArea} component={CellsContainer} valueStyleVariation="bold-15" unitSystem={this.props.unitSystem} hiddenValues={!userIsAuthenticated} isFromBackOfEnvelope />
      </CellRow>
      <CellRow
        classes="break"
        usageGroup={BuildingUse.RentalUses}
      />
    </>
  )

  /**
   * Render Hotel Economics section.
   */
  renderHotelEconomicsSection = (userIsAuthenticated) => (
    <>
      <CellRow usageGroup={BuildingUse.Hotel} >
        <Cell text="Hotel Economics" styleVariation="bold-17 border-below" />
        <Cell styleVariation="border-below" />
      </CellRow>
      <CellRow usageGroup={BuildingUse.Hotel}>
        <ConfigurationHandler config={config.hotelStabilizedAverageDailyRate} component={CellsContainer} valueStyleVariation="bold-15" unitSystem={this.props.unitSystem} hiddenValues={!userIsAuthenticated} isFromBackOfEnvelope />
      </CellRow>
      <CellRow usageGroup={BuildingUse.Hotel}>
        <ConfigurationHandler config={config.hotelStabilizedOccupancyPercentage} component={CellsContainer} valueStyleVariation="bold-15" unitSystem={this.props.unitSystem} hiddenValues={!userIsAuthenticated} isFromBackOfEnvelope />
      </CellRow>
      <CellRow usageGroup={BuildingUse.Hotel}>
        <ConfigurationHandler config={config.hotelStabilizedRevenuePerAvailableRoom} component={CellsContainer} valueStyleVariation="bold-15" unitSystem={this.props.unitSystem} hiddenValues={!userIsAuthenticated} isFromBackOfEnvelope />
      </CellRow>
      <CellRow
        classes="break"
        usageGroup={BuildingUse.Hotel}
      />
    </>
  )

  render() {
    if (this.props.index !== this.props.currentTab) return null;
    const userIsAuthenticated = authentication.isUserAuthenticated();

    return (
      <table>
        <tbody>
          {this.renderSiteBuildingSection(userIsAuthenticated)}
          {this.renderLandCostSection(userIsAuthenticated)}
          {this.renderBuildingCostSection(userIsAuthenticated)}
          {this.renderAreasSection(userIsAuthenticated)}
          {this.renderCondoEconomicsSection(userIsAuthenticated)}
          {this.renderLeaseEconomicsSection(userIsAuthenticated)}
          {this.renderHotelEconomicsSection(userIsAuthenticated)}
        </tbody>
      </table>
    )
  }
};

export default connect(mapStateToProps)(BackOfEnvelopeAssumptionsWorksheet);
