import React from "react";
import MapboxGeocoder from "../../../sharedComponents/MapboxGeocoder";
import ParcelTools from "./ParcelTools";

class SearchBar extends React.PureComponent<{}, {}>{
  render() {
    return (
      <div className="component--search-bar">
        <MapboxGeocoder />
        <ParcelTools />
      </div>
    )
  }
}

export default SearchBar;
