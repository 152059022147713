import React from "react";
import styled from "@react-pdf/styled-components";
import PdfContext from "../../../PdfContext";
import companyInfo from "../../../../../../../utils/companyInfo";

const Container = styled.View`
  display: flex;
  flex-grow: 1;
  margin-top: 35px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  font-family: "Roboto";
`;

const Label = styled.Text`
  padding-bottom: 2px;
  border-bottom: 1px;
  border-color: ${(props) => props.borderColor};
  font-family: "Roboto Bold";
  font-size: 17px;
`;

const SummaryText = styled.Text`
  flex-grow: 1;
  flex-shrink: 1;
  margin-top: 10px;
  font-size: 8px;
  font-weight: normal;
  letter-spacing: 0.25px;
  line-height: 1.7px;
`;

const CompanyLink = styled.Link`
  color: red;
`;

const renderDefaultSummary = () => {
  return (
    <SummaryText>
      {"The following report was generated with the use of the Deepblocks platform, which integrates the data, calculations, visualization, and return analysis of any real property. Deepblocks uses artificial intelligence to get smarter about each market, periodically adjusting proprietary global indexes to improve the market data provided. \n\n\
      Deepblocks is a PropTech company coming out of the Singularity University accelerator, with a goal to make real estate investment analysis simple and accessible to all investors. \n\n\
      If you'd like to know more about creating your own report please visit our website: "
      }<CompanyLink src={companyInfo.WEBSITE_URL}>deepblocks.com</CompanyLink>
    </SummaryText>
  );
}

const Summary = () => (
  <PdfContext.Consumer>
    {({ summary, colorPalette }) => {
      return (
        <Container>
          <Label borderColor={colorPalette.secondary}>SUMMARY</Label>
          {summary
            ? <SummaryText>{summary}</SummaryText>
            : renderDefaultSummary()
          }
        </Container>
      )
    }}
  </PdfContext.Consumer>
);

export default Summary;
