import React from "react";
import parcelAccessors from "../../../../../utils/parcel/parcelAccessors";
import authentication from "../../../../../utils/authentication";
import CellRow from "../../../CellRow";
import Cell from "../../../Cell";

type OwnProps = {
  parcelFeature: any;
}

type Props = OwnProps;

/**
 * Render parking ratio section.
 */
const ParkingRatio = (props: Props) => {
  const { parcelFeature } = props;
  const userIsAuthenticated = authentication.isUserAuthenticated();

  const parkingRatio = {
    residential: parcelAccessors.getResidentialParkingRatio(parcelFeature),
    singleFamily: parcelAccessors.getSingleFamilyParkingRatio(parcelFeature),
    hotel: parcelAccessors.getHotelParkingRatio(parcelFeature),
    retail: parcelAccessors.getRetailParkingRatio(parcelFeature),
    office: parcelAccessors.getOfficeParkingRatio(parcelFeature),
    industrial: parcelAccessors.getIndustrialParkingRatio(parcelFeature),
  }

  if (Object.values(parkingRatio).every((use) => use.length === 0)) return null;

  return (
    <table>
      <thead>
        <tr>
          <th colSpan={2}>Parking by Use</th>
        </tr>
      </thead>
      <tbody>
        {parkingRatio.residential.length > 0
            ? <CellRow>
                <Cell text="Residential" />
                <Cell value={parkingRatio.residential} styleVariation="bold capitalize" hiddenValues={!userIsAuthenticated} />
              </CellRow>
            : null
        }
        {parkingRatio.singleFamily.length > 0
            ? <CellRow>
                <Cell text="Single Family" />
                <Cell value={parkingRatio.singleFamily} styleVariation="bold capitalize" hiddenValues={!userIsAuthenticated} />
              </CellRow>
            : null
        }
        {parkingRatio.hotel.length > 0
            ? <CellRow>
                <Cell text="Hotel" />
                <Cell value={parkingRatio.hotel} styleVariation="bold capitalize" hiddenValues={!userIsAuthenticated} />
              </CellRow>
            : null
        }
        {parkingRatio.retail.length > 0
            ? <CellRow>
                <Cell text="Retail" />
                <Cell value={parkingRatio.retail} styleVariation="bold capitalize" hiddenValues={!userIsAuthenticated} />
              </CellRow>
            : null
        }
        {parkingRatio.office.length > 0
            ? <CellRow>
                <Cell text="Office" />
                <Cell value={parkingRatio.office} styleVariation="bold capitalize" hiddenValues={!userIsAuthenticated} />
              </CellRow>
            : null
        }
        {parkingRatio.industrial.length > 0
            ? <CellRow>
                <Cell text="Industrial" />
                <Cell value={parkingRatio.industrial} styleVariation="bold capitalize" hiddenValues={!userIsAuthenticated} />
              </CellRow>
            : null
        }
      </tbody>
    </table>
  );
}

export default ParkingRatio;
