/**
 * @fileoverview This file is for typing error messages related to authentication.
 */

/**
 * This enum corresponds to firebase authentication error codes.
 * See: https://firebase.google.com/docs/auth/admin/errors
 */
export enum FirebaseErrorCode {
  ExpiredActionCode = "auth/expired-action-code",
  InvalidActionCode = "auth/invalid-action-code",
  UserDisabled = "auth/user-disabled",
  UserNotFound = "auth/user-not-found",
  WrongPassword = "auth/wrong-password",
  WeakPassword = "auth/weak-password",
  AccountExistsWithDifferentCredential = "auth/account-exists-with-different-credential",
  AuthDomainConfigRequired = "auth/auth-domain-config-required",
  CredentialAlreadyInUse = "auth/credential-already-in-use",
  EmailAlreadyInUse = "auth/email-already-in-use",
  OperationNotAllowed = "auth/operation-not-allowed",
  OperationNotSupportedInThisEnvironment = "auth/operation-not-supported-in-this-environment",
  Timeout = "auth/timeout",
  InvalidEmail = "auth/invalid-email",
  MissingAndroidPkgName = "auth/missing-android-pkg-name",
  MissingContinueUri = "auth/missing-continue-uri",
  MissingIosBundleId = "auth/missing-ios-bundle-id",
  InvalidContinueUri = "auth/invalid-continue-uri",
  UnauthorizedContinueUri = "auth/unauthorized-continue-uri",
  ArgumentError = "auth/argument-error",
  InvalidPersistenceType = "auth/invalid-persistence-type",
  UnsupportedPersistenceType = "auth/unsupported-persistence-type",
  InvalidCredential = "auth/invalid-credential",
  InvalidVerificationCode = "auth/invalid-verification-code",
  InvalidVerificationId = "auth/invalid-verification-id",
  CustomTokenMismatch = "auth/custom-token-mismatch",
  InvalidCustomToken = "auth/invalid-custom-token",
  CaptchaCheckFailed = "auth/captcha-check-failed",
  InvalidPhoneNumber = "auth/invalid-phone-number",
  MissingPhoneNumber = "auth/missing-phone-number",
  QuotaExceeded = "auth/quota-exceeded",
  CancelledPopupRequest = "auth/cancelled-popup-request",
  PopupBlocked = "auth/popup-blocked",
  PopupClosedByUser = "auth/popup-closed-by-user",
  UnauthorizedDomain = "auth/unauthorized-domain",
  InvalidUserToken = "auth/invalid-user-token",
  UserTokenExpired = "auth/user-token-expired",
  NullUser = "auth/null-user",
  NetworkRequestFailed = "auth/network-request-failed",
  TooManyRequests = "auth/too-many-requests",
}

export enum CustomErrorCode {
  MissingFields = "missingFields",
  TermsOfServiceNotAccepted = "termsOfServiceNotAccepted",
}

export type ErrorCode = CustomErrorCode | FirebaseErrorCode;
