import React from "react";
import styled from "@react-pdf/styled-components";
import addressIcon from "../../../../../../../assets/images/pdf-icon-property-info.png";
import PdfContext from "../../../PdfContext";

const Container = styled.View`
  height: 25px;
  width: 180px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const AddressIcon = styled.Image`
  height: 15px;
  margin-right: 10px;
`;

const AddressContainer = styled.View`
  height: 100%;
  flex: 1;
  color: ${(props) => props.color};
`;

const AddressText = styled.Text`
  font-family: "Roboto Bold";
  font-size: 9px;
  text-transform: uppercase;
  letter-spacing: 0.25px;
  margin: auto 0;
`;

interface Props {
  textColor: string;
}

const AddressBar = (props: Props) => (
  <PdfContext.Consumer>
    {({ address }) => {
      const streetAddress = address.address ? `${address.address}\n` : "";
      const city = address.city ? `${address.city}, ` : "";
      const state = address.state || "";
      const zipCode = address.zipCode || "";

      return (
        <Container>
          <AddressIcon src={addressIcon} />
          <AddressContainer color={props.textColor}>
            <AddressText>{`${streetAddress}${city}${state} ${zipCode}`}</AddressText>
          </AddressContainer>
        </Container>
      );
    }}
  </PdfContext.Consumer>
);

export default AddressBar;
