import React from "react";
import { Feature, Layer } from "react-mapbox-gl";
import { connect } from "react-redux";
import { developmentSelectors } from "../../../../state/development";
import Pdf from "../../../../types/Pdf";
import { ReactMapboxGlCamera } from "../../../../types/ReactMapboxGlCamera";
import turf from "../../../../utils/turf";
import mapboxPresentationProperties from "../../../utils/mapboxPresentationProperties";
import { MapStyleProperties } from "../../../../utils/mapbox/mapStyleProperties";
import Map from "../sharedComponents/Map";

const DEFAULT_ZOOM = 11;

const VISIBLE_LAYERS = [
  MapStyleProperties.LayerId.UsaOpportunityZoneFill,
];

interface Props {
  parcelGeoJson: turf.AllGeoJSON;
}

class MapLocation extends React.PureComponent<Props, {}> {
  camera: ReactMapboxGlCamera;

  constructor(props) {
    super(props);

    this.camera = {
      zoom: [DEFAULT_ZOOM],
      pitch: [0],
      bearing: [0],
      center: turf.getCoord(turf.centerOfMass(this.props.parcelGeoJson)) as [number, number],
    }
  }

  render() {
    return (
      <Map
        pdfImageType={Pdf.ImageType.Location}
        camera={this.camera}
        mapStyleUrl={MapStyleProperties.StyleUrl.Streets}
        visibleLayers={VISIBLE_LAYERS}
      >
        <Layer type="symbol" layout={mapboxPresentationProperties.mapPinLayout}>
          <Feature coordinates={turf.getCoord(turf.centerOfMass(this.props.parcelGeoJson))} />
        </Layer>
      </Map >
    )
  }
}

const mapStateToProps = (state) => {
  return {
    parcelGeoJson: developmentSelectors.getParcel(state),
  };
}

export default connect(
  mapStateToProps,
)(MapLocation);
