import React from "react";

export default class Sidebar extends React.PureComponent<{}, {}>  {
  render() {
    return (
      <div className="component--sidebar">
        {this.props.children}
      </div>
    );
  }
}
