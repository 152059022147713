import React from "react";
import { connect } from "react-redux";
import { newDevelopmentActions, newDevelopmentSelectors } from "../../../state/newDevelopment";
import { userActions, userSelectors } from "../../../state/user";
import { KeyCode } from "../../../types/KeyCodes";
import { SlideshowType } from "../../../types/Slideshow";
import database from "../../../utils/database/database";
import authentication from "../../../utils/authentication";
import NewProjectMap from "./NewProjectMap";
import NewProjectToolbar from "./NewProjectToolbar";
import ParcelInformationPanel from "./ParcelInformationPanel";
import SearchBar from "./SearchBar";
import SmartSearchPanel from "./SmartSearchPanel";
import WelcomeSlideshow from "./WelcomeSlideshow";
import DrawParcelInformationPanel from "./DrawParcelInformationPanel";
import BusyPopup from "../../sharedComponents/BusyPopup";
import analytics from "../../../utils/analytics";
import { Path } from "../../../types/Path";

const mapStateToProps = (state) => {
  return {
    userLocation: userSelectors.getLocation(state),
    zoningDataInViewport: newDevelopmentSelectors.getZoningDataInViewport(state),
    parcelDataInViewport: newDevelopmentSelectors.getParcelDataInViewport(state),
  }
}

const mapDispatchToProps = {
  initializeNewDevelopment: newDevelopmentActions.initialize,
  modifierKeyDown: newDevelopmentActions.modifierKeyDown,
  modifierKeyUp: newDevelopmentActions.modifierKeyUp,
  getUserLocation: userActions.getLocationStart,
  setDefaultPanel: newDevelopmentActions.setDefaultPanel,
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;
type Props = StateProps & DispatchProps;

interface State {
  initialized: boolean;
}

class NewProjectPage extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      initialized: false,
    }

    analytics.trackPageview(Path.NewProject);
  }

  /**
   * Set default panel once the parcelDataInViewport and zoningDataInViewport flags are ready.
   */
  componentDidUpdate(previousProps: Props) {
    if (
      this.props.parcelDataInViewport !== null
          && this.props.zoningDataInViewport !== null
          && (previousProps.parcelDataInViewport === null
              || previousProps.zoningDataInViewport === null)
    ) {
      const userIsAuthenticated = authentication.isUserAuthenticated();
      !userIsAuthenticated && this.props.setDefaultPanel();
    }
  }

  /**
   * Ensure this.initialize is called once project is loaded from database.
   */
  componentDidMount() {
    this.props.initializeNewDevelopment();
    if (!database.isInitialized()) {
      database.onInitialized(this.initialize);
    } else {
      this.initialize();
    }

    if (this.props.userLocation === undefined) {
      this.props.getUserLocation();
    }
  }

  /**
   * Ensure this.initialize is not called after this component has been
   * unmounted.
   */
  componentWillUnmount() {
    database.removeOnInitializedCallback(this.initialize);
  }

  /**
   * Initialize NewProjectPage. This function should be called once the database
   * has loaded.
   */
  initialize = () => {
    // TODO: Remove this method and `initialized` state property.
    // Also, remove the registering and un-registering of database callbacks
    // on both componentDidMount and ComponentWillUnmount.
    // This is a temporary patch while the database logic gets reduxified.
    this.setState({
      initialized: true,
    })
  }

  render() {
    if (!this.state.initialized) return null;
    const { zoningDataInViewport, parcelDataInViewport } = this.props;

    const userIsAuthenticated = authentication.isUserAuthenticated();
    const slideshowDataIsReady = zoningDataInViewport !== null && parcelDataInViewport !== null;
    const slideShowType = zoningDataInViewport
        ? SlideshowType.WithZoning
        : (parcelDataInViewport
            ? SlideshowType.WithoutZoning
            : SlideshowType.OnlyDrawing
        );

    return (
      <div className="component--new-project-page"
        // https://stackoverflow.com/questions/32255075/react-not-responding-to-key-down-event/32445016
        // https://webaim.org/techniques/keyboard/tabindex
        tabIndex={-1}
        onKeyDown={(event) => this.props.modifierKeyDown(event.key as KeyCode)}
        onKeyUp={(event) => this.props.modifierKeyUp(event.key as KeyCode)}
      >
        <NewProjectToolbar />

        <div className="content">
          <div className="search-bar-wrapper">
            <SearchBar />
            <ParcelInformationPanel />
            <SmartSearchPanel />
            <DrawParcelInformationPanel />
          </div>
          <NewProjectMap slideshowDataIsReady={slideshowDataIsReady} />
          {!userIsAuthenticated
              ? slideshowDataIsReady
                  ? <WelcomeSlideshow slideShowType={slideShowType} />
                  : <BusyPopup text="Loading Map..." preventClose />
              : null
          }
        </div>
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewProjectPage);
