
export type Options = {
  units?: string;
  negate?: boolean;
  decimalPlaces?: number;
  suffix?: string;
  prefix?: string;
  hidden?: boolean;
  type: Type;
  abbreviate?: boolean;
};

export enum Type {
  Currency = "currency",
  PlainText = "plain text",
  Percentage = "percentage",
  Number = "number",
  PhoneNumber = "phoneNumber",
}
