import { ThumbnailState } from "./reducers";

/**
 * Get the thumbnail object.
 */
const getThumbnail = (state, id): ThumbnailState => {
  let thumbnail: ThumbnailState = state.thumbnails.collection[id];
  return thumbnail;
}

/**
 * Get the thumbnail imageUrl.
 */
const getImageUrl = (state, id): string | null => {
  let thumbnail = getThumbnail(state, id);
  if (thumbnail && thumbnail.imageUrl) return thumbnail.imageUrl;
  return null;
}

/**
 * Get the thumbnail isReady value.
 */
const getIsReady = (state, id): boolean => {
  let thumbnail = getThumbnail(state, id);
  return Boolean(thumbnail && thumbnail.isReady);
}

/**
 * Get the thumbnail exists value.
 */
const getExists = (state, id): boolean => {
  let thumbnail = getThumbnail(state, id);
  return Boolean(thumbnail && thumbnail.exists);
}

export default {
  getImageUrl,
  getIsReady,
  getExists
}
