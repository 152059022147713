import developmentAccessors from "../../../../state/development/utils/developmentAccessors";
import { Development } from  "../../../../types/Development/Development";
import { VariableId } from  "../../../../types/VariableId";
import generateLinearRegressionPreset from "./generatorForLinearRegression";

/**
 * Generate the condo buildables presets. Only one bedroom units will be initialized to non zero values.
 */
const generatePresets = (development: Development, targetArea: number): object => {
  const lossFactor = developmentAccessors.getInputValue(development, VariableId.CondoLossFactor) as number;
  const unitArea = developmentAccessors.getInputValue(development, VariableId.CondoTwoBedArea) as number;

  const microUnitBedroomArea = developmentAccessors.getInputValue(development, VariableId.CondoMicrounitArea) as number;
  const studioBedroomArea = developmentAccessors.getInputValue(development, VariableId.CondoStudioArea) as number;
  const oneBedroomArea = developmentAccessors.getInputValue(development, VariableId.CondoOneBedArea) as number;
  const twoBedroomArea = developmentAccessors.getInputValue(development, VariableId.CondoTwoBedArea) as number;
  const threeBedroomArea = developmentAccessors.getInputValue(development, VariableId.CondoThreeBedArea) as number;

  const usableArea = (targetArea * (1 - lossFactor));
  let amenitiesArea = usableArea * 0.1;

  // Guard against amenities consuming all of the usable area.
  if (usableArea - amenitiesArea < unitArea) amenitiesArea = 0;

  const areaAvailableForUnits = usableArea ? usableArea - amenitiesArea : 0;
  const unitQuantity = unitArea ? Math.floor(areaAvailableForUnits / unitArea) : 0;
  const leftoverArea = areaAvailableForUnits - (unitQuantity * unitArea);
  amenitiesArea += leftoverArea;

  const previousMaximumUnits = developmentAccessors.getInputMaximum(development,  VariableId.CondoOneBedQuantityToggleable);
  const previousMaximumAmenities = developmentAccessors.getInputMaximum(development,  VariableId.CondoAmenitiesAreaToggleable);

  return {
    [VariableId.CondoOneBedQuantityToggleable]: {
      "minimum":  0,
      "value":    0,
      "maximum":  2 * unitQuantity || previousMaximumUnits
    },
    [VariableId.CondoMicrounitQuantityToggleable]: {
      "minimum":  0,
      "value":    0,
      "maximum":  2 * unitQuantity || previousMaximumUnits
    },
    [VariableId.CondoStudioQuantityToggleable]: {
      "minimum":  0,
      "value":    0,
      "maximum":  2 * unitQuantity || previousMaximumUnits
    },
    [VariableId.CondoTwoBedQuantityToggleable]: {
      "minimum":  0,
      "value":    unitQuantity,
      "maximum":  2 * unitQuantity || previousMaximumUnits
    },
    [VariableId.CondoThreeBedQuantityToggleable]: {
      "minimum":  0,
      "value":    0,
      "maximum":  2 * unitQuantity || previousMaximumUnits
    },
    [VariableId.CondoAmenitiesAreaToggleable]: {
      "minimum":  0,
      "value": amenitiesArea,
      "maximum": 10 * amenitiesArea || previousMaximumAmenities
    },
    [VariableId.CondoSalePricePerMicrounit]: generateLinearRegressionPreset(development, VariableId.CondoSalePricePerMicrounit, microUnitBedroomArea),
    [VariableId.CondoSalePricePerStudio]: generateLinearRegressionPreset(development, VariableId.CondoSalePricePerStudio, studioBedroomArea),
    [VariableId.CondoSalePricePerOneBed]: generateLinearRegressionPreset(development, VariableId.CondoSalePricePerOneBed, oneBedroomArea),
    [VariableId.CondoSalePricePerTwoBed]: generateLinearRegressionPreset(development, VariableId.CondoSalePricePerTwoBed, twoBedroomArea),
    [VariableId.CondoSalePricePerThreeBed]: generateLinearRegressionPreset(development, VariableId.CondoSalePricePerThreeBed, threeBedroomArea),
    [VariableId.CondoHardCostPerArea]: generateLinearRegressionPreset(development, VariableId.CondoHardCostPerArea),
  };
}

export default generatePresets;
