import React from "react";
import { connect } from "react-redux";
import { GeoJSONLayer } from "react-mapbox-gl";
import { newDevelopmentSelectors } from "../../../../../state/newDevelopment";
import mapboxPresentationProperties from "../../../../utils/mapboxPresentationProperties";

interface Props {
  hoveredFeature: any;
}

class HoveredFeatureLayer extends React.PureComponent<Props, {}> {
  render() {
    return (
      <GeoJSONLayer
        data={{
          type: "FeatureCollection",
          features: this.props.hoveredFeature ? [this.props.hoveredFeature] : []
        }}
        fillPaint={mapboxPresentationProperties.parcelHoverFillPaint}
        linePaint={mapboxPresentationProperties.parcelHoverLinePaint}
      />
    )
  }
}

const mapStateToProps = (state) => {
  return ({
    hoveredFeature: newDevelopmentSelectors.getHoveredFeature(state)
  })
}

export default connect(
  mapStateToProps
)(HoveredFeatureLayer);
