import React from "react";
import PdfContext from "../../../PdfContext";
import ContactDetails from "../../sharedComponents/ContactDetails";

const ToContactDetails = () => (
  <PdfContext.Consumer>
    {({ colorPalette, toContactDetails }) => (
      <ContactDetails
        contactDetails={toContactDetails}
        label="Prepared for:"
        textColor="#333333"
        labelColor={colorPalette.secondary}
        labelHasBorder
      />
    )}
  </PdfContext.Consumer>
);

export default ToContactDetails;
