import React from "react";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import wrapComponentWithPopup from "../../../../sharedComponents/wrapComponentWithPopup";
import { PopupProps } from "../../../../sharedComponents/wrapComponentWithPopup/wrapComponentWithPopup";
import { developmentSelectors } from "../../../../../state/development";
import { pdfSelectors } from "../../../../../state/pdf";
import developmentUiHelper from "../../../../utils/developmentUiHelper";
import database from "../../../../../utils/database/database";
import { Path } from "../../../../../types/Path";
import analytics from "../../../../../utils/analytics";

const mapStateToProps = (state) => {
  return {
    developmentName: developmentSelectors.getName(state),
    pdfDocument: pdfSelectors.getPdfDocument(state),
  }
}

interface OwnProps {
  closePopup(): void;
}

type StateProps = ReturnType<typeof mapStateToProps>;
type RouterProps = RouteComponentProps<{ projectId: string }>;
type Props = OwnProps & StateProps & RouterProps & PopupProps;

interface State {
  projectName: string;
  formSubmitting: boolean;
}

class CopyProjectPopup extends React.PureComponent<Props, State>  {
  constructor(props) {
    super(props);

    this.state = {
      projectName: `Copy of ${developmentUiHelper.getDisplayName(this.props.developmentName)}`,
      formSubmitting: false,
    }
  }

  /**
   * Return true if the submit button is disabled.
   */
  buttonDisabled = () => {
    return this.state.formSubmitting;
  }

  /**
   * Handle input change.
   */
  handleInputChange = (event) => {
    this.setState({ projectName: event.target.value });
  }

  /**
   * Make a copy of the current project.
   */
  handleFormSubmit = async (event) => {
    event.preventDefault();
    this.setState({ formSubmitting: true });
    if (this.props.closePopup) this.props.closePopup();

    await database.saveCurrentProject();
    let newProjectId = await database.duplicateProject(this.state.projectName, this.props.pdfDocument);
    this.props.history.push(`${Path.Explorer}/${newProjectId}`);
    analytics.trackDuplicateProject(newProjectId);
  }

  render() {
    const projectName = developmentUiHelper.getDisplayName(this.props.developmentName);
    return (
      <div className="component--copy-project-popup">
        <div className="top">
          <div className="header">{`${projectName} Project`}</div>
          <div className="top-separator" />
          <div className="text">This project will be added to your Dashboard</div>
        </div>

        <form onSubmit={this.handleFormSubmit}>
          <div className="input-container">
            <label>Name:</label>
            <input
              type="text"
              value={this.state.projectName}
              onChange={this.handleInputChange}
              placeholder={developmentUiHelper.EMPTY_NAME_PLACEHOLDER}
            />
          </div>

          <button
            type="submit"
            disabled={this.buttonDisabled()}
          >
            Duplicate Project
          </button>
        </form>
      </div>
    );
  }
}

export default wrapComponentWithPopup(
  withRouter(
    connect(mapStateToProps)(CopyProjectPopup)
  )
);
