import Pdf from "../../types/Pdf";
import { ReactMapboxGlCamera } from "../../types/ReactMapboxGlCamera";
import actionTypes from "./actionTypes";
import imagesAreReady from "./utils/imagesAreReadyHelper";
import { developmentActionTypes } from "../development";
import getPdfDocument from "./utils/defaultPdfHelper";

export interface PdfState {
  isActive: boolean;
  isReady?: boolean;
  camera?: ReactMapboxGlCamera,
  mapImages?: {
    [Pdf.ImageType.Building]?: string | null,
    [Pdf.ImageType.Location]?: string | null,
    [Pdf.ImageType.Parcel]?: string | null
    [Pdf.ImageType.Cover]?: string | null;
  }
  coverPageImages: {
    [Pdf.ImageType.Cover]: Pdf.Image | null;
    [Pdf.ImageType.Logo]: Pdf.Image | null;
  }
  title: string;
  summary: string;
  fromContactDetails: Pdf.ContactDetails;
  toContactDetails: Pdf.ContactDetails;
  colorPalette: {
    primary: string;
    secondary: string;
    text: string;
  };
  address: Pdf.Address;
}

const INITIAL_STATE: PdfState = {
  isActive: false,
  ...getPdfDocument(),
};

/**
 * Determine which reducer, if any, should handle reducing the given action and state.
 */
const reducer = (previousState = INITIAL_STATE, action): PdfState => {
  switch (action.type) {
    case developmentActionTypes.INITIALIZE: return initialize(previousState, action.payload);
    case actionTypes.START: return start(previousState, action.payload);
    case actionTypes.FINISH: return finish(previousState, action.payload);
    case actionTypes.SET_MAP_IMAGE: return setMapImage(previousState, action.payload);
    case actionTypes.SET_CAMERA: return setCamera(previousState, action.payload);
    case actionTypes.SET_TITLE: return setTitle(previousState, action.payload);
    case actionTypes.SET_SUMMARY: return setSummary(previousState, action.payload);
    case actionTypes.SET_FROM_CONTACTS_DETAILS: return setFromContactsDetails(previousState, action.payload);
    case actionTypes.SET_TO_CONTACTS_DETAILS: return setToContactsDetails(previousState, action.payload);
    case actionTypes.SET_COLOR_PALETTE: return setColorPalette(previousState, action.payload);
    case actionTypes.LOAD_DATA_SUCCESS: return loadDataSuccess(previousState, action.payload);
    case actionTypes.UPLOAD_COVER_PAGE_IMAGE_SUCCESS: return uploadCoverPageImageSuccess(previousState, action.payload);
    case actionTypes.DELETE_COVER_PAGE_IMAGE_SUCCESS: return deleteCoverPageImageSuccess(previousState, action.payload);
    case actionTypes.SET_ADDRESS: return setAddress(previousState, action.payload);
    case actionTypes.UPLOAD_COVER_PAGE_IMAGE_START: return uploadCoverPageImageStart(previousState, action.payload);
    case actionTypes.DELETE_COVER_PAGE_IMAGE_START: return deleteCoverPageImageStart(previousState, action.payload);
    default: return previousState;
  }
}

/**
 * Initialize the pdf store object.
 */
const initialize = (previousState, payload) => {
  return {
    ...INITIAL_STATE,
    coverPageImages: {
      [Pdf.ImageType.Cover]: {
        isLoading: true,
      },
      [Pdf.ImageType.Logo]: {
        isLoading: true,
      },
    },
  }
}

/**
 * Modify the pdf object by updating the isActive value and setting the remaining values to its defaults.
 */
const start = (previousState: PdfState, payload): PdfState => {
  return {
    ...previousState,
    isActive: true,
    isReady: false,
    mapImages: {
      [Pdf.ImageType.Building]: null,
      [Pdf.ImageType.Location]: null,
      [Pdf.ImageType.Parcel]: null,
      [Pdf.ImageType.Cover]: null,
    },
  }
}

/**
 * Reset the pdf object to its pre-generated state.
 */
const finish = (previousState: PdfState, payload): PdfState => {
  return {
    ...previousState,
    isActive: false,
    mapImages: undefined,
  }
}

/**
 * Modify the pdf object by updating the mapImages and isReady values.
 */
const setMapImage = (previousState: PdfState, payload): PdfState => {
  if (!previousState.isActive) return previousState;

  let nextState = {
    ...previousState,
    mapImages: { ...previousState.mapImages }
  };

  nextState.mapImages[payload.imageType] = payload.mapImage;
  let imagesToCheck = [
    nextState.mapImages[Pdf.ImageType.Building],
    nextState.mapImages[Pdf.ImageType.Location],
    nextState.mapImages[Pdf.ImageType.Parcel],
  ];

  if (!nextState.coverPageImages[Pdf.ImageType.Cover]) imagesToCheck.push(nextState.mapImages[Pdf.ImageType.Cover]);

  nextState.isReady = imagesAreReady(imagesToCheck);

  return nextState;
}

/**
 * Modify the pdf object by updating the camera.
 */
const setCamera = (previousState: PdfState, payload): PdfState => {
  return {
    ...previousState,
    camera: payload.camera,
  }
}

/**
 * Modify the pdf object by updating the title.
 */
const setTitle = (previousState: PdfState, payload): PdfState => {
  return {
    ...previousState,
    title: payload.title,
  }
}

/**
 * Modify the pdf object by updating the address.
 */
const setAddress = (previousState: PdfState, payload): PdfState => {
  return {
    ...previousState,
    address: {
      ...previousState.address,
      ...payload.address,
    }
  }
}

/**
 * Modify the pdf object by updating the summary.
 */
const setSummary = (previousState: PdfState, payload): PdfState => {
  return {
    ...previousState,
    summary: payload.summary,
  }
}

/**
 * Modify the pdf object by updating the from contact details.
 */
const setFromContactsDetails = (previousState: PdfState, payload): PdfState => {
  return {
    ...previousState,
    fromContactDetails: {
      ...previousState.fromContactDetails,
      ...payload.fromContactDetails,
    },
  }
}

/**
 * Modify the pdf object by updating the to contact details.
 */
const setToContactsDetails = (previousState: PdfState, payload): PdfState => {
  return {
    ...previousState,
    toContactDetails: {
      ...previousState.toContactDetails,
      ...payload.toContactDetails,
    },
  }
}

/**
 * Modify the pdf object by updating the color palette.
 */
const setColorPalette = (previousState: PdfState, payload): PdfState => {
  return {
    ...previousState,
    colorPalette: {
      ...previousState.colorPalette,
      ...payload.colorPalette,
    },
  }
}

/**
 * Initialize pdf on load success.
 */
const loadDataSuccess = (previousState: PdfState, payload): PdfState => {
  return {
    ...previousState,
    ...payload.document,
    coverPageImages: {
      ...payload.coverPageImages,
    },
  }
}

/**
 * Modify the pdf object by updating the cover page images loading flag.
 */
const uploadCoverPageImageStart = (previousState: PdfState, payload): PdfState => {
  return setCoverPageImagesAsLoading(previousState, payload.imageType);
}

/**
 * Modify the pdf object by updating the cover page images.
 */
const uploadCoverPageImageSuccess = (previousState: PdfState, payload): PdfState => {
  return {
    ...previousState,
    coverPageImages: {
      ...previousState.coverPageImages,
      [payload.imageType]: payload.image,
    }
  }
}

/**
 * Modify the pdf object by updating the cover page images loading flag.
 */
const deleteCoverPageImageStart = (previousState: PdfState, payload): PdfState => {
  return setCoverPageImagesAsLoading(previousState, payload.imageType);
}

/**
 * Set coverPageImages loading flag.
 */
const setCoverPageImagesAsLoading = (previousState: PdfState, imageType: Pdf.ImageType): PdfState => {
  return {
    ...previousState,
    coverPageImages: {
      ...previousState.coverPageImages,
      [imageType]: {
        ...previousState.coverPageImages[imageType],
        isLoading: true,
      }
    }
  }
}

/**
 * Modify the pdf object by updating the cover page images.
 */
const deleteCoverPageImageSuccess = (previousState: PdfState, payload): PdfState => {
  return {
    ...previousState,
    coverPageImages: {
      ...previousState.coverPageImages,
      [payload.imageType]: null,
    }
  }
}

export default reducer;
