/**
 * This utility function compares two arrays.
 * NOTE: This current implementation only works for scalar values.
 * Also assumes that both arrays have the same order. (i.e. [1,2,3] != [2,1,3])
 */
const equals = (array1: Array<any>, array2: Array<any>): boolean => {
  return array1.length === array2.length && array1.every((value, index) => value === array2[index]);
}

export default {
  equals,
}
