import { FilterId } from "../../../../../types/Filter";
import { SearchConfig } from "../../../../../types/Config";
import Format from "../../../../../types/Format";
import Unit from "../../../../../types/Unit";

const config: SearchConfig = {
  [FilterId.Vacant]: {
    label: "Vacant Land",
  },
  [FilterId.ExistingStructureArea]: {
    label: "Existing Building Area",
    formatOptions: { type: Format.Type.Number },
    min: 0,
    max: 929040,
    step: 0.000005,
    round: 100,
    sliderCurve: (x) => Math.pow(10, x) - 500,
    inverseCurve: (x) => Math.log10(x + 500),
    [Unit.System.Imperial]: {
      unitTarget: Unit.Type.SquareFeet,
      formatOptions: { suffix: " SF" },
    },
    [Unit.System.Metric]: {
      unitTarget: Unit.Type.SquareMeters,
      formatOptions: { suffix: " m²" },
    }
  },
  [FilterId.MedianIncomeTotal]: {
    label: "Median Income",
    min: 0,
    max: 10000000,
    step: 0.00001,
    round: 100,
    sliderCurve: (x) => Math.pow(10, x) - 500,
    inverseCurve: (x) => Math.log10(x + 500),
    formatOptions: { type: Format.Type.Currency },
  },
  [FilterId.GrossMedianRent]: {
    label: "Median Gross Rent",
    min: 0,
    max: 10000,
    step: 100,
    round: 100,
    sliderCurve: (x) => x,
    inverseCurve: (x) => x,
    formatOptions: { type: Format.Type.Currency },
  },
  [FilterId.AreaComputed]: {
    label: "Lot Size",
    min: 0,
    max: 929040,
    step: 0.000005,
    round: 100,
    sliderCurve: (x) => Math.pow(10, x) - 500,
    inverseCurve: (x) => Math.log10(x + 500),
    formatOptions: { type: Format.Type.Number },
    [Unit.System.Imperial]: {
      unitTarget: Unit.Type.SquareFeet,
      formatOptions: { suffix: " SF" },
    },
    [Unit.System.Metric]: {
      unitTarget: Unit.Type.SquareMeters,
      formatOptions: { suffix: " m²" },
    }
  },
  [FilterId.ExistingYearBuilt]: {
    label: "Year Built",
    min: 1900,
    max: 2020,
    step: 1,
    round: 1,
    sliderCurve: (x) => x,
    inverseCurve: (x) => x,
  },
  [FilterId.FloorAreaRatio]: {
    label: "Floor Area Ratio",
    min: 0,
    max: 100,
    step: 1,
    round: 1,
    sliderCurve: (x) => x,
    inverseCurve: (x) => x,
  },
  [FilterId.NumberOfUnitsAllowed]: {
    label: "Units Allowed",
    min: 0,
    max: 5000,
    step: 0.00001,
    round: 1,
    sliderCurve: (x) => Math.pow(10, x) - 500,
    inverseCurve: (x) => Math.log10(x + 500),
  },
};

export default config;
