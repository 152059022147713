import React from "react";
import { connect } from "react-redux";
import { developmentActions, developmentSelectors } from "../../../../../state/development";
import { BuildingUse } from "../../../../../types/BuildingUse";
import { Panel } from "../../../../../types/uiElements";
import ParcelInformationTable from "../../../../sharedComponents/ParcelInformationTable";
import { buildingUsageIsEnabled, allTogglesDisabled } from "../../../../utils/uiToggleHelper";
import PanelHeader from "../sharedComponents/PanelHeader";
import ProjectInfoAccordionSection from "./ProjectInfoAccordionSection";
import { VariableId } from "../../../../../types/VariableId";
import { getRentalUseText } from "../BackOfEnvelopePanel/utils/backOfEnvelopeHelper";
import valueFormatter from "../../../../../utils/valueFormatter";
import Format from "../../../../../types/Format";
import panelsConfig from "../../../utils/configFiles/panelsConfig";
import { PropertyListingTabContainer } from "../../../../sharedComponents/PropertyListingTabContainer/PropertyListingTabContainer";

const mapStateToProps = (state) => {
  return {
    values: developmentSelectors.getValues(state),
    parcel: developmentSelectors.getParcel(state),
    toggles: developmentSelectors.getBuildingUsageToggles(state),
  }
}

const mapDispatchToProps = {
  setDevelopmentName: developmentActions.setName,
  toggleBooleanInput: developmentActions.toggleBooleanInput,
}

interface OwnProps {
  panel: Panel;
  panels: Array<Panel>;
  onPanelToggle(panel: Panel);
  onPanelPin(panel: Panel);
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;
type Props = StateProps & DispatchProps & OwnProps;

class ProjectInfoPanel extends React.PureComponent<Props, {}> {
  /**
   * Check if all toggles are enabled and if back of envelope configuration requires it.
   */
  backOfEnvelopeIsEnabled = () => {
    return !(allTogglesDisabled(this.props.toggles) && panelsConfig.backOfEnvelopePanel.requireUsages)
  }

  /**
   * Open back of envelope panel.
   */
  openBackOfEnvelopePanel = async () => {
    const { panels, onPanelToggle, onPanelPin, panel } = this.props;
    if (panels.some((arrayPanel) => arrayPanel.title === panelsConfig.backOfEnvelopePanel.title)) return;
    if (panels.some((arrayPanel) => arrayPanel.title === panel.title && !arrayPanel.isPinned)) await onPanelPin(panel);
    onPanelToggle(panelsConfig.backOfEnvelopePanel);
  }

  /**
   * Render return of cost for use if it is enabled.
   */
  renderReturnOnCost = (text, iconClassName, variableId, buildingUse) => {
    const { values, toggles } = this.props;

    if (!buildingUsageIsEnabled(toggles, buildingUse)) return null;
    const value = values[variableId];

    return (
      <div className="use-section">
        <div className="value">{valueFormatter.format(value, { type: Format.Type.Percentage, decimalPlaces: 2 })}</div>
        <div className="divisor" />
        <div className="icon-text-container">
          <div className={`icon ${iconClassName}`} />
          {text}
        </div>
      </div>
    )
  }

  render() {
    const {
      panel,
      onPanelToggle,
      onPanelPin,
      parcel,
      toggles,
    } = this.props;

    return (
      <div className="component--project-info-panel panel">
        <PanelHeader
          isPinned={panel.isPinned}
          panel={panel}
          onPanelClose={onPanelToggle}
          onPanelPin={onPanelPin}
          hideTitle={true}
        />
        <div className="panel-inner-wrapper">
          <PropertyListingTabContainer tabText="Project Info" selectedFeature={parcel}>
            <div className="accordion-wrapper">
              <ProjectInfoAccordionSection />
              <ParcelInformationTable parcelFeature={parcel} />
            </div>
            {this.backOfEnvelopeIsEnabled() &&
              <div className="return-on-cost-wrapper">
                <div className="header-container">
                  <label className="return-on-cost-label">Return</label>
                  <div className="open-back-of-envelope" onClick={this.openBackOfEnvelopePanel}>See Return Analysis</div>
                </div>
                <div className="content-container">
                  {this.renderReturnOnCost("Condo", "condo", VariableId.CondoReturnOnCost, BuildingUse.Condo)}
                  {this.renderReturnOnCost("Hotel", "hotel", VariableId.HotelAnnualReturnOnCostForBackOfEnvelope, BuildingUse.Hotel)}
                  {this.renderReturnOnCost(getRentalUseText(toggles), "rental", VariableId.RentalUsesAnnualReturnOnCostForBackOfEnvelope, BuildingUse.RentalUses)}
                </div>
              </div>
            }
          </PropertyListingTabContainer>
        </div>
      </div>
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProjectInfoPanel);
