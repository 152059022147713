import React from "react";
import BrandingWatermark from "../../../../sharedComponents/BrandingWatermark";

export default class Footer extends React.PureComponent {
  render() {
    return (
      <div className="component--footer">
        <BrandingWatermark />
      </div>
    )
  }
}
