import React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { Path } from "../../../types/Path";
import authentication from "../../../utils/authentication";
import LogInPopup from "../LogInPopup";
import database from "../../../utils/database/database";

interface State {
  logInPopupIsOpen: boolean;
}

type Props = RouteComponentProps;

class AuthenticationButtons extends React.PureComponent<Props, State>  {
  constructor(props) {
    super(props);

    this.state = {
      logInPopupIsOpen: false,
    }
  }

  /**
   * Toggle the logInPopupIsOpen state.
   */
  toggleLogInPopupIsOpen = () => {
    this.setState({ logInPopupIsOpen: !this.state.logInPopupIsOpen });
  }

  /**
   * Save current project and redirect to subscribe.
   */
  redirectToSubscribe = async () => {
    await database.saveCurrentProject();
    this.props.history.push(Path.Subscribe);
  }

  render() {
    const userIsAuthenticated = authentication.isUserAuthenticated();
    if (userIsAuthenticated) return null;

    return (
      <>
        <div className={`button ${this.state.logInPopupIsOpen ? "active" : ""}`} onClick={this.toggleLogInPopupIsOpen}>
          <div className="icon log-in" />
          <div className="text">Log In</div>
        </div>

        <div className="button" onClick={this.redirectToSubscribe}>
          <div className="icon sign-up" />
          <div className="text">Sign up</div>
        </div>

        {this.state.logInPopupIsOpen && <LogInPopup onStateChange={this.toggleLogInPopupIsOpen} classes="on-explorer-page" />}
      </>
    );
  }
}

export default withRouter(AuthenticationButtons);
