import { colorFor } from "./colors";

const USAGE_PROPERTIES = {
  "condo": {
    "display":          true,
    "displayColorRGB":  colorFor("building-usage-condo"),
  },
  "multifamily": {
    "display":          true,
    "displayColorRGB":  colorFor("building-usage-multifamily"),
  },
  "hotel": {
    "display":          true,
    "displayColorRGB":  colorFor("building-usage-hotel"),
  },
  "office": {
    "display":          true,
    "displayColorRGB":  colorFor("building-usage-office"),
  },
  "industrial": {
    "display":          true,
    "displayColorRGB":  colorFor("building-usage-industrial"),
  },
  "parking": {
    "display":          true,
    "displayColorRGB":  colorFor("building-usage-parking"),
  },
  "retail": {
    "display":          true,
    "displayColorRGB":  colorFor("building-usage-retail"),
  },
  "project": {                      // "General" or "default" usage.
    "display":          false,
    "displayColorRGB":  colorFor("building-usage-project"),
  },
}

/**
 * The list of building usages that are displayable
 *
 * @returns - An array containing the names of usages that are displayable.
 */
const displayableUsages = () =>
  Object
    .keys(USAGE_PROPERTIES)
    .filter( usage => USAGE_PROPERTIES[usage]["display"] );

/**
 * The RGB color string for the given usage.
 *
 * @param usage - The name of a building usage.
 *
 * @returns - A string representation of an RGB color value, including a
 *    leading poundsign (for example "#9C2706"), or null if the requested
 *    building usage is not found in the usage list.
 */
const usageColor = (usage) => {
  const usages = Object.keys(USAGE_PROPERTIES);

  if (usages.includes(usage)) {
    return USAGE_PROPERTIES[usage]["displayColorRGB"];
  } else {
    return null;
  }
};

export {
  displayableUsages,
  usageColor,
};
