import React from "react";
import { connect } from "react-redux";
import { GeoJSONLayer } from "react-mapbox-gl";
import { newDevelopmentSelectors } from "../../../../../state/newDevelopment";
import mapboxPresentationProperties from "../../../../utils/mapboxPresentationProperties";
import { MapStyleProperties } from "../../../../../utils/mapbox/mapStyleProperties";

const mapStateToProps = (state) => {
  return ({
    smartSearchResult: newDevelopmentSelectors.getSmartSearchResult(state),
  });
}

type MapStateProps = ReturnType<typeof mapStateToProps>;
type Props = MapStateProps;

class SmartSearchLayer extends React.PureComponent<Props, {}> {
  render() {
    return (
      <GeoJSONLayer
        data={{
          type: "FeatureCollection",
          features: this.props.smartSearchResult
        } as any}
        fillPaint={mapboxPresentationProperties.searchResultsFillPaint}
        linePaint={mapboxPresentationProperties.searchResultsLinePaint}
        before={MapStyleProperties.LayerId.UsaParcelsQuery}
      />
    )
  }
}

export default connect(mapStateToProps)(SmartSearchLayer);
