const MISSING_TIME_MODIFIED = -1; // Flag indicating that the project still doesn't have a timeModified value.

/**
 * Refactor the user's project list from:
 *
 * `{id: "string"}`
 *
 * to:
 *
 * `
 * {
 *  id: {
 *    name: "string",
 *    timeModified: "number" // milliseconds since epoch
 *  }
 * }
 * `
 */
const refactorProjectList = (user) => {
  for (let projectId of Object.keys(user.projects)) {
    let projectName = user.projects[projectId];

    user.projects[projectId] = {
      name: projectName,
      timeModified: MISSING_TIME_MODIFIED
    }
  }
}

export default refactorProjectList;
