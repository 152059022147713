import developmentAccessors from "../../../../state/development/utils/developmentAccessors";
import { Development } from  "../../../../types/Development/Development";
import { VariableId } from  "../../../../types/VariableId";
import generateLinearRegressionPreset from "./generatorForLinearRegression";

/**
 * Generate the industrial buildables presets.
 */
const generatePresets = (development: Development, targetArea: number): object => {
  const lossFactor = developmentAccessors.getInputValue(development, VariableId.IndustrialLossFactor) as number;
  const netLeasableArea = targetArea * (1 - lossFactor);

  const previousMaximum = developmentAccessors.getInputMaximum(development,  VariableId.IndustrialNetLeasableAreaToggleable);

  return {
    [VariableId.IndustrialNetLeasableAreaToggleable]: {
      "minimum":  0,
      "maximum":  2 * netLeasableArea || previousMaximum,
      "value":    netLeasableArea
    },
    [VariableId.IndustrialYearOneRentPerArea]: generateLinearRegressionPreset(development, VariableId.IndustrialYearOneRentPerArea),
    [VariableId.IndustrialHardCostPerArea]: generateLinearRegressionPreset(development, VariableId.IndustrialHardCostPerArea),
  };
}

export default generatePresets;
