import React from "react";
import NewDevelopmentButton from "../../sharedComponents/NewDevelopmentButton";

export default class NewDevelopmentLink extends React.PureComponent<{}, {}> {
  render() {
    return (
      <div className="application-link">
        <div className="new-development-icon" />
        <h5>New Development</h5>
        <p>Analyze a single site or an assembly, single-use or mixed-use, to determine development potential.</p>
        <NewDevelopmentButton text="START PROJECT" />
      </div>
    )
  }
}
