/**
 * Round `value` to `decimalPlaces` places.
 * @param value
 * @param decimalPlaces
 */
const roundToDecimal = (value: number, decimalPlaces: number): number => {
  // This implementation is safe against floating point errors.
  // See https://stackoverflow.com/a/19722641
  let cutoff = Number(value + "e+" + decimalPlaces)
  return Number(Math.round(cutoff) + "e-" + decimalPlaces)
}

export default roundToDecimal;
