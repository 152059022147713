import React from "react";
import { connect } from "react-redux";
import { developmentSelectors, developmentActions } from "../../../../../state/development";
import { SetbackType } from "../../../../../types/Setback";

interface OwnProps {
  selectedPolygonIndex: number;
  selectedEdgeIndex: number;
  onClick: (selectedEdgeIndex: number) => void;
}

const mapStateToProps = (state, ownProps) => {
  return {
    selectedSetbackType: developmentSelectors.getSetbackType(state, ownProps.selectedPolygonIndex, ownProps.selectedEdgeIndex)
  };
};

const mapDispatchToProps = {
  setSetbackType: developmentActions.setSetbackType
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;
type Props = StateProps & DispatchProps & OwnProps;

const SETBACK_LABELS = {
  A: "Setback A - Primary",
  B: "Setback B - Secondary",
  C: "Setback C - Side",
  D: "Setback D - Rear",
}

class SelectSetback extends React.PureComponent<Props, {}> {
  /**
   * Handle click on the the setback radio buttons.
   */
  handleClick = (event) => {
    let selectedSetbackType = event.target.value;
    this.props.setSetbackType(this.props.selectedPolygonIndex, this.props.selectedEdgeIndex, selectedSetbackType);
  }

  /**
   * Render the Select Setback list.
   */
  renderSelectSetbackList = () => {
    return (
      <div className="setback-list-container">
        {
          Object.values(SetbackType).map(
            (setbackType) => {
              return (
                <label key={setbackType} className="setback-row">
                  <span className="text">{SETBACK_LABELS[setbackType]}</span>
                  <input
                    type="radio"
                    value={setbackType}
                    checked={this.props.selectedSetbackType === setbackType}
                    onClick={this.handleClick}
                    readOnly
                  />
                </label>
              );
            }
          )
        }
      </div>
    );
  }

  render() {
    if (this.props.selectedPolygonIndex < 0 || this.props.selectedEdgeIndex < 0) {
      return null;
    }

    return (
      <div className="component--select-setback">
        <div className="content">
          <div className="header">
            Assign setback type:
          </div>
          {this.renderSelectSetbackList()}
        </div>
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SelectSetback);
