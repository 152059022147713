import React from "react";
import styled from "@react-pdf/styled-components";
import PdfContext from "../../../PdfContext";
import Pdf from "../../../../../../../types/Pdf";

const Container = styled.View`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 600px;
`;

const Image = styled.Image`
  object-fit: scale-down;
  width: auto;
  max-width: 100%;
  height: auto;
`;

const CoverImage = () => (
  <PdfContext.Consumer>
    {({ mapImages, coverImages }) => {
      const imageUrl = (coverImages[Pdf.ImageType.Cover] && coverImages[Pdf.ImageType.Cover].url) || mapImages[Pdf.ImageType.Cover];

      return (
        <Container>
          <Image src={imageUrl} />
        </Container>
      )
    }}
  </PdfContext.Consumer>
);

export default CoverImage;
