import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { developmentSelectors } from "../../../../../../../state/development";
import { BuildingUse } from "../../../../../../../types/BuildingUse";
import { getEnabledRentalUses } from "../../../../../../utils/uiToggleHelper"
import { capitalizeFirstLetter } from "../../../../../../../utils/stringHelper";
import { getRentalHeaderText, getRentalUseText } from "../../utils/backOfEnvelopeHelper";
import Cell from "../../../../../../sharedComponents/Cell";
import authentication from "../../../../../../../utils/authentication";
import CellRow from "../../../../../../sharedComponents/CellRow";
import CellsContainer from "../../../../../../sharedComponents/CellsContainer";
import ConfigurationHandler from "../../../../../../sharedComponents/ConfigurationHandler";
import config from "./config";

const mapStateToProps = (state) => {
  return {
    unitSystem: developmentSelectors.getUnitSystem(state),
    toggles: developmentSelectors.getBuildingUsageToggles(state),
  }
}

interface OwnProps {
  index: number;
  currentTab: number;
}

type StateProps = ReturnType<typeof mapStateToProps>;
type Props = OwnProps & StateProps;

class BackOfEnvelopeRentalUsesWorksheet extends PureComponent<Props, {}> {

  /**
   * Generate a header string for the Lease worksheet. The string is modified depending on which lease uses
   * are currently enabled.
   */
  renderWorksheetHeader = () => {
    let headerText = getRentalHeaderText(this.props.toggles);
    if (!headerText) return null;

    return (
      <>
        <CellRow>
          <Cell
            text={headerText}
            styleVariation="bold-17 border-below"
            tdElementProps={{
              colSpan: "2"
            }}
          />
        </CellRow>
        <CellRow
          classes="break"
        />
      </>
    )
  };

  /**
   * Generate a header for the given rental use section if there are more that one lease uses enabled.
   */
  renderRentalUseHeader = (rentalUse) => {
    let enabledRentalUses = getEnabledRentalUses(this.props.toggles);
    if (enabledRentalUses.length <= 1) return null;

    return (
      <CellRow usageGroup={rentalUse}>
        <Cell text={capitalizeFirstLetter(rentalUse)} styleVariation="bold-17 border-below" />
        <Cell styleVariation="border-below bold" />
      </CellRow>
    )
  }

  /**
   * Render Multifamily section.
   */
  renderMultifamilySection = (userIsAuthenticated) => (
    <>
      {this.renderRentalUseHeader("multifamily")}
      <CellRow usageGroup={BuildingUse.Multifamily}>
        <ConfigurationHandler config={config.multifamilyYearOnePotentialGrossIncome} component={CellsContainer} unitSystem={this.props.unitSystem} hiddenValues={!userIsAuthenticated} isFromBackOfEnvelope />
      </CellRow>
      <CellRow usageGroup={BuildingUse.Multifamily}>
        <ConfigurationHandler config={config.multifamilyAnnualVacancyExpenseForBackOfEnvelope} component={CellsContainer} unitSystem={this.props.unitSystem} hiddenValues={!userIsAuthenticated} isFromBackOfEnvelope />
      </CellRow>
      <CellRow usageGroup={BuildingUse.Multifamily}>
        <ConfigurationHandler config={config.multifamilyYearOneOperatingExpense} component={CellsContainer} unitSystem={this.props.unitSystem} hiddenValues={!userIsAuthenticated} isFromBackOfEnvelope />
      </CellRow>
      <CellRow usageGroup={BuildingUse.Multifamily}>
        <ConfigurationHandler config={config.multifamilyAnnualNetOperatingIncomeForBackOfEnvelope} component={CellsContainer} styleVariation="bold-17 border-above" unitSystem={this.props.unitSystem} hiddenValues={!userIsAuthenticated} isFromBackOfEnvelope />
      </CellRow>
      <CellRow
        usageGroup={BuildingUse.Multifamily}
        classes="break"
      />
    </>
  )

  /**
   * Render Office section.
   */
  renderOfficeSection = (userIsAuthenticated) => (
    <>
      {this.renderRentalUseHeader("office")}
      <CellRow usageGroup={BuildingUse.Office}>
        <ConfigurationHandler config={config.officeYearOnePotentialGrossIncome} component={CellsContainer} unitSystem={this.props.unitSystem} hiddenValues={!userIsAuthenticated} isFromBackOfEnvelope />
      </CellRow>
      <CellRow usageGroup={BuildingUse.Office}>
        <ConfigurationHandler config={config.officeAnnualVacancyExpenseForBackOfEnvelope} component={CellsContainer} unitSystem={this.props.unitSystem} hiddenValues={!userIsAuthenticated} isFromBackOfEnvelope />
      </CellRow>
      <CellRow usageGroup={BuildingUse.Office}>
        <ConfigurationHandler config={config.officeYearOneOperatingExpense} component={CellsContainer} unitSystem={this.props.unitSystem} hiddenValues={!userIsAuthenticated} isFromBackOfEnvelope />
      </CellRow>
      <CellRow usageGroup={BuildingUse.Office}>
        <ConfigurationHandler config={config.officeAnnualNetOperatingIncomeForBackOfEnvelope} component={CellsContainer} styleVariation="bold-17 border-above" unitSystem={this.props.unitSystem} hiddenValues={!userIsAuthenticated} isFromBackOfEnvelope />
      </CellRow>
      <CellRow
        usageGroup={BuildingUse.Office}
        classes="break"
      />
    </>
  )

  /**
   * Render Retail section.
   */
  renderRetailSection = (userIsAuthenticated) => (
    <>
      {this.renderRentalUseHeader("retail")}
      <CellRow usageGroup={BuildingUse.Retail}>
        <ConfigurationHandler config={config.retailYearOnePotentialGrossIncome} component={CellsContainer} unitSystem={this.props.unitSystem} hiddenValues={!userIsAuthenticated} isFromBackOfEnvelope />
      </CellRow>
      <CellRow usageGroup={BuildingUse.Retail}>
        <ConfigurationHandler config={config.retailAnnualVacancyExpenseForBackOfEnvelope} component={CellsContainer} unitSystem={this.props.unitSystem} hiddenValues={!userIsAuthenticated} isFromBackOfEnvelope />
      </CellRow>
      <CellRow usageGroup={BuildingUse.Retail}>
        <ConfigurationHandler config={config.retailYearOneOperatingExpense} component={CellsContainer} unitSystem={this.props.unitSystem} hiddenValues={!userIsAuthenticated} isFromBackOfEnvelope />
      </CellRow>
      <CellRow usageGroup={BuildingUse.Retail}>
        <ConfigurationHandler config={config.retailAnnualNetOperatingIncomeForBackOfEnvelope} component={CellsContainer} styleVariation="bold-17 border-above" unitSystem={this.props.unitSystem} hiddenValues={!userIsAuthenticated} isFromBackOfEnvelope />
      </CellRow>
      <CellRow
        usageGroup={BuildingUse.Retail}
        classes="break"
      />
    </>
  )

  /**
   * Render Industrial section.
   */
  renderIndustrialSection = (userIsAuthenticated) => (
    <>
      {this.renderRentalUseHeader("industrial")}
      <CellRow usageGroup={BuildingUse.Industrial}>
        <ConfigurationHandler config={config.industrialYearOnePotentialGrossIncome} component={CellsContainer} unitSystem={this.props.unitSystem} hiddenValues={!userIsAuthenticated} isFromBackOfEnvelope />
      </CellRow>
      <CellRow usageGroup={BuildingUse.Industrial}>
        <ConfigurationHandler config={config.industrialAnnualVacancyExpenseForBackOfEnvelope} component={CellsContainer} unitSystem={this.props.unitSystem} hiddenValues={!userIsAuthenticated} isFromBackOfEnvelope />
      </CellRow>
      <CellRow usageGroup={BuildingUse.Industrial}>
        <ConfigurationHandler config={config.industrialYearOneOperatingExpense} component={CellsContainer} unitSystem={this.props.unitSystem} hiddenValues={!userIsAuthenticated} isFromBackOfEnvelope />
      </CellRow>
      <CellRow usageGroup={BuildingUse.Industrial}>
        <ConfigurationHandler config={config.industrialAnnualNetOperatingIncomeForBackOfEnvelope} component={CellsContainer} styleVariation="bold-17 border-above" unitSystem={this.props.unitSystem} hiddenValues={!userIsAuthenticated} isFromBackOfEnvelope />
      </CellRow>
      <CellRow
        usageGroup={BuildingUse.Industrial}
        classes="break"
      />
    </>
  )

  /**
   * Render All-In Cost PSF section.
   */
  renderAllInCostPSFSection = (userIsAuthenticated) => (
    <>
      <CellRow>
        <ConfigurationHandler config={config.rentalUsesTotalDevelopmentCostIncludingParking} component={CellsContainer} textModifier={() => getRentalUseText(this.props.toggles)} unitSystem={this.props.unitSystem} hiddenValues={!userIsAuthenticated} isFromBackOfEnvelope />
      </CellRow>
      <CellRow>
        <ConfigurationHandler config={config.rentalUsesGrossBuildableAreaIncludingParking} component={CellsContainer} textModifier={() => getRentalUseText(this.props.toggles)} unitSystem={this.props.unitSystem} hiddenValues={!userIsAuthenticated} isFromBackOfEnvelope />
      </CellRow>
      <CellRow>
        <ConfigurationHandler config={config.rentalUsesDevelopmentCostPerAreaIncludingParking} component={CellsContainer} textModifier={() => getRentalUseText(this.props.toggles)} styleVariation="bold-17 border-above" unitSystem={this.props.unitSystem} hiddenValues={!userIsAuthenticated} isFromBackOfEnvelope />
      </CellRow>
      <CellRow
        classes="break"
      />
    </>
  )

  render() {
    if (this.props.index !== this.props.currentTab) return null;
    const userIsAuthenticated = authentication.isUserAuthenticated();

    return (
      <table className="worksheet-table">
        <tbody>
          {this.renderWorksheetHeader()}
          {this.renderMultifamilySection(userIsAuthenticated)}
          {this.renderOfficeSection(userIsAuthenticated)}
          {this.renderRetailSection(userIsAuthenticated)}
          {this.renderIndustrialSection(userIsAuthenticated)}
          {this.renderAllInCostPSFSection(userIsAuthenticated)}
        </tbody>
      </table>
    )
  }
}

export default connect(
  mapStateToProps
)(BackOfEnvelopeRentalUsesWorksheet);
