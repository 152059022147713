import React from "react";

/**
 * @fileoverview This module provides common-use field renderers, in the form of
 * stateless (i.e "functional") react components.
 */

interface ToggleFieldProps {
  label?: string;
  error?: string;
  color?: string;
  toggleElementProps?: any;
}

interface InputFieldProps {
  type?: string;
  placeholder?: string,
  label?: string;
  error?: string;
  inputElementProps?: any;
}

interface SelectFieldOption {
  label: string;
  value: any;
  hidden?: boolean;
}

interface SelectFieldProps {
  options: Array<SelectFieldOption>;
  label?: string;
  error?: string;
  selectElementProps?: any;
}

/**
 * Render error message wrapped in a span element if an error message exists.
 */
const renderError = (error) => {
  return error
    ? <span className="errorMessage">{error}</span>
    : null;
}

/**
 * A CSS class name that can be used to to style elements differently depending
 * on whether or not form errors are present.
 */
const outerWrapperErrorClass = (error) => {
  return error
    ? "with-error"
    : "";
};

/**
 * Return the style object with background set to color
 * if the value of the input is true, otherwise return empty object.
 * @param input input element
 * @param color color to be set for background
 */
const toggleStyle = (input, color) => {
  return input && (input.value || input.checked)
    ? { background: color }
    : {}
}

/**
 * An <input type="checkbox"> field and accompanying HTML.
 */
const ToggleField = ({ label, error, color, toggleElementProps }: ToggleFieldProps) => {
  return (
    <div className={`toggle-field-wrapper ${outerWrapperErrorClass(error)}`}>
      <div className="toggle-container">
        <label className="toggle-box">
          <input {...toggleElementProps} type="checkbox" checked={Boolean(toggleElementProps.value)}/>
          <div
            className="toggle"
            style={toggleStyle(toggleElementProps, color)}
          />
        </label>
        <label className="toggle-label">
          {label}
        </label>
        {renderError(error)}
      </div>
    </div>
  );
}

/**
 * An <input> field and accompanying HTML.
 */
const InputField = ({ label, type, error, placeholder, inputElementProps }: InputFieldProps) => {
  let name = inputElementProps ? inputElementProps.name : null;

  return (
    <div className={`input-field-wrapper ${outerWrapperErrorClass(error)}`}>
      <label className="top-label" htmlFor={name} hidden={type === "hidden" ? true : false} >{label}</label>
      <div>
        <input {...inputElementProps} placeholder={placeholder ? placeholder : label} type={type} />
        {renderError(error)}
      </div>
    </div>
  );
};

/**
 * A <select> field and accompanying HTML.
 */
const SelectField = ({ options, label, error, selectElementProps }: SelectFieldProps) => {
  let name = selectElementProps ? selectElementProps.name : null;

  return (
    <div className={`select-field-wrapper ${outerWrapperErrorClass(error)}`}>
      <label className="top-label" htmlFor={name}>{label}</label>
      <div>
        <select {...selectElementProps}>
          {options.map((option) =>
            <option
              value={option.value}
              key={`${name}-${option.value}`}
              hidden={option.hidden}
            >
              {option.label}
            </option>)}
        </select>
        {renderError(error)}
      </div>
    </div>
  );
};

export {
  InputField,
  ToggleField,
  SelectField,
};
