import equalWithinEpsilon from "./floatCompare";

const EPSILON = 0.000000001;

/**
 * This module is responsible for manipulating 2-dimensional vectors.
 *
 * A 2-dimensional vector is represented by a simple object of the form:
 *
 *    `{ x: xValue, y: yValue}`.
 */

/**
 * A new 2D vector with the given coordinates.
 */
const create = (xCoordinate, yCoordinate) => {
  return { x: xCoordinate, y: yCoordinate };
};

/**
 * Compute the magnitude or length of a vector.
 *
 * @returns A scalar value.
 */
const magnitude = (vector) => {
  return Math.sqrt((vector.x ** 2) + (vector.y ** 2));
};

/**
 * Compute a vector with the same direction and magnitude 1.
 *
 * @returns A new vector.
 */
const unitVector = (vector) => {
  const vectorMagnitude = magnitude(vector);
  return {
    x: vector.x / vectorMagnitude,
    y: vector.y / vectorMagnitude,
  };
};

/**
 * Compute a counter-clockwise normal vector with magnitude 1.
 *
 * @returns A new vector.
 */
const counterClockwiseUnitNormal = (vector) => {
  return unitVector({
    x: -vector.y,
    y: vector.x,
  });
};

/**
 * Compute a clockwise normal vector with magnitude 1.
 *
 * @returns A new vector.
 */
const clockwiseUnitNormal = (vector) => {
  return unitVector({
    x: vector.y,
    y: -vector.x,
  });
};

/**
 * Add two vectors.
 *
 * @returns A new vector.
 */
const add = (vector1, vector2) => {
  return create(
    vector1.x + vector2.x,
    vector1.y + vector2.y
  );
};

/**
 * Scale the given vector by the given scalar amount.
 *
 * @returns A new vector.
 */
const scale = (vector, scalar) => {
  return create(
    vector.x * scalar,
    vector.y * scalar
  );
};

/**
 * Subtract a vector from another.
 *
 * @returns A new vector.
 */
const subtract = (minuend, subtrahend) => {
  return create(
    minuend.x - subtrahend.x,
    minuend.y - subtrahend.y
  );
};

/**
 * Calculate the cross product of two vectors.
 *
 * @returns A scalar value.
 */
const cross = (vector1, vector2) => {
  return (vector1.x * vector2.y) - (vector1.y * vector2.x);
};

/**
 * Calculate the dot product of two vectors.
 *
 * @returns A scalar value.
 */
const dot = (vector1, vector2) => {
  return (vector1.x * vector2.x) + (vector1.y * vector2.y);
};

/**
 * Decide if two vector are equal by comparing the magnitude of their difference with a given epsilon.
 */
const equal = (vectorA, vectorB, epsilon = EPSILON) => {
  return magnitude(subtract(vectorA, vectorB)) < epsilon;
}

/**
 * Compute intersection of the perpendicular lines that pass by the end point of two given vectors.
 * Details of this algorithm can be found in `/docs/SetbacksAlgorithm.md`.
 */
const normalsIntersection = (vector1, vector2) => {
  let normalToVector1 = clockwiseUnitNormal(vector1);
  let divisor = dot(normalToVector1, vector2);
  if (!equalWithinEpsilon(divisor, 0)) {
    let scalingFactor = (dot(vector2, vector2) - dot(vector1, vector2)) / divisor;
    let normalsIntersection = add(vector1, scale(normalToVector1, scalingFactor));
    return normalsIntersection;
  } else {
    return create(0,0);
  }
}

/**
 * Compute the unit vector that bisects the angle between two given vectors.
 * Details of this algorithm can be found in https://proofwiki.org/wiki/Angle_Bisector_Vector.
 */
const bisectorUnitVector = (vector1, vector2) => {
  let magnitudeOfVector1 = magnitude(vector1);
  let magnitudeOfVector2 = magnitude(vector2);
  let scaledVector1 = scale(vector1, magnitudeOfVector2);
  let scaledVector2 = scale(vector2, magnitudeOfVector1);
  let bisectorUnitVector = unitVector(add(scaledVector1, scaledVector2));
  return bisectorUnitVector;
}

/**
 * Compute the angle in radians (-π, π) between the vector and positive side of the x axis.
 */
const angleOfVector = (vector) => {
  return Math.atan2(vector.y, vector.x);
}

export default {
  create,
  magnitude,
  unitVector,
  counterClockwiseUnitNormal,
  clockwiseUnitNormal,
  add,
  scale,
  subtract,
  cross,
  dot,
  equal,
  normalsIntersection,
  bisectorUnitVector,
  angleOfVector,
};
