import { Chargebee } from "../../types/Service/Chargebee";

/**
 * Returns a boolean indicating if the given subscription status is one of the
 * "active" status that allows use of the software.
 */
const isEnabled = (subscriptionStatus: Chargebee.SubscriptionStatus | undefined): boolean => {
  return subscriptionStatus === Chargebee.SubscriptionStatus.Active
    || subscriptionStatus === Chargebee.SubscriptionStatus.InTrial
    || subscriptionStatus === Chargebee.SubscriptionStatus.NonRenewing;
}

export default {
  isEnabled,
}
