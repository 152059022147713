import React from "react";

const MINIMUM_DIFFERENCE = 2;

interface Props {
  pageNumber: number;
  totalDevelopments: number;
  pageSize: number;
  changePage(pageNumber: number): void;
}

class Pagination extends React.PureComponent<Props, {}> {
  /**
   * Render which are the developments being displayed.
   */
  renderPageNumbers = () => {
    let firstDevelopment = this.props.pageNumber * this.props.pageSize + 1;
    let lastDevelopment = (this.props.pageNumber + 1) * this.props.pageSize;
    if (lastDevelopment > this.props.totalDevelopments) lastDevelopment = this.props.totalDevelopments;

    if (lastDevelopment > firstDevelopment) {
      return <div>{`${firstDevelopment}-${lastDevelopment} of ${this.props.totalDevelopments} Projects`}</div>
    } else if (this.props.totalDevelopments > 0) {
      return <div>{`${firstDevelopment} of ${this.props.totalDevelopments} Projects`}</div>
    } else {
      return <div>{`0 of 0 Projects`}</div>
    }
  }

  /**
   * Render a clickable number that loads the given page number.
   */
  renderPageButton = (pageNumber: number) => {
    return <div className="button" onClick={() => this.props.changePage(pageNumber)}> {pageNumber + 1} </div>
  };

  /**
   * Render the previous page button.
   */
  renderPreviousPageButton = () => {
    if (this.props.pageNumber === 0) return null;
    return <div className="button" onClick={() => this.props.changePage(this.props.pageNumber - 1)}>Prev  </div>
  }

  /**
   * Render the first page button.
   */
  renderFirstPageButton = () => {
    if (this.props.pageNumber < MINIMUM_DIFFERENCE) return null;
    return this.renderPageButton(0);
  }

  /**
   * Render the "middle" pages buttons, these include:
   * - the current page number (not a button)
   * - the predecessor page (if exist)
   * - the successor page (if exist)
   * - three dots to each side when applicable.
   */
  renderMiddlePagesButtons = (lastPage: number) => {
    return (
      <>
        {this.props.pageNumber > MINIMUM_DIFFERENCE ? " ... " : null}
        {this.props.pageNumber > 0 ? this.renderPageButton(this.props.pageNumber - 1) : null}
        <div className="current"> {this.props.pageNumber + 1} </div>
        {this.props.pageNumber < lastPage ? this.renderPageButton(this.props.pageNumber + 1) : null}
        {lastPage - this.props.pageNumber > MINIMUM_DIFFERENCE ? " ... " : null}
      </>
    );
  }

  /**
   * Render the last page button.
   */
  renderLastPageButton = (lastPage: number) => {
    if (lastPage - this.props.pageNumber < MINIMUM_DIFFERENCE) return null;
    return this.renderPageButton(lastPage);
  }

  /**
   * Render the next page button.
   */
  renderNextPageButton = (lastPage: number) => {
    if (this.props.pageNumber === lastPage) return null;
    return <div className="button" onClick={() => this.props.changePage(this.props.pageNumber + 1)}>  Next</div>
  }

  /**
   * Render the buttons for selecting a different page.
   */
  renderPageSelector = () => {
    const lastPage = Math.ceil(this.props.totalDevelopments / this.props.pageSize) - 1;

    return (
      <div className="page-selector">
        {this.renderPreviousPageButton()}
        <div className="numbers">
          {this.renderFirstPageButton()}
          {this.renderMiddlePagesButtons(lastPage)}
          {this.renderLastPageButton(lastPage)}
        </div>
        {this.renderNextPageButton(lastPage)}
      </div>
    );
  }

  render() {
    return (
      <div className="component--pagination">
        {this.renderPageNumbers()}
        {this.props.totalDevelopments > this.props.pageSize ? this.renderPageSelector() : null}
      </div>
    )
  }
}

export default Pagination;
