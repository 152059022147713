import React from "react";
import { Page } from "@react-pdf/renderer";
import styled from "@react-pdf/styled-components";
import Footer from "../sharedComponents/Footer";
import companyInfo from "../../../../../../utils/companyInfo";
import Summary from "./Summary";
import CoverImage from "./CoverImage";
import LogoImage from "./LogoImage";
import Title from "./Title";
import FromContactDetails from "./FromContactDetails";
import ToContactDetails from "./ToContactDetails";
import AddressBar from "../sharedComponents/AddressBar";
import PdfContext from "../../PdfContext";

const Content = styled.View`
  width: 100%;
  height: 761px;
  display: flex;
  flex-direction: row;
  flex-grow: 1;
`;

const LeftContainer = styled.View`
  width: 411px;
  height: 100%;
`;

const RightContainer = styled.View`
  flex-grow: 1;
  flex-shrink: 1;
  height: 100%;
  padding: 36px 15px 20px 15px;
  box-sizing: content-box;
`;

const Link = styled.Link`
  font-family: "Roboto Condensed Bold";
`;

const ContactsContainer = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left: 35px;
  flex-grow: 1;
`;

const AddressContainer = styled.View`
  border-bottom: 1px;
  border-color: ${(props) => props.borderColor};
  display: flex;
  margin-top: 160px;
`;

const CoverPage = () => (
  <PdfContext.Consumer>
    {({ colorPalette }) => (
      <Page size="LETTER">
        <Content>
          <LeftContainer>
            <CoverImage />
            <ContactsContainer>
              <FromContactDetails />
              <ToContactDetails />
            </ContactsContainer>
          </LeftContainer>
          <RightContainer>
            <LogoImage />
            <Title />
            <AddressContainer borderColor={colorPalette.secondary}>
              <AddressBar textColor="#333333" />
            </AddressContainer>
            <Summary />
          </RightContainer>
        </Content>
        <Footer>
          Prepared with Deepblocks. Find out more at <Link href={companyInfo.WEBSITE_URL}>deepblocks.com</Link>.
        </Footer>
      </Page>
    )}
  </PdfContext.Consumer>
);

export default CoverPage;
