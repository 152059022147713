import React from "react";
import { connect } from "react-redux";
import { GeoJsonObject } from "geojson";
import parcelAccessors from "../../../../utils/parcel/parcelAccessors";
import valueFormatter from "../../../../utils/valueFormatter";
import unitConversions from "../../../../utils/unitConversions";
import configHelper from "../../../../utils/configHelper";
import authentication from "../../../../utils/authentication";
import { developmentSelectors } from "../../../../state/development";
import { newDevelopmentSelectors } from "../../../../state/newDevelopment";
import Cell from "../../Cell";
import config from "./config";
import ConditionalCellRow from "../ConditionalCellRow";
import AccordionSection from "../../AccordionSection";
import { accordionSectionSelectors, accordionSectionActions } from "../../../../state/ui/shared/accordionSection";
import { Accordions } from "../../../../state/ui/shared/accordionSection/reducers";

const mapStateToProps = (state) => {
  return {
    unitSystem: developmentSelectors.getUnitSystem(state) || newDevelopmentSelectors.getUnitSystem(state),
    accordionIsOpen: accordionSectionSelectors.getStaticAccordionIsOpen(state, Accordions.PropertyInfo),
  }
}

const mapDispatchToProps = {
  setStaticAccordionIsOpen: accordionSectionActions.setStaticAccordionIsOpen,
}

type OwnProps = {
  parcelFeature: GeoJsonObject;
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;
type Props = OwnProps & StateProps & DispatchProps;

class PropertyInformation extends React.PureComponent<Props, {}> {
  /**
   * Display existing building area.
   */
  displayExistingBuildingArea = () => {
    const { parcelFeature } = this.props;

    let existingBuildingArea = parcelAccessors.getExistingStructureArea(parcelFeature);
    if (existingBuildingArea === 0) return "Vacant";
    if (!existingBuildingArea) return null;

    const unitSpecificConfig = configHelper.getConfigForUnitSystem(config.existingStructureArea, this.props.unitSystem);

    let area = unitConversions.convertFromBase(existingBuildingArea, unitSpecificConfig.unitTarget);
    return valueFormatter.format(area, unitSpecificConfig.formatOptions);
  }

  /**
   * Display an array as comma separated strings.
   */
  displayArray = (array) => {
    return (array && array.length > 0) ? array.join(", ") : null;
  }

  /**
   * Display the published area.
   */
  displayPublishedArea = () => {
    const parcel = this.props.parcelFeature;
    let areaIsComputed = false;
    let area: number | string | null = 0;

    if (parcelAccessors.getIsAnAssembly(parcel)) {
      for (const member of parcelAccessors.getAssemblyParcels(parcel)) {
        let memberArea = parcelAccessors.getAreaPublished(member);
        if (!memberArea) {
          memberArea = parcelAccessors.getAreaComputed(member);
          areaIsComputed = true;
        }
        area += memberArea;
      }
    } else {
      area = parcelAccessors.getAreaPublished(parcel);
      if (!area) {
        area = parcelAccessors.getAreaComputed(parcel);
        areaIsComputed = true;
      }
    }

    const unitSpecificConfig = configHelper.getConfigForUnitSystem(config.parcelArea, this.props.unitSystem);
    area = unitConversions.convertFromBase(area, unitSpecificConfig.unitTarget);

    if (areaIsComputed) {
      area = `Approx. ${valueFormatter.format(area, unitSpecificConfig.formatOptions)}`;
    } else {
      area = valueFormatter.format(area, unitSpecificConfig.formatOptions)
    }

    return area;
  }

  /**
   * Render table section if all fields are available.
   */
  renderConditionalTableSection = (sectionHeader: string, ...components) => {
    const componentToRender = components.filter(Boolean);
    if (componentToRender.length === 0) return null;

    return (
      <table>
        <thead>
          <tr>
            <th colSpan={2}>{sectionHeader}</th>
          </tr>
        </thead>
        <tbody>
          {componentToRender.map((Component, index) => <Component.type key={`sectionHeader_${index}`} {...Component.props} />)}
        </tbody>
      </table>
    )
  }

  /**
   * Toggle the accordion is open prop.
   */
  toggleIsOpen = () => {
    this.props.setStaticAccordionIsOpen(Accordions.PropertyInfo, !this.props.accordionIsOpen);
  }

  render() {
    const { parcelFeature } = this.props;
    const userIsAuthenticated = authentication.isUserAuthenticated();

    return (
      <AccordionSection
        title="Property Info"
        iconClass="property-info"
        isOpen={this.props.accordionIsOpen}
        toggleIsOpen={this.toggleIsOpen}
      >
        {this.renderConditionalTableSection("Property",
          ConditionalCellRow(
            parcelAccessors.getAddress(parcelFeature),
            <Cell text="Legal Address" />,
            <Cell hiddenValues={!userIsAuthenticated} />,
          ),
          ConditionalCellRow(
            this.displayPublishedArea(),
            <Cell text="Lot Size" />,
            <Cell hiddenValues={!userIsAuthenticated} />,
          ),
          ConditionalCellRow(
            parcelAccessors.getBasicStratum(parcelFeature),
            <Cell text="Current Use Definition" />,
            <Cell hiddenValues={!userIsAuthenticated} />,
          ),
          ConditionalCellRow(
            parcelAccessors.getLandUseCode(parcelFeature),
            <Cell text="Land Use" />,
            <Cell hiddenValues={!userIsAuthenticated} />,
          ),
          ConditionalCellRow(
            parcelAccessors.getPublicLand(parcelFeature),
            <Cell text="Public Land" />,
            <Cell hiddenValues={!userIsAuthenticated} />,
          ),
          ConditionalCellRow(
            parcelAccessors.getParcelOfficialId(parcelFeature),
            <Cell text="Folio Number" />,
            <Cell hiddenValues={!userIsAuthenticated} />,
          ),
        )}
        {this.renderConditionalTableSection("Building",
          ConditionalCellRow(
            this.displayExistingBuildingArea(),
            <Cell text="Existing Building Area" />,
            <Cell hiddenValues={!userIsAuthenticated} />,
          ),
          ConditionalCellRow(
            this.displayArray(parcelAccessors.getExistingStructureYearBuilt(parcelFeature)),
            <Cell text="Year Built" />,
            <Cell hiddenValues={!userIsAuthenticated} />,
          ),
          ConditionalCellRow(
            parcelAccessors.getNumberOfUnits(parcelFeature),
            <Cell text="Number of Units" />,
            <Cell hiddenValues={!userIsAuthenticated} formatOptions={{ suffix: " Units" }} />,
          ),
          ConditionalCellRow(
            parcelAccessors.getNumberOfBuildings(parcelFeature),
            <Cell text="Number of Buildings" />,
            <Cell hiddenValues={!userIsAuthenticated} formatOptions={{ suffix: " Buildings" }} />,
          ),
          ConditionalCellRow(
            this.displayArray(parcelAccessors.getConstructionClass(parcelFeature)),
            <Cell text="Construction Type" />,
            <Cell hiddenValues={!userIsAuthenticated} />,
          ),
          ConditionalCellRow(
            this.displayArray(parcelAccessors.getImprovementQuality(parcelFeature)),
            <Cell text="Structure Quality" />,
            <Cell hiddenValues={!userIsAuthenticated} />,
          ),
        )}
        {this.renderConditionalTableSection("Value",
          ConditionalCellRow(
            parcelAccessors.getPurchasePrice(parcelFeature),
            <Cell text="Market Value" />,
            <Cell hiddenValues={!userIsAuthenticated} formatOptions={{ type: "currency" }} />,
          ),
          ConditionalCellRow(
            parcelAccessors.getLandValue(parcelFeature),
            <Cell text="Land Value" />,
            <Cell hiddenValues={!userIsAuthenticated} formatOptions={{ type: "currency" }} />,
          ),
        )}
        {this.renderConditionalTableSection("Sales",
          ConditionalCellRow(
            parcelAccessors.getSalePrice(parcelFeature),
            <Cell text="Sales Price" />,
            <Cell hiddenValues={!userIsAuthenticated} formatOptions={{ type: "currency" }} />,
          ),
          ConditionalCellRow(
            parcelAccessors.getSaleDate(parcelFeature),
            <Cell text="Sales Date" />,
            <Cell hiddenValues={!userIsAuthenticated} />,
          ),
          ConditionalCellRow(
            parcelAccessors.getMultiParcelSale(parcelFeature),
            <Cell text="Assembly Sale" />,
            <Cell hiddenValues={!userIsAuthenticated} />,
          ),
        )}
        {this.renderConditionalTableSection("Owner",
          ConditionalCellRow(
            parcelAccessors.getOwnerName(parcelFeature),
            <Cell text="Owner's Name" />,
            <Cell hiddenValues={!userIsAuthenticated} />,
          ),
          ConditionalCellRow(
            parcelAccessors.getOwnerAddress(parcelFeature),
            <Cell text="Owner's Mailing Address" />,
            <Cell hiddenValues={!userIsAuthenticated} />,
          ),
        )}
      </AccordionSection>
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PropertyInformation);
