const GET_LISTINGS_START = "listings/GET_LISTINGS_START";
const GET_LISTINGS_SUCCESS = "listings/GET_LISTINGS_SUCCESS";
const SET_SELECTED_LISTING = "listings/SET_SELECTED_LISTING";
const CLEAR_LISTINGS = "listings/CLEAR_LISTINGS";

export default {
  GET_LISTINGS_START,
  GET_LISTINGS_SUCCESS,
  SET_SELECTED_LISTING,
  CLEAR_LISTINGS,
};
