import React from "react";
import analytics from "../../../../../utils/analytics";
import authentication, { AuthenticationProviderId } from "../../../../../utils/authentication";
import FederatedAuthenticationProviders from "../../../../sharedComponents/FederatedAuthenticationProviders";
import companyInfo from "../../../../../utils/companyInfo";
import completeSignUp from "../../../../../utils/completeSignUp";
import Authentication from "../../../../../types/Authentication";
import AuthenticationError from "../../../../../errors/AuthenticationError";

interface State {
  name: string;
  email: string;
  password: string;
  errorMessage: string;
  termsOfServiceAccepted: boolean;
};

interface OwnProps {
  onClickLogIn(): void;
}

type Props = OwnProps;

class SignUp extends React.PureComponent<Props, State>  {
  nameInput: any;

  constructor(props) {
    super(props);

    this.state = {
      name: "",
      email: "",
      password: "",
      errorMessage: "",
      termsOfServiceAccepted: false,
    };
  }

  /**
   * Throws error when the form required fields are not all filled or the terms of service is not checked.
   */
  verifyRequiredFields = (state) => {
    let errorCode;
    if (!state.email || !state.password || !state.name) {
      errorCode = Authentication.CustomErrorCode.MissingFields;
    } else if (!state.termsOfServiceAccepted) {
      errorCode = Authentication.CustomErrorCode.TermsOfServiceNotAccepted;
    }

    if (errorCode) throw new AuthenticationError(errorCode);
  }

  /**
   * Update the error message to the given value.
   */
  setErrorMessage = (errorMessage: string) => {
    this.setState({ errorMessage: errorMessage });
  }

  /**
   * This function sets the state for the required fields in this form. If all the required fields
   * are filled, the error message is removed (i.e. set to empty string).
   */
  updateState = (newState) => {
    newState.errorMessage = "";
    this.setState(newState);
  }

  /**
   * Updates the state based on whether the user was able to sign up or not.
   */
  handleFormSubmit = async (event) => {
    event.preventDefault();

    try {
      this.verifyRequiredFields(this.state);
      // Create user in Firebase Authentication.
      await authentication.createUserWithEmailAndPassword(this.state.email, this.state.password);

      // Create required records for this user in other external services.
      await completeSignUp({
        firstName: this.state.name,
      });

      analytics.trackSignUp(AuthenticationProviderId.EmailAndPassword);
    } catch (error) {
      this.setErrorMessage(authentication.getErrorMessage(error.code));
      analytics.trackAuthenticationError(error, AuthenticationProviderId.EmailAndPassword, "Sign Up");
    }
  }

  /**
   * Updates the state when the first name changes.
   */
  handleNameChange = (event) => {
    this.updateState({ name: event.target.value });
  }

  /**
   * Updates the state when the email changes.
   */
  handleEmailChange = (event) => {
    this.updateState({ email: event.target.value });
  }

  /**
   * Updates the state when the password changes.
   */
  handlePasswordChange = (event) => {
    this.updateState({ password: event.target.value });
  }

  /**
   * Updates the state when the user clicks the terms of service checkbox.
   */
  handleTermsOfServiceAcceptedChange = () => {
    this.updateState({ termsOfServiceAccepted: !this.state.termsOfServiceAccepted });
  }

  /**
   * Setting the focus on the email input.
   */
  componentDidMount() {
    if (this.nameInput) this.nameInput.focus();
  }

  render() {
    return (
      <div className="component--sign-up">
        <form onSubmit={this.handleFormSubmit}>
          <div className="input-section">
            <div className="input-field">
              <input
                type="text"
                name="firstName"
                placeholder="Your Name"
                autoComplete="name"
                ref={(input) => this.nameInput = input}
                onChange={this.handleNameChange}
              />
            </div>
          </div>

          <div className="input-section">
            <div className="input-field">
              <input
                type="email"
                name="email"
                placeholder=" Your Email Address"
                autoComplete="username"
                onChange={this.handleEmailChange}
              />
            </div>
          </div>

          <div className="input-section">
            <div className="input-field">
              <input
                type="password"
                name="password"
                placeholder="Password"
                autoComplete="current-password"
                onChange={this.handlePasswordChange}
              />
            </div>
          </div>

          <div className="error-container">
            <p>{this.state.errorMessage}</p>
          </div>

          <div className="tos-agreement">
            <input
              type="checkbox"
              className="tos"
              name="tos"
              onChange={this.handleTermsOfServiceAcceptedChange}
            />
            <div className="text-box">
              I agree to the <a href={companyInfo.TERMS_OF_SERVICE_URL} className="underlined-link" target="_blank" rel="noopener noreferrer">Terms of Service</a>
            </div>
            <div className="text-box right">
              Already subscribed? <span className="underlined" onClick={this.props.onClickLogIn}>Log In</span>
            </div>
          </div>
          <button type="submit" className="submit">Sign Up</button>
        </form>

        <FederatedAuthenticationProviders onError={this.setErrorMessage} termsOfServiceAccepted={this.state.termsOfServiceAccepted} />
      </div>
    );
  }
}

export default SignUp;
