export enum ImageType {
  Parcel = "Parcel",
  Building = "Building",
  Location = "Location",
  Cover = "Cover",
  Logo = "Logo",
}

export interface ContactDetails {
  name?: string;
  companyName?: string;
  phoneNumber?: string;
  email?: string;
}

export interface Image {
  fileName?: string;
  url?: string;
  isLoading: boolean;
}

export interface Document {
  title: string;
  summary: string;
  fromContactDetails: ContactDetails;
  toContactDetails: ContactDetails;
  colorPalette: {
    primary: string;
    secondary: string;
    text: string;
  };
  address: Address;
}

export interface Address {
  address?: string;
  city?: string;
  state?: string;
  zipCode?: string;
}
