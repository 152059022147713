import React from "react";
import styled from "@react-pdf/styled-components";
import PdfContext from "../../../../PdfContext";
import { displayableUsages, usageColor } from "../../../../../../../utils/buildingUsageProperties"
import { buildingUsageIsEnabled, allTogglesDisabled } from "../../../../../../../utils/uiToggleHelper";
import developmentAccessors from "../../../../../../../../state/development/utils/developmentAccessors";

const Container = styled.View`
  position: absolute;
  bottom: 12px;
  right: 12px;
  display: flex;
  flex-direction: row;
  padding: 3px 2px 3px 7px;
  background-color: #F5F7F8;
`;

const Use = styled.View`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-right: 5px;
`;

const Box = styled.View`
  height: 4px;
  width: 8px;
  margin-right: 3px;
  background-color: ${props => props.color};
`;

const Text = styled.Text`
  font-family: "Roboto Condensed";
  font-size: 6.25px;
  color: #333333;
  text-transform: capitalize;
`;

const UsesLegend = () => (
  <PdfContext.Consumer>
    {({ development }) => {
      let toggles = developmentAccessors.getBuildingUsageToggles(development);
      if (allTogglesDisabled(toggles)) return null;

      const displayableUsagesList = displayableUsages().filter(
        (usage) => buildingUsageIsEnabled(toggles, usage)
      );

      return (
        <Container>
          {displayableUsagesList.map((usage) => (
            <Use key={usage}>
              <Box color={usageColor(usage)} />
              <Text>{usage}</Text>
            </Use>
          ))}
        </Container>
      );
    }}
  </PdfContext.Consumer>
);

export default UsesLegend;
