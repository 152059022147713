import React, { Component } from "react";
import { Switch } from "react-router-dom";
import browser from "../../../utils/browser";
import companyInfo from "../../../utils/companyInfo"
import { BrowserSupportRequirement } from "../../../types/BrowserSupportRequirement";

interface Props {
  requiredSupportLevel: BrowserSupportRequirement
}

export default class SupportedBrowserSwitch extends Component<Props, {}> {

  isBrowserSupported = () => {
    switch (this.props.requiredSupportLevel) {
      case BrowserSupportRequirement.Full:    return browser.isFullySupported;
      case BrowserSupportRequirement.Partial: return browser.isPartiallySupported;
      default:                                return false;
    }
  }

  render() {
    if (!this.isBrowserSupported()) {
      window.location = companyInfo.UNSUPPORTED_BROWSER_URL as any;
      return null;
    }

    return (
      <Switch>
        {this.props.children}
      </Switch>
    );
  }
}
