export enum ParcelProperty {
  IsInOpportunityZone = "isInOpportunityZone",
  AreaComputed = "areaComputed",
  AssemblyParcels = "assemblyParcels",
  IsAnAssembly = "isAnAssembly",
  // Parcel Layer
  Id = "id",
  Address = "address",
  City = "city",
  State = "state",
  ZipCode = "zipCode",
  AreaPublished = "areaPublished",
  PurchasePrice = "purchasePrice",
  ExistingStructureArea = "existingStructureArea",
  ExistingStructureYearBuilt = "existingStructureYearBuilt",
  BasicStratum = "basicStratum",
  LandUseCode = "landUseCode",
  PublicLand = "publicLand",
  ParcelId = "parcelId",
  NumberOfResidentialUnits = "numberOfResidentialUnits",
  NumberOfBuildings = "numberOfBuildings",
  ConstructionClass = "constructionClass",
  ImprovementQuality = "improvementQuality",
  LandValue = "landValue",
  SalePrice = "salePrice",
  SaleYear = "saleYear",
  SaleMonth = "saleMonth",
  MultiParcelSale = "multiParcelSale",
  OwnerName = "ownerName",
  OwnerAddress = "ownerAddress",
  OwnerCity = "ownerCity",
  OwnerState = "ownerState",
  OwnerZipCode = "ownerZipCode",
  // Zoning layer
  ZoneId = "zoneId",
  AllowedBuildableArea = "allowedBuildableArea",
  AllowedUses = "allowedUses",
  ArchitectName = "architectName",
  ArchitectUrl = "architectUrl",
  BuildingHeight = "buildingHeight",
  LotCoverage = "lotCoverage",
  FloorAreaRatio = "floorAreaRatio",
  NumberOfFloors = "numberOfFloors",
  NumberOfUnitsAllowed = "numberOfUnitsAllowed",
  AllowedDetailedUses = "allowedDetailedUses",
  SetbacksPrimary = "setbacksPrimary",
  SetbacksSideStreet = "setbacksSideStreet",
  SetbacksSideInterior = "setbacksSideInterior",
  SetbacksRear = "setbacksRear",
  LivingUnitDensityNumerator = "livingUnitDensityNumerator",
  LivingUnitDensityDenominator = "livingUnitDensityDenominator",
  LivingUnitDensityUnitOfMeasure = "livingUnitDensityUnitOfMeasure",
  HotelUnitDensityNumerator = "hotelUnitDensityNumerator",
  HotelUnitDensityDenominator = "hotelUnitDensityDenominator",
  HotelUnitDensityUnitOfMeasure = "hotelUnitDensityUnitOfMeasure",
  ParkingSpacesResidentialNumerator = "parkingSpacesResidentialNumerator",
  ParkingSpacesResidentialDenominator = "parkingSpacesResidentialDenominator",
  ParkingSpacesResidentialUnitOfMeasure = "parkingSpacesResidentialUnitOfMeasure",
  ParkingSpacesSingleFamilyNumerator = "parkingSpacesSingleFamilyNumerator",
  ParkingSpacesSingleFamilyDenominator = "parkingSpacesSingleFamilyDenominator",
  ParkingSpacesSingleFamilyUnitOfMeasure = "parkingSpacesSingleFamilyUnitOfMeasure",
  ParkingSpacesHotelNumerator = "parkingSpacesHotelNumerator",
  ParkingSpacesHotelDenominator = "parkingSpacesHotelDenominator",
  ParkingSpacesHotelUnitOfMeasure = "parkingSpacesHotelUnitOfMeasure",
  ParkingSpacesOfficeNumerator = "parkingSpacesOfficeNumerator",
  ParkingSpacesOfficeDenominator = "parkingSpacesOfficeDenominator",
  ParkingSpacesOfficeUnitOfMeasure = "parkingSpacesOfficeUnitOfMeasure",
  ParkingSpacesRetailNumerator = "parkingSpacesRetailNumerator",
  ParkingSpacesRetailDenominator = "parkingSpacesRetailDenominator",
  ParkingSpacesRetailUnitOfMeasure = "parkingSpacesRetailUnitOfMeasure",
  ParkingSpacesIndustrialNumerator = "parkingSpacesIndustrialNumerator",
  ParkingSpacesIndustrialDenominator = "parkingSpacesIndustrialDenominator",
  ParkingSpacesIndustrialUnitOfMeasure = "parkingSpacesIndustrialUnitOfMeasure",
  // Demographics Layer
  MedianIncomeTotal = "medianIncomeTotal",
  GrossMedianRent = "grossMedianRent",
  PopulationDensity = "populationDensity",
  Gdp = "gdp",
}
