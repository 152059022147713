import firebase from "firebase";
import "firebase/functions";
import { Listing, listingKeyMap, ListingProperty } from "../../types/Service/Listings/Listings";

/**
 * Get list of listings available inside the provided bounding box.
 *
 * @param {Array<number>} boundingBox - bounding box to query data.
 *
 * @returns list of available listings.
 */
const getListings = async (boundingBox: [number, number, number, number]): Promise<any> => {
  const callable = firebase.functions().httpsCallable("bridgeInteractive-getListings");
  const boundingBoxString = boundingBox.join(",");
  const result = await callable({ boundingBox: boundingBoxString });

  const mappedListings = result.data.map((listing: Listing) => {
    let mappedListing = {};
    Object.values(ListingProperty).forEach((key) => mappedListing[key] = listing[listingKeyMap[key]]);

    return mappedListing;
  });

  return mappedListings;
}

export default {
  getListings,
};
