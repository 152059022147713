import { Config } from "../../../../../../../types/Config";
import Unit from "../../../../../../../types/Unit";
import Format from "../../../../../../../types/Format";
import { VariableId } from "../../../../../../../types/VariableId";
import { BuildingUse } from "../../../../../../../types/BuildingUse";

const config: Config = {
  [VariableId.ParcelArea]: {
    variableId: VariableId.ParcelArea,
    text: "Lot Area",
    requiresAuthentication: true,
    formatOptions: { type: Format.Type.Number },
    [Unit.System.Imperial]: {
      unitTarget: Unit.Type.SquareFeet,
      formatOptions: { suffix: " SF", },
    },
    [Unit.System.Metric]: {
      unitTarget: Unit.Type.SquareMeters,
      formatOptions: { suffix: " m²", },
    }
  },

  [VariableId.ProjectGrossBuildableArea]: {
    variableId: VariableId.ProjectGrossBuildableArea,
    text: "Gross Buildable Area",
    requiresAuthentication: true,
    formatOptions: { type: Format.Type.Number },
    [Unit.System.Imperial]: {
      unitTarget: Unit.Type.SquareFeet,
      formatOptions: { suffix: " SF", },
    },
    [Unit.System.Metric]: {
      unitTarget: Unit.Type.SquareMeters,
      formatOptions: { suffix: " m²", },
    }
  },

  [VariableId.ProjectNumberOfFloors]: {
    variableId: VariableId.ProjectNumberOfFloors,
    text: "Number of Floors",
    requiresAuthentication: true,
    formatOptions: {
      type: Format.Type.Number,
      suffix: " floors",
    },
  },

  [VariableId.CondoTotalUnitQuantity]: {
    variableId: VariableId.CondoTotalUnitQuantity,
    text: "Condo Units",
    requiresAuthentication: true,
    buildingUse: BuildingUse.Condo,
    formatOptions: {
      type: Format.Type.Number,
      suffix: " units",
    },
  },

  [VariableId.CondoAverageAreaPerUnit]: {
    variableId: VariableId.CondoAverageAreaPerUnit,
    text: "Condo Average Unit Size",
    requiresAuthentication: true,
    buildingUse: BuildingUse.Condo,
    formatOptions: { type: Format.Type.Number },
    [Unit.System.Imperial]: {
      unitTarget: Unit.Type.SquareFeet,
      formatOptions: { suffix: " SF", },
    },
    [Unit.System.Metric]: {
      unitTarget: Unit.Type.SquareMeters,
      formatOptions: { suffix: " m²", },
    }
  },

  [VariableId.MultifamilyTotalUnitQuantity]: {
    variableId: VariableId.MultifamilyTotalUnitQuantity,
    text: "Multifamily Units",
    requiresAuthentication: true,
    buildingUse: BuildingUse.Multifamily,
    formatOptions: {
      type: Format.Type.Number,
      suffix: " units",
    },
  },

  [VariableId.MultifamilyAverageAreaPerUnit]: {
    variableId: VariableId.MultifamilyAverageAreaPerUnit,
    text: "Multifamily Average Unit Size",
    requiresAuthentication: true,
    buildingUse: BuildingUse.Multifamily,
    formatOptions: { type: Format.Type.Number },
    [Unit.System.Imperial]: {
      unitTarget: Unit.Type.SquareFeet,
      formatOptions: { suffix: " SF", },
    },
    [Unit.System.Metric]: {
      unitTarget: Unit.Type.SquareMeters,
      formatOptions: { suffix: " m²", },
    }
  },

  [VariableId.HotelRoomQuantity]: {
    variableId: VariableId.HotelRoomQuantity,
    text: "Hotel Keys",
    requiresAuthentication: true,
    buildingUse: BuildingUse.Hotel,
    formatOptions: {
      type: Format.Type.Number,
      suffix: " keys",
    },
  },

  [VariableId.HotelAverageRoomArea]: {
    variableId: VariableId.HotelAverageRoomArea,
    text: "Hotel Average Room Size",
    requiresAuthentication: true,
    buildingUse: BuildingUse.Hotel,
    formatOptions: { type: Format.Type.Number },
    [Unit.System.Imperial]: {
      unitTarget: Unit.Type.SquareFeet,
      formatOptions: { suffix: " SF", },
    },
    [Unit.System.Metric]: {
      unitTarget: Unit.Type.SquareMeters,
      formatOptions: { suffix: " m²", },
    }
  },

  [VariableId.OfficeGrossLeasableArea]: {
    variableId: VariableId.OfficeGrossLeasableArea,
    text: "Office Leasable Area",
    requiresAuthentication: true,
    buildingUse: BuildingUse.Office,
    formatOptions: { type: Format.Type.Number },
    [Unit.System.Imperial]: {
      unitTarget: Unit.Type.SquareFeet,
      formatOptions: { suffix: " SF", },
    },
    [Unit.System.Metric]: {
      unitTarget: Unit.Type.SquareMeters,
      formatOptions: { suffix: " m²", },
    }
  },

  [VariableId.RetailNetLeasableArea]: {
    variableId: VariableId.RetailNetLeasableArea,
    text: "Retail Leasable Area",
    requiresAuthentication: true,
    buildingUse: BuildingUse.Retail,
    formatOptions: { type: Format.Type.Number },
    [Unit.System.Imperial]: {
      unitTarget: Unit.Type.SquareFeet,
      formatOptions: { suffix: " SF", },
    },
    [Unit.System.Metric]: {
      unitTarget: Unit.Type.SquareMeters,
      formatOptions: { suffix: " m²", },
    }
  },

  [VariableId.IndustrialNetLeasableArea]: {
    variableId: VariableId.IndustrialNetLeasableArea,
    text: "Industrial Leasable Area",
    requiresAuthentication: true,
    buildingUse: BuildingUse.Industrial,
    formatOptions: { type: Format.Type.Number },
    [Unit.System.Imperial]: {
      unitTarget: Unit.Type.SquareFeet,
      formatOptions: { suffix: " SF", },
    },
    [Unit.System.Metric]: {
      unitTarget: Unit.Type.SquareMeters,
      formatOptions: { suffix: " m²", },
    }
  },

  [VariableId.ParkingRequiredSpacesAfterReduction]: {
    variableId: VariableId.ParkingRequiredSpacesAfterReduction,
    text: "Total Structured Parking",
    requiresAuthentication: true,
    formatOptions: {
      type: Format.Type.Number,
      suffix: " spaces",
    },
  },

  [VariableId.CondoAverageGrossSalesPerSellableArea]: {
    variableId: VariableId.CondoAverageGrossSalesPerSellableArea,
    text: "Condo Average Sale",
    requiresAuthentication: true,
    buildingUse: BuildingUse.Condo,
    formatOptions: {
      type: Format.Type.Currency,
      decimalPlaces: 2
    },
    [Unit.System.Imperial]: {
      unitTarget: Unit.Type.SquareFeet,
      unitIsInverse: true,
      formatOptions: { suffix: " / SF", },
    },
    [Unit.System.Metric]: {
      unitTarget: Unit.Type.SquareMeters,
      unitIsInverse: true,
      formatOptions: { suffix: " / m²", },
    }
  },

  [VariableId.MultifamilyAverageYearOnePotentialGrossMonthlyRentalIncomePerUnit]: {
    variableId: VariableId.MultifamilyAverageYearOnePotentialGrossMonthlyRentalIncomePerUnit,
    text: "Multifamily Average Rent",
    requiresAuthentication: true,
    buildingUse: BuildingUse.Multifamily,
    formatOptions: {
      type: Format.Type.Currency,
      decimalPlaces: 2,
      suffix: " / mo"
    },
  },

  [VariableId.HotelStabilizedAverageDailyRate]: {
    variableId: VariableId.HotelStabilizedAverageDailyRate,
    text: "Hotel ADR",
    requiresAuthentication: true,
    buildingUse: BuildingUse.Hotel,
    formatOptions: {
      type: Format.Type.Currency,
      decimalPlaces: 2,
      suffix: " / key"
    },
  },

  [VariableId.HotelStabilizedRevenuePerAvailableRoom]: {
    variableId: VariableId.HotelStabilizedRevenuePerAvailableRoom,
    text: "Hotel RevPar",
    requiresAuthentication: true,
    buildingUse: BuildingUse.Hotel,
    formatOptions: {
      type: Format.Type.Currency,
      decimalPlaces: 2,
      suffix: " / key"
    },
  },

  [VariableId.OfficeYearOneRentPerArea]: {
    variableId: VariableId.OfficeYearOneRentPerArea,
    text: "Office Rent",
    requiresAuthentication: true,
    buildingUse: BuildingUse.Office,
    formatOptions: {
      type: Format.Type.Currency,
      decimalPlaces: 2
    },
    [Unit.System.Imperial]: {
      unitTarget: Unit.Type.SquareFeet,
      unitIsInverse: true,
      formatOptions: { suffix: " / SF", },
    },
    [Unit.System.Metric]: {
      unitTarget: Unit.Type.SquareMeters,
      unitIsInverse: true,
      formatOptions: { suffix: " / m²", },
    }
  },

  [VariableId.RetailYearOneRentPerArea]: {
    variableId: VariableId.RetailYearOneRentPerArea,
    text: "Retail Rent",
    requiresAuthentication: true,
    buildingUse: BuildingUse.Retail,
    formatOptions: {
      type: Format.Type.Currency,
      decimalPlaces: 2
    },
    [Unit.System.Imperial]: {
      unitTarget: Unit.Type.SquareFeet,
      unitIsInverse: true,
      formatOptions: { suffix: " / SF", },
    },
    [Unit.System.Metric]: {
      unitTarget: Unit.Type.SquareMeters,
      unitIsInverse: true,
      formatOptions: { suffix: " / m²", },
    }
  },

  [VariableId.IndustrialYearOneRentPerArea]: {
    variableId: VariableId.IndustrialYearOneRentPerArea,
    text: "Industrial Rent",
    requiresAuthentication: true,
    buildingUse: BuildingUse.Industrial,
    formatOptions: {
      type: Format.Type.Currency,
      decimalPlaces: 2
    },
    [Unit.System.Imperial]: {
      unitTarget: Unit.Type.SquareFeet,
      unitIsInverse: true,
      formatOptions: { suffix: " / SF", },
    },
    [Unit.System.Metric]: {
      unitTarget: Unit.Type.SquareMeters,
      unitIsInverse: true,
      formatOptions: { suffix: " / m²", },
    }
  },

  [VariableId.ProjectHardCost]: {
    variableId: VariableId.ProjectHardCost,
    text: "Hard Cost",
    requiresAuthentication: true,
    formatOptions: { type: Format.Type.Currency },
  },

  [VariableId.ProjectSoftCost]: {
    variableId: VariableId.ProjectSoftCost,
    text: "Soft Cost",
    requiresAuthentication: true,
    formatOptions: { type: Format.Type.Currency },
  },

  [VariableId.ProjectContingencyCost]: {
    variableId: VariableId.ProjectContingencyCost,
    text: "Contingency Cost",
    requiresAuthentication: true,
    formatOptions: { type: Format.Type.Currency },
  },

  [VariableId.ExistingStructureDemolitionCost]: {
    variableId: VariableId.ExistingStructureDemolitionCost,
    text: "Demolition Cost",
    requiresAuthentication: true,
    formatOptions: { type: Format.Type.Currency },
  },

  [VariableId.ParkingReductionFee]: {
    variableId: VariableId.ParkingReductionFee,
    text: "Parking Reduction Fee",
    requiresAuthentication: true,
    formatOptions: { type: Format.Type.Currency },
  },

  [VariableId.ParcelPurchasePrice]: {
    variableId: VariableId.ParcelPurchasePrice,
    text: "Site Acquisition Cost",
    requiresAuthentication: true,
    formatOptions: { type: Format.Type.Currency },
  },

  [VariableId.ProjectTotalDevelopmentCost]: {
    variableId: VariableId.ProjectTotalDevelopmentCost,
    text: "Total Project Cost",
    requiresAuthentication: true,
    formatOptions: { type: Format.Type.Currency },
  },
};

export default config;
