import React, { PureComponent } from "react";
import Cell from "../Cell/Cell";

interface Props {
  text: string;
  valueStyleVariation: string;
  hiddenValues?: boolean;
  isFromBackOfEnvelope?: boolean;
  styleVariation: string,
}

class CellsContainer extends PureComponent<Props, {}> {
  render() {
    const { text, valueStyleVariation, hiddenValues, isFromBackOfEnvelope, ...rest } = this.props;
    const styleVariation = `${rest.styleVariation || ""} ${valueStyleVariation || ""}`;

    return (
      <>
        <Cell text={text} {...rest} />
        <Cell {...{ ...rest, styleVariation, hiddenValues, isFromBackOfEnvelope }} />
      </>
    )
  }
}

export default CellsContainer;
