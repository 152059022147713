import { Font } from "@react-pdf/renderer";

const registerFonts = () => {
  Font.register(
    {
      src: "https://fonts.gstatic.com/s/roboto/v15/zN7GBFwfMP4uA6AR0HCoLQ.ttf",
      family: "Roboto"
    }
  );

  Font.register(
    {
      src: "https://fonts.gstatic.com/s/roboto/v15/d-6IYplOFocCacKzxwXSOKCWcynf_cDxXwCLxiixG1c.ttf",
      family: "Roboto Bold",
    }
  );

  Font.register(
    {
      src: "https://fonts.gstatic.com/s/robotocondensed/v13/Zd2E9abXLFGSr9G3YK2MsDR-eWpsHSw83BRsAQElGgc.ttf",
      family: "Roboto Condensed",
    }
  );

  Font.register(
    {
      src: "https://fonts.gstatic.com/s/robotocondensed/v13/b9QBgL0iMZfDSpmcXcE8nDokq8qT6AIiNJ07Vf_NrVA.ttf",
      family: "Roboto Condensed Bold",
    }
  );
}

Font.registerHyphenationCallback(word => [word])

export default registerFonts;
