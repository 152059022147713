import actionTypes from "./actionTypes";
import produce from "immer";
import developmentAccessors from "./utils/developmentAccessors";
import { Development } from "../../types/Development/Development";
import { VariableId } from "../../types/VariableId";
import { pdfActionTypes } from "../pdf";

const INITIAL_STATE = {
  values: {},
  isEdited: false,
  isFromShared: false,
  floorsWithSetbacks: [0],
  selectedSetbackFloor: 0,
  selectedSetbackFloorIndex: 0,
};

/**
 * Determine which reducer, if any, should handle reducing the given action and
 * state.
 */
const reducer = (previousState = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.RESET_STATE: return INITIAL_STATE;
    case actionTypes.INITIALIZE: return initialize(previousState, action.payload);
    case actionTypes.RE_CALCULATE: return recalculate(previousState, action.payload);
    case actionTypes.SET_NAME: return setName(previousState, action.payload);
    case actionTypes.SET_RANGED_INPUT: return setRangedInput(previousState, action.payload);
    case actionTypes.SET_RANGED_INPUT_MINIMUM: return setRangedInputMinimum(previousState, action.payload);
    case actionTypes.SET_RANGED_INPUT_MAXIMUM: return setRangedInputMaximum(previousState, action.payload);
    case actionTypes.SET_SETBACK_TYPE: return setSetbackType(previousState, action.payload);
    case actionTypes.TOGGLE_BOOLEAN_INPUT: return toggleBooleanInput(previousState, action.payload);
    case actionTypes.SET_CAMERA: return setCamera(previousState, action.payload);
    case actionTypes.SET_UNIT_SYSTEM: return setUnitSystem(previousState, action.payload);
    case actionTypes.PROJECT_SAVED: return projectSaved(previousState, action.payload);
    case actionTypes.KEEP_PROJECT: return keepProject(previousState, action.payload);
    case actionTypes.ADD_SETBACK_FLOOR: return addSetbackFloor(previousState, action.payload);
    case actionTypes.REMOVE_SETBACK_FLOOR: return removeSetbackFloor(previousState, action.payload);
    case actionTypes.RESET_SELECTED_SETBACK_FLOOR: return resetSelectedSetbackFloor(previousState, action.payload);
    case pdfActionTypes.SET_TITLE:
    case pdfActionTypes.SET_SUMMARY:
    case pdfActionTypes.SET_TO_CONTACTS_DETAILS:
    case pdfActionTypes.SET_FROM_CONTACTS_DETAILS:
    case pdfActionTypes.SET_COLOR_PALETTE:
    case pdfActionTypes.SET_ADDRESS: return setProjectIsEdited(previousState, action.payload);
    default: return previousState
  }
}

/**
 * See `initialize` action creator.
 *
 * Modify the development object to initialize it with the content of the payload.
 */
const initialize = (previousState, payload) => {
  let initialState = payload.initialState;
  developmentAccessors.ensureRequiredInputs(initialState);
  developmentAccessors.update(initialState);
  developmentAccessors.updateFloorsWithSetback(initialState);

  return {
    ...initialState,
    isEdited: false,
    selectedSetbackFloor: 0,
    selectedSetbackFloorIndex: 0,
  };
};

/**
 * See `recalculate` action creator.
 *
 * Modify the development object to re-initialize it with the content of the payload.
 */
const recalculate = (previousState, payload) => {
  let newState = payload.newState;
  developmentAccessors.ensureRequiredInputs(newState);
  developmentAccessors.update(newState);

  return {
    ...newState,
    isEdited: true,
  };
};

/**
 * See `resetSelectedSetbackFloor` action creator.
 */
const resetSelectedSetbackFloor = (previousState, payload) => {
  return {
    ...previousState,
    selectedSetbackFloor: INITIAL_STATE.selectedSetbackFloor,
    selectedSetbackFloorIndex: INITIAL_STATE.selectedSetbackFloorIndex,
  };
};

/**
 * Set project is edited flag.
 */
const setProjectIsEdited = (previousState, payload) => {
  return {
    ...previousState,
    isEdited: true,
  };
}

/**
 * See `projectSaved` action creator.
 *
 * Update project edited flag.
 */
const projectSaved = (previousState, payload) => {
  return {
    ...previousState,
    isEdited: false,
  };
};

/**
 * See `keepProject` action creator.
 *
 * Update isFromShared flag.
 */
const keepProject = (previousState, payload) => {
  return {
    ...previousState,
    isFromShared: false,
  };
};

/**
 * See `setRangedInput` action creator.
 *
 * Modify the development object by fully updating it using the new input
 * value.
 *
 */
const setRangedInput = (previousState, payload) => {
  return produce(previousState, (draftDevelopment) => {
    setInputValue(draftDevelopment, payload.inputId, payload.inputValue, payload.index);
    draftDevelopment.isEdited = true;
  });
}

/**
 * Modify the development object by setting the input value to the target if
 * it is on range, otherwise it sets it to the closest bound.
 */
const setInputValue = (development: Development, inputId: VariableId, targetValue, index?) => {
  if (targetValue > development.constraints.maximums[inputId]) {
    developmentAccessors.setInputMaximumConstrained(development, inputId, targetValue, index);
    developmentAccessors.setInputValueConstrained(development, inputId, targetValue, index);
  } else if (targetValue < development.constraints.minimums[inputId]) {
    developmentAccessors.setInputMinimumConstrained(development, inputId, targetValue, index);
    developmentAccessors.setInputValueConstrained(development, inputId, targetValue, index);
  } else {
    developmentAccessors.setInputValue(development, inputId, targetValue, index);
  }
  developmentAccessors.update(development);
}

/**
 * See `setRangedInputMinimum` action creator.
 *
 * Modify the development object by fully updating it using the new minimum input
 * value.
 */
const setRangedInputMinimum = (previousState, payload) => {
  return produce(previousState, (draftDevelopment) => {
    developmentAccessors.setInputMinimumConstrained(draftDevelopment, payload.inputId, payload.inputMinimum, payload.index);
    developmentAccessors.update(draftDevelopment);
    draftDevelopment.isEdited = true;
  })
};

/**
 * See `setRangedInputMaximum` action creator.
 *
 * Modify the development object by fully updating it using the new maximum input
 * value.
 */
const setRangedInputMaximum = (previousState, payload) => {
  return produce(previousState, (draftDevelopment) => {
    developmentAccessors.setInputMaximumConstrained(draftDevelopment, payload.inputId, payload.inputMaximum, payload.index);
    developmentAccessors.update(draftDevelopment);
    draftDevelopment.isEdited = true;
  })
};

/**
 * Modify the development object by fully updating it with the new type of the given setback.
 */
const setSetbackType = (previousState, payload) => {
  return produce(previousState, (draftDevelopment) => {
    developmentAccessors.setSetbackType(draftDevelopment, draftDevelopment.selectedSetbackFloorIndex, payload.polygonIndex, payload.setbackIndex, payload.setbackType);
    developmentAccessors.update(draftDevelopment);
    draftDevelopment.isEdited = true;
  })
};

/**
 * See the `addSetbackFloor` action creator.
 *
 * Modify the development object by adding a setback to the given floor.
 */
const addSetbackFloor = (previousState, payload) => {
  return produce(previousState, (draftDevelopment) => {
    developmentAccessors.addSetbackFloor(draftDevelopment, payload.floor);
    developmentAccessors.update(draftDevelopment);
    draftDevelopment.isEdited = true;
  })
};

/**
 * See the `removeSetbackFloor` action creator.
 *
 * Modify the development object by removing the setback at the given floor.
 */
const removeSetbackFloor = (previousState, payload) => {
  return produce(previousState, (draftDevelopment) => {
    developmentAccessors.removeSetbackFloor(draftDevelopment, payload.floor);
    developmentAccessors.update(draftDevelopment);
    draftDevelopment.isEdited = true;
  })
};

/**
 * See the `setName` action creator.
 *
 * Modify the development object with the new name value.
 */
const setName = (previousState, payload) => {
  return produce(previousState, (draftDevelopment) => {
    developmentAccessors.setName(draftDevelopment, payload.name);
    draftDevelopment.isEdited = true;
  })
};

/**
 * See `toggleBooleanInput` action creator.
 *
 * Modify the development by fully updating it after negating the
 * value of the identified input.
 */
const toggleBooleanInput = (previousState, payload) => {
  return produce(previousState, (draftDevelopment) => {
    let negatedValue = !developmentAccessors.getInputValue(draftDevelopment, payload.inputId);
    developmentAccessors.setInputValue(draftDevelopment, payload.inputId, negatedValue);
    developmentAccessors.update(draftDevelopment);
    draftDevelopment.isEdited = true;
  })
};

/**
 * See `setCamera` action creator.
 *
 * Modifies the development object adding the new camera values.
 */
const setCamera = (previousState, payload) => {
  return produce(previousState, (draftDevelopment) => {
    developmentAccessors.setCamera(draftDevelopment, payload.camera);
    draftDevelopment.isEdited = true;
  })
};

/**
 * See `setUnitSystem` action creator.
 *
 * Modifies the development object by setting the unit system.
 */
const setUnitSystem = (previousState, payload) => {
  return produce(previousState, (draftDevelopment) => {
    developmentAccessors.setUnitSystem(draftDevelopment, payload.unitSystem);
  })
};

export default reducer;
