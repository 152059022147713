/**
 * @fileoverview This module holds the key values for the `key` property of the KeyboardEvent.
 *
 *  See: https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/key
 *  See: https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/key/Key_Values
 */

export enum KeyCode {
  Enter = "Enter",
  Esc = "Escape",
  Shift = "Shift",
  ArrowDown = "ArrowDown",
  ArrowUp = "ArrowUp",
  Delete = "Delete",
}
