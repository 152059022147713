import React from "react";
import styled from "@react-pdf/styled-components";
import developmentAccessors from "../../../../../../../state/development/utils/developmentAccessors";
import valueFormatter from "../../../../../../../utils/valueFormatter";
import unitConversions from "../../../../../../../utils/unitConversions";
import PdfContext from "../../../PdfContext";

const Container = styled.View`
  min-height: 17px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: ${(props) => `${props.borderBottom || 0}px`};
  border-top: ${(props) => `${props.borderTop || 0}px`};
  border-color: ${(props) => props.borderColor};
  border-style: ${(props) => props.borderStyle};
  padding: 4px 0;
  max-width: 267px;
`;

const Label = styled.Text`
  font-family: ${(props) => props.labelFontFamily};
  font-size: ${(props) => `${props.fontSize}px`};
`;

const Value = styled.Text`
  font-family: ${(props) => props.valueFontFamily};
  font-size: ${(props) => `${props.fontSize}px`};
  max-width: 140px;
`;

const ValueContainer = styled.View`
  display: flex;
  align-items: flex-end;
`;

/**
 * Render value.
 */
const renderValue = (value, props, index?) => {
  return <Value valueFontFamily={props.valueFontFamily} fontSize={props.fontSize} key={index}>{value}</Value>
}

/**
 * Render the cell row value.
 */
const renderCellRowValue = (value, props) => {
  if (value === undefined) return null;

  const { formatOptions, formattingMap, unitTarget, unitIsInverse } = props;

  let array;
  if (Array.isArray(value)) array = value;

  let formattedValue;
  if (array) {
    formattedValue = formattingMap
        ? array.map(formattingMap).map((item) => valueFormatter.format(item.value, item.formatOptions))
        : array;
  } else {
    const convertedValue = unitTarget
        ? unitConversions.convertFromBase(value, unitTarget, unitIsInverse)
        : value;

    formattedValue = formatOptions
        ? valueFormatter.format(convertedValue, formatOptions)
        : convertedValue;
  }

  return (
    <ValueContainer>
      {Array.isArray(formattedValue)
          ? formattedValue.map((line, index) => renderValue(line, props, index))
          : renderValue(formattedValue, props)
      }
    </ValueContainer >
  );
}

const BaseRow = (props) => (
  <PdfContext.Consumer>
    {({ development }) => {
      let value = props.value || developmentAccessors.getInputValue(development, props.variableId);
      let toggles = developmentAccessors.getBuildingUsageToggles(development);

      if (props.buildingUse && !toggles[props.buildingUse]) return null;

      return (
        <Container
          borderColor={props.borderColor}
          borderStyle={props.borderStyle}
          borderTop={props.borderTop}
          borderBottom={props.borderBottom}
        >
          <Label labelFontFamily={props.labelFontFamily} fontSize={props.fontSize}>
            {props.text}
          </Label>

          {renderCellRowValue(value, props)}
        </Container>
      )
    }}
  </PdfContext.Consumer>
);

export default BaseRow;
